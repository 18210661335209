/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {BaseButton} from "components/buttons"
import {useModel, useVisible} from "hooks"
import {AcademicPlans} from "types/academicPlans"
import {academicPlansService} from "services"
import {convertTZ, formatDateOnly, handleError} from "helpers"
import {ConfirmPopup} from "uiKit"
import {AddCoursesPopup} from "../Popup"
import CourseSectionTable from "./CourseSectionTable"
import styles from "./CourseSection.module.css"
import {Course} from "types/courses"
import {Major} from "types/major"
import {cloneDeep, get, isEmpty, uniq} from "lodash"
import {Auth} from "types/auth"
import {ExportButton, ExportFileType} from "components/ui"
import {ExportPdfPayload, exportCsv, exportExcel} from "helpers/export-table"
import moment from "moment"
import {getDateOnly} from "sections/academics/instructional/common/utils"
import {ExportPDFFrame, ExportPdfFrameType} from "sections/shared/student/ExportPDFFrame"
import {DegreeAuditProgressData} from "types/degreeAudit"

type Props = {
    courseSection: AcademicPlans.Section
    studentId: number
    student: Auth.DepartmentStudent
    majorVersionType: string
    progressData?: DegreeAuditProgressData
    reloadView: () => void
}

export function CourseSection(props: Props) {
    const model = useModel()
    function reducer(state, action) {
        return {...state, ...action}
    }
    const initialState = {
        courses: [],
        isSaving: false,
        isDeleting: false,
        forceKey: 1
    }
    const userDateFormat = model.getUserDateFormat()
    const [{courses, forceKey}, dispatch] = useReducer(reducer, initialState)
    const {courseSection, studentId, majorVersionType, student, reloadView, progressData} = props
    const addCoursesPopup = useVisible(false)
    const confirmPopup = useVisible(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const {t} = useTranslation(["common", "academics"])
    const [exporting, setExporting] = useState(false)
    const [exportingPdf, setExportingPdf] = useState(false)
    const [pdfPayload, setPdfPayload] = useState<
        ExportPdfPayload & {
            terms: Array<{
                termId: number
                termName: string
                courses: Array<any>
            }>
        }
    >()

    const courseUnit = useMemo(() => {
        if (isEmpty(courseSection.courseUnit)) {
            return null
        }
        if (courseSection.courseUnit === Course.Unit.Each) {
            return "Each"
        }
        return majorVersionType === Major.Unit.Credit ? "Credit Hour" : "Clock Hour"
    }, [courseSection, majorVersionType])

    useEffect(() => {
        dispatch({courses: courseSection.courses, forceKey: forceKey + 1})
    }, [courseSection.courses])

    const onClickAddCourse = () => {
        addCoursesPopup.open()
    }

    const saveCoursesSuccess = (courses: AcademicPlans.SectionCourse[]) => {
        reloadView()
        addCoursesPopup.close()
    }

    const removeSection = async () => {
        try {
            setIsDeleting(true)
            await academicPlansService.removeSection({academicPlanSectionId: courseSection.academicPlanSectionId})
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsDeleting(false)
        }
    }

    const checkCourseStatus = (course) => {
        const {reviewStatus, status, registeredDate, droppedDate} = course
        switch (status) {
            case AcademicPlans.AcademicPlanCourseStatus.TransferCompleted: {
                return "Completed (T)"
            }
            case AcademicPlans.AcademicPlanCourseStatus.PreviouslyCompleted: {
                return "Completed (P)"
            }
            case AcademicPlans.AcademicPlanCourseStatus.Completed:
            case AcademicPlans.AcademicPlanCourseStatus.Finished: {
                return "Completed"
            }
            case AcademicPlans.AcademicPlanCourseStatus.Incomplete: {
                return "Incomplete"
            }
            case AcademicPlans.AcademicPlanCourseStatus.LoaIncomplete: {
                return "Loa-Incomplete"
            }
            case AcademicPlans.AcademicPlanCourseStatus.Inprogress: {
                return "In Progress"
            }
            case AcademicPlans.AcademicPlanCourseStatus.Failed: {
                return "Failed"
            }
            case AcademicPlans.AcademicPlanCourseStatus.Loa: {
                return "LOA"
            }
        }
        switch (reviewStatus) {
            case AcademicPlans.AcademicPlanCourseReviewStatus.Dropped: {
                return `DD ${getDateOnly({date: droppedDate, placeholder: true, dateFormat: userDateFormat})}`
            }
            case AcademicPlans.AcademicPlanCourseReviewStatus.Registered: {
                return `Registered (${moment(registeredDate).format(model.getUserDateFormat())})`
            }
            case AcademicPlans.AcademicPlanCourseReviewStatus.PendingReview: {
                return "Pending"
            }
            default:
                return null
        }
    }

    const handleExportFile = async (type: ExportFileType) => {
        setExporting(true)
        try {
            const dataExport = cloneDeep(courses).map((course, index) => {
                const isAlternateCourse = !!courses?.find((itemCourse) =>
                    itemCourse.alternateCourses.find(
                        (alternateCourse) =>
                            alternateCourse.academicPlanSectionId === course.academicPlanSectionId &&
                            alternateCourse.courseId === course.courseId
                    )
                )
                course.firstDayOfCourse = formatDateOnly(course.firstDayOfCourse, userDateFormat)
                course.lastDayOfCourse = formatDateOnly(course.lastDayOfCourse, userDateFormat)
                course.startEndDate = [course.firstDayOfCourse, course.lastDayOfCourse]
                    .filter(Boolean)
                    .map((date) => <p>{formatDateOnly(date, userDateFormat)}</p>)

                const startEndTime = uniq(
                    course.events.map((item) => {
                        const start = moment(convertTZ(new Date(item.start), course.timezone)).format(
                            model.getUserTimeFormat()
                        )
                        const end = moment(convertTZ(new Date(item.end), course.timezone)).format(
                            model.getUserTimeFormat()
                        )
                        return `${start} - ${end}`
                    })
                )
                course.weekdays = course.weekdays.join(",")
                course.startEndTime = startEndTime.map((item) => <p>{item}</p>)
                course.isAlternateCourse = isAlternateCourse
                course.id = course.academicPlanCourseId || course.courseId
                course.status = checkCourseStatus(course)
                course.classSize = get(course.registeredScheduleInstructors, [0, "available", "current"], 0)
                return course
            })

            const columns =
                type === "pdf"
                    ? [
                          {title: "Week day", field: "weekdays"},
                          {title: "Start/End", field: "startEndTime"},
                          {title: "Start/End Date", field: "startEndDate"},
                          {title: "Course Name", field: "courseName"},
                          {title: "Schedule", field: "scheduleName"},
                          {title: "Clock/Credit", field: "credit"},
                          {title: "Instructor", field: "courseInstructor"},
                          {title: "Room", field: "roomName"},
                          {title: "Online", field: "onlineClassroomName"},
                          {title: "Status", field: "status"}
                      ]
                    : [
                          {title: "Course Name", field: "courseName"},
                          {title: "Grade", field: "grade"},
                          {title: "Term", field: "termName"},
                          {title: "Schedule", field: "scheduleName"},
                          {title: "Start Date", field: "firstDayOfCourse"},
                          {title: "End Date", field: "lastDayOfCourse"},
                          {title: "Clock/Credit", field: "credit"},
                          {title: "Instructor", field: "courseInstructor"},
                          {title: "Room", field: "roomName"},
                          {title: "Online", field: "onlineClassroomName"},
                          {title: "Status", field: "status"}
                      ]
            const filename = `Academic_Plan_${moment().format("MM_DD_YYYY")}`
            let terms: Array<{
                termId: number
                termName: string
                courses: Array<any>
            }> = []
            for (let course of dataExport) {
                const term = terms.find((term) => term.termId === course.termId)
                if (!term) {
                    terms.push({
                        termId: course.termId,
                        termName: course.termName ?? t("academics:academics.termNameUnassigned"),
                        courses: [course]
                    })
                } else {
                    term.courses.push(course)
                }
            }
            const emptyTerm = terms.find((term) => term.termName === t("academics:academics.termNameUnassigned"))
            terms = terms
                .filter((term) => term.termName !== t("academics:academics.termNameUnassigned"))
                .sort((a, b) => a.termName.localeCompare(b.termName))
            if (emptyTerm) {
                terms.push(emptyTerm)
            }

            const payload = {
                filename,
                columns: columns.map((col) => col.title),
                rows: dataExport.map((item) =>
                    columns.map((col) => {
                        return item[col.field]?.toString() ?? ""
                    })
                ),
                terms
            }

            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            } else if (type === "pdf") {
                setPdfPayload({
                    ...payload,
                    terms: terms.map((term) => {
                        return {
                            ...term,
                            courses: term.courses.map((item) =>
                                columns.map((col) => {
                                    if (["startEndTime", "startEndDate"].includes(col.field)) {
                                        return item[col.field] ?? ""
                                    }
                                    return item[col.field]?.toString() ?? ""
                                })
                            )
                        }
                    })
                })
                setExportingPdf(true)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setExporting(false)
        }
    }

    return (
        <div className={styles.courseSection}>
            <div className={styles.headerWrap}>
                <div className={styles.sectionInfo}>
                    <span className={styles.sectionTitle}>{courseSection.sectionTitle}</span>
                    {courseUnit && (
                        <div className={styles.creditWrap}>
                            <span className={styles.creditTitle}>Unit</span>
                            <span className={styles.unitValue}>{courseUnit}</span>
                        </div>
                    )}
                    <div className={styles.creditWrap}>
                        <span className={styles.creditTitle}>Minimum</span>
                        <span className={styles.creditValue}>
                            {courseSection.sectionMinimum || courseSection.credits.minimum}
                        </span>
                    </div>
                    <div className={styles.completedWrap}>
                        <span className={styles.completedTitle}>Completed</span>
                        <span className={styles.completedValue}>{courseSection.credits.completed}</span>
                    </div>
                </div>
                <div className={styles.actionWrap}>
                    {isEmpty(courses) && (
                        <BaseButton
                            title="Delete Section"
                            variant="secondary"
                            loading={isDeleting}
                            onClick={confirmPopup.open}
                        />
                    )}
                    {model.isStaffOrAdmin() ? (
                        <ExportButton
                            isLoading={exporting}
                            onSelect={handleExportFile}
                            availableFileTypes={["pdf", "csv", "excel"]}
                        />
                    ) : null}
                    <BaseButton title="Add Course" onClick={onClickAddCourse} />
                </div>
            </div>
            <CourseSectionTable
                key={forceKey}
                reloadView={reloadView}
                coursesData={courses}
                studentId={studentId}
                student={student}
                saveCoursesSuccess={saveCoursesSuccess}
                majorVersionType={majorVersionType}
            />
            {addCoursesPopup.isVisible && (
                <AddCoursesPopup
                    isShow={addCoursesPopup.isVisible}
                    onClose={addCoursesPopup.close}
                    selectedCourses={courseSection.courses}
                    studentId={studentId}
                    courseUnit={courseSection.courseUnit}
                    academicPlanSectionId={courseSection.academicPlanSectionId}
                    saveCoursesSuccess={saveCoursesSuccess}
                />
            )}

            {confirmPopup.isVisible && (
                <ConfirmPopup
                    isVisible={confirmPopup.isVisible}
                    title={t("common:confirmationPopup.delete", {title: "Section"})}
                    onClose={confirmPopup.close}
                    onConfirm={removeSection}
                    loading={isDeleting}
                />
            )}

            <ExportPDFFrame
                id={courseSection.sectionId}
                type={ExportPdfFrameType.AcademicPlanSection}
                progressData={progressData}
                pageTitle="Academic Plan"
                fileName={`Academic_Plan`}
                student={student}
                payload={pdfPayload}
                isExporting={exportingPdf}
                onSuccessExported={() => {
                    setExportingPdf(false)
                }}
            />
        </div>
    )
}
