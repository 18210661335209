import React from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {DisbursementStatus} from "types/fin-aid/disbursement"
import styles from "./Status.module.css"

type Props = {
    status: DisbursementStatus
}

const Status: React.FC<Props> = ({status}) => {
    const {t} = useTranslation(["financialAid"])

    return (
        <div className={cx(styles.statusWrap, status)}>
            <span className={styles.statusText}>{t(`finAidAward.disbursement.status.${status}`)}</span>
        </div>
    )
}

export default Status
