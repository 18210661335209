/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Row, Col, Radio} from "antd"
import {keyBy} from "lodash"
import moment from "moment"
import {BaseInput} from "components/inputs/BaseInput"
import {KlassDropdown} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker/BaseDatePicker"
import {FormLabel} from "components/Form"
import styles from "../../Personal.module.css"
import cx from "classnames"
import {userServiceV3} from "services"
import {CITIZENSHIP_STATUS_OPTIONS, MILITARY_VETERAN_STATUS_OPTIONS} from "types/students"
import {useModel} from "hooks"
import {HIGHEST_LEVEL_OF_FORMAL_EDUCATION_ACHIEVED_OPTIONS} from "helpers"

export const GENDER_OPTIONS = [
    {id: "male", name: "Male"},
    {id: "female", name: "Female"},
    {id: "other", name: "Other"},
    {id: "nodisclose", name: "Prefer not to disclose"}
]

export const GENDER_OPTIONS_KEY_BY_ID = keyBy(GENDER_OPTIONS, "id")

const genderPronounOptions = [
    {id: "she-her-hers", name: "She/Her/Hers"},
    {id: "he-him-his", name: "He/Him/His"},
    {id: "they-them-theirs", name: "They/Them/Theirs"},
    {id: "other", name: "Other"}
]

export const genderPronounOptionsKeyById = keyBy(genderPronounOptions, "id")

const marriedOptions = [
    {id: "single", name: "Single"},
    {id: "married", name: "Married"},
    {id: "divorced", name: "Divorced"},
    {id: "widowed", name: "Widowed"},
    {id: "separated", name: "Separated"},
    {id: "unknown", name: "Unknown"}
]

const citizenshipStatusOptions = CITIZENSHIP_STATUS_OPTIONS

const youMilitaryBranchOptions = [
    {id: "air-force", name: "Air Force"},
    {id: "army", name: "Army"},
    {id: "coast-guard", name: "Coast Guard"},
    {id: "marines", name: "Marines"},
    {id: "navy", name: "Navy"},
    {id: "other-military", name: "Other Military"}
]

const veteranStatusOptions = MILITARY_VETERAN_STATUS_OPTIONS

const militaryBenefitsOptions = [
    {id: "none", name: "None"},
    {id: "chapter-1606-gi-bill-selective-reserve", name: "Chapter 1606-GI Bill Selective Reserve"},
    {id: "chapter-30-montggomery-gi-bill", name: "Chapter 30-Montggomery GI Bill"},
    {id: "chapter-31-vocational-rehabilitation", name: "Chapter 31-Vocational Rehabilitation"},
    {id: "chapter-33-post-911-gi-bill", name: "Chapter 33-Post 911 GI Bill"},
    {id: "chapter-35-dependents-educational-assistance", name: "Chapter 35-Dependent's Educational Assistance"},
    {
        id: "mycaa-military-spouse-carrer-advancement-accounts",
        name: "MyCAA-Military Spouse Carrer Advancement Accounts"
    },
    {id: "tuition-assistance-ta", name: "Tuition Assistance (TA)"},
    {id: "unknown", name: "Unknown"}
]

export const marriedOptionsKeyById = keyBy(marriedOptions, "id")
export const citizenshipStatusOptionsKeyById = keyBy(citizenshipStatusOptions, "id")

export const youMilitaryBranchOptionsKeyById = keyBy(youMilitaryBranchOptions, "id")
export const veteranStatusOptionsKeyById = keyBy(veteranStatusOptions, "id")
export const militaryBenefitsOptionsKeyById = keyBy(militaryBenefitsOptions, "id")

const racesOptions = [
    {id: "asian", name: "Asian"},
    {id: "white", name: "White"},
    {id: "hispanic", name: "Hispanic/Latino"},
    {id: "black-african", name: "Black/African American"},
    {id: "american-indian", name: "American Indian/Alaska Native"},
    {id: "native-hawaiia", name: "Native Hawaiian/Pacific Islander"},
    {id: "two-or-more", name: "Two or more races"},
    {id: "unknown", name: "Race or Ethnicity unknown"}
]

export const racesOptionsKeyById = keyBy(racesOptions, "id")

export function GeneralDetail(props) {
    const {
        user,
        isShowSubmitBtn,
        personalData,
        errors,
        onChangePersonalData,
        onChangeSsnItem,
        canShowPersonalInfo,
        canShowDob,
        canShowSsn,
        isOpenSection,
        countries,
        onCheckCustomUserId
    } = props
    const {t} = useTranslation(["user", "common"])
    const {
        firstName,
        lastName,
        maidenName,
        middleName,
        nickNamePreferedName,
        gender,
        genderPronoun,
        permanentResidentId,
        birthDate,
        placeOfBirth,
        race,
        maritalStatus,
        ssn,
        citizenShip,
        isHispanic,
        liveWithParents,
        citizenshipStatus,
        customUserId,
        nameSuffix,
        driverLicenseNumber,
        driverLicenseState,
        highestLevelOfFormalEducationAchieved
    } = personalData

    const [enablePermanentResident, setEnablePermanentResident] = useState(false)
    const model = useModel()
    const {slug} = model

    useEffect(() => {
        if (citizenShip) {
            setEnablePermanentResident(true)
        }
    }, [citizenShip])

    useEffect(() => {
        if (user.create && !user.id) {
            getNextCustomUserId()
        }
    }, [])

    const getNextCustomUserId = async () => {
        const {data: newCustomUserId} = await userServiceV3.nextCustomUserId()
        onChangePersonalData("customUserId", newCustomUserId)
    }
    const onChangePersonalDataGeneralDetail = (key, value) => {
        if (key === "citizenShip" && value) {
            setEnablePermanentResident(true)
        }
        onChangePersonalData(key, value)
    }

    const genderString = gender ? gender.toLowerCase() : ""

    const marialStatusValue = marriedOptionsKeyById[maritalStatus] || null
    const citizenshipStatusValue = citizenshipStatusOptionsKeyById[citizenshipStatus] || null
    const genderValue = GENDER_OPTIONS_KEY_BY_ID[genderString] || null
    const raceValue = racesOptionsKeyById[race] || null
    const countryOptionsKeyByName = keyBy(countries, "label")
    const citizenShipValue = countryOptionsKeyByName[citizenShip]
    const genderPronounValue = genderPronounOptionsKeyById[genderPronoun] || null

    return (
        <div
            className={cx("", {
                [styles.hidden]: !isOpenSection
            })}>
            {canShowPersonalInfo && (
                <>
                    <Row>
                        <Col span={12}>
                            <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                <FormLabel label={t("user.userId")} isRequired />
                                <BaseInput
                                    placeholder="User ID"
                                    value={customUserId}
                                    readOnly={!isShowSubmitBtn}
                                    onChange={(newValue) => onChangePersonalData("customUserId", newValue)}
                                    error={!!errors["customUserId"]}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                <FormLabel label={t("user.genderPronoun")} />
                                <KlassDropdown
                                    onChange={(newValue) => onChangePersonalData("genderPronoun", newValue.id)}
                                    options={genderPronounOptions}
                                    value={genderPronounValue}
                                    placeholder={t("user.genderPronoun")}
                                    readOnly={!isShowSubmitBtn}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                <FormLabel label={t("user.firstName")} isRequired />
                                <BaseInput
                                    onChange={(newValue) => onChangePersonalData("firstName", newValue)}
                                    placeholder={t("user.firstName")}
                                    value={firstName}
                                    readOnly={!isShowSubmitBtn}
                                    className={!!errors["firstName"] ? styles.error : ""}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                <FormLabel label={t("user.lastName")} isRequired />
                                <BaseInput
                                    onChange={(newValue) => onChangePersonalData("lastName", newValue)}
                                    placeholder={t("user.lastName")}
                                    value={lastName}
                                    readOnly={!isShowSubmitBtn}
                                    className={!!errors["lastName"] ? styles.error : ""}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                <FormLabel label={t("user.middleName")} />
                                <BaseInput
                                    onChange={(newValue) => onChangePersonalData("middleName", newValue)}
                                    placeholder={t("user.middleName")}
                                    value={middleName}
                                    readOnly={!isShowSubmitBtn}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                <FormLabel label={t("user.nameSuffix")} />
                                <BaseInput
                                    onChange={(newValue) => onChangePersonalData("nameSuffix", newValue)}
                                    placeholder={t("user.nameSuffix")}
                                    value={nameSuffix}
                                    readOnly={!isShowSubmitBtn}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                <FormLabel label={t("user.maidenName")} />
                                <BaseInput
                                    onChange={(newValue) => onChangePersonalData("maidenName", newValue)}
                                    placeholder={t("user.maidenName")}
                                    value={maidenName}
                                    readOnly={!isShowSubmitBtn}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                <FormLabel label={t("user.nickNamePreferedName")} />
                                <BaseInput
                                    onChange={(newValue) => onChangePersonalData("nickNamePreferedName", newValue)}
                                    placeholder={t("user.nickNamePreferedName")}
                                    value={nickNamePreferedName}
                                    readOnly={!isShowSubmitBtn}
                                    name="preferredName"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {canShowSsn && (
                            <Col span={12}>
                                <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                    <FormLabel label={t("user.ssn")} />
                                    <BaseInput
                                        onChange={onChangeSsnItem}
                                        placeholder="SSN"
                                        value={ssn}
                                        readOnly={!isShowSubmitBtn}
                                        hide
                                        name="ssn"
                                        autoComplete="new-password"
                                        maxLength={11}
                                    />
                                </div>
                            </Col>
                        )}
                        {canShowPersonalInfo && (
                            <Col span={12}>
                                <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                                    <FormLabel label={t("user.gender")} />
                                    <KlassDropdown
                                        onChange={(newValue) => onChangePersonalData("gender", newValue.id)}
                                        options={GENDER_OPTIONS}
                                        value={genderValue}
                                        placeholder={t("user.gender")}
                                        readOnly={!isShowSubmitBtn}
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>
                </>
            )}
            <Row>
                {canShowDob && (
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.dateOfBirth")} />
                            <BaseDatePicker
                                onChange={(newValue) => onChangePersonalData("birthDate", newValue)}
                                value={birthDate ? moment(birthDate) : ""}
                                readOnly={!isShowSubmitBtn}
                                className={!!errors["birthDate"] ? styles.error : ""}
                                disabledDate={(currentDate) => moment(currentDate).isAfter(moment())}
                            />
                        </div>
                    </Col>
                )}
                {canShowDob && (
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("common:studentInfo.placeOfBirth")} />
                            <BaseInput
                                onChange={(newValue) => onChangePersonalData("placeOfBirth", newValue)}
                                placeholder={t("common:studentInfo.placeOfBirth")}
                                value={placeOfBirth}
                                readOnly={!isShowSubmitBtn}
                            />
                        </div>
                    </Col>
                )}
            </Row>
            {canShowPersonalInfo && (
                <Row>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.hispanic")} />
                            <Radio.Group
                                className={styles.checkboxGroup}
                                onChange={(event) =>
                                    onChangePersonalDataGeneralDetail("isHispanic", event.target.value)
                                }
                                value={isHispanic}>
                                <Radio className={styles.radioBtn__item} value={true}>
                                    Yes
                                </Radio>
                                <Radio className={styles.radioBtn__item} value={false}>
                                    No
                                </Radio>
                            </Radio.Group>
                        </div>
                    </Col>

                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.race")} />
                            <KlassDropdown
                                onChange={(newValue) => onChangePersonalData("race", newValue.id)}
                                options={racesOptions}
                                value={raceValue}
                                placeholder={t("user.race")}
                                readOnly={!isShowSubmitBtn}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            {canShowPersonalInfo && (
                <Row>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.marialStatus")} />
                            <KlassDropdown
                                onChange={(newValue) => onChangePersonalData("maritalStatus", newValue.id)}
                                options={marriedOptions}
                                value={marialStatusValue}
                                placeholder={t("user.marialStatus")}
                                readOnly={!isShowSubmitBtn}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.citizenshipStatus")} />
                            <KlassDropdown
                                onChange={(newValue) => onChangePersonalData("citizenshipStatus", newValue.id)}
                                options={citizenshipStatusOptions}
                                value={citizenshipStatusValue}
                                placeholder={t("user.citizenshipStatus")}
                                readOnly={!isShowSubmitBtn}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            {canShowPersonalInfo && (
                <Row>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.citizenShip")} />
                            <KlassDropdown
                                onChange={(newValue) => onChangePersonalDataGeneralDetail("citizenShip", newValue)}
                                options={countries}
                                valueKey="value"
                                labelKey="label"
                                value={citizenShipValue}
                                placeholder={t("user.citizenShip")}
                                readOnly={!isShowSubmitBtn}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.permanentResidentId")} />
                            <BaseInput
                                onChange={(newValue) => onChangePersonalData("permanentResidentId", newValue)}
                                placeholder={t("user.permanentResidentId")}
                                value={permanentResidentId}
                                readOnly={!isShowSubmitBtn || !enablePermanentResident}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            {canShowPersonalInfo && (
                <Row>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.driverLicenseNumber")} />
                            <BaseInput
                                onChange={(newValue) => onChangePersonalData("driverLicenseNumber", newValue)}
                                placeholder={t("user.driverLicenseNumber")}
                                value={driverLicenseNumber}
                                readOnly={!isShowSubmitBtn}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.driverLicenseIssuingState")} />
                            <BaseInput
                                onChange={(newValue) => onChangePersonalData("driverLicenseState", newValue)}
                                placeholder={t("user.driverLicenseIssuingState")}
                                value={driverLicenseState}
                                readOnly={!isShowSubmitBtn}
                            />
                        </div>
                    </Col>
                </Row>
            )}
            {canShowPersonalInfo && (
                <Row>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.highestLevelOfFormalEducationAchieved")} />
                            <KlassDropdown
                                onChange={(newValue) =>
                                    onChangePersonalDataGeneralDetail(
                                        "highestLevelOfFormalEducationAchieved",
                                        newValue.id
                                    )
                                }
                                options={HIGHEST_LEVEL_OF_FORMAL_EDUCATION_ACHIEVED_OPTIONS}
                                value={HIGHEST_LEVEL_OF_FORMAL_EDUCATION_ACHIEVED_OPTIONS.find(
                                    (option) => option.id === highestLevelOfFormalEducationAchieved
                                )}
                                placeholder={t("user.highestLevelOfFormalEducationAchieved")}
                                readOnly={!isShowSubmitBtn}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={cx(styles.itemBodyWrap, styles.itemBodyMargin)}>
                            <FormLabel label={t("user.liveWithParents")} />
                            <Radio.Group
                                className={styles.checkboxGroup}
                                onChange={(event) =>
                                    onChangePersonalDataGeneralDetail("liveWithParents", event.target.value)
                                }
                                value={liveWithParents}>
                                <Radio className={styles.radioBtn__item} value={true}>
                                    Yes
                                </Radio>
                                <Radio className={styles.radioBtn__item} value={false}>
                                    No
                                </Radio>
                            </Radio.Group>
                        </div>
                    </Col>
                </Row>
            )}
        </div>
    )
}
