import React, {useState, useCallback, useEffect, useMemo} from "react"
import moment from "moment"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {AcademicInstructional} from "types/academicInstructional"
import styles from "./AddStudentPopup.module.css"
import {academicPlansService, profileService} from "services"
import {get} from "lodash"
import {StudentInfo} from "./StudentInfo"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {useDebounce} from "react-use"
import {Search} from "components/inputs/Search"
import {Auth} from "types/auth"
import {handleError, toastError} from "helpers"
import {LessonInfo} from "./LessonInfo"
import cx from "classnames"
import {StudentDetail} from "./StudentDetail"
import ListLessons from "./ListLessons/ListLessons"
import OtherReasons from "./OtherReasons/OtherReasons"
import {AcademicPlans} from "types/academicPlans"

type Props = {
    isShow: boolean
    lessonDetail: AcademicInstructional.LessonDetail
    onClose: () => void
    studentProfileIds: number[]
    termId: number
    courseId: number
    timezone?: string
    calendarEventId: number
    getListStudents: () => Promise<void>
}

enum TabMakeup {
    TabMakeupAttendance = "TabMakeupAttendance",
    TabOtherReasons = "TabOtherReasons"
}

export function AddStudentPopup(props: Props) {
    const {
        isShow,
        timezone,
        onClose,
        lessonDetail,
        studentProfileIds = [],
        termId,
        courseId,
        calendarEventId,
        getListStudents
    } = props
    const [selectedTab, setSelectedTab] = useState(TabMakeup.TabMakeupAttendance)
    const [selectedStudent, setSelectedStudent] = useState<AcademicInstructional.StudentInfo>()
    const [students, setStudents] = useState([])
    const [search, setSearch] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [searchDebounce, setSearchDebounce] = useState("")
    const [reason, setReason] = useState("")
    const [listLessons, setListLessons] = useState([])

    useDebounce(() => setSearchDebounce(search), 400, [search])

    const getListStudent = useCallback(async () => {
        if (searchDebounce === "") {
            setStudents([])
            setTotal(0)
            return
        }
        try {
            let {data} = await profileService.getAll({
                filter: {
                    type: ["student"],
                    search: searchDebounce,
                    state: [Auth.UserProfileState.Student, Auth.UserProfileState.Alumni],
                    campuses: get(lessonDetail, ["campusIds"], []),
                    excludeProfileIds: studentProfileIds
                },
                range: {
                    limit: UNLIMITED_PAGE_SIZE,
                    offset: 0
                }
            })
            data = data.map((student) => ({
                ...student,
                profileId: student.id
            }))

            setTotal(data.length)
            setStudents(data)
        } catch (e) {
            handleError(e)
        }
    }, [searchDebounce, lessonDetail, studentProfileIds])

    useEffect(() => {
        getListStudent()
    }, [getListStudent])

    const onSelected = useCallback((student: AcademicInstructional.StudentInfo) => {
        setSelectedStudent(student)
    }, [])

    const onClickAdd = useCallback(async () => {
        try {
            setIsLoading(true)
            const targetLesson = listLessons.find((item) => item.isChecked)?.lesson
            if (selectedTab === TabMakeup.TabMakeupAttendance) {
                if (targetLesson) {
                    await academicPlansService.addStudent({
                        academicMakeupStudents: [
                            {
                                calendarEventId,
                                termId,
                                courseId,
                                lessonId: lessonDetail.id,
                                scheduleId: lessonDetail.scheduleId,
                                studentProfileId: selectedStudent.profileId,
                                type: AcademicPlans.MakeupStudentType.MakeupAttendance,
                                reason: undefined,
                                targetLesson: {
                                    lessonId: targetLesson.id,
                                    courseId: targetLesson.courseId,
                                    termId: targetLesson.termId,
                                    scheduleId: targetLesson.scheduleId
                                }
                            }
                        ]
                    })
                } else {
                    setIsLoading(false)
                    return toastError("Please select at least one lesson.")
                }
            } else {
                await academicPlansService.addStudent({
                    academicMakeupStudents: [
                        {
                            calendarEventId,
                            termId,
                            courseId,
                            lessonId: lessonDetail.id,
                            scheduleId: lessonDetail.scheduleId,
                            studentProfileId: selectedStudent.profileId,
                            type: AcademicPlans.MakeupStudentType.OtherReasons,
                            reason: reason
                        }
                    ]
                })
            }
            getListStudents()
            setSearch("")
            setSelectedStudent(undefined)
            setListLessons([])
            setReason("")
            onClose()
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }, [
        calendarEventId,
        courseId,
        getListStudents,
        lessonDetail,
        listLessons,
        onClose,
        reason,
        selectedStudent,
        selectedTab,
        termId
    ])

    const LessonDetail = useMemo(() => {
        return (
            <>
                <div className={styles.lessonTime}>
                    <span>
                        {moment(lessonDetail.startsAt).format("MM/DD/YYYY, HH:mm")} -{" "}
                        {moment(lessonDetail.endsAt).format("HH:mm")}
                    </span>
                </div>

                <LessonInfo lessonDetail={lessonDetail} />
            </>
        )
    }, [lessonDetail])

    const ListStudents = useMemo(() => {
        return (
            <>
                {LessonDetail}
                <Search
                    className={styles.search}
                    value={search}
                    onChange={(newValue) => setSearch(newValue)}
                    placeholder="Search"
                />
                <div className={styles.listStudents}>
                    <div className={styles.listStudentHeader}>
                        <div className={styles.totalStudent}>
                            <span className={styles.totalStudent__count}>{total}</span>
                            <span className={styles.totalStudent__title}>Students</span>
                        </div>
                    </div>
                    <div className={cx("custom-scroll", styles.listStudent)}>
                        <div className={styles.studentsBody}>
                            {students.map((student, index) => (
                                <StudentInfo
                                    index={index}
                                    key={student.profileId}
                                    student={student}
                                    onSelected={onSelected}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </>
        )
    }, [LessonDetail, onSelected, search, students, total])

    const DetailMakeupInfo = useMemo(() => {
        return (
            <>
                <p className={styles.subTitle}>ADD STUDENT</p>
                <StudentDetail studentDetail={selectedStudent} />
                <p className={styles.subTitle}>TO A CLASS</p>
                {LessonDetail}
                <div className={styles.tabButton}>
                    <div
                        onClick={() => setSelectedTab(TabMakeup.TabMakeupAttendance)}
                        className={cx(styles.tab, {
                            [styles.tabSelected]: selectedTab === TabMakeup.TabMakeupAttendance
                        })}>
                        <span
                            className={cx(styles.tabLabel, {
                                [styles.tabLabelSelected]: selectedTab === TabMakeup.TabMakeupAttendance
                            })}>
                            Makeup attendance
                        </span>
                    </div>
                    <div
                        onClick={() => setSelectedTab(TabMakeup.TabOtherReasons)}
                        className={cx(styles.tab, {
                            [styles.tabSelected]: selectedTab === TabMakeup.TabOtherReasons
                        })}>
                        <span
                            className={cx(styles.tabLabel, {
                                [styles.tabLabelSelected]: selectedTab === TabMakeup.TabOtherReasons
                            })}>
                            Other reasons
                        </span>
                    </div>
                </div>
                {selectedTab === TabMakeup.TabMakeupAttendance ? (
                    <ListLessons
                        studentProfileId={selectedStudent?.profileId}
                        setListLessons={setListLessons}
                        campusIds={get(lessonDetail, ["campusIds"], [])}
                        timezone={timezone}
                    />
                ) : (
                    <OtherReasons reason={reason} setReason={setReason} />
                )}
            </>
        )
    }, [selectedStudent, LessonDetail, selectedTab, lessonDetail, timezone, reason])

    return (
        <BasePopup
            isShow={isShow}
            onClose={() => {
                setSearch("")
                setSelectedStudent(undefined)
                setListLessons([])
                setReason("")
                onClose()
            }}
            width="70vw"
            leftIcon="PERSON_ADD">
            <div className={styles.wrap}>
                <p className={styles.title}>Add Student</p>
                {selectedStudent ? DetailMakeupInfo : ListStudents}
                <div className={styles.action}>
                    {selectedStudent ? (
                        <BaseButton
                            title="Cancel"
                            variant="secondary"
                            loading={isLoading}
                            className={styles.cancelButton}
                            onClick={() => {
                                setSelectedStudent(undefined)
                            }}
                        />
                    ) : (
                        <div />
                    )}
                    <BaseButton title="Add" variant="primary" loading={isLoading} onClick={onClickAdd} />
                </div>
            </div>
        </BasePopup>
    )
}
