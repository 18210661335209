/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useReducer, useState} from "react"
import {Col, Row} from "antd"
import moment from "moment"
import {useTranslation} from "react-i18next"
import {get, isEmpty} from "lodash"
import debounce from "debounce-promise"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {Icon} from "components/Icon"
import {KlassDropAsyncPaginate} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {attendanceService, campusesService, majorService} from "services"
import {formatCodeName, formatDateOnly, getFullName, handleError, roundTwoDigits} from "helpers"
import {useFilterStorage, useModel, useVisible} from "hooks"
import {ReschedulePopup} from "../ReschedulePopup"
import styles from "./../common.module.css"
import missedAttendanceStyles from "./MissedAttendanceSection.module.css"
import {Course} from "types/courses"
import {AttendanceStatus, DailyAttendanceViewType} from "types/attendance"
import {useDebounce} from "react-use"
import {FilterKey} from "types/filter"

function reducer(state, action) {
    return {...state, ...action}
}

const INITIAL_FILTER = {
    date: null,
    campuses: [],
    department: null,
    search: "",
    majorVersions: []
}

function MissedAttendance(props) {
    const {
        data,
        studentProfileId,
        page,
        total,
        pageSize,
        columns,
        fields,
        allFields,
        scheduleId,
        isLoading,
        termIds,
        dispatch,
        courseId,
        attendanceTrackingType
    } = props
    const {t} = useTranslation(["studentServices"])
    const isDailyAttendance = attendanceTrackingType === Course.ProgramAttendanceTrackingType.Daily
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        !isDailyAttendance ? FilterKey.MissedClassroomAttendance : FilterKey.MissedDailyAttendance,
        INITIAL_FILTER
    )
    const initialState = {
        count: {
            missedHours: 0,
            makeUpHours: 0,
            remainingHours: 0,
            absentRangeFiveToTen: 0,
            absentRangeTenToFourteen: 0,
            absentRangeMoreThanFourteen: 0
        }
    }
    const [{count}, dispatchState] = useReducer(reducer, initialState)
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const timeFormat = model.getUserTimeFormat()
    const reschedulePopup = useVisible()
    const [searchDebounce, setSearchDebounce] = useState(searchValue)
    useDebounce(() => setSearchDebounce(searchValue), 500, [searchValue])

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getMissedAttendance},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getMissedAttendanceStats()
    }, [scheduleId, searchDebounce, isDailyAttendance])

    useEffect(() => {
        getMissedAttendance()
    }, [page, pageSize, scheduleId, searchDebounce])

    const getFilterParams = () => {
        const profileId = get(model, "user.profiles[0].id")
        let studentProfileIds = []
        if (studentProfileId) {
            studentProfileIds = [parseInt(studentProfileId)]
        } else if (!model.isStaffOrAdmin() && profileId) {
            studentProfileIds = [profileId]
        }
        const params: any = {
            search: searchDebounce || undefined,
            courseIds: courseId ? [courseId] : undefined,
            majorVersionIds: !isEmpty(currentFilter.majorVersions)
                ? currentFilter.majorVersions.map((majorVersion) => majorVersion.id)
                : undefined,
            campusIds: !isEmpty(currentFilter.campuses) ? currentFilter.campuses.map((campus) => campus.id) : undefined,
            programIds: !isEmpty(currentFilter.programs)
                ? currentFilter.programs.map((program) => program.id)
                : undefined,
            instructorProfileIds: !isEmpty(currentFilter.instructors)
                ? currentFilter.instructors.map((instructor) => instructor.profileId)
                : undefined,
            studentProfileIds: studentProfileIds,
            date: !!currentFilter.date ? moment(currentFilter.date).format("YYYY-MM-DD") : undefined
        }

        if (scheduleId) {
            params.scheduleId = scheduleId
        }
        if (attendanceTrackingType) {
            params.majorTrackingType = attendanceTrackingType
        }
        if (termIds?.length) {
            params.termIds = termIds
        }
        return params
    }

    const getMissedAttendanceStats = async () => {
        try {
            const params = getFilterParams()
            if (isDailyAttendance) {
                const count = await attendanceService.getMissedDailyAttendanceStats(params)
                dispatchState({count})
            } else {
                const count = await attendanceService.getMissedAttendanceStats(params)
                dispatchState({count})
            }
        } catch (error) {
            handleError(error)
        }
    }

    function renderAttendHtml(status, isVerified) {
        if (isVerified === "yes" && status === AttendanceStatus.Rejected) {
            return (
                <div className={styles.attClose}>
                    <Icon icon="CROSS_CIRCLE" />
                </div>
            )
        }
        if (isVerified === "yes") {
            return (
                <div className={styles.attCheckVerified}>
                    <Icon icon="CHECKED" />
                </div>
            )
        }
        switch (status) {
            case AttendanceStatus.Approved:
                return (
                    <div className={styles.attCheck}>
                        <Icon icon="CHECKED" />
                    </div>
                )
            case AttendanceStatus.Irregular:
                return (
                    <div className={styles.actionWarning}>
                        <Icon icon="WARNING_TRIANGLE_FILL" />
                    </div>
                )
            case AttendanceStatus.Rejected:
                return (
                    <div className={styles.attClose}>
                        <Icon icon="CROSS_CIRCLE" />
                    </div>
                )
            case AttendanceStatus.InClass:
            default:
                return null
        }
    }

    const getMissedAttendance = async () => {
        try {
            dispatch({isLoading: true})

            const params = getFilterParams()
            const {data, total} =
                params.majorTrackingType === Course.ProgramAttendanceTrackingType.Daily
                    ? await attendanceService.getMissedDailyAttendanceList({...params, page, pageSize})
                    : await attendanceService.getMissedAttendanceList({...params, page, pageSize})
            const newData = data.map((item, index) => {
                if (isDailyAttendance) {
                    return {
                        attendanceId: item.id,
                        missedHours: `${(item.missedHours ?? 0) * 60}`,
                        makeUpHours: `${(item.makeUpHours ?? 0) * 60}`,
                        attendedHours: `${(item.attendedHours ?? 0) * 60}`,
                        dateHtml: formatDateOnly(item.date, dateFormat),
                        customProfileId: item.student.customProfileId ?? "",
                        instructorNames: (item.instructors ?? [])
                            .map(function (instructor) {
                                return instructor.name
                            })
                            .join(","),
                        majorName: item.major?.name ?? "",
                        majorCode: item.major?.code ?? "",
                        studentName: getFullName(item.student),
                        attendHtml: renderAttendHtml(item.status, item.isVerified)
                    }
                }
                item.attendanceStatus = item.status
                item.attendanceMessage = item.message
                item.isExcused = item.isExcusedAbsence
                item.attendanceTracking =
                    item.termCourseScheduleDetail?.attendanceTracking === Course.AttendanceTrackingType.ClassAttendance
                        ? "Classroom"
                        : item.termCourseScheduleDetail?.attendanceTracking === Course.AttendanceTrackingType.ClockHour
                        ? "Clock Hour"
                        : ""
                item.termCourseCode = item?.termCourseScheduleDetail?.termCourseCode ?? ""
                item.scheduleSuffix = item?.termCourseScheduleDetail?.scheduleSuffix ?? ""
                return {
                    ...item,
                    id: item.attendanceId,
                    scheduledHours: `${roundTwoDigits((item.scheduledHours ?? 0) * 60)}`,
                    missedHours: `${roundTwoDigits((item.missedHours ?? 0) * 60)}`,
                    makeUpHours: `${roundTwoDigits((item.makeUpHours ?? 0) * 60)}`,
                    attendedHours: `${roundTwoDigits((item.attendedHours ?? 0) * 60)}`,
                    instructorNames: item.instructors,
                    attendHtml: renderAttendHtml(item.status, item.isVerified)
                }
            })
            props.dispatch({data: newData, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const getStatusHtml = (missedAttendanceCompletedAt) => {
        return missedAttendanceCompletedAt ? (
            <div className={styles.statusWrapper}>
                <Icon icon="CHECKED" />
                <span>{moment(missedAttendanceCompletedAt).format(dateFormat)}</span>
            </div>
        ) : (
            <div className={styles.noStatusWrapper}>
                <Icon icon="CHECKED" />
            </div>
        )
    }

    // const getAssignedHtml = (assignedTo, assignedType) => {
    //     let color = ""
    //     switch (assignedType) {
    //         case "Registrar":
    //             color = "--secondary-500"
    //             break
    //         case "Student Affairs":
    //             color = "--success-500"
    //             break
    //         case "Student":
    //             color = "--primary-400-base"
    //             break
    //         case "Instructor":
    //             color = "--black-200"
    //             break
    //         case "DOE":
    //             color = "--primary-500"
    //             break
    //         default:
    //             color = ""
    //             break
    //     }
    //     return (
    //         <div className={styles.assignedWrapper}>
    //             <span style={{background: `var(${color})`}} className={styles.assignedLabel}>
    //                 {assignedType}
    //             </span>
    //             <span>{assignedTo}</span>
    //         </div>
    //     )
    // }

    // const getForm = (form) => {
    //     return (
    //         <div>
    //             <Icon icon="FILE_TEXT" />
    //         </div>
    //     )
    // }

    // const onClickReschedule = () => {
    //     reschedulePopup.open()
    // }

    // const renderReschedule = () => {
    //     return (
    //         <div onClick={onClickReschedule}>
    //             <Icon icon="RESCHEDULE" />
    //         </div>
    //     )
    // }

    const getColumns = () => {
        if (isDailyAttendance) {
            return [
                {
                    title: t("attendance.table.date"),
                    field: "dateHtml"
                },
                {
                    title: t("attendance.table.studentId"),
                    field: "customProfileId",
                    headerStyle: {minWidth: "150px"}
                },
                {
                    title: t("attendance.table.name"),
                    field: "studentName",
                    headerStyle: {minWidth: "150px"}
                },
                {
                    title: t("attendance.table.majorCode"),
                    field: "majorCode",
                    headerStyle: {minWidth: "150px"}
                },
                {
                    title: t("attendance.table.majorName"),
                    field: "majorName",
                    headerStyle: {minWidth: "150px"}
                },
                {
                    title: t("attendance.table.attendedHours"),
                    field: "attendedHours",
                    headerStyle: {minWidth: "80px"}
                },
                {
                    title: t("attendance.table.missedHours"),
                    field: "missedHours",
                    headerStyle: {minWidth: "80px"}
                },
                {
                    title: t("attendance.table.makeUpHours"),
                    field: "makeupHours",
                    headerStyle: {minWidth: "80px"}
                },
                {
                    title: t("attendance.table.attend"),
                    field: "attendHtml",
                    headerStyle: {minWidth: "80px"}
                }
            ]
        }
        return [
            {
                title: t("attendance.table.date"),
                field: "date",
                fieldType: "date",
                format: dateFormat
            },
            {
                title: t("attendance.table.dot"),
                field: "dayOfTerm",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.studentId"),
                field: "customProfileId",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.name"),
                field: "studentName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.id"),
                field: "termCourseCode",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: "Schedule Id",
                field: "scheduleSuffix",
                headerStyle: {minWidth: "120px"}
            },
            {
                title: t("attendance.table.course"),
                field: "lessonName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.type"),
                field: "lessonType",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.scheduledHours"),
                field: "scheduledHours",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.attendedHours"),
                field: "attendedHours",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.missedHours"),
                field: "missedHours",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.makeUpHours"),
                field: "makeUpHours",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.attend"),
                field: "attendHtml",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.instructor"),
                field: "instructorNames",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.attendanceTracking"),
                field: "attendanceTracking",
                headerStyle: {minWidth: "200px"}
            }
        ]
    }

    function getFields() {
        if (isDailyAttendance) {
            return [
                t("attendance.table.date"),
                t("attendance.table.studentId"),
                t("attendance.table.name"),
                t("attendance.table.majorCode"),
                t("attendance.table.majorName"),
                t("attendance.table.attendedHours"),
                t("attendance.table.missedHours"),
                t("attendance.table.makeUpHours"),
                t("attendance.table.attend")
            ]
        }
        return [
            t("attendance.table.date"),
            t("attendance.table.dot"),
            t("attendance.table.studentId"),
            t("attendance.table.name"),
            t("attendance.table.id"),
            "Schedule Id",
            t("attendance.table.course"),
            t("attendance.table.type"),
            t("attendance.table.scheduledHours"),
            t("attendance.table.attendedHours"),
            t("attendance.table.missedHours"),
            t("attendance.table.makeUpHours"),
            t("attendance.table.attend"),
            t("attendance.table.instructor"),
            t("attendance.table.attendanceTracking")
        ]
    }

    const onClickRow = () => {}

    const onClickClearFilter = () => {
        clearFilter()
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    const onSearchInput = (search) => {
        changeSearch(search)
    }

    const onApplyFilter = () => {
        applyFilter()
        getMissedAttendance()
        getMissedAttendanceStats()
    }

    const onCampusSearch = async (campus: string, loadedOptions) => {
        try {
            const {data: campuses, total} = await campusesService.getAll({
                fields: ["id", "name", "code"],
                filter: {
                    search: campus
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                }
            })
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onProgramSearch = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: programs, total} = await majorService.getAll({
                filter: {
                    search
                },
                range: {
                    page,
                    pageSize
                }
            })
            const programOptions = programs.map((program) => ({
                ...program,
                name: `${program.code} - ${program.name}`
            }))
            return {
                options: programOptions,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onMajorVersionSearch = async (search = "") => {
        try {
            const data = await attendanceService.getDailyAttendanceStatsNew({
                viewType: DailyAttendanceViewType.ViewByProgram
            })
            const majorVersions = data.map((majorVersion) => ({
                ...majorVersion,
                name: `${majorVersion.versionCode} - ${majorVersion.majorName}`
            }))
            return {
                options: majorVersions,
                hasMore: false
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const debounceCampuses = useCallback(debounce(onCampusSearch, 300), [])
    const debouncePrograms = useCallback(debounce(onProgramSearch, 300), [])
    const debounceMajorVersions = useCallback(debounce(onMajorVersionSearch, 300), [])

    return (
        <div className={styles.wrap}>
            <BaseNewFilter
                filter={currentFilter}
                onClick={onApplyFilter}
                searchValue={searchValue}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <BaseDatePicker
                            onChange={(newValue) => onChangeFilter("date", newValue)}
                            value={currentFilter.date ? moment(currentFilter.date) : ""}
                        />
                    </Col>
                    <Col span={12}>
                        <KlassDropAsyncPaginate
                            value={currentFilter.campuses}
                            onChange={(newValue) => onChangeFilter("campuses", newValue)}
                            loadOptions={debounceCampuses}
                            isMulti
                            placeholder={t("filter.campus")}
                            getOptionLabel={formatCodeName}
                        />
                    </Col>
                    <Col span={12}>
                        {isDailyAttendance ? (
                            <KlassDropAsyncPaginate
                                value={currentFilter.majorVersions}
                                onChange={(newValue) => onChangeFilter("majorVersions", newValue)}
                                loadOptions={debounceMajorVersions}
                                labelKey={"name"}
                                isMulti
                                placeholder="Major Version"
                            />
                        ) : (
                            <KlassDropAsyncPaginate
                                value={currentFilter.programs}
                                onChange={(newValue) => onChangeFilter("programs", newValue)}
                                loadOptions={debouncePrograms}
                                getOptionLabel={(option) => `${option.name} (${option.code})`}
                                isMulti
                                placeholder="Program"
                            />
                        )}
                    </Col>
                </Row>
            </BaseNewFilter>
            <Row gutter={16}>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.missedHoursColor}`}>
                                <Icon icon="PERSON_DONE" color="var(--black-base)" />
                            </div>
                            <p className={styles.cardTitle}>{t("attendance.missedAttendance.missedHours")}</p>
                        </div>
                        <p className={styles.cardValue}>{count.missedHours}</p>
                    </div>
                </Col>

                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.makeUpColor}`}>
                                <Icon icon="CHECKED" />
                            </div>
                            <p className={styles.cardTitle}>{t("attendance.missedAttendance.makeUp")}</p>
                        </div>
                        <p className={styles.cardValue}>{count.makeUpHours}</p>
                    </div>
                </Col>

                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.remainedColor}`}>
                                <Icon icon="CHECKED" />
                            </div>
                            <p className={styles.cardTitle}>{t("attendance.missedAttendance.remaining")}</p>
                        </div>
                        <p className={styles.cardValue}>{count.remainingHours}</p>
                    </div>
                </Col>
                {attendanceTrackingType === Course.ProgramAttendanceTrackingType.Course ? (
                    <Col span={6}>
                        <div className={styles.cardWrap}>
                            <div className={styles.titleWrap}>
                                <p className={styles.cardTitle} style={{marginLeft: 10}}>
                                    {t("attendance.missedAttendance.activitiesCourse")}
                                </p>
                            </div>
                            <div className={missedAttendanceStyles.absentStats}>
                                <div className={missedAttendanceStyles.activitiesCourseItem}>
                                    <p className={missedAttendanceStyles.activitiesCourseText}>5-10</p>
                                    <p className={styles.cardValue}>{count.absentRangeFiveToTen}</p>
                                </div>
                                <div className={missedAttendanceStyles.activitiesCourseItem}>
                                    <p className={missedAttendanceStyles.activitiesCourseText}>10-14</p>
                                    <p className={styles.cardValue}>{count.absentRangeTenToFourteen}</p>
                                </div>
                                <div className={missedAttendanceStyles.activitiesCourseItem}>
                                    <p className={missedAttendanceStyles.activitiesCourseText}>{">14"}</p>
                                    <p className={styles.cardValue}>{count.absentRangeMoreThanFourteen}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                ) : null}
            </Row>

            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onChangeAllFields={props.onChangeAllFields}
                        onDraggableColumn={props.onDraggableColumn}
                    />
                    <KlassappTable
                        isShowCheckedColumn={false}
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        onClickRowItem={onClickRow}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                        onDraggableColumn={props.onDraggableColumn}
                        onChangeAllFields={props.onChangeAllFields}
                    />
                </Col>
            </Row>
            <ReschedulePopup isShow={reschedulePopup.isVisible} onClose={reschedulePopup.close} />
        </div>
    )
}

export const MissedAttendanceSection = KlassappTableHOC(MissedAttendance)
