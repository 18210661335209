/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import {useVisible} from "hooks"
import {get} from "lodash"
import {handleError, displaySince} from "helpers"
import styles from "./PersonalFeed.module.css"
import DefaultLogo from "assets/images/png/EdularLogoRectangle.png"
import {BasePopup} from "components/popup"
import {Icon} from "components/Icon"
import {Branding} from "types/branding"
import {newsFeedPostsService} from "services"
import {Link, useHistory} from "react-router-dom"
import MediaViewer from "./parts/MediaViewer/MediaViewer"

export function PersonalFeed(props) {
    const [activeItem, setActiveItem] = useState(null)
    const [feedData, setFeedData] = useState([])
    const [hasMoreFeed, setHasMoreFeed] = useState<boolean>(true)
    const mediaViewerPopup = useVisible(false)

    const personalFeedPopup = useVisible(false)
    const history = useHistory()

    useEffect(() => {
        getData()
    }, [])

    const onClickItem = (item) => {
        onCloseMediaViewer()
        setActiveItem(item)
        personalFeedPopup.open()
    }

    const onClosePopup = () => {
        setActiveItem(null)
        personalFeedPopup.close()
    }

    const getBrandingImg = () => {
        const rectangularImg = get(
            props.model,
            ["clientSetting", "branding", Branding.BrandingType.OfficialLogoRectangular],
            null
        )
        const squareImg = get(
            props.model,
            ["clientSetting", "branding", Branding.BrandingType.OfficialLogoSquare],
            null
        )
        return squareImg || rectangularImg || DefaultLogo
    }

    const logo = getBrandingImg()

    const getData = async () => {
        try {
            const pageSize = 10

            const {data, total} = await newsFeedPostsService.listMyFeed({
                range: {
                    page: Math.ceil(feedData.length / 10) + 1,
                    pageSize
                }
            })
            let newData = data.map((item) => ({
                ...item,
                id: item.postId
            }))
            const finalData = feedData.concat(newData)
            setHasMoreFeed(finalData.length < total)
            setFeedData(finalData)
        } catch (error) {
            handleError(error)
        }
    }

    const viewSource = () => {
        if (activeItem) {
            const origin = window.location.origin
            if (activeItem.chatChannelId && activeItem?.sourceUrl?.startsWith(origin)) {
                history.push(activeItem.sourceUrl.replace(origin, ""))
            } else {
                const url = new URL(activeItem.sourceUrl)
                window.open(url.href, "_blank")
            }
        }
    }

    const onClickMedia = () => {
        mediaViewerPopup.open()
    }

    const onCloseMediaViewer = () => {
        mediaViewerPopup.close()
    }
    return (
        <>
            {!feedData.length ? (
                <div className={styles.noFeedContainer}>
                    <span>No feed found</span>
                </div>
            ) : (
                <>
                    <PerfectScrollbar className={styles.wrap} onYReachEnd={() => hasMoreFeed && getData()}>
                        {feedData.map((personalFeed) => (
                            <div
                                key={personalFeed.id}
                                className={styles.item}
                                onClick={() => onClickItem(personalFeed)}>
                                <img src={personalFeed.imageUrl || logo} className={styles.image} alt="" />
                                <div className={styles.info}>
                                    <p className={styles.title}>{personalFeed.title}</p>
                                    <p
                                        className={styles.description}
                                        dangerouslySetInnerHTML={{
                                            __html: personalFeed.previewText
                                        }}
                                    />
                                    {personalFeed.authorName && (
                                        <p className={styles.authorName}>
                                            <Icon icon="USER" />
                                            {personalFeed.authorName}
                                        </p>
                                    )}
                                    <p className={styles.date}>
                                        {displaySince(personalFeed.postCreatedAt, "MM/DD/YYYY hh:mm A")}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </PerfectScrollbar>
                    <BasePopup
                        isShow={personalFeedPopup.isVisible}
                        onClose={onClosePopup}
                        leftIcon="NEWS"
                        leftIconColor="#fff"
                        width="80vw">
                        <div className={styles.wrapPopup}>
                            <p className={styles.headerPopup}>Feed &amp; News</p>
                            <div className={styles.bodyPopup}>
                                <p className={styles.titlePopup}>{activeItem?.title}</p>
                                <div className={styles.content}>
                                    <div className={styles.moreInfoWrap}>
                                        {activeItem?.authorName && (
                                            <div className={styles.authorName}>
                                                <Icon icon="USER" />
                                                <span className={styles.moreInfoText}>{activeItem?.authorName}</span>
                                            </div>
                                        )}
                                        <div className={styles.moreInfo}>
                                            <Icon icon="CALENDAR_FILL_INPUT" />
                                            <span className={styles.moreInfoText}>
                                                {activeItem?.postCreatedAt &&
                                                    displaySince(activeItem.postCreatedAt, "MM/DD/YYYY hh:mm A")}
                                            </span>
                                        </div>
                                    </div>
                                    {activeItem?.imageUrl && (
                                        <div className={styles.imageWrap}>
                                            <img
                                                src={activeItem?.imageUrl}
                                                className={styles.imagePopup}
                                                alt=""
                                                onClick={() => onClickMedia()}
                                            />
                                        </div>
                                    )}
                                    <div dangerouslySetInnerHTML={{__html: activeItem?.text}} />
                                </div>
                                {activeItem?.sourceUrl && (
                                    <div className={styles.actionPopup}>
                                        <div className={styles.viewSource} onClick={viewSource}>
                                            <span className={styles.viewSourceText}>View Source</span>
                                            <Icon icon="BACK" className={styles.viewSourceIcon} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </BasePopup>
                    {activeItem?.imageUrl && (
                        <MediaViewer
                            isShow={mediaViewerPopup.isVisible}
                            onClose={onCloseMediaViewer}
                            url={activeItem?.imageUrl}
                        />
                    )}
                </>
            )}
        </>
    )
}
