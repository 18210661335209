import React, {useReducer, useState} from "react"
import {useTranslation} from "react-i18next"
import {Checkbox, Row, Col, Radio, Divider} from "antd"
import cx from "classnames"
import {BasePopup} from "components/popup"
import {FormLabel} from "components/Form"
import {BaseButton, SecondaryButton} from "components/buttons"
import {KlassDropAsyncPaginate} from "components/Select"
import {studentService, settingChecklistService, linksService} from "services"
import {handleError} from "helpers"
import CheckListTable from "./CheckListTable"
import styles from "./AddStudentChecklistPopup.module.css"
import {Task} from "types/tasks"
import {StudentActivity} from "types/activity"
import LinksSection from "../LinksSection"
import {UserDocument} from "types/user-documents"

function reducer(state, action) {
    return {...state, ...action}
}

enum AddChecklistType {
    AddNewItem = "AddNewItem",
    AddFromChecklistSettings = "AddFromChecklistSettings"
}

export function AddStudentChecklistPopup(props) {
    const initialState = {
        checklistData: {
            name: "",
            checklistItemNameId: null,
            isVisibleForStudent: false,
            isDocumentUploadRequired: false
        },
        type: AddChecklistType.AddNewItem,
        isSubmitting: false
    }
    const [{checklistData, type, isSubmitting}, dispatch] = useReducer(reducer, initialState)
    const {isShow, onClose, profileId, departmentId, onCreateSuccess, student, studentCheklist} = props
    const {t} = useTranslation(["common"])
    const [isArchiveAll, setIsArchiveAll] = useState(false)
    const [linkedTasks, setLinkedTasks] = useState<Task[]>([])
    const [linkedActivities, setlinkedActivities] = useState<StudentActivity[]>([])
    const [linkedDocuments, setlinkedDocuments] = useState<UserDocument[]>([])

    const onChangeData = (key, value) => {
        checklistData[key] = value
        dispatch({checklistData})
    }

    const saveLinks = React.useCallback(
        async (studentChecklistItemId: number) => {
            return await linksService.saveChecklistItemLink(studentChecklistItemId, {
                studentActivityIds: linkedActivities.map((item) => item.studentActivityId),
                taskIds: linkedTasks.map((item) => item.taskId),
                userDocumentIds: linkedDocuments.map((item) => item.userDocumentId)
            })
        },
        [linkedActivities, linkedTasks, linkedDocuments]
    )

    const onSave = async () => {
        const data = {
            ...checklistData,
            profileId,
            isDocumentUploadRequired: !!checklistData.isDocumentUploadRequired,
            isVisibleForStudent: !!checklistData.isVisibleForStudent,
            departmentId,
            isRequired: true
        }
        try {
            dispatch({isSubmitting: true})
            const createdStudentChecklistItem = await studentService.createStudentChecklistCustom(data)
            if (createdStudentChecklistItem?.studentChecklistItemId) {
                try {
                    await saveLinks(createdStudentChecklistItem.studentChecklistItemId)
                } catch (e) {
                    console.error(e)
                }
            }
            onSaveSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isSubmitting: false})
        }
    }

    const onSaveSuccess = () => {
        onClose()
        if (onCreateSuccess) {
            onCreateSuccess()
        }
    }

    const onChangeType = (newType) => {
        dispatch({type: newType})
    }

    const onChecklistItemNameSearchChange = async (search = "", loadedOptions) => {
        try {
            const {profiles} = student
            const userCampuses = profiles.map(({campuses}) => campuses.map(({id}) => id))
            const campusIds = userCampuses.flatMap((id) => id)
            const pageSize = 10
            const page = Math.ceil(loadedOptions.length / pageSize) + 1

            let {data: checklistItemNames, total} = await settingChecklistService.getAllChecklistItemNames({
                filter: {
                    search,
                    departmentIds: [departmentId],
                    ...(campusIds.length && {campusIds})
                },
                range: {
                    page,
                    pageSize
                }
            })

            const fetchedResultCount: number = checklistItemNames.length || 0

            if (search.trim() !== "") {
                let isExists: boolean = false

                if (checklistItemNames.length) {
                    checklistItemNames.forEach((checklistItemName) => {
                        if (checklistItemName.name.toLowerCase() === search.toLowerCase()) {
                            isExists = true
                        }
                    })
                }

                if (!isExists) {
                    checklistItemNames = [
                        ...new Set([
                            {
                                checklistItemNameId: null,
                                name: search
                            },
                            ...checklistItemNames
                        ])
                    ]
                }
            }
            const studentCheklistNameIds = studentCheklist.map((checklist) => checklist.checklistItemNameId)

            return {
                options: checklistItemNames.filter(
                    (checklist) => !studentCheklistNameIds.includes(checklist.checklistItemNameId)
                ),
                hasMore: loadedOptions.length + fetchedResultCount < total
            }
        } catch (e) {
            handleError(e)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const renderAddNewItem = () => {
        return (
            <>
                <div className={styles.formItemWrap}>
                    <FormLabel label={t("checklist.name")} />
                    <KlassDropAsyncPaginate
                        value={{...checklistData} || null}
                        onChange={(selectedItem) => {
                            onChangeData("name", selectedItem.name ?? null)
                            onChangeData("checklistItemNameId", selectedItem.checklistItemNameId ?? null)
                        }}
                        valueKey="checklistItemNameId"
                        loadOptions={onChecklistItemNameSearchChange}
                    />
                </div>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <div className={cx(styles.formItemWrap, styles.formItemFlex)}>
                            <FormLabel label={t("checklist.visibleForStudent")} className={styles.formLabel} />
                            <Checkbox
                                checked={checklistData.isVisibleForStudent}
                                onChange={(event) => onChangeData("isVisibleForStudent", event.target.checked)}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className={cx(styles.formItemWrap, styles.formItemFlex)}>
                            <FormLabel label={t("checklist.documentUpload")} className={styles.formLabel} />
                            <Checkbox
                                checked={checklistData.isDocumentUploadRequired}
                                onChange={(event) => onChangeData("isDocumentUploadRequired", event.target.checked)}
                            />
                        </div>
                    </Col>
                </Row>

                <Divider className={styles.divider} />

                <LinksSection
                    profileIds={[profileId]}
                    maxTasks={1}
                    tasks={linkedTasks}
                    onTasksChange={setLinkedTasks}
                    maxActivities={1}
                    activities={linkedActivities}
                    onActivitiesChange={setlinkedActivities}
                    maxDocuments={1}
                    documents={linkedDocuments}
                    onDocumentsChange={setlinkedDocuments}
                />

                <div className={styles.action}>
                    <SecondaryButton
                        title={t("action.cancel")}
                        onClick={onClose}
                        className={styles.cancelBtn}
                        disabled={isSubmitting}
                    />
                    <BaseButton title={t("action.save")} onClick={onSave} loading={isSubmitting} />
                </div>
            </>
        )
    }

    const renderAddChecklist = () => {
        const {profiles} = student
        const activeStudentProfiles = profiles.filter((profile) => profile.type === "student" && profile.active === 1)
        const profile = activeStudentProfiles[0] ?? null
        const userCampuses = profiles.map(({campuses}) => campuses.map(({id}) => id))
        const userCampusIds = userCampuses.flatMap((id) => id)

        return (
            <div>
                <div className={cx(styles.formItemWrap, styles.formItemFlex, styles.archiveOldChecklistContainer)}>
                    <span className={styles.archiveOldChecklistsLabel}>{t("checklist.archiveOldChecklists")}</span>
                    <Checkbox checked={isArchiveAll} onChange={(event) => setIsArchiveAll(event.target.checked)} />
                </div>
                <p className={styles.checklistTitle}>
                    Add Whole checklist
                    <span className={styles.checklistTitle__desc}>You can add 1 item at a time</span>
                </p>
                <CheckListTable
                    departmentId={departmentId}
                    profileId={profileId}
                    onSaveSuccess={onSaveSuccess}
                    onClose={onClose}
                    isArchiveAll={isArchiveAll}
                    campusIds={userCampusIds ?? []}
                    programIds={profile?.currentMajor?.id ? [profile?.currentMajor?.id] : []}
                    degreeLevelIds={profile?.degreeLevel?.degreeLevelId ? [profile?.degreeLevel?.degreeLevelId] : []}
                    payingMethodIds={profile?.payingMethods?.map((payingMethod) => payingMethod.payingMethodId) ?? []}
                    dependencyStatuses={profile?.dependencyStatus ? [profile?.dependencyStatus] : []}
                    enrollmentTypes={profile?.enrollmentType ? [profile?.enrollmentType] : []}
                    enrollmentCitizenshipStatuses={
                        profile?.enrollmentCitizenStatus ? [profile?.enrollmentCitizenStatus] : []
                    }
                />
            </div>
        )
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="EDIT_LINE" leftIconColor="#fff" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>{t("checklist.addStudentChecklist")}</p>
                <Radio.Group
                    onChange={(event) => onChangeType(event.target.value)}
                    value={type}
                    className={styles.radioBtnWrap}>
                    <Radio value={AddChecklistType.AddNewItem} className={styles.radioBtn__item}>
                        Add New Item
                    </Radio>
                    <Radio value={AddChecklistType.AddFromChecklistSettings} className={styles.radioBtn__item}>
                        Add Checklist
                    </Radio>
                </Radio.Group>
                {type === AddChecklistType.AddNewItem ? renderAddNewItem() : renderAddChecklist()}
            </div>
        </BasePopup>
    )
}
