import React, {useState} from "react"
import {BaseButton, BasePopup, SecondaryButton} from "components"
import styles from "./PopupVerifyGrade.module.css"
import {withTranslation} from "react-i18next"
import cx from "classnames"
import {academicCourseGradesService} from "services"
import {handleError, toastSuccess} from "helpers"
import {AttendanceStatus} from "types/attendance"

type PopupVerifyGradeProps = {
    isShowConfirmVerify: boolean
    onCancelConfirm: () => void
    refreshData: () => void
    termIds: number[]
    courseId: number
    scheduleId?: number
    t: Function
    data: Array<{
        passedValue: boolean
        gpaValue: string
        totalValue: string
        maxTotalValue: string
        isVerifiedValue: boolean
        studentProfileId: number
        alphaValue: number
        isChecked: boolean
        attendanceStatus: AttendanceStatus
        weightedAvgValue: string
        academicStudentStatsId: number
    }>
}

const PopupVerifyGrade = (props: PopupVerifyGradeProps) => {
    const {isShowConfirmVerify, onCancelConfirm, data = [], t, refreshData, courseId, termIds, scheduleId} = props
    const [loading, setLoading] = useState(false)

    const onPressVerify = async () => {
        setLoading(true)
        try {
            const payload = data.map((item) => ({
                academicStudentStatsId: item.academicStudentStatsId,
                weightedAvg: item.weightedAvgValue ? parseFloat(item.weightedAvgValue) : 0,
                courseId: courseId,
                passFail: item.passedValue,
                gpa: item.gpaValue ? parseFloat(item.gpaValue) : "",
                alphabetical: item.alphaValue,
                totalGrade: item.totalValue ? parseFloat(item.totalValue) : 0,
                maxTotalGrade: item.maxTotalValue ? parseFloat(item.maxTotalValue) : 0,
                isVerified: item.isChecked ? item.isChecked : !!item.isVerifiedValue,
                studentProfileId: item.studentProfileId,
                termId: termIds?.length ? termIds[0] : undefined,
                scheduleId: scheduleId
            }))
            await academicCourseGradesService.addManualGrade({
                listGrades: payload
            })
            await refreshData()
            toastSuccess("Verify Success")
            onCancelConfirm()
        } catch (err) {
            handleError(err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <BasePopup
            isShow={isShowConfirmVerify}
            onClose={onCancelConfirm}
            width="70vw"
            leftIcon="WARNING_SOLID"
            leftIconColor="#fff">
            <div>
                <div className={styles.popupTitle}>{t("common:confirmationPopup.confirmVerifyGrade")}</div>
                <span className={styles.smallTitle}>{t("common:confirmationPopup.smallTextVerifyGrade")}</span>
                <div>
                    <div className={cx(styles.actionDetailWrapPopup)}>
                        <div>
                            <SecondaryButton onClick={onCancelConfirm} title={t("common:action.cancelModal")} />
                        </div>
                        <div className={cx(styles.buttonPopupConfirm)}>
                            <BaseButton onClick={onPressVerify} title={t("common:action.confirm")} loading={loading} />
                        </div>
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}

export default withTranslation(["settings", "common"])(PopupVerifyGrade)
