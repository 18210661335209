import React, {useState} from "react"
import {Row, Col, Upload} from "antd"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseNewFilter} from "uiKit"
import {useTranslation} from "react-i18next"
import styles from "./Filter.module.css"
import {BaseButton} from "components"
import {RcFile} from "antd/lib/upload/interface"
import {isirService} from "services"
import {handleError} from "helpers"

type State = {
    dateReceived?: string
    search?: string
}

type Props = {
    updateFilter
    onChangeFilter
    onClearFilter
    filterObject?: State
    setFilterFormSearch
    onUploaded?: () => void
}

const initState: State = {
    dateReceived: "",
    search: ""
}

export function Filter(props: Props) {
    const {updateFilter, onChangeFilter, onClearFilter, filterObject, setFilterFormSearch, onUploaded} = props
    const {t} = useTranslation(["common"])

    const [inputFields, setInputFields] = useState(initState)
    const [formSearch, setFormSearch] = useState("")

    const onClickClear = async () => {
        setInputFields({...initState})
        onClearFilter()
    }

    const onSearchInput = (search) => {
        setFormSearch(search)
        setFilterFormSearch(search)
    }

    const [isUploading, setUploading] = React.useState(false)

    const handleFilesChange = React.useCallback(
        async (file: RcFile, files: RcFile[]) => {
            if (!files?.length) return
            setUploading(true)
            try {
                await isirService.isirUpload(files)
                onUploaded?.()
            } catch (error) {
                handleError(error)
            } finally {
                setUploading(false)
            }
        },
        [onUploaded]
    )

    return (
        <BaseNewFilter
            className={styles.filterContainer}
            searchValue={formSearch}
            onSearchInput={onSearchInput}
            onClick={onChangeFilter}
            onClickClear={onClickClear}
            filter={filterObject}
            renderRightFilter={() => (
                <div className="flex justify-end items-center">
                    <Upload multiple beforeUpload={handleFilesChange}>
                        <BaseButton title={t("isir.upload")} className={styles.addButton} loading={isUploading} />
                    </Upload>
                </div>
            )}>
            <Row gutter={[40, 32]}>
                <Col span={12}>
                    <BaseDatePicker
                        placeholder={t("isir.dateReceived")}
                        format="YYYY-MM-DD"
                        value={inputFields.dateReceived}
                        onChange={(date, dateString) => {
                            setInputFields((prevState) => ({...prevState, dateReceived: date}))
                            updateFilter({createdAt: dateString})
                        }}
                    />
                </Col>
            </Row>
        </BaseNewFilter>
    )
}
