import {AxiosInstance} from "axios"
import {EventDispatcher} from "./EventDispatcher"
import {getUrlParams} from "helpers"
import axiosModule from "axios"

export class Gate extends EventDispatcher {
    private axios: AxiosInstance
    private token: string | null = null
    public isTokenByUrl: boolean = false

    constructor() {
        super()
        this.axios = axiosModule.create()
        const {token} = getUrlParams()
        this.verifyTokenFromParam(token)
    }

    public setToken(token: string | null) {
        this.token = token
    }

    public getToken(): string | null {
        return this.token
    }

    verifyTokenFromParam(token) {
        this.isTokenByUrl = !!token
        this.token = token
    }

    public async request(urlPath: string, data: any = {}): Promise<GateResponse> {
        let url = urlPath

        url = process.env.REACT_APP_API_DOMAIN + urlPath

        let request: any = {
            method: "post",
            url: url,
            responseType: "json",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        }

        /**
         * If JWT exists in localstorage, adding "Authorization" header
         * with JWT from localstore.
         */
        if (this.token) {
            request.headers.Authorization = `Bearer ${this.token}`
        }

        if (data.method) {
            request.method = data.method
        }

        if (data.data) {
            request.data = data.data
        }

        console.log("-->> " + url, request)

        let errorMessage: string | null = null
        let responseData: any

        try {
            let response = await this.axios(request)
            responseData = response.data
            console.log("<<-- ", responseData)
        } catch (error: any) {
            if (error.response) {
                errorMessage = error.response.data.errors[0].message
                responseData = error.response.data
                console.log("<<-- ", errorMessage, responseData)
            } else {
                // Network level errorMessage. Server is down or something
                errorMessage = error.message
                responseData = {success: false, errors: [{message: errorMessage}]}
                console.log("<<-- ", errorMessage)
            }
        }

        this.dispatch(responseData)
        return responseData
    }
}

export type GateResponse<D = any> = {
    success: boolean
    errors?: GateError[]
    data?: D
    meta?: any
}

export type GateError = {
    field?: string
    data?: string
    message: string
}
