import React, {useEffect, useRef, useState} from "react"
import {BasePopup} from "components/popup"
import styles from "./ApplyJobForm.module.css"
import {Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {BaseButton, BaseEditor, BaseInput} from "components"
import {handleError, toastError, toastSuccess} from "helpers"
import {careerService} from "services"
import {CareerServices} from "types/careerServices"
import {useModel} from "hooks"
import {ReactComponent as FileIcon} from "./assets/file.svg"
import {ReactComponent as RemoveIcon} from "./assets/remove.svg"

type Props = {
    open: boolean
    type: "email" | "system"
    onClose: () => void
    job: CareerServices.Job
    onSuccess?: (application: CareerServices.Application) => void
}

export function ApplyJobForm(props: Props) {
    const {open, type, job, onClose, onSuccess} = props
    const [subject, setSubject] = useState<string>("")
    const [content, setContent] = useState<string>("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [errorFields, setErrorFields] = useState<any>([])
    const model = useModel()
    const fileRef = useRef<any>()
    const [attachments, setAttachments] = useState<{attachmentUrl: string; attachmentFileName: string}[]>([])
    const isEmailApplied = type === "email"

    useEffect(() => {
        job && setSubject(`Application ${job.title}`)
    }, [job])

    const validateBeforeSubmit = () => {
        let _errors = []
        if (!subject || subject.trim() === "") {
            _errors.push("subject")
        }
        if (!content || content.trim() === "") {
            _errors.push("content")
        }
        setErrorFields(_errors)
        return _errors.length > 0
    }

    const onSubmit = async () => {
        try {
            setIsSubmitting(true)
            if (validateBeforeSubmit()) {
                toastError("Check field errors.")
                return
            }
            const payload = {
                jobId: job.id,
                emailSubject: subject,
                emailContent: content,
                applyVia: type,
                attachments
            }
            const application = await careerService.applyJob(payload)
            if (onSuccess) onSuccess(application)
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onOpenChooseFile = () => {
        if (isSubmitting || isUploading) {
            return
        }
        fileRef.current.click()
    }

    const onClickUpload = (e) => {
        e.target.value = ""
    }

    const onChangeFile = async (e) => {
        if (isSubmitting || isUploading) {
            return
        }
        const files = e.target.files
        if (files.length > 0) {
            await submitDocument(files)
        }
    }

    const onClickRemoveDocument = async (item: any) => {
        const _attachments = attachments.filter((x) => x.attachmentUrl !== item.attachmentUrl)
        setAttachments(_attachments)
    }

    const submitDocument = async (files: FileList) => {
        try {
            setIsUploading(true)
            const formData = new FormData()
            Array.from(files).forEach((item) => {
                formData.append("files", item)
            })
            const data = await careerService.attachApplicationDocuments(formData)
            setAttachments(data)
            toastSuccess("Attach documents successful")
        } catch (error) {
            toastError("Attach documents failure")
        } finally {
            setIsUploading(false)
        }
    }

    const renderAttachments = () => {
        if (attachments.length <= 0) return

        return (
            <div className={styles.attachmentsWrap}>
                {attachments.map((item) => {
                    return (
                        <div className={styles.documentWrap} key={item.attachmentUrl}>
                            <FileIcon className={styles.fileIcon} width={24} height={24} />
                            <div className={styles.documentFileName}>{item.attachmentFileName}</div>
                            <RemoveIcon
                                className={styles.removeIcon}
                                width={24}
                                height={24}
                                onClick={() => onClickRemoveDocument(item)}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <BasePopup
            isShow={open}
            onClose={onClose}
            leftIcon="SEND_VIA_EMAIL"
            leftIconColor="#fff"
            width="60vw"
            closable
            destroyOnClose>
            <div className={styles.headingTitle}>Apply {isEmailApplied ? "via Email" : "Job"}</div>
            <Row className={styles.field} gutter={[0, 24]}>
                {isEmailApplied && (
                    <>
                        <Col span={24}>
                            <FormLabel label="Mail to" className={styles.fieldLabel} />
                            <BaseInput value={job.emailAppliedAddress} disabled />
                        </Col>
                        <Col span={24}>
                            <FormLabel label="Sender" className={styles.fieldLabel} />
                            <BaseInput value={model.getUserEmail()} disabled />
                        </Col>
                    </>
                )}
                <Col span={24}>
                    <FormLabel label="Subject" className={styles.fieldLabel} />
                    <BaseInput value={subject} onChange={setSubject} error={errorFields.includes("subject")} />
                </Col>
                <Col span={24}>
                    <FormLabel
                        label={isEmailApplied ? "Mail" : "Application description"}
                        className={styles.fieldLabel}
                    />
                    {isEmailApplied && <div className={styles.fieldDescription}>Create email using the form below</div>}
                    <BaseEditor value={content} onChange={setContent} defaultValue={content} />
                </Col>
                <Col span={24}>
                    <FormLabel label="Attachments" className={styles.fieldLabel} />
                    <div className={styles.fieldDescription}>Attach you resume, cover letter and other files</div>
                    <div className={styles.uploadWrap}>
                        <input
                            ref={fileRef}
                            style={{display: "none"}}
                            type="file"
                            multiple
                            onChange={onChangeFile}
                            onClick={onClickUpload}
                            accept="application/pdf"
                        />
                        <BaseButton
                            variant="secondary"
                            title="Attach"
                            onClick={onOpenChooseFile}
                            loading={isUploading}
                        />
                    </div>
                    {renderAttachments()}
                </Col>
            </Row>
            <div className={styles.doneBtn}>
                <BaseButton title={"Send"} loading={isSubmitting} onClick={onSubmit} className={styles.saveBtn} />
            </div>
        </BasePopup>
    )
}
