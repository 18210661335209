import {get} from "lodash"
import BaseService from "./Base"
import {EnrollmentType} from "types/admissions"

class SettingEnrollmentTypesService extends BaseService {
    async getAll(params = {}): Promise<{data: EnrollmentType[]; total: number}> {
        const response = await this.post("/v1/admissions/enrollment-types/list", params)
        return get(response, "data", null)
    }

    async create(params = {}): Promise<EnrollmentType> {
        const response = await this.post("/v1/admissions/enrollment-types/create", params)
        return get(response, "data.data", null)
    }

    async update(id, data, params = {}): Promise<void> {
        data.code = id
        const response = await this.post(`/v1/admissions/enrollment-types/edit`, data, params)
        return get(response, "data", null)
    }

    async delete(params = {}) {
        const response = await this.post(`/v1/admissions/enrollment-types/delete`, params)
        return get(response, "data", null)
    }

    async getAllActive(params = {}): Promise<{data: EnrollmentType[]; total: number}> {
        const response = await this.post("/v1/admissions/enrollment-types/list/active", params)
        return get(response, "data", null)
    }
}

export default SettingEnrollmentTypesService
