/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useMemo, useRef, useState} from "react"
import {useInfiniteQuery} from "@tanstack/react-query"
import {handleError} from "helpers"
import {documentHubService} from "services"
import {BaseDepartmentId} from "types/departments"
import {FilterList} from "types/filter"
import styles from "./DocumentBlockView.module.css"
import {Row, Spin} from "antd"
import {BaseButton, BasePopup, Icon} from "components"
import {PDFViewer} from "components/FileViewer"
import {useHistory} from "react-router-dom"
import {useVisible} from "hooks"
import {BlockItem} from "./BlockItem"
import {stringifyUrl} from "query-string"

type Props = {
    filterParams?: {
        filters: FilterList
        search?: string
        userId?: number
        profileId?: number
        onlyVisibleForUser: boolean
    }
    userId?: number
    departmentId?: BaseDepartmentId
    backUrl?: string
}

export function DocumentBlockView(props: Props) {
    const {filterParams, userId, departmentId, backUrl} = props
    const history = useHistory()
    const [selectedDocument, setSelectedDocument] = useState<any>()
    const previewPopup = useVisible(false)

    const fetchData = async ({pageParam = 1}) => {
        const {data, total} = await documentHubService.listDocuments({
            ...filterParams,
            range: {pageSize: 10, page: pageParam}
        })
        return {results: data, nextPage: pageParam + 1, totalPages: Math.ceil(total / 10)}
    }

    const {
        data,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
        isFetching,
        refetch: refetchData
    } = useInfiniteQuery(["document-blocks", userId, filterParams], fetchData, {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.nextPage <= lastPage.totalPages) return lastPage.nextPage
            return undefined
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const isEmpty = useMemo(() => {
        if (data && data.pages && data.pages.length > 0 && data.pages[0].totalPages > 0) {
            return false
        }
        return true
    }, [data])

    const intObserver = useRef<IntersectionObserver>()
    const lastItemRef = useCallback(
        (item) => {
            if (isFetchingNextPage) return
            if (intObserver.current) intObserver.current.disconnect()

            intObserver.current = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting && hasNextPage) {
                        fetchNextPage()
                    }
                },
                {
                    threshold: 0.25,
                    root: document.querySelector("#document-blocks"),
                    rootMargin: "150px"
                }
            )

            if (item) intObserver.current.observe(item)
        },
        [isFetchingNextPage, fetchNextPage, hasNextPage]
    )

    const goToDetails = (data) => {
        const documentDetailsUrl = stringifyUrl(
            {
                url: `/documents/detail`,
                query: {
                    id: data.userDocumentId,
                    backUrl,
                    userProfileId: data.ownerUserProfileId
                }
            },
            {skipNull: true}
        )
        history.push(documentDetailsUrl)
    }

    const onPreviewDocument = (data) => {
        setSelectedDocument(data)
        previewPopup.open()
    }

    const onClosePreview = () => {
        previewPopup.close()
        setSelectedDocument(null)
    }

    return (
        <div className={styles.main} id={`document-blocks}`}>
            <Row gutter={[12, 12]}>
                {data?.pages.map((page) =>
                    page.results.map((item, idx) => {
                        return (
                            <BlockItem
                                key={item.userDocumentId}
                                item={item}
                                itemRef={page.results.length === idx + 1 ? lastItemRef : null}
                                goToDetails={goToDetails}
                                onPreviewDocument={onPreviewDocument}
                                onRefetchData={refetchData}
                                departmentId={departmentId}
                            />
                        )
                    })
                )}
            </Row>
            {isFetching && <Spin className={styles.loadingData} />}
            {!isFetching && isEmpty && <div className={styles.emptyList}>No document found</div>}
            {selectedDocument && (
                <BasePopup
                    isShow={previewPopup.isVisible}
                    onClose={onClosePreview}
                    isShowLeftSide={false}
                    width="70vw"
                    closable={false}>
                    <div>
                        <div className={styles.previewHeader}>
                            <div className={styles.previewTitle}>
                                <Icon icon="FILE_TEXT" className={styles.icon} />
                                <span className={styles.title}>Preview Document</span>
                            </div>
                            <div className={styles.actions}>
                                <BaseButton title="Document Details" onClick={() => goToDetails(selectedDocument)} />
                                <div className={styles.actionClose} onClick={onClosePreview}>
                                    <Icon icon="CLOSE" color="#000" className={styles.closeIcon} />
                                </div>
                            </div>
                        </div>
                        <PDFViewer
                            fileSrc={selectedDocument.documentUrl}
                            options={{zoom: "fitToWidth", view: "fitToWidth"}}
                        />
                    </div>
                </BasePopup>
            )}
        </div>
    )
}
