/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from "react"
import {Link} from "react-router-dom"
import {Tooltip, Button} from "antd"
import moment from "moment"
import cx from "classnames"
import {get, head, isEmpty, keyBy} from "lodash"
import {Icon} from "components/Icon"
import {Rsvp} from "components/Calendar/Common"
import {BaseButton} from "components/buttons"
import {CalendarEventType, CalendarRecurringType, RSVP} from "types/calendar"
import {extractEventFromRrule, getScheduleEventMethod, getRecurringInfo} from "helpers/calendar"
import {calendarService, userServiceV3, attendanceService} from "services"
import {getFullName, handleError} from "helpers"
import styles from "./EventContent.module.css"
import {useModel} from "hooks"
import {UserTextIcon} from "components/UserTextIcon"

export function EventPreviewContent(props) {
    const {eventInfo, isShowGoing, eventOptions, closeEventPreview, isWorkScheduleView} = props
    const model = useModel()
    const userTimeFormat = model.getUserTimeFormat()
    const [inviteInfos, setInviteInfos] = useState({
        total: 0,
        yes: 0,
        waiting: 0,
        no: 0,
        maybe: 0,
        users: []
    })
    const [hostUsers, setHostUsers] = useState([])
    const [isShowParticipantDetail, setIsShowParticipantDetail] = useState(false)
    const [lesson, setLesson] = useState(null)

    const lessonUrl = useMemo(() => {
        if (isEmpty(lesson)) {
            return ""
        }
        const {termId, courseId, scheduleId, id} = lesson
        return `/academics/instructional/term/${termId}/course/${courseId}/schedule/${scheduleId}/lessons/${id}?tab=1`
    }, [lesson])

    useEffect(() => {
        if (eventInfo) {
            getEventInfo()
            getLesson()
        }
    }, [eventInfo.id])

    if (!eventInfo) return null

    const {
        startDateTime,
        endDateTime,
        description,
        rsvp,
        ownerFullName,
        willGo,
        reminders,
        json_data,
        isHoliday,
        isLesson,
        type
    } = eventInfo?._def?.extendedProps || {}
    const isSystemEvent = [
        CalendarEventType.StartTermDate,
        CalendarEventType.EndTermDate,
        CalendarEventType.WorkSchedule
    ].includes(type)
    const isTermSystemEvent = [CalendarEventType.StartTermDate, CalendarEventType.EndTermDate].includes(type)
    const options = (eventOptions || []).filter(
        (eventOption) =>
            eventOption.icon !== "EDIT" &&
            eventOption.icon !== "DELETE" &&
            eventOption.icon !== "CALENDAR_INPUT" &&
            (!eventOption.canShow || eventOption.canShow(eventInfo._def))
    )
    const descriptionParsed = (description || "").replaceAll("\n", "<br />")
    const editEventInfo = eventOptions.find(
        (eventOption) => eventOption.icon === "EDIT" && (!eventOption.canShow || eventOption.canShow(eventInfo._def))
    )
    const deleteEventInfo = eventOptions.find(
        (eventOption) => eventOption.icon === "DELETE" && (!eventOption.canShow || eventOption.canShow(eventInfo._def))
    )
    const goingEventInfo = eventOptions.find(
        (eventOption) =>
            eventOption.icon === "CALENDAR_INPUT" && (!eventOption.canShow || eventOption.canShow(eventInfo._def))
    )

    const getEventInfo = async () => {
        try {
            const eventId = parseInt(eventInfo.id)
            if (!eventId) return
            const {invites, owner_user_id, invited_user_ids, host_user_ids} = await calendarService.getObject(eventId)
            const userIds = [...invited_user_ids, ...host_user_ids].filter(Boolean)
            let users = []
            if (!isEmpty(userIds)) {
                const {data} = await userServiceV3.getAllAutocomplete({
                    filter: {id: userIds},
                    range: {page: 1, pageSize: userIds.length}
                })
                const invitesByUserId = keyBy(invites, "invited_user_id")
                const owner = data.find((user) => user.id === owner_user_id)
                const restInvitedUsers = data.filter(
                    (user) => user.id !== owner_user_id && invited_user_ids.includes(user.id)
                )
                users = owner ? [{...owner, isOwner: true}, ...restInvitedUsers] : restInvitedUsers
                users = users.map((user) => {
                    user.will_go = get(invitesByUserId, [user.id, "will_go"])
                    return user
                })
                const hostUsers = data.filter((user) => host_user_ids.includes(user.id))
                setHostUsers(hostUsers)
            }
            let yes = 0
            let waiting = 0
            let no = 0
            let maybe = 0
            invites.forEach(({will_go}) => {
                switch (will_go) {
                    case RSVP.Yes:
                        yes += 1
                        break
                    case RSVP.NOT_SELECTED:
                        waiting += 1
                        break
                    case RSVP.No:
                        no += 1
                        break
                    case RSVP.Maybe:
                        maybe += 1
                        break
                    default:
                        break
                }
            })
            setInviteInfos({
                total: invites.length,
                yes,
                waiting,
                no,
                maybe,
                users
            })
        } catch (error) {
            handleError(error)
        }
    }

    const getLesson = async () => {
        if (!isLesson) {
            return
        }
        try {
            const eventId = parseInt(eventInfo.id)
            const {data} = await attendanceService.getLessonByCalendarEventId({calendarEventId: eventId})
            setLesson(data)
        } catch (error) {
            handleError(error)
        }
    }

    const onClickEventOption = (event, action, newValue = null) => {
        closeEventPreview()
        if (action) {
            action(event, newValue)
        }
    }

    const toggleShowParticipant = () => {
        setIsShowParticipantDetail(!isShowParticipantDetail)
    }

    const renderHeader = () => {
        return (
            <div className={styles.eventPreviewHeader}>
                {options.map((eventOption, index) => (
                    <Tooltip
                        key={index}
                        title={eventOption.title}
                        placement="bottom"
                        color="rgba(97,97,97,0.902)"
                        overlayInnerStyle={{minHeight: 27}}
                        overlayClassName={styles.tooltipCard}>
                        <Button
                            className={styles.eventPreviewHeaderBtn}
                            icon={
                                <Icon
                                    icon={eventOption.icon}
                                    color="#5f6368"
                                    className={cx(styles.eventPreviewHeaderIcon, eventOption.iconClassName)}
                                />
                            }
                            onClick={() => onClickEventOption(eventInfo, eventOption.action)}
                        />
                    </Tooltip>
                ))}
                <Tooltip
                    title="Close"
                    placement="bottom"
                    color="rgba(97,97,97,0.902)"
                    overlayInnerStyle={{minHeight: 27}}
                    overlayClassName={styles.tooltipCard}>
                    <Button
                        className={styles.eventPreviewHeaderBtn}
                        icon={<Icon icon="CLOSE" color="#5f6368" className={styles.eventPreviewHeaderIcon} />}
                        onClick={closeEventPreview}
                    />
                </Tooltip>
            </div>
        )
    }
    const renderTitle = () => {
        const originalColor = get(eventInfo, "_def.ui.backgroundColor", "#1e90ff")
        const title = get(eventInfo, "_def.title", "")
        if (!title) {
            return null
        }
        return (
            <div className={cx(styles.eventPreviewContentRow, styles.eventPreviewContentRow__border)}>
                <div className={styles.eventPreviewContentLeft}>
                    <div className={styles.eventPreviewType} style={{background: originalColor}}></div>
                </div>
                <div className={styles.eventPreviewInfo}>
                    <p className={styles.eventPreviewTitleEvent}>{title}</p>
                </div>
            </div>
        )
    }

    const renderTimeContent = () => {
        const allDay = get(eventInfo, "event.allDay", false)
        const isSameDate = moment(startDateTime).isSame(moment(endDateTime), "date")
        const dateFormat = "dddd, MMMM DD YYYY"
        const dateTimeFormat = "dddd, MMMM DD YYYY, HH:mm"
        if ([CalendarEventType.StartTermDate, CalendarEventType.EndTermDate].includes(type)) {
            return <span>{moment(startDateTime).format(dateFormat)}</span>
        }
        if (isSameDate) {
            return (
                <>
                    <span>{moment(startDateTime).format(dateFormat)}</span>
                    {!allDay && (
                        <>
                            <div className={styles.eventPreviewDate__dot} />
                            <span className={styles.eventPreviewDate__time}>
                                {moment(startDateTime).format(userTimeFormat)} -{" "}
                                {moment(endDateTime).format(userTimeFormat)}
                            </span>
                        </>
                    )}
                </>
            )
        }
        return (
            <span>
                {moment(startDateTime).format(dateTimeFormat)} - {moment(endDateTime).format(dateTimeFormat)}
            </span>
        )
    }

    const renderTime = () => {
        const rrule = get(eventInfo, "_def.extendedProps.rrule")
        const {recurring} = extractEventFromRrule(rrule)

        return (
            <>
                <div
                    className={cx(styles.eventPreviewContentRow, {
                        [styles.eventPreviewContentRow__border]: recurring === CalendarRecurringType.Never
                    })}>
                    <div className={styles.eventPreviewContentLeft}>
                        <Icon className={styles.infoIcon} icon="TIMER" />
                    </div>
                    <div className={styles.eventPreviewInfo}>
                        <div className={styles.eventPreviewDate}>{renderTimeContent()}</div>
                    </div>
                </div>
                {recurring !== CalendarRecurringType.Never && (
                    <div className={cx(styles.eventPreviewContentRow, styles.eventPreviewContentRow__border)}>
                        <div className={styles.eventPreviewContentLeft}>
                            <Icon className={styles.infoIcon} icon="REPEAT" />
                        </div>
                        <div className={styles.eventPreviewInfo}>{getRecurringInfo(rrule)}</div>
                    </div>
                )}
            </>
        )
    }

    const renderLogo = (user) => {
        const {firstName, lastName, photo} = user
        if (photo) {
            return <img src={photo.original} alt="" className={styles.participantItem__avatar} />
        }
        const text = `${head(firstName)}${head(lastName)}`.trim() || "A"
        return <UserTextIcon text={text} className={styles.participantItem__avatar} small />
    }

    const renderInviteStatus = (will_go: RSVP) => {
        switch (will_go) {
            case RSVP.Yes:
                return (
                    <span className={styles.participantItem__inviteStatus}>
                        <Icon icon="ACTIVE" className={styles.participantItem__inviteStatusIcon} />
                    </span>
                )
            case RSVP.No:
                return (
                    <span className={styles.participantItem__inviteStatus}>
                        <Icon
                            icon="CROSS_CIRCLE_FILL"
                            color="var(--error-400-base)"
                            className={styles.participantItem__inviteStatusIcon}
                        />
                    </span>
                )
            case RSVP.Maybe:
                return (
                    <span className={styles.participantItem__inviteStatus}>
                        <Icon icon="QUESTION" className={styles.participantItem__inviteStatusIcon} />
                    </span>
                )
            case RSVP.NOT_SELECTED:
            default:
                return null
        }
    }

    const renderParticipantItem = (user) => {
        const {fullName, id, isOwner, will_go} = user

        return (
            <div key={id} className={styles.participantItem}>
                <div className={styles.participantItem__avatarWrap}>
                    {renderInviteStatus(will_go)}
                    {renderLogo(user)}
                </div>
                <div className={styles.participantItemInfo}>
                    <span className={styles.participantItem__name}>{fullName}</span>
                    {isOwner && <span className={styles.participantItem__owner}>Organizer</span>}
                </div>
            </div>
        )
    }

    const renderParticipantsDetail = () => {
        if (isShowParticipantDetail) {
            return (
                <div className={styles.participantsWrap}>
                    {inviteInfos.users.map((user) => renderParticipantItem(user))}
                </div>
            )
        }
        return null
    }

    const renderHostUsers = () => {
        if (isWorkScheduleView || isSystemEvent) {
            return
        }
        const hostUsersInfo = hostUsers.map((user) => getFullName(user)).join(", ")
        if (!hostUsersInfo) {
            return null
        }
        return (
            <div className={cx(styles.eventPreviewContentRow, styles.eventPreviewContentRow__border)}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="PERSON_ADD" />
                </div>
                <div className={styles.eventPreviewInfo}>
                    <p className={styles.eventPreviewTitle}>{hostUsersInfo}</p>
                </div>
            </div>
        )
    }

    const getCountInviteInfo = () => {
        const {yes, no, maybe, waiting} = inviteInfos
        return [
            {label: "Yes", value: yes},
            {label: "No", value: no},
            {label: "Maybe", value: maybe},
            {label: "Awaiting", value: waiting}
        ]
            .filter((item) => item.value > 0)
            .map((item) => `${item.value} ${item.label}`)
            .join(", ")
    }

    const renderParticipant = () => {
        if (isWorkScheduleView || isSystemEvent) {
            return
        }

        return (
            <div className={cx(styles.eventPreviewContentRow, styles.eventPreviewContentRow__border)}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="PEOPLE" />
                </div>
                <div className={styles.inviteInfoWrap}>
                    <div className={styles.inviteInfo} onClick={toggleShowParticipant}>
                        <div className={styles.eventPreviewInfo}>
                            <p className={styles.eventPreviewTitle}>{inviteInfos.total} Participants</p>
                            <p className={styles.participantDesc}>{getCountInviteInfo()}</p>
                        </div>
                        <div>
                            <div
                                className={cx(styles.collapseInviteIconWrap, {
                                    [styles.iconCollapse]: !isShowParticipantDetail
                                })}>
                                <Icon icon="CHEVRON_DOWN" color="#666" />
                            </div>
                        </div>
                    </div>
                    {renderParticipantsDetail()}
                </div>
            </div>
        )
    }

    const renderCourseInfo = () => {
        const {schedule_suffix, moduleTitle, subModuleTitle} = eventInfo.extendedProps || {}
        if (schedule_suffix || moduleTitle || subModuleTitle) {
            const eventMethod = getScheduleEventMethod(json_data?.eventMethod)
            return (
                <div className={cx(styles.eventPreviewContentRow, styles.eventPreviewContentRow__border)}>
                    <div className={styles.eventPreviewContentLeft}>
                        <Icon className={styles.infoIcon} icon="COURSE" />
                    </div>
                    <div className={styles.eventPreviewInfo}>
                        <p className={styles.eventPreviewTitle}>{schedule_suffix}</p>
                        <p className={styles.eventPreviewTitle}>{moduleTitle}</p>
                        <p className={styles.eventPreviewTitle}>{subModuleTitle}</p>
                        <p className={styles.eventPreviewTitle}>{eventMethod}</p>
                    </div>
                </div>
            )
        }
        return null
    }

    const renderDescription = (description) => {
        if (!description) {
            return null
        }
        const holidayDescription = isHoliday && json_data?.notes
        if (!isEmpty(holidayDescription)) {
            description += ` (${holidayDescription})`
        }
        return (
            <div className={cx(styles.eventPreviewContentRow, styles.eventPreviewContentRow__border)}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="NOTES" />
                </div>
                <div className={styles.eventPreviewInfo}>
                    <div
                        className={styles.eventPreviewTitle}
                        dangerouslySetInnerHTML={{
                            __html: description
                        }}
                    />
                </div>
            </div>
        )
    }

    const renderReminders = () => {
        if (isWorkScheduleView || isSystemEvent || isEmpty(reminders)) {
            return null
        }

        return (
            <div className={cx(styles.eventPreviewContentRow, styles.eventPreviewContentRow__border)}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="BELL" />
                </div>
                <div className={styles.eventPreviewInfo}>
                    <p className={styles.eventPreviewTitle}>
                        {reminders?.map((reminder) => reminder.label).join(", ")}
                    </p>
                </div>
            </div>
        )
    }

    const renderLocation = () => {
        const {location} = json_data || {}
        if (!location) {
            return null
        }
        return (
            <div className={cx(styles.eventPreviewContentRow, styles.eventPreviewContentRow__border)}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="LOCATION" />
                </div>
                <div className={styles.eventPreviewInfo}>
                    <p className={styles.eventPreviewTitle}>{location.name}</p>
                </div>
            </div>
        )
    }

    const renderOwnerUser = () => {
        if (!ownerFullName) {
            return null
        }
        return (
            <div className={cx(styles.eventPreviewContentRow)}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="CALENDAR_INPUT" />
                </div>
                <div className={styles.eventPreviewInfo}>
                    <p className={styles.eventPreviewTitle}>{ownerFullName}</p>
                </div>
            </div>
        )
    }

    const renderGoing = () => {
        if (model.viewOtherUserCalendar?.id && !model.viewOtherUserCalendar?.hasPermissionToEdit) {
            return null
        }
        return (
            <div className={styles.goingWrap}>
                <span className={styles.goingTitle}>Going?</span>
                <Rsvp
                    value={willGo}
                    onChange={(newValue) => onClickEventOption(eventInfo, goingEventInfo.action, newValue)}
                    className={styles.goingBody}
                />
            </div>
        )
    }

    const hasPermissionsToDeleteOtherView = model.viewOtherUserCalendar?.id
        ? model.viewOtherUserCalendar?.hasPermissionToDelete
        : true

    return (
        <div className={styles.eventPreviewWrap}>
            {props.children}
            <div className={styles.eventPreviewBody}>
                {renderHeader()}
                <div className={styles.evenPreviewContent}>
                    {renderTitle()}
                    {renderTime()}
                    {renderHostUsers()}
                    {renderParticipant()}
                    {renderCourseInfo()}
                    {renderDescription(descriptionParsed)}
                    {renderReminders()}
                    {renderLocation()}
                    {renderOwnerUser()}
                </div>
            </div>
            {isShowGoing && !isSystemEvent && renderGoing()}
            {isLesson && !isEmpty(lesson) && (
                <div className={styles.eventPreviewAction}>
                    <Link to={lessonUrl}>
                        <BaseButton title="Go Lesson Detail" variant="secondary" />
                    </Link>
                </div>
            )}
            {!isTermSystemEvent && (
                <div className={styles.eventPreviewAction}>
                    {editEventInfo ? (
                        <BaseButton
                            title={editEventInfo.title}
                            variant="secondary"
                            onClick={() => onClickEventOption(eventInfo, editEventInfo.action)}
                        />
                    ) : (
                        <div />
                    )}
                    {deleteEventInfo && hasPermissionsToDeleteOtherView && (
                        <BaseButton
                            title="Delete Event"
                            variant="secondary"
                            onClick={() => onClickEventOption(eventInfo, deleteEventInfo.action)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
