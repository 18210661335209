import React from "react"
import {v4 as uuid} from "uuid"
import {isEmpty} from "lodash"
import {withTranslation} from "react-i18next"

import {BaseInput} from "components/inputs"
import {BaseButton, SecondaryButton, AddItemCircleButton} from "components/buttons"
import {PermissionsRequired} from "components/PermissionsRequired"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {BaseDepartmentId} from "types/departments"
import {PermissionUserType} from "types/permission"
import {getPermissionsByDepartment} from "sections/Settings/helper"
import {handleError, checkPermission, toastError, toastWarning} from "helpers"
import {admissionService} from "services"
import {Model} from "Model"
import styles from "./HowDidYouKnowAboutUs.module.css"

type PageProps = {
    t: Function
    model: Model
    departmentId: number
}

type Props = KlassappTableProps & PageProps

type State = {
    newItemData: any
    oldItemData: any
    isSubmitting: boolean
    isGettingMaxId: boolean
    permissions: PermissionUserType
}

class HowDidYouKnowAboutUs extends React.Component<Props, State> {
    state = {
        newItemData: null,
        oldItemData: null,
        isSubmitting: false,
        isGettingMaxId: false,
        permissions: {staff: []}
    }

    componentDidMount() {
        this.getPermissions()
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete}
        ])
        this.getData()
    }

    getPermissions = () => {
        const permissions = getPermissionsByDepartment(BaseDepartmentId.Admissions)
        this.setState({permissions})
    }

    getPageTitle = () => {
        return this.props.t("admission.howDidYouKnowAboutUs.howDidYouKnowAboutUs")
    }

    getFields = () => {
        const {t} = this.props
        return [t("admission.howDidYouKnowAboutUs.title")]
    }

    getTableHeaderActions = (isShowDuplicateBtn = true, checkedData = []) => {
        const canWrite = checkPermission(this.state.permissions, this.props.model)
        if (!canWrite) {
            return []
        }
        const {t, onClickShowConfirmModal} = this.props
        const actions = [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
        return actions
    }

    canShowDeleteIcon = () => {
        return checkPermission(this.state.permissions, this.props.model)
    }

    canShowEditIcon = () => {
        return checkPermission(this.state.permissions, this.props.model)
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: this.canShowEditIcon
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: this.canShowDeleteIcon
            }
        ]
    }

    getData = async () => {
        this.props.dispatch({isLoading: true})
        const params = this.getParams()
        try {
            const {data, total} = await admissionService.getAllHowDidYouKnowAbouUs(params)
            const newData = data.map((item) => {
                item.id = item.howDidYouKnowAboutUsId
                return item
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    getParams = () => {
        const {page, pageSize, orderField} = this.props
        const params: any = {
            range: {
                page,
                pageSize
            }
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    getColumns = () => {
        const {t} = this.props

        return [
            {
                title: t("admission.howDidYouKnowAboutUs.title"),
                field: "name",
                style: {minWidth: "200px"},
                sortable: true
            }
        ]
    }

    onClickDeleteMulti = async () => {
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await admissionService.deleteHowDidYouKnowAbouUs(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickEdit = (editItem) => {
        const {newItemData} = this.state
        if (newItemData) {
            toastWarning(this.props.t("common:validation.saveBeforeEdit"))
            return
        }
        this.setState(
            {
                newItemData: {
                    ...editItem,
                    nameData: editItem.name
                },
                oldItemData: {...editItem}
            },
            () => {
                const newData = this.props.data.map((item) => {
                    if (item.id === editItem.id) {
                        item.name = this.renderNameInput()
                        return item
                    }
                    return item
                })
                this.props.dispatch({data: newData})
            }
        )
    }

    onClickDelete = async (deletedItem) => {
        if (deletedItem.isForm) {
            this.setState({newItemData: null, oldItemData: null})
            await this.getData()
            return
        }
        try {
            this.props.dispatch({isLoading: true})
            this.setState({newItemData: null, oldItemData: null})
            await admissionService.deleteHowDidYouKnowAbouUs([deletedItem.id])
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onChangeNewItemData = (key, value) => {
        const {newItemData} = this.state
        newItemData[key] = value
        const newData = this.props.data.map((item) => {
            if (item.id === newItemData.id) {
                item.name = this.renderNameInput()
                return item
            }
            return item
        })
        this.setState({newItemData})
        this.props.dispatch({data: newData})
    }

    renderNameInput = () => {
        const newItemData = this.state.newItemData || {}
        return (
            <BaseInput
                value={newItemData.nameData}
                placeholder="Title"
                onChange={(value) => this.onChangeNewItemData("nameData", value)}
            />
        )
    }

    onClickAddItem = async () => {
        const {isGettingMaxId} = this.state
        const {isLoading} = this.props
        if (isLoading || isGettingMaxId) return
        const newItemData = {
            id: uuid(),
            nameData: "",
            isForm: true
        }
        this.setState(
            {
                newItemData,
                oldItemData: null
            },
            () => {
                const {data} = this.props
                const newItem = {
                    ...newItemData,
                    name: this.renderNameInput()
                }
                data.push(newItem)
                this.props.dispatch({data})
            }
        )
    }

    validateDataBeforeSubmit = () => {
        const {nameData} = this.state.newItemData
        if (!nameData) {
            toastError(this.props.t("common:validation.cantEmpty", {field: "The Title"}))
            return false
        }
        return true
    }

    onClickSave = async () => {
        if (!this.validateDataBeforeSubmit()) {
            return
        }
        const {nameData, isForm, id} = this.state.newItemData
        if (isForm) {
            const submitData = {
                name: nameData
            }
            await this.createHowDidYouKnowAbouUs(submitData)
        } else {
            const submitData = {name: nameData}
            await this.updateHowDidYouKnowAbouUs(id, submitData)
        }
        this.setState({newItemData: null, oldItemData: null}, () => {
            this.getData()
        })
    }

    createHowDidYouKnowAbouUs = async (data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await admissionService.createHowDidYouKnowAbouUs(data)
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    updateHowDidYouKnowAbouUs = async (id, data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await admissionService.updateHowDidYouKnowAbouUs(id, data)
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    onClickCancel = () => {
        const {oldItemData, isSubmitting} = this.state
        const {data, isLoading} = this.props
        if (isLoading || isSubmitting) {
            return
        }
        const newData = data
            .filter((item) => !item.isForm)
            .map((item) => {
                if (oldItemData && oldItemData.id === item.id) {
                    item.name = oldItemData.name
                    return item
                }
                return item
            })
        this.setState({newItemData: null, oldItemData: null})
        this.props.dispatch({data: newData})
    }

    render() {
        const {newItemData, isSubmitting, isGettingMaxId, permissions} = this.state
        const {
            t,
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isHideMenuActions,
            isShowTableHeaderAction,
            orderField,
            isLoading
        } = this.props

        return (
            <div className={styles.wrapper}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn
                    onClickRowItem={() => {}}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                <PermissionsRequired permissions={permissions}>
                    {!newItemData ? (
                        <div className={styles.buttonWrap}>
                            {!isGettingMaxId && !isLoading && <AddItemCircleButton onClick={this.onClickAddItem} />}
                        </div>
                    ) : (
                        <div className={styles.buttonWrap}>
                            <SecondaryButton
                                title={t("common:action.cancel")}
                                onClick={this.onClickCancel}
                                className={styles.cancelBtn}
                            />
                            <BaseButton
                                title={t("common:action.save").toUpperCase()}
                                onClick={this.onClickSave}
                                loading={isSubmitting}
                            />
                        </div>
                    )}
                </PermissionsRequired>
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["admission", "common"])(HowDidYouKnowAboutUs))
