/* eslint-disable react-hooks/exhaustive-deps */
import {Auth} from "types/auth"
import {useTranslation} from "react-i18next"
import {useAllEnrollmentTypes, useModel} from "hooks"
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {majorService, settingAcademicTrackService, userService} from "services"
import {getFieldLabel, handleError} from "helpers"
import {Settings} from "types/settings"
import {get} from "lodash"
import moment from "moment/moment"
import {KlassDropdown} from "components/Select"
import styles from "uiKit/StudentInfoPanel/StudentInfoPanel.module.css"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"
import TermSelect from "components/TermSelect"
import {BaseDatePicker} from "components/DateTimePicker"
import {BaseInput} from "components/inputs"
import cx from "classnames"
import CircularSpin from "components/CircularSpin"
import {Icon} from "components/Icon"
import {Tooltip} from "antd"

type EditableStudentFieldProps = {
    field:
        | "customProfileId"
        | "customUserId"
        | "startDate"
        | "contractEndDate"
        | "firstDateOfClass"
        | "midpointDate"
        | "projectedEndDate"
        | "actualEndDate"
        | "enrollmentType"
        | "campuses"
        | "currentMajor"
        | "currentMajorVersionId"
        | "academicTrackId"
        | "startingTermId"
        | "currentTerm"
        | "initialInquiryDate"
        | "applicationCompletedDate"
        | "acceptanceDate"
    activeProfile?: Auth.Profile
    student?: Auth.DepartmentStudent
    getUserData?: (profileId: number) => Promise<any>
    readOnly?: boolean
}

const EditableStudentField = ({
    field,
    activeProfile,
    student,
    getUserData,
    readOnly = false
}: EditableStudentFieldProps) => {
    const {t} = useTranslation(["common", "user"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const [isEditing, setEditing] = useState(false)
    const [value, setValue] = useState<any | undefined>()
    const [isSubmitting, setSubmitting] = useState(false)

    const [majorVersionOptions, setMajorVersionOptions] = useState([])
    const [academicTracks, setAcademicTracks] = useState([])
    const {enrollmentTypes} = useAllEnrollmentTypes()

    useEffect(() => {
        setEditing(false)
    }, [student?.profileId])

    useEffect(() => {
        ;(async function getAcademicTrackOptions() {
            if (field !== "academicTrackId") return
            try {
                const {data} = await settingAcademicTrackService.getAllAcademicTracks({
                    sort: {orderBy: "name", orderDir: "asc"}
                })
                setAcademicTracks(data)
            } catch (error) {
                console.error(error)
            }
        })()
    }, [field])

    useEffect(() => {
        ;(async function getMajorVersionOptions() {
            if (field !== "currentMajorVersionId" || !activeProfile?.currentMajor) {
                setMajorVersionOptions([])
                return
            }
            try {
                const majorVersions = await majorService.getMajorVersions({
                    orderBy: "version_name",
                    orderDir: "ASC",
                    program_id: (activeProfile.currentMajor as Auth.CurrentMajor).programId,
                    isActive: true
                })
                setMajorVersionOptions(
                    majorVersions.map((item) => ({
                        ...item,
                        versionName: item.version_name,
                        versionCode: item.version_code
                    }))
                )
            } catch (error) {
                console.error(error)
                setMajorVersionOptions([])
            }
        })()
    }, [field, activeProfile?.currentMajor])

    const label: string = useMemo(() => {
        switch (field) {
            case "customProfileId": {
                const isProspectOrApplicant = [
                    Auth.UserProfileState.Applicant,
                    Auth.UserProfileState.Prospect
                ].includes(activeProfile?.state)
                const isStudentOrAlumni = [Auth.UserProfileState.Student, Auth.UserProfileState.Alumni].includes(
                    activeProfile?.state
                )
                return isProspectOrApplicant
                    ? t("studentInfo.applicantID")
                    : isStudentOrAlumni
                    ? t("studentInfo.enrollmentID")
                    : t("studentInfo.profileId")
            }
            case "customUserId":
                return t("studentInfo.userID")
            case "startDate":
                return getFieldLabel(model, Settings.GeneralLabel.StartDate, t("studentInfo.startDate"))
            case "contractEndDate":
                return getFieldLabel(model, Settings.GeneralLabel.ContractEndDate, t("studentInfo.contractEndDate"))
            case "firstDateOfClass":
                return getFieldLabel(model, Settings.GeneralLabel.FirstDateOfClass, t("studentInfo.firstDateOfClass"))
            case "midpointDate":
                return getFieldLabel(model, Settings.GeneralLabel.MidpointDate, t("studentInfo.midpointDate"))
            case "projectedEndDate":
                return getFieldLabel(model, Settings.GeneralLabel.ProjectedEndDate, t("studentInfo.projectedEndDate"))
            case "actualEndDate":
                return (
                    getFieldLabel(model, Settings.GeneralLabel.ActualEndDate, t("studentInfo.actualEndDate")) +
                    " (System generated)"
                )
            case "enrollmentType":
                return t("studentInfo.enrollmentType")
            case "campuses":
                return getFieldLabel(model, Settings.GeneralLabel.Campus, t("studentInfo.campus"))
            case "currentMajor":
                return getFieldLabel(model, Settings.GeneralLabel.Program, t("studentInfo.program"))
            case "currentMajorVersionId":
                return getFieldLabel(model, Settings.GeneralLabel.ProgramVersion, t("studentInfo.programVersion"))
            case "academicTrackId":
                return getFieldLabel(model, Settings.GeneralLabel.AcademicTrack, t("studentInfo.academicTrack"))
            case "startingTermId":
                return t("studentInfo.startingTerm")
            case "currentTerm":
                return getFieldLabel(model, Settings.GeneralLabel.CurrentTerm, t("studentInfo.currentTerm"))
            case "initialInquiryDate":
                return "Initial inquiry date"
            case "applicationCompletedDate":
                return "App Completion date"
            case "acceptanceDate":
                return "Admission acceptance"
            default:
                return ""
        }
    }, [field, activeProfile, student])

    const displayValue: React.ReactNode = useMemo(() => {
        switch (field) {
            case "customProfileId":
                return activeProfile?.customProfileId
            case "customUserId":
                return get(student, "customUserId")
            case "startDate": {
                const date = activeProfile?.startDate || activeProfile?.startingTerm?.startDate
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            case "contractEndDate": {
                const date = activeProfile?.contractEndDate || activeProfile?.startingTerm?.endDate
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            case "firstDateOfClass": {
                const date = activeProfile?.firstDateOfClass
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            case "midpointDate": {
                const date = activeProfile?.midpointDate
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            case "projectedEndDate": {
                const date = activeProfile?.projectedEndDate
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            case "actualEndDate": {
                const date = activeProfile?.actualEndDate
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            case "enrollmentType":
                const enrollmentType = (enrollmentTypes || []).find(({code}) => code === activeProfile?.enrollmentType)
                return enrollmentType ? enrollmentType.name : "-"
            case "campuses": {
                if (!activeProfile?.campuses?.length) return "-"
                return (
                    <>
                        {activeProfile.campuses
                            .slice(0, 5)
                            .map((campus) => campus.name)
                            .join(", ")}
                        {activeProfile.campuses.length > 5 && (
                            <span style={{color: "var(--primary-400-base)"}}>
                                &nbsp;(+{activeProfile.campuses.length - 5})
                            </span>
                        )}
                    </>
                )
            }
            case "currentMajor":
                return get(activeProfile, "currentMajor.name", "-")
            case "currentMajorVersionId":
                const versionName = get(activeProfile, "currentMajorVersionId.versionName", "-")
                const versionCode = get(activeProfile, "currentMajorVersionId.versionCode", "")
                return `${versionName}${versionCode ? ` (${versionCode})` : ""}`
            case "academicTrackId":
                return activeProfile?.academicTrack || "-"
            case "startingTermId":
                return get(activeProfile, "startingTerm.name", "-")
            case "currentTerm":
                return get(activeProfile, "currentTerm.name", "-")
            case "initialInquiryDate": {
                const date = student?.initialInquiryDate
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            case "applicationCompletedDate": {
                const date = student?.applicationCompletedDate
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            case "acceptanceDate": {
                const date = student?.acceptanceDate
                return date ? moment(date).utc().format(userDateFormat) : "-"
            }
            default:
                return null
        }
    }, [field, activeProfile, student])

    const handleEditClick = useCallback(() => {
        switch (field) {
            case "customUserId":
            case "initialInquiryDate":
            case "applicationCompletedDate":
            case "acceptanceDate":
                setValue(student?.[field])
                break
            case "customProfileId":
            case "firstDateOfClass":
            case "midpointDate":
            case "projectedEndDate":
            case "currentMajor":
            case "currentMajorVersionId":
            case "currentTerm":
            case "campuses":
                setValue(activeProfile?.[field])
                break
            case "enrollmentType":
                const enrollmentType = (enrollmentTypes || []).find(({code}) => code === activeProfile?.enrollmentType)
                setValue(enrollmentType)
                break
            case "academicTrackId":
                setValue(
                    activeProfile?.academicTrackId
                        ? {trackId: activeProfile?.academicTrackId, name: activeProfile?.academicTrack}
                        : undefined
                )
                break
            case "startingTermId":
                setValue(activeProfile?.startingTerm)
                break
            default:
                setValue(undefined)
        }
        setEditing(true)
    }, [field, activeProfile, student])

    const renderInput = () => {
        switch (field) {
            case "enrollmentType":
                return (
                    <KlassDropdown
                        isClearable
                        options={enrollmentTypes || []}
                        value={value}
                        onChange={setValue}
                        className={styles.contentInput}
                        components={{
                            ClearIndicator: () => <div className="w-20" />,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        menuPortalTarget={document.body}
                        stylesCustom={{
                            menuPortal: (styles) => ({
                                ...styles,
                                zIndex: 9999
                            }),
                            menu: (styles) => ({
                                ...styles,
                                width: "max-content",
                                minWidth: "100%",
                                maxWidth: "450px",
                                right: 0
                            })
                        }}
                    />
                )
            case "campuses":
                return (
                    <CampusSelect
                        isClearable
                        isMulti
                        value={value ?? []}
                        onChange={setValue}
                        className={styles.contentInput}
                        components={{
                            ClearIndicator: () => <div className="w-20" />,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        menuPortalTarget={document.body}
                        stylesCustom={{
                            menuPortal: (styles) => ({
                                ...styles,
                                zIndex: 9999
                            }),
                            menu: (styles) => ({
                                ...styles,
                                width: "max-content",
                                minWidth: "100%",
                                maxWidth: "450px",
                                right: 0
                            })
                        }}
                    />
                )
            case "currentMajor":
                return (
                    <ProgramSelect
                        isClearable
                        isActive
                        value={value}
                        onChange={setValue}
                        className={styles.contentInput}
                        components={{
                            ClearIndicator: () => <div className="w-20" />,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        menuPortalTarget={document.body}
                        stylesCustom={{
                            menuPortal: (styles) => ({
                                ...styles,
                                zIndex: 9999
                            }),
                            menu: (styles) => ({
                                ...styles,
                                width: "max-content",
                                minWidth: "100%",
                                maxWidth: "450px",
                                right: 0
                            })
                        }}
                    />
                )
            case "currentMajorVersionId":
                return (
                    <KlassDropdown
                        isClearable
                        options={majorVersionOptions}
                        getOptionLabel={({versionCode, versionName}) =>
                            versionCode ? ` ${versionName} (${versionCode})` : versionName
                        }
                        getOptionValue={({versionCode, versionName}) =>
                            versionCode ? ` ${versionName} (${versionCode})` : versionName
                        }
                        value={value}
                        onChange={setValue}
                        className={styles.contentInput}
                        components={{
                            ClearIndicator: () => <div className="w-20" />,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        menuPortalTarget={document.body}
                        stylesCustom={{
                            menuPortal: (styles) => ({
                                ...styles,
                                zIndex: 9999
                            }),
                            menu: (styles) => ({
                                ...styles,
                                width: "max-content",
                                minWidth: "100%",
                                maxWidth: "450px",
                                right: 0
                            })
                        }}
                    />
                )
            case "academicTrackId":
                return (
                    <KlassDropdown
                        isClearable
                        options={academicTracks}
                        valueKey="trackId"
                        labelKey="name"
                        value={value}
                        onChange={setValue}
                        className={styles.contentInput}
                        components={{
                            ClearIndicator: () => <div className="w-20" />,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        menuPortalTarget={document.body}
                        stylesCustom={{
                            menuPortal: (styles) => ({
                                ...styles,
                                zIndex: 9999
                            }),
                            menu: (styles) => ({
                                ...styles,
                                width: "max-content",
                                minWidth: "100%",
                                maxWidth: "450px",
                                right: 0
                            })
                        }}
                    />
                )
            case "startingTermId":
            case "currentTerm":
                return (
                    <TermSelect
                        isClearable
                        value={value}
                        onChange={setValue}
                        className={styles.contentInput}
                        components={{
                            ClearIndicator: () => <div className="w-20" />,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        menuPortalTarget={document.body}
                        stylesCustom={{
                            menuPortal: (styles) => ({
                                ...styles,
                                zIndex: 9999
                            }),
                            menu: (styles) => ({
                                ...styles,
                                width: "max-content",
                                minWidth: "100%",
                                maxWidth: "450px",
                                right: 0
                            })
                        }}
                    />
                )
            case "startDate":
            case "contractEndDate":
            case "firstDateOfClass":
            case "midpointDate":
            case "projectedEndDate":
            case "actualEndDate":
            case "initialInquiryDate":
            case "applicationCompletedDate":
            case "acceptanceDate":
                return (
                    <BaseDatePicker
                        value={value ? moment.utc(value) : undefined}
                        onChange={(value) => setValue(value?.toISOString())}
                        className={styles.contentInput}
                        suffixIcon={null}
                        clearIcon={null}
                    />
                )
            case "customProfileId":
            case "customUserId":
            default:
                return <BaseInput value={value} onChange={setValue} className={styles.contentInput} />
        }
    }

    const handleSaveClick = useCallback(async () => {
        if (!student?.profileId && !activeProfile?.id) return
        try {
            setSubmitting(true)
            let submitData = {
                [field]: value ?? null
            }
            switch (field) {
                case "enrollmentType":
                    submitData[field] = value?.id || null
                    break
                case "campuses":
                    submitData[field] = value?.map((campus) => ({id: campus.id, name: campus.name})) ?? []
                    break
                case "currentMajor":
                    submitData[field] = value?.id || null
                    break
                case "currentMajorVersionId":
                    submitData[field] = value?.id || null
                    break
                case "academicTrackId":
                    submitData[field] = value?.trackId || null
                    break
                case "startingTermId": {
                    submitData[field] = value?.id || null
                    if (value?.id) {
                        const {data: programsOfTerm} = await majorService.getMajorByTerm([value.id])
                        const programByTerm = programsOfTerm.find((major) => major.id === student.currentMajor?.id)
                        if (!!programByTerm?.startDate) {
                            submitData.startDate = moment.utc(programByTerm.startDate).toISOString()
                        }
                        if (!!programByTerm?.projectedEndDate) {
                            submitData.contractEndDate = moment.utc(programByTerm.projectedEndDate).toISOString()
                            submitData.projectedEndDate = moment.utc(programByTerm.projectedEndDate).toISOString()
                        }
                    }
                    break
                }
                case "currentTerm":
                    submitData[field] = value?.id || null
                    break
            }
            if (field === "customUserId") {
                await userService.updateUserById(student?.userId || activeProfile?.userId, submitData)
            } else {
                await userService.updateProfileUserById(student?.profileId || activeProfile?.id, {
                    type: student?.type || activeProfile?.type,
                    ...submitData
                })
            }
            await getUserData?.(student?.profileId || activeProfile?.id)
            setEditing(false)
            setValue(undefined)
        } catch (error) {
            handleError(error)
        } finally {
            setSubmitting(false)
        }
    }, [student?.profileId, activeProfile?.id, value, getUserData])

    const editable = !["startDate", "contractEndDate", "actualEndDate"].includes(field) && !readOnly

    return (
        <div className={styles.contentWrap}>
            <div
                className={cx(styles.contentTitle, {[styles.editable]: editable})}
                onClick={editable ? handleEditClick : undefined}
                title={label}>
                {label}
            </div>
            {isEditing ? (
                <>
                    <div className={cx(styles.contentValue)}>{renderInput()}</div>
                    <div className={styles.contentActions}>
                        {isSubmitting ? (
                            <CircularSpin size="small" />
                        ) : (
                            <div className={styles.contentAction} onClick={handleSaveClick}>
                                <Icon icon="CHECK_CIRCLE" />
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <Tooltip title={displayValue}>
                    <div
                        className={cx(styles.contentValue, {[styles.editable]: editable})}
                        onClick={editable ? handleEditClick : undefined}>
                        <span className={styles.displayValue}>{displayValue}</span>
                    </div>
                </Tooltip>
            )}
        </div>
    )
}

export default EditableStudentField
