/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {studentService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import groupBy from "lodash/groupBy"
import {uniqWith} from "lodash"

type Props = Partial<KlassDropdownAsyncProps> & {
    profileIds?: number[]
    selectedChecklistsSelectedIds?: number[]
}

const isEqualChecklistItem = (a, b) =>
    a.departmentId === b.departmentId && a.checklistItemNameId === b.checklistItemNameId && a.name === b.name

const StudentChecklistSelect: React.FC<Props> = ({profileIds = [], selectedChecklistsSelectedIds = [], ...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            const {data} = await studentService.autocompleteChecklist({
                search,
                studentProfileId: profileIds
            })

            const dataFiltered = data.filter(
                (item) => !selectedChecklistsSelectedIds.includes(item.studentChecklistItemId)
            )

            return Object.entries(groupBy(uniqWith(dataFiltered, isEqualChecklistItem), "departmentId")).map(
                ([departmentId, items]) => ({
                    label: t(`common:moduleName.${departmentId}`),
                    options: items
                })
            )
        }, 500),
        [profileIds]
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions
            valueKey="studentChecklistItemId"
            labelKey="name"
            getOptionLabel={(option) => option.name}
        />
    )
}

export default StudentChecklistSelect
