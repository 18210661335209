/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {cloneDeep} from "lodash"
import {BasePopup} from "components/popup"
import {Icon} from "components/Icon"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {BaseButton, SecondaryButton} from "components/buttons"
import {BaseTextArea} from "components/inputs"
import {TaskAction, TaskActionFor, TaskStatus, TaskType} from "types/tasks"
import {handleError, toastSuccess, getFullName, toastError} from "helpers"
import {taskService} from "services"
import {Status} from "sections/Tasks/Common"
import tableStyles from "../ResponsesTable/ResponsesTable.module.css"
import styles from "./ConfirmPopup.module.css"

export function ConfirmPopup(props) {
    const {
        isShow,
        onClose,
        type,
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        orderField,
        isLoading,
        taskData,
        checkedData,
        getCurrentData,
        confirmPopupActionFor
    } = props
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [reason, setReason] = useState("")

    const getFields = () => {
        return ["Task ID", "User", "Role", "Email", "Submit Date", "Status"]
    }

    const getPageTitle = () => {}

    const getColumns = () => {
        return [
            {
                title: "Task ID",
                field: "taskHtml"
            },
            {
                title: "User",
                field: "userHtml"
            },
            {
                title: "Role",
                field: "roleHtml"
            },
            {
                title: "Email",
                field: "emailHtml"
            },
            {
                title: "Submit Date",
                field: "submittedAt",
                fieldType: "date",
                format: "MM/DD/YYYY"
            },
            {
                title: "Status",
                field: "statusHtml"
            }
        ]
    }

    const onToggleRow = (event, rowItem) => {
        event.stopPropagation()
        const data = getCurrentData()
        if (rowItem.isOpenChild) {
            const newData = data
                .filter((item) => !item.parentSubTaskId)
                .map((item) => {
                    item.isOpenChild = false
                    item.taskHtml = renderTaskHtml(item)
                    return item
                })
            props.dispatch({data: newData})
        } else {
            const childs = rowItem.users.map((item) => {
                item.parentSubTaskId = rowItem.id
                item.taskUserId = item.taskUserId || item.id
                if (!item.parentSubTaskId || item.parentSubTaskId === rowItem.id) {
                    item.id = `${rowItem.id}_${item.id}`
                    item.parentSubTaskId = rowItem.id
                }
                item.userHtml = getFullName(item)
                item.emailHtml = item.email || item.emailTwo
                item.roleHtml = item.type
                return item
            })
            if (childs && childs.length) {
                const newData = cloneDeep(data)
                const subTasks = [...newData, ...childs].map((item: any) => {
                    if (item.id === rowItem.id) {
                        item.isOpenChild = true
                    }
                    item.taskHtml = renderTaskHtml(item)
                    item.statusHtml = renderStatusHtml(item)
                    return item
                })
                props.dispatch({data: subTasks, isLoading: false})
            }
        }
    }

    const renderStatusHtml = (subTask) => {
        return <Status status={subTask.status} />
    }

    const renderTaskHtml = (subTask) => {
        return (
            <div className={tableStyles.taskWrap}>
                {!subTask.parentId && (subTask.users || []).length ? (
                    <>
                        <div className={tableStyles.arrowWrap} onClick={(e) => onToggleRow(e, subTask)}>
                            <Icon
                                icon={subTask.isOpenChild ? "ARROW_DOWN" : "ARROW_RIGHT"}
                                className={tableStyles.arrowIcon}
                            />
                        </div>
                        <p className={tableStyles.task__id}>{subTask.subTaskId}</p>
                    </>
                ) : (
                    <div className={tableStyles.arrowWrap}></div>
                )}
            </div>
        )
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const newData = (checkedData || []).map((item) => {
                item.taskHtml = renderTaskHtml(item)
                return item
            })
            dispatch({data: newData, total: newData.length})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {}

    const onClickDeleteMulti = async () => {}

    const onClickDuplicateMulti = async () => {}

    const onClickEdit = (editedItem) => {}

    const onClickDelete = async (deletedItem) => {}

    const onClick = () => {
        switch (type) {
            case TaskAction.APPROVE:
                onClickApprove()
                break
            case TaskAction.REJECT:
                onClickReject()
                break
            case TaskAction.REOPEN:
                onClickReopen()
                break
            default:
                return ""
        }
    }

    const onClickReject = async () => {
        if (!reason) {
            toastError("Please enter the reason")
            return
        }
        try {
            setIsSubmitting(true)
            if (confirmPopupActionFor === TaskActionFor.SUBTASK) {
                const subTaskIds = checkedData
                    .filter(
                        (item) =>
                            !item.parentId &&
                            [
                                TaskStatus.AWAITING_APPROVAL,
                                TaskStatus.STARTED,
                                TaskStatus.TODO,
                                TaskStatus.OVERDUE
                            ].includes(item.status)
                    )
                    .map((item) => item.subTaskId)
                await taskService.rejectSubTask({subtaskIds: subTaskIds, reason})
            } else {
                const taskUserIds = checkedData
                    .filter((item) => item.isChecked && !!item.parentId && item.status === TaskStatus.TODO)
                    .map((item) => item.taskUserId)
                await taskService.rejectTaskUser({taskUserIds, reason})
            }
            setReason("")
            toastSuccess("Not Approved successful")
            onClose()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onClickApprove = async () => {
        try {
            setIsSubmitting(true)
            if (confirmPopupActionFor === TaskActionFor.SUBTASK) {
                const subTaskIds = checkedData
                    .filter((item) => !item.parentId && item.status === TaskStatus.AWAITING_APPROVAL)
                    .map((item) => item.subTaskId)
                await taskService.approveSubTask({subtaskIds: subTaskIds})
            }
            setReason("")
            toastSuccess("Approve successful")
            onClose()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onClickReopen = async () => {
        try {
            setIsSubmitting(true)
            if (confirmPopupActionFor === TaskActionFor.TASK_USER) {
                const taskUser = checkedData.find((item) => item.isChecked)
                await taskService.resendTaskUser({taskUserId: taskUser.taskUserId, message: reason})
            }
            setReason("")
            toastSuccess("Reopen successful")
            onClose()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        if (isShow) {
            getData()
        }
    }, [page, pageSize, isShow])

    const renderIconByType = () => {
        switch (type) {
            case TaskAction.APPROVE:
                return "CHECKMARK_CIRCLE"
            case TaskAction.REJECT:
                return "CROSS_CIRCLE_FILL"
            case TaskAction.REOPEN:
                return "PERSON_DONE"
            default:
                return "CHECKMARK_CIRCLE"
        }
    }

    const renderTitleByType = () => {
        switch (type) {
            case TaskAction.APPROVE:
                return "Approve task"
            case TaskAction.REJECT:
                return "Reject task"
            case TaskAction.REOPEN:
                return "Reopen task"
            default:
                return ""
        }
    }

    const renderDescriptionByType = () => {
        switch (type) {
            case TaskAction.APPROVE:
                return "Are you sure you want to approve the task"
            case TaskAction.REJECT:
                return "Are you sure you want to Not approved the task"
            case TaskAction.REOPEN:
                return "Are you sure you want to reopen the task"
            default:
                return ""
        }
    }

    const renderReason = () => {
        const title = type === TaskAction.REJECT ? "not approved" : "reopening"
        return (
            <div className={styles.reopenBody}>
                <p className={styles.body__title}>Reason for {title}</p>
                <BaseTextArea placeholder="Reason" value={reason} onChange={(newValue) => setReason(newValue)} />
            </div>
        )
    }

    useEffect(() => {
        const conditions =
            isShow &&
            type === TaskAction.REJECT &&
            confirmPopupActionFor === TaskActionFor.SUBTASK &&
            taskData.type === TaskType.CUSTOM
        if (conditions) {
            const taskNameFromJsonData = taskData?.jsonData?.originalName
            const taskNameFromSplit = taskData.name?.split("-(")?.slice(0, 1)[0]
            const originalTaskName = taskNameFromJsonData || taskNameFromSplit || taskData.name
            const url = taskData?.custom?.baseSignUrl || ""
            setReason(
                `This <${originalTaskName}> has been not approved. Please login to ${url}  to make the necessary corrections.`
            )
        }
        return () => {
            if (conditions) {
                setReason("")
            }
        }
    }, [isShow, type])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon={renderIconByType()}>
            <div className={styles.wrap}>
                <p className={styles.title}>{renderTitleByType()}</p>
                <div className={styles.body}>
                    <p className={styles.body__title}>{renderDescriptionByType()}</p>
                    <div className={styles.taskInfo}>
                        <p className={styles.taskName}>{taskData?.name}</p>
                        <p className={styles.taskCode}>{taskData?.code}</p>
                    </div>
                    <p className={styles.for}>For</p>
                    <KlassappTableHeader
                        isShowAction={false}
                        actions={[]}
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onChangeAllFields={props.onChangeAllFields}
                        onDraggableColumn={props.onDraggableColumn}
                    />
                    <KlassappTable
                        columns={columns}
                        fields={fields}
                        data={data}
                        menuActions={[]}
                        isLoading={isLoading}
                        allFields={allFields}
                        isShowCheckedColumn
                        orderField={orderField}
                        onClickRowItem={onClickRowItem}
                        onChangeFields={props.onChangeFields}
                        onToggleCheckHeader={() => {}}
                        onUpdateRowData={() => {}}
                        onUpdateTableData={props.onUpdateTableData}
                        onDraggableColumn={props.onDraggableColumn}
                        onChangeAllFields={props.onChangeAllFields}
                    />
                    {(type === TaskAction.REOPEN || type === TaskAction.REJECT) && renderReason()}
                </div>
                <div className={styles.action}>
                    <SecondaryButton title="Cancel" className={styles.cancelBtn} onClick={onClose} />
                    <BaseButton
                        title={type === TaskAction.REJECT ? "Not Approved" : type}
                        onClick={onClick}
                        loading={isSubmitting}
                    />
                </div>
            </div>
        </BasePopup>
    )
}

export default KlassappTableHOC(ConfirmPopup)
