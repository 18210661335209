/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {contactStrategyService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import {getFormattedContactStrategyName} from "types/contact-strategy"

type Props = Partial<KlassDropdownAsyncProps> & {}

const ContactStrategySelect: React.FC<Props> = ({...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            const {data} = await contactStrategyService.getAll({
                filter: {
                    search
                },
                range: {
                    page: 1,
                    pageSize: 20
                },
                sort: {
                    orderBy: "name",
                    orderDir: "ASC"
                },
                fields: ["contactStrategyId", "code", "name", "isActive", "departmentSubunitId"]
            })
            return data
        }, 500),
        []
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions
            valueKey="contactStrategyId"
            labelKey="name"
            getOptionLabel={getFormattedContactStrategyName}
        />
    )
}

export default ContactStrategySelect
