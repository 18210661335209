import React from "react"
import {keyBy} from "lodash"
import {PaginationPerPage} from "types/common"
import {KlassDropdown} from "components/Select"
import {Icon} from "components/Icon"
import styles from "./BasePagination.module.css"
import {useTranslation} from "react-i18next"
import cx from "classnames"

const rowPerPageOptions = [
    {id: PaginationPerPage.item10, name: PaginationPerPage.item10},
    {id: PaginationPerPage.item20, name: PaginationPerPage.item20},
    {id: PaginationPerPage.item50, name: PaginationPerPage.item50},
    {id: PaginationPerPage.item100, name: PaginationPerPage.item100}
]

const rowPerPageOptionsKeyById = keyBy(rowPerPageOptions, "id")

export function BasePagination(props) {
    const {
        page,
        total,
        defaultPageSize,
        onChangePage,
        onChangeRowPerPage,
        displayMode = "normal",
        isShowAllRecords
    } = props
    const {t} = useTranslation(["common"])
    let totalPage = Math.ceil(total / defaultPageSize)
    totalPage = totalPage <= 0 ? 1 : totalPage
    const itemCount = defaultPageSize * page
    const valueOption = rowPerPageOptionsKeyById[defaultPageSize] || rowPerPageOptionsKeyById[PaginationPerPage.item100]
    const isDisabledLeft = page === 1
    const isDisabledRight = page === totalPage

    const onClickGoToPrevPage = () => {
        if (isDisabledLeft) return
        onChangePage(page - 1)
    }

    const onClickGoToNextPage = () => {
        if (isDisabledRight) return
        onChangePage(page + 1)
    }

    const onClickGotoFirstPage = () => {
        if (isDisabledLeft) return
        onChangePage(1)
    }

    const onClickGoToLastPage = () => {
        if (isDisabledRight) return
        onChangePage(totalPage)
    }

    if (isShowAllRecords) {
        return (
            <div className={styles.wrap}>
                <p className={styles.totalTitle}>
                    <span className={styles.totalCount}>{total}</span> {t("common:message.items")}
                </p>
            </div>
        )
    }

    // panel display
    if (displayMode === "panel") {
        return (
            <div className={styles.panelWrap}>
                <div className={styles.panelInfo}>
                    <div className={styles.panelTotalTitle}>
                        <div className={styles.panelTotalCount}>{total}</div>
                        <div className={styles.panelTotalDesc}>{t("common:message.items")}</div>
                    </div>
                    <div className={styles.panelDivider} />
                    <div className={styles.panelPaginationInfo}>
                        {page}/{totalPage}
                    </div>
                    <div className={styles.panelDivider} />
                    <div className={styles.panelRowPerPage}>
                        {defaultPageSize * (page - 1) + 1}-{itemCount > total ? total : itemCount}
                    </div>
                </div>
                <div className={styles.panelPaginationAction}>
                    <div
                        className={`${styles.panelPaginationActionItem} ${
                            isDisabledLeft ? styles.actionItemDisabled : ""
                        }`}
                        onClick={onClickGotoFirstPage}>
                        <Icon
                            icon="SKIP_PAGE_RIGHT"
                            color={isDisabledLeft ? "#e5e5e5" : "#666"}
                            className={cx(styles.panelSvg, styles.rotate)}
                        />
                    </div>
                    <div
                        className={cx(styles.panelPaginationActionItem, {[styles.actionItemDisabled]: isDisabledLeft})}
                        onClick={onClickGoToPrevPage}>
                        <Icon
                            icon="ARROW_LEFT"
                            color={isDisabledLeft ? "#e5e5e5" : "#666"}
                            className={styles.panelSvg}
                        />
                    </div>
                    <div
                        className={`${styles.panelPaginationActionItem} ${
                            isDisabledRight ? styles.actionItemDisabled : ""
                        }`}
                        onClick={onClickGoToNextPage}>
                        <Icon
                            icon="ARROW_RIGHT_1"
                            color={isDisabledRight ? "#e5e5e5" : "#666"}
                            className={styles.panelSvg}
                        />
                    </div>
                    <div
                        className={`${styles.panelPaginationActionItem} ${
                            isDisabledRight ? styles.actionItemDisabled : ""
                        }`}
                        onClick={onClickGoToLastPage}>
                        <Icon
                            icon="SKIP_PAGE_RIGHT"
                            color={isDisabledRight ? "#e5e5e5" : "#666"}
                            className={styles.panelSvg}
                        />
                    </div>
                </div>
            </div>
        )
    }

    // normal display
    return (
        <div className={styles.wrap}>
            <p className={styles.totalTitle}>
                <span className={styles.totalCount}>{total}</span> {t("common:message.items")}
            </p>
            <p className={styles.title}>{t("common:message.rows")}</p>
            <div className={styles.dropdown}>
                <KlassDropdown
                    options={rowPerPageOptions}
                    value={valueOption}
                    placeholder={t("common:message.rowPerPage")}
                    onChange={(newValue) => onChangeRowPerPage(newValue.id)}
                    stylesCustom={{
                        control: (provided, state) => {
                            return {
                                ...provided,
                                borderRadius: "6px",
                                border: "none",
                                boxShadow: state?.selectProps?.error ? "none" : "1px 2px 3px 1px var(--black-100)",
                                minHeight: "36px",
                                fontSize: 13
                            }
                        }
                    }}
                />
            </div>
            <div className={styles.rowPerPage}>
                {defaultPageSize * (page - 1) + 1}-{itemCount > total ? total : itemCount}
            </div>
            <div className={styles.paginationInfo}>
                <p className={styles.paginationInfo__page}>{page}</p>
                <p className={styles.paginationInfo__text}>of</p>
                <p className={styles.paginationInfo__page}>{totalPage}</p>
            </div>
            <div className={styles.paginationAction}>
                <div
                    className={`${styles.paginationActionItem} ${isDisabledLeft ? styles.actionItemDisabled : ""}`}
                    onClick={onClickGotoFirstPage}>
                    <Icon
                        icon="SKIP_PAGE_RIGHT"
                        color={isDisabledLeft ? "#e5e5e5" : "#666"}
                        className={styles.rotate}
                    />
                </div>
                <div
                    className={`${styles.paginationActionItem} ${isDisabledLeft ? styles.actionItemDisabled : ""}`}
                    onClick={onClickGoToPrevPage}>
                    <Icon icon="ARROW_LEFT" color={isDisabledLeft ? "#e5e5e5" : "#666"} />
                </div>
                <div
                    className={`${styles.paginationActionItem} ${isDisabledRight ? styles.actionItemDisabled : ""}`}
                    onClick={onClickGoToNextPage}>
                    <Icon icon="ARROW_RIGHT_1" color={isDisabledRight ? "#e5e5e5" : "#666"} />
                </div>
                <div
                    className={`${styles.paginationActionItem} ${isDisabledRight ? styles.actionItemDisabled : ""}`}
                    onClick={onClickGoToLastPage}>
                    <Icon icon="SKIP_PAGE_RIGHT" color={isDisabledRight ? "#e5e5e5" : "#666"} />
                </div>
            </div>
        </div>
    )
}
