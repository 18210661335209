/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {useTranslation} from "react-i18next"
import {Tabs} from "antd"
import {KlassappTableHOC} from "HOC"
import {useCurrentProfilePermissions, useActiveTab} from "hooks"
import {Screen} from "components/ui"
import {TabHeader} from "components/Tab"
import {Permissions} from "types/permission"
import {EmailTemplates, SMSTemplates} from "./parts"

const {TabPane} = Tabs

export enum SettingTemplatesTabs {
    Email = "email",
    SMS = "sms"
}

function Templates(props) {
    const {t} = useTranslation(["templates", "settings", "common"])
    const currentProfilePermissions = useCurrentProfilePermissions()

    const [activeTabKey, onChangeTab] = useActiveTab(SettingTemplatesTabs.Email)

    return (
        <Screen
            htmlTitle={t("templates:templates")}
            header={{
                title: t("templates:templates")
            }}>
            <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab} destroyInactiveTabPane>
                {currentProfilePermissions.hasPermissions({
                    staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.View]
                }) && (
                    <TabPane
                        tab={<TabHeader title={t("templates:titleTemplates", {field: "Email"})} />}
                        key={SettingTemplatesTabs.Email}>
                        <EmailTemplates />
                    </TabPane>
                )}

                {currentProfilePermissions.hasPermissions({
                    staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.View]
                }) && (
                    <TabPane
                        tab={<TabHeader title={t("templates:titleTemplates", {field: "Text Messages"})} />}
                        key={SettingTemplatesTabs.SMS}>
                        <SMSTemplates />
                    </TabPane>
                )}
            </Tabs>
        </Screen>
    )
}

export default KlassappTableHOC(Templates)
