import React, {useRef} from "react"
import cx from "classnames"
import {SecondaryButton} from "components/buttons"
import style from "./ExportButton.module.css"
import {Dropdown, Menu} from "antd"

export type ExportFileType = "csv" | "excel" | "pdf"

type Option = {
    type: ExportFileType
    label: string
}

const EXPORT_FILE_TYPE_OPTIONS: Option[] = [
    {
        type: "csv",
        label: "CSV"
    },
    {
        type: "excel",
        label: "EXCEL"
    },
    {
        type: "pdf",
        label: "PDF"
    }
]

const ALL_FILE_TYPES = EXPORT_FILE_TYPE_OPTIONS.map((o) => o.type)

type Props = {
    className?: string
    upperCaseLabel?: boolean
    classNameButton?: string
    classNameDropdown?: string
    label?: string
    availableFileTypes?: ExportFileType[]
    onSelect?: (type: ExportFileType) => void
    onClick?: () => void
    isLoading?: boolean
}

export const ExportButton = ({
    classNameDropdown,
    upperCaseLabel = true,
    className,
    classNameButton,
    label = "EXPORT",
    onSelect,
    availableFileTypes = ALL_FILE_TYPES,
    onClick,
    isLoading
}: Props) => {
    const ref = useRef(null)
    const filteredOptions = EXPORT_FILE_TYPE_OPTIONS.filter((option) => availableFileTypes.includes(option.type))

    const handleOptionSelect = (type: ExportFileType) => () => {
        onSelect?.(type)
    }
    const menu = (
        <Menu className={cx(style.dropdown, classNameDropdown)} style={{borderRadius: 10}}>
            {filteredOptions.map((menuAction, index) => (
                <Menu.Item key={index} className={style.dropdownItem} onClick={handleOptionSelect(menuAction.type)}>
                    <span className={style.dropdownItem}>{menuAction.label}</span>
                </Menu.Item>
            ))}
        </Menu>
    )
    if (onClick) {
        return (
            <div className={cx(style.wrapper, className)} ref={ref}>
                <SecondaryButton
                    uppercase={upperCaseLabel}
                    className={classNameButton}
                    loading={isLoading}
                    title={label}
                    onClick={onClick}
                />
            </div>
        )
    }
    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <div className={cx(style.wrapper, className)} ref={ref}>
                <SecondaryButton
                    loading={isLoading}
                    uppercase={upperCaseLabel}
                    className={classNameButton}
                    title={label}
                />
            </div>
        </Dropdown>
    )
}
