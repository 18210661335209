import React from "react"
import styles from "./ProgressReportPdf.module.css"
import CourseCredit from "./CourseCredit"
import {get} from "lodash"
import moment from "moment-timezone"
import {StatusTerm} from "../CurrentProgress/CurrentProgress"
import {formatDateOnly, formatDecimal, getFieldLabel} from "helpers"
import {Settings} from "types/settings"
import {useModel} from "hooks"

type TermCourseCreditProps = {
    listTableDegreeAudit: {
        cgpa: number
        name: string
        startDate: string
        endDate: string
        termId: number
        totalCredit: number
        totalGpa: number
        type: "clock" | "credit"
    }[]
    profileId: number
}

const TermCourseCredit = (props: TermCourseCreditProps) => {
    const {listTableDegreeAudit = [], profileId} = props
    const model = useModel()

    const checkStatus = (startDate: string, endDate: string) => {
        const momentStart = moment(startDate).startOf("day")
        const momentEnd = moment(endDate).endOf("day")
        const today = moment()
        if (today.isSameOrAfter(momentStart) && today.isSameOrBefore(momentEnd)) {
            return StatusTerm.InProgress
        } else if (today.isAfter(momentEnd)) {
            return StatusTerm.Ended
        } else {
            return StatusTerm.NotStarted
        }
    }

    return (
        <div className={styles.tableContainer}>
            {listTableDegreeAudit.map((table, index) => {
                const status = checkStatus(get(table, ["startDate"], ""), get(table, ["endDate"], ""))
                const startDate = formatDateOnly(table.startDate, model.getUserDateFormat())
                const endDate = formatDateOnly(table.endDate, model.getUserDateFormat())
                const gpa = typeof table?.totalGpa === "number" ? formatDecimal(table?.totalGpa) : table?.totalGpa
                const cgpa = typeof table?.cgpa === "number" ? formatDecimal(table.cgpa) : table?.cgpa
                return (
                    <div key={index.toString()} className={styles.tableTerm}>
                        <div className={styles.tableTermTitle}>
                            <div className={styles.termName}>
                                <span className={styles.tableTitle}>{table.name}</span>
                                <span className={styles.tableTitle}>
                                    {startDate} - {endDate}
                                </span>
                            </div>
                            <div className={styles.tableTermTitle}>
                                <div className={styles.gpaBlock}>
                                    <span className={styles.cgpaText}>
                                        {getFieldLabel(model, Settings.AcademicLabel.Gpa, "GPA")}
                                    </span>
                                    <span className={styles.cgpaValue}>{gpa}</span>
                                </div>
                                <div className={styles.cgpaBlock}>
                                    <span className={styles.cgpaText}>
                                        {getFieldLabel(model, Settings.AcademicLabel.Cgpa, "CGPA")}
                                    </span>
                                    <span className={styles.cgpaValue}>{cgpa}</span>
                                </div>
                            </div>
                        </div>
                        <CourseCredit gpa={gpa} status={status} termId={table.termId} profileId={profileId} />
                    </div>
                )
            })}
        </div>
    )
}

export default TermCourseCredit
