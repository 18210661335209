import React, {useEffect, useRef, useState} from "react"
import {BasePopup} from "components/popup"
import styles from "./ChecklistForm.module.css"
import {BaseButton, BaseInput} from "components"
import {handleError, toastError} from "helpers"
import {CareerServices} from "types/careerServices"
import {FormLabel} from "components/Form"
import {KlassDropdown} from "components/Select"
import {ReactComponent as VideoIcon} from "./assets/video.svg"
import {ReactComponent as RemoveIcon} from "./assets/remove.svg"
import {getPreSignedUrl, uploadUsingPreSignedUrl} from "helpers/chat"
import {careerService} from "services"
import {Switch} from "components/inputs/Switch"

type Props = {
    item?: CareerServices.ReadinessChecklistEntity
    open: boolean
    onClose: () => void
    onSuccess?: () => void
}

const CHECKLIST_TYPE = {
    video: "Video",
    document: "Create document",
    advisor: "Advisor meeting"
}

type LocalFile = {
    url: string
    file?: any
}

export function ChecklistForm(props: Props) {
    const {item, open, onClose, onSuccess} = props
    const [checklist, setChecklist] = useState<Partial<CareerServices.ReadinessChecklistEntity>>({
        isRequired: true
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const isEdit = !!item
    const fileRef = useRef<any>()
    const [attachment, setAttachment] = useState<LocalFile>()

    useEffect(() => {
        if (item) {
            setChecklist(item)
            setAttachment({url: item?.videoUrl})
        } else {
            setChecklist({})
            setAttachment(undefined)
        }
    }, [item])

    let checklistTypes: any = []
    Object.entries(CHECKLIST_TYPE).forEach(([key, value]) => {
        checklistTypes.push({
            value: key,
            label: value
        })
    })

    const onOpenChooseFile = () => {
        if (isSubmitting) {
            return
        }
        fileRef.current.click()
    }

    const onClickUpload = (e) => {
        e.target.value = ""
    }

    const onChangeFile = async (e) => {
        if (isSubmitting) {
            return
        }
        const files = e.target.files
        if (files.length > 0) {
            const file = files[0]
            setAttachment({
                url: URL.createObjectURL(file),
                file: file
            })
        }
    }

    const onClickRemoveDocument = async () => {
        setAttachment(undefined)
    }

    const onSubmit = async () => {
        try {
            setIsSubmitting(true)
            if (!checklist.name || checklist.name.trim() === "" || !checklist.order || !checklist.type) {
                toastError("Please fill the information before submitting.")
                return
            }
            if (
                checklist.type === CareerServices.ReadinessChecklistType.Video &&
                (!attachment || (attachment.url === "" && !attachment.file))
            ) {
                toastError("Video checklist required uploading video")
                return
            }
            const {id, name, order, type, videoUrl, isRequired} = checklist
            let payload: Partial<CareerServices.ReadinessChecklistEntity> = {
                name,
                order: parseInt(order.toString()),
                type,
                videoUrl,
                isRequired
            }
            if (payload.type === CareerServices.ReadinessChecklistType.Video) {
                if (attachment.url !== payload.videoUrl) {
                    // upload new file
                    const fileExtension = attachment.file.name.split(".").pop()
                    const {url, urlForUploading} = await getPreSignedUrl(fileExtension)
                    await uploadUsingPreSignedUrl(urlForUploading, attachment.file)
                    payload.videoUrl = url
                }
            } else {
                payload.videoUrl = null
            }
            if (isEdit) {
                await careerService.updateReadinessChecklist({id, ...payload})
            } else {
                await careerService.createReadinessChecklist(payload)
            }
            onSuccess?.()
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onChangeData = (key: string, value: any) => {
        checklist[key] = value
        setChecklist({...checklist})
    }

    const renderAttachment = () => {
        if (attachment)
            return (
                <div className={styles.attachmentsWrap}>
                    <div className={styles.documentWrap}>
                        <VideoIcon className={styles.fileIcon} width={24} height={24} />
                        <div className={styles.documentFileName}>Uploaded Video</div>
                        <RemoveIcon
                            className={styles.removeIcon}
                            width={24}
                            height={24}
                            onClick={() => onClickRemoveDocument()}
                        />
                    </div>
                </div>
            )
    }

    return (
        <BasePopup
            isShow={open}
            onClose={onClose}
            leftIcon={isEdit ? "PENCIL" : "PLUS_CIRCLE"}
            leftIconColor="#fff"
            width="40vw"
            closable
            destroyOnClose>
            <div className={styles.headingTitle}>{isEdit ? "Edit checklist" : "Add checklist"}</div>
            <div className={styles.field}>
                <FormLabel label="Name" />
                <BaseInput
                    type="text"
                    value={checklist?.name}
                    onChange={(_value) => onChangeData("name", _value)}
                    placeholder="Name"
                />
            </div>
            <div className={styles.field}>
                <FormLabel label="Require completion" />
                <Switch
                    checked={checklist?.isRequired}
                    onClick={() => onChangeData("isRequired", !checklist?.isRequired)}
                />
            </div>
            <div className={styles.field}>
                <FormLabel label="Order" />
                <BaseInput
                    type="number"
                    min={0}
                    value={checklist?.order}
                    onChange={(_value) => onChangeData("order", _value)}
                    placeholder="Order"
                />
            </div>
            <div className={styles.field}>
                <FormLabel label="Type" />
                <KlassDropdown
                    value={checklist?.type && checklistTypes.filter((x) => x.value === checklist.type)[0]}
                    placeholder="Select"
                    onChange={(option: any) => onChangeData("type", option.value)}
                    valueKey="value"
                    labelKey="label"
                    options={checklistTypes}
                />
            </div>
            {checklist.type === CareerServices.ReadinessChecklistType.Video && (
                <div className={styles.field}>
                    <FormLabel label="Upload video" />
                    <div className={styles.uploadWrap}>
                        <input
                            ref={fileRef}
                            style={{display: "none"}}
                            type="file"
                            onChange={onChangeFile}
                            onClick={onClickUpload}
                            accept="video/*"
                        />
                        <BaseButton variant="secondary" title="Upload video" onClick={onOpenChooseFile} />
                        {renderAttachment()}
                    </div>
                </div>
            )}
            <div className={styles.doneBtn}>
                <BaseButton title="Submit" onClick={onSubmit} className={styles.saveBtn} loading={isSubmitting} />
            </div>
        </BasePopup>
    )
}
