import {useModel} from "hooks"
import {Documents} from "./Documents"
import {Screen} from "components/ui"
import {useMemo} from "react"
import {FilterKey} from "types/filter"

export function DocumentHub() {
    const model = useModel()

    const userId = useMemo(() => {
        return model.isStudent() ? model.user.id : undefined
    }, [model])

    return (
        <Screen
            htmlTitle="Document Hub"
            header={{
                title: "Document Hub"
            }}>
            <Documents userId={userId} filterKey={FilterKey.DocumentHub} />
        </Screen>
    )
}
