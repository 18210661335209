import React from "react"
import cx from "classnames"
import {Icon} from "components/Icon"
import {BarChart} from "components/Chart"
import styles from "./LeaveAbsence.module.css"
import {useTranslation} from "react-i18next"

export function LeaveAbsence(props) {
    const {t} = useTranslation(["student"])
    const chartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                data: [220, 170, 170, 220, 250, 200, 100, 200, 120, 220, 140, 146],
                backgroundColor: "#5540fb"
            },
            {
                data: [200, 190, 190, 220, 240, 180, 120, 180, 135, 200, 180, 150],
                backgroundColor: "#bbb3fd"
            }
        ]
    }

    const options = {
        tooltips: {
            enabled: true
        },
        plugins: {
            datalabels: {
                display: false
            }
        }
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.headerWrap}>
                <Icon icon="UNION" color="#5540FB" />
                <p className={styles.title}>{t("student.workspace.leaveOfAbsence")}</p>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.infoWrap}>
                <div className={styles.infoItem}>
                    <div className={cx(styles.infoItem__color, styles.loaColor)}></div>
                    <p className={styles.infoItem__title}>LOA</p>
                </div>
                <div className={styles.infoItem}>
                    <div className={cx(styles.infoItem__color, styles.returnColor)}></div>
                    <p className={styles.infoItem__title}>{t("student.workspace.return")}</p>
                </div>
            </div>
            <div className={styles.chartWrap}>
                <BarChart data={chartData} options={options} />
            </div>
        </div>
    )
}
