import React, {useReducer} from "react"
import {useTranslation} from "react-i18next"
import {Row, Col, Radio} from "antd"
import cx from "classnames"
import {keyBy} from "lodash"
import {KlassDropdown} from "components/Select"
import {BaseInput} from "components/inputs"
import {FormLabel} from "components/Form"
import {BaseButton, SecondaryButton} from "components/buttons"
import {Icon} from "components/Icon"
import {UserType, ParticipantType, TaskType} from "types/tasks"
import {toastError} from "helpers"
import styles from "./TaskSignerForm.module.css"

const USER_TYPE_OPTIONS = [
    {id: UserType.STUDENT, name: "Student"},
    {id: UserType.STAFF, name: "Staff"},
    {id: UserType.OTHERS, name: "Others"}
]
const USER_TYPE_BY_ID = keyBy(USER_TYPE_OPTIONS, "id")

function reducer(state, action) {
    return {...state, ...action}
}

export function TaskSignerForm(props) {
    const {t} = useTranslation(["tasks", "common"])
    const {onCancel, onSave, signer, type, taskType, primaryIndex, userDataOnlyIndex, index} = props
    const initialState = {
        signerData: {
            id: signer.id || "",
            userType: signer.userType || "",
            label: signer.label || "",
            participantType: signer.participantType || ParticipantType.PrimarySigner,
            isForm: !!signer.isForm
        }
    }
    const [{signerData}, dispatch] = useReducer(reducer, initialState)
    const cantChangePrimaryValue = primaryIndex !== -1 && primaryIndex !== index
    const cantChangeUserDataOnlyValue = userDataOnlyIndex !== -1 && userDataOnlyIndex !== index
    const isScanTask = taskType === TaskType.SCAN_UPLOAD

    const onChangeData = (key, value) => {
        if (type === TaskType.FROM_LIBRARY) {
            return
        }
        if (key === "participantType") {
            if (
                (cantChangePrimaryValue && value === ParticipantType.PrimarySigner) ||
                (cantChangeUserDataOnlyValue && value === ParticipantType.UserDataOnly)
            ) {
                return
            }
            switch (signerData.userType) {
                case UserType.STUDENT:
                    signerData.students = []
                    break
                case UserType.STAFF:
                    signerData.staffs = []
                    break
                case UserType.OTHERS:
                    signerData.others = []
                    break
                default:
                    break
            }
        }
        signerData[key] = value
        dispatch({signerData})
    }

    const resetData = () => {
        dispatch({signerData: {userType: "", label: "", participantType: ParticipantType.PrimarySigner}})
    }

    const onClickCancel = () => {
        onCancel(signerData)
        resetData()
    }

    const onClickSave = () => {
        if (!signerData.userType) {
            toastError(t("common:validation.hasToBeSelect", {field: "User Type"}))
            return
        }
        if (!signerData.label) {
            toastError(t("common:validation.cantEmpty", {field: "Label"}))
            return
        }
        onSave(signerData)
        resetData()
    }

    return (
        <div className={styles.wrap}>
            <Row gutter={[20, 20]}>
                <Col span={8}>
                    <div className={styles.formItemWrap}>
                        <FormLabel label="User Type" isRequired />
                        <KlassDropdown
                            options={USER_TYPE_OPTIONS}
                            value={USER_TYPE_BY_ID[signerData.userType]}
                            onChange={(value) => onChangeData("userType", value.id)}
                        />
                    </div>
                </Col>
                <Col span={16}>
                    <div className={styles.formItemWrap}>
                        <FormLabel label="Label" isRequired />
                        <BaseInput
                            value={signerData.label}
                            placeholder="Label"
                            onChange={(value) => onChangeData("label", value)}
                        />
                    </div>
                </Col>
                <Col span={isScanTask ? 8 : 24}>
                    <div className={styles.formItemWrap}>
                        <Radio.Group
                            onChange={(event) => onChangeData("participantType", event.target.value)}
                            value={signerData.participantType}
                            className={styles.radioBtnWrap}>
                            <Radio
                                value={ParticipantType.PrimarySigner}
                                className={cx(styles.radioBtn__item, {
                                    [styles.radioBtn__active]:
                                        signerData.participantType === ParticipantType.PrimarySigner
                                })}>
                                {t("taskDetail.signers.primary")}
                            </Radio>
                            {!isScanTask && (
                                <>
                                    <Radio
                                        value={ParticipantType.NonPrimarySigner}
                                        className={cx(styles.radioBtn__item, {
                                            [styles.radioBtn__active]:
                                                signerData.participantType === ParticipantType.NonPrimarySigner
                                        })}>
                                        {t("taskDetail.signers.nonPrimary")}
                                    </Radio>
                                    <Radio
                                        value={ParticipantType.UserDataOnly}
                                        className={cx(styles.radioBtn__item, {
                                            [styles.radioBtn__active]:
                                                signerData.participantType === ParticipantType.UserDataOnly
                                        })}>
                                        {t("taskDetail.signers.userDataOnly")}
                                    </Radio>
                                </>
                            )}
                        </Radio.Group>
                    </div>
                </Col>
                <Col span={24}>
                    {cantChangePrimaryValue && (
                        <div className={styles.primaryInfo}>
                            <div className={styles.primaryInfo__iconWrap}>
                                <Icon icon="INFO" className={styles.primaryInfo__icon} />
                            </div>
                            <p className={styles.primaryInfo__text}>
                                Participant {primaryIndex + 1} Has been selected as Primary Signer
                            </p>
                        </div>
                    )}
                </Col>
            </Row>
            <div className={styles.actionBtnWrap}>
                <SecondaryButton
                    title={t("common:action.cancel")}
                    className={styles.cancelBtn}
                    onClick={onClickCancel}
                />
                <BaseButton title={t("common:action.save")} onClick={onClickSave} />
            </div>
        </div>
    )
}
