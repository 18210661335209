import {Timestamps} from "./common"
import {DepartmentSubunit} from "./departments"

export enum UserDocumentStatus {
    Pending = "pending",
    Approved = "approved",
    Rejected = "rejected"
}

export type UserDocument = {
    userDocumentId: number
    code: string
    name: string
    description: string
    departmentSubunitId: number
    departmentSubunit?: DepartmentSubunit // populate
    status: UserDocumentStatus
    documentUrl: string
    ownerUserId: number
    ownerUserProfileId: number | null
    termId: number | null
    statusUpdatedAt: string | null
    isVisibleForUser: boolean | 0 | 1
    statusUpdatedByUserProfileId: number | null
    lastUpdatedByUserProfileId: number | null
} & Timestamps

export enum DocumentAction {
    Delete = "DELETE",
    Approve = "APPROVE",
    Reject = "REJECT"
}
