import {useQuery} from "@tanstack/react-query"
import {majorService} from "services"

const queryCacheKey = "programs-by-term"
const INITIAL_DATA = []

export const useProgramsByTerm = ({termIds}: {termIds?: number[]}) => {
    const query = useQuery(
        [queryCacheKey, termIds],
        async () => {
            if (termIds?.length) {
                const {data} = await majorService.getMajorByTerm(termIds)
                return data
            }
        },
        {
            enabled: !!termIds?.length
        }
    )

    return {
        programs: query.data ?? INITIAL_DATA
    }
}
