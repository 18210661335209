import {ClockHoursTranscriptSection} from "types/clock-hours-transcript"
import styles from "./ProgressReportPdf.module.css"
import {chunk, cloneDeep} from "lodash"
import {formatDecimal, formatDecimalWithoutRound} from "helpers"

const COLUMNS_COUNT = 3

type ClockHoursTranscriptSectionsProps = {
    data: ClockHoursTranscriptSection[]
}

export const ClockHoursTranscriptSections = ({data}: ClockHoursTranscriptSectionsProps) => {
    if (!data?.length) {
        return null
    }

    return (
        <div>
            {data.map((section) => {
                let courses = cloneDeep(section.courses)
                const coursesPerColumn = Math.ceil(courses.length / COLUMNS_COUNT)
                const additionalCoursesCount = COLUMNS_COUNT - Math.ceil(courses.length % COLUMNS_COUNT)
                courses = courses.concat(
                    Array(additionalCoursesCount).fill({courseName: "", courseCode: "", credits: undefined})
                )
                return (
                    <div className={styles.tableContainer}>
                        <div className={styles.subjectBlock}>
                            <div style={{width: "50%"}}>
                                <span className={styles.subjectTitle}>Subject:</span>
                                <span className={styles.subjectValue}>{section.sectionTitle}</span>
                            </div>
                            <div>
                                <span className={styles.subjectTitle}>Grade:</span>
                                <span className={styles.subjectValue}>
                                    {typeof section.avgGpa === "number" ? formatDecimal(section.avgGpa) : "NA"}
                                </span>
                            </div>
                            <div>
                                <span className={styles.subjectTitle}>Completed:</span>
                                <span className={styles.subjectValue}>No</span>
                            </div>
                        </div>
                        <div className={styles.tableBlockClockHoursTranscript}>
                            {chunk(courses, coursesPerColumn).map((courses) => {
                                return (
                                    <table key={section.sectionId} className={styles.tableClockHoursTranscript}>
                                        <thead>
                                            <tr>
                                                <th className={styles.tableHeaderText}>
                                                    <div className={styles.headerTransfer}>
                                                        <span className={styles.blockSeparatorHeader}></span>
                                                    </div>
                                                </th>
                                                <th className={styles.tableHeaderText}>
                                                    <div className={styles.headerTransfer}>
                                                        <span className={styles.blockSeparatorHeader}>Grade</span>
                                                    </div>
                                                </th>
                                                <th className={styles.tableHeaderText}>
                                                    <div className={styles.headerTransfer}>
                                                        <span className={styles.blockSeparatorHeader}>Hrs</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {courses.map((course) => {
                                                return (
                                                    <tr style={{height: 50}} key={course.courseId}>
                                                        <td className={styles.tableColumn}>{course.courseName}</td>
                                                        <td className={styles.tableColumn}>
                                                            {formatDecimalWithoutRound(course.gpa ?? "")}
                                                        </td>
                                                        <td className={styles.tableColumn}>{course.credits}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
