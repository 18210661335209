/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {Auth} from "types/auth"
import {StudentChat} from "../StudentChat"

type Props = {
    student?: Auth.DepartmentStudent
    departmentId?: number
    onClose?: () => void
}

export const MessagePanel = (props: Props) => {
    const {student, departmentId} = props

    return <StudentChat user={student} userIdField="userId" departmentId={departmentId} isSidebarChat />
}
