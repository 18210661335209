import React from "react"
import {DatePicker} from "antd"
import {Icon} from "components/Icon"
import cx from "classnames"
import styles from "./BaseDatePicker.module.css"
import {useModel} from "hooks"

type DatePickerComponentsProps = {[key in string]: any}

export type BaseDatePickerProps = DatePickerComponentsProps & {
    value?: any
    onChange?: (value: any, text: string) => void
    className?: string
    placeholder?: string
    format?: string
    disabled?: boolean
    readOnly?: boolean
    error?: boolean
    suffixIcon?: JSX.Element
    disabledDate?: (currentDate: any) => boolean
}

export function BaseDatePicker(props: BaseDatePickerProps) {
    const model = useModel()
    const {onChange, className, format, placeholder, error, readOnly, disabled, ...rest} = props
    const currentUserDateFormat = model && model.getUserDateFormat && model.getUserDateFormat()

    return (
        <DatePicker
            onChange={onChange}
            className={cx(styles.datePicker, className, {
                [styles.error]: error,
                [styles.disabled]: disabled,
                [styles.readOnly]: readOnly
            })}
            suffixIcon={
                <div className={styles.calendarIconWrap}>
                    <Icon icon="CALENDAR_INPUT" color="#fff" />
                </div>
            }
            placeholder={placeholder || currentUserDateFormat || "MM/DD/YYYY"}
            format={format || currentUserDateFormat || "MM/DD/YYYY"}
            disabled={disabled || readOnly}
            {...rest}
        />
    )
}

BaseDatePicker.defaultProps = {
    suffixIcon: (
        <div className={styles.calendarIconWrap}>
            <Icon icon="CALENDAR_INPUT" color="#fff" />
        </div>
    )
}
