import {Icon} from "components"
import {QRCodeSVG} from "qrcode.react"
import styles from "./QRScan.module.css"
import {AttendanceEventType} from "types/attendance"
import {useEffect, useState} from "react"
import cx from "classnames"
import {useInterval} from "react-use"
import useTOPT from "hooks/useTOPT"
import {MakeupAttendanceType} from "../EditMakeupAttendance/EditMakeupAttendance"

type Props = {
    selectedAttendance?: MakeupAttendanceType
    showAsStandalone?: boolean
}

export const QRScan = (props: Props) => {
    const {selectedAttendance, showAsStandalone = false} = props
    const [currentEventType, setCurrentEventType] = useState<AttendanceEventType>(AttendanceEventType.BreakStart)
    const getOtp = useTOPT()
    const [qrCode, setQrCode] = useState<string>(undefined)
    const [totp, setTOtp] = useState<string>()
    const [countDown, setCountdown] = useState(-1)

    useInterval(() => {
        const {otp, timeLeft} = getOtp()
        setTOtp(otp)
        setCountdown(timeLeft)
    }, 1000)

    useEffect(() => {
        if (totp) {
            const qrData = {
                isMakeup: true,
                token: totp,
                eventType: selectedAttendance ? currentEventType : AttendanceEventType.Checkin,
                academicMakeupStudentId: selectedAttendance?.academicMakeupStudentId,
                studentProfileId: selectedAttendance?.studentProfileId,
                comments: "Makeup Attendance"
            }
            setQrCode(JSON.stringify(qrData))
        }
    }, [totp, selectedAttendance, currentEventType])

    return (
        <>
            {selectedAttendance ? (
                <>
                    <div className={styles.actions}>
                        <div
                            className={cx(styles.buttonAction, {
                                [styles.active]: [
                                    AttendanceEventType.BreakStart,
                                    AttendanceEventType.BreakEnd
                                ].includes(currentEventType)
                            })}
                            onClick={() => setCurrentEventType(AttendanceEventType.BreakStart)}>
                            <Icon icon="ATTENDANCE_IN" className={styles.eventIcon} color={"#1e90ff"} />
                            <span className={styles.buttonText}>BREAK</span>
                        </div>
                        <div
                            className={cx(styles.buttonAction, {
                                [styles.active]: currentEventType === AttendanceEventType.Checkout
                            })}
                            onClick={() => setCurrentEventType(AttendanceEventType.Checkout)}>
                            <span className={styles.buttonText}>CHECKOUT</span>
                            <Icon icon="CHECKOUT_FILL" className={styles.eventIcon} color={"#1e90ff"} />
                        </div>
                    </div>
                </>
            ) : null}
            <div className={cx(styles.content, {[styles.standaloneContent]: showAsStandalone})}>
                <div className={styles.qrInfoWrap}>
                    <span className={styles.detail}>
                        {selectedAttendance ? "SCAN QR ON YOUR DEVICE" : "SCAN QR ON YOUR DEVICE TO CHECKIN"}
                    </span>
                    <span className={styles.countDownText}>{countDown >= 0 ? countDown : ""}</span>
                </div>
                <QRCodeSVG width={280} height={280} value={qrCode} />
            </div>
        </>
    )
}
