/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react"
import {Spin} from "antd"
import {chain, sortBy} from "lodash"
import {useTranslation} from "react-i18next"
import {AuthNetEnvironment, DispatchDataResponse, useAcceptJs} from "react-acceptjs"

import {Label} from "components/inputs/Label"
import {BaseButton, BaseInput} from "components"
import {KlassDropdown} from "components/Select"
import {handleError, handleErrorMessage} from "helpers"
import {useModel} from "hooks"
import {addressService, studentPaymentService} from "services"
import styles from "./AuthorizeNetPayment.module.css"
import {GeneralPayment} from "types/student-account/ledger-accounts"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import {StudentCharge} from "types/student-account/student-ledger"
import {PaymentPlanItem} from "types/student-account/payment-plan"

type Props = {
    providerConfig
    studentId
    paymentAmount
    currency
    isCheckingOut: boolean
    selectedPayment?: GeneralPayment
    customNote: string
    setCurrencyMinAmount: Function
    payerFullName?: string
    selectedCharges?: StudentCharge[]
    selectedPaymentItems?: PaymentPlanItem[]
    academicYear?: number
}

interface BasicCardInfo {
    fullName: string
    zip: string
    cardNumber: string
    cardCode: string
    month: string
    year: string
}

interface FormBasicCardInfo {
    firstName: string
    lastName: string
    country: any
    state: any
    zip: string
    cardNumber: string
    cardCode: string
    expiry: string
}

const AUTHORIZE_NET_ENVIRONMENT: AuthNetEnvironment = (
    (process.env.REACT_APP_AUTHORIZE_NET_ENVIRONMENT as any) ?? ""
).toUpperCase()

export const AuthorizeNetPayment = (props: Props) => {
    const {
        providerConfig,
        studentId,
        paymentAmount,
        currency,
        isCheckingOut,
        selectedPayment,
        customNote,
        setCurrencyMinAmount,
        payerFullName,
        selectedCharges,
        selectedPaymentItems,
        academicYear
    } = props

    const model = useModel()
    const {t} = useTranslation(["user", "common"])

    const authData = {
        apiLoginID: providerConfig?.loginId,
        clientKey: providerConfig?.clientKey
    }

    const {dispatchData, loading, error} = useAcceptJs({
        environment: AUTHORIZE_NET_ENVIRONMENT,
        authData
    })
    const [cardData, setCardData] = useState<FormBasicCardInfo>({
        firstName: "",
        lastName: "",
        country: null,
        state: null,
        zip: "",
        cardNumber: "",
        expiry: "",
        cardCode: ""
    })
    const [studentPayment, setStudentPayment] = useState<any | undefined>()
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [isDropDown, setIsDropDown] = useState(false)
    const {setPaymentStatus, setPaymentError} = useContext(StudentPaymentContext)

    useEffect(() => {
        searchCountries()
    }, [])

    useEffect(() => {
        if (cardData.country?.label === "United States" || cardData.country === "United States") {
            setIsDropDown(true)
            searchStates("", cardData.country?.value)
            if (typeof cardData.state === "string") {
                setCardData((prev) => ({
                    ...prev,
                    state: ""
                }))
            }
        } else {
            if (typeof cardData.state === "object") {
                setCardData((prev) => ({
                    ...prev,
                    state: ""
                }))
            }
            setIsDropDown(false)
        }
    }, [cardData.country])

    const searchCountries = async (search = "") => {
        try {
            const params = !search ? {} : {filter: {search}}
            const response = await addressService.getCountries(params)
            const countries = response.data
                .filter((country) => !!country.name)
                .map((country) => ({value: country.countryId, label: country.name}))
            setCountries(countries)
            return countries
        } catch (e) {
            handleError(e)
        }
    }

    const searchStates = async (search = "", countryId = 0) => {
        try {
            const params = {
                filter: {
                    search,
                    countryId
                }
            }
            if (!search) {
                delete params.filter.search
            }
            const response = await addressService.getStates(params)
            const states = chain(response.data)
                .groupBy("type")
                .map((value, key) => ({
                    label: key,
                    options: sortBy(value, ["name"]).map((state) => ({value: state.stateId, label: state.name}))
                }))
                .value()
            setStates(states)
            return states
        } catch (e) {
            handleError(e)
        }
    }

    useEffect(() => {
        if (providerConfig) {
            setCurrencyMinAmount(providerConfig.minimumChargeAmountInCentsForCurrency)
        }
    }, [providerConfig])

    useEffect(() => {
        if (!model.user || !countries?.length) {
            return
        }

        ;(async function loadStudentData() {
            let country: any = model.user.country ?? null
            let state: any = model.user.state ?? null

            if (country) {
                country = countries.find((el) => el.label === country) ?? null
            }

            if (country?.label === "United States") {
                setIsDropDown(true)
                const statesData = await searchStates(state, country.value)
                state = statesData[0]?.options?.find((el) => el.label === state) ?? null
            } else {
                setIsDropDown(false)
            }

            setCardData((prev) => ({
                ...prev,
                firstName: model.user.firstName ?? "",
                lastName: model.user.lastName ?? "",
                country: country,
                state,
                zip: model.user.postalCode ?? ""
            }))
        })()
    }, [model.user, countries])

    useEffect(() => {
        if (!studentId || !paymentAmount || !isCheckingOut) {
            setStudentPayment(undefined)
            return
        }

        ;(async function initStudentPayment() {
            try {
                const {studentPayment} = await studentPaymentService.createAuthorizeNetStudentPayment({
                    amountInCents: Math.floor(paymentAmount * 100),
                    currency,
                    studentProfileId: studentId,
                    generalPaymentId: selectedPayment?.generalPaymentId,
                    payerFullName,
                    academicYear,
                    paymentPlanItems: selectedPaymentItems?.length
                        ? selectedPaymentItems
                              .filter((item) => item.paymentAmount)
                              .map((item) => ({
                                  paymentPlanId: item.paymentPlanId,
                                  paymentPlanItemId: item.paymentPlanItemId,
                                  amount: paymentAmount
                              }))
                        : undefined,
                    notes: `${selectedPayment?.name || ""}${customNote ? `: ${customNote}` : ""}`
                })
                if (selectedCharges?.length) {
                    await studentPaymentService.assignPaymentCharges(
                        studentPayment.paymentId,
                        selectedCharges.map((charge) => ({chargeId: charge.chargeId, amount: charge.paymentAmount}))
                    )
                }
                setStudentPayment(studentPayment)
            } catch (error) {
                handleError(error)
            }
        })()
    }, [
        studentId,
        isCheckingOut,
        paymentAmount,
        currency,
        selectedPayment,
        customNote,
        payerFullName,
        selectedCharges,
        selectedPaymentItems
    ])

    const validateBeforeSubmit = () => {
        const attrs = {
            firstName: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "First Name"})},
            lastName: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Last Name"})},
            country: {type: "object", errorMessage: t("common:validation.hasToBeSelect", {field: "Country"})},
            zip: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Zip"})},
            cardNumber: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Card Number"})},
            expiry: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Expiry"})},
            cardCode: {type: "string", errorMessage: t("common:validation.cantEmpty", {field: "Card Code"})}
        }
        for (let [key, value] of Object.entries(attrs)) {
            if (!cardData[key]) {
                handleErrorMessage(value.errorMessage)
                return true
            } else if (
                (value.type === "string" && cardData[key].trim() === "") ||
                (value.type === "array" && cardData[key].length < 1) ||
                (value.type === "object" && Object.keys(cardData[key]).length < 1)
            ) {
                handleErrorMessage(value.errorMessage)
                return true
            }
        }
        if (isDropDown) {
            if (Object.keys(cardData.state).length < 1) {
                handleErrorMessage(t("common:validation.hasToBeSelect", {field: "State"}))
                return true
            }
        } else if (cardData.state.trim() === "") {
            handleErrorMessage(t("common:validation.cantEmpty", {field: "State"}))
            return true
        }
        return false
    }

    const handleSubmit = async (event) => {
        try {
            event.preventDefault()

            // validate filled data
            const {firstName, lastName, country, state, zip, cardNumber, cardCode, expiry} = cardData
            if (validateBeforeSubmit()) {
                return
            }

            setPaymentStatus("processing")
            setPaymentError(undefined)

            // Dispatch CC data to Authorize.net and receive payment nonce for use on your server
            const fullName = `${firstName} ${lastName}`
            const expiryData = (expiry ?? "").split("/")
            const newCardData: BasicCardInfo = {
                fullName,
                zip,
                cardNumber: (cardNumber ?? "").replace(/\s/g, ""),
                cardCode,
                month: expiryData[0] ?? "",
                year: expiryData[1] ?? ""
            }
            const response: DispatchDataResponse = await dispatchData({cardData: newCardData})

            // complete payment at server
            const paymentResult = await studentPaymentService.completeAuthorizeNetStudentPayment({
                paymentId: studentPayment.paymentId,
                payerFirstName: firstName,
                payerLastName: lastName,
                country: country.label,
                state: isDropDown ? state.label : state,
                zip,
                authorizeNetPaymentNonce: response
            })
            setPaymentStatus("confirmed")
            if (paymentResult) {
                window.location.href = `${window.location.origin}?paymentId=${studentPayment.paymentId}`
            }
        } catch (error: DispatchDataResponse | any) {
            if (error?.messages?.message[0]?.text) {
                setPaymentStatus("failed")
                setPaymentError(error?.messages?.message[0]?.text || "")
            } else {
                setPaymentStatus("failed")
                setPaymentError(error.message || "")
            }
        }
    }

    return (
        <>
            {!authData || !studentPayment ? (
                <div>
                    <Spin />
                </div>
            ) : (
                <>
                    <div className={styles.payFields}>
                        <Label text={t("common:studentInfo.firstName")}>
                            <BaseInput
                                onChange={(value) => setCardData({...cardData, firstName: value})}
                                value={cardData.firstName}
                            />
                        </Label>
                    </div>
                    <div className={styles.payFields}>
                        <Label text={t("common:studentInfo.lastName")}>
                            <BaseInput
                                onChange={(value) => setCardData({...cardData, lastName: value})}
                                value={cardData.lastName}
                            />
                        </Label>
                    </div>
                    <div className={styles.payFields}>
                        <p className={styles.bodyTitle}>{t("user.country")}</p>
                        <KlassDropdown
                            value={cardData.country}
                            placeholder={t("common:selectField.placeholder")}
                            onChange={(option: any) => setCardData({...cardData, country: option})}
                            valueKey="value"
                            labelKey="label"
                            options={countries}
                        />
                    </div>
                    <div className={styles.payFields}>
                        <p className={styles.bodyTitle}>{t("user.state")}</p>
                        {isDropDown ? (
                            <KlassDropdown
                                value={cardData.state}
                                placeholder={t("common:selectField.placeholder")}
                                onChange={(option: any) => setCardData({...cardData, state: option})}
                                options={states}
                                valueKey="value"
                                labelKey="label"
                                isGrouped
                            />
                        ) : (
                            <BaseInput
                                onChange={(value) => setCardData({...cardData, state: value})}
                                placeholder={t("user.state")}
                                value={cardData.state}
                            />
                        )}
                    </div>
                    <div className={styles.payFields}>
                        <Label text={t("common:studentInfo.postalCode")}>
                            <BaseInput
                                onChange={(value) => setCardData({...cardData, zip: value})}
                                value={cardData.zip}
                            />
                        </Label>
                    </div>
                    <div className={styles.payFields}>
                        <Label text={"Card Number"}>
                            <BaseInput
                                onChange={(value) => {
                                    setCardData({
                                        ...cardData,
                                        cardNumber:
                                            (value ?? "").trim() !== ""
                                                ? value
                                                      .replace(/\s/g, "")
                                                      .match(/.{1,4}/g)
                                                      .join(" ")
                                                : value
                                    })
                                }}
                                value={cardData.cardNumber}
                                placeholder="xxxx xxxx xxxx xxxx"
                                maxLength={19}
                            />
                        </Label>
                    </div>
                    <div className={styles.payFields}>
                        <Label text={"Expiry"}>
                            <BaseInput
                                onChange={(value) =>
                                    setCardData({
                                        ...cardData,
                                        expiry:
                                            (value ?? "").trim().length > 2
                                                ? value.replace(/\//g, "").replace(/^(.{2})/, "$1/")
                                                : value
                                    })
                                }
                                value={cardData.expiry}
                                placeholder="mm/yyyy"
                                maxLength={7}
                            />
                        </Label>
                    </div>
                    <div className={styles.payFields}>
                        <Label text={"Card Code"}>
                            <BaseInput
                                onChange={(value) => setCardData({...cardData, cardCode: value})}
                                value={cardData.cardCode}
                                placeholder="xxx"
                                maxLength={3}
                            />
                        </Label>
                    </div>
                    <div className={styles.doneBtn}>
                        <BaseButton
                            title={"Pay"}
                            isActive
                            loading={loading}
                            onClick={handleSubmit}
                            disabled={loading || error}
                        />
                    </div>
                </>
            )}
        </>
    )
}
