import {BaseButton, BaseLoading, BasePopup} from "components"
import {useCallback, useEffect, useState} from "react"
import styles from "./EditSapStatusPopup.module.css"
import {FormLabel} from "components/Form"
import {handleError, toDateOnly} from "helpers"
import {useTranslation} from "react-i18next"
import {academicPlansService, userProfileServiceV3} from "services"
import {KlassDropdown} from "components/Select"
import {AcademicPlans} from "types/academicPlans"
import {OPTIONS_SAP_STATUS} from "sections/calendar/parts/common"

import moment from "moment"
import {BaseDatePicker} from "components/DateTimePicker"

type Props = {
    isShow: boolean
    onClose: () => void
    courseId?: number
    sapStatus?: AcademicPlans.SapStatus
    termId?: number
    studentProfileId: number
    onRefreshLda: () => Promise<void>
    sapDate?: string
    scheduleId?: number
}

export function EditSapStatusPopup(props: Props) {
    const {isShow, onClose, sapStatus, onRefreshLda, termId, courseId, scheduleId, studentProfileId, sapDate} = props
    const [isLoading, setIsLoading] = useState(false)
    const [editedSapStatus, setEditedSapStatus] = useState<{label: string; value: AcademicPlans.SapStatus}>()
    const [editedSapDate, setEditedSapDate] = useState(sapDate ? moment(toDateOnly(sapDate)) : moment())
    const {t} = useTranslation(["common"])

    useEffect(() => {
        if (sapStatus) {
            const currentSapStatus = OPTIONS_SAP_STATUS.find((item) => item.value === sapStatus)
            setEditedSapStatus(currentSapStatus)
        } else {
            setEditedSapStatus(undefined)
        }
        if (sapDate) {
            setEditedSapDate(moment(toDateOnly(sapDate)))
        }
    }, [sapStatus, sapDate])

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true)
            if (!courseId && termId) {
                await academicPlansService.updateTermSapStatus({
                    termId,
                    studentProfileId: studentProfileId,
                    sapStatus: editedSapStatus?.value,
                    sapDate: editedSapDate ? editedSapDate.format("YYYY-MM-DD") : undefined
                })
            } else if (courseId) {
                await academicPlansService.updateCourseSapStatus({
                    termId,
                    scheduleId,
                    courseId,
                    studentProfileId: studentProfileId,
                    sapStatus: editedSapStatus?.value,
                    sapDate: editedSapDate ? editedSapDate.format("YYYY-MM-DD") : undefined
                })
            } else {
                const submitDataUserProfile = {
                    id: studentProfileId,
                    sapStatus: editedSapStatus?.value,
                    sapDate: editedSapDate ? editedSapDate.format("YYYY-MM-DD") : undefined
                }
                await userProfileServiceV3.update(submitDataUserProfile)
            }
            await onRefreshLda()
            setIsLoading(false)
            onClose()
        } catch (err) {
            handleError(err)
            setIsLoading(false)
        }
    }, [courseId, editedSapStatus, onClose, onRefreshLda, scheduleId, studentProfileId, termId, editedSapDate])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_LINE_ALT" width="50vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Edit Sap Status</p>
                <FormLabel label="Sap Status" />
                <KlassDropdown
                    value={editedSapStatus}
                    onChange={(newValue) => {
                        setEditedSapStatus(newValue)
                    }}
                    options={OPTIONS_SAP_STATUS}
                    labelKey="label"
                    valueKey="value"
                    closeMenuOnSelect
                    placeholder="Sap Status"
                />
                <FormLabel className={styles.sapDateTitle} label="Sap Date" />
                <BaseDatePicker
                    value={editedSapDate}
                    onChange={(option) => setEditedSapDate(option)}
                    placeholder="Sap Date"
                />
                <div className={styles.submitContainer}>
                    <BaseButton
                        title={isLoading ? t("common:action.saving") : t("common:action.save")}
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
