import React, {useEffect, useState} from "react"
import cx from "classnames"
import {useTranslation} from "react-i18next"
import {Checkbox, Col, Modal, Row, Switch, Tooltip} from "antd"
import moment from "moment"
import {Icon} from "components/Icon"
import {BaseInput} from "components/inputs"
import {BaseButton, SecondaryButton} from "components"
import {KlassDropdown} from "components/Select"
import {BaseDatePicker, BaseTimePicker} from "components/DateTimePicker"
import {DailyRecurringBody, MonthlyRecurringBody, WeeklyRecurringBody} from "components/Calendar/Common/Recurring"
import {handleError, isInvalidValue, toastError, translate} from "helpers"
import {getDisabledEndHours, getDisabledEndMinutes, getExtraEventData} from "helpers/calendar"
import {chatService, eventTypeService} from "services"
import {CalendarRecurringType, RecurringEnd, RSVP} from "types/calendar"
import {
    OPTIONS_FREQUENCY as OPTIONS_FREQUENCY_ALL,
    OPTIONS_FREQUENCY_TYPE,
    REMINDERS
} from "sections/calendar/parts/common/constants"
import {EndBody, EndTitle} from "sections/calendar/parts/common"
import styles from "./ActivityCalendarEventPopup.module.css"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import produce from "immer"
import {UserAutocomplete} from "types/user"
import UserSelect from "components/UserSelect"
import {v4 as uuid} from "uuid"
import {useModel} from "hooks"
import {Auth} from "types/auth"
import StudentStatusSelect from "components/StudentStatusSelect"
import WorkflowSelect from "components/WorkflowSelect"
import {WorkflowTriggerElement} from "types/workflow"
import {pick} from "lodash"
import {NewStudentStatusSelect} from "components/NewStudentStatusSelect"

const OPTIONS_FREQUENCY = OPTIONS_FREQUENCY_ALL.map((opt) => ({
    value: opt.value,
    originalValue: opt.originalValue,
    label: translate(`calendar:calendar.frequency.${opt.label}`)
}))

const renderEventTypeOption = (option: Readonly<any>) => {
    return (
        <div className={styles.eventType}>
            <div className={styles.eventType__color} style={{backgroundColor: option.label}} />
            <div className={styles.eventType__label}>
                {option.name} ({option.code})
            </div>
        </div>
    )
}

const getMandatoryFields = (event) => {
    const mandatoryFields = [
        "name",
        "eventType",
        "startTime",
        "endTime",
        "startDate",
        "recurring",
        "hostUsers",
        "invitedUsers"
    ]
    const recurring = typeof event.recurring === "string" ? event.recurring : event.recurring.originalValue
    switch (recurring) {
        case "YEARLY": {
            if (event.end?.value === RecurringEnd.AfterExecution) {
                mandatoryFields.push("executions")
            } else if (event.end?.value === RecurringEnd.OnDate) {
                mandatoryFields.push("endDate")
            }
            break
        }
        case "MONTHLY": {
            mandatoryFields.push("every")
            if (+event.monthlyRecurring === 0) {
                /*day of the month*/
                mandatoryFields.push("monthDay")
            } else {
                /*day of the week*/
                mandatoryFields.push("monthWeek", "monthWeekDay")
            }
            break
        }
        case "DAILY": {
            mandatoryFields.push("every")
            break
        }
        case "WEEKLY": {
            mandatoryFields.push("every", "days")
            break
        }
    }
    if (recurring !== "NEVER") {
        mandatoryFields.push("end")
        if (event.end?.value === RecurringEnd.AfterExecution) {
            mandatoryFields.push("executions")
        } else if (event.end?.value === RecurringEnd.OnDate) {
            mandatoryFields.push("endDate")
        }
    }
    return mandatoryFields
}

const setDateOfTimeValue = (eventStartDate, timeValue) => {
    const startDate = moment(eventStartDate)
    if (!startDate.isValid()) return timeValue
    const time = moment(timeValue)
    if (!time.isValid()) return timeValue
    const date = startDate.get("date")
    const month = startDate.get("month")
    const year = startDate.get("year")
    return time.set("date", date).set("month", month).set("year", year)
}

const reminderOptions = REMINDERS.map((reminder) => ({value: reminder.id, label: reminder.name}))

type CalendarEventPopupProps = {
    isShow: boolean
    onClose: () => void
    initialData?: any
    onSave: (eventData) => void
}

export const ActivityCalendarEventPopup: React.FC<CalendarEventPopupProps> = ({
    isShow,
    onClose,
    initialData,
    onSave
}) => {
    const {t} = useTranslation(["calendar", "common"])
    const model = useModel()
    const userTimeFormat = model.getUserTimeFormat()
    const [errors, setErrors] = useState({})
    const [eventData, setEventData] = useState<any>({
        object_id: `NEW_${uuid()}`,
        publicId: "",
        name: "",
        typeEvent: "",
        startDate: moment().add(1, "day"),
        endDate: "",
        startTime: moment().add(1, "day").startOf("day").add(8, "hour"),
        endTime: moment().add(1, "day").startOf("day").add(9, "hour"),
        rsvp: RSVP.Yes,
        recurring: OPTIONS_FREQUENCY[0],
        end: OPTIONS_FREQUENCY_TYPE[0],
        executions: "",
        every: "",
        days: {},
        monthlyRecurring: 0,
        monthDay: {},
        monthWeek: {},
        monthWeekDay: {},
        notes: "",
        allDay: false,
        rrule: undefined,
        reminders: [reminderOptions[1]]
    })
    const [channels, setChannels] = useState([])
    const [eventTypes, setEventTypes] = useState([])

    useEffect(() => {
        if (initialData) setEventData((data) => ({...data, ...initialData}))
    }, [initialData])

    useEffect(() => {
        ;(async function getEventTypes() {
            try {
                const {data} = await eventTypeService.getAll({
                    range: {limit: UNLIMITED_PAGE_SIZE, offset: 0}
                })
                setEventTypes(data)
            } catch (error) {
                handleError(error)
            }
        })()
        ;(async function getChannels() {
            try {
                const channels = await chatService.getAllRooms({
                    pagination: {page: 1, pageSize: UNLIMITED_PAGE_SIZE},
                    sort: {
                        orderBy: "created_at",
                        order: "DESC"
                    },
                    filter: {
                        type: "channel"
                    }
                })
                setChannels(channels)
            } catch (error) {
                handleError(error)
            }
        })()
    }, [])

    const onChangeEventData = React.useCallback((key, value) => {
        setErrors((errors) =>
            produce(errors, (draft) => {
                if (!isInvalidValue(value)) {
                    delete draft[key]
                }
            })
        )
        setEventData((data) => ({
            ...data,
            [key]: value
        }))
    }, [])

    const onChangeEventDataMultiple = React.useCallback((values: {[key: string]: any}) => {
        setErrors((errors) =>
            produce(errors, (draft) => {
                Object.entries(values).forEach(([key, value]) => {
                    if (!isInvalidValue(value)) {
                        delete draft[key]
                    }
                })
            })
        )
        setEventData((data) => ({
            ...data,
            ...values
        }))
    }, [])

    const validate = React.useCallback(
        (eventData) => {
            const event = getExtraEventData(eventData)
            const mandatoryFields = getMandatoryFields(event)
            const errorsAux: any = {}
            mandatoryFields.forEach((field) => {
                if (isInvalidValue(event[field])) {
                    errorsAux[field] = true
                }
            })
            const hasRequiredFieldError = Object.keys(errorsAux).length > 0
            if (hasRequiredFieldError) {
                setErrors(errorsAux)
                toastError(t("common:validation.fieldRequired"))
                return true
            }
            if (moment(event.startTime).isSameOrAfter(moment(event.endTime))) {
                errorsAux.startTime = true
                errorsAux.endTime = true
                toastError(t("message.endTimeGreaterStartTime"))
                return true
            }
            return false
        },
        [t]
    )

    useEffect(() => {
        setEventData((eventData) => ({
            ...eventData,
            startTime: setDateOfTimeValue(eventData.startDate, eventData.startTime),
            endTime: setDateOfTimeValue(eventData.startDate, eventData.endTime)
        }))
    }, [eventData.startDate])

    const handleSaveClick = React.useCallback(() => {
        const hasError = validate(eventData)
        if (hasError) {
            return
        }
        onSave(eventData)
    }, [onSave, validate, eventData])

    const renderEndBody = () => {
        return (
            <EndBody
                end={eventData.end}
                endDate={eventData.endDate}
                executions={eventData.executions}
                errors={errors}
                setEnd={(newValue) => onChangeEventDataMultiple({end: newValue})}
                setEndDate={(newValue) => onChangeEventDataMultiple({endDate: newValue})}
                setExecutions={(newValue) => onChangeEventDataMultiple({executions: newValue})}
            />
        )
    }

    const renderYearlyRecurring = () => {
        return (
            <>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={16}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderMonthlyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={16}>
                    <MonthlyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={16}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderWeeklyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={16}>
                    <WeeklyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={16}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderDailyRecurring = () => {
        return (
            <>
                <Col span={8} />
                <Col span={16}>
                    <DailyRecurringBody eventData={eventData} errors={errors} onChangeEventData={onChangeEventData} />
                </Col>
                <Col span={2} />
                <Col span={6}>
                    <EndTitle />
                </Col>
                <Col span={16}>{renderEndBody()}</Col>
            </>
        )
    }

    const renderRecurring = () => {
        const originalValue = eventData.recurring?.originalValue
        switch (originalValue) {
            case CalendarRecurringType.Yearly:
                return renderYearlyRecurring()
            case CalendarRecurringType.Monthly:
                return renderMonthlyRecurring()
            case CalendarRecurringType.Weekly:
                return renderWeeklyRecurring()
            case CalendarRecurringType.Daily:
                return renderDailyRecurring()
            default:
                return null
        }
    }

    return (
        <Modal
            keyboard={false}
            closable={false}
            visible={isShow}
            onCancel={onClose}
            closeIcon={<Icon icon="CLOSE" color="#000" className={styles.closeIcon} />}
            maskClosable={false}
            footer={null}
            className="eventPopup">
            <div className={styles.eventPopupWrap}>
                <div className={styles.eventPopupHeader}>
                    <h1 className={styles.eventPopupTitle}>{t("calendar.createEvent")}</h1>
                </div>
                <div className={styles.body}>
                    <Row gutter={[16, 24]} className={styles.rowItem}>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="PENCIL" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.workSchedule.name")}</label>
                        </Col>
                        <Col span={16}>
                            <BaseInput
                                onChange={(newValue) => onChangeEventDataMultiple({name: newValue})}
                                placeholder={t("calendar.workSchedule.name")}
                                value={eventData.name}
                                className={cx(!!errors["name"] ? styles.error : "")}
                            />
                        </Col>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="OUTLINE" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.type")}</label>
                        </Col>
                        <Col span={16}>
                            <KlassDropdown
                                onChange={(newValue) => {
                                    onChangeEventDataMultiple({
                                        eventType: newValue,
                                        eventSignInRequired: !!newValue?.signInRequired
                                    })
                                }}
                                options={eventTypes}
                                value={eventData.eventType}
                                placeholder={t("calendar.type")}
                                getOptionLabel={renderEventTypeOption}
                                valueKey="eventTypeId"
                                error={errors["eventType"]}
                            />
                        </Col>
                        <Col span={2} className={styles.eventPopupLabel}></Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.wholeDay")}</label>
                        </Col>
                        <Col span={16}>
                            <Switch
                                size="small"
                                onClick={(v) => onChangeEventDataMultiple({allDay: v})}
                                checked={eventData.allDay}
                            />
                        </Col>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="CALENDAR_FILL" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.date")}</label>
                        </Col>
                        <Col span={16}>
                            <BaseDatePicker
                                onChange={(newValue) => onChangeEventDataMultiple({startDate: newValue})}
                                value={eventData.startDate ? moment(eventData.startDate) : ""}
                                className={cx(styles.shadow, !!errors["startDate"] ? styles.error : "")}
                            />
                        </Col>
                        {!eventData.allDay && (
                            <>
                                <Col span={2} className={styles.eventPopupLabel}>
                                    <Icon icon="TIME_CLOCK_OUTLINE" className={styles.eventPopupIcon} color="#62B1FF" />
                                </Col>
                                <Col span={6} className={styles.eventPopupLabel}>
                                    <label>{t("calendar.time")}</label>
                                </Col>
                                <Col span={8}>
                                    <BaseTimePicker
                                        value={eventData.startTime}
                                        placeholder="Time"
                                        format={userTimeFormat}
                                        onChange={(newValue) => onChangeEventDataMultiple({startTime: newValue})}
                                        error={errors["startTime"]}
                                    />
                                </Col>
                                <Col span={8}>
                                    <BaseTimePicker
                                        value={eventData.endTime}
                                        placeholder="Time"
                                        format={userTimeFormat}
                                        onChange={(newValue) => onChangeEventDataMultiple({endTime: newValue})}
                                        error={errors["endTime"]}
                                        disabledHours={() => getDisabledEndHours(eventData.startTime)}
                                        disabledMinutes={() =>
                                            getDisabledEndMinutes(eventData.startTime, eventData.endTime)
                                        }
                                    />
                                </Col>
                            </>
                        )}
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="PERSON_FILL" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.channel")}</label>
                        </Col>
                        <Col span={16}>
                            <KlassDropdown
                                onChange={(newValue) => onChangeEventDataMultiple({channel: newValue})}
                                options={channels}
                                value={eventData.channel}
                                placeholder={t("calendar.addChannel")}
                                valueKey="room_id"
                                error={errors["channel"]}
                            />
                        </Col>
                        <Col span={24}>
                            <Row gutter={[24, 12]}>
                                <Col span={2} className={styles.eventPopupLabel}>
                                    <Icon icon="PERSON_ADD" className={styles.eventPopupIcon} color="#62B1FF" />
                                </Col>
                                <Col span={6} className={styles.eventPopupLabel}>
                                    <label>{t("calendar.hosts")}</label>
                                </Col>
                                <Col span={16}>
                                    <div className={styles.hostsWrap}>
                                        <div className={styles.hostsDropdown}>
                                            <UserSelect
                                                isMulti
                                                value={eventData.hostUsers}
                                                type={Auth.UserProfileType.Staff}
                                                onChange={(options?: UserAutocomplete[]) => {
                                                    onChangeEventDataMultiple({
                                                        hostUsers: options ?? []
                                                    })
                                                }}
                                                className={cx(!!errors["hostUsers"] ? styles.error : "")}
                                            />
                                        </div>
                                        <Tooltip title="Hosts can edit and delete the event">
                                            <div className={styles.infoIconWrap}>
                                                <Icon icon="INFO" />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[24, 12]}>
                                <Col span={2} className={styles.eventPopupLabel}>
                                    <Icon icon="PERSON_ADD" className={styles.eventPopupIcon} color="#62B1FF" />
                                </Col>
                                <Col span={6} className={styles.eventPopupLabel}>
                                    <label>{t("calendar.invite")}</label>
                                </Col>
                                <Col span={16}>
                                    <UserSelect
                                        isMulti
                                        isActive={eventData.includeArchive ? [true, false] : true}
                                        value={eventData.invitedUsers}
                                        onChange={(options?: UserAutocomplete[]) => {
                                            onChangeEventDataMultiple({
                                                invitedUsers: options ?? []
                                            })
                                        }}
                                        className={cx(!!errors["invitedUsers"] ? styles.error : "")}
                                    />
                                </Col>
                                <Col span={8} />
                                <Col span={16}>
                                    <Checkbox
                                        checked={!!eventData.includeArchive}
                                        onChange={(e) => onChangeEventData("includeArchive", e.target.checked)}>
                                        {t("calendar.searchArchive")}
                                    </Checkbox>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]} className={styles.rowItem}>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="REPEAT" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.recurring")}</label>
                        </Col>
                        <Col span={16}>
                            <div className={styles.dropDown}>
                                <KlassDropdown
                                    onChange={(newValue) => onChangeEventDataMultiple({recurring: newValue})}
                                    options={OPTIONS_FREQUENCY}
                                    value={eventData.recurring}
                                    labelKey="label"
                                    valueKey="value"
                                    placeholder={t("calendar.recurring")}
                                />
                            </div>
                        </Col>
                        {renderRecurring()}
                    </Row>
                    <Row gutter={[16, 12]} className={styles.rowItem}>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="NOTIFY" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.reminder")}</label>
                        </Col>
                        <Col span={16}>
                            <KlassDropdown
                                creatable
                                isMulti
                                isClearable
                                placeholder="Select or type reminder"
                                formatCreateLabel={(inputValue) => `Use "${inputValue} minutes"`}
                                options={reminderOptions}
                                valueKey="value"
                                labelKey="label"
                                onChange={(options) => {
                                    onChangeEventDataMultiple({
                                        reminders:
                                            (options || [])
                                                .map((option) =>
                                                    option.__isNew__
                                                        ? isNaN(option.value)
                                                            ? null
                                                            : {
                                                                  value: +option.value * 60,
                                                                  label: `${option.value} minutes before`
                                                              }
                                                        : option
                                                )
                                                .filter(Boolean) ?? []
                                    })
                                }}
                                value={eventData.reminders}
                                error={!!errors["reminders"]}
                            />
                        </Col>
                        <Col span={8} />
                        <Col span={16}>
                            <Checkbox
                                checked={!!eventData.withSMS}
                                onChange={(e) => onChangeEventData("withSMS", e.target.checked)}>
                                {t("calendar.sendSmsReminder")}
                            </Checkbox>
                        </Col>
                    </Row>
                    {/*
                    <Row gutter={[16, 12]} className={styles.rowItem}>
                        <Col span={2} className={styles.eventPopupLabel}>
                            <Icon icon="NOTES" className={styles.eventPopupIcon} color="#62B1FF" />
                        </Col>
                        <Col span={6} className={styles.eventPopupLabel}>
                            <label>{t("calendar.notes")}</label>
                        </Col>
                        <Col span={16}>
                            <BaseTextArea
                                onChange={(newValue) => onChangeEventDataMultiple({description: newValue})}
                                placeholder={t("calendar.notes")}
                                value={eventData.description}
                            />
                        </Col>
                    </Row>
                    */}

                    <Row gutter={[16, 12]} className={styles.rowItem}>
                        <Col span={8} />
                        <Col span={16}>
                            <Checkbox
                                checked={!!eventData.eventSignInRequired}
                                onChange={(e) => onChangeEventData("eventSignInRequired", e.target.checked)}>
                                Sign-in Required
                            </Checkbox>
                        </Col>
                        {!!eventData.eventSignInRequired && (
                            <>
                                <Col span={8}>
                                    <label>For individuals that attended change status to</label>
                                </Col>
                                <Col span={16}>
                                    <NewStudentStatusSelect
                                        value={eventData.attendedChangeToStatus}
                                        onChange={(newValue) => onChangeEventData("attendedChangeToStatus", newValue)}
                                    />
                                </Col>
                                <Col span={8}>
                                    <label>For individuals that didn’t attended change status to</label>
                                </Col>
                                <Col span={16}>
                                    <NewStudentStatusSelect
                                        value={eventData.notAttendedChangeToStatus}
                                        onChange={(newValue) =>
                                            onChangeEventData("notAttendedChangeToStatus", newValue)
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <label>Trigger workflow upon completion (for attendees)</label>
                                </Col>
                                <Col span={16}>
                                    <WorkflowSelect
                                        isClearable
                                        triggerElement={WorkflowTriggerElement.CalendarEventCompletion}
                                        value={eventData.completionWorkflow}
                                        onChange={(newValue) =>
                                            onChangeEventData(
                                                "completionWorkflow",
                                                newValue ? pick(newValue, ["id", "code", "name"]) : undefined
                                            )
                                        }
                                    />
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
                <div className={styles.actionBtn}>
                    <div />
                    <div className={styles.rightActionBtn}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            onClick={onClose}
                            className={styles.cancelBtn}
                        />
                        <BaseButton title={t("common:action.save")} onClick={handleSaveClick} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
