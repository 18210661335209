import {Timestamps} from "../common"
import {Auth} from "../auth"
import {FundSource} from "./fund-source"
import {AwardSummary, FinAidOffer, FinAidPackage} from "./fin-aid"
import {TermDetails} from "../terms"
import {PaymentPeriod} from "types/student-account/payment-period"

export enum DisbursementStatus {
    Pending = "pending",
    SapHold = "sap_hold",
    Requested = "requested",
    Approved = "approved",
    Rejected = "rejected",
    Disbursed = "disbursed"
}

export interface Disbursement extends Timestamps {
    disbursementId: number
    awardSummaryId: number
    awardSummary?: AwardSummary // populate
    finAidOffer?: FinAidOffer // populate
    finAidPackage?: FinAidPackage // populate
    studentProfileId: number
    studentProfile?: Auth.LinkedProfileDetails // populate
    status: DisbursementStatus
    awardYear: number
    termId?: number
    term?: TermDetails // populate
    fundSourceId: number
    fundSource?: FundSource // populate
    disbursedSequenceNo: number
    requestedDate?: string | null
    disbursedDate?: string | null
    drawAndPostDate?: string | null

    scheduledDate?: string | null
    numberOfHoursCompleted?: number | null
    grossAmount: number
    netAmount: number
    interestRebase: number
    originationFee: number

    academicYear?: number | null
    academicYearBegin?: string | null
    academicYearEnd?: string | null
    comments?: string | null
    codStatusCode?: string | null
    codCycleFlag?: string | null
    codActionFlag?: string | null
    codDisbReleaseIndicator?: string | null
    codDisbReleaseTime?: string | null
    codDisbSequenceNo?: number | null
    codPrevDisbSequenceNo?: number | null
    codCurrentCycleDocId?: string | null
    periodId?: number | null
    period?: PaymentPeriod
    acceptanceDate?: string | null

    createdByProfileId: number
    createdBy: Auth.LinkedProfileDetails
    updatedByProfileId: number
    updatedBy: Auth.LinkedProfileDetails
}

export interface DisbursementLog extends Timestamps {
    disbursementLogId: number
    disbursementId: number
    disbursement?: Disbursement // populate
    note?: string | null
    codDisbSequenceNo?: number | null
    disbursedDate?: string | null
    grossAmount?: number | null
    netAmount?: number | null
}
