import React, {useRef} from "react"
import "emoji-mart/css/emoji-mart.css"
import {Picker} from "emoji-mart"
import styles from "./EmojiPicker.module.css"
import cx from "classnames"
import {useClickAway} from "react-use"

export const EmojiPicker = (props) => {
    const {isShow, onClose, onSelect, className} = props
    const ref = useRef(null)
    useClickAway(ref, () => {
        onClose()
    })
    return (
        <div className={cx(styles.root, isShow && styles.rootShow, className)} ref={ref}>
            <Picker
                set="google"
                onSelect={onSelect}
                color="#1e90ff"
                emojiSize={32}
                showPreview={false}
                showSkinTones
                emojiTooltip
                title={""}
                sheetSize={64}
                style={{width: "100%", border: "none", background: "white", borderRadius: "8px"}}
            />
        </div>
    )
}
