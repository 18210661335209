import {BaseButton, Icon} from "components"
import styles from "./MakeupAttendance.module.css"
import {useFilterStorage, useModel, useVisible} from "hooks"
import {BaseNewFilter, KlassappTable, KlassappTableHeader, SignaturePopup} from "uiKit"
import PopupVerifyAttendance from "sections/academics/instructional/InstructionalLessonDetail/parts/PopupVerifyAttendance"
import {KlassappTableHOC} from "HOC"
import {Course} from "types/courses"
import {useTranslation} from "react-i18next"
import {useEffect, useMemo, useState} from "react"
import {cloneDeep} from "lodash"
import {useDebounce} from "react-use"
import {academicPlansService} from "services"
import {
    checkPermission,
    convertTZ,
    getFullName,
    handleError,
    openLinkInNewTab,
    roundTwoDigits,
    toastError,
    toDateOnly
} from "helpers"
import moment from "moment"
import {AttendanceEvent, AttendanceEventType} from "types/attendance"
import {KlassappTableProps} from "types/common"
import {FilterKey} from "types/filter"
import cx from "classnames"
import {QRScanPopup} from "./QRScan"
import PopupAddMakeupAttendance from "./AddMakeupAttendance/PopupAddMakeupAttendance"
import {Auth} from "types/auth"
import EditMakeupAttendance from "./EditMakeupAttendance/EditMakeupAttendance"
import {MakeupStudentType} from "."
import {ExportButton, ExportFileType} from "components/ui"
import {exportCsv, exportExcel, exportPdf} from "helpers/export-table"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {Permissions} from "@edular/permissions"
import {Col, Row} from "antd"
import CampusSelect from "components/CampusSelect"

export enum FilterEventType {
    Active = "active",
    InSchool = "inSchool",
    Break = "break",
    Checkout = "checkOut",
    Missed = "missed"
}

const defaultFilter = {
    majorVersionIds: [],
    filterEventType: undefined,
    campuses: [],
    programs: [],
    instructors: [],
    date: null
}

function reducer(state, action) {
    return {...state, ...action}
}

enum ReviewItemType {
    APPROVAL = "approval",
    AUDIT = "audit"
}

type Props = {
    getClassroomMakeupAttendanceStats: () => void
    tableType: MakeupStudentType
    studentProfileId?: number
    filters: {
        isToday: boolean
        isUnconfirmed: boolean
        isNotVerified: boolean
        dateRange?: [moment.Moment, moment.Moment]
    }
} & KlassappTableProps

const MakeupAttendance = (props: Props) => {
    const {tableType, getClassroomMakeupAttendanceStats, filters, studentProfileId} = props
    const popupQrCode = useVisible()
    const signaturePopup = useVisible(false)
    const addMakeupLessonPopup = useVisible()
    const editMakeupLessonPopup = useVisible()
    const [exporting, setExporting] = useState(false)
    const model = useModel()
    const userCampuses = model.getCurrentUserProfile()?.campuses ?? []
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        tableType === MakeupStudentType.Active
            ? FilterKey.ActiveClassroomMakeupAttendance
            : FilterKey.CompletedClassroomMakeupAttendance,
        {...defaultFilter, campuses: userCampuses}
    )
    const {t} = useTranslation(["studentServices"])
    const dateFormat = model.getUserDateFormat()
    const [isShowConfirmVerify, setIsShowConfirmVerify] = useState(false)
    const {data, page, total, pageSize, columns, fields, getCurrentData, allFields, isLoading, dispatch} = props
    const [targetSignature, setTargetSignature] = useState<{
        type: Auth.UserProfileType
        id: number
        profileId: number
        signatureType: "student" | "instructor" | "advisor"
    }>()
    const [selectedAttendance, setSelectedAttendance] = useState<{
        tableType: MakeupStudentType
        studentName: string
        academicMakeupStudentId: number
        lessonId: number
        calendarEventId: number
        termId: number
        scheduleId: number
        attendanceId: number
        courseId: number
        attendedMinutes: number
        makeUpMinutes: number
        actualAttendedHours: number
        actualAttendedMinutes: number
        studentCheckInSignatureImage: string
        studentCheckInSignatureUpdatedAt: string
        studentCheckOutSignatureImage: string
        studentCheckOutSignatureUpdatedAt: string
        instructorSignatureUpdatedAt: string
        instructorSignatureImage: string
        advisorSignatureUpdatedAt: string
        advisorSignatureImage: string
        advisorName: string
        instructorName: string
        studentProfileId: number
        timezone: string
        date: string
        instructorPhoto: any
        instructorCustomUserId: string
        events: AttendanceEvent[]
        creditToDot: number | null
        appliedMinutes: number | null
    }>()
    const [searchDebounce, setSearchDebounce] = useState(searchValue)

    useDebounce(() => setSearchDebounce(searchValue), 500, [searchValue])

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getListOfAttendance},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRefreshData = async () => {
        getClassroomMakeupAttendanceStats()
        await getListOfAttendance()
    }

    const handleExportFile = async (type: ExportFileType) => {
        setExporting(true)
        try {
            const {attendances} = await listAttendances(true)
            const dataExport = attendances.map((makeupStudent, index) => {
                const checkOutEvent = (makeupStudent.attendance?.events ?? []).find(
                    (event) => event.eventType === AttendanceEventType.Checkout
                )
                makeupStudent.checkOutHtml = checkOutEvent
                    ? moment(convertTZ(new Date(checkOutEvent.createdAt), makeupStudent.timezone)).format(
                          model.getUserTimeFormat()
                      )
                    : ""
                makeupStudent.dateReport = moment(convertTZ(makeupStudent.date, makeupStudent.timezone)).format(
                    dateFormat
                )
                makeupStudent.studentCheckInSigned = makeupStudent.studentCheckInSignatureImage
                    ? `${makeupStudent.studentName} (${moment(makeupStudent.studentCheckInSignatureUpdatedAt)
                          .local(true)
                          .format(model.getUserDateTimeFormat())})`
                    : ""
                makeupStudent.studentCheckOutSigned = makeupStudent.studentCheckOutSignatureImage
                    ? `${makeupStudent.studentName} (${moment(makeupStudent.studentCheckOutSignatureUpdatedAt)
                          .local(true)
                          .format(model.getUserDateTimeFormat())})`
                    : ""
                makeupStudent.instructorSigned = makeupStudent.instructorSignatureImage
                    ? `${makeupStudent.instructorName} (${moment(makeupStudent.instructorSignatureUpdatedAt)
                          .local(true)
                          .format(model.getUserDateTimeFormat())})`
                    : ""
                makeupStudent.advisorSigned = makeupStudent.advisorSignatureImage
                    ? `${makeupStudent.advisorName} (${moment(makeupStudent.advisorSignatureUpdatedAt)
                          .local(true)
                          .format(model.getUserDateTimeFormat())})`
                    : ""
                return makeupStudent
            })
            const columns = [
                {title: "ID", field: "customUserId"},
                {title: "Name", field: "studentName"},
                {title: "Start Date", field: "startDateHtml"},
                {title: "Program", field: "programName"},
                {title: "Date", field: "dateReport"},
                {title: "Check In", field: "checkInHtml"},
                {title: "Check Out", field: "checkOutHtml"},

                {title: "Actual Minutes", field: "attendedMinutes"},
                {title: "Applied Minutes", field: "appliedMinutes"},
                {title: "Student Check In Sign", field: "studentCheckInSigned"},
                {title: "Student Check Out Sign", field: "studentCheckOutSigned"},
                {title: "Instructor Sign", field: "instructorSigned"},
                {title: "Credit To Dot", field: "creditToDot"},
                {title: "Academic Advisor Sign", field: "advisorSigned"}
            ]
            const filename = `Makeup_Attendance_${moment().format("MM_DD_YYYY")}`
            const payload = {
                filename,
                columns: columns.map((col) => col.title),
                rows: dataExport.map((item) =>
                    columns.map((col) => {
                        return item[col.field]?.toString() ?? ""
                    })
                )
            }

            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            } else if (type === "pdf") {
                exportPdf(payload)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setExporting(false)
        }
    }

    const getFilterParams = (isExport: boolean) => {
        const params: any = {
            filter: {
                courseIds: [],
                termIds: [],
                type: tableType,
                search: searchDebounce,
                scheduleIds: [],
                lessonIds: [],
                campusIds: currentFilter?.campuses?.length ? currentFilter.campuses.map((campus) => campus.id) : [],
                studentProfileIds: studentProfileId ? [studentProfileId] : [],
                linkedObjects: true,
                isNotVerified: filters.isNotVerified,
                dateRange: {
                    startDate: moment().startOf("day").format("YYYY-MM-DD"),
                    endDate: moment().endOf("day").format("YYYY-MM-DD")
                },
                date: moment().startOf("day").format("YYYY-MM-DD")
            },
            range: isExport
                ? {page: 1, pageSize: UNLIMITED_PAGE_SIZE}
                : {
                      page,
                      pageSize
                  }
        }
        if (tableType === MakeupStudentType.Active) {
            params.filter.isUnconfirmed = filters.isUnconfirmed
        }
        if (!filters.isToday && filters.dateRange && tableType !== MakeupStudentType.Incomplete) {
            params.filter.dateRange = {
                startDate: filters.dateRange[0].startOf("day").format("YYYY-MM-DD"),
                endDate: filters.dateRange[1].endOf("day").format("YYYY-MM-DD")
            }
        }

        return params
    }

    function onClickReview(type, item) {
        signaturePopup.open()
    }

    function renderStatusApproved(item) {
        const approvalDate = item.approvalStatusUpdatedAt
        return (
            <div className={styles.statusWrap}>
                <div className={cx(styles.signatureWrap, styles.signature__approval)}>
                    <Icon icon="CHECKMARK_CIRCLE" className={styles.signature__statusIcon} color="#18A957" />
                    <img src={item?.imageUrl} alt="signature" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>
                        {moment(approvalDate).local(true).format(model.getUserDateTimeFormat())}
                    </p>
                    <p className={styles.signatureName}>{item?.name ?? ""}</p>
                </div>
            </div>
        )
    }

    function renderStatusRejected(item) {
        const approvalDate = item.approvalStatusUpdatedAt
        return (
            <div className={styles.statusWrap}>
                <div className={cx(styles.signatureWrap, styles.signature__reject)}>
                    <Icon icon="CROSS_CIRCLE" className={styles.signature__statusIcon} color="#DF1642" />
                    <img src={item["approvalSignature"].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>
                        {moment(approvalDate).local(true).format("MM/DD/YYYY hh:mm A")}
                    </p>
                    <p className={styles.dateInfo__text}>{getFullName(item["approvalSignature"])}</p>
                </div>
            </div>
        )
    }

    const renderApprovalHtml = (item, isDisabled: boolean = false) => {
        const enableMakeupAttendanceSignOff = checkPermission(
            {
                staff: [Permissions.Staff.AcademicAffairs.MakeUpAttendance.MakeUpAttendanceSignOff.Edit]
            },
            model
        )
        const enableMakeupAttendanceVerification = checkPermission(
            {
                staff: [Permissions.Staff.AcademicAffairs.MakeUpAttendance.MakeUpAttendanceVerification.Edit]
            },
            model
        )
        if (item.approvalStatus === "empty") {
            return null
        }
        if (isDisabled) {
            return (
                <div className={styles.review}>
                    <Icon color="#666666" icon="PENCIL" />
                    <span
                        className={cx(styles.signText, {
                            [styles.disabledHtml]: isDisabled
                        })}>
                        Sign
                    </span>
                </div>
            )
        }
        switch (item.approvalStatus) {
            case "pending": {
                return (
                    <div
                        className={styles.review}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (item.signatureType === "instructor" && !enableMakeupAttendanceSignOff) {
                                return toastError(`You don't have permissions to sign`)
                            }
                            if (item.signatureType === "advisor" && !enableMakeupAttendanceVerification) {
                                return toastError(`You don't have permissions to sign`)
                            }
                            setSelectedAttendance(item)
                            setTargetSignature({
                                type: item.userType,
                                id: item.userId,
                                profileId: item.profileId,
                                signatureType: item.signatureType
                            })
                            onClickReview(ReviewItemType.APPROVAL, item)
                        }}>
                        <Icon color="#ff349b" icon="PENCIL" />
                        <span className={styles.signText}>Sign</span>
                    </div>
                )
            }
            case "approved":
                return renderStatusApproved(item)
            case "rejected":
                return renderStatusRejected(item)
            default:
                return <></>
        }
    }

    const renderButtonQrCode = (attendance) => {
        return (
            <div
                className={styles.attendanceQrCode}
                onClick={(e) => {
                    e.stopPropagation()
                    setSelectedAttendance({tableType, ...attendance})
                    popupQrCode.open()
                }}>
                <Icon icon="ATTENDANCE_QR_CODE" />
            </div>
        )
    }

    const listAttendances = async (isExport: boolean = false) => {
        const params = getFilterParams(isExport)
        const {data, total} = await academicPlansService.getListOfClassroomMakeupAttendance(params)
        const attendances = (data || []).map((item, index) => {
            if (item.isVerified === "yes" || !model.isStaffOrAdmin()) {
                item.isDisableCheckBox = true
            }
            const checkInEvent = (item.attendance?.events ?? []).find(
                (event) => event.eventType === AttendanceEventType.Checkin
            )
            const checkOutEvent = (item.attendance?.events ?? []).find(
                (event) => event.eventType === AttendanceEventType.Checkout
            )
            return {
                ...item,
                startDateHtml: item.startDate
                    ? moment(toDateOnly(item.startDate)).format(model.getUserDateFormat())
                    : "",
                studentName: `${item.firstName} ${item.lastName}`,
                checkInHtml: checkInEvent
                    ? moment(convertTZ(new Date(checkInEvent.createdAt), item.timezone)).format(
                          model.getUserTimeFormat()
                      )
                    : "",
                checkOutHtml:
                    tableType === MakeupStudentType.Incomplete ? (
                        <Icon
                            className={styles.warningIcon}
                            color="var(--warning-400-base)"
                            icon={"WARNING_TRIANGLE_FILL"}
                        />
                    ) : checkOutEvent ? (
                        moment(convertTZ(new Date(checkOutEvent.createdAt), item.timezone)).format(
                            model.getUserTimeFormat()
                        )
                    ) : (
                        ""
                    ),
                events: item.attendance?.events ?? [],
                attendanceId: item.attendance?.attendanceId,
                missedHours: `${roundTwoDigits(item.attendance?.missedHours ?? 0)}`,
                makeUpHours: `${roundTwoDigits(item.attendance?.makeUpHours ?? 0)}`,
                attendedMinutes: `${roundTwoDigits((item.attendance?.attendedHours ?? 0) * 60)}`,
                actualAttendedHours: `${roundTwoDigits(item.attendance?.actualAttendedHours ?? 0)}`,
                actualAttendedMinutes: `${roundTwoDigits((item.attendance?.actualAttendedHours ?? 0) * 60)}`,
                dateHtml: moment(convertTZ(item.date, item.timezone)).format(dateFormat),
                customProfileId: item.customProfileId ?? "",
                buttonQrCode:
                    [MakeupStudentType.Completed, MakeupStudentType.Incomplete].includes(tableType) || checkOutEvent
                        ? null
                        : renderButtonQrCode({...item, attendanceId: item.attendance?.attendanceId}),
                studentCheckInSign: renderApprovalHtml({
                    ...item,
                    attendanceId: item.attendance?.attendanceId,
                    approvalStatus: item.studentCheckInSignatureId ? "approved" : "empty",
                    imageUrl: item.studentCheckInSignatureImage,
                    approvalStatusUpdatedAt: item.studentCheckInSignatureUpdatedAt,
                    name: item.studentName,
                    userId: item.studentUserId,
                    profileId: item.studentProfileId,
                    userType: Auth.UserProfileType.Student,
                    signatureType: "student"
                }),
                studentCheckOutSign: renderApprovalHtml({
                    ...item,
                    attendanceId: item.attendance?.attendanceId,
                    approvalStatus: item.studentCheckOutSignatureId ? "approved" : "empty",
                    imageUrl: item.studentCheckOutSignatureImage,
                    approvalStatusUpdatedAt: item.studentCheckOutSignatureUpdatedAt,
                    name: item.studentName,
                    userId: item.studentUserId,
                    profileId: item.studentProfileId,
                    userType: Auth.UserProfileType.Student,
                    signatureType: "student"
                }),
                instructorSign: renderApprovalHtml(
                    {
                        ...item,
                        attendanceId: item.attendance?.attendanceId,
                        approvalStatus: item.instructorSignatureId ? "approved" : "pending",
                        imageUrl: item.instructorSignatureImage,
                        name: item.instructorName,
                        approvalStatusUpdatedAt: item.instructorSignatureUpdatedAt,
                        userId: model.user.userId,
                        profileId: model.user.profileId,
                        userType: Auth.UserProfileType.Staff,
                        signatureType: "instructor",
                        academicMakeupStudentId: item.academicMakeupStudentId
                    },
                    !model.isStaffOrAdmin() ||
                        ((!checkInEvent || !checkOutEvent) && tableType !== MakeupStudentType.Incomplete)
                ),
                advisorSign: renderApprovalHtml(
                    {
                        ...item,
                        attendanceId: item.attendance?.attendanceId,
                        approvalStatus: item.advisorSignatureId ? "approved" : "pending",
                        imageUrl: item.advisorSignatureImage,
                        name: item.advisorName,
                        approvalStatusUpdatedAt: item.advisorSignatureUpdatedAt,
                        userId: model.user.userId,
                        profileId: model.user.profileId,
                        userType: Auth.UserProfileType.Staff,
                        signatureType: "advisor",
                        academicMakeupStudentId: item.academicMakeupStudentId
                    },
                    !model.isStaffOrAdmin() || !item.instructorSignatureId
                )
            }
        })
        return {attendances, total}
    }

    async function getListOfAttendance() {
        try {
            dispatch({isLoading: true})
            const {attendances, total} = await listAttendances()
            props.dispatch({data: attendances, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function getColumns() {
        return [
            {
                title: "ID",
                field: "customUserId",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "NAME",
                field: "studentName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "START DATE",
                field: "startDateHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "PROGRAM",
                field: "programName",
                headerStyle: {minWidth: "160px"}
            },
            {
                title: "DATE",
                field: "dateHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "CHECK IN",
                field: "checkInHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "CHECK OUT",
                field: "checkOutHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "ACTUAL MINUTES",
                field: "actualAttendedMinutes",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "MAKEUP MINUTES",
                field: "attendedMinutes",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "APPLIED MINUTES",
                field: "appliedMinutes",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "STUDENT CHECK IN SIGN",
                field: "studentCheckInSign",
                headerStyle: {minWidth: "200px"}
            },
            {
                title: "STUDENT CHECK OUT SIGN",
                field: "studentCheckOutSign",
                headerStyle: {minWidth: "200px"}
            },
            {
                title: "INSTRUCTOR SIGN",
                field: "instructorSign",
                headerStyle: {minWidth: "200px"}
            },
            {
                title: "CREDIT TO DOT",
                field: "creditToDot",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "ACADEMIC ADVISOR SIGN",
                field: "advisorSign",
                headerStyle: {minWidth: "200px"}
            },
            {
                title: "",
                field: "buttonQrCode",
                headerStyle: {maxWidth: "40px", minWidth: "40px"}
            }
        ]
    }

    function getFields() {
        return [
            "ID",
            "NAME",
            "START DATE",
            "PROGRAM",
            "DATE",
            "CHECK IN",
            "CHECK OUT",
            "ACTUAL MINUTES",
            "MAKEUP MINUTES",
            "APPLIED MINUTES",
            "STUDENT CHECK IN SIGN",
            "STUDENT CHECK OUT SIGN",
            "INSTRUCTOR SIGN",
            "CREDIT TO DOT",
            "ACADEMIC ADVISOR SIGN",
            ""
        ]
    }

    const onClickRow = async (data) => {
        setSelectedAttendance({...data, tableType})
        editMakeupLessonPopup.open()
    }

    const onCancelConfirm = () => {
        setIsShowConfirmVerify(false)
    }

    const onUpdateRowData = (updatedData) => {
        const data = getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        dispatch({data: newData})
    }

    useEffect(() => {
        getListOfAttendance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, searchDebounce, filters])

    const onApplyFilter = () => {
        applyFilter()
        getListOfAttendance()
    }

    const onSignSignature = async (signature) => {
        if (targetSignature) {
            const params: any = {
                academicMakeupStudentId: selectedAttendance.academicMakeupStudentId,
                studentProfileId: selectedAttendance.studentProfileId,
                lessonId: selectedAttendance.lessonId ?? null
            }
            if (targetSignature.signatureType === "student") {
                params.studentCheckInSignatureId = signature.signatureId
            } else if (targetSignature.signatureType === "advisor") {
                params.advisorSignatureId = signature.signatureId
            } else if (targetSignature.signatureType === "instructor") {
                params.instructorSignatureId = signature.signatureId
            }
            await academicPlansService.editClassroomMakeupAttendance({
                academicClassroomMakeupStudents: [params]
            })
            await onRefreshData()
            signaturePopup.close()
        }
    }

    const renderTitleSignature = useMemo(() => {
        const isAdvisor = targetSignature?.signatureType === "advisor"
        const isInstructor = targetSignature?.signatureType === "instructor"
        return (
            <div className={styles.titleSignContainer}>
                <Icon icon={"SIGN_MAKEUP_ATTENDANCE"} />
                <span className={styles.titleSign}>
                    {isAdvisor ? "ACADEMIC AFFAIRS" : isInstructor ? "INSTRUCTOR" : "PUT YOUR SIGN"}
                </span>
                <span className={styles.subTitleSign}>CONFIRM</span>
            </div>
        )
    }, [targetSignature])

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    return (
        <div className={styles.container}>
            {!studentProfileId ? (
                <div className={styles.addStudentContainer}>
                    <span className={styles.addStudentText}>ADD STUDENT</span>
                    <div className={styles.addStudentRightButton}>
                        <BaseButton
                            title="Scan QR"
                            onClick={() => {
                                setSelectedAttendance(undefined)
                                popupQrCode.open()
                            }}
                        />
                        <BaseButton
                            title="Open Terminal"
                            onClick={() => {
                                const url = "/qr/make-up"
                                openLinkInNewTab(url)
                            }}
                        />
                        <BaseButton title="Add Student" onClick={addMakeupLessonPopup.open} />
                    </div>
                </div>
            ) : null}
            <BaseNewFilter
                searchValue={searchValue}
                onSearchInput={changeSearch}
                onClick={onApplyFilter}
                onClickClear={clearFilter}
                filter={currentFilter}
                renderRightFilter={
                    model.isStaffOrAdmin()
                        ? () => (
                              <div className={styles.actionWrap}>
                                  <ExportButton
                                      label="EXPORT"
                                      isLoading={exporting}
                                      onSelect={handleExportFile}
                                      availableFileTypes={["pdf", "csv", "excel"]}
                                  />
                              </div>
                          )
                        : undefined
                }>
                <Row gutter={[40, 32]}>
                    <Col span={12}>
                        <CampusSelect
                            isMulti
                            displayOnlyCampusIds={userCampuses.map((campus) => campus.id)}
                            isClearable
                            placeholder="Campus"
                            value={currentFilter.campuses}
                            onChange={(newValue) => onChangeFilter("campuses", newValue)}
                        />
                    </Col>
                    <Col span={12}></Col>
                </Row>
            </BaseNewFilter>
            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                isShowAction={!!data.find((item) => item.isChecked)}
                actions={[]}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                isShowCheckedColumn={false}
                isShowCheckedColumnHeader={false}
                columns={columns}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                isShowColumnPicker
                onClickRowItem={onClickRow}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <PopupVerifyAttendance
                data={data}
                type={Course.ProgramAttendanceTrackingType.Daily}
                isShowConfirmVerify={isShowConfirmVerify}
                onCancelConfirm={onCancelConfirm}
                refreshData={onRefreshData}
            />
            <PopupAddMakeupAttendance
                onlyCheckIn
                onRefreshData={onRefreshData}
                onClose={addMakeupLessonPopup.close}
                isShow={addMakeupLessonPopup.isVisible}
            />
            {selectedAttendance ? (
                <EditMakeupAttendance
                    onRefreshData={onRefreshData}
                    selectedAttendance={selectedAttendance}
                    onClose={editMakeupLessonPopup.close}
                    isShow={editMakeupLessonPopup.isVisible}
                />
            ) : null}
            {targetSignature && signaturePopup.isVisible && (
                <SignaturePopup
                    isShow={signaturePopup.isVisible}
                    onClose={signaturePopup.close}
                    targetUser={targetSignature}
                    onSetNewSignature={onSignSignature}
                    actions={{
                        onCancel: signaturePopup.close,
                        onSign: onSignSignature
                    }}
                    autoClose
                    customTitle={renderTitleSignature}
                />
            )}
            <QRScanPopup
                isVisible={popupQrCode.isVisible}
                selectedAttendance={selectedAttendance}
                onClose={popupQrCode.close}
            />
        </div>
    )
}

export const TableMakeupAttendance = KlassappTableHOC(MakeupAttendance, {
    page: 1,
    pageSize: 100
})
