import {useQuery, useQueryClient} from "@tanstack/react-query"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {useCallback} from "react"
import {tagService} from "services"
import {Tag} from "types/tags"

const queryCacheKey = "all-media-library-tags"

const INITIAL_DATA: Tag[] = []

export const useAllMediaLibraryTags = () => {
    const queryClient = useQueryClient()

    const query = useQuery<Tag[]>(
        [queryCacheKey],
        async () => {
            const {data: tags} = await tagService.getAllTags({
                range: {
                    pageSize: UNLIMITED_PAGE_SIZE,
                    page: 1
                }
            })
            return tags
        },
        {
            refetchOnMount: false
        }
    )

    return {
        tags: query.data ?? INITIAL_DATA,
        invalidateMediaLibraryTagsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient]),
        isLoading: query.isLoading
    }
}
