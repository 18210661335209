import React from "react"
import {Menu} from "antd"
import {AcademicPlans} from "types/academicPlans"
import {Icon} from "components/Icon"
import {handleError} from "helpers"
import {useVisible} from "hooks"
import {Auth} from "types/auth"
import {academicPlansService} from "services"
import {TermChooseCoursesPopup} from "../Popup"
import styles from "./SectionItem.module.css"
import {isEmpty} from "lodash"
import {TransferAndPreviouslyCompletedCourse} from "./TransferAndPreviouslyCompletedCourse"
import {TermViewCourseType} from "./TermViewTab"

type Props = {
    termItem: AcademicPlans.Term
    studentId: number
    student: Auth.DepartmentStudent
    index: number
    termSection: any
    canTermRegister: boolean
    conflictEvents: any[]
    availableCourses: any[]
    registeringTerm: AcademicPlans.Term
    reloadView: () => void
    updateEvents: (data: AcademicPlans.UpdateEvents[]) => void
    onRegisterCourseSuccess: () => void
}

export function TransferAndPreviouslyCompletedItem(props: Props) {
    const {
        termItem,
        studentId,
        student,
        termSection,
        canTermRegister,
        conflictEvents,
        availableCourses,
        registeringTerm,
        reloadView,
        updateEvents,
        onRegisterCourseSuccess
    } = props
    const addCoursesPopup = useVisible(false)
    const {courses = []} = termSection
    let totalCredit = courses.reduce((total, item) => {
        return (
            total +
            (item.courseType === TermViewCourseType.Transfer ? item.appliedCredits ?? 0 : item.creditsApplied ?? 0)
        )
    }, 0)

    const removeSection = async () => {
        try {
            await academicPlansService.removeSection({
                studentProfileId: studentId,
                academicPlanSectionId: termSection.id
            })
            reloadView()
        } catch (error) {
            handleError(error)
        }
    }

    const addCourses = () => {
        addCoursesPopup.open()
    }

    const renderHeader = () => {
        const menu = (
            <Menu className={styles.menuActionWrap}>
                <Menu.Item key="edit" className={styles.menuActionItem} onClick={addCourses}>
                    <Icon className={styles.menuActionIcon} icon="PLUS_CIRCLE" />
                    <span className={styles.menuActionTitle}>Add Courses</span>
                </Menu.Item>
                {isEmpty(courses) && (
                    <Menu.Item key="delete" className={styles.menuActionItem} onClick={removeSection}>
                        <Icon className={styles.menuActionIcon} icon="DELETE" />
                        <span className={styles.menuActionTitle}>Remove</span>
                    </Menu.Item>
                )}
            </Menu>
        )

        return (
            <div className={styles.termCourseSectionHeader}>
                <p className={styles.termCourseSectionHeader__title}>{termSection.sectionTitle}</p>
                <div className={styles.termCourseSectionHeader__action}>
                    <div className={styles.sectionHourInfo}>
                        <span className={styles.sectionHourInfoTitle}>Credit</span>
                        <span className={styles.sectionHourInfoValue}>{totalCredit}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div>
                {renderHeader()}
                <div
                    className={styles.termCourseSectionBody}
                    style={{
                        padding: 8,
                        minHeight: 100
                    }}>
                    {courses.map((termCourse, index) => {
                        const unavailableScheduleIds = courses
                            .filter(
                                (course) =>
                                    course.courseId === termCourse.courseId &&
                                    (course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Dropped ||
                                        course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Registered)
                            )
                            .map((course) => course.registeredScheduleId)

                        return (
                            <TransferAndPreviouslyCompletedCourse
                                key={index}
                                studentId={studentId}
                                student={student}
                                unavailableScheduleIds={unavailableScheduleIds}
                                termItem={termItem}
                                termCourse={termCourse}
                                termSection={termSection}
                                canTermRegister={canTermRegister}
                                conflictEvents={conflictEvents}
                                availableCourses={availableCourses}
                                registeringTerm={registeringTerm}
                                reloadView={reloadView}
                                updateEvents={updateEvents}
                                onRegisterCourseSuccess={onRegisterCourseSuccess}
                            />
                        )
                    })}
                </div>
            </div>
            {addCoursesPopup.isVisible && (
                <TermChooseCoursesPopup
                    isShow={addCoursesPopup.isVisible}
                    onClose={addCoursesPopup.close}
                    section={termSection}
                    academicPlanTermId={termItem.id}
                    studentId={studentId}
                    onSaveSuccess={reloadView}
                />
            )}
        </div>
    )
}
