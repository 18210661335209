/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from "react"
import debounce from "debounce-promise"
import {BasePopup} from "components/popup"
import styles from "./ReassignPopup.module.css"
import {FormLabel} from "components/Form"
import {useTranslation} from "react-i18next"
import {UserAutocomplete} from "types/user"
import {KlassDropdownAsync} from "components/Select"
import {userService} from "services"
import {BaseButton} from "components"
import {Auth} from "types/auth"

type Props = {
    isShow: boolean
    onClose: () => void
    onConfirm: (userId: number) => Promise<void>
}

const ReassignPopup: React.FC<Props> = ({isShow, onClose, onConfirm}) => {
    const {t} = useTranslation(["tasks", "common"])
    const [isLoading, setLoading] = React.useState(false)
    const [selectedOption, setSelectedOption] = React.useState<UserAutocomplete | undefined>()

    const debounceSearchStudents = useCallback(
        debounce(async (search = "") => {
            const {data} = await userService.searchUsers({
                filters: {
                    type: Auth.UserProfileType.Staff,
                    search
                },
                range: {
                    page: 1,
                    pageSize: 50
                }
            })
            return data
        }, 500),
        []
    )

    const handleConfirm = React.useCallback(async () => {
        setLoading(true)
        try {
            if (selectedOption) {
                await onConfirm(selectedOption.userId)
            }
        } catch {
        } finally {
            setLoading(false)
        }
    }, [selectedOption, onConfirm])

    return (
        <BasePopup isShow={isShow} onClose={onClose} width="60vw" leftIcon="DUPLICATE">
            <div className={styles.wrap}>
                <p className={styles.title}>Reassign Task</p>
                <div className={styles.body}>
                    <FormLabel label={t("tasks.reassignTaskToStaff")} />
                    <KlassDropdownAsync
                        isMulti={false}
                        isClearable={false}
                        valueKey="profileId"
                        labelKey="name"
                        getOptionLabel={(option: UserAutocomplete) => (
                            <span>
                                <b>{option.profileId}</b> {option.name}
                            </span>
                        )}
                        loadOptions={debounceSearchStudents}
                        onChange={setSelectedOption}
                    />
                    <BaseButton
                        title={t("common:action.save").toUpperCase()}
                        onClick={handleConfirm}
                        className={styles.btnSave}
                        loading={isLoading}
                    />
                </div>
            </div>
        </BasePopup>
    )
}

export default ReassignPopup
