import React from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {RSVP} from "types/calendar"
import styles from "./Rsvp.module.css"

type Props = {
    value: string
    onChange: (newValue: string) => void
    className?: string
}

export function Rsvp(props: Props) {
    const {value, onChange, className} = props
    const {t} = useTranslation(["calendar"])

    return (
        <div className={cx(styles.rsvpWrap, className)}>
            <span
                className={cx(styles.rsvpItem, {
                    [styles.rsvpYesActive]: value === RSVP.Yes
                })}
                onClick={() => onChange(RSVP.Yes)}>
                {t("calendar.yes")}
            </span>
            <span
                className={cx(styles.rsvpItem, {
                    [styles.rsvpMaybeActive]: value === RSVP.Maybe
                })}
                onClick={() => onChange(RSVP.Maybe)}>
                {t("calendar.maybe")}
            </span>
            <span
                className={cx(styles.rsvpItem, {
                    [styles.rsvpNoActive]: value === RSVP.No
                })}
                onClick={() => onChange(RSVP.No)}>
                {t("calendar.no")}
            </span>
        </div>
    )
}
