/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect, useState} from "react"
import {Button, Checkbox} from "antd"
import {isBoolean, isEmpty} from "lodash"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {useHistory} from "react-router-dom"
import moment from "moment"
import momentTz from "moment-timezone"
import {KlassappTable, KlassappTableHeader, SignaturePopup} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {linksService, studentService, userServiceV3} from "services"
import {useVisible, useCurrentProfilePermissions} from "hooks"
import {getFullName, getPositionMoveInObject, handleError, toastSuccess, downloadFile} from "helpers"
import {Icon} from "components/Icon"
import {AddItemCircleButton, BaseButton} from "components/buttons"
import {PermissionsRequired} from "components/PermissionsRequired"
import {BaseDepartmentId} from "types/departments"
import {Permissions, PermissionUserType} from "types/permission"
import {AddStudentChecklistPopup} from "./../AddStudentChecklistPopup"
import styles from "./ChecklistTable.module.css"
import {Order} from "types/common"
import {ConfirmPopup} from "uiKit"
import {BaseDatePicker} from "components/DateTimePicker"
import {useModel} from "hooks"
import {Auth} from "types/auth"
import {Link} from "react-router-dom"

function reducer(state, action) {
    return {...state, ...action}
}

enum ReviewItemType {
    APPROVAL = "approval",
    AUDIT = "audit"
}

enum ArchivalType {
    Archive = "archive",
    Unarchive = "unarchive"
}

function Checklist(props) {
    const initialState = {
        reviewItem: null,
        reviewType: null,
        isRejecting: false,
        isApproving: false,
        isExporting: false
    }
    const [{reviewItem, reviewType, isRejecting, isApproving, isExporting}, dispatchState] = useReducer(
        reducer,
        initialState
    )
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        orderField,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        isShowTableHeaderAction,
        isLoadedTableFuncs,
        studentId,
        getStudentChecklistProgress,
        onCreateSuccess,
        departmentId,
        getCurrentData
    } = props
    const history = useHistory()
    const signaturePopup = useVisible(false)
    const confirmPopup = useVisible(false)
    const addStudentChecklistCustomPopup = useVisible(false)
    const {t} = useTranslation(["common"])
    const currentProfilePermissions = useCurrentProfilePermissions()
    const [isConfirmPopupArchive, setIsConfirmPopupArchive] = useState<boolean>(false)
    const [isShowArchived, setIsShowArchived] = useState<boolean>(false)
    const [tableHeaderActionsArchivalType, setTableHeaderActionsArchivalType] = useState<ArchivalType | null>(null)
    const [studentData, setStudentData] = useState(null)
    const model = useModel()

    const getFields = () => {
        return [
            t("checklist.order"),
            t("checklist.name"),
            t("checklist.approval"),
            t("checklist.audit"),
            t("checklist.documentUpload"),
            t("checklist.notRequired"),
            t("checklist.added"),
            t("checklist.dueDate"),
            t("checklist.task"),
            t("checklist.activity")
        ]
    }

    function getPageTitle() {
        return "Checklist"
    }

    const getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = props
        const tableHeaderActions = [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]

        if (tableHeaderActionsArchivalType) {
            tableHeaderActions.push({
                title: t(`common:action.${tableHeaderActionsArchivalType}`),
                icon: "ARCHIVE_FILL",
                action: () => {
                    setIsConfirmPopupArchive(true)
                    confirmPopup.open()
                }
            })
        }

        dispatch({tableHeaderActions})
        return tableHeaderActions
    }

    const onClickArchiveMulti = async () => {
        const checkedIds = getCurrentData()
            .filter((item) => item.isChecked)
            .map((item) => item.studentChecklistItemId)
        try {
            dispatch({isLoading: true})
            await studentService.archiveChecklistItemsForStudent({
                profileId: +studentId,
                studentChecklistItemIds: checkedIds,
                isArchived: tableHeaderActionsArchivalType === ArchivalType.Archive ? true : false
            })
            await getData()
            getStudentChecklistProgress()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function getColumns() {
        return [
            {
                title: t("checklist.order"),
                field: "order",
                headerStyle: {textAlign: "center", borderRight: "1px solid var(--black-200)"},
                style: {minWidth: "120px", borderRight: "1px solid var(--black-200)"},
                sortable: true,
                orderField: "position"
            },
            {
                title: t("checklist.name"),
                field: "name",
                sortable: true,
                headerStyle: {textAlign: "center", borderRight: "1px solid var(--black-200)"},
                style: {minWidth: "200px", borderRight: "1px solid var(--black-200)"}
            },
            {
                title: t("checklist.approval"),
                field: "approvalHtml",
                headerStyle: {textAlign: "center", borderRight: "1px solid var(--black-200)"},
                style: {
                    minWidth: "200px",
                    borderLeft: "1px solid var(--black-200)",
                    borderRight: "1px solid var(--black-200)"
                }
            },
            {
                title: t("checklist.audit"),
                field: "auditHtml",
                headerStyle: {textAlign: "center", borderRight: "1px solid var(--black-200)"},
                style: {
                    minWidth: "200px",
                    borderLeft: "1px solid var(--black-200)",
                    borderRight: "1px solid var(--black-200)"
                }
            },
            {
                title: t("checklist.documentUpload"),
                field: "docsHtml",
                headerStyle: {textAlign: "center", borderLeft: "1px solid var(--black-200)"},
                style: {minWidth: "200px", borderLeft: "1px solid var(--black-200)"}
            },
            {
                title: t("checklist.notRequired"),
                field: "notRequiredHtml",
                headerStyle: {textAlign: "center", borderLeft: "1px solid var(--black-200)"},
                style: {minWidth: "100px", borderLeft: "1px solid var(--black-200)"}
            },
            {
                title: t("checklist.added"),
                field: "createdAt",
                fieldType: "date",
                format: "DD/MM/YYYY",
                headerStyle: {textAlign: "center", borderLeft: "1px solid var(--black-200)"},
                style: {minWidth: "150px", borderLeft: "1px solid var(--black-200)"}
            },
            {
                title: t("checklist.dueDate"),
                field: "dueDateHtml",
                headerStyle: {textAlign: "center", borderLeft: "1px solid var(--black-200)"},
                style: {minWidth: "200px", borderLeft: "1px solid var(--black-200)"}
            },
            {
                title: t("checklist.task"),
                field: "linkedTasks",
                style: {minWidth: "80px"},
                render: (tasks) => tasks.map((task) => <Link to={task.taskUrl}>{task.name}</Link>)
            },
            {
                title: t("checklist.activity"),
                field: "linkedActivities",
                style: {minWidth: "80px"},
                render: (activities) =>
                    activities
                        .filter(Boolean)
                        .map((activity) => (
                            <Link to={`/activities/${activity.studentActivityId}`}>{activity.name}</Link>
                        ))
            }
        ]
    }

    useEffect(() => {
        if (isShowArchived) {
            dispatch({orderField: {field: "isArchived", order: Order.Asc}, isLoading: true})
        } else {
            dispatch({orderField: {field: "position", order: Order.Asc}, isLoading: true})
        }
    }, [isShowArchived])

    const getParams = () => {
        const params: any = {
            filter: {
                profileId: +studentId,
                departmentId: props.departmentId,
                isArchived: isShowArchived
            },
            range: {
                page,
                pageSize
            },
            sort: {
                orderBy: "position",
                orderDir: Order.Asc
            },
            linkedEntities: true
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }

        return params
    }

    const getData = async () => {
        const params = getParams()
        try {
            dispatch({isLoading: true})
            const {data, total} = await studentService.getAllChecklist(params)
            const links = !isEmpty(data)
                ? await linksService.getChecklistItemLinks(data.map((item) => item.studentChecklistItemId))
                : []
            let totalPage = Math.ceil(total / pageSize)
            totalPage = totalPage <= 0 ? 1 : totalPage
            const newData = data.map((item, index) => {
                item.id = item.studentChecklistItemId
                item.name = renderNameHtml(item.checklistItem.name)
                item.approvalHtml = renderApprovalHtml(item)
                item.auditHtml = renderAuditHtml(item)
                item.docsHtml = renderDocsHtml(item)
                item.notRequiredHtml = renderNotRequiredHtml(item)
                item.dueDateHtml = renderDueDateHtml(item)
                item.rowClassName = isBoolean(item.isRequired) && !item.isRequired ? styles.rowHighlighted : undefined
                if (orderField.order === Order.Asc) {
                    item.order = (page - 1) * pageSize + index + 1
                } else if (totalPage !== page) {
                    item.order = (totalPage - page) * pageSize + data.length - index - 1
                } else {
                    item.order = data.length - index
                }
                if (item.isArchived) {
                    item.order += ` (${t("checklist.archived")})`
                }
                item.linkedTasks = links[index]?.tasks
                item.linkedActivities = links[index]?.studentActivities
                return item
            })
            dispatch({data: newData, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const updateChecklist = async (id, data) => {
        try {
            dispatch({isLoading: true})
            await studentService.updateStudentChecklistPosition(id, data)
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onDraggableEnd = async (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        if (destination.index === source.index) {
            return
        }
        const {id} = data[source.index]
        const newPosition = getPositionMoveInObject(
            data,
            "position",
            source.index,
            destination.index,
            orderField.order === Order.Asc
        )
        await updateChecklist(id, {position: newPosition})
        await getData()
    }

    const renderNameHtml = (name) => {
        return <span className={styles.checklistName}>{name}</span>
    }

    function renderApprovalHtml(item) {
        if (
            ((isBoolean(item.isRequired) && !item.isRequired) || !!item.isArchived) &&
            item.approvalStatus === "pending"
        ) {
            return <div className={styles.upload__disabled}>Review</div>
        }
        switch (item.approvalStatus) {
            case "pending":
                return (
                    <div className={styles.review} onClick={() => onClickReview(ReviewItemType.APPROVAL, item)}>
                        Review
                    </div>
                )
            case "approved":
                return renderStatusApproved(item, "approvalSignature")
            case "rejected":
                return renderStatusRejected(item, "approvalSignature")
            default:
                return <></>
        }
    }

    function renderAuditHtml(item) {
        if (((isBoolean(item.isRequired) && !item.isRequired) || !!item.isArchived) && item.auditStatus === "pending") {
            return <div className={styles.upload__disabled}>Review</div>
        }
        switch (item.auditStatus) {
            case "pending":
                return (
                    <div className={styles.review} onClick={() => onClickReview(ReviewItemType.AUDIT, item)}>
                        Review
                    </div>
                )
            case "approved":
                return renderStatusApproved(item, "auditSignature")
            case "rejected":
                return renderStatusRejected(item, "auditSignature")
            default:
                return <></>
        }
    }

    function renderDocsHtml(item) {
        if (item.document) {
            return (
                <div className={styles.fileName} onClick={() => onOpenDocument(item.document)}>
                    {item.document.name}
                </div>
            )
        }
        if (isBoolean(item.checklistItem.isDocumentUploadRequired) && !item.checklistItem.isDocumentUploadRequired) {
            return <div className={styles.upload__disabled}>N/A</div>
        }
        if ((isBoolean(item.isRequired) && !item.isRequired) || !!item.isArchived) {
            return <div className={styles.upload__disabled}>Upload</div>
        }
        return (
            <div className={styles.upload} onClick={() => onClickUpload(item)}>
                Upload
            </div>
        )
    }

    const onChangeNotRequired = async (toggleItem, checked) => {
        try {
            await studentService.updateStudentChecklistDocumentUpload({
                studentChecklistItemId: toggleItem.id,
                isRequired: checked
            })
            getStudentChecklistProgress()
        } catch (error) {
            handleError(error)
        }
        const currentData = getCurrentData()
        const newData = currentData.map((item) => {
            if (item.id === toggleItem.id) {
                toggleItem.isRequired = checked
                item.approvalHtml = renderApprovalHtml(toggleItem)
                item.auditHtml = renderAuditHtml(toggleItem)
                item.docsHtml = renderDocsHtml(toggleItem)
                item.rowClassName =
                    isBoolean(toggleItem.isRequired) && !toggleItem.isRequired ? styles.rowHighlighted : undefined
                item.notRequiredHtml = renderNotRequiredHtml(toggleItem)
                item.dueDateHtml = renderDueDateHtml(toggleItem)
            }
            return item
        })
        dispatch({data: newData})
    }

    const renderNotRequiredHtml = (item) => {
        return (
            <div className={styles.notRequiredCheckbox}>
                <Checkbox
                    checked={!item.isRequired}
                    disabled={!!item.isArchived}
                    onChange={(event) => onChangeNotRequired(item, !event.target.checked)}
                />
            </div>
        )
    }

    const onChangeDueDate = async (updatedItem, newDate) => {
        try {
            await studentService.updateStudentChecklistDueDate({
                studentChecklistItemId: updatedItem.id,
                dueDate: newDate ? moment(newDate).endOf("date").toISOString() : null
            })
            getStudentChecklistProgress()
        } catch (error) {
            handleError(error)
        }
        const currentData = getCurrentData()
        const newData = currentData.map((item) => {
            if (item.id === updatedItem.id) {
                updatedItem.dueDate = newDate
                item.approvalHtml = renderApprovalHtml(updatedItem)
                item.auditHtml = renderAuditHtml(updatedItem)
                item.docsHtml = renderDocsHtml(updatedItem)
                item.rowClassName =
                    isBoolean(updatedItem.isRequired) && !updatedItem.isRequired ? styles.rowHighlighted : undefined
                item.notRequiredHtml = renderNotRequiredHtml(updatedItem)
                item.dueDateHtml = renderDueDateHtml(updatedItem)
            }
            return item
        })
        dispatch({data: newData})
    }

    const renderDueDateHtml = (item) => {
        return (
            <div className={styles.notRequiredCheckbox}>
                <BaseDatePicker
                    value={item.dueDate ? moment(item.dueDate) : null}
                    onChange={(newDate) => onChangeDueDate(item, newDate)}
                    disabled={!!item.isArchived}
                />
            </div>
        )
    }

    function onOpenDocument(document) {
        window.open(document.documentUrl)
    }

    function onClickUpload(item) {
        const {pathname, search} = history.location
        const backUrl = `${pathname}${search}`
        const submitDataAfterSuccess = {studentChecklistItemId: item.studentChecklistItemId}
        history.push({
            pathname: "/documents/detail",
            search: `?backUrl=${backUrl}&userProfileId=${studentId}`,
            state: {
                submitDataAfterSuccess,
                documentName: item.checklistItem.name,
                departmentId
            }
        })
    }

    function renderStatusApproved(item, key: "approvalSignature" | "auditSignature") {
        const approvalDate = key === "approvalSignature" ? item.approvalStatusUpdatedAt : item.auditStatusUpdatedAt
        return (
            <div className={styles.statusWrap}>
                <div className={cx(styles.signatureWrap, styles.signature__approval)}>
                    <Icon icon="CHECKMARK_CIRCLE" className={styles.signature__statusIcon} color="#18A957" />
                    <img src={item[key].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>
                        {moment(approvalDate).local(true).format("MM/DD/YYYY hh:mm A")}
                    </p>
                    <p className={styles.dateInfo__text}>{getFullName(item[key])}</p>
                </div>
            </div>
        )
    }

    function renderStatusRejected(item, key) {
        const approvalDate = key === "approvalSignature" ? item.approvalStatusUpdatedAt : item.auditStatusUpdatedAt
        return (
            <div className={styles.statusWrap}>
                <div className={cx(styles.signatureWrap, styles.signature__reject)}>
                    <Icon icon="CROSS_CIRCLE" className={styles.signature__statusIcon} color="#DF1642" />
                    <img src={item[key].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>
                        {moment(approvalDate).local(true).format("MM/DD/YYYY hh:mm A")}
                    </p>
                    <p className={styles.dateInfo__text}>{getFullName(item[key])}</p>
                </div>
            </div>
        )
    }

    function onClickReview(type, item) {
        const permissions = getPermissionsByDepartment()
        if (currentProfilePermissions.hasPermissions(permissions)) {
            signaturePopup.open()
            dispatchState({reviewItem: item, reviewType: type})
        }
    }

    function getKeyFromType() {
        return reviewType === ReviewItemType.APPROVAL ? "approvalStatus" : "auditStatus"
    }

    async function onClickReject() {
        if (isRejecting || isApproving) {
            return
        }
        const data = {
            studentChecklistItemId: reviewItem.studentChecklistItemId
        }
        const key = getKeyFromType()
        data[key] = "rejected"
        try {
            dispatchState({isRejecting: true})
            if (reviewType === ReviewItemType.APPROVAL) {
                await studentService.updateStudentApprovalStatus(data)
            } else {
                await studentService.updateStudentAuditStatus(data)
            }
            dispatchState({reviewItem: null})
            signaturePopup.close()
            await getData()
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isRejecting: false})
        }
    }

    async function onClickApprove() {
        if (isRejecting || isApproving) {
            return
        }
        const data = {
            studentChecklistItemId: reviewItem.studentChecklistItemId
        }
        const key = getKeyFromType()
        data[key] = "approved"
        try {
            dispatchState({isApproving: true})
            if (reviewType === ReviewItemType.APPROVAL) {
                await studentService.updateStudentApprovalStatus(data)
            } else {
                await studentService.updateStudentAuditStatus(data)
            }
            dispatchState({reviewItem: null})
            signaturePopup.close()
            await getData()
            getStudentChecklistProgress()
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isApproving: false})
        }
    }

    const onClickDeleteMulti = async () => {
        const checkedIds = getCurrentData()
            .filter((item) => item.isChecked)
            .map((item) => item.studentChecklistItemId)
        try {
            dispatch({isLoading: true})
            await studentService.deleteStudentChecklistCustom({studentChecklistItemIds: checkedIds})
            await getData()
            getStudentChecklistProgress()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickEdit = () => {}

    const onClickRowItem = () => {}

    const onUpdateRowData = (updatedData) => {
        const data = getCurrentData()

        let selectedArchivedCount: number = 0
        let selectedUnarchivedCount: number = 0

        const newData = data.map((item) => {
            if (item.id === updatedData.id) {
                updatedData.isActiveTableCol = updatedData.isChecked

                if (updatedData.isActiveTableCol) {
                    updatedData.isArchived ? selectedArchivedCount++ : selectedUnarchivedCount++
                }

                return updatedData
            } else {
                if (item.isActiveTableCol) {
                    item.isArchived ? selectedArchivedCount++ : selectedUnarchivedCount++
                }
            }
            return item
        })

        let tableHeaderActionType = null
        if (selectedArchivedCount > 0 && selectedUnarchivedCount === 0) {
            tableHeaderActionType = ArchivalType.Unarchive
        } else if (selectedArchivedCount === 0 && selectedUnarchivedCount > 0) {
            tableHeaderActionType = ArchivalType.Archive
        }

        setTableHeaderActionsArchivalType(tableHeaderActionType)
        dispatch({data: newData})
    }

    const onUpdateTableData = (updatedData) => {
        const data = getCurrentData()

        let selectedArchivedCount: number = 0
        let selectedUnarchivedCount: number = 0

        const newData = data.map((item) => {
            item.isActiveTableCol = item.isChecked

            if (item.isChecked) {
                item.isArchived ? selectedArchivedCount++ : selectedUnarchivedCount++
            }
            return item
        })

        let tableHeaderActionType = null
        if (selectedArchivedCount > 0 && selectedUnarchivedCount === 0) {
            tableHeaderActionType = ArchivalType.Unarchive
        } else if (selectedArchivedCount === 0 && selectedUnarchivedCount > 0) {
            tableHeaderActionType = ArchivalType.Archive
        }

        setTableHeaderActionsArchivalType(tableHeaderActionType)
        dispatch({data: newData})
    }

    useEffect(() => {
        dispatchFunc([{key: "getTableHeaderActions", func: getTableHeaderActions}])
    }, [tableHeaderActionsArchivalType])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickRowItem", func: onClickRowItem},
            {key: "onUpdateRowData", func: onUpdateRowData},
            {key: "onUpdateTableData", func: onUpdateTableData}
        ])
        dispatch({orderField: {field: "position", order: Order.Asc}, isLoading: true})
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, orderField?.field, orderField?.order])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    function onClickAddItem() {
        addStudentChecklistCustomPopup.open()
    }

    function onCreateStudentChecklistSuccess() {
        toastSuccess("Create checklist successful")
        getData()
        onCreateSuccess()
    }

    const getDepartmentName = () => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return "Admissions"
            case BaseDepartmentId.FinancialAid:
                return "Financial_Aid"
            case BaseDepartmentId.StudentServices:
                return "Student_Service"
            case BaseDepartmentId.StudentAccount:
                return "Student_Account"
            case BaseDepartmentId.Academics:
                return "Academics"
            default:
                return ""
        }
    }

    const onClickExportBtn = async () => {
        try {
            dispatchState({isExporting: true})
            const timezone = momentTz.tz.guess()
            const data = await studentService.exportAsCsv({profileId: studentId, departmentId, timezone})
            const departmentName = getDepartmentName()
            const fileName = `StudentChecklist-${studentId}-${departmentName}_${moment().format("MM_DD_YYYY")}.csv`
            downloadFile(fileName, data)
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isExporting: false})
        }
    }

    const getPermissionsByDepartment = (): PermissionUserType => {
        switch (departmentId) {
            case BaseDepartmentId.Admissions:
                return {staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.Edit]}
            case BaseDepartmentId.FinancialAid:
                return {staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.Edit]}
            case BaseDepartmentId.Academics:
                return {staff: [Permissions.Staff.Academics.Registrar.Students.AcademicPlanning.Edit]}
            case BaseDepartmentId.StudentServices:
                return {
                    staff: [
                        Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.AcademicPlanning
                            .Edit
                    ]
                }
            default:
                return {}
        }
    }

    const permissions = getPermissionsByDepartment()
    const onModalConfirmCancel = () => {
        confirmPopup.close()
    }
    const onModalConfirmAcceptArchive = async () => {
        confirmPopup.close()
        setIsConfirmPopupArchive(false)
        await onClickArchiveMulti()
    }

    useEffect(() => {
        if (props.studentId) {
            loadStudent(+props.studentId)
        }
    }, [props.studentId])

    const loadStudent = async (studentProfileId: number) => {
        try {
            const {
                data: [student]
            } = await userServiceV3.getAll({
                filter: {
                    profileId: [+studentProfileId]
                },
                linkedObjects: true
            })
            setStudentData(student)
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <div>
            <ConfirmPopup
                isVisible={confirmPopup.isVisible}
                title={
                    isConfirmPopupArchive &&
                    tableHeaderActionsArchivalType &&
                    t("common:checklist.confirmArchive", {
                        archivalType: tableHeaderActionsArchivalType.toUpperCase()
                    })
                }
                onClose={onModalConfirmCancel}
                onConfirm={isConfirmPopupArchive && tableHeaderActionsArchivalType && onModalConfirmAcceptArchive}
            />
            <div className={styles.rightActionBtn}>
                <div className={cx(styles.archiveOldChecklistContainer)}>
                    <span className={styles.archiveOldChecklistsLabel}>{t("common:checklist.showArchived")}</span>
                    <Checkbox checked={isShowArchived} onChange={(event) => setIsShowArchived(event.target.checked)} />
                </div>
                <BaseButton title="Export" variant="secondary" onClick={onClickExportBtn} loading={isExporting} />
            </div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                actions={tableHeaderActions}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                isShowCheckedColumn={model.getUserProfileType() !== Auth.UserProfileType.Student}
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={[]}
                fields={fields}
                allFields={allFields}
                isDraggable={orderField.field === "position"}
                orderField={orderField}
                onClickRowItem={props.onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableEnd={onDraggableEnd}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <PermissionsRequired permissions={permissions}>
                <AddItemCircleButton onClick={onClickAddItem} />
            </PermissionsRequired>
            <SignaturePopup
                isShow={signaturePopup.isVisible}
                onClose={() => signaturePopup.close()}
                title="Please Review Item">
                <div className={styles.divider}></div>
                <div className={styles.action}>
                    <Button
                        className={cx(styles.actionBtn, styles.rejectBtn)}
                        onClick={onClickReject}
                        loading={isRejecting}>
                        <span>{t("action.reject")}</span>
                        <Icon icon="CROSS_CIRCLE" color="#df1642" className={styles.action__icon} />
                    </Button>
                    <Button
                        className={cx(styles.actionBtn, styles.approveBtn)}
                        onClick={onClickApprove}
                        loading={isApproving}>
                        <span>{t("action.approve")}</span>
                        <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.action__icon} />
                    </Button>
                </div>
            </SignaturePopup>
            {addStudentChecklistCustomPopup.isVisible && (
                <AddStudentChecklistPopup
                    isShow={addStudentChecklistCustomPopup.isVisible}
                    onClose={() => addStudentChecklistCustomPopup.close()}
                    profileId={+studentId}
                    departmentId={departmentId}
                    onCreateSuccess={onCreateStudentChecklistSuccess}
                    student={studentData}
                    studentCheklist={data}
                />
            )}
        </div>
    )
}

export const ChecklistTable = KlassappTableHOC(Checklist)
