/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {mediaLibraryService} from "services"
import {KlassDropAsyncPaginate, KlassDropdownAsyncProps} from "../Select"
import styles from "./MediaLibrarySelect.module.css"
import {Icon} from "components/Icon"

type Props = Partial<KlassDropdownAsyncProps>

export enum MediaLibraryItemType {
    Link = "link",
    Image = "image",
    Video = "video",
    Document = "document"
}

export const MEDIA_LIBRARY_ICONS = {
    [MediaLibraryItemType.Link]: "LINK",
    [MediaLibraryItemType.Image]: "IMAGE",
    [MediaLibraryItemType.Video]: "WATCH_VIDEO",
    [MediaLibraryItemType.Document]: "DIGITAL_DOCUMENT_TASK"
}

const MediaLibrarySelect: React.FC<Props> = (props) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "", loadedOptions) => {
            const pageSize = 10
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await mediaLibraryService.getAll({
                filter: {
                    search
                },
                range: {
                    page,
                    pageSize
                },
                sort: {orderBy: "createdAt", orderDir: "desc"}
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        }, 500),
        []
    )

    return (
        <KlassDropAsyncPaginate
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions
            valueKey="itemId"
            labelKey="title"
            getOptionLabel={(option) => {
                return (
                    <div className={styles.container}>
                        <Icon icon={MEDIA_LIBRARY_ICONS[option.type]} className={styles.icon} />
                        <span>{option.title}</span>
                    </div>
                )
            }}
        />
    )
}

export default MediaLibrarySelect
