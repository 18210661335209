/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useCallback, useEffect, useState} from "react"
import {withTranslation} from "react-i18next"
import * as QueryString from "query-string"
import {BaseLoading} from "components/Loading"
import {Screen} from "components/ui"
import {BaseButton, Button} from "components/buttons/Button"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import styles from "./AddGrading.module.css"
import {Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {isSpaceKey, toastError} from "helpers"
import {BaseInput, RadioButton} from "components"
import {KlassappTableHOC} from "HOC"
import {useHistory, useLocation} from "react-router-dom"
import {routing} from "helpers/routing"
import PassFailForm from "./PassFailForm"
import GradeForm from "./GradeForm"
import {isEqual, get, cloneDeep, differenceWith, differenceBy, pick, isEmpty, parseInt} from "lodash"
import {academicGradingSystem} from "services"
import {GradingSystemDetail, GradingType, ParamsCreateGradingSystem} from "types/gradingSystem"
import * as queryString from "query-string"
import {AcademicsTab} from "sections/Settings/Academics/Academics"
import {CheckboxChangeEvent} from "antd/lib/checkbox"

const AddGrading = (props) => {
    const location = useLocation()

    const queryParams: {id?: string} = queryString.parse(location.search)
    const isEditing = !!queryParams.id
    const [gradeData, setGradeData] = useState<any>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedIndexRadio, setSelectedIndexRadio] = useState(0)
    const history = useHistory()
    const [errors, setErrors] = useState({})
    const [id, setId] = useState("")
    const [minimumPassingGrade, setMinimumPassingGrade] = useState("")
    const [isWeightingRequired, setWeightingIsRequired] = useState(false)
    const [description, setDescription] = useState("")
    const [responseData, setResponseData] = useState<GradingSystemDetail>()
    const [initialDataPassFail, setInitialDataPassFail] = useState([])
    const [newDataPassFail, setNewDataPassFail] = useState([])

    const [initialDataGradingScale, setInitialDataGradingScale] = useState([])
    const [newDataGradingScale, setNewDataGradingScale] = useState([])

    const [initialDataGradingElements, setInitialDataGradingElements] = useState([])
    const [newDataGradingElements, setNewDataGradingElements] = useState([])

    const [isAlphabeticalScaleEnabled, setIsAlphabeticalScaleEnabled] = useState(false)
    const [isFourPointScaleEnabled, setIsFourPointScaleEnabled] = useState(false)
    const {t} = props

    const getDetailGradingSystem = async () => {
        if (!queryParams?.id) {
            return
        }
        try {
            const response = await academicGradingSystem.getDetailGradingSystem(parseInt(queryParams?.id))
            const detail = response.data
            setId(detail.code)
            setSelectedIndexRadio(detail.type === GradingType.PASSFAIL ? 0 : 1)
            setDescription(detail.title)
            setMinimumPassingGrade(detail?.minimumPassingGrade >= 0 ? `${detail.minimumPassingGrade}` : "")
            setWeightingIsRequired(!!detail?.isWeightingRequired)
            setResponseData(detail)
        } catch (err) {}
    }

    useEffect(() => {
        if (isEditing) {
            getDetailGradingSystem()
        }
    }, [])

    const compareIsChanged = () => {
        if (selectedIndexRadio === 0) {
            for (let i = 0; i < initialDataPassFail.length; i++) {
                if (
                    initialDataPassFail[i].lowValue !== newDataPassFail[i].lowValue ||
                    initialDataPassFail[i].highValue !== newDataPassFail[i].highValue ||
                    (responseData && (responseData.code !== id || responseData.title !== description))
                ) {
                    return true
                }
            }
        } else {
            if (
                initialDataGradingScale.length !== newDataGradingScale.length ||
                initialDataGradingElements.length !== newDataGradingElements.length
            ) {
                return true
            }
            const dataCheckGradingScale = ["lowValue", "highValue", "fourPointScaleValue", "alphabeticalValue"]
            const dataCheckGradingElements = [
                "gradingItemValue",
                "colorLabelValue",
                "affectGpaValue",
                "weightValue",
                "percentGpaValue",
                "minPercentageToPassValue"
            ]
            let isEqualGradingScale = true
            let isEqualGradingElements = true

            for (let i = 0; i < initialDataGradingScale.length; i++) {
                const oldGradingScale = pick(initialDataGradingScale[i], dataCheckGradingScale)
                const newGradingScale = pick(newDataGradingScale[i], dataCheckGradingScale)
                if (!isEqual(oldGradingScale, newGradingScale)) {
                    isEqualGradingScale = false
                    break
                }
            }
            for (let i = 0; i < initialDataGradingElements.length; i++) {
                const oldGradingElements = pick(initialDataGradingElements[i], dataCheckGradingElements)
                const newGradingElements = pick(newDataGradingElements[i], dataCheckGradingElements)

                if (!isEqual(oldGradingElements, newGradingElements)) {
                    isEqualGradingElements = false
                    break
                }
            }
            if (
                !isEqualGradingScale ||
                !isEqualGradingElements ||
                responseData?.code !== id ||
                responseData?.title !== description ||
                isAlphabeticalScaleEnabled !== responseData?.isAlphabeticalScaleEnabled ||
                isFourPointScaleEnabled !== responseData?.isFourPointScaleEnabled ||
                isWeightingRequired !== !!responseData?.isWeightingRequired ||
                (minimumPassingGrade !== "" && responseData?.minimumPassingGrade !== parseFloat(minimumPassingGrade))
            ) {
                return true
            }
        }
        return false
    }

    useEffect(() => {
        const {history} = props
        const searchParams: any = QueryString.parse(history.location.search)
        const {id} = searchParams
        if (id) {
        }
    }, [])

    const checkDuplicateValue = (arr1, arr2, fieldName: string) => {
        for (let i = 0; i < arr1.length; i++) {
            let count = 0
            for (let j = 0; j < arr2.length; j++) {
                if (arr1[i][fieldName] === arr2[j][fieldName]) {
                    count++
                }
                if (count >= 2) {
                    return true
                }
            }
        }
        return false
    }

    const onClickDoneBtn = useCallback(async () => {
        let totalErrors = {...errors}

        if (!description || description === "") {
            totalErrors = {...totalErrors, description: true}
        } else {
            totalErrors["description"] && delete totalErrors["description"]
        }
        if (!id || id === "") {
            totalErrors = {...totalErrors, id: true}
        } else {
            totalErrors["id"] && delete totalErrors["id"]
        }

        if (Object.keys(totalErrors).length === 0) {
            for (let i = 0; i < newDataGradingElements.length; i++) {
                const gradingElemnt = newDataGradingElements[i]
                if (
                    gradingElemnt.minPercentageToPassValue &&
                    gradingElemnt.minPercentageToPassValue !== "" &&
                    parseFloat(gradingElemnt.minPercentageToPassValue) > parseFloat(gradingElemnt.percentGpaValue)
                ) {
                    return toastError("The percentage (Min. Pass) must be equal or less than % GPA column")
                }
            }
            let params: ParamsCreateGradingSystem = {
                type: selectedIndexRadio === 1 ? GradingType.GRADES : GradingType.PASSFAIL,
                code: id,
                title: description,
                isWeightingRequired,
                gradingElements: [],
                gradingScale: [],
                gradingPassFail: [],
                isAlphabeticalScaleEnabled,
                isFourPointScaleEnabled
            }
            if (selectedIndexRadio === 0) {
                params.gradingPassFail = newDataPassFail.map((item, index) => {
                    const value: any = {
                        numericLow: parseFloat(item.lowValue),
                        numericHigh: parseFloat(item.highValue),
                        passFail: index === 0
                    }
                    if (typeof item.id == "number") {
                        value.id = item.id
                    }
                    return value
                })
            } else {
                if (
                    minimumPassingGrade !== "" &&
                    minimumPassingGrade !== undefined &&
                    minimumPassingGrade !== null &&
                    parseInt(minimumPassingGrade) >= 0
                ) {
                    params.minimumPassingGrade = parseFloat(minimumPassingGrade)
                }
                let isDuplicate = checkDuplicateValue(
                    newDataGradingElements,
                    newDataGradingElements,
                    "gradingItemValue"
                )

                if (isDuplicate) {
                    return toastError("Please enter unique field grading item")
                }
                params.gradingScale = newDataGradingScale.map((item, index) => {
                    const alphabetical = get(item, ["alphabeticalValue"])
                    const value: any = {
                        numericLow: parseFloat(item.lowValue),
                        numericHigh: parseFloat(item.highValue),
                        alphabetical: alphabetical?.length ? alphabetical : null,
                        fourPointScale:
                            !item.fourPointScaleValue || item.fourPointScaleValue === ""
                                ? null
                                : parseFloat(item.fourPointScaleValue)
                    }
                    if (typeof item.id == "number") {
                        value.id = item.id
                    }
                    return value
                })
                params.gradingElements = newDataGradingElements.map((item, index) => {
                    const value: any = {
                        gradingItem: item.gradingItemValue,
                        colorLabel: item.colorLabelValue,
                        affectGpa: !item.affectGpaValue || item.affectGpaValue === "" ? 0 : 1,
                        weight: item.weightValue && item.weightValue !== "" ? parseInt(item.weightValue) : 0,
                        percentGpa: parseFloat(item.percentGpaValue),
                        minPercentageToPass:
                            item.minPercentageToPassValue && item.minPercentageToPassValue !== ""
                                ? parseFloat(item.minPercentageToPassValue)
                                : null
                    }
                    if (typeof item.id == "number") {
                        value.id = item.id
                    }
                    return value
                })
            }
            setIsSubmitting(true)
            try {
                if (isEditing) {
                    await academicGradingSystem.updateGradingSystem(parseInt(queryParams.id), params)
                } else {
                    await academicGradingSystem.createGradingSystem(params)
                }
                setIsSubmitting(false)
                history.push(`/settings/academics?tab=${AcademicsTab.GradingSystem}`)
            } catch (err) {
                setIsSubmitting(false)
                if (isEditing) {
                    toastError("Update grading system failure")
                } else {
                    toastError("Create grading system failure")
                }
            }
        } else {
            setErrors(totalErrors)
            toastError(t("common:validation.fieldRequired"))
        }
    }, [
        errors,
        description,
        selectedIndexRadio,
        id,
        newDataPassFail,
        newDataGradingScale,
        newDataGradingElements,
        minimumPassingGrade,
        isWeightingRequired,
        isAlphabeticalScaleEnabled,
        isFourPointScaleEnabled
    ])

    const onClickCancelBtn = useCallback(() => {
        setNewDataPassFail(cloneDeep(initialDataPassFail))
        setNewDataGradingScale(cloneDeep(initialDataGradingScale))
        setNewDataGradingElements(cloneDeep(initialDataGradingElements))
        setMinimumPassingGrade(responseData?.minimumPassingGrade ? responseData?.minimumPassingGrade.toString() : "")
        setWeightingIsRequired(!!responseData?.isWeightingRequired)
        setId(responseData?.code)
        setDescription(responseData?.title)
    }, [initialDataPassFail, initialDataGradingScale, initialDataGradingElements, responseData])

    const onKeyDownCourseCode = useCallback((key, event) => {
        const {key: keyPressed} = event
        if (isSpaceKey(keyPressed)) {
            event.preventDefault()
        }
    }, [])

    let isChanged = compareIsChanged()

    const onChangeId = useCallback(
        (value: string) => {
            if (!value || value === "") {
                setErrors({...errors, id: true})
            } else {
                errors["id"] && delete errors["id"]
            }
            setId(value)
        },
        [errors]
    )
    const onChangeMinimumPassingGrade = useCallback(
        (value: string) => {
            setMinimumPassingGrade(value)
        },
        [errors]
    )

    const onChangeWeightingIsRequired = useCallback((event: CheckboxChangeEvent) => {
        setWeightingIsRequired(!event.target.checked)
    }, [])

    const onChangeDescription = useCallback(
        (value: string) => {
            if (!value || value === "") {
                setErrors({...errors, description: true})
            } else {
                errors["description"] && delete errors["description"]
            }
            setDescription(value)
        },
        [errors]
    )

    return (
        <Screen
            htmlTitle={t("settings.grades")}
            header={{
                title: responseData?.title ? responseData?.title : "",
                code: responseData?.code ? responseData?.code : "",
                backUrl: `/settings/academics?tab=${AcademicsTab.GradingSystem}`,
                variant: "column"
            }}>
            {/* <BaseLoading isShow={isLoading} /> */}
            <Col span={24}>
                <Col span={24}>
                    <Row gutter={[20, 20]}>
                        <Col span={7}>
                            <div>
                                <FormLabel label={"Id"} isRequired />
                                <BaseInput
                                    onChange={onChangeId}
                                    placeholder={"Id"}
                                    value={id}
                                    onKeyDown={(newValue) => onKeyDownCourseCode("id", newValue)}
                                    className={!!errors["id"] ? styles.error : ""}
                                />
                                {errors["id"] && (
                                    <sub className={styles.errorText}>{t("common:validation:fieldRequired")}</sub>
                                )}
                            </div>
                        </Col>
                        <Col span={17}>
                            <div>
                                <FormLabel label={t("academics:settings.description")} isRequired />
                                <BaseInput
                                    onChange={onChangeDescription}
                                    placeholder={t("academics:settings.description")}
                                    value={description}
                                    className={!!errors["description"] ? styles.error : ""}
                                />
                                {errors["description"] && (
                                    <sub className={styles.errorText}>{t("common:validation:fieldRequired")}</sub>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Col>
            <div className={styles.divider}></div>
            <div className={styles.groupRadioButton}>
                <div className={styles.radioButton}>
                    <RadioButton
                        classNameText={styles.textRadioButton}
                        checked={selectedIndexRadio === 0}
                        text="PASS/FAIL:"
                        onClick={() => setSelectedIndexRadio(0)}
                    />
                </div>
                <div className={styles.radioButtonGrade}>
                    <RadioButton
                        classNameText={styles.textRadioButton}
                        checked={selectedIndexRadio === 1}
                        text="GRADES"
                        onClick={() => setSelectedIndexRadio(1)}
                    />
                </div>
            </div>
            <div className={styles.divider}></div>
            {selectedIndexRadio === 0 ? (
                <PassFailForm
                    responseData={responseData}
                    onClickCancelBtn={onClickCancelBtn}
                    getDetailGradingSystem={getDetailGradingSystem}
                    setNewDataForm={setNewDataPassFail}
                    newDataForm={newDataPassFail}
                    setInitialDataForm={setInitialDataPassFail}
                    initialDataForm={initialDataPassFail}
                />
            ) : (
                <GradeForm
                    onClickCancelBtn={onClickCancelBtn}
                    getDetailGradingSystem={getDetailGradingSystem}
                    onChangeWeightingIsRequired={onChangeWeightingIsRequired}
                    isWeightingRequired={isWeightingRequired}
                    onChangeMinimumPassingGrade={onChangeMinimumPassingGrade}
                    responseData={responseData}
                    minimumPassingGrade={minimumPassingGrade}
                    setInitialDataGradingScale={setInitialDataGradingScale}
                    setInitialDataGradingElements={setInitialDataGradingElements}
                    initialDataGradingScale={initialDataGradingScale}
                    initialDataGradingElements={initialDataGradingElements}
                    newDataGradingScale={newDataGradingScale}
                    newDataGradingElements={newDataGradingElements}
                    setNewDataGradingElements={setNewDataGradingElements}
                    setNewDataGradingScale={setNewDataGradingScale}
                    isAlphabeticalScaleEnabled={isAlphabeticalScaleEnabled}
                    isFourPointScaleEnabled={isFourPointScaleEnabled}
                    setIsAlphabeticalScaleEnabled={setIsAlphabeticalScaleEnabled}
                    setIsFourPointScaleEnabled={setIsFourPointScaleEnabled}
                />
            )}
            <PermissionsRequired
                permissions={{
                    staff: !gradeData?.name
                        ? [Permissions.Staff.Settings.General.Courses.Add]
                        : [Permissions.Staff.Settings.General.Courses.Edit]
                }}>
                <div className={styles.actionWrap}>
                    {isChanged && (
                        <BaseButton
                            title={t("common:action.cancel")}
                            variant="secondary"
                            className={styles.cancelBtn}
                            onClick={onClickCancelBtn}
                        />
                    )}
                    <div>
                        <BaseButton
                            title={isSubmitting ? t("common:action.saving") : t("common:action.save")}
                            onClick={onClickDoneBtn}
                            isActive={isChanged}
                            disabled={!!Object.keys(errors).length}
                            loading={isSubmitting}
                        />
                    </div>
                </div>
            </PermissionsRequired>
        </Screen>
    )
}

export default KlassappTableHOC(withTranslation(["academics", "common", "course"])(AddGrading))
