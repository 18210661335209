import {useQuery} from "@tanstack/react-query"
import {profileService} from "services"
import {Auth} from "types/auth"
import PayingMethod = Auth.PayingMethod

const queryCacheKey = "all-paying-methods"
const INITIAL_DATA: PayingMethod[] = []

export const useAllPayingMethods = () => {
    const query = useQuery<PayingMethod[]>(
        [queryCacheKey],
        async () => {
            const data = await profileService.getPayingMethods({
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                }
            })
            return data.map((payingMethod) => ({
                ...payingMethod,
                id: payingMethod.payingMethodId
            }))
        },
        {
            refetchOnMount: false
        }
    )

    return {
        payingMethods: query.data ?? INITIAL_DATA
    }
}
