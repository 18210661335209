/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {Tooltip} from "antd"
import {Icon} from "components/Icon"
import {AcademicPlans} from "types/academicPlans"
import {Auth} from "types/auth"
import styles from "./CourseItem.module.css"
import {Course} from "types/courses"
import {TermViewCourseType} from "./TermViewTab"

type Props = {
    termItem: AcademicPlans.Term
    studentId: number
    student: Auth.DepartmentStudent
    termSection: AcademicPlans.TermSection
    termCourse: any
    canTermRegister: boolean
    conflictEvents: any[]
    availableCourses: any[]
    unavailableScheduleIds?: number[]
    registeringTerm: AcademicPlans.Term
    reloadView: () => void
    updateEvents: (data: AcademicPlans.UpdateEvents[]) => void
    onRegisterCourseSuccess: () => void
}

export function TransferAndPreviouslyCompletedCourse(props: Props) {
    const {studentId, termCourse} = props

    const renderTerm = () => {
        return (
            <div className={styles.courseItemBodyItem}>
                <span className={styles.courseItemBodyItem__title}>Term</span>
                <span className={styles.courseItemBodyItem__value}>
                    {termCourse.courseType === TermViewCourseType.Transfer
                        ? termCourse?.termNameTransfer ?? ""
                        : termCourse?.termName ?? ""}
                </span>
            </div>
        )
    }

    const renderReviewStatus = () => {
        return (
            <div className={styles.courseStatus}>
                <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.courseStatus__icon} />
                <span className={styles.courseStatus__registered}>
                    Completed ({termCourse.courseType === TermViewCourseType.Transfer ? "T" : "P"})
                </span>
            </div>
        )
    }

    return (
        <div className={styles.courseItem}>
            <div className={styles.bodyWrap}>
                {termCourse.role === Course.AcademicPlanCourseRole.ClassAssistance ? (
                    <div className={styles.classAssistance}>
                        <span className={styles.classAssistanceLabel}>Class Assistance</span>
                        <Tooltip title={"Clock hour will not be calculated"} key={"Class Assistance"}>
                            <div className={styles.classAssistanceInfo}>
                                <Icon icon={"INFO"} />
                            </div>
                        </Tooltip>
                    </div>
                ) : null}
                <div className={styles.courseItemDetail}>
                    <div className={styles.courseItemDetail__left}>
                        <span className={styles.courseItemDetail__left__courseCode}>
                            {termCourse.courseType === TermViewCourseType.Transfer
                                ? termCourse.codeEquivalent
                                : termCourse.codeApplied}
                        </span>
                        <span className={styles.courseItemDetail__left__courseName}>
                            {termCourse.courseType === TermViewCourseType.Transfer
                                ? termCourse.courseName
                                : termCourse.courseNameApplied}
                        </span>
                    </div>
                </div>
                <div className={styles.courseItemDetail}>
                    <div className={styles.courseItemBodyItem}>
                        <span className={styles.courseItemBodyItem__title}>Credit</span>
                        <div className={styles.courseItemBodyItem__creditWrap}>
                            <span className={styles.courseItemBodyItem__credit}>
                                {termCourse.courseType === TermViewCourseType.Transfer
                                    ? termCourse.appliedCredits ?? 0
                                    : termCourse.creditsApplied ?? 0}
                            </span>
                        </div>
                    </div>
                    {renderTerm()}
                </div>
                {renderReviewStatus()}
            </div>
        </div>
    )
}
