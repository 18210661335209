import React from "react"
import {Row, Col, Progress} from "antd"
import cx from "classnames"
import {Icon} from "components/Icon"
import styles from "./ProfileScore.module.css"
import {useModel} from "hooks"
import {formatDateOnly, formatDecimal, formatDecimalWithoutRound, getFieldLabel, roundTwoDigits} from "helpers"
import {Settings} from "types/settings"

import {IconEdit2Fill} from "components/ui/icons"
import {AcademicPlans} from "types/academicPlans"

type Props = {
    missedHours?: number
    attendanceRating?: any
    cgpa?: number
    isShowSapStatus?: boolean
    onlyShowCgpa?: boolean
    onClickEditLda?: () => void
    onEditSapStatus?: () => void
    onEditGpa?: () => void
    onEditAttendance?: () => void
    onEditSapDate?: () => void
    isEditableLda?: boolean
    isEditableSapStatus?: boolean
    isEditableGpa?: boolean
    isEditableAttendance?: boolean
    gpa?: number
    lastDayAttendance?: string
    sapStatus?: AcademicPlans.SapStatus
    sapDate?: string
}

const ProfileScore = (props: Props) => {
    const {
        isEditableLda = false,
        isEditableSapStatus = false,
        missedHours = 0,
        attendanceRating,
        gpa = 0,
        cgpa = 0,
        onlyShowCgpa,
        lastDayAttendance,
        sapStatus,
        isEditableGpa,
        isShowSapStatus,
        sapDate,
        onEditSapStatus,
        onEditGpa,
        isEditableAttendance,
        onEditAttendance,
        onEditSapDate,
        onClickEditLda
    } = props
    const model = useModel()

    return (
        <div className={styles.wrap}>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <div className={cx(styles.itemWrap, styles.firstItem)}>
                        <div className={styles.itemHeader}>
                            <Icon icon="PERSON_FILL" color="#62B1FF" />
                            <p className={styles.headerTitle}>Attendance</p>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.editAttendance}>
                                <Progress
                                    type="circle"
                                    percent={roundTwoDigits(attendanceRating ?? 0)}
                                    strokeColor="#1e90ff"
                                    strokeWidth={10}
                                />
                                {isEditableAttendance ? (
                                    <div onClick={onEditAttendance} className={styles.editSap}>
                                        <IconEdit2Fill className={styles.iconEditLda} />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.itemWrap}>
                        <div className={styles.itemHeader}>
                            <Icon icon="COURSE" color="#62B1FF" />
                            <p className={styles.headerTitle}>
                                {onlyShowCgpa
                                    ? getFieldLabel(model, Settings.AcademicLabel.Cgpa, "CGPA")
                                    : getFieldLabel(model, Settings.AcademicLabel.Gpa, "GPA")}
                            </p>
                        </div>
                        <div className={styles.body}>
                            <div className={styles.editGpa}>
                                <p className={cx(styles.bodyDesc, styles.gpaScore)}>
                                    {onlyShowCgpa ? formatDecimal(cgpa) : formatDecimal(gpa)}
                                </p>
                                {isEditableGpa ? (
                                    <div onClick={onEditGpa} className={styles.editSap}>
                                        <IconEdit2Fill className={styles.iconEditLda} />
                                    </div>
                                ) : null}
                            </div>
                            {!onlyShowCgpa ? (
                                <div className={cx(styles.bodyDesc, styles.gpaBody)}>
                                    <p className={styles.gpaText}>
                                        {getFieldLabel(model, Settings.AcademicLabel.Cgpa, "CGPA")}:
                                    </p>
                                    <p className={styles.gpaScoreBottom}>{formatDecimal(cgpa)}</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.itemWrap}>
                        <div className={styles.itemHeader}>
                            <Icon icon="LDA" color="#62B1FF" />
                            <p className={styles.headerTitle}>LDA</p>
                            {isEditableLda ? (
                                <div onClick={onClickEditLda} className={styles.buttonEditLda}>
                                    <IconEdit2Fill className={styles.iconEditLda} />
                                </div>
                            ) : null}
                        </div>
                        <div className={styles.body}>
                            <div className={cx(styles.ldaBody)}>
                                {lastDayAttendance ? (
                                    <div className={styles.ldaItem}>
                                        <Icon icon="CALENDAR" color="#62B1FF" className={styles.ldaIcon} />
                                        <p className={styles.ldaText}>
                                            {formatDateOnly(lastDayAttendance, model.getUserDateFormat())}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                            <div className={cx(styles.ldaBody)}>
                                <div className={styles.ldaItem}>
                                    <p className={styles.missedTitle}>MISSED HOURS:</p>
                                    <p className={styles.missedText}>{missedHours ? roundTwoDigits(missedHours) : 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={6}>
                    {isShowSapStatus ? (
                        <div className={cx(styles.itemWrap, styles.lastItem)}>
                            <div className={styles.itemHeader}>
                                <Icon icon="LDA" color="#62B1FF" />
                                <p className={styles.headerTitle}>SAP STATUS/DATE</p>
                            </div>
                            <div className={styles.sapView}>
                                <div className={styles.body}>
                                    <div className={cx(styles.ldaBody)}>
                                        <div className={styles.ldaItem}>
                                            <p className={styles.sapStatusTitle}>SAP STATUS:</p>
                                            <p className={styles.sapStatus}>{sapStatus ?? "NaN"}</p>
                                        </div>
                                    </div>
                                    <div className={cx(styles.ldaBody)}>
                                        <div className={styles.ldaItem}>
                                            <p className={styles.sapStatusTitle}>SAP DATE:</p>
                                            <p className={styles.sapStatus}>
                                                {formatDateOnly(sapDate, model.getUserDateFormat(), "N/A")}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {isEditableSapStatus ? (
                                    <div onClick={onEditSapStatus} className={styles.editSap}>
                                        <IconEdit2Fill className={styles.iconEditLda} />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </Col>
            </Row>
        </div>
    )
}

export {ProfileScore}
