import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdownAsyncProps} from "../Select"
import {BaseDepartmentId} from "types/departments"
import orderBy from "lodash/orderBy"
import {KlassDropdown} from "../inputs"
import {useAllActivities} from "hooks"

type Props = Partial<KlassDropdownAsyncProps> & {
    departmentId?: number | number[]
    filterGroup?: boolean
    excludeActivityGroupId?: number
}

const ActivitiesSelect: React.FC<Props> = ({departmentId, filterGroup = false, excludeActivityGroupId, ...props}) => {
    const {t} = useTranslation(["workflow", "common"])
    const {activities} = useAllActivities()

    const filteredActivities = useMemo(
        () =>
            activities.filter((activity) => {
                if (!activity.isActive) return false
                if (departmentId) {
                    if (!(Array.isArray(departmentId) ? departmentId : [departmentId]).includes(activity.departmentId))
                        return false
                }
                if (filterGroup) {
                    if (excludeActivityGroupId) {
                        return !activity.activityGroupIds?.filter((groupId) => groupId !== excludeActivityGroupId)
                            ?.length
                    } else {
                        return !activity.activityGroupIds?.length
                    }
                }
                return true
            }),
        [activities, departmentId, filterGroup, excludeActivityGroupId]
    )

    const activitiesGroups = React.useMemo(
        () =>
            (Object.values(BaseDepartmentId).filter((v) => typeof v === "number") as number[])
                .map((departmentId) => {
                    let options = orderBy(
                        filteredActivities.filter((activity) => activity.departmentId === departmentId)
                    )

                    options = options.map(({activityId, name, departmentId}) => ({
                        activityId,
                        name,
                        departmentId
                    }))

                    return {
                        label: t(`workflow.statusDepartment.${departmentId}`),
                        options
                    }
                })
                .filter((group) => group.options.length > 0),
        [t, filteredActivities]
    )

    return (
        <KlassDropdown
            {...props}
            options={activitiesGroups}
            valueKey="activityId"
            labelKey="name"
            getOptionLabel={(option) =>
                `[${t(`common:moduleAbbrev.${option.departmentId || option.stage?.departmentId || option.id}`)}] ${
                    option.name
                }`
            }
        />
    )
}

export default ActivitiesSelect
