import {Checkbox, Col, Radio} from "antd"
import styles from "./MakeupAttendance.module.css"
import {useCallback, useEffect, useMemo, useState} from "react"
import RoundingRange from "../DailyAttendance/RoundingRange"
import {useTranslation} from "react-i18next"
import {BaseButton, BaseLoading} from "components"
import {useMutation, useQuery} from "@tanstack/react-query"
import {generalSettingsService} from "services"
import {AttendanceRoundingType, GeneralSettingField} from "types/settings/general-setting"
import {handleError, toastError} from "helpers"
import {cloneDeep, isEqual, pick} from "lodash"
import {YesNo} from "types/common"
import {useAttendanceRounding} from "hooks"
import {RoundingType} from "../DailyAttendance"

type MakeupAttendanceProps = {}

const MakeupAttendance = (props: MakeupAttendanceProps) => {
    const [isRoundingEnabled, setIsRoundingEnabled] = useState(false)
    const [enableDocumentAttachment, setEnableDocumentAttachment] = useState(false)
    const [enableRemoteMakeupAttendance, setEnableRemoteMakeupAttendance] = useState(false)
    const {t} = useTranslation(["common"])
    const [currentData, setCurrentData] = useState([])

    const {data: generalSettings, refetch: refetchGeneralSettings} = useQuery(
        ["makeup-attendance-setting"],
        async () => {
            return await generalSettingsService.getSettings([
                GeneralSettingField.MakeupDocumentAttachment,
                GeneralSettingField.MakeupRemote
            ])
        },
        {
            initialData: []
        }
    )
    const updateGeneralSettings = useMutation(
        async ({field, checked}: {field: GeneralSettingField; checked: boolean}) => {
            await generalSettingsService.updateSettings([
                {
                    field,
                    value: checked ? YesNo.Yes : YesNo.No
                }
            ])
        },
        {
            onSuccess() {
                refetchGeneralSettings()
            },
            onError: (err) => {
                handleError(err)
            }
        }
    )

    const initialEnableDocumentAttachment = useMemo(
        () =>
            generalSettings.find((item) => item.field === GeneralSettingField.MakeupDocumentAttachment)?.value ===
            YesNo.Yes,
        [generalSettings]
    )
    const initialEnableRemoteMakeupAttendance = useMemo(
        () => generalSettings.find((item) => item.field === GeneralSettingField.MakeupRemote)?.value === YesNo.Yes,
        [generalSettings]
    )
    const attendanceRounding = useAttendanceRounding({
        type: AttendanceRoundingType.Makeup
    })

    useEffect(() => {
        setIsRoundingEnabled(attendanceRounding.isEnabled)
    }, [attendanceRounding.isEnabled])

    useEffect(() => {
        setCurrentData(cloneDeep(attendanceRounding.items.map((item, index) => ({...item, id: index}))))
    }, [attendanceRounding.items])

    useEffect(() => {
        setEnableDocumentAttachment(initialEnableDocumentAttachment)
        setEnableRemoteMakeupAttendance(initialEnableRemoteMakeupAttendance)
    }, [initialEnableDocumentAttachment, initialEnableRemoteMakeupAttendance])

    const onClickSave = useCallback(async () => {
        for (let attendanceRounding of currentData) {
            if (
                !attendanceRounding.from?.toString()?.length ||
                !attendanceRounding.to?.toString()?.length ||
                !attendanceRounding.value?.toString()?.length
            ) {
                toastError("From, To, Value cannot be empty.")
                return
            }
        }
        try {
            updateGeneralSettings.mutate({
                field: GeneralSettingField.MakeupDocumentAttachment,
                checked: enableDocumentAttachment
            })
            updateGeneralSettings.mutate({
                field: GeneralSettingField.MakeupRemote,
                checked: enableRemoteMakeupAttendance
            })

            await attendanceRounding.update({
                data: currentData.map((item) => {
                    return {
                        from: Number(item.from),
                        to: Number(item.to),
                        value: Number(item.value)
                    }
                }),
                enabled: isRoundingEnabled,
                type: AttendanceRoundingType.Makeup
            })
        } catch (error: any) {
            handleError(error)
        }
    }, [
        attendanceRounding,
        currentData,
        isRoundingEnabled,
        updateGeneralSettings,
        enableDocumentAttachment,
        enableRemoteMakeupAttendance
    ])

    const onClickCancelBtn = useCallback(() => {
        setEnableDocumentAttachment(initialEnableDocumentAttachment)
        setEnableRemoteMakeupAttendance(initialEnableRemoteMakeupAttendance)
        setIsRoundingEnabled(attendanceRounding.isEnabled)
        setCurrentData(cloneDeep(attendanceRounding.items.map((item, index) => ({...item, id: index}))))
    }, [
        initialEnableDocumentAttachment,
        initialEnableRemoteMakeupAttendance,
        attendanceRounding.isEnabled,
        attendanceRounding.items
    ])

    const isChangedTableData = () => {
        const fieldNames = ["from", "to", "value"]
        if (attendanceRounding.items.length !== currentData.length) {
            return true
        }
        for (let i = 0; i < attendanceRounding.items.length; i++) {
            const oldData = pick(attendanceRounding.items[i], fieldNames)
            const newData = pick(currentData[i], fieldNames)
            if (!isEqual(oldData, newData)) {
                return true
            }
        }
        return false
    }
    const isChanged =
        isRoundingEnabled !== attendanceRounding.isEnabled ||
        initialEnableDocumentAttachment !== enableDocumentAttachment ||
        initialEnableRemoteMakeupAttendance !== enableRemoteMakeupAttendance ||
        isChangedTableData()

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Document attachment</span>
            <Col span={24} className={styles.checkboxContainer}>
                <Checkbox
                    checked={enableDocumentAttachment}
                    onChange={(event) => setEnableDocumentAttachment(event.target.checked)}>
                    Enable
                </Checkbox>
            </Col>
            <hr className={styles.line} />
            <span className={styles.title}>Remote makeup attendance</span>
            <Col span={24} className={styles.checkboxContainer}>
                <Checkbox
                    checked={enableRemoteMakeupAttendance}
                    onChange={(event) => setEnableRemoteMakeupAttendance(event.target.checked)}>
                    Enable
                </Checkbox>
            </Col>
            <hr className={styles.line} />
            <span className={styles.title}>Makeup attendance rounding</span>
            <Col span={24} className={styles.checkboxContainer}>
                <Radio.Group
                    name=""
                    className={styles.radioGroup}
                    onChange={(event) => {
                        setIsRoundingEnabled(event.target.value === RoundingType.Rounding)
                    }}
                    value={isRoundingEnabled ? RoundingType.Rounding : RoundingType.NoRounding}>
                    <Radio className={styles.radioItem} value={RoundingType.NoRounding}>
                        No Rounding
                    </Radio>
                    <Radio className={styles.radioItem} value={RoundingType.Rounding}>
                        Rounding
                    </Radio>
                </Radio.Group>
                {isRoundingEnabled ? <RoundingRange listData={currentData} setListData={setCurrentData} /> : null}
            </Col>
            <hr className={styles.line} />
            <div className={styles.action}>
                <BaseButton
                    title={t("common:action.cancel")}
                    variant="secondary"
                    className={styles.cancelBtn}
                    disabled={!isChanged}
                    onClick={onClickCancelBtn}
                />
                <BaseButton
                    title={t("common:action.save")}
                    onClick={onClickSave}
                    disabled={!isChanged}
                    loading={attendanceRounding.isUpdating}
                />
            </div>
            <BaseLoading isShow={attendanceRounding.isUpdating || attendanceRounding.isFetching} />
        </div>
    )
}

export {MakeupAttendance}
