import React from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import styles from "./Status.module.css"
import {RefundStatus} from "types/fin-aid/refund"

type Props = {
    status: RefundStatus
}

const Status: React.FC<Props> = ({status}) => {
    const {t} = useTranslation(["financialAid"])

    return (
        <div className={cx(styles.statusWrap, status)}>
            <span className={styles.statusText}>{t(`refund.status.${status}`)}</span>
        </div>
    )
}

export default Status
