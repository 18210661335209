import {useQuery} from "@tanstack/react-query"
import {userService} from "services"

type UserProps = {
    userId?: number
}

export const useUser = ({userId}: UserProps) => {
    const query = useQuery(
        ["user", userId],
        async () => {
            const user = await userService.getUserById({filter: {id: [userId]}, linkedObjects: false})
            return user
        },
        {
            enabled: !!userId,
            refetchOnMount: false
        }
    )

    return {
        user: query.data,
        isLoading: query.isFetching
    }
}
