/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect} from "react"
import {KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import styles from "./PaymentPlanItemTable.module.css"
import moment from "moment/moment"
import {useModel} from "hooks"
import {formatCurrency, formatDateOnly, toDateOnly} from "helpers"
import {PaymentPlan, PaymentPlanItem} from "types/student-account/payment-plan"
import {useTranslation} from "react-i18next"

type Props = KlassappTableProps & {
    paymentPlan: Partial<PaymentPlan>
}

const PaymentPlanItemTable = ({
    columns,
    data,
    allFields,
    fields,
    menuActions,
    isLoading,
    dispatch,
    dispatchFunc,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    paymentPlan
}: Props) => {
    const {t} = useTranslation(["studentAccount", "common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    const getFields = useCallback(
        () => [
            t("payments.column.id"),
            t("payments.column.originalAmount"),
            t("payments.column.paidAmount"),
            t("payments.column.dueDate"),
            t("payments.column.status")
        ],
        []
    )

    const getColumns = useCallback(() => {
        return [
            {
                title: t("payments.column.id"),
                field: "paymentPlanItemId"
            },
            {
                title: t("payments.column.originalAmount"),
                field: "amount",
                render: (value) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("payments.column.paidAmount"),
                field: "paidAmount",
                render: (value) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("payments.column.dueDate"),
                field: "dueDate",
                render: (value) => formatDateOnly(value, userDateFormat)
            },
            {
                title: t("payments.column.status"),
                field: "amount",
                render: (_, record: PaymentPlanItem) => {
                    if (!record.paidAmount) {
                        const dueDate = moment(toDateOnly(record.dueDate))
                        const numDays = Math.abs(moment().diff(dueDate, "day"))
                        const isPastDue = dueDate.isBefore(Date.now(), "day")
                        const isDue = moment().add(20, "day").isAfter(dueDate)
                        if (isPastDue)
                            return (
                                <span className={styles.pastDue}>
                                    Past Due{" "}
                                    {t(!numDays ? "paymentPlan.today" : "paymentPlan.numDays", {
                                        count: numDays
                                    })}
                                </span>
                            )
                        if (isDue)
                            return (
                                <span className={styles.due}>
                                    Due{" "}
                                    {t(!numDays ? "paymentPlan.today" : "paymentPlan.numDays", {
                                        count: numDays
                                    })}
                                </span>
                            )
                        return "Pending"
                    }
                    if (record.paidAmount < record.amount) return "Partially Paid"
                    return "Completed"
                }
            }
        ]
    }, [])

    const onClickRowItem = useCallback(() => {}, [])

    const getMenuActions = useCallback(() => [], [])

    const getData = useCallback(() => {
        const data = paymentPlan.items || []
        dispatch({total: data.length, data: data.map((item) => ({...item, id: item.paymentPlanItemId}))})
    }, [paymentPlan.items])

    useEffect(() => {
        getData()
    }, [paymentPlan?.items])

    useEffect(() => {
        dispatch({isClassComponent: false, isHideMenuActions: true})
        dispatchFunc([
            {key: "getPageTitle", func: () => ""},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getMenuActions", func: getMenuActions},
            {key: "getListData", func: getData}
        ])
    }, [])

    return (
        <div className={styles.wrap}>
            <h3 className={styles.title}>PAYMENTS</h3>
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn={false}
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(PaymentPlanItemTable)
