import qs, {ParsedQuery} from "query-string"
import {CALENDAR_SETTINGS_TABS} from "types/calendarSettings"
import {WorkflowType} from "types/workflow"
import {FinancialAidStudentTabs} from "sections/FinancialAid/FinancialAidStudent/FinancialAidStudent"
import {FinancialAidStaffTabs} from "sections/FinancialAid/FinancialAidStaff"
import {StudentAccountStaffTab} from "sections/StudentAccount/Staff/StudentAccountStaff"
import {StudentAccountStudentTab} from "sections/StudentAccount/Student/StudentAccountStudent"
import {StudentLedgerSubtab} from "sections/StudentAccount/Student/parts"
import {FinAidPlanTabs} from "sections/FinancialAid/FinancialAidStudent/parts/FinAidPlan/FinAidPlan"
import {AwardTab} from "sections/FinancialAid/FinancialAidStudent/parts/FinAidAward/FinAidAward"

const stringify = (url: string, query: ParsedQuery) =>
    qs.stringifyUrl({url, query}, {skipEmptyString: true, skipNull: true})

const routing = {
    academics: {
        instructional: {
            index: () => "/academics/instructional",
            course: (courseId: number, termId: number | string = "current", tab: number = 1, scheduleId?: number) => {
                if (scheduleId) {
                    return `/academics/instructional/term/${termId}/course/${courseId}/schedule/${scheduleId}?tab=${tab}`
                }
                return `/academics/instructional/term/${termId}/course/${courseId}?tab=${tab}`
            },
            lessonDetail: (
                courseId: number,
                termId: number | string,
                lessonId: number,
                tab: number = 1,
                scheduleId?: number
            ) => {
                if (scheduleId) {
                    return `/academics/instructional/term/${termId}/course/${courseId}/schedule/${scheduleId}/lessons/${lessonId}?tab=${tab}`
                }
                return `/academics/instructional/term/${termId}/course/${courseId}/lessons/${lessonId}/?tab=${tab}`
            },
            courseGradesDetail: (courseId: number, termId: number | string, lessonId: number, tab: number = 1) =>
                `/academics/grades/term/${termId}/course/${courseId}/lessons/${lessonId}/?tab=${tab}`,
            manualGrade: (
                courseId: number,
                termId: number | string,
                scheduleId?: number,
                academicCourseGradeId?: number
            ) => {
                if (scheduleId) {
                    return `/academics/instructional/term/${termId}/course/${courseId}/schedule/${scheduleId}/manual-grade${
                        academicCourseGradeId ? `?academicCourseGradeId=${academicCourseGradeId}` : ""
                    }`
                }
                return `/academics/instructional/term/${termId}/course/${courseId}/manual-grade${
                    academicCourseGradeId ? `?academicCourseGradeId=${academicCourseGradeId}` : ""
                }`
            },
            courseStudentDetail: (
                courseId: number,
                termId: number | string = "current",
                studentId: number,
                tab: string = "academic"
            ) => `/academics/instructional/term/${termId}/course/${courseId}/student/${studentId}?tab=${tab}`
        },
        activities: {
            manualGrade: (
                courseId: number,
                termId: number | string,
                scheduleId?: number,
                academicCourseGradeId?: number
            ) => {
                if (scheduleId) {
                    return `/academics/activities/term/${termId}/course/${courseId}/schedule/${scheduleId}/manual-grade${
                        academicCourseGradeId ? `?academicCourseGradeId=${academicCourseGradeId}` : ""
                    }`
                }
                return `/academics/activities/term/${termId}/course/${courseId}/manual-grade${
                    academicCourseGradeId ? `?academicCourseGradeId=${academicCourseGradeId}` : ""
                }`
            }
        }
    },
    registrar: {
        lessonDetail: (courseId: number, termId: number | string, lessonId: number, tab: number = 1) =>
            `/academics/registrar/term/${termId}/course/${courseId}/lessons/${lessonId}?tab=${tab}`,
        termCourse: () => {
            return `/academics/registrar/staff?tab=termAndCourses`
        },
        course: (courseId: number, termId: number | string = "current", tab: number = 1) => {
            return `/academics/registrar/term/${termId}/course/${courseId}?tab=${tab}`
        },
        courseStudentDetail: (
            courseId: number,
            termId: number | string = "current",
            studentId: number,
            tab: string = "academic"
        ) => `/academics/registrar/term/${termId}/course/${courseId}/student/${studentId}?tab=${tab}`,
        sapAlertDetail: ({
            departmentId,
            studentProfileId
        }: {
            departmentId: number
            studentProfileId: number
        }): string => {
            return `/academics/registrar/student/${studentProfileId}/?department=${departmentId}&saptype=alerts&tab=sap&view=list`
        },
        activityDetail: ({
            studentProfileId,
            courseId,
            termId,
            scheduleId,
            academicCourseGradeId
        }: {
            studentProfileId: number
            courseId: number
            termId: number | string
            scheduleId?: number
            academicCourseGradeId?: number
        }) => {
            if (scheduleId) {
                return `/academics/registrar/student/${studentProfileId}/term/${termId}/course/${courseId}/schedule/${scheduleId}/activity${
                    academicCourseGradeId ? `?academicCourseGradeId=${academicCourseGradeId}` : ""
                }`
            }
            return `/academics/registrar/student/${studentProfileId}/term/${termId}/course/${courseId}/activity${
                academicCourseGradeId ? `?academicCourseGradeId=${academicCourseGradeId}` : ""
            }`
        }
    },
    finAid: {
        settings: (tab) => `/settings/financial-aid?tab=${tab}`,
        staff: {
            index: (tab: FinancialAidStaffTabs) => `/financial-aid/staff?tab=${tab}`
        },
        student: {
            index: (
                profileId,
                tab?: FinancialAidStudentTabs,
                {
                    awardtab,
                    finaidtab
                }: {
                    finaidtab?: FinAidPlanTabs
                    awardtab?: AwardTab
                } = {}
            ) =>
                `/financial-aid/student/${profileId}?tab=${tab}${awardtab ? `&awardtab=${awardtab}` : ""}${
                    finaidtab ? `&finaidtab=${finaidtab}` : ""
                }`,
            isirDetail: (profileId: number | null) => `/financial-aid/student/${profileId}`,
            worksheetDetail: (studentId: number, worksheetId: number | "new") =>
                `/financial-aid/student/${studentId}/worksheets/${worksheetId}`,
            worksheet2Detail: (studentId: number, worksheetId: number | "new") =>
                `/financial-aid/student/${studentId}/aw-clock/${worksheetId}`,
            offerDetail: (studentId: number, offerId: number) =>
                `/financial-aid/student/${studentId}/offers/${offerId}`,
            offerDocumentDetail: (studentId: number, offerId: number) =>
                `/financial-aid/student/${studentId}/offer-docs/${offerId}`,
            cfpDetail: (studentId: number, cfpId: number | "new") =>
                `/financial-aid/student/${studentId}/cfps/${cfpId}`,
            cfpFinAidOffer: (studentId: number) => `/financial-aid/student/${studentId}/cfp-fao`,
            awardSummaryDetail: (studentId: number, awardSummaryId: number | "new") =>
                `/financial-aid/student/${studentId}/award-summaries/${awardSummaryId}`,
            disbursementDetail: (studentId: number, disbursementId: number | "new") =>
                `/financial-aid/student/${studentId}/disbursements/${disbursementId}`,
            refundDetail: (studentId: number, refundId: number | "new") =>
                `/financial-aid/student/${studentId}/refunds/${refundId}`
        }
    },
    studentAccount: {
        staff: {
            billing: {
                index: () => `/student-account/staff?tab=${StudentAccountStaffTab.Billings}`,
                detail: (billingId: number | "new") => `/student-account/staff/billings/${billingId}`
            }
        },
        student: {
            ledger: {
                charge: {
                    index: (profileId: number) =>
                        `/student-account/student/${profileId}?tab=${StudentAccountStudentTab.StudentLedger}&subtab=${StudentLedgerSubtab.Charges}`,
                    detail: (profileId: number, chargeId: number | "new") =>
                        `/student-account/student/${profileId}/charges/${chargeId}`
                },
                payment: {
                    index: (profileId: number) =>
                        `/student-account/student/${profileId}?tab=${StudentAccountStudentTab.StudentLedger}&subtab=${StudentLedgerSubtab.Payments}`,
                    detail: (profileId: number, paymentId: number | "new") =>
                        `/student-account/student/${profileId}/payments/${paymentId}`
                }
            }
        }
    },
    coa: {
        templates: (tab) => `/coa/templates?tab=${tab}`,
        coaTemplate: {
            create: () => "/coa/templates/coa-details",
            edit: (id: number) => `/coa/templates/coa-details/${id}`
        },
        rateTemplate: {
            create: () => "/coa/templates/rate-details",
            edit: (id: number) => `/coa/templates/rate-details/${id}`
        },
        cfpSettings: {
            preview: () => "/coa/cfp-settings/preview"
        }
    },
    calendarSettings: {
        templates: (list: CALENDAR_SETTINGS_TABS = CALENDAR_SETTINGS_TABS.WorkSchedule) =>
            stringify("/settings/calendar", {tab: list}),
        workSchedule: {
            create: () => "/settings/calendar/work-schedule-details",
            edit: (id: number) => `/settings/calendar/work-schedule-details/${id}`
        },
        custom: {
            create: () => "/settings/calendar/custom-details",
            edit: (id: number) => `/settings/calendar/custom-details/${id}`
        },
        eventType: {
            create: () => "/settings/calendar/event-type-details",
            edit: (id: number) => `/settings/calendar/event-type-details/${id}`
        }
    },
    settings: {
        terms: {
            index: () => "/settings/terms",
            create: () => "/settings/terms/detail?tab=1",
            edit: (id: number) => `/settings/terms/detail?id=${id}&tab=1`,
            schedule: {
                index: (termId: number) => `/settings/terms/detail?id=${termId}&tab=2`,
                create: (termId: number, queryString: string) => `/settings/terms/${termId}/schedules?${queryString}`,
                edit: (termId: number) => `/settings/terms/${termId}/schedules`
            },
            grade: {
                index: (termId: number) => `/settings/terms/detail?id=${termId}&tab=3`,
                create: (termId: number) => `/settings/terms/${termId}/grade/detail`,
                edit: (termId: number, gradeId: number) => `/settings/terms/${termId}/grade/detail?id=${gradeId}`
            },
            coa: {
                index: (termId: number) => `/settings/terms/detail?id=${termId}&tab=4`,
                create: (termId: number) => `/settings/terms/${termId}/coa/detail`,
                edit: (termId: number, gradeId: number) => `/settings/terms/${termId}/coa/detail?id=${gradeId}`
            }
        },
        workflow: {
            index: () => "/settings/workflows",
            detail: (id: number | WorkflowType) => `/settings/workflows/${id}`,
            detailPath: () => "/settings/workflows/:id/:action?",
            contactStrategyDetail: (id: number | "add") => `/settings/contact-strategy/${id}`,
            contactStrategyDetailPath: "/settings/contact-strategy/:id"
        }
    },
    reports: {
        list: (
            tab:
                | "admin-reports"
                | "admissions"
                | "financial-aid"
                | "student-account"
                | "academics"
                | "student-service" = "admin-reports"
        ) => stringify("/reports", {tab})
    }
}

export {routing}
