import React from "react"
import moment from "moment"
import cx from "classnames"
import styles from "./Tasks.module.css"

export function TaskDueDate(props) {
    const {date} = props
    if (!date) return null
    const isLate = moment(date).isSameOrBefore(moment())

    return (
        <div className={styles.itemDesc}>
            <p className={cx(styles.datetime, {[styles.redDateTime]: isLate})}>{moment(date).format("MMM DD, YYYY")}</p>
        </div>
    )
}
