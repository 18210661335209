import {CloseOutlined, ConsoleSqlOutlined} from "@ant-design/icons"
import {useCallback, useEffect, useRef, useState} from "react"
import styles from "./TagSlider.module.css"
import {useAllMediaLibraryTags} from "hooks"
import {Tag} from "types/tags"
import cx from "classnames"

const SCROLL_AMOUNT = 300

type TagSliderProps = {
    selectedTags: Tag[]
    onSelectedTagsChange: (tags: Tag[]) => void
}

const TagSlider = ({selectedTags, onSelectedTagsChange}: TagSliderProps) => {
    const [maxScrollWidth, setMaxScrollWidth] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)
    const containerRef = useRef<HTMLDivElement>(null)

    const {tags} = useAllMediaLibraryTags()

    const isTagSelected = (tagId: number) => selectedTags.find((t) => tagId === t.tagId)

    const handleTagClick = (tag: Tag) => {
        const val = isTagSelected(tag.tagId)
            ? selectedTags?.filter((t) => t.tagId !== tag.tagId)
            : [...selectedTags, tag]
        onSelectedTagsChange(val)
    }

    useEffect(() => {
        if (containerRef.current) {
            setMaxScrollWidth(containerRef.current.scrollWidth - containerRef.current.offsetWidth)
        }
    }, [tags])

    const handlePrev = useCallback(() => {
        if (containerRef.current) {
            const val = containerRef.current.scrollLeft > 0 ? containerRef.current.scrollLeft - SCROLL_AMOUNT : 0
            containerRef.current.scrollLeft = val
            setScrollLeft(val)
        }
    }, [])

    const handleNext = useCallback(() => {
        if (containerRef.current) {
            const val =
                containerRef.current.scrollLeft < maxScrollWidth
                    ? containerRef.current.scrollLeft + SCROLL_AMOUNT
                    : maxScrollWidth
            containerRef.current.scrollLeft = val
            setScrollLeft(val)
        }
    }, [])

    return (
        <div className={styles.tagSlider}>
            {scrollLeft > 0 && (
                <button onClick={handlePrev} className={styles.controls}>
                    &lt;
                </button>
            )}
            <div className={styles.tagContainer} ref={containerRef}>
                {tags.map((tag) => (
                    <div
                        key={tag.tagId}
                        className={cx(styles.tag, {
                            [styles.selected]: isTagSelected(tag.tagId)
                        })}
                        onClick={() => handleTagClick(tag)}>
                        <span>
                            {tag.name} ({tag.itemsCount ?? 0})
                        </span>
                        {isTagSelected(tag.tagId) && <CloseOutlined className={styles.closeIcon} />}
                    </div>
                ))}
            </div>
            {scrollLeft < maxScrollWidth && (
                <button onClick={handleNext} className={styles.controls}>
                    &gt;
                </button>
            )}
        </div>
    )
}

export default TagSlider
