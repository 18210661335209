import cx from "classnames"
import React from "react"
import styles from "./TextPreloader.module.css"

export const TextPreloader = (props) => {
    const {size} = props
    const sizeClassName = size === "small" ? "bubblingGSmall" : ""
    return (
        <div className={cx(styles.bubblingG, styles[sizeClassName])}>
            <div className={`${styles.bubbling1} ${styles.bubbling}`} />
            <div className={`${styles.bubbling2} ${styles.bubbling}`} />
            <div className={`${styles.bubbling3} ${styles.bubbling}`} />
        </div>
    )
}
