/* eslint-disable react-hooks/exhaustive-deps */
import {useQuery} from "@tanstack/react-query"
import styles from "./DocumentStats.module.css"
import {documentHubService} from "services"
import {getEnableSubunitsByDepartmentPermission, handleError} from "helpers"
import {BaseLoading, Icon} from "components"
import cx from "classnames"
import {useAllDepartmentSubunits, useModel} from "hooks"
import {FieldOperator, FilterKey} from "types/filter"
import {useMemo} from "react"
import {PermissionType} from "types/permission"
import {get} from "lodash"

type Props = {
    userId?: number
    filterKey?: FilterKey
    onReloadFilter?: () => void
}

const LINK_LABELS = {
    task: "Task",
    checklist: "Checklist",
    activity: "Activities"
}

const LINK_ICONS = {
    task: "TASK_FILL",
    checklist: "CHECKMARK_CIRCLE",
    activity: "CHECKLIST"
}

export function DocumentStats(props: Props) {
    const {userId, filterKey, onReloadFilter} = props
    const model = useModel()
    const accessibleSubunitIds = useMemo(() => {
        return getEnableSubunitsByDepartmentPermission(PermissionType.View, model, "Documents")
    }, [])
    const {subunits} = useAllDepartmentSubunits()

    const statQuery = useQuery(
        ["document-stats", userId],
        () => documentHubService.getStatistics({userId, onlyVisibleForUser: model.isStudent()}),
        {
            initialData: {
                departments: [],
                links: []
            },
            onError: (err) => {
                handleError(err)
            }
        }
    )

    const onClickLink = (linkType: string) => {
        const filterItemKey =
            linkType === "task" ? "linkedTasks" : linkType === "activity" ? "linkedActivities" : "linkedChecklists"
        let filters: any = {}
        filters[filterItemKey] = {
            operator: FieldOperator.Equal,
            value: true
        }
        model.updateStorageFilter(filterKey, {
            filters
        })
        onReloadFilter?.()
    }

    const onClickDepartment = (departmentId: number) => {
        const subUnitInfos = get(model.departmentPermissions, [departmentId], {})
        const filteredArray = Object.keys(subUnitInfos)
            .map((x) => +x)
            .filter((value) => accessibleSubunitIds.includes(value))
        let filters: any = {}
        filters.departmentSubunit = {
            operator: FieldOperator.Equal,
            value: subunits.filter((x) => filteredArray.includes(x.subunitId))
        }
        model.updateStorageFilter(filterKey, {
            filters
        })
        onReloadFilter?.()
    }

    const {departments = [], links = []} = statQuery.data

    return (
        <div className={styles.container}>
            {departments.length > 0 && (
                <div className={styles.section}>
                    <span className={styles.header}>Departments</span>
                    <div className={styles.itemsWrap}>
                        {departments.map((department) => {
                            return (
                                <div
                                    key={department.departmentId}
                                    className={styles.item}
                                    onClick={() => onClickDepartment(department.departmentId)}>
                                    <div className={styles.itemHeader}>
                                        <div className={styles.headerLeft}>
                                            <div className={styles.iconWrap}>
                                                <Icon
                                                    icon="FILE_TEXT"
                                                    className={styles.icon}
                                                    color="var(--black-200)"
                                                />
                                            </div>
                                        </div>
                                        <span className={styles.value}>{department.total}</span>
                                    </div>
                                    <span className={styles.title}>{department.departmentName}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
            {links.length > 0 && (
                <div className={styles.section}>
                    <span className={styles.header}>Links</span>
                    <div className={styles.itemsWrap}>
                        {links.map((link) => {
                            return (
                                <div
                                    key={link.type}
                                    className={cx(styles.item, styles.linkItem)}
                                    onClick={() => onClickLink(link.type)}>
                                    <div className={styles.itemHeader}>
                                        <div className={styles.headerLeft}>
                                            <div className={cx(styles.iconWrap, styles.linkIconWrap)}>
                                                <Icon icon={LINK_ICONS[link.type]} className={styles.icon} />
                                            </div>
                                            <span className={styles.title}>{LINK_LABELS[link.type]}</span>
                                        </div>
                                        <span className={styles.value}>{link.total}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
            <BaseLoading isShow={statQuery.isLoading} />
        </div>
    )
}
