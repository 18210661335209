/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {studentBillingsService} from "services"
import {useModel} from "hooks"
import {Col, Row} from "antd"
import {routing} from "helpers/routing"
import {getFullName, handleError, toastError} from "helpers"
import {KlassappTableProps} from "types/common"
import {BaseButton} from "components"
import {StudentBilling, StudentBillingStatus} from "types/student-account/student-billing"
import styles from "./StudentBillings.module.css"
import {RateType} from "types/rate-sheets"

const INITIAL_FILTER = {}

interface Props extends KlassappTableProps {
    status: StudentBillingStatus[]
}

function StudentBillings({
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    tableHeaderActions,
    isLoading,
    menuActions,
    isHideMenuActions,
    isShowTableHeaderAction,
    onClickShowConfirmModal,
    getCurrentPage,
    getCurrentPageSize,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    status
}: Props) {
    const history = useHistory()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const {t} = useTranslation(["studentAccount", "common"])

    // region filter

    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState(INITIAL_FILTER)

    const onChangeFilter = (key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }

    const onClearFilter = () => {
        setFilter(INITIAL_FILTER)
    }

    const onApplyFilter = () => {
        getData()
    }

    const onSearchInput = (search = "") => {
        setSearch(search)
    }

    // endregion filter

    const getData = React.useCallback(async () => {
        dispatch({isLoading: true})
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {total, data: billings} = await studentBillingsService.getBillings({
            filter: {
                search,
                status
            },
            range: {
                pageSize,
                page
            },
            linkedEntities: true
        })
        dispatch({total, data: billings.map((item) => ({...item, id: item.billingId})), isLoading: false})
    }, [search, filter, status])

    const onClickRowItem = React.useCallback(({billingId}: StudentBilling) => {
        history.push(routing.studentAccount.staff.billing.detail(billingId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClickEdit = React.useCallback(
        (item) => {
            onClickRowItem(item)
        },
        [onClickRowItem]
    )

    const onClickDelete = React.useCallback(
        async (deletedItem: StudentBilling) => {
            if (deletedItem.isLock) {
                toastError(t("common:message.cantDeleteLockedItem"))
                return
            }
            try {
                dispatch({isLoading: true})
                await studentBillingsService.deleteBillings([deletedItem.billingId])
                await getData()
            } catch (e) {
                handleError(e)
            } finally {
                dispatch({isLoading: false})
            }
        },
        [getData]
    )

    const getPageTitle = () => {
        return t("billings.title")
    }

    const getFields = React.useCallback(() => {
        const fields = [
            t("billings.id"),
            t("billings.student"),
            t("billings.awardYear"),
            t("billings.term"),
            t("billings.startDate"),
            t("billings.packagedHours"),
            t("billings.registeredHours"),
            t("billings.registeredCourses")
        ]
        if (status.includes(StudentBillingStatus.Completed)) {
            fields.push(t("billings.date"))
        } else {
            fields.push(t("billings.tuitionRate"))
        }
        return fields
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status])

    const getColumns = React.useCallback(
        () => [
            {
                title: t("billings.id"),
                field: "billingId"
            },
            {
                title: t("billings.student"),
                field: "profile",
                render: (profile) => getFullName(profile)
            },
            {
                title: t("billings.awardYear"),
                field: "awardYear",
                render: (year) => `${year} - ${year + 1}`
            },
            {
                title: t("billings.term"),
                field: "term",
                render: (term) => term?.name
            },
            {
                title: t("billings.startDate"),
                field: "term.startDate",
                render: (date) => (date ? moment(date).format(userDateFormat) : "")
            },
            {
                title: t("billings.packagedHours"),
                titleHtml: (
                    <div>
                        <div>{t("billings.packaged")}</div>
                        <div className={styles.subheader}>{t("billings.hours")}</div>
                    </div>
                ),
                field: "packaged"
            },
            {
                title: t("billings.registeredHours"),
                titleHtml: (
                    <div>
                        <div>{t("billings.registered")}</div>
                        <div className={styles.subheader}>{t("billings.hours")}</div>
                    </div>
                ),
                field: "registered"
            },
            {
                title: t("billings.registeredCourses"),
                titleHtml: (
                    <div>
                        <div>{t("billings.registered")}</div>
                        <div className={styles.subheader}>{t("billings.courses")}</div>
                    </div>
                ),
                field: "registeredCourses"
            },
            {
                title: t("billings.tuitionRate"),
                field: "billingId",
                render: (_, record: StudentBilling) =>
                    record.items?.find((item) => item.itemType === RateType.Program)?.itemName
            },
            {
                title: t("billings.description"),
                field: "description"
            },
            {
                title: t("billings.date"),
                field: "completedAt",
                render: (date) => (date ? moment(date).format(userDateFormat) : "")
            }
        ],
        [t, userDateFormat]
    )

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            const hasLockedItem = (checkedData || []).some((item) => item.isLock)
            const hasCompletedItem = (checkedData || []).some((item) => item.status === StudentBillingStatus.Completed)
            const actions = []
            if (checkedData?.length === 1 && checkedData[0].status !== StudentBillingStatus.Completed) {
                actions.push({
                    title: t("common:action.edit"),
                    icon: "EDIT",
                    action: () => onClickEdit(checkedData[0])
                })
            }
            if (!hasLockedItem && !hasCompletedItem) {
                actions.push({
                    title: t("common:action.delete"),
                    icon: "DELETE",
                    action: () => onClickShowConfirmModal("DELETE")
                })
            }
            return actions
        },
        [t, onClickShowConfirmModal, onClickEdit]
    )

    const getMenuActions = React.useCallback(() => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit,
                canShow: (item: StudentBilling) => item.status !== StudentBillingStatus.Completed
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete,
                canShow: (item: StudentBilling) => !item.isLock && item.status !== StudentBillingStatus.Completed
            }
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        dispatchFunc([{key: "getColumns", func: getColumns}])
    }, [getColumns])

    useEffect(() => {
        dispatchFunc([{key: "getFields", func: getFields}])
    }, [getFields])

    useEffect(() => {
        getData()
    }, [search, page, pageSize])

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                renderRightFilter={() => (
                    <div className="flex justify-end items-center">
                        <BaseButton variant="secondary" title={t("common:action.export")} />
                        {!status.includes(StudentBillingStatus.Completed) && (
                            <BaseButton
                                title={t("common:action.add")}
                                className="ml-24"
                                onClick={() => {
                                    history.push(routing.studentAccount.staff.billing.detail("new"))
                                }}
                            />
                        )}
                    </div>
                )}
                onSearchInput={onSearchInput}>
                <Row gutter={[24, 24]}>
                    <Col span={12}></Col>
                </Row>
            </BaseNewFilter>

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
            />
        </div>
    )
}

export default KlassappTableHOC(StudentBillings)
