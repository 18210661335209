/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {KlassappTableHeader, KlassappTable, BaseNewFilter} from "uiKit"
import {Icon} from "components/Icon"
import {KlassappTableHOC} from "HOC"
import {instructionalService} from "services"
import styles from "./MyCourses.module.css"
import {useModel} from "hooks"
import {AcademicPlans} from "types/academicPlans"

import {formatDateOnly, roundTwoDigits} from "helpers"

function MyCourses(props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        isLoading,
        isLoadedTableFuncs,
        onClickCourseId,
        studentId,
        termIds
    } = props
    const [searchValue, setSearchValue] = React.useState("")
    const {t} = useTranslation(["course"])
    const model = useModel()

    const getFields = () => {
        return [
            t("course.code"),
            t("course.name"),
            "Schedule Name",
            "Sisid",
            "Grade (GPA)",
            "Status",
            t("course.lastLesson"),
            t("course.attendance"),
            "Instructors"
        ]
    }

    const getPageTitle = () => {
        return "Course"
    }

    const getColumns = () => {
        return [
            {
                title: t("course.code"),
                field: "courseCode"
            },
            {
                title: t("course.name"),
                field: "courseName",
                style: {width: "250px"}
            },
            {
                title: "Schedule Name",
                field: "scheduleName",
                style: {width: "250px"}
            },
            {
                title: "Sisid",
                field: "sisId"
            },
            {
                title: "Grade (GPA)",
                field: "gpa"
            },
            {
                title: "Status",
                field: "status"
            },
            {
                title: t("course.lastLesson"),
                field: "lastLesson"
            },
            {
                title: "Instructors",
                field: "instructors"
            },
            {
                title: t("course.attendance"),
                field: "attendancePercent"
            },
            {
                title: t("course.action"),
                field: "actionHtml"
            }
        ]
    }

    const checkStatus = (data: {
        status: AcademicPlans.AcademicPlanCourseStatus
        reviewStatus: AcademicPlans.AcademicPlanCourseReviewStatus
    }) => {
        if (data.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Dropped) {
            return "Withdrawn"
        } else if (data.status === AcademicPlans.AcademicPlanCourseStatus.Suspended) {
            return "Suspended"
        } else if (
            data.status === AcademicPlans.AcademicPlanCourseStatus.Finished ||
            data.status === AcademicPlans.AcademicPlanCourseStatus.Completed
        ) {
            return "Completed"
        } else if (data.status === AcademicPlans.AcademicPlanCourseStatus.Failed) {
            return "Failed"
        } else if (data.status === AcademicPlans.AcademicPlanCourseStatus.Inprogress) {
            return "In-progress"
        } else if (data.status === AcademicPlans.AcademicPlanCourseStatus.Incomplete) {
            return "Incomplete"
        } else if (data.status === AcademicPlans.AcademicPlanCourseStatus.LoaIncomplete) {
            return "Loa-Incomplete"
        } else {
            return ""
        }
    }

    const getCourses = async (search = "") => {
        dispatch({isLoading: true})
        try {
            const params: any = {
                range: {page, pageSize},
                filter: {}
            }
            params.filter.termIds = termIds
            if (searchValue && searchValue !== "") {
                params.filter.search = searchValue
            }
            const profileId = model.profileId
            if (studentId) {
                params.filter.studentProfileIds = [studentId]
            } else if (!model.isStaffOrAdmin() && profileId) {
                params.filter.studentProfileIds = [profileId]
            }
            const {data, total} = await instructionalService.getTermAndCourseList(params)
            const myCourses = data.map((item: any, index: number) => {
                item.id = index + 1
                item.termName = item.name
                item.status = checkStatus(item)
                item.lastLesson = formatDateOnly(item?.attendance?.date, model.getUserDateFormat())
                item.attendancePercent = item.attendance ? `${roundTwoDigits(item.attendance?.percentage)}%` : ""
                item.actionHtml = renderActionHtml(item)
                return item
            })
            dispatch({data: myCourses, total})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const renderActionHtml = (course) => {
        if (course.attendance?.actionNeededIds?.length > 0) {
            return <Icon icon="ALERT" />
        }
        return null
    }

    const onClickRowItem = (row) => {
        onClickCourseId(row.courseId, row.registeredScheduleId)
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getCourses},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getCourses()
        }
    }, [isLoadedTableFuncs, page, pageSize, searchValue, studentId, termIds])

    const onApplyFilter = () => {
        //
    }

    const onClickClearFilter = () => {
        //
    }

    const onSearchInput = (newValue) => {
        setSearchValue(newValue)
    }

    return (
        <>
            <p className={styles.title}>Courses</p>
            <BaseNewFilter
                searchValue={searchValue}
                className={styles.filterWrap}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}
            />
            <KlassappTableHeader
                isShowAction={false}
                actions={[]}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </>
    )
}

export default KlassappTableHOC(MyCourses)
