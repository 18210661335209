import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {useAllActivities, useAllDepartments} from "hooks"

type Props = Partial<KlassDropdownAsyncProps> & {
    departmentId?: number
}

const DepartmentActivitySelect: React.FC<Props> = ({departmentId, ...props}) => {
    const {t} = useTranslation(["common"])
    const {departments} = useAllDepartments()
    const {activities} = useAllActivities()

    const options = useMemo(() => {
        if (!departmentId) {
            return []
        }
        const moduleDepartment = departments.find((dept) => dept.departmentId === departmentId)
        const moduleDepartmentId = moduleDepartment?.useActivitiesFromDepartmentId
        return activities.filter((activity) => {
            if (!activity.isActive) return false
            if (moduleDepartmentId) {
                if (activity.departmentId !== moduleDepartmentId) return false
            } else {
                if (activity.departmentId !== departmentId) return false
            }
            return true
        })
    }, [departmentId, departments, activities])

    return (
        <KlassDropdown placeholder={props.placeholder || t("selectField.placeholder")} options={options} {...props} />
    )
}

export default DepartmentActivitySelect
