import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {formatCodeName} from "helpers"
import {pick} from "lodash"
import {useAllDegreeLevels} from "hooks"

type Props = Partial<KlassDropdownAsyncProps> & {}

const DegreeLevelSelect: React.FC<Props> = ({...props}) => {
    const {t} = useTranslation(["common"])
    const {degreeLevels} = useAllDegreeLevels()

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={degreeLevels}
            valueKey="degreeLevelId"
            labelKey="name"
            getOptionLabel={formatCodeName}
        />
    )
}

export default DegreeLevelSelect
