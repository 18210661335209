import React from "react"
import {KlassappTableHeader, KlassappTable} from "uiKit"

export function ViewListMediaLibrary(props) {
    const {
        data,
        isLoading,
        total,
        page,
        pageSize,
        columns,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isShowTableHeaderAction,
        newMenuActions,
        setSelectedItem
    } = props

    return (
        <>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={newMenuActions}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                onClickRowItem={(item) => setSelectedItem(item)}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </>
    )
}
