/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownProps} from "../Select"
import {Auth} from "types/auth"
import styles from "./UserSelectV3.module.css"
import {getFullName} from "helpers"
import {useAutocompleteUsers} from "hooks"
import debounce from "lodash/debounce"
import {InputActionMeta} from "react-select"

type Props = Partial<KlassDropdownProps> & {
    type?: Auth.UserProfileType[]
    withProfiles?: boolean
    belongsToMyCampuses?: boolean
}

const UserSelectV3: React.FC<Props> = ({type, withProfiles = true, belongsToMyCampuses = true, ...props}) => {
    const {t} = useTranslation(["common"])
    const [inputText, setInputText] = useState<string>("")
    const [searchText, setSearchText] = useState<string>("")

    const handleSearchDebounced = useRef(debounce((searchText) => setSearchText(searchText), 300)).current

    const handleInputChange = (inputText: string, meta: InputActionMeta) => {
        if (meta.action !== "input-blur" && meta.action !== "menu-close") {
            setInputText(inputText)
            // not trigger searching when clear text value
            if (!!inputText) handleSearchDebounced(inputText)
        }
    }

    const {isLoading, users} = useAutocompleteUsers({type, withProfiles, belongsToMyCampuses, search: searchText})

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            defaultOptions
            valueKey="id"
            labelKey="name"
            getOptionLabel={UserOptionLabel}
            inputValue={inputText}
            onInputChange={handleInputChange}
            options={users}
            isLoading={isLoading}
        />
    )
}

export default UserSelectV3

const UserOptionLabel = (option) => {
    return (
        <span>
            <span className={styles.userId}>{option.customUserId}</span>
            {getFullName(option) || option.name}
        </span>
    )
}
