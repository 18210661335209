import {get} from "lodash"
import {BaseService} from "services"

export default class ExternshipsService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_EXTERNSHIP_API_BASE_URL)
    }

    async studentGetAllDocs(studentId, body = {}) {
        const response = await this.post(`/docs/${studentId}/student`, body)
        return get(response, "data", [])
    }

    async staffGetAllStudents(staffId, body = {}) {
        const response = await this.post(`/users/student/list/externship/staff/${staffId}`, body)
        return get(response, "data", [])
    }

    async externshipSiteGetAll(body = {}) {
        const response = await this.post("/users/externship-site/list", body)
        return get(response, "data", [])
    }

    async weeksGetAll(body = {}) {
        const response = await this.post("/users/week/list", body)
        return get(response, "data", [])
    }

    async externshipCoordinatorGetAll(body = {}) {
        const response = await this.post("/users/get-all-staff", body)
        return get(response, "data", [])
    }
    async generateSessionToken(body = {}) {
        const response = await this.post("/generate-session-token", body)
        return get(response, "data", [])
    }

    async docsAdd(body = {}) {
        const response = await this.post("docs/add", body)
        return get(response, "data", [])
    }
}
