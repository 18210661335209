import {useQuery} from "@tanstack/react-query"
import {handleError} from "helpers"
import {academicGradingSystem} from "services"

type UseGradingTemplateItemsParams = {
    gradingTemplateId: number
}
type GradingItem = {
    id: number
    gradingItem: string
    colorLabel: string
}
export const useGradingTemplateItems = ({gradingTemplateId}: UseGradingTemplateItemsParams) => {
    const gradingTemplateItems = useQuery<GradingItem[]>(
        ["grading-template-items", gradingTemplateId],
        async () => {
            const {data} = await academicGradingSystem.getDetailGradingSystem(gradingTemplateId)
            const {gradingElements = []} = data || {}
            const gradingItems = gradingElements.map((item) => ({
                id: item.id,
                gradingItem: item.gradingItem,
                colorLabel: item.colorLabel
            }))
            return gradingItems
        },
        {
            onError: (err) => {
                handleError(err)
            },
            enabled: !!gradingTemplateId,
            initialData: []
        }
    )
    return {
        data: gradingTemplateItems.data,
        isLoading: gradingTemplateItems.isFetching
    }
}
