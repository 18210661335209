import React, {useEffect, useState} from "react"
import {BasePopup} from "components/popup"
import styles from "./ActivityGroupForm.module.css"
import {BaseButton, BaseInput} from "components"
import {handleError, toastError} from "helpers"
import {FormLabel} from "components/Form"
import DepartmentActivitySelect from "components/DepartmentActivitySelect"
import {ActivityGroup, CreateActivityGroup} from "types/activity-groups"
import {settingActivityGroupService} from "services"
import ActivitiesSelect from "components/ActivitiesSelect"

type Props = {
    departmentId: number
    item?: ActivityGroup
    maxRank: number
    open: boolean
    onClose: () => void
    onSuccess?: () => void
}

export function ActivityGroupForm(props: Props) {
    const {departmentId, item, maxRank, open, onClose, onSuccess} = props
    const [activityGroup, setActivityGroup] = useState<CreateActivityGroup>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const isEdit = !!item
    const [errorFields, setErrorFields] = useState<string[]>([])

    useEffect(() => {
        setActivityGroup(item ?? {})
    }, [item])

    const validateBeforeSubmit = () => {
        const {activities, name} = activityGroup
        const _errors: string[] = []
        if (!name || name.trim() === "") {
            _errors.push("name")
        }
        if (!activities || activities.length <= 0) {
            _errors.push("activities")
        }
        setErrorFields(_errors)
        return _errors.length > 0
    }

    const onSubmit = async () => {
        if (validateBeforeSubmit()) {
            toastError("Please check required fields before submit")
            return
        }
        try {
            setIsSubmitting(true)
            let payload: CreateActivityGroup = {
                name: activityGroup?.name,
                activityIds: (activityGroup?.activities || []).map((x) => x.activityId)
            }
            if (isEdit) {
                await settingActivityGroupService.update({
                    ...payload,
                    id: activityGroup?.id
                })
            } else {
                await settingActivityGroupService.create({
                    ...payload,
                    departmentId
                })
            }
            onSuccess?.()
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onChangeData = (key: keyof ActivityGroup, value: any) => {
        setActivityGroup((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <BasePopup
            isShow={open}
            onClose={onClose}
            leftIcon={isEdit ? "PENCIL" : "PLUS_CIRCLE"}
            leftIconColor="#fff"
            width="40vw"
            closable
            destroyOnClose>
            <div className={styles.headingTitle}>{isEdit ? "Edit Activity Group" : "Add Activity Group"}</div>
            <div className={styles.field}>
                <FormLabel label="Priority" />
                <BaseInput type="text" value={isEdit ? item.priorityRank : maxRank} disabled />
            </div>
            <div className={styles.field}>
                <FormLabel label="Name" isRequired />
                <BaseInput
                    type="text"
                    value={activityGroup?.name}
                    onChange={(v) => onChangeData("name", v)}
                    error={errorFields.includes("name")}
                />
            </div>
            <div className={styles.field}>
                <FormLabel label="Activities" isRequired />
                <ActivitiesSelect
                    departmentId={departmentId}
                    isMulti
                    placeholder="Select Activities"
                    value={activityGroup?.activities}
                    onChange={(v) => onChangeData("activities", v)}
                    error={errorFields.includes("activities")}
                    filterGroup
                    excludeActivityGroupId={item?.id}
                />
            </div>
            <div className={styles.doneBtn}>
                <BaseButton title="Submit" onClick={onSubmit} className={styles.saveBtn} loading={isSubmitting} />
            </div>
        </BasePopup>
    )
}
