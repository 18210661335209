import {IconType} from "components/Icon"
import {WorkDay} from "./work-schedule"

export type CalendarDetails = {
    calendarId: number
    ownerUserId: number
    name: string
    description: string
    createdAt: string
}

export type CalendarEvent = {
    objectId: number
    groupId: number
    calendarId: number
    type: CalendarEventType
    name: string
    description: string
    ownerUserId: number
    createdAt: string
    startAtUtc: string
    endAtUtc: string
    startAtWall: string
    startAtTimezone: string
    duration: number
    rrule: string
    jsonData: {
        [key in string]: string
    }
    started_at?: string | null
}

export type Event = {
    classNames: string
    color: string
    description: string
    end: string
    id: number
    initialEventIndex: number
    isAppointmentSlot: boolean
    order: number
    ownerUserId: number
    start: string
    endDateTime: any
    startDateTime: any
} & {[key in string]: any}

export type CalendarEventOption = {
    title: string
    icon: IconType
    action?: Function
    iconClassName?: string
    canShow?: (event: any) => void
}

export type CalendarProps = {
    calendarRef: any
    events: Event[]
    isLoading: boolean
    viewOptions?: any[]
    workDays?: WorkDay[]
    holidayDays?: any
    onDateClick: (date: any) => void
    onChangeDatesSet: (date: any) => void
    eventOptions: CalendarEventOption[]
} & {[key in string]: any}

export type CreateEventPayload = {
    calendar_id: number
    name: string
    description?: string
    duration: number
    json_data: {
        eventType?: string
        eventTypeId?: number
        color: string
        allDay?: boolean
        notifications?: number[]
        with_sms?: boolean
        with_email?: boolean
        with_push_notification?: boolean
        eventSignInRequired?: boolean
        attendedChangeToStatus?: any
        notAttendedChangeToStatus?: any
        completionWorkflow?: any
        daysOfCourse?: string
        term_id?: number
        course_id?: number
        schedule_id?: number
        sub_module_id?: number
        location?: any
        resources?: any
    }
    invited_user_ids?: number[]
    owner_user_id: number
    rrule: string
    type: string
    start_at_timezone: string
    start_at_wall: string
    edular_group_id?: number
    host_user_ids?: number[]
}

export enum CalendarRecurringType {
    Never = "NEVER",
    Yearly = "YEARLY",
    Monthly = "MONTHLY",
    Weekly = "WEEKLY",
    Daily = "DAILY"
}

export enum Months {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}

export enum Day {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
}

export enum CalendarEventType {
    Event = "event",
    CourseSchedule = "course_schedule",
    StartTermDate = "start_term_date",
    EndTermDate = "end_term_date",
    AppointmentSlot = "appointment_slot",
    WorkSchedule = "work_schedule"
}

export enum RSVP {
    Yes = "yes",
    Maybe = "maybe",
    No = "no",
    NOT_SELECTED = "not_selected"
}

export interface EventType {
    eventTypeId: number
    code: string
    name: string
    label: string
    updatedAt: string
}

export enum RescheduleSelection {
    Class = "class",
    Calendar = "calendar"
}

export enum RecurringPopupType {
    Delete = "Delete",
    Update = "Update"
}

export enum TargetRecurringEvent {
    OnlyThis = "only_this",
    ThisAndFurther = "this_and_further",
    All = "all"
}

export enum RecurringEnd {
    Never = 0,
    AfterExecution = 1,
    OnDate = 2
}

export type EventInvitee = {
    invite_id: number
    object_id: number
    created_at: string
    invited_user_id: number
    will_go: RSVP
    rsvp: boolean
    check_in?: string | null
    is_check_in: boolean
    json_data: {}
}
