import React from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {TaskStatus} from "types/tasks"
import styles from "./Status.module.css"

type Props = {
    status: TaskStatus
    doneWithRejected?: boolean
}

export function Status(props: Props) {
    const {status, doneWithRejected} = props
    const {t} = useTranslation(["tasks"])

    return (
        <div
            className={cx(styles.statusWrap, {
                [styles.status__todo]: status === TaskStatus.TODO,
                [styles.status__started]: status === TaskStatus.STARTED,
                [styles.status__done]: status === TaskStatus.DONE && !doneWithRejected,
                [styles.status__done_with_rejected]: status === TaskStatus.DONE && doneWithRejected,
                [styles.status__awaiting]: status === TaskStatus.AWAITING_APPROVAL,
                [styles.status__rejected]: status === TaskStatus.REJECTED,
                [styles.status__overdue]: status === TaskStatus.OVERDUE,
                [styles.status__draft]: status === TaskStatus.DRAFT
            })}>
            <span className={styles.statusText}>{status ? t(`tasks.${status}`) : ""}</span>
        </div>
    )
}
