import {useModel} from "hooks"

export const useLabels = () => {
    const model = useModel()

    const isLabelActive = (labelId: string) => {
        const label = model.fieldLabels[labelId]

        return label?.isActive ?? false
    }

    return {
        isActive: isLabelActive
    }
}
