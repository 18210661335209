import React from "react"
import {BasePopup} from "../BasePopup"
import {BaseButton, SecondaryButton} from "components/buttons/Button"
import styles from "./ConfirmPopupChildren.module.css"
import cx from "classnames"
import {IconType} from "components"

type ConfirmPopupChildrenProps = {
    title: string
    isVisible?: boolean
    children: any
    onClose: () => void
    onConfirm: () => void
    isSubmitting?: boolean
    alignFooterButtons?: "left" | "center" | "right" | "expanded"
    width?: string
    icon?: IconType
}

export const ConfirmPopupChildren = ({
    title,
    isVisible = true,
    children,
    onClose,
    onConfirm,
    isSubmitting = false,
    alignFooterButtons = "expanded",
    width = "400px",
    icon = "WARNING_SOLID"
}: ConfirmPopupChildrenProps) => (
    <BasePopup isShow={isVisible} onClose={onClose} leftIcon={icon} leftIconColor="#fff" width={width}>
        <div className={styles.root}>
            <div className={styles.title}>{title}</div>
            {children}
            <div className={styles.footer}>
                <div
                    className={cx(styles.buttons, {
                        [styles.expandedButtons]: alignFooterButtons === "expanded",
                        [styles.leftButtons]: alignFooterButtons === "left",
                        [styles.centerButtons]: alignFooterButtons === "center",
                        [styles.rightButtons]: alignFooterButtons === "right"
                    })}>
                    <div className={styles.button}>
                        <SecondaryButton onClick={onClose} title="CANCEL" disabled={isSubmitting} />
                    </div>
                    <div className={styles.button}>
                        <BaseButton
                            onClick={onConfirm}
                            title="CONFIRM"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        </div>
    </BasePopup>
)
