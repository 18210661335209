import {get} from "lodash"
import BaseService from "./Base"
import {EditStudentGradingTemplatePayload} from "types/students"

class AcademicCourseGradesService extends BaseService {
    async getCourseGradeStudents(data) {
        const response = await this.post("/v1/instructional/course-grades/list", data)
        return get(response, "data.data", [])
    }
    async getCourseGradeStudentsByCourse(data) {
        const response = await this.post("/v1/instructional/course-grades/list-by-course", data)
        return get(response, "data.data", [])
    }
    async getCourseGradeStudentStats(data: {courseId?: number; studentProfileId: number; termId?: number}) {
        const response = await this.post("/v1/instructional/course-grades/student/stats", data)
        return get(response, "data.data", [])
    }
    async addManualGrade(data) {
        const response = await this.post("/v1/instructional/course-grades/add-manual-grade", data)
        return get(response, "data.data", {})
    }
    async editStudentGradingTemplate(payload: EditStudentGradingTemplatePayload) {
        const response = await this.post("/v1/instructional/student-grading-template/edit", payload)
        return get(response, "data.data", {})
    }
    async updateGpa(data: {
        listGrades: Array<{
            academicStudentStatsId?: number
            academicTermStatsId?: number
            courseId?: number
            studentProfileId: number
            termId: number
            scheduleId?: number
            gpa: number | null
        }>
    }) {
        const response = await this.post("/v1/instructional/course-grades/update-gpa", data)
        return get(response, "data.data", {})
    }
    async getClassActivities(data) {
        const response = await this.post("/v3/academic/activities/list", data)
        return get(response, "data", {})
    }
    async getLessonCourseGradeStudents(data) {
        const response = await this.post("/v1/instructional/course-grades/lesson-grade-table", data)
        return get(response, "data", {})
    }
    async editLessonCourseGrade(data) {
        const response = await this.post("/v1/instructional/course-grades/lesson-grade-table/edit", data)
        return get(response, "data.data", {})
    }
    async deleteLessonCourseGrade(data) {
        const response = await this.post("/v1/instructional/course-grades/lesson-grade-table/delete", data)
        return get(response, "data.data", {})
    }
    async getLessonCourseGradeStudentsDetail(data) {
        const response = await this.post("/v1/instructional/course-grades/lesson-grade-table/detail", data)
        return get(response, "data", {})
    }
    async getGradeStats(data) {
        const response = await this.post("/v1/instructional/course-grades/grade/stats", data)
        return get(response, "data", {})
    }
    async addCourseGradeStudents(data) {
        const response = await this.post("/v1/instructional/course-grades/edit", data)
        return get(response, "data.data", [])
    }
    async getListStudent(data) {
        const response = await this.post("/v1/instructional/course-grades/student/list", data)
        return get(response, "data", {})
    }
    async updateCourseGradeStudents(data) {
        const response = await this.post("/v1/instructional/course-grades/update", data)
        return get(response, "data.data", [])
    }
}

export default AcademicCourseGradesService
