import React from "react"
import styles from "./StudentProgress.module.css"
import progressStyles from "./TotalProgress.module.css"
import cx from "classnames"
import {useModel} from "hooks"
import {formatDateOnly, getFullName, isFieldActive} from "helpers"
import {User} from "types/user"

import {Settings} from "types/settings"

type Props = {
    percent: number
    title?: string
    activeSchedule?: Partial<{
        firstDayOfCourse: string
        lastDayOfCourse: string
        instructors: Array<User>
        instructor: string
    }>
    termInfo?: Partial<{
        startDate: string
        endDate: string
        name: string
    }>
}

export function TotalProgress(props: Props) {
    const {percent, title, activeSchedule, termInfo} = props
    const model = useModel()
    const percentNumber = (percent || 0) <= 100 ? percent : 100
    const showFieldActive = isFieldActive(model, Settings.AcademicLabel.TotalProgress)
    return (
        <div className={styles.progressWrap}>
            {showFieldActive && (
                <>
                    <p className={styles.progressTitle}>{title ?? "Total Progress"}</p>
                    <div className={styles.progressBarWrap}>
                        <div className={styles.progressBarValue} style={{width: `${percentNumber}%`}}>
                            <p className={styles.progressBarNumber}>{percent || 0}%</p>
                        </div>
                    </div>
                </>
            )}

            <div className={progressStyles.hourItemWrap}>
                {activeSchedule || termInfo ? (
                    <div className={cx(progressStyles.dateContainer)}>
                        <div className={cx(progressStyles.hourItem, progressStyles.hourItemLeft)}>
                            <div className={progressStyles.hourTitleWrap}>
                                <p className={progressStyles.hourTitle}>First day:</p>
                            </div>
                            <p className={progressStyles.hourDescription}>
                                {formatDateOnly(
                                    activeSchedule?.firstDayOfCourse || termInfo?.startDate,
                                    model.dateFormat
                                )}
                            </p>
                        </div>
                        <div className={cx(progressStyles.hourItem)}>
                            <div className={progressStyles.hourTitleWrap}>
                                <p className={progressStyles.hourTitle}>Term:</p>
                            </div>
                            <p className={progressStyles.hourDescription}>{termInfo?.name}</p>
                        </div>
                    </div>
                ) : null}
                {activeSchedule || termInfo ? (
                    <div className={cx(progressStyles.dateContainer)}>
                        <div className={cx(progressStyles.hourItem, progressStyles.hourItemLeft)}>
                            <div className={progressStyles.hourTitleWrap}>
                                <p className={progressStyles.hourTitle}>Last day:</p>
                            </div>
                            <p className={progressStyles.hourDescription}>
                                {formatDateOnly(activeSchedule?.lastDayOfCourse || termInfo?.endDate, model.dateFormat)}
                            </p>
                        </div>
                        <div className={progressStyles.hourItem}>
                            {activeSchedule?.instructors?.length || activeSchedule?.instructor ? (
                                <>
                                    <div className={progressStyles.hourTitleWrap}>
                                        <p className={progressStyles.hourTitle}>Instructors:</p>
                                    </div>
                                    <p className={progressStyles.hourDescription} style={{fontSize: 12}}>
                                        {activeSchedule.instructor ??
                                            activeSchedule?.instructors.map((user) => getFullName(user)).join(",")}
                                    </p>
                                </>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}
