import React, {useRef, useState, useEffect} from "react"
import {Input, Tooltip} from "antd"
import cx from "classnames"
import styles from "./BaseInput.module.css"
import {Icon} from "components/Icon"
import {useTranslation} from "react-i18next"
import {preventMinusCharacter} from "helpers/inputHelper"

let timeout = null

type InputComponentsProps = {[key in string]: any}

export type BaseInputProps = InputComponentsProps & {
    title?: string
    value?: string | number
    defaultValue?: string | number
    className?: string
    placeholder?: string
    error?: boolean
    copy?: boolean
    hide?: boolean
    mask?: (str: string) => string
    icon?: JSX.Element
    borderless?: boolean
    readOnly?: boolean
    disabled?: boolean
    onKeyDown?: any
    onChange?: any
    prefix?: JSX.Element
    suffix?: JSX.Element
    min?: number | string
    max?: number | string
    maxLength?: number
    disabledArrow?: boolean
    type?:
        | "button"
        | "checkbox"
        | "color"
        | "date"
        | "datetime-local"
        | "email"
        | "file"
        | "hidden"
        | "image"
        | "month"
        | "number"
        | "password"
        | "radio"
        | "range"
        | "reset"
        | "search"
        | "submit"
        | "tel"
        | "text"
        | "time"
        | "url"
        | "week"
        | "credit-card"
}

export function BaseInput(props: BaseInputProps) {
    const inputRef = useRef(null)
    const {
        title,
        onChange,
        className,
        error,
        copy,
        borderless,
        onKeyDown,
        hide,
        disabledArrow,
        mask,
        icon,
        type,
        ...rest
    } = props
    const [textCopy, setTextCopy] = useState("Copy")
    const [textType, setTextType] = useState(type === "number" ? "text" : type)
    const isShowPassword = textType === "password"

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    // Remove non-numeric characters and retain '.' or '-'
    const getNumberInputValue = (inputValue: string): string => {
        inputValue = inputValue.replace(/[^0-9.-]/g, "")
        // Ensure only one '.' is allowed
        const dotIndex = inputValue.indexOf(".")
        if (dotIndex !== -1) {
            inputValue = inputValue.slice(0, dotIndex + 1) + inputValue.slice(dotIndex + 1).replace(/\./g, "")
        }
        // Ensure '-' is allowed only at the start
        const dashIndex = inputValue.indexOf("-")
        if (dashIndex > 0) {
            inputValue = inputValue.replace(/-/g, "")
        }
        return inputValue
    }

    const onChangeValue = React.useCallback(
        (e) => {
            let inputValue = e.target.value
            if (type === "number") {
                inputValue = getNumberInputValue(inputValue)
            }
            const text = mask ? mask(inputValue) : inputValue
            onChange?.(text)
        },
        [type, onChange, mask]
    )

    const copyText = React.useCallback(() => {
        inputRef.current.select()
        document.execCommand("copy")
        setTextCopy("Copied to clipboard")
        inputRef.current.focus()
    }, [])

    const onVisibleChange = React.useCallback((visible) => {
        if (!visible) {
            timeout = setTimeout(() => {
                setTextCopy("Copy")
            }, 100)
        }
    }, [])

    const onHideChange = React.useCallback(() => {
        setTextType(textType === "password" ? "text" : "password")
    }, [textType])

    const onkeyDownValue = React.useCallback((event) => onKeyDown && onKeyDown(event), [onKeyDown])

    const renderSuffixIcon = () => {
        if (icon) {
            return (
                <span className={styles.suffixIconWrap} onClick={copyText}>
                    {icon}
                </span>
            )
        }
        if (copy) {
            return (
                <Tooltip title={textCopy} onVisibleChange={onVisibleChange}>
                    <span className={styles.suffixIconWrap} onClick={copyText}>
                        <Icon icon="COPY" className={styles.suffixIcon} color="rgba(0,0,0,.45)" />
                    </span>
                </Tooltip>
            )
        }
        if (hide) {
            return (
                <span className={styles.suffixIconWrap} onClick={onHideChange}>
                    <Icon
                        icon={isShowPassword ? "EYE_CROSSED_LINE" : "EYE"}
                        className={styles.suffixIcon}
                        color={isShowPassword ? "#ccc" : "#1e90ff"}
                    />
                </span>
            )
        }
        return null
    }

    return (
        <Input
            ref={inputRef}
            className={cx(styles.baseInput, className, {
                error: error,
                [styles.borderless]: borderless,
                [styles.hideText]: textType === "password" && hide,
                [styles.disabledArrow]: !!disabledArrow
            })}
            type={textType}
            onWheel={disabledArrow ? () => inputRef.current?.blur() : undefined}
            onChange={onChangeValue}
            onKeyDown={(event) => {
                props.type === "number" && Number(props.min) === 0
                    ? onkeyDownValue(preventMinusCharacter(event))
                    : onkeyDownValue(event)
            }}
            suffix={renderSuffixIcon()}
            {...rest}
        />
    )
}
