import React from "react"
import {Checkbox, Divider, Tooltip} from "antd"
import {cloneDeep, isEmpty} from "lodash"
import {TFunction, withTranslation} from "react-i18next"
import debounce from "debounce-promise"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {Search} from "components/inputs/Search"
import {BaseButton} from "components/buttons"
import {courseService, academicPlansService} from "services"
import {handleError, toastError} from "helpers"
import {Course} from "types/courses"
import styles from "./CoursesTable.module.css"
import {Icon} from "components"

type PageProps = {
    t: TFunction
    sectionId?: number
    unit?: Course.Unit
    uniqCourse?: boolean
    selectedCourses?: any[]
    onAddCourses: (addedCourses: Course.CourseTableItem[]) => void
}

type CourseTable = Course.CourseTableItem & {isChecked: true}

type Props = KlassappTableProps<CourseTable> & PageProps

type State = {
    searchValue: string
}

class CoursesTable extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: ""
        }
        this.onSearchChange = debounce(this.onSearchChange.bind(this), 300)
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.getData()
    }

    getFields = () => {
        const {t} = this.props
        return [t("course.code"), t("course.name"), t("course.instructor"), "Class Assistance"]
    }

    renderIsCA = (isCA: boolean) => {
        const {getCurrentData, dispatch} = this.props
        return (
            <Checkbox
                checked={isCA}
                onChange={(event) => {
                    const data = getCurrentData()
                    dispatch({
                        data: data.map((item) => ({
                            ...item,
                            isCA: this.renderIsCA(event.target.checked),
                            role: event.target.checked
                                ? Course.AcademicPlanCourseRole.ClassAssistance
                                : Course.AcademicPlanCourseRole.Student
                        }))
                    })
                }}
            />
        )
    }

    getPageTitle = () => {
        return "Course"
    }

    renderTextHtml = (title) => {
        return (
            <div>
                <span className={styles.headerTableText}>{title}</span>
            </div>
        )
    }

    getColumns = () => {
        const {t} = this.props

        return [
            {
                title: t("course.code"),
                field: "code",
                style: {minWidth: "150px"}
            },
            {
                title: t("course.name"),
                field: "name",
                style: {minWidth: "250px"}
            },
            {
                title: t("course.instructor"),
                field: "instructor",
                style: {minWidth: "200px"}
            },
            {
                title: "Class Assistance",
                field: "isCA",
                style: {minWidth: "20px"},
                titleHtml: (
                    <div className={styles.classAssistance}>
                        <span>Class Assistance</span>
                        <Tooltip title={"Clock hour will not be calculated"} key={"Class Assistance"}>
                            <div className={styles.classAssistanceInfo}>
                                <Icon icon={"INFO"} />
                            </div>
                        </Tooltip>
                    </div>
                )
            }
        ]
    }

    getData = async (search = "") => {
        const {searchValue} = this.state
        const {dispatch, page, pageSize, sectionId, unit, orderField, uniqCourse, selectedCourses} = this.props
        try {
            dispatch({isLoading: true})
            const selectedCourseIds = (selectedCourses || []).map((course) => course.id)
            const params: any = {
                filter: {
                    search: search || searchValue
                },
                range: {page, pageSize}
            }
            if (sectionId) {
                const courses = await academicPlansService.getAllSectionCourses({sectionId})
                const newData = courses.filter((course) => {
                    const notChoose = !selectedCourseIds.includes(course.id)
                    let hasMatchSearch = true
                    if (searchValue) {
                        hasMatchSearch =
                            course.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                            course.code.toLowerCase().includes(searchValue.toLowerCase())
                    }
                    course.isCA = this.renderIsCA(false)
                    return notChoose && hasMatchSearch
                })
                dispatch({data: newData, total: newData.length})
            } else {
                if (unit) {
                    params.filter.totalUnits = unit
                }
                params.filter.ignoreCourseIds = selectedCourseIds
                if (!isEmpty(orderField)) {
                    params.sort = {
                        orderBy: orderField?.field,
                        orderDir: orderField?.order
                    }
                }
                const {data, total} = await courseService.getAll(params)
                const newData = data.map((course: any) => {
                    course.id = course.courseId
                    return course
                })
                dispatch({data: newData, total})
            }
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    onClickRowItem = () => {}

    onSearchChange = async () => {
        await this.getData()
    }

    onChangeSearch = (newValue) => {
        this.setState({searchValue: newValue}, () => {
            this.onSearchChange()
        })
    }

    onClickAdd = () => {
        const {data, dispatch, onAddCourses} = this.props
        const checkedData = data
            .filter((course) => course.isChecked)
            .map(({courseId, name, code, instructor, id, role}) => ({id, courseId, name, code, instructor, role}))
        if (checkedData.length === 0) {
            toastError("Please select the course")
            return
        }
        onAddCourses(cloneDeep(checkedData) as Course.CourseTableItem[])
        const restCourses = data.filter((course) => !course.isChecked)
        dispatch({data: restCourses})
    }

    render() {
        const {searchValue} = this.state
        const {t, page, total, pageSize, columns, data, allFields, fields, orderField, isLoading} = this.props

        return (
            <>
                <Divider />
                <div className={styles.selectCourseWrap}>
                    <p className={styles.selectCourseTitle}>Select Courses to add</p>
                    <Search
                        value={searchValue}
                        onChange={this.onChangeSearch}
                        placeholder={t("course.searchForCourse")}
                    />
                    <div className={styles.selectCourseTable}>
                        <KlassappTableHeader
                            isShowAction={false}
                            actions={[]}
                            page={page}
                            total={total}
                            defaultPageSize={pageSize}
                            onChangePage={this.props.onChangePage}
                            onChangeRowPerPage={this.props.onChangeRowPerPage}
                            fields={fields}
                            allFields={allFields}
                            onChangeFields={this.props.onChangeFields}
                            onChangeAllFields={this.props.onChangeAllFields}
                            onDraggableColumn={this.props.onDraggableColumn}
                        />
                        <KlassappTable
                            columns={columns}
                            data={data}
                            menuActions={[]}
                            isLoading={isLoading}
                            fields={fields}
                            allFields={allFields}
                            orderField={orderField}
                            isShowCheckedColumn
                            onClickRowItem={this.onClickRowItem}
                            onChangeFields={this.props.onChangeFields}
                            onUpdateRowData={this.props.onUpdateRowData}
                            onUpdateTableData={this.props.onUpdateTableData}
                            onClickSortColumn={this.props.onClickSortColumn}
                            onDraggableColumn={this.props.onDraggableColumn}
                            onChangeAllFields={this.props.onChangeAllFields}
                        />
                    </div>
                    <div className={styles.addAction}>
                        <BaseButton title={t("common:action.add")} onClick={this.onClickAdd} />
                    </div>
                </div>
            </>
        )
    }
}

export default KlassappTableHOC(withTranslation(["course", "common"])(CoursesTable))
