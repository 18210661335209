/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import styles from "./CurrentProgress.module.css"
import cx from "classnames"
import {degreeAuditService, previousCompletedCreditsService, transferCreditsService} from "services"
import {get} from "lodash"
import TableCurrentProgress from "./TableCurrentProgress"
import moment from "moment-timezone"
import {Course} from "types/courses"
import {useModel} from "hooks"
import {formatDecimal, formatDecimalWithoutRound, getFieldLabel, handleError} from "helpers"
import {Settings} from "types/settings"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import TransferCredits from "./TransferCredits"
import PreviouslyCompletedCredits from "./PreviouslyCompletedCredits"

export enum StatusTerm {
    InProgress = "In progress",
    Ended = "Ended",
    NotStarted = "Not started",
    Withdrawn = "Withdrawn"
}

const CurrentProgress = (props) => {
    const [listCurrentProgress, setListCurrentProgress] = useState([])
    const model = useModel()
    const {studentId} = props
    const dateFormat = model.getUserDateFormat()
    const [transferCredits, setTransferCredits] = useState([])
    const [previouslyCompletedCredits, setPreviouslyCompletedCredits] = useState([])

    useEffect(() => {
        getTransferCredits()
        getPreviouslyCompletedCredits()
    }, [])

    async function getTransferCredits() {
        try {
            const params: any = {
                studentProfileId: studentId,
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                filter: {
                    approved: true
                }
            }
            let {data, total} = await transferCreditsService.listTransferCredits(params)
            setTransferCredits(data)
        } catch (err) {
            handleError(err)
        }
    }

    async function getPreviouslyCompletedCredits() {
        try {
            const params: any = {
                studentProfileId: studentId,
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                filter: {
                    approved: true
                }
            }
            let {data, total} = await previousCompletedCreditsService.listPreviousCompletedCredits(params)
            setPreviouslyCompletedCredits(data)
        } catch (err) {
            handleError(err)
        }
    }

    const getData = async () => {
        try {
            const response = await degreeAuditService.getTableCurrentProgress({studentProfileId: studentId})
            const data = response.data || []
            setListCurrentProgress(data)
        } catch (err) {}
    }

    useEffect(() => {
        getData()
    }, [])

    const checkStatus = (startDate: string, endDate: string) => {
        const momentStart = moment(startDate).startOf("day")
        const momentEnd = moment(endDate).endOf("day")
        const today = moment()
        if (today.isSameOrAfter(momentStart) && today.isSameOrBefore(momentEnd)) {
            return StatusTerm.InProgress
        } else if (today.isAfter(momentEnd)) {
            return StatusTerm.Ended
        } else {
            return StatusTerm.NotStarted
        }
    }

    const checkStatusColor = (startDate: string, endDate: string) => {
        const momentStart = moment(startDate).startOf("day")
        const momentEnd = moment(endDate).endOf("day")
        const today = moment()
        if (today.isSameOrAfter(momentStart) && today.isSameOrBefore(momentEnd)) {
            return "#18a957"
        } else if (today.isAfter(momentEnd)) {
            return "gray"
        } else {
            return "gray"
        }
    }

    return (
        <div style={{paddingTop: 20}}>
            {transferCredits?.length ? <TransferCredits transferCredits={transferCredits} /> : null}
            {previouslyCompletedCredits?.length ? (
                <PreviouslyCompletedCredits previouslyCompletedCredits={previouslyCompletedCredits} />
            ) : null}
            {listCurrentProgress.map((item) => {
                const typeMajor = item.type
                const status = checkStatus(item.startDate, item.endDate)
                const color = checkStatusColor(item.startDate, item.endDate)

                return (
                    <>
                        <div className={styles.row}>
                            <span className={styles.titleTable} style={{color: "#1e90ff", marginRight: 10}}>
                                {get(item, ["name"], "")}
                            </span>
                            <span className={styles.titleTable}>
                                {moment(get(item, ["startDate"]))
                                    .utc()
                                    .format(dateFormat)}
                            </span>
                        </div>
                        <div className={styles.row} style={{marginTop: 8}}>
                            <div className={cx(styles.infoProgress, styles.credits)}>
                                <span className={styles.titleProgress}>
                                    {typeMajor === Course.CourseType.Clock ? "HOURS" : "CREDITS"}
                                </span>
                                <span className={styles.numberProgress}>{item?.totalCredit ?? 0}</span>
                            </div>
                            <div className={cx(styles.infoProgress, styles.gpa)}>
                                <span className={styles.titleProgress}>
                                    {getFieldLabel(model, Settings.AcademicLabel.Gpa, "GPA")}
                                </span>
                                <span className={styles.numberProgress}>
                                    {typeof item?.totalGpa === "number" ? formatDecimal(item?.totalGpa) : "NA"}
                                </span>
                            </div>
                            <div className={cx(styles.infoProgress, styles.gpa)}>
                                <span className={styles.titleProgress}>
                                    {getFieldLabel(model, Settings.AcademicLabel.Cgpa, "CGPA")}
                                </span>
                                <span className={styles.numberProgress}>
                                    {typeof item?.cgpa === "number" ? formatDecimal(item?.cgpa) : 0}
                                </span>
                            </div>
                            <div className={cx(styles.infoProgress, styles.status)}>
                                <span className={styles.titleProgress}>STATUS</span>
                                <div
                                    className={styles.statusProgress}
                                    style={{
                                        background: color
                                    }}>
                                    <span className={styles.titleStatus}>{status}</span>
                                </div>
                            </div>
                        </div>
                        <div style={{marginBottom: 40}}>
                            <TableCurrentProgress
                                typeMajor={typeMajor}
                                statusTerm={status}
                                termId={item.termId}
                                studentId={studentId}
                            />
                        </div>
                    </>
                )
            })}
        </div>
    )
}

export default CurrentProgress
