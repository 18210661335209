/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import dayGridPlugin from "@fullcalendar/daygrid"
import moment from "moment"
import "moment/min/locales"
import {BaseLoading} from "components"
import {CalendarProps} from "types/calendar"
import {EventContent, MoreLinkContent} from "components/Calendar/parts"
import styles from "./Calendar.module.css"
import {useModel} from "hooks"
import {Auth} from "types/auth"

const VIEWS = {
    DAY: "timeGridDay",
    MONTH: "dayGridMonth",
    WEEK: "timeGridWeek",
    YEAR: "YEAR"
}

export const Calendar = (props: CalendarProps & {customCalendarHeight?: number}) => {
    const [selectedDay, setSelectedDay] = useState(new Date())
    const [allEvents, setAllEvents] = useState([])
    const [clickInfo, setClickInfo] = useState<any>({})
    const [isShowMoreEvent, setIsShowMoreEvent] = useState(false)
    const [isShowEventPreview, setIsShowEventPreview] = useState(null)
    const [moreLinkData, setMoreLinkData] = useState(null)
    const [updateViewTo] = useState(VIEWS.DAY)
    const model = useModel()
    const {
        calendarRef,
        events,
        isLoading,
        onDateClick,
        onChangeDatesSet,
        eventOptions,
        viewOptions,
        isShowGoing,
        dayHeaders,
        customCalendarHeight = 450,
        ...rest
    } = props

    useEffect(() => {
        if (calendarRef.current) {
            calendarRef.current.getApi().gotoDate(selectedDay)
        }
    }, [selectedDay])

    useEffect(() => {
        setAllEvents(events)
    }, [events])

    const changeToDayView = (date) => {
        calendarRef.current.getApi().gotoDate(date)
        setSelectedDay(date)
    }

    const onClickToday = () => {
        const today = new Date()
        calendarRef.current.getApi().gotoDate(today)
        setSelectedDay(today)
    }

    const onClickPrevMonth = () => {
        switch (updateViewTo) {
            case VIEWS.DAY: {
                const prevDay = moment(selectedDay).subtract(1, "day").toDate()
                setSelectedDay(prevDay)
                break
            }
            case VIEWS.WEEK: {
                const prevWeek = moment(selectedDay).subtract(1, "week").toDate()
                setSelectedDay(prevWeek)
                break
            }
            case VIEWS.MONTH: {
                const prevMonth = moment(selectedDay).subtract(1, "month").toDate()
                setSelectedDay(prevMonth)
                break
            }
            default:
                break
        }
    }

    const onClickNextMonth = () => {
        switch (updateViewTo) {
            case VIEWS.DAY: {
                const prevDay = moment(selectedDay).add(1, "day").toDate()
                setSelectedDay(prevDay)
                break
            }
            case VIEWS.WEEK: {
                const prevWeek = moment(selectedDay).add(1, "week").toDate()
                setSelectedDay(prevWeek)
                break
            }
            case VIEWS.MONTH: {
                const prevMonth = moment(selectedDay).add(1, "month").toDate()
                setSelectedDay(prevMonth)
                break
            }
            default:
                break
        }
    }

    const handleDateClick = (date) => {
        if (isShowEventPreview || isShowMoreEvent) {
            return
        }
        onDateClick(date)
    }

    const handleEventClick = (clickInfo) => {
        setClickInfo(clickInfo)
    }

    const handleShowEventPreview = (visible) => {
        setIsShowEventPreview(visible)
    }

    const handleShowMoreEventPreview = (visible) => {
        setIsShowMoreEvent(visible)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <EventContent
                updateViewTo={updateViewTo}
                eventInfo={eventInfo}
                clickInfo={clickInfo}
                eventOptions={eventOptions}
                isShowGoing={isShowGoing}
                handleShowEventPreview={handleShowEventPreview}
            />
        )
    }

    const handleMoreLinkClick = (info) => {
        setIsShowMoreEvent(true)
        setMoreLinkData(info)
        setClickInfo(info)
    }

    const renderMoreLinkContent = ({text}) => {
        return (
            <MoreLinkContent
                text={text}
                moreLinkData={moreLinkData}
                updateViewTo={updateViewTo}
                clickInfo={clickInfo}
                eventOptions={eventOptions}
                handleShowEventPreview={handleShowEventPreview}
                handleShowMoreEventPreview={handleShowMoreEventPreview}
                onClickDateDetail={onClickDateDetail}
            />
        )
    }

    const onClickDateDetail = (date) => {
        setSelectedDay(date)
    }

    return (
        <div className={styles.root}>
            <div className={styles.rightSide}>
                <BaseLoading isShow={isLoading} />
                <FullCalendar
                    events={allEvents}
                    initialView={VIEWS.DAY}
                    eventOverlap={false}
                    editable={false}
                    selectable={false}
                    selectMirror
                    allDaySlot
                    dayMaxEvents={2}
                    dayMaxEventRows={2}
                    slotDuration="01:00:00"
                    scrollTime="08:00:00"
                    firstDay={0}
                    contentHeight={customCalendarHeight}
                    eventOrder="order"
                    nowIndicator
                    navLinks
                    dayHeaders={dayHeaders}
                    navLinkDayClick={changeToDayView}
                    // TODO locale={model.getFullCalendarLocale()}
                    datesSet={onChangeDatesSet}
                    ref={calendarRef}
                    plugins={[timeGridPlugin, interactionPlugin, dayGridPlugin]}
                    customButtons={{
                        todayBtn: {
                            text: "Today",
                            click: onClickToday
                        },
                        prevIcon: {
                            icon: "chevron-left",
                            click: onClickPrevMonth
                        },
                        nextIcon: {
                            icon: "chevron-right",
                            click: onClickNextMonth
                        }
                    }}
                    headerToolbar={{
                        start: "todayBtn prevIcon,nextIcon",
                        center: "title",
                        end: ""
                    }}
                    dayHeaderFormat={({date, localeCodes}) => {
                        const newDate = moment(date.array).locale(localeCodes[0])
                        if (updateViewTo === VIEWS.MONTH) {
                            return `${moment(newDate).format("ddd")}`
                        }
                        return `${moment(newDate).format("ddd")} \n ${moment(newDate).format("D")}`
                    }}
                    views={{
                        timeGridWeek: {
                            titleFormat: {year: "numeric", month: "long"}
                        },
                        day: {
                            titleFormat: {year: "numeric", month: "long", day: "numeric", weekday: "long"}
                        }
                    }}
                    eventTimeFormat={{
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false
                    }}
                    slotLabelFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: model.user?.timeFormat === Auth.TimeFormat.Time_12
                    }}
                    scrollTimeReset={false}
                    dateClick={handleDateClick}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                    moreLinkContent={renderMoreLinkContent}
                    moreLinkClick={handleMoreLinkClick}
                    {...rest}
                />
            </div>
        </div>
    )
}
