/* eslint-disable react-hooks/exhaustive-deps */
import {FilterList} from "types/filter"
import {Card} from "../Card"
import {useCallback} from "react"
import {studentServicesService} from "services"
import {Bar} from "react-chartjs-2"
import {v4 as uuid} from "uuid"
import {useTranslation} from "react-i18next"
import {useQuery} from "@tanstack/react-query"
import {handleError} from "helpers"

const MONTH_LABELS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

type Props = {
    loadKey: string
    filters?: FilterList
    chartOptions?: any
}

export function Enroll(props: Props) {
    const {loadKey, filters, chartOptions} = props
    const {t} = useTranslation(["studentServices"])

    const {data, isFetching} = useQuery({
        queryKey: ["student-enroll", loadKey],
        enabled: !!loadKey,
        queryFn: async () => {
            const data = await studentServicesService.getEnroll({filters})
            return {
                labels: data.map((x) => {
                    const timeLabels = x.yearMonth.split("-")
                    return `${MONTH_LABELS[parseInt(timeLabels[1]) - 1]} ${timeLabels[0]}`
                }),
                datasets: [
                    {
                        data: data.map((x) => x.noStudents),
                        label: "",
                        backgroundColor: "#5dc389"
                    }
                ]
            }
        },
        onError: (err) => {
            handleError(err)
        }
    })

    const datasetKeyProvider = useCallback(() => uuid(), [filters])

    const renderChart = () => {
        if (!data) return null
        return <Bar data={data} options={chartOptions} datasetKeyProvider={datasetKeyProvider} />
    }

    return (
        <Card
            key={loadKey}
            iconName="PERSON_DONE"
            title={t("enroll")}
            iconColor="var(--success-300)"
            renderChart={renderChart}
            isLoading={isFetching}
        />
    )
}
