import {BasePopup} from "components"
import {MakeupAttendanceType} from "../EditMakeupAttendance/EditMakeupAttendance"
import {QRScan} from "./QRScan"
import styles from "./QRScan.module.css"

type Props = {
    isVisible: boolean
    onClose: () => void
    selectedAttendance?: MakeupAttendanceType
}

export const QRScanPopup = (props: Props) => {
    const {isVisible, onClose, selectedAttendance} = props

    return (
        <BasePopup
            isShow={isVisible}
            onClose={onClose}
            width="70vw"
            leftIcon="ATTENDANCE_QR_CODE"
            leftIconColor="white">
            <div className={styles.container}>
                <p className={styles.title}>QR scan</p>
                <div className={styles.line} />
                <QRScan selectedAttendance={selectedAttendance} />
            </div>
        </BasePopup>
    )
}
