import React from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {Radio} from "antd"
import {Every} from "sections/calendar/parts/common"
import {KlassDropdown} from "components/Select"
import {
    OPTIONS_DAYS_LONG,
    OPTIONS_MONTH_DAYS,
    OPTIONS_MONTHLY_FREQUENCY
} from "sections/calendar/parts/common/constants"
import styles from "./MonthlyRecurring.module.css"

export function MonthlyRecurringBody(props) {
    const {t} = useTranslation("calendar")
    const {eventData, onChangeEventData, errors} = props

    return (
        <>
            <Every
                title={t("calendar.month")}
                // @ts-ignore
                error={!!errors["every"]}
                value={eventData.every}
                onChange={(newValue) => onChangeEventData("every", newValue)}
            />
            <div className={styles.monthlyItem}>
                <Radio
                    value={0}
                    checked={eventData.monthlyRecurring === 0}
                    onChange={() => onChangeEventData("monthlyRecurring", 0)}
                    className={cx(styles.radioBtn__item, {
                        [styles.radioBtn__active]: !!eventData.isPrimary
                    })}>
                    <div className={styles.monthlyItem__title}>{t("calendar.day")}</div>
                </Radio>
                <div className={styles.monthlyItem__body}>
                    <KlassDropdown
                        placeholder=""
                        closeMenuOnSelect
                        value={eventData.monthDay}
                        error={!!errors["monthDay"]}
                        onChange={(newValue) => onChangeEventData("monthDay", newValue)}
                        options={OPTIONS_MONTH_DAYS}
                        labelKey="label"
                        valueKey="value"
                        isDisabled={+eventData.monthlyRecurring !== 0}
                    />
                </div>
            </div>
            <div className={styles.monthlyItem}>
                <Radio
                    value={1}
                    checked={eventData.monthlyRecurring === 1}
                    onChange={() => onChangeEventData("monthlyRecurring", 1)}>
                    <div className={styles.monthlyItem__title}>{t("calendar.the")}</div>
                </Radio>
                <div className={styles.monthlyItem__body}>
                    <div className={styles.dropdownItem}>
                        <KlassDropdown
                            error={!!errors["monthWeek"]}
                            value={eventData.monthWeek}
                            onChange={(newValue) => onChangeEventData("monthWeek", newValue)}
                            options={OPTIONS_MONTHLY_FREQUENCY}
                            labelKey="label"
                            valueKey="value"
                            isDisabled={+eventData.monthlyRecurring !== 1}
                            closeMenuOnSelect
                            placeholder=""
                        />
                    </div>
                    <div>
                        <KlassDropdown
                            placeholder=""
                            closeMenuOnSelect
                            error={!!errors["monthWeekDay"]}
                            value={eventData.monthWeekDay}
                            onChange={(newValue) => onChangeEventData("monthWeekDay", newValue)}
                            options={OPTIONS_DAYS_LONG}
                            labelKey="label"
                            valueKey="value"
                            isDisabled={+eventData.monthlyRecurring !== 1}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
