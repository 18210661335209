import React from "react"
import ActivityForm from "sections/shared/student/ActivityForm"
import {PanelExpandedSection} from "uiKit/PanelExpandedSection"
import {EVENT_STUDENT_ACTIVITY_CREATED, StudentActivity} from "types/activity"
import {emitEvent} from "helpers/eventHelper"

type DisplayPart = "list" | "create"

type Props = {
    studentId: number
    departmentId?: number
    onNavigate?: (displayMode: DisplayPart) => void
    onDone?: (activity: StudentActivity) => void
}

export const CreateActivity = (props: Props) => {
    const {studentId, departmentId, onNavigate, onDone} = props

    return (
        <PanelExpandedSection title="Add activity" onClose={() => onNavigate("list")}>
            <ActivityForm
                departmentId={departmentId}
                studentId={studentId}
                isCompactForm={false}
                displayMode="panel"
                onDone={(activity) => {
                    onNavigate("list")
                    onDone?.(activity)
                    emitEvent(EVENT_STUDENT_ACTIVITY_CREATED, {
                        profileId: activity.profileId,
                        activity
                    })
                }}
            />
        </PanelExpandedSection>
    )
}
