import {BaseButton, BasePopup, SecondaryButton} from "components"
import styles from "./MediaLibraryPopup.module.css"
import {useEffect, useState} from "react"
import {useModel} from "hooks"
import {toastError} from "helpers"
import MediaLibrarySelect from "components/MediaLibrarySelect"

type Props = {
    isShow: boolean
    onClose: () => void
    onSelect: (_mediaLibraryItem: any) => void
}

export function MediaLibraryPopup(props: Props) {
    const {isShow, onClose, onSelect} = props
    const [mediaLibraryItem, setMediaLibraryItem] = useState()
    const model = useModel()

    useEffect(() => {
        setMediaLibraryItem(undefined)
    }, [])

    const onSubmit = () => {
        if (!mediaLibraryItem) {
            toastError("Please select a media libary item.")
            return
        }

        onSelect(mediaLibraryItem)
        setMediaLibraryItem(undefined)
        onClose()
    }

    return (
        <BasePopup
            isShow={isShow}
            onClose={onClose}
            leftIcon="DOCUMENT"
            leftIconColor="#fff"
            width="40vw"
            closable={false}
            destroyOnClose>
            <div className={styles.headingTitle}>Attach media library</div>
            <MediaLibrarySelect profileId={model.profileId} onChange={setMediaLibraryItem} />
            <div className={styles.actionButtons}>
                <SecondaryButton title="Cancel" onClick={onClose} className={styles.addOption} />
                <BaseButton title="SAVE" onClick={onSubmit} className={styles.addOption} />
            </div>
        </BasePopup>
    )
}
