import React from "react"
import {v4 as uuid} from "uuid"
import {Checkbox} from "antd"
import {isEmpty, isEqual} from "lodash"
import {withTranslation} from "react-i18next"
import {BaseInput} from "components/inputs"
import {AddItemCircleButton, BaseButton, SecondaryButton} from "components/buttons"
import {Icon} from "components/Icon"
import {BaseNewFilter, KlassappTable, KlassappTableContext, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {checkPermission, handleError, toastError, toastSuccess, toastWarning} from "helpers"
import {generalSettingsService, settingActivityService} from "services"
import {KlassappTableHOC} from "HOC"
import {BaseDepartmentId} from "types/departments"
import {PermissionsRequired} from "components/PermissionsRequired"
import {getPermissionsByDepartment} from "sections/Settings/helper"
import {Model} from "Model"
import {PermissionUserType} from "types/permission"
import styles from "./Activities.module.css"
import StudentStatusSelect from "components/StudentStatusSelect"
import {STATES_OPTIONS} from "types/students"
import {NewStudentStatusSelect} from "components/NewStudentStatusSelect"
import cx from "classnames"
import {FilterKey} from "types/filter"
import {GeneralSettingField} from "types/settings/general-setting"
import {MAP_DEPARTMENT_2_UNREAD_ACTIVITY_SETTING_FIELD} from "types/activity"

type PageProps = {
    t: Function
    departmentId: BaseDepartmentId
    model: Model
}

type Props = KlassappTableProps & PageProps

type State = {
    newItemData: any
    oldItemData: any
    isSubmitting: boolean
    isSubmittingAdvisor: boolean
    advisorActivityTarget: any
    oldAdvisorActivityTarget: any
    search: string
    permissions: PermissionUserType
    showUnreadActivities?: boolean
}

class Activities extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        const storageData = this.props.model.getStorageFilter(FilterKey.Activity)
        this.state = {
            newItemData: null,
            oldItemData: null,
            isSubmitting: false,
            isSubmittingAdvisor: false,
            advisorActivityTarget: {
                daily: "",
                weekly: "",
                monthly: ""
            },
            oldAdvisorActivityTarget: {
                daily: "",
                weekly: "",
                monthly: ""
            },
            search: !isEmpty(storageData) ? storageData.search : "",
            permissions: {staff: []}
        }
    }

    componentDidMount() {
        this.getPermissions()
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: this.onClickDuplicateMulti},
            {key: "onClickRestoreMulti", func: this.onClickRestoreMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete}
        ])
        this.getData()
        if (
            this.props.departmentId === BaseDepartmentId.Admissions ||
            this.props.departmentId === BaseDepartmentId.FinancialAid
        ) {
            this.getAdvisorActivityTarget()
        }
        this.getUnreadActivitySettings()
    }

    getPermissions = () => {
        const permissions = getPermissionsByDepartment(this.props.departmentId)
        this.setState({permissions})
    }

    getPageTitle = () => {
        return this.props.t("settings.activities.activity")
    }

    getFields = () => {
        const {t} = this.props
        return [
            t("settings.activities.name"),
            t("settings.activities.hold"),
            t("settings.activities.statuses"),
            t("settings.activities.updateToStatus")
        ]
    }

    getTableHeaderActions = (isShowDuplicateBtn = true, checkedData = []) => {
        const canWrite = checkPermission(this.state.permissions, this.props.model)
        if (!canWrite) {
            return []
        }
        const hasLockedItem = (checkedData || []).some((item) => item.isLocked)
        const hasInactiveItem = (checkedData || []).some((item) => !item.isActive)
        const {t, onClickShowConfirmModal} = this.props
        const actions = []
        if (!hasLockedItem && !hasInactiveItem) {
            actions.push({
                title: t("common:action.deactivate"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DEACTIVATE")
            })
        }
        if (isShowDuplicateBtn && !hasInactiveItem) {
            actions.push({
                title: t("common:action.duplicate"),
                icon: "DUPLICATE",
                action: () => this.onClickDuplicateMulti()
            })
        }
        if (hasInactiveItem) {
            actions.push({
                title: t("common:action.restore"),
                icon: "REPLY",
                action: () => this.onClickRestoreMulti()
            })
        }
        return actions
    }

    canShowDeleteIcon = (data) => {
        return checkPermission(this.state.permissions, this.props.model) && !data.isLocked && data.isActive
    }

    canShowRestoreIcon = (data) => {
        return checkPermission(this.state.permissions, this.props.model) && !data.isActive && !data.isForm
    }

    canShowEditIcon = (data) => {
        return checkPermission(this.state.permissions, this.props.model) && data.isActive
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: this.canShowEditIcon
            },
            {
                title: t("common:action.restore"),
                icon: "REPLY",
                action: this.onClickRestore,
                canShow: this.canShowRestoreIcon
            },
            {
                title: t("common:action.deactivate"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: this.canShowDeleteIcon
            }
        ]
    }

    getData = async () => {
        this.props.dispatch({isLoading: true})
        const params = this.getParams()

        try {
            const {data, total} = await settingActivityService.getAllActivities(params)
            const newData = data.map((item) => {
                item.id = item.activityId
                item.nameHtml = this.renderNameHtml(item)
                item.holdHtml = this.renderHoldCheckbox(item)
                item.statusesHtml = this.renderStatusesHtml(item)
                item.updateToStatusHtml = this.renderUpdateToStatusHtml(item)
                item.isInacctiveTableCol = !item.isActive
                return item
            })
            this.props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    renderNameHtml = (item) => {
        return (
            <div className={styles.titleWrap}>
                {item.isLocked ? (
                    <div className={styles.lockIcon}>
                        <Icon icon="LOCK_CHECKMARK" />
                    </div>
                ) : (
                    ""
                )}
                <span className={cx(styles.title, {[styles.inactiveTitle]: !item.isActive})}>{`${
                    item.isActive ? "" : "INACTIVE - "
                }${item.name}`}</span>
            </div>
        )
    }

    renderStatusesHtml = (item) => {
        const {t, model} = this.props
        return (
            <div className={styles.titleWrap}>
                <span className={styles.title}>
                    {model.clientSetting.isNewStudentStatusesVisible
                        ? item.studentStatuses
                              ?.map(
                                  (status) =>
                                      status.name +
                                      (status.state
                                          ? ` (${STATES_OPTIONS.find((option) => option.id === status.state).name})`
                                          : "")
                              )
                              .join(", ")
                        : item.statuses
                              ?.map(
                                  (status) =>
                                      (status.stage?.departmentId
                                          ? `[${t(`common:moduleAbbrev.${status.stage?.departmentId}`)}] `
                                          : "") + status.name
                              )
                              .join(", ")}
                </span>
            </div>
        )
    }

    renderUpdateToStatusHtml = (item) => {
        const {t, model} = this.props
        return (
            <div className={styles.titleWrap}>
                <span className={styles.title}>
                    {model.clientSetting.isNewStudentStatusesVisible ? (
                        <>
                            {item.updateToStudentStatus?.name}
                            {!!item.updateToStudentStatus?.state &&
                                ` (${
                                    STATES_OPTIONS.find((option) => option.id === item.updateToStudentStatus.state).name
                                })`}
                        </>
                    ) : (
                        <>
                            {item.updateToStatus?.stage?.departmentId
                                ? `[${t(`common:moduleAbbrev.${item.updateToStatus?.stage?.departmentId}`)}]`
                                : ""}{" "}
                            {item.updateToStatus?.name}
                        </>
                    )}
                </span>
            </div>
        )
    }

    getParams = () => {
        const {search} = this.state
        const {page, pageSize, departmentId, orderField} = this.props
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {
                search,
                departmentIds: [departmentId]
            }
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    getAdvisorActivityTarget = async () => {
        try {
            const advisorActivityTarget = await settingActivityService.getAllAdvisorActivityTarget()
            this.setState({advisorActivityTarget, oldAdvisorActivityTarget: {...advisorActivityTarget}})
        } catch (e) {
            handleError(e)
        }
    }

    getUnreadActivitySettingField = () => {
        return MAP_DEPARTMENT_2_UNREAD_ACTIVITY_SETTING_FIELD[this.props.departmentId]
    }

    getUnreadActivitySettings = async () => {
        try {
            const settings = await generalSettingsService.getSettings([
                GeneralSettingField.AcadActivityShowUnread,
                GeneralSettingField.FaActivityShowUnread,
                GeneralSettingField.AdmActivityShowUnread,
                GeneralSettingField.SaActivityShowUnread,
                GeneralSettingField.SsActivityShowUnread
            ])
            this.setState({
                showUnreadActivities: !!JSON.parse(
                    settings.find((setting) => setting.field === this.getUnreadActivitySettingField())?.value ?? null
                )
            })
        } catch (e) {
            console.error(e)
        }
    }

    getColumns = () => {
        const {t} = this.props

        return [
            {
                title: t("settings.activities.name"),
                field: "nameHtml",
                style: {minWidth: "200px"},
                sortable: true,
                orderField: "name"
            },
            {
                title: t("settings.activities.hold"),
                field: "holdHtml",
                style: {minWidth: "80px"},
                sortable: true,
                orderField: "isHoldActivity"
            },
            {
                title: t("settings.activities.statuses"),
                field: "statusesHtml",
                style: {width: "150px"},
                sortable: false
            },
            {
                title: t("settings.activities.updateToStatus"),
                field: "updateToStatusHtml",
                style: {width: "150px"},
                sortable: false
            }
        ]
    }

    onClickDeleteMulti = async () => {
        const {t} = this.props
        const checkedItems = this.props.data.filter((item) => item.isChecked)
        const lockedActivities = checkedItems.filter((item) => item.isLocked)
        if (lockedActivities.length) {
            toastError(t("common:message.cantDeleteLockedItem"))
            return
        }
        const payload = checkedItems.map((item) => item.id)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingActivityService.deleteActivity(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickDuplicateMulti = async () => {
        const {data, departmentId} = this.props
        const payload = data
            .filter((item) => item.isChecked)
            .map(({name, isHoldActivity, statuses, updateToStatus, studentStatuses, updateToStudentStatus}) => ({
                name: `${name} (Copy)`,
                isHoldActivity,
                departmentId,
                updateToStatusId: updateToStatus?.statusId || null,
                statusIds: statuses?.map((status) => status.statusId) || [],
                updateToStudentStatusId: updateToStudentStatus?.statusId || null,
                studentStatusIds: studentStatuses?.map((status) => status.statusId) || []
            }))
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingActivityService.duplicateActivity(payload)
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickRestoreMulti = async () => {
        const {data} = this.props
        const activityIds = data.filter((item) => item.isChecked && !item.isActive).map((item) => item.activityId)
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingActivityService.restoreActivity({
                activityIds
            })
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickEdit = (editItem) => {
        const {newItemData} = this.state
        if (newItemData) {
            toastWarning(this.props.t("common:validation.saveBeforeEdit"))
            return
        }
        this.setState(
            {
                newItemData: {
                    ...editItem,
                    nameData: editItem.name,
                    isHoldActivityData: editItem.isHoldActivity,
                    updateToStatusData: this.props.model.clientSetting.isNewStudentStatusesVisible
                        ? editItem.updateToStudentStatus
                        : editItem.updateToStatus,
                    statusesData: this.props.model.clientSetting.isNewStudentStatusesVisible
                        ? editItem.studentStatuses
                        : editItem.statuses
                },
                oldItemData: {...editItem}
            },
            () => {
                const newData = this.props.data.map((item) => {
                    if (item.id === editItem.id) {
                        item.nameHtml = this.renderNameInput()
                        item.holdHtml = this.renderHoldCheckbox()
                        item.statusesHtml = this.renderStatusesSelect()
                        item.updateToStatusHtml = this.renderUpdateToStatusSelect()
                        return item
                    }
                    return item
                })
                this.props.dispatch({data: newData})
            }
        )
    }

    onClickDelete = async (deletedItem) => {
        const {t} = this.props
        if (deletedItem.isLock) {
            toastError(t("common:message.cantDeleteLockedItem"))
            return
        }
        if (deletedItem.isForm) {
            this.setState({newItemData: null, oldItemData: null})
            await this.getData()
            return
        }
        try {
            this.props.dispatch({isLoading: true})
            this.setState({newItemData: null, oldItemData: null})
            await settingActivityService.deleteActivity([deletedItem.id])
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickRestore = async (restoreItem) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({newItemData: null, oldItemData: null})
            await settingActivityService.restoreActivity({activityIds: [restoreItem.id]})
            await this.getData()
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onChangeNewItemData = (key, value) => {
        const {newItemData} = this.state
        newItemData[key] = value
        const newData = this.props.data.map((item) => {
            if (item.id === newItemData.id) {
                item.nameHtml = this.renderNameInput()
                item.holdHtml = this.renderHoldCheckbox()
                item.statusesHtml = this.renderStatusesSelect()
                item.updateToStatusHtml = this.renderUpdateToStatusSelect()
                return item
            }
            return item
        })
        this.setState({newItemData})
        this.props.dispatch({data: newData})
    }

    renderNameInput = () => {
        const newItemData = this.state.newItemData || {}
        return (
            <BaseInput
                value={newItemData.nameData}
                placeholder="Name"
                onChange={(value) => this.onChangeNewItemData("nameData", value)}
            />
        )
    }

    renderHoldCheckbox = (item = null) => {
        if (item) {
            return (
                <div key={`show-${item.activityId}`} className={styles.visibleCheckboxWrap}>
                    <Checkbox checked={item.isHoldActivity} />
                </div>
            )
        }
        const newItemData = this.state.newItemData || {}
        return (
            <div key={`edit-${newItemData.activityId}`} className={styles.visibleCheckboxWrap}>
                <Checkbox
                    checked={newItemData.isHoldActivityData}
                    onChange={(event) => this.onChangeNewItemData("isHoldActivityData", event.target.checked)}
                />
            </div>
        )
    }

    renderStatusesSelect = () => {
        const {departmentId, model} = this.props
        const {statusesData} = this.state.newItemData || {}
        return (
            <KlassappTableContext.Consumer>
                {({setIsShowScrollTable}) =>
                    model.clientSetting.isNewStudentStatusesVisible ? (
                        <NewStudentStatusSelect
                            isMulti
                            isClearable
                            value={statusesData}
                            onChange={(value) => this.onChangeNewItemData("statusesData", value)}
                            onMenuOpen={() => setIsShowScrollTable(false)}
                            onMenuClose={() => setIsShowScrollTable(true)}
                        />
                    ) : (
                        <StudentStatusSelect
                            departmentId={departmentId}
                            isMulti
                            isClearable
                            value={statusesData}
                            onChange={(value) => this.onChangeNewItemData("statusesData", value)}
                            onMenuOpen={() => setIsShowScrollTable(false)}
                            onMenuClose={() => setIsShowScrollTable(true)}
                        />
                    )
                }
            </KlassappTableContext.Consumer>
        )
    }

    renderUpdateToStatusSelect = () => {
        const {departmentId, model} = this.props
        const {updateToStatusData} = this.state.newItemData || {}
        return (
            <KlassappTableContext.Consumer>
                {({setIsShowScrollTable}) =>
                    model.clientSetting.isNewStudentStatusesVisible ? (
                        <NewStudentStatusSelect
                            isMulti={false}
                            isClearable
                            value={updateToStatusData}
                            onChange={(value) => this.onChangeNewItemData("updateToStatusData", value)}
                            onMenuOpen={() => setIsShowScrollTable(false)}
                            onMenuClose={() => setIsShowScrollTable(true)}
                        />
                    ) : (
                        <StudentStatusSelect
                            departmentId={departmentId}
                            isMulti={false}
                            isClearable
                            value={updateToStatusData}
                            onChange={(value) => this.onChangeNewItemData("updateToStatusData", value)}
                            onMenuOpen={() => setIsShowScrollTable(false)}
                            onMenuClose={() => setIsShowScrollTable(true)}
                        />
                    )
                }
            </KlassappTableContext.Consumer>
        )
    }

    onClickAddItem = () => {
        const newItem = {
            id: uuid(),
            nameHtml: this.renderNameInput(),
            holdHtml: this.renderHoldCheckbox(),
            statusesHtml: this.renderStatusesSelect(),
            updateToStatusHtml: this.renderUpdateToStatusSelect(),
            isForm: true
        }
        const {data} = this.props
        data.push(newItem)
        this.setState({newItemData: newItem, oldItemData: null})
        this.props.dispatch({data})
    }

    validateDataBeforeSubmit = () => {
        const {nameData} = this.state.newItemData
        if (!nameData) {
            toastError(this.props.t("common:validation.cantEmpty", {field: "Name"}))
            return false
        }
        return true
    }

    onClickSave = async () => {
        if (!this.validateDataBeforeSubmit()) {
            return
        }
        const {nameData, isHoldActivityData, statusesData, updateToStatusData, isForm, id} = this.state.newItemData
        const {departmentId} = this.props
        const submitData: any = {
            name: nameData,
            isHoldActivity: !!isHoldActivityData
        }
        if (this.props.model.clientSetting.isNewStudentStatusesVisible) {
            submitData.updateToStudentStatusId = updateToStatusData?.statusId || null
            submitData.studentStatusIds = statusesData?.map((status) => status.statusId) || []
            submitData.updateToStatusId = null
            submitData.statusIds = []
        } else {
            submitData.updateToStatusId = updateToStatusData?.statusId || null
            submitData.statusIds = statusesData?.map((status) => status.statusId) || []
            submitData.updateToStudentStatusId = null
            submitData.studentStatusIds = []
        }
        if (isForm) {
            submitData.departmentId = departmentId
            await this.createActivity(submitData)
        } else {
            await this.updateActivity(id, submitData)
        }
        this.props.dispatch({isShowTableHeaderAction: false})
        this.setState({newItemData: null, oldItemData: null}, () => {
            this.getData()
        })
    }

    createActivity = async (data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await settingActivityService.createActivity(data)
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    updateActivity = async (id, data) => {
        try {
            this.props.dispatch({isLoading: true})
            this.setState({isSubmitting: true})
            await settingActivityService.updateActivity(id, data)
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.setState({isSubmitting: false})
        }
    }

    onClickCancel = () => {
        const {oldItemData} = this.state
        const newData = this.props.data
            .filter((item) => !item.isForm)
            .map((item) => {
                if (oldItemData && oldItemData.id === item.id) {
                    item.nameHtml = oldItemData.name
                    item.holdHtml = this.renderHoldCheckbox(oldItemData)
                    item.statusesHtml = this.renderStatusesHtml(oldItemData)
                    item.updateToStatusHtml = this.renderUpdateToStatusHtml(oldItemData)
                    return item
                }
                return item
            })
        this.setState({newItemData: null, oldItemData: null})
        this.props.dispatch({data: newData})
    }

    onChangeAdvisorActivityTarget = (key, value) => {
        const {advisorActivityTarget} = this.state
        advisorActivityTarget[key] = value ? Number.parseInt(value, 10) : ""
        this.setState({advisorActivityTarget})
    }

    onClickCancelAdvisorActivityTarget = () => {
        const {oldAdvisorActivityTarget} = this.state
        this.setState({advisorActivityTarget: {...oldAdvisorActivityTarget}})
    }

    onClickSaveAdvisorActivityTarget = async () => {
        const {advisorActivityTarget} = this.state
        try {
            this.setState({isSubmittingAdvisor: true})
            const newData = await settingActivityService.updateAdvisorActivityTarget(advisorActivityTarget)
            this.setState({advisorActivityTarget: newData, oldAdvisorActivityTarget: {...newData}})
            toastSuccess(this.props.t("settings.activities.updateAdvisorActivityTargetSuccess"))
        } catch (e) {
            handleError(e)
        } finally {
            this.setState({isSubmittingAdvisor: false})
        }
    }

    onSearchInput = (search) => {
        this.props.model.updateStorageFilter(FilterKey.Activity, {search, page: 1})
        this.props.dispatch({page: 1})
        this.setState({search}, () => {
            this.getData()
        })
    }

    render() {
        const {
            newItemData,
            isSubmitting,
            advisorActivityTarget,
            oldAdvisorActivityTarget,
            isSubmittingAdvisor,
            search,
            permissions
        } = this.state
        const {
            t,
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            allFields,
            fields,
            tableHeaderActions,
            isLoading,
            isHideMenuActions,
            isShowTableHeaderAction,
            orderField,
            departmentId
        } = this.props
        const isChanged = !isEqual(advisorActivityTarget, oldAdvisorActivityTarget)
        const {showUnreadActivities} = this.state

        return (
            <div className={styles.wrapper}>
                {(departmentId === BaseDepartmentId.Admissions || departmentId === BaseDepartmentId.FinancialAid) && (
                    <div className={styles.activityTarget}>
                        {/*
                        <p className={styles.activityTarget__title}>{t("settings.activities.activityTarget")}</p>
                        <div className={styles.activityTargetForm}>
                            <div className={styles.activityTargetSquare}></div>
                            <p className={styles.activityTarget__text}>{t("settings.activities.daily")}</p>
                            <div>
                                <BaseInput
                                    placeholder="Number"
                                    type="number"
                                    value={advisorActivityTarget.daily}
                                    onChange={(value) => this.onChangeAdvisorActivityTarget("daily", value)}
                                />
                            </div>
                        </div>
                        <div className={styles.activityTargetForm}>
                            <div className={styles.activityTargetSquare}></div>
                            <p className={styles.activityTarget__text}>{t("settings.activities.weekly")}</p>
                            <div>
                                <BaseInput
                                    placeholder="Number"
                                    type="number"
                                    value={advisorActivityTarget.weekly}
                                    onChange={(value) => this.onChangeAdvisorActivityTarget("weekly", value)}
                                />
                            </div>
                        </div>
                        <div className={styles.activityTargetForm}>
                            <div className={styles.activityTargetSquare}></div>
                            <p className={styles.activityTarget__text}>{t("settings.activities.monthly")}</p>
                            <div>
                                <BaseInput
                                    placeholder="Number"
                                    type="number"
                                    value={advisorActivityTarget.monthly}
                                    onChange={(value) => this.onChangeAdvisorActivityTarget("monthly", value)}
                                />
                            </div>
                        </div>
                        */}

                        <PermissionsRequired permissions={permissions}>
                            {isChanged && (
                                <div className={styles.buttonWrap}>
                                    <SecondaryButton
                                        title={t("common:action.cancel")}
                                        onClick={this.onClickCancelAdvisorActivityTarget}
                                        className={styles.cancelBtn}
                                    />
                                    <BaseButton
                                        title={t("common:action.save").toUpperCase()}
                                        onClick={this.onClickSaveAdvisorActivityTarget}
                                        loading={isSubmittingAdvisor}
                                    />
                                </div>
                            )}
                        </PermissionsRequired>
                    </div>
                )}

                {showUnreadActivities != null && (
                    <Checkbox
                        checked={showUnreadActivities}
                        onChange={async (e) => {
                            const checked = e.target.checked
                            this.setState({showUnreadActivities: checked})
                            await generalSettingsService.updateSettings([
                                {
                                    field: this.getUnreadActivitySettingField(),
                                    value: JSON.stringify(checked)
                                }
                            ])
                        }}>
                        Show Alerts
                    </Checkbox>
                )}

                <BaseNewFilter searchValue={search} onSearchInput={this.onSearchInput} />
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn
                    onClickRowItem={() => {}}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                <PermissionsRequired permissions={permissions}>
                    {!newItemData ? (
                        !isLoading && <AddItemCircleButton onClick={this.onClickAddItem} />
                    ) : (
                        <div className={styles.buttonWrap}>
                            <SecondaryButton
                                title={t("common:action.cancel")}
                                onClick={this.onClickCancel}
                                className={styles.cancelBtn}
                            />
                            <BaseButton
                                title={t("common:action.save").toUpperCase()}
                                onClick={this.onClickSave}
                                loading={isSubmitting}
                            />
                        </div>
                    )}
                </PermissionsRequired>
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["settings", "common"])(Activities))
