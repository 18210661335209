import {handleError, toastSuccess} from "helpers"
import {KlassappTableHOC} from "HOC"
import {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {v4 as uuidv4} from "uuid"
import {academicPlansService} from "services"
import {KlassappTableProps} from "types/common"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./ClockHoursTranscript.module.css"
import {Major} from "types/major"
import {Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {AddItemCircleButton, BaseButton, BaseInput, BaseLoading, Icon} from "components"
import {Course} from "types/courses"
import {cloneDeep} from "lodash"
import {useMutation, useVisible} from "hooks"
import {AddCoursesPopup} from "./AddCoursesPopup"
import {useQuery} from "@tanstack/react-query"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {ClockHoursTranscriptSectionEditPayload} from "types/clock-hours-transcript"

type ProgramTableProps = KlassappTableProps & {
    selectedProgram: Major.MajorVersion
}

type SectionDetail = {
    sectionId: string | number
    sectionTitle?: string
    courses: Array<SectionCourse>
}

export type SectionCourse = {
    courseId: number
    sectionCourseId: string | number
    deleteHtml?: React.ReactElement
}

const SectionView = (props: ProgramTableProps) => {
    const {
        data,
        dispatch,
        dispatchFunc,
        selectedProgram,
        page,
        pageSize,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        columns,
        isShowTableHeaderAction
    } = props
    const addCoursesPopup = useVisible()
    const [currentSection, setCurrentSection] = useState<SectionDetail>()
    const [loading, setLoading] = useState(false)
    const [initialSections, setInitialSections] = useState<Array<SectionDetail>>([])
    const [sections, setSections] = useState<Array<SectionDetail>>([])
    const sectionsData = useRef<Array<SectionDetail>>([])

    const editSection = useMutation(academicPlansService.editClockHoursTranscriptSections, {
        onSuccess: () => {
            getData()
            toastSuccess("Updated Successfully")
            setLoading(false)
        },
        onError: (err) => {
            setLoading(false)
            handleError(err, true)
        }
    })

    const {isFetching: isFetchingSections, refetch: refetchSections} = useQuery(
        ["clock-hours-transcript-sections", selectedProgram.id, page, pageSize],
        () =>
            academicPlansService.listClockHoursTranscriptSections({
                filter: {
                    programVersionId: selectedProgram.id
                },
                range: {
                    page,
                    pageSize: UNLIMITED_PAGE_SIZE
                }
            }),
        {
            onSuccess: ({data, total}) => {
                data = data.map((section) => {
                    if (section?.courses) {
                        section.courses = section.courses.map((course) => {
                            return {
                                ...course,
                                deleteHtml: (
                                    <div
                                        onClick={() => {
                                            onDeleteCourse(course.courseId, section.sectionId)
                                        }}
                                        className={styles.trashButton}>
                                        <Icon icon="TRASH_LINE" />
                                    </div>
                                )
                            }
                        })
                    }
                    return section
                })
                setInitialSections(cloneDeep(data))
                setSections(cloneDeep(data))
                dispatch({data: data, total})
            },
            onError: (err) => {
                handleError(err)
            },
            enabled: !!selectedProgram
        }
    )

    useEffect(() => {
        sectionsData.current = cloneDeep(sections)
    }, [sections])

    const getFields = () => {
        return ["Code", "Course Name", "Clock", ""]
    }

    const getPageTitle = () => {
        return "Major"
    }

    const getColumns = () => {
        return [
            {
                title: "Code",
                field: "courseCode",
                sortable: true,
                style: {minWidth: 300}
            },
            {
                title: "Course Name",
                field: "courseName",
                sortable: true,
                style: {minWidth: 300}
            },
            {
                title: "Clock",
                field: "credits",
                sortable: true,
                style: {minWidth: 40}
            },
            {
                title: "",
                field: "deleteHtml",
                style: {maxWidth: 40}
            }
        ]
    }

    const onDeleteCourse = (courseId: number, sectionId: number | string) => {
        const newSections = cloneDeep(sectionsData.current)
        for (const section of newSections) {
            if (section.courses.length && section.sectionId === sectionId) {
                section.courses = section.courses.filter((course) => course.courseId !== courseId)
            }
        }
        setSections(cloneDeep(newSections))
    }

    const getData = async () => {
        refetchSections()
    }
    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const addSection = () => {
        setSections([
            ...sections,
            {
                sectionId: uuidv4(),
                courses: []
            }
        ])
    }

    const preparePayloadForSaving = (): ClockHoursTranscriptSectionEditPayload => {
        let payload: any = {}
        const initialSectionCourseIds = initialSections.reduce((sectionCourseIds, section) => {
            sectionCourseIds.push(...section.courses.map((course) => course.sectionCourseId))
            return sectionCourseIds
        }, [])
        const initialSectionIds = initialSections.reduce((sectionIds, section) => {
            sectionIds.push(section.sectionId)
            return sectionIds
        }, [])
        const newSectionIds = sections.reduce((sectionIds, section) => {
            sectionIds.push(section.sectionId)
            return sectionIds
        }, [])
        const newSectionCourseIds = sections.reduce((sectionCourseIds, section) => {
            sectionCourseIds.push(...section.courses.map((course) => course.sectionCourseId))
            return sectionCourseIds
        }, [])

        const deleteSectionCourseIds = initialSectionCourseIds.filter(
            (sectionCourseId) => !newSectionCourseIds.includes(sectionCourseId)
        )
        const deleteSectionIds = initialSectionIds.filter((sectionId) => !newSectionIds.includes(sectionId))
        payload = {
            sections: sections.map((section) => {
                return {
                    programVersionId: selectedProgram.id,
                    sectionId: typeof section.sectionId === "number" ? section.sectionId : undefined,
                    courses: section.courses.map((course) => {
                        return {
                            courseId: course.courseId,
                            sectionCourseId:
                                typeof course.sectionCourseId === "number" ? course.sectionCourseId : undefined
                        }
                    }),
                    sectionTitle: section.sectionTitle
                }
            }) as any,
            deleteSectionIds,
            deleteSectionCourseIds
        }
        return payload
    }

    const onSave = async () => {
        setLoading(true)
        try {
            const payload = preparePayloadForSaving()
            editSection.mutate(payload)
        } catch (err) {
            handleError(err)
        }
    }

    const onDeleteSection = (id: string | number) => {
        setSections(sections.filter((item) => item.sectionId !== id))
    }

    const showAddCoursesPopup = useCallback(() => {
        addCoursesPopup.open()
    }, [addCoursesPopup])

    const saveCoursesSuccess = (courses: Array<Course.Course & {sectionCourseId: number; sectionId: number}>) => {
        const newSections = cloneDeep(sections)
        const section = newSections.find((item) => item.sectionId === courses[0].sectionId)
        section.courses = courses.map((course) => {
            return {
                ...course,
                deleteHtml: (
                    <div
                        onClick={() => {
                            onDeleteCourse(course.courseId, course.sectionId)
                        }}
                        className={styles.trashButton}>
                        <Icon icon="TRASH_LINE" />
                    </div>
                )
            }
        })
        setSections(newSections)
    }

    const handleAddNewCourse = useCallback(
        (section: SectionDetail) => {
            setCurrentSection(section)
            showAddCoursesPopup()
        },
        [showAddCoursesPopup]
    )

    const isChanged = useMemo(() => {
        if (initialSections.length !== sections.length) {
            return true
        }
        const courses = sections.reduce((courses, section) => {
            courses = [...courses, ...section.courses]
            return courses
        }, [])
        const initialCourses = initialSections.reduce((courses, section) => {
            courses = [...courses, ...section.courses]
            return courses
        }, [])
        if (courses.length !== initialCourses.length) {
            return true
        }
        return false
    }, [initialSections, sections])

    return (
        <>
            {sections.map((section) => {
                return (
                    <div style={{marginBottom: 20}} key={section.sectionId}>
                        <Row gutter={[12, 12]}>
                            <Col span={24}>
                                <div>
                                    <FormLabel label="Section title" />
                                    <BaseInput
                                        placeholder=""
                                        onChange={(text) => {
                                            section.sectionTitle = text
                                            setSections(cloneDeep(sections))
                                        }}
                                        value={section.sectionTitle}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <KlassappTableHeader
                            isShowAction={isShowTableHeaderAction}
                            actions={tableHeaderActions}
                            page={page}
                            isShowAllRecords={true}
                            total={section.courses?.length ?? 0}
                            defaultPageSize={pageSize}
                            onChangePage={props.onChangePage}
                            onChangeRowPerPage={props.onChangeRowPerPage}
                        />
                        <KlassappTable
                            columns={columns}
                            fields={fields}
                            data={section.courses}
                            menuActions={[]}
                            isLoading={isLoading}
                            allFields={allFields}
                            isShowCheckedColumn={false}
                            orderField={orderField}
                            onChangeFields={props.onChangeFields}
                            onChangeAllFields={props.onChangeAllFields}
                            onUpdateRowData={props.onUpdateRowData}
                            onClickSortColumn={props.onClickSortColumn}
                            onUpdateTableData={props.onUpdateTableData}
                            onDraggableColumn={props.onDraggableColumn}
                        />
                        <div className={styles.actionWrap}>
                            <BaseButton
                                title="Delete Section"
                                variant="secondary"
                                icon={<Icon icon="DELETE" className={styles.deleteIcon} />}
                                uppercase={false}
                                onClick={() => onDeleteSection(section.sectionId)}
                            />
                            <AddItemCircleButton
                                title="Add Courses"
                                className={styles.addCourses}
                                onClick={() => handleAddNewCourse(section)}
                            />
                        </div>

                        {addCoursesPopup.isVisible && currentSection && (
                            <AddCoursesPopup
                                isShow={addCoursesPopup.isVisible}
                                onClose={addCoursesPopup.close}
                                selectedCourses={currentSection.courses}
                                sectionId={currentSection.sectionId}
                                saveCoursesSuccess={saveCoursesSuccess}
                            />
                        )}
                    </div>
                )
            })}
            <div className={styles.line} />
            <div>
                <BaseButton
                    title="Add Section"
                    icon={<Icon icon="PLUS" />}
                    variant="secondary"
                    onClick={addSection}
                    className={styles.addSectionBtn}
                />
            </div>
            <div className={styles.saveActionWrap}>
                <BaseButton
                    title="Save"
                    loading={loading}
                    disabled={!isChanged}
                    onClick={isChanged ? onSave : undefined}
                />
            </div>
            <BaseLoading isShow={isFetchingSections} />
        </>
    )
}

export default KlassappTableHOC(SectionView)
