import cx from "classnames"
import React, {useState} from "react"
import styles from "./PollPopupV2.module.css"
import {Icon} from "components/Icon"
import {Radio} from "antd"
import {RadioButton} from "components/inputs"
import {BaseInput} from "components/inputs"
import {Label} from "components/inputs/Label"
import {BaseButton, SecondaryButton} from "components/buttons"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {v4 as uuid} from "uuid"
import {chatServiceV2} from "services"
import {handleErrorChat} from "helpers"
import {BasePopup} from "components"
import {Communication} from "types/communication"

type Props = {
    isShow: boolean
    onClosePopup: () => void
    roomId: number
}

const MAX_OPTIONS = 10
const POLL_CLOSE_PERIODS = [
    {value: 300, text: "5min"},
    {value: 600, text: "10min"},
    {value: 900, text: "15min"},
    {value: 1800, text: "30min"},
    {value: 3600, text: "1hr"},
    {value: 86400, text: "1day"}
]

const INITIAL_POLL = {
    type: Communication.PollTypes.Single,
    question: "",
    correctAnswer: null,
    explanation: "",
    closePeriod: null
}
const INITIAL_POLL_OPTION = {
    id: uuid(),
    option: "",
    correct: false
}
const INITIAL_ERRORS = {
    question: "",
    options: []
}

export default function PollPopupV2(props: Props) {
    const {isShow, onClosePopup, roomId} = props

    const [errors, setErrors] = useState(INITIAL_ERRORS)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [options, setOptions] = useState([INITIAL_POLL_OPTION])
    const [poll, setPoll] = useState(INITIAL_POLL)

    const onChangePollData = (key, value) => {
        setPoll({...poll, [key]: value})
    }

    const onChangeOption = (index, value) => {
        const newOptions = [...options]
        newOptions[index] = {...newOptions[index], option: value}
        setOptions(newOptions)
    }

    const addOption = () => {
        if (options.length >= MAX_OPTIONS) {
            return
        }
        setOptions([...options, {id: uuid(), option: "", correct: false}])
    }

    const removeOption = (index) => {
        const newOptions = [...options]
        newOptions.splice(index, 1)
        setOptions(newOptions)
    }

    const handlerPeriod = (value) => {
        let newPeriod = value
        if (poll.closePeriod === value) {
            newPeriod = null
        }
        onChangePollData("closePeriod", newPeriod)
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }
        const items: any = reorder(options, result.source.index, result.destination.index)
        setOptions(items)
    }

    const isValid = (payload) => {
        const newErrors: any = {
            question: "",
            options: []
        }
        if (payload.type === Communication.PollTypes.Quiz && payload.correctAnswer === -1) {
            newErrors.options.push("Please select correct answer")
        }
        if (payload.options.length <= 1) {
            newErrors.options.push("Please add at least 2 options")
        }
        if (!payload.question) {
            newErrors.question = "Please enter question"
        }
        if (payload.options.some((option) => !option)) {
            newErrors.options.push("Please enter option")
        }
        if (payload.options.length !== new Set(payload.options).size) {
            newErrors.options.push("Please enter unique option")
        }
        setErrors(newErrors)
        return newErrors.options.length === 0 && !newErrors.question
    }

    const createPoll = async () => {
        try {
            setIsSubmitting(true)

            const payload = {
                ...poll,
                roomId: roomId,
                options: options.map(({option}) => option),
                correctAnswer: options.findIndex(({correct}) => correct)
            }
            if (poll.type !== Communication.PollTypes.Quiz) {
                delete payload.correctAnswer
                delete payload.explanation
            }
            if (!poll.closePeriod) {
                delete payload.closePeriod
            }
            if (!isValid(payload)) {
                setIsSubmitting(false)
                return
            }
            await chatServiceV2.createPoll(payload)
            onClose()
        } catch (e) {
            handleErrorChat(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onClose = () => {
        setPoll(INITIAL_POLL)
        setOptions([INITIAL_POLL_OPTION])
        setErrors(INITIAL_ERRORS)
        onClosePopup()
    }

    const handleCheck = (index) => {
        const newOptions = options.map((option, i) => ({
            ...option,
            correct: i === index
        }))
        setOptions(newOptions)
    }

    return (
        <BasePopup
            isShow={isShow}
            onClose={onClose}
            width="60vw"
            isShowLeftSide={false}
            closable={false}
            destroyOnClose>
            <div className={styles.titleWrap}>
                <h2 className={styles.title}>Create Poll</h2>
            </div>
            <div className={styles.pollTypeWrapper}>
                <Radio.Group
                    name="type"
                    className={styles.radioGroup}
                    onChange={(event) => onChangePollData("type", event.target.value)}
                    value={poll.type}>
                    <Radio className={styles.radioItem} value={"single"}>
                        Single
                    </Radio>
                    <Radio className={styles.radioItem} value={"multiple"}>
                        Multiple
                    </Radio>
                    <Radio className={styles.radioItem} value={"quiz"}>
                        Quiz
                    </Radio>
                </Radio.Group>
            </div>
            {poll.type === Communication.PollTypes.Quiz && (
                <p className={styles.textSecondary}>
                    Polls type Quiz have one correct answer. User can't revoke their answers
                </p>
            )}
            <div className={styles.row}>
                <div className={styles.item}>
                    <Label text="Question" required>
                        <BaseInput
                            onChange={(newValue) => onChangePollData("question", newValue)}
                            placeholder="Question"
                            value={poll.question}
                            className={cx({[styles.error]: !!errors["question"]})}
                        />
                    </Label>
                </div>
                <div className={styles.item}>
                    <Label text="Poll options" required>
                        {null}
                    </Label>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={"options"}>
                            {(droppableProvided, droppableSnapshot) => (
                                <div
                                    className={cx(styles.optionsContainer, {
                                        [styles.dragging]: droppableSnapshot.isDraggingOver
                                    })}
                                    ref={droppableProvided.innerRef}
                                    {...droppableProvided.droppableProps}>
                                    {options.map((option, index) => (
                                        <Draggable
                                            draggableId={`option-${option.id}`}
                                            index={index}
                                            key={`option-${option.id}`}>
                                            {(provided, snapshot) => (
                                                <div
                                                    className={cx(styles.optionItem, {
                                                        [styles.draggingItem]: snapshot.isDragging
                                                    })}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    {poll.type === Communication.PollTypes.Quiz && (
                                                        <RadioButton
                                                            name="correctAnswer"
                                                            type="radio"
                                                            onClick={() => handleCheck(index)}
                                                        />
                                                    )}
                                                    <BaseInput
                                                        onChange={(newValue) => onChangeOption(index, newValue)}
                                                        placeholder="Option"
                                                        value={option.option}
                                                    />
                                                    <span className={cx(styles.iconWrapper, styles.cursorMove)}>
                                                        <Icon icon="UNORDER_LIST" />
                                                    </span>
                                                    <span
                                                        className={styles.iconWrapper}
                                                        onClick={() => removeOption(index)}>
                                                        <Icon icon="CLOSE" />
                                                    </span>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {errors.options.length > 0 && (
                        <ul className={styles.errorOptionsList}>
                            {errors.options.map((error, index) => (
                                <li className={styles.textError} key={index}>
                                    {error}
                                </li>
                            ))}
                        </ul>
                    )}
                    {options.length < MAX_OPTIONS && (
                        <SecondaryButton
                            title="Add Option"
                            icon={<Icon icon="ADD_CIRCLE_BLUE" className={styles.addIcon} />}
                            onClick={addOption}
                            disabled={options.length >= MAX_OPTIONS}
                            className={styles.addOption}
                            bold={false}
                        />
                    )}
                </div>
                <div className={styles.item}>
                    <Label text="Close period">{null}</Label>
                    <div className={styles.closePeriodContainer}>
                        {POLL_CLOSE_PERIODS.map((period) => (
                            <div
                                key={`period-${period.value}`}
                                className={cx(styles.periodItem, {
                                    [styles.periodItemActive]: period.value === poll.closePeriod
                                })}
                                onClick={() => handlerPeriod(period.value)}>
                                {period.text}
                            </div>
                        ))}
                    </div>
                    <p className={styles.textSecondary}>
                        The poll will be closed automatically after the selected time (by default the poll will remain
                        open until user closes it)
                    </p>
                </div>
                {poll.type === Communication.PollTypes.Quiz && (
                    <div className={styles.item}>
                        <Label text="Explanation">
                            <BaseInput
                                onChange={(newValue) => onChangePollData("explanation", newValue)}
                                placeholder="Explanation"
                                value={poll.explanation}
                            />
                        </Label>
                        <p className={styles.textSecondary}>Users will see this text after choosing the answer</p>
                    </div>
                )}
                <div className={styles.actionButtons}>
                    <SecondaryButton title="Cancel" onClick={onClose} className={styles.addOption} />
                    <BaseButton
                        title="SAVE"
                        onClick={createPoll}
                        disabled={options.length === 1}
                        className={styles.addOption}
                        loading={isSubmitting}
                    />
                </div>
            </div>
        </BasePopup>
    )
}
