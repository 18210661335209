import React, {useEffect} from "react"
import {Checkbox, Col, Divider, Radio, Row} from "antd"
import {BaseInput} from "components/inputs/BaseInput"
import {BaseTextArea} from "components/inputs/TextArea"
import {BaseDatePicker} from "components/DateTimePicker"
import {KlassDropdown} from "components/Select"
import {BaseButton, SecondaryButton} from "components/buttons"
import styles from "./PaymentPlanForm.module.css"
import moment from "moment"
import {FormLabel} from "components/Form"
import {useTranslation} from "react-i18next"
import {LedgerAccount, LedgerAccountType} from "types/student-account/ledger-accounts"
import {studentAccountService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import TermSelect from "components/TermSelect"
import cx from "classnames"
import NumberFormat from "react-number-format"
import {useModel} from "hooks"
import {formatCurrency, getFullName, toDateOnly} from "helpers"
import {
    FREQUENCY_OPTIONS,
    PaymentPlan,
    PaymentPlanFrequency,
    PaymentPlanStatus,
    PaymentPlanType,
    PaymentReminder
} from "types/student-account/payment-plan"
import LedgerAccountSelect from "components/LedgerAccountSelect"
import StudentChargeSelect from "components/StudentChargeSelect"
import {sumBy} from "lodash"
import {StudentCharge} from "types/student-account/student-ledger"

const currentYear = moment().year()
const yearOptions = [-1, 0, 1, 2, 3, 4, 5, 6].map((index) => ({
    value: currentYear + index,
    label: `${currentYear + index} - ${currentYear + index + 1}`
}))

type Props = {
    profileId: number
    paymentPlan: Partial<PaymentPlan>
    setPaymentPlan: React.Dispatch<React.SetStateAction<Partial<PaymentPlan>>>
    isEditing: boolean
    isSaving: boolean
    updatePlanStatus: (status: PaymentPlanStatus) => void
    onClickEdit: () => void
    onClickDelete: () => void
    onClickCancel: () => void
    onClickSave: () => void
}

export function PaymentPlanForm({
    profileId,
    paymentPlan,
    setPaymentPlan,
    isEditing,
    isSaving,
    updatePlanStatus,
    onClickEdit,
    onClickDelete,
    onClickCancel,
    onClickSave
}: Props) {
    const {t} = useTranslation(["studentAccount", "common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const [reminders, setReminders] = React.useState<PaymentReminder[]>([])

    useEffect(() => {
        ;(async function getPaymentReminders() {
            try {
                const {data} = await studentAccountService.getPaymentReminders({
                    range: {
                        page: 1,
                        pageSize: UNLIMITED_PAGE_SIZE
                    }
                })
                setReminders(data)
            } catch (error) {
                console.error(error)
            }
        })()
    }, [])

    useEffect(() => {
        if (paymentPlan.type === PaymentPlanType.MinimumAmount) {
            const numberOfPayments = paymentPlan.minPaymentAmount
                ? Math.ceil((paymentPlan.currentBalance || 0) / paymentPlan.minPaymentAmount)
                : 0
            setPaymentPlan((prev) => ({...prev, numberOfPayments}))
        }
    }, [setPaymentPlan, paymentPlan.type, paymentPlan.minPaymentAmount, paymentPlan.currentBalance])

    const paymentAmount =
        paymentPlan.type === PaymentPlanType.MinimumAmount
            ? paymentPlan.minPaymentAmount
            : paymentPlan.numberOfPayments
            ? (paymentPlan.currentBalance || 0) / paymentPlan.numberOfPayments
            : 0

    const finalPayment = paymentPlan.firstPaymentDue ? moment(toDateOnly(paymentPlan.firstPaymentDue)) : moment()
    if (paymentPlan.numberOfPayments > 0) {
        switch (paymentPlan.frequency) {
            case PaymentPlanFrequency.Weekly:
                finalPayment.add(paymentPlan.numberOfPayments - 1, "week")
                break
            case PaymentPlanFrequency.BiWeekly:
                finalPayment.add((paymentPlan.numberOfPayments - 1) * 2, "week")
                break
            case PaymentPlanFrequency.Monthly:
                finalPayment.add(paymentPlan.numberOfPayments, "month")
                break
        }
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.bodyWrap}>
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <FormLabel label="Payment Plan ID" isRequired />
                        <BaseInput
                            readOnly={!isEditing}
                            value={paymentPlan.code}
                            onChange={(code) => setPaymentPlan((prev) => ({...prev, code}))}
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Title" isRequired />
                        <BaseInput
                            readOnly={!isEditing}
                            value={paymentPlan.title}
                            onChange={(title) => setPaymentPlan((prev) => ({...prev, title}))}
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Charges" />
                        <StudentChargeSelect
                            readOnly={!isEditing}
                            profileId={profileId}
                            isMulti
                            isClearable
                            value={paymentPlan.charges}
                            onChange={(options?: StudentCharge[]) =>
                                setPaymentPlan((prev) => {
                                    const totalChargeAmount = sumBy(options || [], "amount")
                                    const newData: Partial<PaymentPlan> = {
                                        ...prev,
                                        charges: options || [],
                                        chargeIds: (options || []).map((option) => option.chargeId),
                                        originalAmount: totalChargeAmount
                                    }
                                    if (!prev.accountId) {
                                        newData.accountId = options?.[0]?.accountId
                                        newData.account = options?.[0]?.account
                                    }
                                    if (!paymentPlan.paymentPlanId) {
                                        newData.currentBalance = totalChargeAmount
                                    }
                                    return newData
                                })
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Academic Year" isRequired />
                        <KlassDropdown
                            readOnly={!isEditing}
                            options={yearOptions}
                            labelKey="label"
                            valueKey="value"
                            placeholder={t("common:selectField.placeholder")}
                            value={yearOptions.find((option) => option.value === paymentPlan.academicYear)}
                            onChange={(option) => setPaymentPlan((prev) => ({...prev, academicYear: option.value}))}
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Type" isRequired />
                        <LedgerAccountSelect
                            readOnly={!isEditing}
                            active
                            types={LedgerAccountType.Charges}
                            value={paymentPlan.account}
                            onChange={(account: LedgerAccount) =>
                                setPaymentPlan((prev) => ({
                                    ...prev,
                                    account,
                                    accountId: account?.accountId || null
                                }))
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Term" />
                        <TermSelect
                            readOnly={!isEditing}
                            isClearable
                            value={paymentPlan.term}
                            onChange={(term) =>
                                setPaymentPlan((prev) => ({
                                    ...prev,
                                    term,
                                    termId: term?.id || null
                                }))
                            }
                        />
                    </Col>
                    <Col span={24}>
                        <FormLabel label="Description" />
                        <BaseTextArea
                            readOnly={!isEditing}
                            className={styles.textarea}
                            value={paymentPlan.description}
                            onChange={(description) => setPaymentPlan((prev) => ({...prev, description}))}
                        />
                    </Col>
                </Row>
            </div>

            <Divider className={styles.divider} />

            <div className={styles.bodyWrap}>
                <Row gutter={[20, 12]} align="bottom">
                    <Col span={24}>
                        <Radio.Group
                            value={paymentPlan.type}
                            onChange={(event) =>
                                !isEditing
                                    ? undefined
                                    : setPaymentPlan((prev) => ({
                                          ...prev,
                                          type: event.target.value
                                      }))
                            }
                            className={styles.radioGroup}>
                            <Radio
                                value={PaymentPlanType.MinimumAmount}
                                className={cx(styles.radioGroup__item, {
                                    [styles.active]: paymentPlan.type === PaymentPlanType.MinimumAmount
                                })}>
                                Minimum payment amount
                            </Radio>
                            <Radio
                                value={PaymentPlanType.NumberPayments}
                                className={cx(styles.radioGroup__item, {
                                    [styles.active]: paymentPlan.type === PaymentPlanType.NumberPayments
                                })}>
                                Number of payments
                            </Radio>
                        </Radio.Group>
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Original amount" isRequired />
                        <NumberFormat
                            readOnly={!isEditing}
                            className={styles.numberInput}
                            thousandSeparator
                            prefix="$"
                            min={0}
                            allowNegative={false}
                            value={paymentPlan.originalAmount}
                            onValueChange={({floatValue}) =>
                                setPaymentPlan((prev) => ({
                                    ...prev,
                                    originalAmount: floatValue
                                }))
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Current balance" isRequired />
                        <NumberFormat
                            readOnly={!isEditing}
                            className={styles.numberInput}
                            thousandSeparator
                            prefix="$"
                            min={0}
                            allowNegative={false}
                            value={paymentPlan.currentBalance}
                            onValueChange={({floatValue}) =>
                                setPaymentPlan((prev) => ({
                                    ...prev,
                                    currentBalance: floatValue
                                }))
                            }
                        />
                    </Col>
                    {paymentPlan.type === PaymentPlanType.MinimumAmount ? (
                        <Col span={8}>
                            <FormLabel label="Minimum payment amount" isRequired />
                            <NumberFormat
                                readOnly={!isEditing}
                                className={styles.numberInput}
                                thousandSeparator
                                prefix="$"
                                min={0}
                                allowNegative={false}
                                value={paymentPlan.minPaymentAmount}
                                onValueChange={({floatValue}) =>
                                    setPaymentPlan((prev) => ({
                                        ...prev,
                                        minPaymentAmount: floatValue
                                    }))
                                }
                            />
                        </Col>
                    ) : (
                        <Col span={8} />
                    )}
                    <Col span={8}>
                        <FormLabel label="Number of payments" isRequired />
                        <BaseInput
                            type="number"
                            min={0}
                            disabledArrow
                            readOnly={!isEditing || paymentPlan.type === PaymentPlanType.MinimumAmount}
                            value={paymentPlan.numberOfPayments}
                            onChange={(value) =>
                                setPaymentPlan((prev) => ({
                                    ...prev,
                                    numberOfPayments: value ? +value : undefined
                                }))
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="First Payment Due Date" isRequired />
                        <BaseDatePicker
                            readOnly={!isEditing}
                            allowClear={false}
                            value={
                                paymentPlan.firstPaymentDue
                                    ? moment(toDateOnly(paymentPlan.firstPaymentDue))
                                    : undefined
                            }
                            onChange={(value) =>
                                setPaymentPlan((prev) => ({
                                    ...prev,
                                    firstPaymentDue: value?.format("YYYY-MM-DD")
                                }))
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Frequency" isRequired />
                        <KlassDropdown
                            readOnly={!isEditing}
                            isClearable={false}
                            options={FREQUENCY_OPTIONS}
                            value={FREQUENCY_OPTIONS.find((option) => option.id === paymentPlan.frequency)}
                            onChange={(option) =>
                                setPaymentPlan((prev) => ({
                                    ...prev,
                                    frequency: option.id
                                }))
                            }
                        />
                    </Col>
                    <Col span={8}>
                        <FormLabel label="Payment reminders" />
                        <KlassDropdown
                            readOnly={!isEditing}
                            isMulti
                            options={reminders}
                            valueKey="paymentReminderId"
                            getOptionLabel={(option: PaymentReminder) =>
                                t(`paymentPlan.reminderType.${option.type}`, {count: option.numDays})
                            }
                            value={paymentPlan.reminders || []}
                            onChange={(options) =>
                                setPaymentPlan((prev) => ({
                                    ...prev,
                                    reminders: options || [],
                                    reminderIds: (options || []).map((option) => option.paymentReminderId)
                                }))
                            }
                        />
                    </Col>
                </Row>
            </div>

            <Divider className={styles.divider} />

            <p className={styles.footerDesc}>
                Payment plan is for <b>{t("paymentPlan.numPayments", {count: paymentPlan.numberOfPayments})}</b> of{" "}
                <b>{formatCurrency(paymentAmount)}</b> {paymentPlan.frequency}. Final payment is at{" "}
                {finalPayment.format(userDateFormat)}.
            </p>

            <div className={styles.footerAction}>
                {!isEditing && (
                    <p className={styles.footerAction__desc}>
                        Updated on{" "}
                        <span className={styles.footerAction__bold_600}>
                            {moment(paymentPlan.updatedAt).format(userDateFormat)}
                        </span>{" "}
                        by <span className={styles.footerAction__bold_600}>{getFullName(paymentPlan.updatedBy)}</span>
                    </p>
                )}
                <div className={styles.footerAction__button}>
                    {isEditing ? (
                        <>
                            <SecondaryButton title="Cancel" onClick={onClickCancel} className={styles.cancelBtn} />
                            <BaseButton title="Save" onClick={onClickSave} loading={isSaving} />
                        </>
                    ) : (
                        <>
                            {paymentPlan.status === PaymentPlanStatus.Active ? (
                                <SecondaryButton
                                    title="Cancel Plan"
                                    onClick={() => updatePlanStatus(PaymentPlanStatus.Canceled)}
                                    loading={isSaving}
                                />
                            ) : (
                                <SecondaryButton
                                    title="Reactivate Plan"
                                    onClick={() => updatePlanStatus(PaymentPlanStatus.Active)}
                                    loading={isSaving}
                                />
                            )}
                            <SecondaryButton title="Delete Plan" onClick={onClickDelete} />
                            <BaseButton title="Edit Plan" onClick={onClickEdit} />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
