/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer} from "react"
import {Status, StudentFilterV2, StudentForm, StudentListTable} from "sections/shared/students"
import {checkPermission} from "helpers"
import {Permissions} from "types/permission"
import {AppliedFilter, FieldOperator, FilterKey} from "types/filter"
import {useModel} from "hooks"
import {isEmpty, sumBy} from "lodash"
import {BaseDepartmentId} from "types/departments"
import styles from "./FinancialAidStaffVerificationTab.module.css"
import {CardItem} from "./parts/CardItem"
import {useQuery} from "@tanstack/react-query"
import {finAidService} from "services"
import {FinAidOfferStatus} from "types/fin-aid/fin-aid"

const INITIAL_FILTER: AppliedFilter = {
    search: "",
    filters: {
        includeArchive: {operator: FieldOperator.Equal, value: false}
    }
}

type State = {
    activeStudent: any
    staffs: any
    statsInfo: any
    studentForm: StudentForm
    studentFormSearch: string
    isExporting: boolean
    exportFileType: any
}

export function FinancialAidStaffPackagingTab() {
    const model = useModel()
    const storageFilter = model.getStorageFilter(FilterKey.FinAidPage_Verifications)
    const departmentId = BaseDepartmentId.FinancialAid
    const initialState: State = {
        activeStudent: {},
        staffs: [],
        statsInfo: [],
        studentFormSearch: !isEmpty(storageFilter) ? storageFilter.search : "",
        studentForm: !isEmpty(storageFilter?.filters) ? storageFilter.filters : {},
        isExporting: false,
        exportFileType: null
    }

    function reducer(state, action) {
        return {...state, ...action}
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const canShowAllStudents = React.useMemo(
        () => checkPermission({staff: [Permissions.Staff.FinancialAid.Director.View]}, model),
        [model]
    )

    const canHaveAccessOthers = React.useMemo(
        () => checkPermission({staff: [Permissions.Staff.FinancialAid.Students.PermissionsToAccessOthers.View]}, model),
        [model]
    )

    const statisticsQuery = useQuery(
        ["statistics", state.studentForm?.faAwardYear],
        async () => await finAidService.getPackagingStatistics(state.studentForm?.faAwardYear),
        {
            initialData: []
        }
    )

    const onApplyFilter = (payload: AppliedFilter) => {
        const {filters, search} = payload
        dispatch({studentForm: filters, studentFormSearch: search})
    }

    return (
        <div>
            <div className={styles.statusInfoWrap}>
                <div className={styles.statusColItem}>
                    <CardItem label="All" count={sumBy(statisticsQuery.data, "itemCount")} />
                </div>
                <div className={styles.statusColItem}>
                    <CardItem
                        label="Packaged"
                        count={
                            statisticsQuery.data.find((record) => record.status === FinAidOfferStatus.Packaged)
                                ?.itemCount ?? 0
                        }
                    />
                </div>
                <div className={styles.statusColItem}>
                    <CardItem
                        label="Certified"
                        count={
                            statisticsQuery.data.find((record) => record.status === FinAidOfferStatus.Certified)
                                ?.itemCount ?? 0
                        }
                    />
                </div>
            </div>
            <div className="mt-24">
                <StudentFilterV2
                    status={Status.finAidStatus}
                    canShowAllStudents={canShowAllStudents}
                    canHaveAccessOthers={canHaveAccessOthers}
                    filterKey={FilterKey.FinAidPage_Packagings}
                    departmentId={departmentId}
                    viewType="standard"
                    showExport={false}
                    hideLeadRecordAction
                    hideLeadSource
                    hideShowDueOnly
                    hideChecklist
                    initialFilter={INITIAL_FILTER}
                    onApplyFilter={onApplyFilter}
                    onClearFilter={() => onApplyFilter({filters: {}, search: ""})}
                />
                <StudentListTable
                    departmentId={departmentId}
                    studentForm={state.studentForm}
                    studentFormSearch={state.studentFormSearch}
                    updateState={dispatch}
                    canShowAllStudents={canShowAllStudents}
                    canHaveAccessOthers={canHaveAccessOthers}
                    filterMemoryKey={FilterKey.FinAidPage_Packagings}
                    exportFileType={state.exportFileType}
                    isFinancialPackagingTab
                    useNewFilter
                />
            </div>
        </div>
    )
}
