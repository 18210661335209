import React from "react"
import {Icon, IconType} from "components/Icon"
import styles from "./StudentTableIconWithIndicator.module.css"

type Props = {
    onClick?: (e) => void
    icon: IconType
    showIndicator?: boolean
}

const StudentTableIconWithIndicator: React.FC<Props> = ({onClick, icon, showIndicator = false}) => {
    return (
        <div className="relative" onClick={onClick}>
            <Icon icon={icon} className={styles.tableIcon} />
            {showIndicator && <div className={styles.indicator} />}
        </div>
    )
}

export default StudentTableIconWithIndicator
