import React from "react"
import {Icon} from "components/Icon"
import styles from "./StudentInfo.module.css"
import {AcademicInstructional} from "types/academicInstructional"
import {getFullName} from "helpers"
import cx from "classnames"

type Props = {
    student: AcademicInstructional.StudentInfo
    disabled?: boolean
    index: number
    onSelected: (student: AcademicInstructional.StudentInfo) => void
}

export function StudentInfo(props: Props) {
    const {student, onSelected, disabled, index} = props

    const renderStudentAvatar = () => {
        let studentPhoto = "/image/DefaultAvatar.png"
        if (student?.photo) {
            studentPhoto = student.photo["64"]
        }
        return (
            <div>
                <img src={studentPhoto} className={styles.studentItem__img} alt="" />
            </div>
        )
    }

    return (
        <div
            onClick={() => onSelected(student)}
            className={styles.studentItem}
            style={{background: index % 2 !== 0 ? "#fbfbfb" : "white"}}>
            {renderStudentAvatar()}
            <span className={styles.studentItem__info}>
                {student.customProfileId} {getFullName(student)}
            </span>
            <div className={styles.buttonNext}>
                <Icon icon="ARROW_CIRCLE_RIGHT" className={styles.attendanceTickIcon} />
            </div>
        </div>
    )
}
