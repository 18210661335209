import React from "react"
import cx from "classnames"
import {Icon} from "components/Icon"
import {BarChart} from "components/Chart"
import styles from "./Revenue.module.css"
import {useTranslation} from "react-i18next"

export function Revenue(props) {
    const {t} = useTranslation(["student"])
    const chartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        datasets: [
            {
                type: "line",
                borderColor: "#b3246d",
                borderWidth: 2,
                fill: false,
                data: [500, 400, 450, 440, 450, 480, 520, 500, 470, 450, 500, 550]
            },
            {
                type: "line",
                borderColor: "#1e90ff",
                borderWidth: 2,
                fill: false,
                data: [450, 350, 380, 390, 400, 390, 450, 420, 400, 430, 450, 520]
            },
            {
                data: [220, 170, 170, 220, 250, 200, 100, 200, 120, 220, 140, 146],
                backgroundColor: "#939393"
            },
            {
                data: [200, 190, 190, 220, 240, 180, 120, 180, 135, 200, 180, 150],
                backgroundColor: "#ff8a38"
            },
            {
                data: [200, 190, 190, 220, 240, 180, 120, 180, 135, 200, 180, 150],
                backgroundColor: "#0f4880"
            },
            {
                data: [200, 190, 190, 220, 240, 180, 120, 180, 135, 200, 180, 150],
                backgroundColor: "#ff349b"
            },
            {
                data: [200, 190, 190, 220, 240, 180, 120, 180, 135, 200, 180, 150],
                backgroundColor: "#18a957"
            }
        ]
    }

    const options = {
        tooltips: {
            enabled: true
        },
        plugins: {
            datalabels: {
                display: false
            }
        }
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.headerWrap}>
                <p className={styles.title}>{t("student.workspace.revenue")}</p>
                <div className={styles.barInfoWrap}>
                    <div className={styles.barInfoItem}>
                        <Icon icon="CHECKBOX_CHECKED" color="#939393" />
                        <p className={styles.barInfoItem__title}>PELL</p>
                    </div>
                    <div className={styles.barInfoItem}>
                        <Icon icon="CHECKBOX_CHECKED" color="#ff8a38" />
                        <p className={styles.barInfoItem__title}>SUB</p>
                    </div>
                    <div className={styles.barInfoItem}>
                        <Icon icon="CHECKBOX_CHECKED" color="#0f4880" />
                        <p className={styles.barInfoItem__title}>UNSUB</p>
                    </div>
                    <div className={styles.barInfoItem}>
                        <Icon icon="CHECKBOX_CHECKED" color="#ff349b" />
                        <p className={styles.barInfoItem__title}>SELFPAY</p>
                    </div>
                    <div className={styles.barInfoItem}>
                        <Icon icon="CHECKBOX_CHECKED" color="#18a957" />
                        <p className={styles.barInfoItem__title}>OTHERS</p>
                    </div>
                </div>
            </div>
            <div className={styles.lineInfoWrap}>
                <div className={styles.lineInfoItem}>
                    <div className={cx(styles.lineInfoItem__color, styles.actualColor)}></div>
                    <p className={styles.lineInfoItem__title}>{t("student.workspace.actualRevenue")}</p>
                </div>
                <div className={styles.lineInfoItem}>
                    <div className={cx(styles.lineInfoItem__color, styles.revenueColor)}></div>
                    <p className={styles.lineInfoItem__title}>{t("student.workspace.projectedRevenue")}</p>
                </div>
            </div>
            <BarChart data={chartData} options={options} />
        </div>
    )
}
