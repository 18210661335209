import {Checkbox, Col, Radio} from "antd"
import styles from "./DailyAttendance.module.css"
import {useCallback, useEffect, useState} from "react"
import RoundingRange from "./RoundingRange"
import {BaseButton, BaseLoading} from "components"
import {useTranslation} from "react-i18next"
import {AttendanceRoundingType} from "types/settings/general-setting"
import {handleError, toastError, toastSuccess} from "helpers"
import {cloneDeep, isEqual, pick} from "lodash"
import {useAttendanceRounding} from "hooks"

type DailyAttendanceProps = {}
export enum RoundingType {
    NoRounding = "NoRounding",
    Rounding = "Rounding"
}
const DailyAttendance = (props: DailyAttendanceProps) => {
    const [isRoundingEnabled, setIsRoundingEnabled] = useState(false)
    const {t} = useTranslation(["common"])
    const [currentData, setCurrentData] = useState([])

    const attendanceRounding = useAttendanceRounding({
        type: AttendanceRoundingType.Daily
    })

    useEffect(() => {
        setIsRoundingEnabled(attendanceRounding.isEnabled)
    }, [attendanceRounding.isEnabled])

    useEffect(() => {
        setCurrentData(cloneDeep(attendanceRounding.items.map((item, index) => ({...item, id: index}))))
    }, [attendanceRounding.items])

    const onClickSave = useCallback(async () => {
        for (let attendanceRounding of currentData) {
            if (
                !attendanceRounding.from?.toString()?.length ||
                !attendanceRounding.to?.toString()?.length ||
                !attendanceRounding.value?.toString()?.length
            ) {
                toastError("From, To, Value cannot be empty.")
                return
            }
        }
        try {
            await attendanceRounding.update({
                data: currentData.map((item) => {
                    return {
                        from: Number(item.from),
                        to: Number(item.to),
                        value: Number(item.value)
                    }
                }),
                enabled: isRoundingEnabled,
                type: AttendanceRoundingType.Daily
            })

            toastSuccess("Updated")
        } catch (error: any) {
            handleError(error)
        }
    }, [currentData, attendanceRounding, isRoundingEnabled])

    const onClickCancelBtn = useCallback(() => {
        setIsRoundingEnabled(attendanceRounding.isEnabled)
        setCurrentData(cloneDeep(attendanceRounding.items.map((item, index) => ({...item, id: index}))))
    }, [attendanceRounding.isEnabled, attendanceRounding.items])

    const isChangedTableData = () => {
        const fieldNames = ["from", "to", "value"]

        if (attendanceRounding.items.length !== currentData.length) {
            return true
        }

        for (let i = 0; i < attendanceRounding.items.length; i++) {
            const oldData = pick(attendanceRounding.items[i], fieldNames)
            const newData = pick(currentData[i], fieldNames)

            if (!isEqual(oldData, newData)) {
                return true
            }
        }
        return false
    }
    const isChanged = isRoundingEnabled !== attendanceRounding.isEnabled || isChangedTableData()

    return (
        <div className={styles.wrapper}>
            <span className={styles.title}>Daily attendance rounding</span>
            <Col span={24} className={styles.checkboxContainer}>
                <Radio.Group
                    name=""
                    className={styles.radioGroup}
                    onChange={(event) => {
                        setIsRoundingEnabled(event.target.value === RoundingType.Rounding)
                    }}
                    value={isRoundingEnabled ? RoundingType.Rounding : RoundingType.NoRounding}>
                    <Radio className={styles.radioItem} value={RoundingType.NoRounding}>
                        No Rounding
                    </Radio>
                    <Radio className={styles.radioItem} value={RoundingType.Rounding}>
                        Rounding
                    </Radio>
                </Radio.Group>
                {isRoundingEnabled ? <RoundingRange listData={currentData} setListData={setCurrentData} /> : null}
            </Col>
            <div className={styles.action}>
                <BaseButton
                    title={t("common:action.cancel")}
                    variant="secondary"
                    className={styles.cancelBtn}
                    disabled={!isChanged}
                    onClick={onClickCancelBtn}
                />
                <BaseButton
                    title={t("common:action.save")}
                    onClick={onClickSave}
                    disabled={!isChanged}
                    loading={attendanceRounding.isUpdating}
                />
            </div>
            <BaseLoading isShow={attendanceRounding.isUpdating || attendanceRounding.isFetching} />
        </div>
    )
}

export {DailyAttendance}
