/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdownProps} from "../Select"
import {KlassDropdown} from "components/inputs"
import {StudentCharge} from "types/student-account/student-ledger"
import styles from "./StudentChargeSelect.module.css"
import {formatDateOnly} from "helpers"
import {useModel} from "hooks"
import {studentLedgerService} from "services"
import {Order} from "types/common"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {formatCurrency} from "helpers"

type Props = Partial<KlassDropdownProps> & {
    profileId: number
}

const StudentChargeSelect: React.FC<Props> = ({profileId, ...props}) => {
    const {t} = useTranslation(["common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    const [charges, setCharges] = useState<StudentCharge[]>([])

    useEffect(() => {
        ;(async function loadCharges() {
            const {data} = await studentLedgerService.getCharges({
                filter: {
                    profileIds: [profileId]
                },
                range: {pageSize: UNLIMITED_PAGE_SIZE, page: 1},
                sort: {
                    orderBy: "tranxDate",
                    orderDir: Order.Desc
                },
                linkedEntities: true
            })
            setCharges(data)
        })()
    }, [profileId])

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={charges}
            valueKey="chargeId"
            labelKey="account.name"
            getOptionLabel={(option: StudentCharge) => (
                <span>
                    <span className={styles.id}>{option.chargeId}</span>
                    {option.account?.name}
                    <span className={styles.date}>{formatCurrency(option.amount)}</span>
                    <span className={styles.date}>({formatDateOnly(option.tranxDate, userDateFormat)})</span>
                </span>
            )}
        />
    )
}

export default StudentChargeSelect
