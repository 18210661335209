import React, {useContext, useEffect} from "react"
import {Tabs} from "antd"
import {useActiveTab, useDepartmentStudent, useCurrentProfilePermissions} from "hooks"
import {Screen} from "components/ui"
import {PaymentPlanTab, StudentLedgerTab} from "./parts"
import {TabHeader} from "components/Tab"
import {useTranslation} from "react-i18next"
import {StudentAccountStaffTab as StaffTab} from "../Staff/StudentAccountStaff"
import Disbursements from "../../FinancialAid/FinancialAidStudent/parts/FinAidAward/Disbursement/Disbursements"
import {useParams} from "react-router-dom"
import {BaseDepartmentId} from "types/departments"
import {getFullName} from "helpers"
import {AdvisorTitle, ModuleStudentHeader} from "uiKit"
import {StudentPaymentContext} from "context/StudentPaymentContext"
import StudentAccountPanelInfo from "sections/shared/student/StudentAccountPanelInfo/StudentAccountPanelInfo"
import {Permissions} from "types/permission"
import {Auth} from "types/auth"

const {TabPane} = Tabs

export enum StudentAccountStudentTab {
    StudentLedger = "student-ledger",
    PaymentPlan = "payment-plan",
    Disbursement = "disbursement",
    Refund = "refund",
    Scholarship = "scholarship"
}

export default function StudentAccountStudent(props) {
    const {t} = useTranslation(["studentAccount", "common"])
    const params = useParams<{profileId: string}>()
    const studentId = +params.profileId
    const [activeTabKey, onChangeTab] = useActiveTab(StudentAccountStudentTab.StudentLedger, "tab")
    const {setStudentId} = useContext(StudentPaymentContext)
    const {student} = useDepartmentStudent({studentId, departmentId: BaseDepartmentId.StudentAccount})
    const currentProfilePermissions = useCurrentProfilePermissions()

    useEffect(() => {
        setStudentId(studentId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId])

    const pageTitle = getFullName(student) || t("student.pageTitle")

    return (
        <Screen
            htmlTitle={pageTitle}
            header={{
                title: pageTitle,
                backUrl: `/student-account/staff?tab=${StaffTab.Students}`
            }}
            rightPanel={{
                type: "student",
                data: {
                    student,
                    departmentId: BaseDepartmentId.StudentAccount,
                    advisorTitle: AdvisorTitle.advisors,
                    isDetailButtonVisible: true,
                    detailUrl: "student-account",
                    fromPage: "student-account",
                    renderDepartmentInfo: (props) => <StudentAccountPanelInfo student={student} {...props} />
                }
            }}>
            <ModuleStudentHeader
                activeModule={Auth.ModuleNavigation.StudentAccount}
                profileId={studentId}
                userId={student?.userId}
                customUserId={student?.customUserId}
                isBackButtonActive
            />
            <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab}>
                {currentProfilePermissions.hasPermissions({
                    staff: [Permissions.Staff.StudentAccount.Students.StudentLedger.View],
                    student: [Permissions.Student.StudentAccount.Students.StudentLedger.View]
                }) && (
                    <TabPane
                        tab={<TabHeader title={t("student.tab.studentledger")} />}
                        key={StudentAccountStudentTab.StudentLedger}>
                        {activeTabKey === StudentAccountStudentTab.StudentLedger && <StudentLedgerTab {...props} />}
                    </TabPane>
                )}
                {currentProfilePermissions.hasPermissions({
                    staff: [Permissions.Staff.StudentAccount.Students.PaymentPlan.View],
                    student: [Permissions.Student.StudentAccount.Students.PaymentPlan.View]
                }) && (
                    <TabPane
                        tab={<TabHeader title={t("student.tab.paymentplan")} />}
                        key={StudentAccountStudentTab.PaymentPlan}>
                        {activeTabKey === StudentAccountStudentTab.PaymentPlan && <PaymentPlanTab {...props} />}
                    </TabPane>
                )}
                {currentProfilePermissions.hasPermissions({
                    staff: [Permissions.Staff.StudentAccount.Students.FinAid.View],
                    student: [Permissions.Student.StudentAccount.Students.FinAid.View]
                }) && (
                    <TabPane
                        tab={<TabHeader title={t("student.tab.disbursement")} />}
                        key={StudentAccountStudentTab.Disbursement}>
                        {activeTabKey === StudentAccountStudentTab.Disbursement && (
                            <Disbursements studentId={studentId} />
                        )}
                    </TabPane>
                )}
                {/*
                <TabPane tab={<TabHeader title={t("student.tab.refund")} />} key={StudentAccountStudentTab.Refund}>
                    {activeTabKey === StudentAccountStudentTab.Refund && <RefundTab />}
                </TabPane>
                <TabPane
                    tab={<TabHeader title={t("student.tab.scholarship")} />}
                    key={StudentAccountStudentTab.Scholarship}>
                    {activeTabKey === StudentAccountStudentTab.Scholarship && <ScholarshipTab {...props} />}
                </TabPane>
                */}
            </Tabs>
        </Screen>
    )
}
