import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {majorService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

const queryCacheKey = "all-programs"
const INITIAL_DATA = []

export const useAllPrograms = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data: majors} = await majorService.getAll({
                sort: {orderBy: "name", orderDir: "asc"},
                filter: {},
                range: {pageSize: UNLIMITED_PAGE_SIZE, page: 1},
                linkedEntities: ["campus", "degreeLevel"]
            })
            return majors
        },
        {
            refetchOnMount: false
        }
    )

    return {
        programs: query.data ?? INITIAL_DATA,
        invalidateProgramsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
