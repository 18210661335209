import React, {useEffect} from "react"
import {BaseButton, Icon} from "components"
import {ActivityPanel} from "sections/shared/student"
import styles from "./ActivitiesAndWorkflowTriggers.module.css"
import {studentActivitiesService} from "services"
import {Button, Col, Row} from "antd"
import {useMutation} from "@tanstack/react-query"
import {handleError} from "helpers"
import {emitEvent} from "helpers/eventHelper"
import {EVENT_STUDENT_ACTIVITY_READ, EVENT_STUDENT_ACTIVITY_UNREAD} from "types/activity"

type Props = {
    studentId: number
    departmentId?: number
    onAdd: () => void
}

export function ActivitiesList(props: Props) {
    const {studentId, departmentId, onAdd} = props

    useEffect(() => {
        ;(async function markAsRead() {
            try {
                await studentActivitiesService.markAsRead(studentId)
                emitEvent(EVENT_STUDENT_ACTIVITY_READ, {profileId: studentId})
            } catch (error) {
                console.error(error)
            }
        })()
    }, [studentId])

    const markAsUnreadMutation = useMutation(
        async (_: any) => {
            const result = await studentActivitiesService.markAsUnread(studentId)
            emitEvent(EVENT_STUDENT_ACTIVITY_UNREAD, result)
        },
        {
            onSuccess: () => {},
            onError: (error) => handleError(error)
        }
    )

    return (
        <>
            <div className={styles.actionWrap}>
                <BaseButton onClick={onAdd} title="Add activity" className={styles.addAction} />
            </div>
            <div className="text-right mt-16">
                <Button type="text" onClick={markAsUnreadMutation.mutate} loading={markAsUnreadMutation.isLoading}>
                    <Row gutter={6} align="middle" className={styles.markUnreadButton}>
                        <Col>Mark as unread</Col>
                        <Col className="relative mt-4">
                            <Icon icon="CHECKMARK_CIRCLE" className={styles.activityIcon} />
                            <div className={styles.indicator} />
                        </Col>
                    </Row>
                </Button>
            </div>
            <ActivityPanel
                departmentId={departmentId}
                studentId={studentId}
                title=""
                wrapContent={false}
                showTitle={false}
                displayMode="panel"
            />
        </>
    )
}
