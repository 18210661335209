import React, {useReducer} from "react"
import {Col, Row} from "antd"
import {KlassDropdown} from "components/Select"
import {BaseDatePicker, BaseRangePicker} from "components/DateTimePicker"
import {BaseFilter} from "uiKit"
import styles from "./Filter.module.css"
import {formatCodeName} from "helpers"
import {useTranslation} from "react-i18next"
import ProgramSelect from "components/ProgramSelect"

function reducer(state, action) {
    return {...state, ...action}
}

export function Filter(props) {
    const {t} = useTranslation(["studentServices", "common"])

    const initialState = {
        filter: {
            search: "",
            campuses: [],
            programs: [],
            date: null
        }
    }

    const [{filter}, dispatchState] = useReducer(reducer, initialState)

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter}
        newFilter[key] = value
        dispatchState({filter: newFilter})
    }

    return (
        <BaseFilter isShowFilterClear>
            <Row gutter={[40, 32]}>
                <Col span={12}>
                    <BaseDatePicker placeholder="Date Range" value="" getOptionLabel={formatCodeName} />
                </Col>
                <Col span={12}>
                    <KlassDropdown options={[]} value={[]} placeholder="Campus" />
                </Col>
                <Col span={12}>
                    <ProgramSelect
                        value={filter.programs}
                        onChange={(newValue) => onChangeFilter("programs", newValue)}
                        isMulti
                        placeholder={t("filter.program")}
                    />
                </Col>
                <Col span={12}></Col>
                <Col span={12}>
                    <p className={styles.filter__title}>Student Status</p>
                    <BaseRangePicker placeholder={["Start Date", "End Date"]} value="" />
                </Col>
                <Col span={12}>
                    <p className={styles.filter__title}>Transactions</p>
                    <BaseRangePicker placeholder={["Start Date", "End Date"]} value="" />
                </Col>
            </Row>
        </BaseFilter>
    )
}
