import React from "react"
import {SecondaryButton} from "components/buttons"
import cx from "classnames"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import styles from "./ScholarshipItemTable.module.css"
import {tableData} from "./rawData"

type PageProps = {
    t: Function
    history?: any
}

type Props = KlassappTableProps & PageProps

type State = {
    filterData: any
}

class ScholarshipItemTable extends React.Component<Props, State> {
    async componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions}
        ])
        this.getData()
    }

    getPageTitle = () => {
        return "Document"
    }

    getFields = () => {
        return ["#", "Title", "Invoice", "Amount", "Paid", "Balance", "Due Date", "Paid Date", "Status"]
    }

    getColumns = () => {
        return [
            {
                title: "#",
                field: "index",
                style: {minWidth: "70px"}
            },
            {
                title: "Title",
                field: "title",
                style: {minWidth: "200px"}
            },
            {
                title: "Invoice#",
                field: "invoice",
                style: {minWidth: "150px"}
            },
            {
                title: "Amount",
                field: "amount",
                style: {minWidth: "120px"}
            },
            {
                title: "Paid",
                field: "paid",
                style: {minWidth: "150px"}
            },
            {
                title: "Balance",
                field: "balance",
                style: {minWidth: "150px"}
            },
            {
                title: "Due Date",
                field: "dueDate",
                // fieldType: "date",
                // format: "MM/DD/YYYY",
                style: {minWidth: "150px"}
            },
            {
                title: "Paid Date",
                field: "paidDate",
                style: {minWidth: "150px"}
            },
            {
                title: "Status",
                field: "statusHtml",
                style: {minWidth: "150px"}
            }
        ]
    }

    getTableHeaderActions = () => {
        return [
            {
                title: "Generate Invoice",
                icon: "FILE_TEXT",
                action: this.onClickGenerateInvoice
            }
        ]
    }

    onClickGenerateInvoice = async () => {}

    getParams = () => {}

    getData = () => {
        const newData = tableData.map((item: any, index) => {
            item.index = index + 1
            item.statusHtml = this.renderStatusHtml(item.status)
            return item
        })
        this.props.dispatch({data: newData, total: newData.length})
    }

    renderStatusHtml = (status) => {
        return (
            <div
                className={cx(styles.status, {
                    [styles.status__complete]: status === "complete",
                    [styles.status__overdue]: status === "overdue"
                })}>
                {status}
            </div>
        )
    }

    onClickRowItem = () => {}

    render() {
        const {
            isLoading,
            data,
            tableHeaderActions,
            page,
            total,
            pageSize,
            columns,
            fields,
            allFields,
            isShowTableHeaderAction
        } = this.props

        return (
            <div className={styles.wrap}>
                <div className={styles.exportBtnWrap}>
                    <SecondaryButton title="Export" />
                </div>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
            </div>
        )
    }
}

export default KlassappTableHOC(ScholarshipItemTable)
