/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Tabs} from "antd"
import {get} from "lodash"
import {useHistory, useLocation} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {Screen} from "components/ui"
import {useActiveTab} from "hooks"
import {studentService} from "services"
import {getFullName, handleError} from "helpers"
import {TabHeader} from "components/Tab"
import {Documents} from "sections/Documents"
import {Tasks} from "sections/shared/student"
import {Permissions} from "types/permission"
import {BaseDepartmentId} from "types/departments"
import {useCurrentProfilePermissions} from "hooks"
import {AdvisorTitle, ModuleStudentHeader} from "uiKit"
import {AdmissionStudentTab} from "./parts"
import {Auth} from "types/auth"
import {AdmissionPanelInfo} from "sections/shared/student"
import {Model} from "Model"
import {BaseLoading} from "components"

const {TabPane} = Tabs

export enum ADMISSION_STUDENT_TABS {
    ADMISSIONS = "admissions",
    DOCUMENTS = "documents",
    TASKS = "tasks"
}

type Props = {
    model: Model
    match: any
}

export default function AdmissionStudent(props: Props) {
    const departmentId = BaseDepartmentId.Admissions
    const [activeTabKey, onChangeTab] = useActiveTab(ADMISSION_STUDENT_TABS.ADMISSIONS)
    const {t} = useTranslation(["admission", "common"])
    const [student, setStudent] = useState<Auth.DepartmentStudent | undefined>()
    const [canAccess, setCanAccess] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const currentProfilePermissions = useCurrentProfilePermissions()
    const {
        match: {
            params: {profileId}
        },
        model
    } = props
    const [backUrl, setBackUrl] = useState<string | null>(model.isStaffOrAdmin() ? "/admissions/staff?tab=2" : null)
    const history = useHistory()
    const location = useLocation()
    async function getStudentDetail() {
        try {
            setIsLoading(true)
            const {
                data: [student]
            } = await studentService.getDepartmentStudents({
                filter: {profileIds: [+profileId], departmentId}
            })
            setStudent(student)
            const canAccess =
                model.isStaffOrAdmin() || !!model.user.profiles.find((profile) => profile.id === +profileId)
            setCanAccess(canAccess)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const backUrl = location.state?.["backUrlFromPage"]
        if (backUrl) {
            setBackUrl(backUrl)
        }
    }, [location])

    useEffect(() => {
        getStudentDetail()
    }, [profileId])

    const renderContent = () => {
        if (!canAccess) {
            return <p>You don't have permission to view this page</p>
        }

        if (!student) {
            return <>{isLoading ? <BaseLoading isShow /> : <p>Information not found</p>}</>
        }

        return (
            <>
                <ModuleStudentHeader
                    activeModule={Auth.ModuleNavigation.Admissions}
                    profileId={profileId}
                    userId={student?.userId}
                    customUserId={student?.customUserId}
                    isBackButtonActive={!!backUrl}
                />
                <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab}>
                    {currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.View],
                        student: [Permissions.Student.Admissions.View],
                        others: [Permissions.Others.Admissions.View]
                    }) && (
                        <TabPane
                            tab={<TabHeader icon="DOOR_OPEN" title={t("admissionStudent.admission")} />}
                            key={ADMISSION_STUDENT_TABS.ADMISSIONS}>
                            <AdmissionStudentTab studentId={+profileId} departmentId={departmentId} model={model} />
                        </TabPane>
                    )}
                    <TabPane
                        tab={<TabHeader icon="FILE_TEXT" title={t("common:tasks.title")} />}
                        key={ADMISSION_STUDENT_TABS.TASKS}>
                        <Tasks
                            studentId={profileId}
                            departmentId={BaseDepartmentId.Admissions}
                            history={history}
                            model={model}
                            fromPage={{backUrl: `/admissions/student/${profileId}?tab=${ADMISSION_STUDENT_TABS.TASKS}`}}
                            backUrlFromPage={`/admissions/student/${profileId}?tab=${ADMISSION_STUDENT_TABS.TASKS}`}
                        />
                    </TabPane>
                    <TabPane
                        tab={<TabHeader icon="FILE_TEXT" title={t("admissionStudent.documents")} />}
                        key={ADMISSION_STUDENT_TABS.DOCUMENTS}>
                        <Documents
                            userId={student?.userId}
                            backUrl={`/admissions/student/${profileId}?tab=${ADMISSION_STUDENT_TABS.DOCUMENTS}`}
                            departmentId={departmentId}
                            {...props}
                        />
                    </TabPane>
                </Tabs>
            </>
        )
    }

    return (
        <Screen
            htmlTitle="Admission Student"
            header={{
                title: getFullName(student) || "-",
                code: get(student, "customProfileId", ""),
                backUrl
            }}
            rightPanel={{
                type: "student",
                data: {
                    student,
                    departmentId: BaseDepartmentId.Admissions,
                    advisorTitle: AdvisorTitle.admissionAdvisors,
                    renderDepartmentInfo: (props) => (
                        <AdmissionPanelInfo
                            {...props}
                            {...{
                                student,
                                setStudent,
                                reloadStudent: getStudentDetail
                            }}
                        />
                    )
                }
            }}>
            {renderContent()}
        </Screen>
    )
}
