import {Timestamps} from "types/common"
import {Auth} from "types/auth"
import {TermDetails} from "types/terms"
import {LedgerAccount} from "types/student-account/ledger-accounts"
import {StudentPayment, StudentPaymentCharge} from "types/student-account/student-payment"
import {StudentBilling} from "types/student-account/student-billing"
import {PaymentPlan} from "types/student-account/payment-plan"
import {PaymentPeriod} from "types/student-account/payment-period"

export enum StudentChargeStatus {
    Pending = "pending", // Posted
    InProgress = "in_progress", // Partially Paid
    Completed = "completed" // Paid
}

export type StudentCharge = Timestamps & {
    chargeId: number
    status: StudentChargeStatus
    profileId: number
    profile?: Auth.LinkedProfileDetails // populate
    academicYear?: number | null
    awardYear?: number | null
    termId?: number
    term?: TermDetails // populate
    accountId?: number | null
    account?: LedgerAccount // populate
    customAccountName?: string | null
    description?: string | null
    notes?: string | null
    amount: number
    tranxDate: string
    dueDate?: string | null
    paidAmount: number
    paidDate?: string | null
    isInstitutionalCharge: boolean | 0 | 1
    isLock: boolean | 0 | 1
    periodId?: number | null
    period?: PaymentPeriod
    billingItemId?: number | null
    billing?: StudentBilling // populate
    paymentPlanId?: number | null
    paymentPlan?: PaymentPlan // populate
    paymentCharges?: StudentPaymentCharge[] // populate
    payments?: StudentPayment[] // populate

    updatedByProfileId?: number
    updatedBy?: Auth.LinkedProfileDetails // populate

    // internal
    paymentAmount?: number
    paymentAccountId?: number
}

////////////////////////////////////////////////

export enum StudentTransactionType {
    Charge = "charge",
    Payment = "payment"
}

export type StudentTransaction = Timestamps & {
    tranxId: number
    tranxDate: string
    profileId: number
    profile?: Auth.LinkedProfileDetails // populate
    type: StudentTransactionType
    chargeId?: number
    charge?: StudentCharge // populate
    paymentId?: number
    payment?: StudentPayment // populate

    amount: number
    academicYear?: number | null
    awardYear?: number | null
    reconcileDate?: string | null
    reconcileByProfileId?: number | null
    reconcileByProfile?: Auth.LinkedProfileDetails // populate

    // internal
    id?: number
    balance?: number
    chargeTotal?: number
    paymentTotal?: number
    latestBalance?: number
    description?: string
    rowClassName?: string
}

export enum StudentChargeLinkedEntity {
    Profile = "profile",
    Account = "account",
    Term = "term",
    Payments = "payments"
}

export type StudentStats = {
    paymentDueCount?: number
    paymentPastDueCount?: number
    paymentPastDueAmount?: number
    paymentPastDueDate?: string | null
    nextPaymentAmount?: number
    nextPaymentDate?: string | null
    nextProjectedDisbursementAmount?: number
    nextProjectedDisbursementDate?: string | null
    acadYearStats: Record<
        number,
        {
            chargeTotal: number
            paymentTotal: number
            balance: number
        }
    >
    awardYearStats: Record<
        number,
        {
            chargeTotal: number
            paymentTotal: number
            balance: number
        }
    >
}
