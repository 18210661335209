import {KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./TransferCredits.module.css"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {handleError} from "helpers"
import {TransferCredits as TransferCreditsType} from "types/transferCredits"

type TransferCreditsProps = KlassappTableProps & {
    transferCredits: Array<TransferCreditsType>
}

const TransferCredits = (props: TransferCreditsProps) => {
    const {
        transferCredits,
        data,
        dispatch,
        dispatchFunc,
        page,
        pageSize,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        total
    } = props

    const {t} = useTranslation(["course"])
    const history = useHistory()

    const getFields = () => {
        return [
            "CODE",
            "COURSE NAME",
            "TERM NAME",
            "COMPLETED HOURS",
            "REQ CREDITS",
            "EARNED CREDITS",
            "ALPHABETICAL",
            "GRADE (GPA)"
        ]
    }

    const getPageTitle = () => {
        return "Course"
    }
    const [columns, setColumns] = useState([
        {
            title: "CODE",
            field: "codeEquivalent",
            style: {
                minWidth: "60px",
                maxWidth: "120px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }
        },
        {
            title: "COURSE NAME",
            field: "courseName",
            style: {
                minWidth: "300px",
                maxWidth: "300px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }
        },
        {
            title: "TERM NAME",
            field: "termNameTransfer",
            style: {
                minWidth: "300px",
                maxWidth: "300px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }
        },
        {
            title: "REQ CREDITS",
            field: "creditsEquivalent",
            style: {
                minWidth: "60px",
                maxWidth: "60px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }
        },
        {
            title: "EARNED CREDITS",
            field: "appliedCredits",
            style: {
                minWidth: "60px",
                maxWidth: "60px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden"
            }
        },
        {
            title: "ALPHABETICAL",
            field: "alpha"
        },
        {
            title: "GRADE (GPA)",
            field: "grades"
        }
    ])

    const getColumns = () => {
        return columns
    }

    const getData = async () => {
        try {
            dispatch({isLoading: true})
            const listData = transferCredits.map((item) => ({
                ...item,
                alpha: typeof item.alphabetical === "string" ? item.alphabetical : "",
                earnedCredits: item.appliedCredits
            }))
            dispatch({data: listData, total: total})
        } catch (err) {
            handleError(err)
        } finally {
            dispatch({isLoading: false})
        }
    }
    const getMenuActions = () => {
        return []
    }
    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getMenuActions", func: getMenuActions}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferCredits])

    return (
        <div style={{marginBottom: 40}}>
            <div className={styles.row}>
                <span className={styles.titleTable} style={{color: "#1e90ff", marginRight: 10}}>
                    TRANSFER CREDITS
                </span>
            </div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(TransferCredits)
