/* eslint-disable react-hooks/exhaustive-deps */
import {AddItemCircleButton, BaseButton, SecondaryButton} from "components/buttons"
import {Dropdown} from "components/Dropdown"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import {BasePopup} from "components/popup"
import {handleError, handleErrorMessage, validateEmail} from "helpers"
import {KlassappTableHOC} from "HOC"
import {useVisible} from "hooks"
import {QRCodeSVG} from "qrcode.react"
import {useEffect, useState} from "react"
import {withTranslation} from "react-i18next"
import {settingExternshipSiteService, settingSiteSupervisorService, userProfileServiceV3, userServiceV3} from "services"
import {Auth} from "types/auth"
import {KlassappTableProps} from "types/common"
import {ConfirmPopupChildren, KlassappTable, KlassappTableHeader} from "uiKit"
import SiteSupervisorQrCodeDetail from "./components/SiteSupervisorQrCodeDetail"
import styles from "./SiteSupervisors.module.css"

type PageProps = {
    t: Function
}

type Props = KlassappTableProps & PageProps

function SiteSupervisors(props: Props) {
    const [newItemData, setNewItemData] = useState<any>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [externshipSitesList, setExternshipSitesList] = useState<any[]>([])
    const [deletedItem, setDeletedItem] = useState<any>(null)
    const [selectedQRCodeData, setSelectedQRCodeData] = useState(null)
    const confirmPopup = useVisible()
    const formPopup = useVisible()

    const {t, isLoading} = props

    const externshipCoordinatorsList = selectedQRCodeData
        ? externshipSitesList
              .find((externshipSite) => {
                  return externshipSite.id === selectedQRCodeData.externshipSiteId
              })
              ?.supervisorDetails?.map((supervisorDetail) => supervisorDetail.name)
        : []

    useEffect(() => {
        props.dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDeleteConfirm}
        ])
        getData()
        getListExternshipSites()
    }, [])

    const getPageTitle = () => {
        return props.t("settings.siteSupervisors.siteSupervisor")
    }

    const getFields = () => {
        return [
            t("settings.siteSupervisors.name"),
            t("settings.siteSupervisors.title"),
            t("settings.siteSupervisors.email"),
            t("settings.siteSupervisors.pin"),
            t("settings.siteSupervisors.externshipSite"),
            t("settings.siteSupervisors.qrCode")
        ]
    }

    const getColumns = () => {
        const {t} = props

        return [
            {
                title: t("settings.siteSupervisors.name"),
                field: "userName",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.siteSupervisors.title"),
                field: "userTitle",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.siteSupervisors.email"),
                field: "userEmail",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.siteSupervisors.pin"),
                field: "pin",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.siteSupervisors.externshipSite"),
                field: "externshipSiteName",
                style: {minWidth: "200px"}
            },
            {
                title: t("settings.siteSupervisors.qrCode"),
                field: "qrCode",
                style: {minWidth: "200px"},
                render: (value, record) => renderQRCodeSmall(record)
            }
        ]
    }

    const renderQRCodeSmall = (item) => {
        if ((item?.qrCode ?? "").trim() === "") {
            return null
        }

        return <QRCodeSVG className={styles.listQRCode} value={item.qrCode} onClick={() => onTogglePopupQRCode(item)} />
    }
    const getData = async () => {
        props.dispatch({isLoading: true})
        const params = getParams()

        try {
            const {data, total} = await settingSiteSupervisorService.getAllSiteSupervisors(params)
            props.dispatch({data, total})
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const getListExternshipSites = async () => {
        props.dispatch({isLoading: true})
        const params = {
            filter: {}
        }

        try {
            const {data} = await settingExternshipSiteService.getAllExternshipSites(params)
            const newData = data.map((item) => {
                return item
            })
            setExternshipSitesList(newData)
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const getParams = () => {
        const {page, pageSize} = props
        const params = {
            range: {
                page,
                pageSize
            },
            filter: {}
        }
        return params
    }

    const onClickDeleteMulti = async () => {
        const checkedItems = props.data.filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingSiteSupervisorService.deleteSiteSupervisor(payload)
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const onClickEdit = (editItem) => {
        setNewItemData({
            ...editItem
        })
        formPopup.open()
    }

    const onClickDeleteConfirm = async (deletedItem) => {
        setDeletedItem(deletedItem)
        confirmPopup.open()
    }

    const onClickDelete = async () => {
        try {
            props.dispatch({isLoading: true})
            await settingSiteSupervisorService.deleteSiteSupervisor([deletedItem.id])
            setDeletedItem(null)
            confirmPopup.close()
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const onClickAddItem = () => {
        formPopup.open()
    }

    const onClosePopup = () => {
        formPopup.close()
        setNewItemData(null)
    }

    const onChange = (key, value) => {
        setNewItemData((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    const onClickSave = async () => {
        const {userEmail} = newItemData
        // validate email format
        if (!validateEmail(userEmail)) {
            handleErrorMessage("Please enter a valid email")
            return false
        }

        if (!newItemData.id) {
            await createSiteSupervisor()
        } else {
            await updateSiteSupervisor()
        }
        setNewItemData(null)
        formPopup.close()
        getData()
    }

    const createSiteSupervisor = async () => {
        const {userName, userTitle, userEmail, externshipSiteId} = newItemData
        try {
            props.dispatch({isLoading: true})
            setIsSubmitting(true)

            const userFullName = userName.trim().split(" ")
            const lastName = userFullName.length > 1 ? userFullName.pop() : ""
            const firstName = userFullName.join(" ")

            const submitData = {
                emails: [{email: userEmail, isPrimary: true}],
                firstName: firstName,
                lastName: lastName,
                title: userTitle,
                active: true,
                type: Auth.UserProfileType.Others,
                isSiteSupervisor: true,
                externshipSiteId: externshipSiteId
            }
            await settingSiteSupervisorService.createSiteSupervisor(submitData)
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
            setIsSubmitting(false)
        }
    }

    const updateSiteSupervisor = async () => {
        const {userName, userTitle, userEmail, externshipSiteId, id, userId, xProfileId, pin} = newItemData
        try {
            props.dispatch({isLoading: true})
            setIsSubmitting(true)
            const userFullName = userName.trim().split(" ")
            const lastName = userFullName.length > 1 ? userFullName.pop() : ""
            const firstName = userFullName.join(" ")
            const submitDataUser = {
                emails: [{email: userEmail, isPrimary: true}],
                firstName: firstName,
                lastName: lastName,
                id: userId
            }
            await userServiceV3.update(submitDataUser)

            const submitDataUserProfile = {
                title: userTitle,
                active: true,
                id: xProfileId
            }
            await userProfileServiceV3.update(submitDataUserProfile)

            const submitDataSupervisor = {
                profileId: xProfileId,
                externshipSiteId: externshipSiteId,
                pin: Number(pin)
            }
            await settingSiteSupervisorService.updateSiteSupervisor(id, submitDataSupervisor)
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
            setIsSubmitting(false)
        }
    }

    const onTogglePopupQRCode = (item) => {
        setSelectedQRCodeData(item)
    }

    let tableHeaderActionsTemp = props.tableHeaderActions.filter((item) => {
        return item.title !== "Duplicate"
    })

    return (
        <div className={styles.wrapper}>
            <KlassappTableHeader
                isShowAction={props.isShowTableHeaderAction}
                actions={tableHeaderActionsTemp}
                page={props.page}
                total={props.total}
                defaultPageSize={props.pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={props.columns}
                data={props.data}
                menuActions={props.isHideMenuActions ? [] : props.menuActions}
                isLoading={props.isLoading}
                fields={props.fields}
                allFields={props.allFields}
                isShowCheckedColumn={false}
                onClickRowItem={() => {}}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
                className={styles.tableScrollHidden}
            />

            {!newItemData && <AddItemCircleButton onClick={onClickAddItem} />}

            <BasePopup
                isShow={formPopup.isVisible}
                onClose={onClosePopup}
                isShowLeftSide
                leftIcon="EDIT"
                className={styles.modalWrapper}>
                <div className={styles.modalRoot}>
                    <div className={styles.modalTitle}>
                        {newItemData?.id ? "Edit Site Supervisor Details" : "Create Site Supervisor"}
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.siteSupervisors.name")} />
                        <BaseInput
                            value={newItemData?.userName}
                            placeholder={t("settings.siteSupervisors.name")}
                            onChange={(value) => onChange("userName", value)}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.siteSupervisors.title")} />
                        <BaseInput
                            value={newItemData?.userTitle}
                            placeholder={t("settings.siteSupervisors.title")}
                            onChange={(value) => onChange("userTitle", value)}
                        />
                    </div>

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.siteSupervisors.email")} />
                        <BaseInput
                            value={newItemData?.userEmail}
                            placeholder={t("settings.siteSupervisors.email")}
                            onChange={(value) => onChange("userEmail", value)}
                        />
                    </div>

                    {newItemData?.id && (
                        <div className={styles.formGroup}>
                            <FormLabel label={t("settings.siteSupervisors.pin")} />
                            <BaseInput
                                type="number"
                                value={newItemData?.pin}
                                placeholder={t("settings.siteSupervisors.pin")}
                                onChange={(value) => onChange("pin", value)}
                            />
                        </div>
                    )}

                    <div className={styles.formGroup}>
                        <FormLabel label={t("settings.siteSupervisors.externshipSite")} />
                        <Dropdown
                            placeholder={t("settings.siteSupervisors.externshipSite")}
                            onChange={(value) => onChange("externshipSiteId", value.id)}
                            options={externshipSitesList}
                            value={externshipSitesList.filter((el) => {
                                return el.id === newItemData?.externshipSiteId
                            })}
                        />
                    </div>

                    <div className={styles.buttonWrap}>
                        <SecondaryButton
                            title={props.t("common:action.cancel")}
                            onClick={onClosePopup}
                            className={styles.cancelBtn}
                        />
                        <BaseButton
                            title={props.t("common:action.save").toUpperCase()}
                            onClick={onClickSave}
                            loading={isSubmitting}
                        />
                    </div>
                </div>
            </BasePopup>

            <ConfirmPopupChildren
                isVisible={confirmPopup.isVisible}
                onClose={confirmPopup.close}
                onConfirm={onClickDelete}
                title={t("settings.siteSupervisors.confirmation")}
                isSubmitting={isLoading}>
                <div className={styles.confirmBody}>{t("settings.siteSupervisors.deleteConfirmMessage")}</div>
            </ConfirmPopupChildren>

            <SiteSupervisorQrCodeDetail
                isVisible={!!selectedQRCodeData}
                onClose={() => onTogglePopupQRCode(null)}
                externshipCoordinatorsList={externshipCoordinatorsList}
                qrData={selectedQRCodeData}
            />
        </div>
    )
}

export default KlassappTableHOC(withTranslation(["settings", "common"])(SiteSupervisors))
