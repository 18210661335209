import React from "react"
import {Pie} from "react-chartjs-2"
import styles from "./DailyChart.module.css"
import cx from "classnames"
import {FilterEventType} from "./DailyAttendance"
import {DailyAttendanceViewType} from "types/attendance"

type Props = {
    data: any
    filter: {
        majorVersionIds: number[]
        phaseIds: number[]
        eventType?: string
    }
    viewType: DailyAttendanceViewType
    onClickItem: (item: any, type: string) => void
}

const options = {
    legend: {display: false},
    plugins: {
        datalabels: {display: false},
        tooltip: {
            enabled: false
        },
        legend: {
            display: false
        }
    },
    events: [],
    onHover: (event) => {
        event.native.target.style.cursor = "default"
    },
    hover: {
        mode: null
    },
    elements: {
        arc: {
            borderWidth: 0
        }
    }
}
const rChart3 = 135
const rChart2 = 230
const rChart1 = 200

export function DailyChart(props: Props) {
    const {data, onClickItem, viewType, filter} = props
    const {majorVersionIds, eventType: filterEventType, phaseIds} = filter
    const {
        versionName,
        activeStudentsCount,
        studentsInSchool,
        studentsOnBreak,
        studentsCheckedOut,
        studentsMissed,
        name
    } = data
    const statistics = {
        labels: ["Break", "Check out", "In school", "Missed"],
        datasets: [
            {
                data: [
                    studentsOnBreak,
                    studentsCheckedOut,
                    studentsInSchool - studentsOnBreak - studentsCheckedOut,
                    studentsMissed
                ],
                backgroundColor: ["#ff8a38", "#828282", "#18a957", "#df1642"]
            }
        ]
    }
    const statistics2 = {
        labels: ["In School", "Missed"],
        datasets: [
            {
                data: [studentsInSchool, studentsMissed],
                backgroundColor: ["#18a957", "#df1642"]
            }
        ]
    }
    const items: Array<{label: string; color: string; value: number; type: FilterEventType}> = [
        {label: "Active Students", color: "#e6e6e6", value: activeStudentsCount ?? 0, type: FilterEventType.Active},
        {label: "In School", color: "#18a957", value: studentsInSchool, type: FilterEventType.InSchool},
        {label: "Break", color: "#ff8a38", value: studentsOnBreak, type: FilterEventType.Break},
        {label: "Check out", color: "#828282", value: studentsCheckedOut, type: FilterEventType.Checkout},
        {label: "Missed", color: "#df1642", value: studentsMissed, type: FilterEventType.Missed}
    ]

    const isFocusedFilter = (type: FilterEventType) => {
        if (viewType === DailyAttendanceViewType.ViewByProgram) {
            return majorVersionIds.includes(data.id) && filterEventType === type
        } else {
            return phaseIds.includes(data.phaseId) && filterEventType === type
        }
    }

    return (
        <div className={styles.wrap}>
            <p className={styles.title}>{viewType === DailyAttendanceViewType.ViewByProgram ? versionName : name}</p>
            <div className={styles.body}>
                <div className={styles.chart}>
                    <div
                        className={styles.chartActive}
                        style={{
                            width: rChart3,
                            borderRadius: rChart3 / 2
                        }}>
                        <div style={{position: "absolute", top: 10, width: rChart2, height: rChart2, zIndex: 1}}>
                            <Pie data={statistics2} options={options} />
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                width: rChart1,
                                height: rChart1,
                                zIndex: 2,
                                top: (rChart2 - rChart1) / 4 + 10
                            }}>
                            <Pie data={statistics} options={options} />
                        </div>
                    </div>
                </div>
                <div className="pie-wrap">
                    <div className="entry" style={{background: "#87cefa", height: "50%", width: "50%"}}></div>
                    <div className="entry" style={{background: "#ffffe0", height: "50%", width: "50%"}}></div>
                    <div className="pink entry" style={{background: "#ffc0cb", height: "50%", width: "50%"}}></div>
                    <div className="purple entry" style={{background: "#800080", height: "50%", width: "50%"}}></div>
                    <div className="green entry" style={{background: "#32cd32", height: "50%", width: "50%"}}></div>
                    <div className="wheat entry" style={{background: "#f5deb3", height: "50%", width: "50%"}}></div>
                </div>
                <div className={styles.rightInfo}>
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={cx(styles.infoItem, {
                                [styles.infoItemSelected]: isFocusedFilter(item.type)
                            })}
                            onClick={() => item.type && onClickItem(data, item.type)}>
                            <div className={styles.infoItem__left}>
                                <div className={styles.infoItem__color} style={{background: item.color}} />
                                <span className={styles.infoItem__title}>{item.label}</span>
                            </div>
                            <span className={styles.infoItem__value}>{item.value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
