import {Auth} from "./auth"
import {Campus} from "./campus"
import {Department} from "./departments"

export namespace Communication {
    export enum RoomTypes {
        Default = "default",
        FollowUp = "follow",
        Sms = "sms",
        Channel = "channel",
        DirectChat = "direct",
        Department = "department",
        General = "general",
        Scheduled = "scheduled",
        NewChat = "new",
        DirectMessage = "directMessage",
        Settings = "settings",
        MultiDepartmental = "multiDepartmental",
        OpenedSupportHub = "openedSupportHub",
        ClosedSupportHub = "closedSupportHub",
        NewSupportHub = "newSupportHub"
    }

    export enum AttachedFileType {
        Image = "image",
        Video = "video",
        File = "file"
    }

    export type AttachedFile = {
        url: string
        mimetype: string
        filename: string
        fileSize: number
        // additional info
        type: AttachedFileType
        file?: any
        poster?: string
    }

    export type MessageReaction = {
        reaction: string
        count: number
        me: boolean
    }

    export type ReplyMessage = {
        messageId: number
        authorFullName: string
        content: string
        file?: AttachedFile
        withSms?: any
        withEmail?: any
    }

    export type MessageType = "system" | "text" | "file" | "poll"

    export type OnlineStatus = "online" | string | null

    export type Message = {
        messageId: number
        content: string
        type: MessageType
        file?: AttachedFile
        isPinned: boolean
        withSms?: any
        withEmail?: any
        createdAt: string
        editedAt?: string
        author: RoomUser
        title?: string
        replyTo?: ReplyMessage
        roomId?: number | string
        room?: Room
        reactions?: MessageReaction[]
        scheduledAt?: string
        deletedAt?: string
        jsonData?: any
        isRead?: boolean
        poll?: Poll
        // for thread attributes
        unread?: number
        totalComments?: number
        isThread?: boolean
    }

    export type RoomUser = {
        userId: number
        fullName: string
        photo?: string
        isDepartmentChatUser?: boolean
        isMe?: boolean
        onlineAt?: OnlineStatus
        profiles?: Auth.UserProfile[]
    }

    export type Room = {
        roomId: string | number
        name: string
        description?: string | null
        type: RoomTypes
        notifications?: NotificationMode
        role?: RoomRole
        canWrite?: boolean
        unread?: number
        // static room's icon
        roomIcon?: JSX.Element
        isGroup?: boolean
        isHidden?: boolean

        // last message
        lastMessageId?: number
        lastMessageContent?: string
        ownerId?: number
        createdAt?: string
        userId?: number
    }

    export type ChannelRoom = Room & {
        owner?: RoomUser
    }

    export type DirectChatRoom = Room & {
        image?: string
        onlineAt?: OnlineStatus
    }

    export type DepartmentRoom = Room & {
        type: RoomTypes.Department
        departmentRoomId?: number
        departmentId?: number
        departmentName?: string
        campusId?: number
        campusName?: string
        user?: {
            userId: number
            fullName: string
            photo?: string | null
            onlineAt?: OnlineStatus
        }
    }

    export type DirectMessageRoom = Room & {
        directMessageUserIds?: number[]
    }

    export type MultiDepartmentalMessageRoom = Room & {
        departmentCampusRoomIds: number[]
        departmentId: number
        departmentCampusUsers?: DepartmentCampusUser[]
    }

    export type SupportRoom = Room & {
        status: SupportRoomStatus
        subStatus: string // "new_request",
        supportId: number
    }

    export type GeneralRoom = ChannelRoom | DirectChatRoom | DepartmentRoom

    export enum ItemGroupType {
        Department = "group_department"
    }

    export type ItemGroup = {
        id: number
        type: ItemGroupType
        name: string
        unread?: number
        icon?: any
        children?: DepartmentRoom[]
    }

    export type DepartmentalChat = {
        departmentId: number
        departmentName: string
        unread?: number
        campus?: DepartmentRoom[]
    }

    export type DepartmentCampus = {
        campusId: number
        campusName: string
        unread?: number
        rooms?: DepartmentRoom[]
        totalRooms?: number
        roomsPage?: number
        roomsPageSize?: number
    }

    export enum RoomGroupId {
        Overview = "overview",
        Channel = "channel",
        Direct = "direct",
        Department = "department",
        DirectMessage = "direct-message",
        OpenedSupportHub = "openedSupportHub",
        ClosedSupportHub = "closedSupportHub"
    }

    export type RoomGroup = {
        id?: RoomGroupId
        title?: string
        items?: Communication.GeneralRoom[]
        roomType?: Communication.RoomTypes
        isDepartmental?: boolean
        topBorder?: boolean
        departmentId?: number
        campusId?: number
        totalRooms?: number
        roomsPage?: number
        sectionId?: string
    }

    export enum RoomRole {
        Admin = "admin",
        Member = "member"
    }

    export enum NotificationMode {
        All = "all",
        OnlyMentions = "only_mentions",
        OnlyThreads = "only_threads",
        ThreadComments = "threads_comments",
        Off = "off"
    }

    export const NOTIFICATION_OPTIONS: {id: Communication.NotificationMode; name: string}[] = [
        {id: Communication.NotificationMode.OnlyMentions, name: "Mentions of me"},
        {id: Communication.NotificationMode.All, name: "All messages"},
        {id: Communication.NotificationMode.Off, name: "Turn notifications off"}
    ]

    export const NOTIFICATION_CHANNEL_OPTIONS: {id: Communication.NotificationMode; name: string}[] = [
        {id: Communication.NotificationMode.All, name: "All threads and comments"},
        {id: Communication.NotificationMode.OnlyThreads, name: "Just threads"},
        {id: Communication.NotificationMode.Off, name: "Turn notifications off"}
    ]

    export type FragmentMessage = {
        direction: "backward" | "forward" | "both"
        messageId: number
    }

    export enum ChannelPages {
        Home = "home",
        ThreadDetails = "thread"
    }

    export enum FollowUpSourceType {
        Room = "room",
        Message = "message"
    }

    export type FollowUpMessage = {
        followUpId: number
        userId: number
        sourceType: FollowUpSourceType
        sourceId: number
        message?: Message
        room?: ChannelRoom
    }

    export enum SocketCommand {
        Ping = "ping",
        Auth = "auth",
        NewMessage = "newMessage",
        EditMessage = "editMessage",
        Typing = "typing",
        RoomCreated = "roomCreated",
        RoomUnread = "updateRoomUnread",
        TotalUnread = "updateTotalUnread",
        RoomUpdated = "roomUpdated",
        RoomRemoved = "roomRemoved",
        UpdateMessageReadStatus = "updateMessageReadStatus",
        ThreadCreated = "threadCreated",
        AddPollVote = "addPollVote",
        RetractPollVote = "retractPollVote",
        UpdatePoll = "updatePoll",
        UpdatePollVoters = "updatePollVoters",
        PollClosed = "pollClosed",
        NewNotification = "newNotification",
        UpdateMessagePinned = "updateMessagePinned",
        OnlineStatus = "onlineStatus",
        SupportTicketUpdated = "supportTicketUpdated"
    }

    export enum SocketStatus {
        Unknown = "unknown",
        Connected = "connected",
        Disconnected = "disconnected"
    }

    export type TypingEvent = {
        roomId: number
        fromUserId: number
    }

    export const FOLLOW_UP_TYPES: {label: string; value: number}[] = [
        {
            label: "30mins",
            value: 1800
        },
        {
            label: "1h",
            value: 3600
        },
        {
            label: "2h",
            value: 7200
        },
        {
            label: "tomorrow",
            value: 86400
        }
    ]

    export enum EditorType {
        Text = "text",
        Html = "html"
    }

    export type NotificationTask = {
        taskId: number
        taskUrl: string
        taskCode: string
        taskName: string
        taskType: string
        rawMessage: string
        taskStatus: string
        taskDueDate: string
        taskMessage: string
        taskCreatedBy: string
        taskDescription: string
    }

    export type NotificationEvent = {
        calendarEventColor: string
        calendarEventDuration?: number
        calendarEventName: string
        calendarEventType: string
        calendarEventUrl: string
        calendarObjectId: number
        rawMessage: string
    }

    export type NotificationWorkflow = {
        action_id: number
        contentText: string
        source: string
        source_id: number
        workflow: {
            id: number
            code: string
            name?: string
        }
    }

    export type NotificationReport = {
        reportId: number
    }

    export type NotificationContactStrategy = {
        source: string
        source_id: number
        node_id: string
        profile?: any
        subject?: string
        contentText: string
        contentHtml: string
        contactStrategy: {
            contactStrategyId: number
            code: string
            name?: string
        }
    }

    export type NotificationActivity = {
        student: {
            userId: number
            profileId: number
            fullName: string
            id: number
        }
        description: string
        activity: {
            name: string
            departmentId: number
        }
        profileId: number
        studentActivityId: number
    }

    export type NotificationItem = {
        notificationId: number
        roomId?: number
        messageId?: number
        type: string
        source: string
        content: string
        isRead: boolean
        jsonData:
            | NotificationTask
            | NotificationEvent
            | NotificationWorkflow
            | NotificationContactStrategy
            | NotificationReport
            | any
        createdAt: string
        fromUser?: string
        roomName?: string
        roomType?: string
        payload?: string
    }

    export enum NotificationType {
        Calendar = "calendar",
        Tasks = "tasks",
        Activities = "activities",
        Admissions = "admissions",
        CareerServices = "career-services",
        ContactStrategy = "contactStrategy",
        ProcessWorkflow = "processWorkflow",
        Channel = "channel",
        Department = "department",
        Reports = "reports",
        Private = "private",
        DirectSMS = "smsDirect"
    }

    export type NotificationSummaryItem = {
        source: NotificationType
        notification: Communication.NotificationItem
        total: number
        unread: number
    }

    export type StorageLastState = Partial<{
        activeGroup: ItemGroup
        activeRoom: Room
        roomProps: any
        unreadSections: Record<string, boolean>
        // use for SMS
        activePageId: string
        pageProps: any
    }>

    export const CHAT_STORAGE_KEY = "chat_lastState"

    export enum PollTypes {
        Single = "single",
        Multiple = "multiple",
        Quiz = "quiz"
    }

    export type PollOption = {
        option: string
        pollOptionId: number
        chosen?: boolean
        correct?: boolean
        voters?: number
    }

    export type Poll = {
        isClosed: boolean
        explanation: string | null
        hasVoted: boolean
        ownerId: number
        pollId: number
        question: string
        roomId: number
        totalVoters: number
        type: PollTypes
        options: PollOption[]
        closeAt: string | null
    }

    export enum ChatOptions {
        UnreadCount = "unreadCount",
        Settings = "settings",
        UnreadNotification = "unreadNotification",
        UnreadSms = "unreadSms"
    }

    export enum ReturnKey {
        NEW_MESSAGE = "newMessage",
        NEW_LINE = "newLine"
    }

    export type ChatSettings = {
        userId: number
        returnKey: ReturnKey
        isOutOfOffice: boolean
        outOfOfficeMessage: string | null
        outOfOfficeStartAt: string | null
        outOfOfficeEndAt: string | null
        outOfOfficeStartDate: string | null
        outOfOfficeStartTime: string | null
        outOfOfficeEndDate: string | null
        outOfOfficeEndTime: string | null
        timezone: string
    }

    export type DepartmentCampusUser = {
        user: Auth.AutocompleteUserDetail
        department: Department
        campuses: Campus.Campus[]
        selectedCampus?: Campus.Campus
    }

    export enum PredefinedRoomId {
        DirectMessage = "direct-message",
        MultiDepartmental = "multi-departmental",
        WithoutDepartmentalRoom = "without-departmental"
    }

    export enum SupportRoomStatus {
        Open = "open",
        Cancelled = "canceled",
        Resolved = "resolved"
    }

    export const SupportRoomStatusToType = {
        [SupportRoomStatus.Open]: RoomTypes.OpenedSupportHub,
        [SupportRoomStatus.Cancelled]: RoomTypes.ClosedSupportHub,
        [SupportRoomStatus.Resolved]: RoomTypes.ClosedSupportHub
    }

    export const SupportRoomStatusToGroup = {
        [SupportRoomStatus.Open]: RoomGroupId.OpenedSupportHub,
        [SupportRoomStatus.Cancelled]: RoomGroupId.ClosedSupportHub,
        [SupportRoomStatus.Resolved]: RoomGroupId.ClosedSupportHub
    }

    export type ViewAsProfile = Auth.Profile & {
        hasPermissionToDelete?: boolean
    }

    export type UserDirectAndDepartmentRooms = {
        directRoom?: DirectChatRoom
        departmentRoom?: DepartmentRoom
        departmentId: number
        campusId?: number
        userId: number
        allowCreateDepartmentChat: boolean
    }
}

export type ChatMessagePayload = {
    senderId?: number
    roomId: number
    content: string
    type: "message"
    replyToMessageId?: number
    withSms?: boolean
    withEmail?: boolean
    file?: {
        type: "file" | "image" | "video"
        url: string
        filename: string
        fileSize: number
        mimetype: string
    }
    jsonData: {
        source: Record<string, any>
        email?: {
            subject: string
            type?: string
            html?: string
            editorType?: string
        }
        mediaLibraryId?: number
    }
}
