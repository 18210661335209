/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {AddItemCircleButton, BaseInput} from "components"
import {KlassappTableHOC} from "HOC"
import React, {useCallback, useEffect, useState} from "react"
import {withTranslation} from "react-i18next"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {v4 as uuid} from "uuid"
import styles from "./GradingElements.module.css"
import ButtonCheckbox from "components/buttons/buttonCheckbox"
import {ColorPicker} from "uiKit"
import cx from "classnames"
import {cloneDeep} from "lodash"
import {academicGradingSystem} from "services"
import {formatDecimal, isPositiveFloat, preventMinus, toastError} from "helpers"
import {Checkbox} from "antd"

let dataGradingElementsForm = []

const GradingElements = (props) => {
    const {t} = props
    const {
        onClickCancelBtn,
        getDetailGradingSystem,
        responseData,
        getCurrentData,
        setInitialDataGradingElements,
        initialDataGradingElements,
        newDataGradingElements,
        setNewDataGradingElements,
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        menuActions,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        onChangeWeightingIsRequired,
        isWeightingRequired
    } = props
    const [currentColumn, setCurrentColumn] = useState([
        {
            title: t("academics:settings.gradingItems"),
            field: "gradingItem",
            sortable: true
        },
        {
            title: t("academics:settings.colorLabel"),
            field: "colorLabel",
            classNameHeader: styles.colorColumn,
            style: {minWidth: "120px", width: "120px", maxWidth: "120px"},
            sortable: true
        },
        {
            title: t("academics:settings.affectGPA"),
            field: "affectGpa",
            classNameHeader: styles.percentColumn,
            style: {minWidth: "150px", width: "150px"},
            sortable: true
        },
        {
            title: t("academics:settings.weight"),
            classNameHeader: styles.colorColumn,
            field: "weight",
            style: {minWidth: "120px", width: "120px", maxWidth: "120px"},
            sortable: true
        },
        {
            title: t("academics:settings.percentGPA"),
            field: "percentGpa",
            classNameHeader: styles.percentColumn,
            style: {minWidth: "150px", width: "150px"},
            sortable: true
        },
        {
            title: t("academics:settings.percentMinPass"),
            field: "minPercentageToPass",
            classNameHeader: styles.percentColumn,
            style: {minWidth: "150px", width: "150px"},
            sortable: true
        }
    ])
    const [currentField, setCurrentField] = useState([
        t("academics:settings.gradingItems"),
        t("academics:settings.colorLabel"),
        t("academics:settings.affectGPA"),
        t("academics:settings.weight"),
        t("academics:settings.percentGPA"),
        t("academics:settings.percentMinPass")
    ])

    const randomColor = () => "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")

    const getFields = () => {
        return currentField
    }

    const getPageTitle = () => {
        return "Grading Elements"
    }

    const getColumns = () => {
        return currentColumn
    }

    const generateColor = (name: string) => {
        if (name === "Assignment") {
            return "#facf74"
        } else if (name === "Quiz") {
            return "#b3246d"
        } else if (name === "Project") {
            return "#35a957"
        } else {
            return "#e95c7b"
        }
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const newData = ["Assignment", "Quiz", "Project", "Exam"].map((item) => {
                const id = uuid()
                return {
                    id: id,
                    gradingItem: renderInput("gradingItem", "Grading Items", id, item, false, "text"),
                    gradingItemValue: item,
                    colorLabelValue: generateColor(item),
                    affectGpaValue: true,
                    weightValue: isWeightingRequired ? "0" : "1",
                    percentGpaValue: "0",
                    minPercentageToPassValue: null,
                    colorLabel: renderColorPicker(id, false, generateColor(item)),
                    affectGpa: renderCheckBox(id, false, "affectGpa", "Affect GPA", true),
                    weight: !isWeightingRequired ? "" : renderInput("weight", "Weight", id, "0", false, "number"),
                    percentGpa: renderInput("percentGpa", "% GPA", id, "0", false, "number"),
                    minPercentageToPass: renderInput(
                        "minPercentageToPass",
                        "Min. Pass (% GPA)",
                        id,
                        "",
                        false,
                        "number"
                    )
                }
            })
            dataGradingElementsForm = [...newData]
            setNewDataGradingElements([...newData])
            const gradingElementsData = calculatePercent()
            setInitialDataGradingElements(cloneDeep(gradingElementsData))
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const getMenuActions = () => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete
            }
        ]
    }
    const onClickDeleteMulti = async () => {
        const newData = getCurrentData().filter((item) => item.isChecked && typeof item.id === "number")
        try {
            const ids = newData.map((item) => item.id)
            if (ids.length) {
                await academicGradingSystem.deleteGradingElements(ids)
                getDetailGradingSystem()
            }
            onClickCancelBtn()
        } catch (err) {
            onClickCancelBtn()
        }
        dispatch({isShowTableHeaderAction: false})
    }

    const onClickDuplicateMulti = async () => {
        const duplicateData = getCurrentData()
            .filter((item) => item.isChecked)
            .map((item) => ({
                ...item,
                gradingItemValue: `${item.gradingItemValue} (Copy)`,
                gradingItem: renderInput(
                    "gradingItem",
                    "Grading Items",
                    item.id,
                    `${item.gradingItemValue} (Copy)`,
                    false,
                    "text"
                ),
                isChecked: false,
                id: uuid()
            }))
        const oldData = data.map((item) => ({...item, isChecked: false}))
        const newData = [...oldData, ...duplicateData]
        dataGradingElementsForm = [...duplicateData]
        setNewDataGradingElements(newData)
        dispatch({data: newData, total: newData.length, isShowTableHeaderAction: false})
    }

    const onClickEdit = (editItem) => {
        const newData = dataGradingElementsForm.map((item) => {
            if (editItem.id !== item.id) return item
            const weightingIsNotRequired = !isWeightingRequired
            return {
                ...item,
                gradingItem: renderInput("gradingItem", "Grading Items", item.id, item.gradingItemValue, true, "text"),
                colorLabel: renderColorPicker(item.id, true, item.colorLabelValue),
                affectGpa: renderCheckBox(item.id, true, "affectGpa", "Affect GPA", !!item.affectGpaValue),
                weight: weightingIsNotRequired
                    ? ""
                    : renderInput(
                          "weight",
                          "Weight",
                          item.id,
                          item.affectGpaValue ? item.weightValue : "",
                          !!item.affectGpaValue && !weightingIsNotRequired,
                          "number"
                      ),
                percentGpa: renderInput(
                    "percentGpa",
                    "% GPA",
                    item.id,
                    !!item.affectGpaValue ? item.percentGpaValue : "",
                    false,
                    "number"
                ),
                minPercentageToPass: renderInput(
                    "minPercentageToPass",
                    "Min. Pass",
                    item.id,
                    !!item.minPercentageToPassValue ? item.minPercentageToPassValue : "",
                    !!item.affectGpaValue,
                    "number"
                )
            }
        })
        dataGradingElementsForm = [...newData]
        setNewDataGradingElements([...newData])
    }

    const onClickDelete = async (deleteItem) => {
        const newData = dataGradingElementsForm.filter(
            (item) => deleteItem.id === item.id && typeof item.id === "number"
        )
        try {
            const ids = newData.map((item) => item.id)
            if (ids.length) {
                await academicGradingSystem.deleteGradingElements(ids)
                getDetailGradingSystem()
            }
            onClickCancelBtn()
        } catch (err) {
            onClickCancelBtn()
        }
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem},
            {key: "getMenuActions", func: getMenuActions}
        ])
    }, [currentColumn, currentField, newDataGradingElements, initialDataGradingElements, data, isWeightingRequired])

    const onClickRowItem = (row) => {}

    const onChangeColorPicker = (id: string, value: string) => {
        const changeDataForm = dataGradingElementsForm.map((item) => {
            if (item.id !== id) {
                return item
            }
            let newValue: any = {
                ...item
            }
            newValue = {
                ...newValue,
                colorLabel: renderColorPicker(id, true, value),
                colorLabelValue: value
            }
            return newValue
        })
        dataGradingElementsForm = changeDataForm
        setNewDataGradingElements(changeDataForm)
    }

    const renderColorPicker = (id: string, isEditing: boolean, value: string) => {
        return <ColorPicker disable={!isEditing} value={value} onChange={(value) => onChangeColorPicker(id, value)} />
    }

    const renderInput = (
        field: string,
        nameField: string,
        id: string,
        value: string,
        isEditing: boolean,
        type: "number" | "text"
    ) => {
        if (!isEditing) {
            return (
                <div className={styles.titleWrap}>
                    <span className={styles.title}>{value}</span>
                </div>
            )
        }
        return (
            <BaseInput
                type={type}
                placeholder={nameField}
                disabledArrow
                value={value}
                min={type === "number" ? 0 : undefined}
                onKeyPress={type === "number" ? preventMinus : undefined}
                onChange={(text) => {
                    if (type === "number") {
                        if (isPositiveFloat(text)) {
                            onChangeInputGradingElements(id, field, text, type, nameField)
                        } else {
                            return toastError(`Invalid value`)
                        }
                    } else {
                        onChangeInputGradingElements(id, field, text, type, nameField)
                    }
                }}
            />
        )
    }

    const onChangeInputGradingElements = (id, field, value, type: "number" | "text", nameField: string) => {
        const changeDataForm = dataGradingElementsForm.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                [field]: renderInput(field, nameField, item.id, value, true, type),
                [`${field}Value`]: value
            }
            return newValue
        })

        dataGradingElementsForm = changeDataForm
        setNewDataGradingElements(changeDataForm)
        if (field === "weight") {
            calculatePercent()
        }
    }

    const onChangeCheckBox = (id) => {
        const changeDataForm = dataGradingElementsForm.map((item) => {
            const affectGpa = !item.affectGpaValue
            if (item.id !== id) {
                return item
            }
            let newValue: any = {
                ...item
            }
            const weightingIsNotRequired = !isWeightingRequired

            newValue = {
                ...newValue,
                affectGpa: renderCheckBox(item.id, true, "affectGpa", "Affect GPA", affectGpa),
                affectGpaValue: affectGpa,
                weight: !weightingIsNotRequired
                    ? renderInput("weight", "Weight", item.id, affectGpa ? item.weightValue : "", affectGpa, "number")
                    : "",
                weightValue: weightingIsNotRequired ? 1 : affectGpa ? item.weightValue : "",
                percentGpaValue: affectGpa ? item.percentGpaValue : "",
                minPercentageToPassValue: affectGpa ? item.minPercentageToPassValue : "",
                minPercentageToPass: renderInput(
                    "minPercentageToPass",
                    "Min. Pass",
                    item.id,
                    affectGpa ? (!!item.minPercentageToPassValue ? item.minPercentageToPassValue : "") : "",
                    affectGpa,
                    "number"
                ),
                percentGpa: renderInput(
                    "percentGpa",
                    "% GPA",
                    item.id,
                    affectGpa ? item.percentGpaValue : "",
                    false,
                    "number"
                )
            }
            return newValue
        })
        dataGradingElementsForm = changeDataForm
        setNewDataGradingElements(changeDataForm)
        calculatePercent()
    }

    const renderCheckBox = (id: string, isEditing: boolean, field: string, nameField: string, checked: boolean) => {
        return (
            <div className={styles.checkBoxAffectGPA} style={{pointerEvents: isEditing ? "auto" : "none"}}>
                <ButtonCheckbox
                    id={id}
                    name={nameField}
                    classNameCheckBox={styles.checkbox}
                    allChecked={checked}
                    onClick={
                        isEditing
                            ? function (): void {
                                  onChangeCheckBox(id)
                              }
                            : undefined
                    }
                />
            </div>
        )
    }

    const onAdd = useCallback(() => {
        const id = uuid()
        const weightingIsNotRequired = !isWeightingRequired
        const newData = [
            ...dataGradingElementsForm,
            {
                id: id,
                gradingItem: renderInput("gradingItem", "Grading Items", id, "", true, "text"),
                gradingItemValue: "",
                colorLabelValue: randomColor(),
                affectGpaValue: true,
                weightValue: weightingIsNotRequired ? 1 : "0",
                percentGpaValue: "0",
                colorLabel: renderColorPicker(id, true, randomColor()),
                affectGpa: renderCheckBox(id, true, "affectGpa", "Affect GPA", true),
                weight: weightingIsNotRequired ? "" : renderInput("weight", "Weight", id, "0", true, "number"),
                percentGpa: renderInput("percentGpa", "% GPA", id, "0", false, "number"),
                minPercentageToPass: renderInput("minPercentageToPass", "Min. Pass", id, "", true, "number")
            }
        ]
        dataGradingElementsForm = newData
        const totalWeight = dataGradingElementsForm.reduce(
            (previousValue, currentValue, index) =>
                previousValue +
                (currentValue.weightValue && currentValue.weightValue !== "" ? parseInt(currentValue.weightValue) : 0),
            0
        )
        for (let i = 0; i < dataGradingElementsForm.length; i++) {
            const element = dataGradingElementsForm[i]
            if (element.affectGpaValue === true) {
                const percent =
                    !element.weightValue ||
                    element.weightValue === "" ||
                    element.weightValue === 0 ||
                    element.weightValue === "0"
                        ? 0
                        : formatDecimal((parseFloat(element.weightValue) * 100) / totalWeight)
                dataGradingElementsForm[i].percentGpaValue = percent
                dataGradingElementsForm[i].percentGpa = renderInput(
                    "percentGpa",
                    "% GPA",
                    element.id,
                    `${percent}`,
                    false,
                    "number"
                )
            } else {
                dataGradingElementsForm[i].percentGpaValue = "0"
                dataGradingElementsForm[i].percentGpa = renderInput(
                    "percentGpa",
                    "% GPA",
                    element.id,
                    "",
                    false,
                    "number"
                )
            }
        }
        setNewDataGradingElements(newData)
    }, [newDataGradingElements, isWeightingRequired])

    useEffect(() => {
        if (responseData && responseData.gradingElements && responseData.gradingElements.length) {
            const gradingElementsData = responseData.gradingElements
            const weightingIsNotRequired = !isWeightingRequired
            const newData = gradingElementsData.map((item) => ({
                ...item,
                gradingItem: renderInput("gradingItem", "Grading Items", item.id, item.gradingItem, false, "text"),
                colorLabel: renderColorPicker(item.id, false, item.colorLabel),
                affectGpa: renderCheckBox(item.id, false, "affectGpa", "Affect GPA", !!item.affectGpa),
                weight: weightingIsNotRequired
                    ? ""
                    : renderInput("weight", "Weight", item.id, !!item.affectGpa ? item.weight : "", false, "number"),
                percentGpa: renderInput(
                    "percentGpa",
                    "% GPA",
                    item.id,
                    !!item.affectGpa && weightingIsNotRequired ? item.percentGpa : "",
                    false,
                    "number"
                ),
                minPercentageToPass: renderInput(
                    "minPercentageToPass",
                    "Min. Pass",
                    item.id,
                    !!item.minPercentageToPass ? item.minPercentageToPass : "",
                    false,
                    "number"
                ),
                gradingItemValue: item.gradingItem,
                colorLabelValue: item.colorLabel,
                affectGpaValue: !!item.affectGpa,
                weightValue: weightingIsNotRequired ? 1 : item.weight,
                percentGpaValue: item.percentGpa,
                minPercentageToPassValue: item.minPercentageToPass
            }))
            dataGradingElementsForm = [...newData]
            setNewDataGradingElements([...newData])
            const data = calculatePercent()
            setInitialDataGradingElements(cloneDeep(data))
        } else {
            getData()
        }
    }, [responseData, isWeightingRequired])

    useEffect(() => {
        dispatch({data: initialDataGradingElements, total: initialDataGradingElements.length})
        dataGradingElementsForm = initialDataGradingElements
    }, [initialDataGradingElements])

    useEffect(() => {
        dataGradingElementsForm = [...newDataGradingElements]
        dispatch({data: [...newDataGradingElements], total: newDataGradingElements.length})
    }, [newDataGradingElements])

    const totalWeight = !isWeightingRequired
        ? 0
        : newDataGradingElements.reduce(
              (previousValue, currentValue, index) =>
                  previousValue +
                  (currentValue.weightValue && currentValue.weightValue !== ""
                      ? parseInt(currentValue.weightValue)
                      : 0),
              0
          )

    const calculatePercent = () => {
        const totalWeight = dataGradingElementsForm.reduce(
            (previousValue, currentValue, index) =>
                previousValue +
                (currentValue.weightValue && currentValue.weightValue !== "" ? parseInt(currentValue.weightValue) : 0),
            0
        )
        for (let i = 0; i < dataGradingElementsForm.length; i++) {
            const element = dataGradingElementsForm[i]
            if (element.affectGpaValue === true) {
                const percent =
                    !element.weightValue ||
                    element.weightValue === "" ||
                    element.weightValue === 0 ||
                    element.weightValue === "0"
                        ? 0
                        : formatDecimal((parseFloat(element.weightValue) * 100) / totalWeight)
                dataGradingElementsForm[i].percentGpaValue = percent
                dataGradingElementsForm[i].percentGpa = renderInput(
                    "percentGpa",
                    "% GPA",
                    element.id,
                    `${percent}`,
                    false,
                    "number"
                )
            } else {
                dataGradingElementsForm[i].percentGpaValue = "0"
                dataGradingElementsForm[i].percentGpa = renderInput(
                    "percentGpa",
                    "% GPA",
                    element.id,
                    "",
                    false,
                    "number"
                )
            }
        }
        setNewDataGradingElements(dataGradingElementsForm)
        return dataGradingElementsForm
    }

    return (
        <>
            <Checkbox style={{marginTop: 18}} checked={!isWeightingRequired} onChange={onChangeWeightingIsRequired}>
                <span className={styles.weightingNotRequired}>Weighting not required</span>
            </Checkbox>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                isShowAllRecords
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                menuActions={menuActions}
                data={data}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn
                classNameActionMenu={styles.colorColumn}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <div className={styles.bottomRow}>
                <div className={styles.checkBoxColumn} />
                <div className={cx(styles.flexView, styles.paddingRow)}>
                    <span>Total</span>
                </div>
                <div className={cx(styles.paddingValueData, styles.colorColumn)} />
                <div className={cx(styles.paddingValueData, styles.percentColumn)} />
                <div className={cx(styles.paddingValueData, styles.colorColumn)}>
                    <span>{totalWeight.toString()}</span>
                </div>
                <div className={cx(styles.paddingValueData, styles.percentColumn)}>
                    <span>100%</span>
                </div>
                <div className={cx(styles.paddingValueData, styles.percentColumn)}></div>
                <div className={cx(styles.paddingRow, styles.colorColumn)} />
            </div>
            <AddItemCircleButton className={styles.addItemButton} onClick={onAdd} />
        </>
    )
}

export default KlassappTableHOC(withTranslation(["academics", "common", "course"])(GradingElements))
