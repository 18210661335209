/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {useTranslation} from "react-i18next"
import {useModel} from "hooks"
import {formatCurrency, getFullName, handleError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {Refund, RefundStatus} from "types/fin-aid/refund"
import {BaseButton} from "components"
import Status from "../Status"
import styles from "./RefundTable.module.css"
import {routing} from "helpers/routing"
import {useHistory} from "react-router-dom"
import {useParams} from "react-router-dom"

interface Props extends KlassappTableProps {}

const RefundTable: React.FC<Props> = ({
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    orderField,
    tableHeaderActions,
    isShowTableHeaderAction,
    isLoading,
    dispatch,
    dispatchFunc,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    onChangePage,
    onChangeRowPerPage,
    getCurrentData
}) => {
    const {t} = useTranslation(["financialAid", "common"])
    const history = useHistory()
    const model = useModel()
    const params = useParams<{studentId: string}>()
    const studentId = +params.studentId
    const userDateFormat = model.getUserDateFormat()

    const queryParams = React.useMemo(
        () => ({
            range: {
                limit: pageSize,
                offset: (page - 1) * pageSize
            }
        }),
        [page, pageSize, orderField]
    )

    const getRefundList = React.useCallback(() => {
        dispatch({isLoading: true})
        try {
            const data = [
                {
                    id: 1, // must have
                    refundId: "RF001",
                    studentId: "ST001",
                    profileId: 1,
                    firstName: "First",
                    lastName: "Last",
                    name: "[Refund name]",
                    type: "cash",
                    termId: 1,
                    termName: "Term 1",
                    amount: 1000,
                    status: RefundStatus.Requested,
                    updatedAt: new Date().toISOString()
                },
                {
                    id: 2, // must have
                    refundId: "RF002",
                    studentId: "ST002",
                    profileId: 1,
                    firstName: "First",
                    lastName: "Last",
                    name: "[Refund name]",
                    type: "cash",
                    termId: 1,
                    termName: "Term 1",
                    amount: 1000,
                    status: RefundStatus.Rejected,
                    updatedAt: new Date().toISOString()
                },
                {
                    id: 3, // must have
                    refundId: "RF003",
                    studentId: "ST003",
                    profileId: 1,
                    firstName: "First",
                    lastName: "Last",
                    name: "[Refund name]",
                    type: "cash",
                    termId: 1,
                    termName: "Term 1",
                    amount: 1000,
                    status: RefundStatus.Refunded,
                    updatedAt: new Date().toISOString()
                },
                {
                    id: 4, // must have
                    refundId: "RF004",
                    studentId: "ST004",
                    profileId: 1,
                    firstName: "First",
                    lastName: "Last",
                    name: "[Refund name]",
                    type: "cash",
                    termId: 1,
                    termName: "Term 1",
                    amount: 1000,
                    status: RefundStatus.Pending,
                    updatedAt: new Date().toISOString()
                }
            ]
            const total = data.length
            dispatch({data, total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }, [queryParams])

    const handleRowClick = React.useCallback(
        (row) => {
            history.push(routing.finAid.student.refundDetail(row.profileId, row.id))
        },
        [studentId]
    )

    const tableOptions = React.useMemo(
        () => [
            {key: "getPageTitle", func: () => t("refund.table.title")},
            {key: "getListData", func: getRefundList},
            {
                key: "getFields",
                func: () => [
                    t("refund.table.columns.studentId"),
                    t("refund.table.columns.studentName"),
                    t("refund.table.columns.refundId"),
                    t("refund.table.columns.name"),
                    t("refund.table.columns.type"),
                    t("refund.table.columns.amount"),
                    t("refund.table.columns.updatedAt"),
                    t("refund.table.columns.status"),
                    t("refund.table.columns.action")
                ]
            },
            {key: "onClickRowItem", func: handleRowClick},
            {key: "getTableHeaderActions", func: () => []}
        ],
        [t, getRefundList, handleRowClick]
    )

    const getColumns = React.useCallback(
        () => [
            {
                title: t("refund.table.columns.studentId"),
                field: "studentId",
                sortable: true
            },
            {
                title: t("refund.table.columns.studentName"),
                field: "firstName",
                sortable: true,
                render: (value, record: Refund) => getFullName(record)
            },
            {
                title: t("refund.table.columns.refundId"),
                field: "refundId",
                sortable: true
            },
            {
                title: t("refund.table.columns.name"),
                field: "name",
                sortable: true
            },
            {
                title: t("refund.table.columns.term"),
                field: "termName",
                orderField: "termId",
                sortable: true
            },
            {
                title: t("refund.table.columns.type"),
                field: "type",
                sortable: true,
                render: (value) => value
            },
            {
                title: t("refund.table.columns.description"),
                field: "description",
                sortable: true
            },
            {
                title: t("refund.table.columns.amount"),
                field: "amount",
                sortable: true,
                render: (value: number) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("refund.table.columns.updatedAt"),
                field: "updatedAt",
                sortable: true,
                fieldType: "date",
                format: userDateFormat
            },
            {
                title: t("refund.table.columns.status"),
                field: "status",
                sortable: true,
                render: (value: RefundStatus) => <Status status={value} />
            },
            {
                title: t("refund.table.columns.action"),
                field: "",
                sortable: false,
                render: (_, record: Refund) => (
                    <BaseButton
                        title={t("refund.label.request")}
                        className={styles.btnRequest}
                        disabled={![RefundStatus.Pending, RefundStatus.Rejected].includes(record.status)}
                        size="small"
                    />
                )
            }
        ],
        [t, userDateFormat]
    )

    React.useEffect(() => {
        dispatchFunc([{key: "getColumns", func: getColumns}])
    }, [dispatchFunc, getColumns])

    React.useEffect(() => {
        dispatch({isLoading: true})
        dispatchFunc(tableOptions)
    }, [tableOptions])

    React.useEffect(() => {
        getRefundList()
    }, [getRefundList])

    const handleUpdateRowData = React.useCallback((updatedData) => {
        const data = getCurrentData()
        const newData = data.map((item) => {
            if (item.id === updatedData.id) {
                return {
                    ...updatedData,
                    isActiveTableCol: !!updatedData.isChecked
                }
            }
            return item
        })
        dispatch({data: newData})
    }, [])

    const handleUpdateTableData = React.useCallback((data) => {
        const newData = data.map((item) => {
            return {
                ...item,
                isActiveTableCol: !!item.isChecked
            }
        })
        dispatch({data: newData})
    }, [])

    return (
        <div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={onDraggableColumn}
            />

            <KlassappTable
                columns={columns}
                menuActions={[]}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn
                onClickRowItem={handleRowClick}
                onChangeFields={onChangeFields}
                onUpdateRowData={handleUpdateRowData}
                onUpdateTableData={handleUpdateTableData}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(RefundTable)
