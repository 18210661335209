/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {chatServiceV2} from "services"
import {KlassDropAsyncPaginate, KlassDropdownAsyncProps} from "components/Select"
import {Communication} from "types/communication"
import cx from "classnames"
import styles from "./ChannelSelect.module.css"

type Props = Partial<KlassDropdownAsyncProps> & {}

export const ChannelSelect = (props: Props) => {
    const search = React.useCallback(
        debounce(async (search: string = "", loadedOptions) => {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await chatServiceV2.getSidebar(Communication.RoomTypes.Channel, {
                filter: {
                    search
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        }, 500),
        []
    )

    return (
        <KlassDropAsyncPaginate
            {...props}
            placeholder={props.placeholder || "Select channel"}
            loadOptions={search}
            defaultOptions
            valueKey="roomId"
            labelKey="name"
            className={cx({[styles.readOnly]: props.readOnly})}
        />
    )
}
