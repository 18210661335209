/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {v4 as uuid} from "uuid"
import {useTranslation, withTranslation} from "react-i18next"
import {BaseInput} from "components/inputs"
import {BaseButton, SecondaryButton, AddItemCircleButton} from "components/buttons"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {handleError, toastError, toastWarning} from "helpers"
import {settingEnrollmentTypesService} from "services"
import {KlassappTableHOC} from "HOC"
import {ActivityStatus} from "components/ui"
import {Checkbox} from "antd"
import styles from "./EnrollmentTypes.module.css"

function EnrollmentTypes(props: KlassappTableProps) {
    const [newItemData, setNewItemData] = useState<any>(null)
    const [oldItemData, setOldItemData] = useState<any>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("")
    const {t} = useTranslation(["admission", "user", "common"])

    useEffect(() => {
        props.dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "getTableHeaderActions", func: getTableHeaderActions}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [search])

    useEffect(() => {
        const newData = props.data.map((item) => {
            const isEditItem = item.id === newItemData?.editItem.id
            return {
                ...item,
                nameHtml: isEditItem ? renderTextInput() : item.name,
                statusHtml: isEditItem
                    ? renderIsActiveCheckbox()
                    : renderStatusHtml(item.isActive ? "active" : "inactive")
            }
        })
        props.dispatch({data: newData})
    }, [newItemData])

    const getPageTitle = () => {
        return t("admission.enrollmentTypes.title")
    }

    const getFields = () => {
        return [t("admission.enrollmentTypes.name"), t("admission.enrollmentTypes.active")]
    }

    const getData = async () => {
        try {
            props.dispatch({isLoading: true})
            const params = getParams()
            const {data, total} = await settingEnrollmentTypesService.getAll(params)
            const newData = data.map((item) => ({
                ...item,
                id: item.code,
                nameHtml: item.name,
                statusHtml: renderStatusHtml(item.isActive ? "active" : "inactive")
            }))
            props.dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const renderStatusHtml = (status) => {
        return <ActivityStatus status={status} />
    }

    const getDataSubmit = () => {
        const {nameData, isActiveData} = newItemData
        const submitData = {
            name: nameData,
            isActive: !!isActiveData
        }
        return submitData
    }

    const getParams = () => {
        const {page, pageSize} = props
        const params = {
            filter: {search},
            range: {
                page,
                pageSize
            }
        }
        return params
    }

    const getColumns = () => {
        return [
            {
                title: t("admission.enrollmentTypes.name"),
                field: "nameHtml"
            },
            {
                title: t("admission.enrollmentTypes.active"),
                field: "statusHtml",
                style: {maxWidth: "200px"}
            }
        ]
    }

    const onClickDeleteMulti = async () => {
        const checkedItems = props.getCurrentData().filter((item) => item.isChecked)

        const payload = checkedItems.map((item) => item.code)
        try {
            props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingEnrollmentTypesService.delete({codes: payload})
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const onClickEdit = (editItem) => {
        if (newItemData) {
            toastWarning(t("common:validation.saveBeforeEdit"))
            return
        }
        setNewItemData({
            ...editItem,
            nameData: editItem.name,
            isActiveData: editItem.isActive,
            isEdit: true,
            editItem
        })
        setOldItemData({...editItem})
    }

    const onClickDelete = async (deletedItem) => {
        if (deletedItem.isForm) {
            setNewItemData(null)
            setOldItemData(null)
            await getData()
            return
        }
        try {
            props.dispatch({isLoading: true})
            setNewItemData(null)
            setOldItemData(null)
            await settingEnrollmentTypesService.delete({
                codes: [deletedItem.code]
            })
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
        }
    }

    const renderTextInput = () => {
        return (
            <BaseInput
                value={newItemData.nameData}
                onChange={(value) => {
                    setNewItemData({...newItemData, nameData: value})
                }}
            />
        )
    }

    const onClickAddItem = () => {
        const newItem = {
            id: uuid(),
            nameHtml: renderTextInput(),
            statusHtml: renderIsActiveCheckbox(),
            isForm: true
        }
        const {data} = props
        data.push(newItem)
        setNewItemData(newItem)
        setOldItemData(null)
        props.dispatch({data})
    }

    const validateBeforeSubmit = () => {
        if (!newItemData.nameData) {
            toastError(t("common:validation.cantEmpty"))
            return false
        }
        return true
    }

    const onClickSave = async () => {
        const {isForm, id} = newItemData
        if (validateBeforeSubmit()) {
            const submitData = getDataSubmit()
            if (isForm) {
                await create({...submitData, code: uuid()})
            } else {
                await update(id, submitData)
            }
            setNewItemData(null)
            setOldItemData(null)
            getData()
            props.dispatch({isShowTableHeaderAction: false})
        }
    }

    const create = async (data) => {
        try {
            props.dispatch({isLoading: true})
            setIsSubmitting(true)
            await settingEnrollmentTypesService.create(data)
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
            setIsSubmitting(false)
        }
    }

    const update = async (id, data) => {
        try {
            props.dispatch({isLoading: true})
            setIsSubmitting(true)
            await settingEnrollmentTypesService.update(id, data)
        } catch (e) {
            handleError(e)
        } finally {
            props.dispatch({isLoading: false})
            setIsSubmitting(false)
        }
    }

    const onClickCancel = () => {
        const newData = props.data
            .filter((item) => !item.isForm)
            .map((item) => {
                if (oldItemData && oldItemData.id === item.id) {
                    item.nameHtml = oldItemData.name
                    item.statusHtml = renderStatusHtml(oldItemData.isActive ? "active" : "inactive")
                    return item
                }
                return item
            })
        setNewItemData(null)
        setOldItemData(null)
        props.dispatch({data: newData})
    }

    const getTableHeaderActions = () => {
        const {onClickShowConfirmModal} = props
        const actions = [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
        return actions
    }

    const onSearchInput = (value) => {
        setSearch(value)
    }

    const renderIsActiveCheckbox = () => {
        return (
            <div className={styles.visibleCheckboxWrap}>
                <Checkbox
                    checked={newItemData.isActiveData}
                    onChange={(event) => {
                        setNewItemData({...newItemData, isActiveData: event.target.checked})
                    }}
                />
            </div>
        )
    }

    return (
        <div className={styles.wrapper}>
            <BaseNewFilter searchValue={search} onSearchInput={onSearchInput} />
            <KlassappTableHeader
                isShowAction={props.isShowTableHeaderAction}
                actions={props.tableHeaderActions}
                page={props.page}
                total={props.total}
                defaultPageSize={props.pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={props.fields}
                allFields={props.allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={props.columns}
                data={props.data}
                menuActions={props.isHideMenuActions ? [] : props.menuActions}
                isLoading={props.isLoading}
                fields={props.fields}
                allFields={props.allFields}
                isShowCheckedColumn
                onClickRowItem={() => {}}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                className={styles.tableScrollHidden}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            {!newItemData ? (
                <div className={styles.buttonWrap}>
                    <AddItemCircleButton onClick={onClickAddItem} />
                </div>
            ) : (
                <div className={styles.buttonWrap}>
                    <SecondaryButton
                        title={t("common:action.cancel")}
                        onClick={onClickCancel}
                        className={styles.cancelBtn}
                    />
                    <BaseButton
                        title={t("common:action.save").toUpperCase()}
                        onClick={onClickSave}
                        loading={isSubmitting}
                    />
                </div>
            )}
        </div>
    )
}

export default KlassappTableHOC(EnrollmentTypes)
