import React, {useState} from "react"
import {PanelExpandedSection} from "uiKit/PanelExpandedSection"
import {Tabs} from "antd"
import {useTranslation} from "react-i18next"
import WorkflowTriggersTable from "sections/Workflow/WorkflowTriggersTable/WorkflowTriggersTable"
import {TabHeader} from "components/Tab"
import {ActivitiesList} from "./ActivitiesList"

type DisplayPart = "list" | "create"

const {TabPane} = Tabs

enum Tab {
    Activities = "activities",
    WorkflowTriggers = "workflow-triggers"
}

type Props = {
    studentId: number
    departmentId?: number
    onNavigate?: (displayMode: DisplayPart) => void
    onClose?: () => void
}

export function ActivitiesAndWorkflowTriggers(props: Props) {
    const {studentId, departmentId, onClose, onNavigate} = props
    const {t} = useTranslation(["activities", "common"])
    const [activeTabKey, onChangeTab] = useState<string>(Tab.Activities)

    return (
        <PanelExpandedSection title="Activities" onClose={onClose}>
            <Tabs className="fullwidth" activeKey={activeTabKey} onChange={onChangeTab}>
                <TabPane tab={<TabHeader title={t("activities.title")} />} key={Tab.Activities}>
                    {activeTabKey === Tab.Activities && (
                        <ActivitiesList
                            departmentId={departmentId}
                            studentId={studentId}
                            onAdd={() => onNavigate("create")}
                        />
                    )}
                </TabPane>
                <TabPane tab={<TabHeader title={t("activities.workflowTriggers")} />} key={Tab.WorkflowTriggers}>
                    {activeTabKey === Tab.WorkflowTriggers && (
                        <WorkflowTriggersTable profileId={studentId} departmentId={departmentId} displayMode="panel" />
                    )}
                </TabPane>
            </Tabs>
        </PanelExpandedSection>
    )
}
