/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import moment from "moment"
import cx from "classnames"
import {useHistory} from "react-router-dom"
import {useTranslation} from "react-i18next"

import styles from "./StudentKanBanView.module.css"
import {BaseLoading} from "components/Loading"
import {Icon} from "components/Icon"
import {BasePopup} from "components/popup"
import {BaseButton, SecondaryButton} from "components/buttons"
import {KlassDropdown} from "components/Select"
import {settingStageService, studentService} from "services"
import {useAllStudentDepartmentStatuses, useModel} from "hooks"
import {Auth} from "types/auth"
import {BaseDepartmentId} from "types/departments"
import {Order} from "types/common"
import {getFullName, handleError, handleErrorMessage, truncateWithEllipses} from "helpers"
import {isEmpty} from "lodash"

type StudentKanBanViewProps = {
    departmentId: number
    studentForm: any
    studentFormSearch: string
    canShowAllStudents: boolean
    canHaveAccessOthers: boolean
    updateState: (state: any) => void
    detailUrl: string
}

type BoardProps = {
    data: any
    goToDetail
    onDragStart
    onDragOver
    onDrop
    loadStudent
    activeStudentProfileId: number
    onScroll
}

type CardProps = {
    stageId: number
    data: any
    goToDetail
    onDragStart
    loadStudent
    activeStudentProfileId: number
}

const DepartmentPrefix = {
    [BaseDepartmentId.Admissions]: "admission",
    [BaseDepartmentId.FinancialAid]: "finAid"
}

const Card = (props: CardProps) => {
    const {
        stageId,
        data: {userId, profileId, studentName, programName, advisorName, startDate, status},
        onDragStart,
        loadStudent,
        activeStudentProfileId,
        goToDetail
    } = props
    const {t} = useTranslation(["admission"])

    return (
        <div
            key={profileId}
            className={cx(styles.card, {
                [styles.cardSelected]: profileId === activeStudentProfileId
            })}
            draggable
            onDragStart={(event) => onDragStart(event, stageId, userId)}
            onClick={() => (profileId ? loadStudent(profileId) : null)}>
            <div className={styles.cardHeader}>
                <div>
                    <span className={styles.cardCode}>{userId}</span>
                    <span className={styles.studentName}>{studentName}</span>
                </div>
                <span className={styles.viewDetailsContainer} onClick={() => goToDetail(profileId)}>
                    <Icon className={styles.iconViewDetails} icon="VIEW_DETAILS" />
                </span>
            </div>
            <div className={styles.dataRow}>
                <div className={styles.dataContainer}>
                    <span className={styles.label}>{t("admission:kanban.program")}</span>
                    <span className={styles.value}>{programName ?? ""}&nbsp;</span>
                </div>
                <div className={styles.dataContainer}>
                    <span className={styles.label}>{t("admission:kanban.advisor")}</span>
                    <span className={styles.value}>{advisorName ?? ""}&nbsp;</span>
                </div>
            </div>
            <div className={styles.dataRow}>
                <div className={styles.dataContainer}>
                    <span className={styles.label}>{t("admission:kanban.startDate")}</span>
                    <span className={styles.value}>
                        {startDate ? moment(startDate).format("MM/DD/YYYY") : ""}&nbsp;
                    </span>
                </div>
                <div className={styles.dataContainer}>
                    <span className={styles.label}>{t("admission:kanban.status")}</span>
                    <div>
                        <span
                            className={cx({
                                [styles.statusHTML]: (status ?? "").trim() !== ""
                            })}>
                            {truncateWithEllipses(status ?? "", 15, 7, 7)}&nbsp;
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Board = (props: BoardProps) => {
    const {
        data: {stageId, name, count, data},
        onDragStart,
        onDragOver,
        onDrop,
        loadStudent,
        activeStudentProfileId,
        goToDetail,
        onScroll
    } = props

    return (
        <div
            key={stageId}
            className={styles.boardContainer}
            onDragOver={(event) => onDragOver(event, stageId)}
            onDrop={(event) => onDrop(event, stageId)}>
            <div className={styles.boardHeaderContainer}>
                <div className={styles.boardHeaderLeftContainer}>
                    <div className={styles.boardHeaderIcon}></div>
                    {name.toUpperCase()}
                </div>
                <span className={styles.boardHeaderCount}>{count ?? 0}</span>
            </div>
            <div
                className={styles.boardCardsContainer}
                onScroll={(event) => onScroll(event, stageId, data?.length ?? 0)}>
                {data?.map((el) => (
                    <Card
                        key={el.profileId}
                        stageId={stageId}
                        data={el}
                        goToDetail={goToDetail}
                        onDragStart={onDragStart}
                        loadStudent={loadStudent}
                        activeStudentProfileId={activeStudentProfileId}
                    />
                ))}
            </div>
        </div>
    )
}

export function StudentKanBanView(props: StudentKanBanViewProps) {
    const {
        departmentId,
        studentForm,
        studentFormSearch,
        canShowAllStudents,
        canHaveAccessOthers,
        updateState,
        detailUrl
    } = props
    const {t} = useTranslation(["common", "admission"])
    const history = useHistory()

    const model = useModel()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [stages, setStages] = useState([])
    const {statuses} = useAllStudentDepartmentStatuses()
    const [stageIndex, setStageIndex] = useState([])
    const [data, setData] = useState([])
    const [draggedData, setDraggedData] = useState(null)
    const [activeStudentProfileId, setActiveStudentProfileId] = useState<number>(null)
    const [isVisibleConfirmPopup, setIsVisibleConfirmPopup] = useState<boolean>(false)
    const [movedToStatus, setMovedToStatus] = useState(null)
    const [isSubmittingPopup, setIsSubmittingPopup] = useState<boolean>(false)

    useEffect(() => {
        getStages()
    }, [])

    useEffect(() => {
        if (stages?.length) {
            getStudents()
        }
    }, [studentForm, studentFormSearch, stages?.length])

    function goToDetail(profileId: number) {
        history.push(`/${detailUrl}/student/${profileId}`)
    }

    async function getStages() {
        setIsLoading(true)
        try {
            const params = {
                filter: {
                    departmentId
                },
                sort: {
                    orderBy: "position",
                    orderDir: Order.Asc
                }
            }
            let {data} = await settingStageService.getDepartmentStagesAll(params)
            if (data) {
                data.unshift({
                    stageId: 0,
                    name: "Not Assigned",
                    departmentId
                })

                const filterParams = getFilter()
                const {total} = await studentService.getDepartmentStudents({
                    filter: {
                        ...filterParams,
                        isNotAssignedAdmissionStageId: true
                    }
                })

                let newStageIndex: string[] = []
                let newData = data.map((el) => {
                    newStageIndex.push(el.name)
                    return {
                        stageId: el.stageId,
                        name: el.name,
                        count: el.stageId === 0 ? total ?? 0 : el.studentCount,
                        data: []
                    }
                })
                setStageIndex(newStageIndex)
                setData(newData)
                setStages(data)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const getFilter = () => {
        const filters = {...studentForm, ...model.filterUserList}
        const {
            campus,
            program,
            advisor,
            term,
            startingTerm,
            checklist,
            status,
            state,
            profileStatus,
            applicationCompletedDate,
            startDateRange,
            createdAtRange,
            inquiryDateRange,
            lastActivityDateRange,
            activityIds,
            activityDescription,
            studentStatusIds
        } = filters
        let filter: any = {
            departmentId,
            activityDescription
        }
        if (campus) {
            filter.campusIds = campus.map(({id}) => id)
        }
        if (program) {
            filter.programIds = program.map(({id}) => id)
        }
        if (studentStatusIds) {
            filter.studentStatusIds = studentStatusIds.map(({statusId}) => statusId)
        }

        if (!canShowAllStudents) {
            const profile = model.user.profiles.find((profile) => profile.type === Auth.UserProfileType.Staff)
            if (!canHaveAccessOthers) {
                filter.advisorProfileIds = [profile.id]
            } else if (!isEmpty(advisor)) {
                filter.advisorProfileIds = (advisor || []).map((item) => item?.profileId).filter((item) => item)
            } else {
                filter.advisorProfileIds = [profile.id]
            }
        } else if (!isEmpty(advisor)) {
            filter.advisorProfileIds = (advisor || []).map((item) => item?.profileId).filter((item) => item)
        } else {
            filter.advisorProfileIds = []
        }

        if (checklist === "completed") {
            filter.checklistCompleted = true
        }
        if (checklist === "notCompleted") {
            filter.checklistCompleted = false
        }
        if (status?.length) {
            filter.statusIds = status.map(({statusId}) => statusId)
        }
        if (state) {
            filter.states = state.map(({id}) => id)
        }
        if (profileStatus) {
            filter.profileStatus = profileStatus.map(({id}) => id === "active")
        }
        if (term) {
            filter.termsIds = term.map(({id}) => id)
        }
        if (startingTerm) {
            filter.startingTermIds = startingTerm.map(({id}) => id)
        }
        if (activityIds) {
            filter.activityIds = activityIds.map(({activityId}) => activityId)
        }
        if (applicationCompletedDate) {
            filter.applicationCompletedDate = moment(applicationCompletedDate).format("YYYY-MM-DD")
        }
        if (startDateRange && startDateRange.length) {
            filter.startDateRange = [
                moment(startDateRange[0]).format("YYYY-MM-DD"),
                moment(startDateRange[1]).format("YYYY-MM-DD")
            ]
        }
        if (createdAtRange && createdAtRange.length) {
            filter.createdAtRange = [
                moment(createdAtRange[0]).startOf("date").toISOString(),
                moment(createdAtRange[1]).endOf("date").toISOString()
            ]
        }
        if (inquiryDateRange && inquiryDateRange.length) {
            filter.inquiryDateRange = [
                moment(inquiryDateRange[0]).format("YYYY-MM-DD"),
                moment(inquiryDateRange[1]).format("YYYY-MM-DD")
            ]
        }
        if (lastActivityDateRange && lastActivityDateRange.length) {
            filter.lastActivityDateRange = [
                moment(lastActivityDateRange[0]).startOf("date").toISOString(),
                moment(lastActivityDateRange[1]).endOf("date").toISOString()
            ]
        }
        if (studentFormSearch) {
            filter.search = studentFormSearch
        }
        return filter
    }

    async function getStudents(filterStages?) {
        setIsLoading(true)
        const filterParams = getFilter()
        try {
            let stages: any = []

            let newData = data?.map((el) => {
                let data = []
                if (!filterStages?.length) {
                    stages.push({
                        stageId: el.stageId,
                        fetchedCount: 0,
                        pageSize: 20
                    })
                } else {
                    let filteredStage = filterStages?.find((filterStage) => el.stageId === filterStage.stageId)
                    if (filteredStage) {
                        stages.push({
                            stageId: filteredStage.stageId,
                            fetchedCount: filteredStage.fetchedCount,
                            pageSize: filteredStage.pageSize,
                            isReload: filteredStage.isReload
                        })
                        if (!filteredStage.isReload) {
                            data = el.data
                        } else if (filteredStage.isDataChanged) {
                            let filteredStageIndex = filterStages?.findIndex(
                                (filterStage) => el.stageId === filterStage.stageId
                            )
                            if (filteredStageIndex === 0) {
                                el.count = el.count - 1
                            } else if (filteredStageIndex === 1) {
                                el.count = el.count + 1
                            }
                        }
                    } else {
                        data = el.data
                    }
                }

                return {
                    ...el,
                    data
                }
            })

            const result: any = await Promise.all(
                stages.map((stage) => {
                    return studentService.getDepartmentStudents({
                        filter: {
                            ...filterParams,
                            [`${DepartmentPrefix[departmentId]}StageId`]: stage.stageId,
                            isNotAssignedAdmissionStageId: stage.stageId === 0
                        },
                        range: {
                            page: Math.ceil(stage.fetchedCount / stage.pageSize) + 1,
                            pageSize: stage.pageSize
                        },
                        sort: {
                            orderBy: "profileId",
                            orderDir: "asc"
                        }
                    })
                })
            )

            stages.forEach((stage, index) => {
                let data = result[index]
                if (data?.data?.length) {
                    data.data.forEach((student, index) => {
                        const studentFormatted = {
                            id: student.userId,
                            userId: student.userId,
                            profileId: student.profileId,
                            code: null,
                            studentName: getFullName(student),
                            programName: student.programName,
                            advisorName: student[
                                `${
                                    DepartmentPrefix[departmentId] === DepartmentPrefix[1]
                                        ? "admision"
                                        : DepartmentPrefix[departmentId]
                                }Advisors`
                            ]
                                ?.map((advisor) => getFullName(advisor))
                                ?.join(", "),
                            startDate: student.startDate,
                            status: student[`${DepartmentPrefix[departmentId]}Status`],
                            isActiveTableCol: index === 0,
                            stageName: student[`${DepartmentPrefix[departmentId]}Stage`]
                        }

                        const currentStudentStageIndex = stageIndex.indexOf(
                            student[`${DepartmentPrefix[departmentId]}Stage`]
                        )
                        if (currentStudentStageIndex >= 0) {
                            newData[currentStudentStageIndex].data.push(studentFormatted)
                        } else {
                            newData[0].data.push(studentFormatted)
                        }
                    })
                }
            })

            if (!filterStages?.length) {
                let studentProfileId: number = null
                newData?.forEach((el) => {
                    if (el.data?.length && !studentProfileId) {
                        studentProfileId = el.data[0].profileId
                    }
                })
                if (studentProfileId) {
                    await loadStudent(studentProfileId)
                }
            }
            setData(newData)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    async function loadStudent(studentProfileId: number) {
        try {
            const {
                data: [activeStudent]
            } = await studentService.getDepartmentStudents({
                filter: {departmentId: props.departmentId, profileIds: [studentProfileId]}
            })
            setActiveStudentProfileId(studentProfileId)
            updateState({activeStudent})
        } catch (error) {
            handleError(error)
        }
    }

    const onScroll = (event, stageId: number, fetchedCount: number) => {
        const bottom = event.target.scrollHeight - (event.target.scrollTop + 1) === event.target.clientHeight
        if (bottom) {
            getStudents([{fetchedCount, pageSize: 20, isReload: false, stageId}])
        }
    }

    const onDragStart = (event, stageId, userId) => {
        event.dataTransfer.setData("stageId", stageId)
        event.dataTransfer.setData("userId", userId)
    }

    const onDragOver = (event, stageId) => {
        event.preventDefault()
    }

    const onDrop = (event, stageId: number) => {
        if (stageId === 0) {
            handleErrorMessage(t("admission:kanban.cannotAssigningStatus"))
            return onClosePopup()
        }
        let stageIdPrevious = event.dataTransfer.getData("stageId")
        if (stageIdPrevious) {
            stageIdPrevious = parseInt(stageIdPrevious)
        }
        if (stageIdPrevious === stageId) {
            return onClosePopup()
        }
        let userId = event.dataTransfer.getData("userId")
        if (userId) {
            userId = parseInt(userId)
        }

        let draggedCard: any = null
        let draggedFromStage: string = null
        let draggedFromStatus: string = null
        let draggedToStage: string = null
        data.forEach((board) => {
            if (board.stageId === stageIdPrevious) {
                draggedFromStage = board.name
                board.data.forEach((card) => {
                    if (card.userId === userId) {
                        draggedCard = card
                        draggedFromStatus = card.status
                    }
                })
            }
        })
        if (stageIdPrevious === 0) {
            draggedFromStatus = "Not Assigned"
        }
        let newBoards = data.map((board) => {
            let newBoardData = board
            if (board.stageId === stageId) {
                draggedToStage = board.name
                if (!board.data?.length) {
                    board.data = []
                }
                board.data.push(draggedCard)
            } else if (board.stageId === stageIdPrevious) {
                newBoardData.data = board.data.filter((card) => card.userId !== userId)
            }

            newBoardData.data = board.data.filter((card) => card)

            return newBoardData
        })

        setDraggedData({
            draggedCard,
            draggedFromStage,
            draggedFromStageId: stageIdPrevious,
            draggedToStage,
            draggedToStageId: stageId,
            draggedFromStatus
        })
        setData(newBoards)
        setIsVisibleConfirmPopup(true)
    }

    const onClosePopup = (isDataChanged?: boolean) => {
        const {draggedFromStageId, draggedToStageId} = draggedData
        let draggedFromStageDataLength: number = 20
        let draggedToStageDataLength: number = 20
        data?.forEach((el) => {
            if (el.stageId === draggedFromStageId && el.data?.length) {
                draggedFromStageDataLength = el.data.length + 1
            }
            if (el.stageId === draggedToStageId && el.data?.length) {
                draggedToStageDataLength = el.data?.length + 1
            }
        })

        setDraggedData(null)
        setMovedToStatus(null)
        getStudents([
            {
                fetchedCount: 0,
                pageSize: draggedFromStageDataLength,
                isReload: true,
                isDataChanged,
                stageId: draggedFromStageId
            },
            {
                fetchedCount: 0,
                pageSize: draggedToStageDataLength,
                isReload: true,
                isDataChanged,
                stageId: draggedToStageId
            }
        ])
        setIsSubmittingPopup(false)
        setIsVisibleConfirmPopup(false)
    }

    const onClickSavePopup = async () => {
        if (!movedToStatus) {
            return handleErrorMessage(t("admission:kanban.statusValidation"))
        }

        setIsSubmittingPopup(true)
        try {
            const {
                draggedCard: {profileId}
            } = draggedData
            const {statusId} = movedToStatus
            if (!profileId || !statusId) {
                handleErrorMessage(t("admission:kanban.errorAssigningStatus"))
                return onClosePopup()
            }

            await studentService.updateStudentStatus(profileId, {statusId})
            onClosePopup(true)
        } catch (error) {
            handleError(error)
            onClosePopup()
        }
    }

    return (
        <div className={styles.parentDiv}>
            <BaseLoading isShow={isLoading} />
            <div className={styles.container}>
                {data.map((el) => (
                    <Board
                        key={el.stageId}
                        data={el}
                        goToDetail={goToDetail}
                        onDragStart={onDragStart}
                        onDragOver={onDragOver}
                        onDrop={onDrop}
                        loadStudent={loadStudent}
                        activeStudentProfileId={activeStudentProfileId}
                        onScroll={onScroll}
                    />
                ))}
            </div>
            <BasePopup
                isShow={isVisibleConfirmPopup}
                onClose={() => onClosePopup()}
                isShowLeftSide={false}
                closable={false}
                width="35vw"
                className={styles.popup}>
                <div className={styles.popupContainer}>
                    <div>
                        <Icon className={styles.iconConfirm} icon="CONFIRM_INFO" />
                    </div>
                    <span className={cx(styles.popupRow, styles.popupTitle)}>
                        {t("admission:kanban.statusChangeConfirmQuestion")}
                    </span>
                    <span className={cx(styles.popupRow, styles.popupStudentNameContainer)}>
                        <span className={styles.popupStudentCode}>{draggedData?.draggedCard?.userId}</span>
                        <span>{draggedData?.draggedCard?.studentName}</span>
                    </span>
                    <span className={cx(styles.popupRow, styles.popupStatusContainer)}>
                        from
                        <span className={styles.popupFromStatus}>
                            {truncateWithEllipses(draggedData?.draggedFromStatus ?? "", 15, 7, 7)}
                        </span>
                        to
                        <KlassDropdown
                            onChange={(selectedValue) => setMovedToStatus(selectedValue)}
                            options={statuses.filter((status) => status.stageId === draggedData?.draggedToStageId)}
                            value={movedToStatus}
                            closeMenuOnSelect
                            className={styles.popupToStatus}
                        />
                    </span>
                    <div className={styles.actionDetailWrapPopup}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            className={styles.cancelBtn}
                            onClick={() => onClosePopup()}
                        />
                        <BaseButton
                            title={t("common:action.confirm")}
                            uppercase
                            loading={isSubmittingPopup}
                            onClick={onClickSavePopup}
                        />
                    </div>
                </div>
            </BasePopup>
        </div>
    )
}
