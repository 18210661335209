/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {KlassDropdown} from "components/Select"
import {translate} from "helpers"
import styles from "./ViewOptions.module.css"

type ViewOptionsProps = {
    viewOptions: any[]
    t: Function
    onChange: (value: string) => void
    updateViewTo?: string
}

const VIEW_OPTIONS = [
    {value: "DAY", label: translate("calendar:calendar.options.Day")},
    {value: "WEEK", label: translate("calendar:calendar.options.Week")},
    {value: "MONTH", label: translate("calendar:calendar.options.Month")}
    // {value: "YEAR", label: t("calendar.options.Year")}
]

export const ViewOptions = ({viewOptions = null, t, onChange, updateViewTo}: ViewOptionsProps) => {
    const options = viewOptions || VIEW_OPTIONS
    const [view, setView] = useState(VIEW_OPTIONS[1])

    const onChangeCalendarView = (v) => {
        onChange(v.value)
        setView(v)
    }

    useEffect(() => {
        if (updateViewTo) {
            const newView = VIEW_OPTIONS.find((v) => v.value === updateViewTo)
            if (newView) {
                onChangeCalendarView(newView)
            }
        }
    }, [updateViewTo])

    return (
        <div className={styles.weekOptions}>
            <KlassDropdown
                value={view}
                placeholder=""
                closeMenuOnSelect
                defaultValue={[VIEW_OPTIONS[1]]}
                onChange={onChangeCalendarView}
                options={options}
                valueKey="value"
                labelKey="label"
            />
        </div>
    )
}
