import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {degreeLevelService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {Order} from "types/common"

const queryCacheKey = "all-degree-levels"
const INITIAL_DATA = []

export const useAllDegreeLevels = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data: degreeLevels} = await degreeLevelService.getAll({
                sort: {
                    orderBy: "name",
                    orderDir: Order.Asc
                },
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                }
            })
            return degreeLevels.map((item) => ({...item, id: item.degreeLevelId}))
        },
        {
            refetchOnMount: false
        }
    )

    return {
        degreeLevels: query.data ?? INITIAL_DATA,
        invalidateDegreeLevelsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
