import React, {useEffect, useMemo} from "react"
import {isArray, size} from "lodash"
import {Checkbox} from "antd"
import moment from "moment"
import get from "lodash/get"
import cx from "classnames"
import {Icon} from "components"
import ActionRowItem from "./ActionRowItem"
import styles from "../KlassappTable.module.css"
import {useModel} from "hooks"
import {BaseInput} from "components/inputs"
import {KlassappTableDropdown} from "uiKit"

export default function TableRowItem(props) {
    const model = useModel()
    const {
        columns,
        data,
        menuActions,
        isShowCheckedColumn,
        onToggleCheckBox,
        isShowColumnPicker,
        fields,
        onClickRowItem,
        onDoubleClickRowItem,
        classNameActionMenu,
        onUpdateRowData,
        isDraggable,
        snapshot,
        provided,
        expandable,
        onChangeCellInput,
        keyRender,
        colSpanAll,
        expandableKey,
        expandLevel = 0,
        defaultExpandable,
        expandIndent
    } = props
    const [expanded, setExpanded] = React.useState(expandable ? !!expandable?.isExpanded : defaultExpandable)
    const currentUserDateFormat = model && model.getUserDateFormat && model.getUserDateFormat()
    const currentUserDateTimeFormat = model && model.getUserDateTimeFormat && model.getUserDateTimeFormat()

    useEffect(() => {
        setExpanded(!!expandable?.isExpanded)
    }, [expandable?.isExpanded])

    const canExpandable = useMemo(() => {
        return expandableKey && isArray(data[expandableKey]) && size(data[expandableKey]) > 0
    }, [expandableKey, data])

    const onToggleCheck = (checked) => {
        const newData = {...data}
        newData.isChecked = checked
        if (onToggleCheckBox) {
            onToggleCheckBox(newData)
        } else {
            onUpdateRowData(newData, data)
        }
    }

    const onUpdateValue = (field) => {
        return (value) => {
            const newData = {...data}
            newData[field] = value
            onUpdateRowData(newData, data)
        }
    }

    const renderTextInput = (column, id, inputValue) => {
        const {field, type, min, disabledArrow, readOnly} = column
        let extraProp: object = {
            disabledArrow,
            readOnly
        }
        if (type) {
            extraProp = {
                ...extraProp,
                type
            }
        }
        if (min) {
            extraProp = {
                ...extraProp,
                min: parseInt(min)
            }
        }

        return <BaseInput value={inputValue} onChange={(value) => onChangeCellInput(field, id, value)} {...extraProp} />
    }

    const renderSelectInput = ({field, options, idKey}, id, inputValue) => {
        return (
            <KlassappTableDropdown
                options={options}
                value={options.filter((el) => {
                    return el[idKey] === inputValue
                })}
                onChange={(value) => onChangeCellInput(field, id, value[idKey])}
            />
        )
    }

    const renderDataByType = (column, data) => {
        const {fieldType, field, format, render} = column
        const value = get(data, field)
        if (render) {
            return render(value, data, onUpdateValue(field))
        }
        switch (fieldType) {
            case "dateUtc":
                const formatDateUtc = currentUserDateFormat || format
                if (!value) {
                    return ""
                }
                if (moment(value).isValid()) {
                    return moment.utc(value).format(formatDateUtc)
                }
                return ""
            case "date":
                const formatDate = currentUserDateFormat || format
                return value ? moment(value).format(formatDate) : ""
            case "dateTime":
                return value ? moment(value).format(currentUserDateTimeFormat || format) : ""
            case "dateTimeUtc":
                return value ? moment.utc(value).format(currentUserDateTimeFormat || format) : ""
            case "withSubtext":
                return (
                    <>
                        <p>{value || ""}</p>
                        <p className={cx(styles.subText)}>{data.subText || ""}</p>
                    </>
                )
            case "inputText":
                return renderTextInput(column, data[keyRender], value)
            case "inputDropdown":
                return renderSelectInput(column, data[keyRender], value)
            case "numberWithZero":
                return value
            default:
                return value ?? ""
        }
    }

    const toggleExpand = React.useCallback(() => {
        setExpanded((expanded) => !expanded)
    }, [])

    const filteredMenuActions = (menuActions || []).filter(
        (menuAction) => !menuAction.canShow || menuAction.canShow(data)
    )

    const renderExpandColumn = (column, index) => {
        const isFirstExpandColumn = canExpandable && index === 0
        return (
            <div
                className={styles.containerExpandIconWithColumn}
                style={{paddingLeft: !!expandableKey && index === 0 ? `${expandLevel * expandIndent}px` : 0}}>
                {(!!expandable?.rowExpandable?.(data) || isFirstExpandColumn) && (
                    <div
                        className={styles.arrowWrap}
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            toggleExpand()
                        }}>
                        <Icon icon={expanded ? "ARROW_DOWN" : "ARROW_RIGHT"} className={styles.arrowIcon} />
                    </div>
                )}
                {renderDataByType(column, data)}
            </div>
        )
    }

    const renderColumn = (column, index) => {
        if ((!!expandable?.expandedRowRender && index === expandable?.expandColumnSequence) || canExpandable) {
            return renderExpandColumn(column, index)
        }
        if (!!expandableKey && expandLevel > 0 && index === 0) {
            return (
                <div style={{paddingLeft: `${(expandLevel + 1) * expandIndent}px`}}>
                    {renderDataByType(column, data)}
                </div>
            )
        }
        return renderDataByType(column, data)
    }

    const renderRowItem = () => {
        return (
            <>
                {isShowCheckedColumn && (
                    <td className={cx(styles.tableCell, styles.expandIconCell, data.cellTableStyle)}>
                        {data.isDisableCheckBox || expandLevel > 0 ? null : (
                            <Checkbox
                                checked={data.isChecked}
                                onChange={(event) => onToggleCheck(event.target.checked)}
                            />
                        )}
                    </td>
                )}
                {!!expandable?.expandedRowRender && !expandable?.expandColumnSequence && (
                    <td className={cx(styles.tableCell, styles.expandIconCell, data.cellTableStyle)}>
                        {!!expandable?.rowExpandable?.(data) && (
                            <div className={styles.arrowWrap} onClick={toggleExpand}>
                                <Icon icon={expanded ? "ARROW_DOWN" : "ARROW_RIGHT"} className={styles.arrowIcon} />
                            </div>
                        )}
                    </td>
                )}
                {colSpanAll &&
                    colSpanAll.map(
                        (field, index) =>
                            Object.keys(data).includes(field) && (
                                <td
                                    key={index}
                                    colSpan={columns.length}
                                    className={cx(styles.tableCell, data.cellTableStyle)}
                                    onClick={!data.notClickRow ? () => onClickRowItem?.(data, expandLevel) : undefined}
                                    onDoubleClick={() => onDoubleClickRowItem?.(data, expandLevel)}>
                                    {get(data, field) ?? ""}
                                </td>
                            )
                    )}
                {!colSpanAll &&
                    columns.map(
                        (column, index) =>
                            fields.includes(column.title) && (
                                <td
                                    key={index}
                                    className={cx(styles.tableCell, column.classNameBody, data.cellTableStyle)}
                                    style={column.style}
                                    onClick={!data.notClickRow ? () => onClickRowItem?.(data, expandLevel) : undefined}
                                    onDoubleClick={() => onDoubleClickRowItem?.(data, expandLevel)}>
                                    {renderColumn(column, index)}
                                </td>
                            )
                    )}
                {filteredMenuActions && filteredMenuActions.length ? (
                    <td className={cx(styles.tableCell, data.cellTableStyle, classNameActionMenu)}>
                        {expandLevel > 0 ? null : (
                            <div className={styles.flexEnd}>
                                <ActionRowItem data={data} menuActions={filteredMenuActions} />
                            </div>
                        )}
                    </td>
                ) : isShowColumnPicker ? (
                    <td
                        className={cx(styles.tableCell, data.cellTableStyle, classNameActionMenu)}
                        onClick={!data.notClickRow ? () => onClickRowItem?.(data, expandLevel) : undefined}></td>
                ) : null}
            </>
        )
    }

    if (isDraggable) {
        return (
            <tr
                key={data[keyRender]}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={cx(
                    styles.tableRow,
                    data.isActiveTableCol ? styles.tableRowActive : "",
                    snapshot.isDragging ? styles.tableRowDragging : "",
                    data.rowClassName
                )}
                style={{...provided.draggableProps?.style, ...provided.dragHandleProps?.style, ...data.rowStyle}}>
                {renderRowItem()}
            </tr>
        )
    }

    let expandRowColSpan = columns.filter((column) => fields.includes(column.title)).length
    if (isShowCheckedColumn) {
        expandRowColSpan += 1
    }
    if (!!expandable?.expandedRowRender) {
        expandRowColSpan += 1
    }
    if ((filteredMenuActions && filteredMenuActions.length) || isShowColumnPicker) {
        expandRowColSpan += 1
    }

    return (
        <>
            <tr
                key={data[keyRender]}
                className={cx(
                    styles.tableRow,
                    {
                        [styles.tableRowActive]: data.isActiveTableCol,
                        [styles.warningBackground]: !!data.warning,
                        [styles.withoutCursor]: !!data.notClickRow || !onClickRowItem,
                        [styles.tableRowInactive]: data.isInacctiveTableCol
                    },
                    data.rowClassName
                )}
                style={data.rowStyle}>
                {renderRowItem()}
            </tr>
            {!!expandable?.expandedRowRender && (
                <tr key={`${data[keyRender]}-expand`} className={data.expandedRowClass}>
                    <td colSpan={expandRowColSpan}>{expandable?.expandedRowRender?.(data, expanded)}</td>
                </tr>
            )}
            {canExpandable &&
                expanded &&
                data[expandableKey].map((rowItem, index) => (
                    <TableRowItem
                        key={rowItem[keyRender] || index}
                        {...props}
                        data={rowItem}
                        expandLevel={expandLevel + 1}
                    />
                ))}
        </>
    )
}
