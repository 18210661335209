import {useQuery} from "@tanstack/react-query"
import {settingEnrollmentTypesService} from "services"
import {EnrollmentType} from "types/admissions"

const INITIAL_DATA: EnrollmentType[] = []

export const useAllEnrollmentTypes = () => {
    const query = useQuery(
        ["all-active-enrollment-types"],
        async () => {
            const {data} = await settingEnrollmentTypesService.getAllActive()
            return data.map((item) => ({...item, id: item.code}))
        },
        {refetchOnMount: false}
    )

    return {enrollmentTypes: query.data ?? INITIAL_DATA, isLoading: query.isFetching}
}
