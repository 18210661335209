import React from "react"
import {Button, ButtonProps} from "antd"
import cx from "classnames"
import styles from "./BaseButton.module.css"

export type BaseButtonProps = ButtonProps & {
    title: string
    variant?: "primary" | "secondary"
    htmlType?: "submit" | "button" | "reset"
    className?: string
    isActive?: boolean
    uppercase?: boolean
    bold?: boolean
    disabled?: boolean
    loading?: boolean
    href?: string
    target?: string
    onClick?: any
    icon?: JSX.Element
    children?: JSX.Element
}

export function BaseButton(props: BaseButtonProps) {
    const {
        title,
        className,
        isActive = true,
        uppercase = true,
        bold = true,
        variant = "primary",
        disabled = false,
        ...rest
    } = props

    return (
        <Button
            className={cx(styles.baseButton, styles[variant], className, {
                [styles.isActive]: isActive && !disabled,
                [styles.disabled]: !isActive || disabled,
                [styles.uppercase]: uppercase,
                [styles.bold]: bold
            })}
            {...rest}>
            {title}
            {props.children}
        </Button>
    )
}

BaseButton.defaultProps = {
    variant: "primary",
    isActive: true,
    uppercase: true,
    bold: true
}
