/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import cx from "classnames"
import React, {useEffect, useState} from "react"
import {courseService, aiSchedulingParametersService} from "services"
import styles from "./AICourseScheduling.module.css"
import {KlassappTableEditable, KlassappTableHeader} from "uiKit"
import {BaseButton, SecondaryButton} from "components/buttons"
import {Checkbox} from "components/ui"
import {useTranslation} from "react-i18next"
import {KlassappTableHOC} from "HOC"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import {handleError} from "helpers"

import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"

const AiSchedulingOtion = ({id, index, order, code, isActive, t}) => {
    return (
        <Draggable draggableId={`draggable-${id}`} index={index} key={`draggable-${id}`}>
            {(provided, snapshot) => (
                <div
                    className={cx(styles.options_container, {
                        [styles.draggingItem]: snapshot.isDragging
                    })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    {!!isActive && <span className={styles.filters_priority}> {order} </span>}
                    <span
                        className={cx(styles.filter_option, {
                            [styles.filter_option_active]: isActive
                        })}>
                        <Checkbox value={isActive} className={styles.checkbox}></Checkbox>{" "}
                        {t(`academics:settings.aiCourseScheduling.parameters.${code}`)}
                    </span>
                </div>
            )}
        </Draggable>
    )
}

function AICourseScheduling(props) {
    const [params, setParams] = useState([])
    const [courseMinStudents, setCourseMinStudents] = useState({})
    const [isChanged, setIsChanged] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        isHideMenuActions,
        menuActions,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction
    } = props

    const {t} = useTranslation(["course"])
    const getFields = () => {
        return [
            t("academics:settings.aiCourseScheduling.code"),
            t("academics:settings.aiCourseScheduling.name"),
            t("academics:settings.aiCourseScheduling.numStudents")
        ]
    }

    const handleCourseMinStudentsChange = React.useCallback(
        (courseId, value) => {
            const newCourseMinStudents = {...courseMinStudents}
            newCourseMinStudents[+courseId] = +value

            const index = data.findIndex((course) => course.id === courseId)
            data[index].minOfStudents = +value

            setCourseMinStudents(newCourseMinStudents)
            setIsChanged(true)
        },
        [courseMinStudents, data]
    )

    const getColumns = () => {
        return [
            {
                title: t("academics:settings.aiCourseScheduling.code"),
                field: "code",
                style: {minWidth: "20%"},
                headerStyle: {minWidth: "20%"}
            },
            {
                title: t("academics:settings.aiCourseScheduling.name"),
                field: "name",
                style: {minWidth: "50%"},
                headerStyle: {minWidth: "50%"}
            },
            {
                title: t("academics:settings.aiCourseScheduling.numStudents"),
                field: "minOfStudents",
                style: {minWidth: "30%"},
                headerStyle: {minWidth: "30%"},
                fieldType: "inputText"
            }
        ]
    }

    const getParams = () => {
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {
                status: "active"
            }
        }
        return params
    }

    const simplifyAiSchedulingData = (data) => {
        return data.map((item) => {
            item.id = item.aiSchedulingParameterId
            return item
        })
    }
    const getData = async () => {
        dispatch({isLoading: true})
        setParams([])
        try {
            const params = getParams()
            const {data, total} = await courseService.getAll(params)
            const fixedCourses: any = data.map((item) => {
                const newItem: any = {...item}
                newItem.id = newItem.courseId
                return newItem
            })
            const response = await aiSchedulingParametersService.getAll({})
            setParams(simplifyAiSchedulingData(response.data))
            dispatch({data: fixedCourses, total: total})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "handleCourseMinStudentsChange", func: handleCourseMinStudentsChange}
        ])
    }, [data])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const removeParam = (list, option) => {
        const newList = [...list]

        const index = newList.findIndex((item) => item.id === option.id)
        delete newList[index]

        let order = 1
        return newList
            .filter((item) => item)
            .sort((a, b) => a.order - b.order)
            .map((item) => {
                item.order = order++
                return item
            })
    }
    const insertParam = (list, option, order) => {
        const newList = [...list]

        const index = list.findIndex((item) => item.order === order)

        if (index > -1) {
            for (let i = index; i < newList.length; i++) {
                newList[i].order += 1
            }
        }
        newList.push(option)

        return newList
    }

    const onDragEnded = ({destination, draggableId, source}) => {
        const {droppableId, index} = source
        const isActive = droppableId === "droppableId-active"

        let activeList = [...params.filter((option) => option.isActive)]
        let inactiveList = [...params.filter((option) => !option.isActive)]
        let option = isActive ? activeList[index - 1] : inactiveList[index - 1]

        const chunk = removeParam(isActive ? activeList : inactiveList, option)
        if (isActive) {
            activeList = chunk
        } else {
            inactiveList = chunk
        }
        {
            const {droppableId, index} = destination
            const order = index === 0 ? 1 : index
            const isActive = droppableId === "droppableId-active"
            option.isActive = isActive
            option.order = order
            const chunk = insertParam(isActive ? activeList : inactiveList, option, order)
            if (isActive) {
                activeList = chunk
            } else {
                inactiveList = chunk
            }
        }

        const newOptions = [...activeList, ...inactiveList].filter((item) => item).sort((a, b) => a.order - b.order)
        setParams(newOptions)
        setIsChanged(true)
    }

    async function onClickCancel() {
        await getData()
        setIsChanged(false)
        setIsSubmitting(false)
    }

    async function onClickSave() {
        try {
            setIsSubmitting(true)

            await Promise.all(
                params.map(async ({aiSchedulingParameterId, isActive, order}) => {
                    return await aiSchedulingParametersService.update({
                        aiSchedulingParameterId,
                        isActive,
                        order
                    })
                })
            )
            await Promise.all(
                Object.keys(courseMinStudents).map(async (courseId) => {
                    return await courseService.updateCourse(+courseId, {
                        minOfStudents: +courseMinStudents[courseId]
                    })
                })
            )

            setIsChanged(false)
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>{t("academics:settings.aiCourseScheduling.logicAndPriority")}</p>
            <DragDropContext onDragEnd={onDragEnded}>
                <div
                    style={{
                        display: "flex",
                        width: "100%"
                    }}>
                    <Droppable droppableId={"droppableId-active"}>
                        {(droppableProvided, droppableSnapshot) => (
                            <div
                                className={cx(styles.filters, {
                                    [styles.dragging]: droppableSnapshot.isDraggingOver
                                })}
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}>
                                <label className={styles.filters_title}>
                                    {t("academics:settings.aiCourseScheduling.filters")}
                                </label>
                                <div
                                    style={{
                                        marginTop: "8px"
                                    }}>
                                    {params
                                        .filter((option) => option.isActive)
                                        .map((option, index) => (
                                            <AiSchedulingOtion
                                                key={`active-${index}`}
                                                index={index + 1}
                                                t={t}
                                                {...option}
                                            />
                                        ))}
                                </div>
                            </div>
                        )}
                    </Droppable>
                    <Droppable droppableId={"droppableId-not-active"}>
                        {(droppableProvided, droppableSnapshot) => (
                            <div
                                className={cx(styles.filters_options, {
                                    [styles.dragging]: droppableSnapshot.isDraggingOver
                                })}
                                ref={droppableProvided.innerRef}
                                {...droppableProvided.droppableProps}>
                                <label className={styles.filters_title}>
                                    {t("academics:settings.aiCourseScheduling.filtersOptions")}
                                </label>
                                <div
                                    style={{
                                        marginTop: "8px"
                                    }}>
                                    {params
                                        .filter((option) => !option.isActive)
                                        .map((option, index) => (
                                            <AiSchedulingOtion
                                                key={`active-${index}`}
                                                index={index + 1}
                                                t={t}
                                                {...option}
                                            />
                                        ))}
                                </div>
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
            <p className={styles.title}>{t("academics:settings.aiCourseScheduling.activeCourses")}</p>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
            />
            <KlassappTableEditable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn
                orderField={orderField}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onChangeCellInput={(field, id, value) => handleCourseMinStudentsChange(id, value)}
            />
            <PermissionsRequired permissions={{staff: [Permissions.Staff.Settings.Modules.Academics.Edit]}}>
                {isChanged && (
                    <div className={styles.buttonWrap}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            onClick={onClickCancel}
                            className={styles.cancelBtn}
                        />
                        <BaseButton
                            title={t("common:action.save").toUpperCase()}
                            onClick={onClickSave}
                            loading={isSubmitting}
                        />
                    </div>
                )}
            </PermissionsRequired>
        </div>
    )
}

export default KlassappTableHOC(AICourseScheduling)
