import {IconType} from "components/Icon"

export enum YesNo {
    Yes = "yes",
    No = "no"
}

export enum KeyDownOption {
    Space = " ",
    SpaceCode = "Spacebar"
}

export enum Order {
    Asc = "asc",
    Desc = "desc"
}

export type SelectOption = {
    name: string
    id: string | number
}

export enum ActivityStatus {
    Active = "active",
    Inactive = "inactive"
}

export type Timestamps = {
    createdAt: string
    updatedAt: string
    deletedAt?: string | null
}

export enum PaginationPerPage {
    item10 = 10,
    item20 = 20,
    item50 = 50,
    item100 = 100
}

export enum ErrorCode {
    Unauthorized = "unauthorized"
}

export type TableColumns = {
    title: string
    field: string
    subFields?: any[]
    classNameHeader?: string
    classNameBody?: string
    headerStyle?: any
    fieldType?: string
    format?: string
    titleHtml?: any
    sortable?: boolean
    orderField?: string
    isHideTitle?: boolean
    isFixed?: boolean
    render?: Function
    renderText?: Function
}

export type TableMenuActions = {
    title: string
    icon: string
    action: Function
    canShow?: (data: any) => boolean
}

export type UrlParams = {
    tab?: string
    token?: string
}

export type KlassappTableProps<T = any> = {
    isLoading?: boolean
    data?: (T & {isChecked: boolean})[]
    page?: number
    pageSize?: number
    total?: number
    fields?: string[]
    allFields?: string[]
    unselectableFields?: string[]
    menuActions?: TableMenuActions[]
    columns?: TableColumns[]
    tableHeaderActions?: TableMenuActions[]
    orderField?: any
    isHideMenuActions?: boolean
    isShowTableHeaderAction?: boolean
    isLoadedTableFuncs?: boolean
    dispatch?: Function
    dispatchFunc?: Function
    onClickSortColumn?: Function
    onUpdateRowData?: Function
    onUpdateTableData?: Function
    onChangeFields?: (fields: string[]) => void
    onChangeAllFields?: (fields: string[]) => void
    onDraggableColumn?: (sourceIndex: number, destIndex: number) => void
    onChangePage?: Function
    onChangeRowPerPage?: Function
    updateTableHeaderActions?: Function
    onClickShowConfirmModal?: Function
    getCurrentData?: () => any[]
    onClickRowItem?: (rowData) => void
    getCurrentPage?: () => number
    getCurrentPageSize?: () => number
    className?: string
    tableClassName?: string
    tableRowParent?: string
    parentColumns?: any[]
    subHeaderColumns?: any[]
    onDoubleClickRowItem?: Function
    isShowCheckedColumn?: boolean
    isShowCheckedColumnHeader?: boolean
    onToggleCheckBox?: (data: any) => void
    isShowColumnPicker?: boolean
    isShowHeader?: boolean
    isShowSubHeader?: boolean
    subHeaderClassName?: string
    isShowScrollTable?: boolean
    isDraggable?: boolean
    onDraggableEnd?: Function
    onToggleCheckHeader?: (checked: boolean) => void
    icon?: IconType
    classNameActionMenu?: string
    expandable?: {
        isExpanded?: boolean
        rowExpandable?: (record) => boolean
        expandedRowRender: (record, expanded) => React.ReactNode
        expandColumnSequence?: number
    }
    colSpanAll?: string[]
    keyRender?: string
    expandableKey?: string
    defaultExpandable?: boolean
    expandIndent?: number
    emptyText?: string
}

export type DraggableInfo = {
    index: number
    droppableId: string
}

export type DraggableResult = {
    draggableId: string
    type: string
    source: DraggableInfo
    reason: string
    mode: string
    destination: DraggableInfo
    combine: string
}

export type DisplayMode = "normal" | "panel"

export type Mutable<T> = {-readonly [P in keyof T]: T[P]}

export type DeepMutable<T> = {-readonly [P in keyof T]: DeepMutable<T[P]>}

export type DeepPartial<T> = {
    [K in keyof T]?: T[K] extends Array<infer R> ? Array<DeepPartial<R>> : DeepPartial<T[K]>
}

export const DATE_UNIT_OPTIONS = [
    {value: "minutes", label: "Minute(s)"},
    {value: "hours", label: "Hour(s)"},
    {value: "days", label: "Day(s)"},
    {value: "weeks", label: "Week(s)"},
    {value: "months", label: "Month(s)"},
    {value: "years", label: "Year(s)"}
]

export const DATE_UNIT_CUSTOM_OPTIONS = [{value: "custom", label: "Custom"}, ...DATE_UNIT_OPTIONS]
