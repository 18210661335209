import {Search} from "components/inputs/Search"
import {handleError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {useEffect, useState} from "react"
import {useDebounce} from "react-use"
import {majorVersionsService} from "services"
import {KlassappTableProps} from "types/common"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./ClockHoursTranscript.module.css"
import {Major} from "types/major"
import {useQuery} from "@tanstack/react-query"

type ProgramTableProps = KlassappTableProps & {
    setSelectedProgram: (program: Major.MajorVersion) => void
}

const ProgramTable = (props: ProgramTableProps) => {
    const {
        dispatch,
        dispatchFunc,
        setSelectedProgram,
        page,
        pageSize,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        columns,
        isShowTableHeaderAction
    } = props
    const [search, setSearch] = useState("")
    const [searchDebounce, setSearchDebounce] = useState("")
    useDebounce(() => setSearchDebounce(search), 500, [search])

    const {data: programVersions, isFetching} = useQuery(
        ["list-program-versions", page, pageSize, searchDebounce, orderField],
        async () => {
            const response = await majorVersionsService.getAll({
                range: {page, pageSize: pageSize},
                linkedEntities: true,
                filter: {searchByProgram: searchDebounce, type: Major.Unit.Clock},
                sort: {orderBy: orderField.field ?? "id", orderDir: orderField.order ?? "DESC"}
            })
            if (response.data) {
                response.data = response.data.map((item) => {
                    return {
                        ...item,
                        programName: item.program.name,
                        programCode: item.program.code
                    }
                })
            }
            return response
        },
        {
            initialData: {
                data: [],
                total: 0
            }
        }
    )

    const getFields = () => {
        return ["Program Name", "Program Code", "Program Version Code", "Program Version Name"]
    }

    const getPageTitle = () => {
        return "Major"
    }

    const getColumns = () => {
        return [
            {
                title: "Program Name",
                field: "programName"
            },
            {
                title: "Program Code",
                field: "programCode"
            },
            {
                title: "Program Version Code",
                field: "version_code",
                sortable: true
            },
            {
                title: "Program Version Name",
                field: "version_name",
                sortable: true
            }
        ]
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programVersions])

    const onClickRowItem = (row) => {
        setSelectedProgram(row)
    }

    return (
        <>
            <Search className={styles.searchBar} value={search} onChange={setSearch} placeholder={"Search Program"} />
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={programVersions.total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={programVersions.data}
                menuActions={[]}
                isLoading={isFetching}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onUpdateRowData={props.onUpdateRowData}
                onClickSortColumn={props.onClickSortColumn}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
            />
        </>
    )
}

export default KlassappTableHOC(ProgramTable)
