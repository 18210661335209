/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react"
import {Col, Radio, Row} from "antd"
import cx from "classnames"
import {chain, isEmpty, sortBy, uniq} from "lodash"
import {useTranslation} from "react-i18next"
import debounce from "debounce-promise"

import styles from "./LeadDistributions.module.css"
import {KlassappTableProps} from "types/common"
import {Auth} from "types/auth"
import {StudentStatus} from "types/students"
import {KlassappTableHOC} from "HOC"
import {Icon} from "components/Icon"
import {KlassDropdown} from "components/Select"
import {AddItemCircleButton, BaseButton, SecondaryButton} from "components/buttons"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import {useGroupedStudentStatusesByStateAsDropdownOptions} from "components/NewStudentStatusSelect/useGroupedStudentStatusesByStateAsDropdownOptions"
import {getFullName, handleError, handleErrorMessage, StatusStateShortName, toastSuccess} from "helpers"
import {
    ConfirmPopup,
    KlassappTable,
    KlassappTableDropdown,
    KlassappTableDropdownAsync,
    KlassappTableDropdownAsyncPaginate,
    KlassappTableHeader
} from "uiKit"
import {addressService, admissionService, campusesService, majorService, profileService, userServiceV3} from "services"
import {Preloader} from "components/preloader"
import {useVisible} from "hooks"
import CampusSelect from "components/CampusSelect"

enum LeadAssignmentType {
    RoundRobin = "round-robin",
    Percentage = "percentage"
}

enum LeadSelectionType {
    ByState = "by-state",
    BySource = "by-source"
}

type SelectOption = {
    id: number
    name: string
}

type LeadDistributionSettings = {
    leadDistributionExcludedStatuses: StudentStatus[] | null
    leadDistributionExcludedCountries: any[]
    leadDistributionExcludedStates: any[]
}

interface LeadDistributionsProps extends KlassappTableProps {
    departmentId: number
}

const LeadDistributions: React.FC<LeadDistributionsProps> = ({
    page,
    total,
    pageSize,
    data,
    orderField,
    isHideMenuActions,
    menuActions,
    isLoading,
    dispatch,
    dispatchFunc,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onChangePage,
    onChangeRowPerPage,
    getCurrentData
}) => {
    const {t} = useTranslation(["admission", "common", "user"])

    const initSettings: LeadDistributionSettings = {
        leadDistributionExcludedStatuses: [],
        leadDistributionExcludedCountries: [],
        leadDistributionExcludedStates: []
    }

    const columnsLeadDistributions = [
        {
            title: t("user:user.user"),
            field: "userHtml",
            style: {minWidth: "160px", maxWidth: "160px"},
            sortable: true
        },
        {
            title: t("admission.leadDistributions.leadDistributionPoolTable.percentage"),
            field: "percentageHtml",
            style: {maxWidth: "100px"},
            sortable: true
        },
        {
            title: t("user:user.title"),
            field: "titleHtml",
            style: {minWidth: "150px"},
            sortable: true
        },
        {
            title: t("admission.leadDistributions.leadDistributionPoolTable.programSpecialist"),
            field: "programSpecialistHtml",
            style: {minWidth: "160px", maxWidth: "160px"},
            sortable: false
        },
        {
            title: t("admission.leadDistributions.leadDistributionPoolTable.state"),
            field: "stateHtml",
            style: {minWidth: "160px", maxWidth: "160px"},
            sortable: false
        }
    ]
    const fieldsLeadDistributions: string[] = columnsLeadDistributions.map((column) => column.title)

    const [isSettingsLoading, setIsSettingsLoading] = useState<boolean>(true)
    const [settings, setSettings] = useState<LeadDistributionSettings>(initSettings)
    const [originalSettings, setOriginalSettings] = useState<LeadDistributionSettings>(initSettings)
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [roleOptions, setRoleOptions] = useState([])
    const [groupedStates, setGroupedStates] = useState([])
    const [originalPools, setOriginalPools] = useState(null)
    const [poolIdToBeDeleted, setPoolIdToBeDeleted] = useState<number>(null)
    const [isAddingNewPool, setIsAddingNewPool] = useState<boolean>(false)
    const [poolIdBeingEdited, setPoolIdBeingEdited] = useState<number>(null)
    const [newDistributionItem, setNewDistributionItem] = useState(null)
    const [deletedDistributionItem, setDeletedDistributionItem] = useState(null)
    const [editingDistributionItem, setEditingDistributionItem] = useState(null)
    const [allSelectedCampuses, setAllSelectedCampuses] = useState([])
    const [campuses, setCampuses] = useState([])
    const [filteredCampuses, setFilteredCampuses] = useState([])

    const confirmPopupDeletePool = useVisible()
    const confirmPopupDeleteDistributionItem = useVisible()

    const {statuses, groupedStatuses} = useGroupedStudentStatusesByStateAsDropdownOptions({
        forState: Object.values(Auth.StudentState) as Auth.StudentState[]
    })

    useEffect(() => {
        getCountries()
        getRolesOptions()
    }, [])

    useEffect(() => {
        if (countries.length) {
            getUSStates()
        }
    }, [JSON.stringify(countries)])

    useEffect(() => {
        if (statuses.length && countries.length && states.length) {
            getSettingsList()
        }
    }, [JSON.stringify(statuses), JSON.stringify(countries), JSON.stringify(states)])

    useEffect(() => {
        getLeadDistributionPools()
    }, [JSON.stringify(settings)])

    const getColumns = (leadDistributionPool) => {
        return columnsLeadDistributions
            .map((column) => {
                if (
                    leadDistributionPool.leadAssignmentType === LeadAssignmentType.RoundRobin &&
                    column.title === t("admission.leadDistributions.leadDistributionPoolTable.percentage")
                ) {
                    return null
                }

                if (
                    leadDistributionPool.selectionType === LeadSelectionType.BySource &&
                    column.title === t("admission.leadDistributions.leadDistributionPoolTable.state")
                ) {
                    return null
                }

                return column
            })
            .filter((column) => column)
    }

    const getFields = (leadDistributionPool) => {
        return fieldsLeadDistributions
            .map((field) => {
                if (
                    leadDistributionPool.leadAssignmentType === LeadAssignmentType.RoundRobin &&
                    field === t("admission.leadDistributions.leadDistributionPoolTable.percentage")
                ) {
                    return null
                }

                if (
                    leadDistributionPool.selectionType === LeadSelectionType.BySource &&
                    field === t("admission.leadDistributions.leadDistributionPoolTable.state")
                ) {
                    return null
                }

                return field
            })
            .filter((field) => field)
    }

    const getCountries = async () => {
        try {
            const response = await addressService.getCountries({})
            const countries = response.data
                .filter((country) => !!country.name)
                .map((country) => ({value: country.countryId, label: country.name}))
            setCountries(countries)
        } catch (e) {
            handleError(e)
        }
    }

    const getUSStates = async () => {
        try {
            const params = {
                filter: {
                    countryId: countries?.find((item) => item.label === "United States").value ?? 89
                }
            }
            const response = await addressService.getStates(params)
            const states = (response.data || []).map((state) => ({value: state.stateId, label: state.name}))
            const groupedStates = chain(response.data || [])
                .groupBy("type")
                ?.map((value, key) => ({
                    label: key,
                    options: sortBy(value, ["name"]).map((state) => ({value: state.stateId, label: state.name}))
                }))
                .value()
            setGroupedStates(groupedStates)
            setStates(states)
        } catch (e) {
            handleError(e)
        }
    }

    const getOptionLabel = useCallback(
        (status: StudentStatus) => `[${StatusStateShortName[status.state]}] ${status.name}`,
        []
    )
    const getCampuses = async () => {
        try {
            const {data, total} = await campusesService.getAll()
            setCampuses(data)
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    useEffect(() => {
        getCampuses()
    }, [])

    useEffect(() => {
        setFilteredCampuses(campuses.filter((x) => !allSelectedCampuses.includes(x.id)))
    }, [allSelectedCampuses, campuses])

    const getRolesOptions = async () => {
        try {
            const data = await profileService.getRoles({
                sort: {
                    orderBy: "name",
                    orderDir: "asc"
                },
                filter: {
                    userType: Auth.UserProfileType.Staff
                }
            })
            const roles: Array<SelectOption> = data.map((role) => ({roleId: role.roleId, name: role.name}))
            setRoleOptions(roles)
        } catch (error) {
            handleError(error)
        }
    }

    const getParamsUsers = () => {
        const params = {
            filter: {
                type: Auth.UserProfileType.Staff,
                profileStatus: 1
            },
            sort: {
                orderBy: "firstName",
                orderDir: "ASC"
            },
            range: {
                limit: 20,
                offset: 0
            },
            linkedObjects: true
        }
        return params
    }

    const searchUsers = useCallback(
        debounce(async (leadDistributionPoolId: number, search: string = "") => {
            const data = getCurrentData()
            let campusIds: number[] = []
            let roleIds: number[] = []
            data.forEach((leadDistributionPool) => {
                if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                    leadDistributionPool?.campuses?.forEach((campus) => campusIds.push(campus.id))
                    leadDistributionPool?.roles?.forEach((role) => roleIds.push(role.roleId))
                }
            })

            let params: any = getParamsUsers()
            params.filter = {
                ...params.filter,
                search
            }
            if (campusIds.length) {
                params.filter = {
                    ...params.filter,
                    campus: campusIds
                }
            }
            if (roleIds.length) {
                params.filter = {
                    ...params.filter,
                    roleIds: roleIds
                }
            }
            const {data: users} = await userServiceV3.getAll(params)
            return users
        }, 500),
        []
    )

    const onProgramSearch = async (program: string, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: programs, total} = await majorService.getAll({
                filter: {
                    search: program
                },
                range: {
                    page,
                    pageSize
                }
            })
            const programOptions = programs.map((program) => ({
                ...program,
                name: `${program.code} - ${program.name}`
            }))
            return {
                options: programOptions,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getSettingsList = async () => {
        try {
            const {data} = await admissionService.getLeadDistributionSettingsList({})

            let newSettings = {...initSettings}

            const existingLeadDistributionExcludedStatusIds: number[] =
                data?.leadDistributionExcludedStatuses?.map((status) => status.statusId) ?? []
            if (existingLeadDistributionExcludedStatusIds.length) {
                newSettings = {
                    ...newSettings,
                    leadDistributionExcludedStatuses: statuses.filter(
                        (status) => existingLeadDistributionExcludedStatusIds.indexOf(status.statusId) >= 0
                    )
                }
            }

            const existingLeadDistributionExcludedCountries: string[] =
                data?.leadDistributionExcludedCountries?.map((countryItem) => countryItem.country) ?? []
            if (existingLeadDistributionExcludedCountries.length) {
                newSettings = {
                    ...newSettings,
                    leadDistributionExcludedCountries: countries.filter(
                        (countryItem) => existingLeadDistributionExcludedCountries.indexOf(countryItem.label) >= 0
                    )
                }
            }

            const existingLeadDistributionExcludedStates: string[] =
                data?.leadDistributionExcludedStates?.map((stateItem) => stateItem.state) ?? []
            if (existingLeadDistributionExcludedStates.length) {
                newSettings = {
                    ...newSettings,
                    leadDistributionExcludedStates: states.filter(
                        (stateItem) => existingLeadDistributionExcludedStates.indexOf(stateItem.label) >= 0
                    )
                }
            }

            setSettings({...newSettings})
            setOriginalSettings({...newSettings})
            setIsSettingsLoading(false)
        } catch (error) {
            setIsSettingsLoading(false)
            handleError(error)
        } finally {
            setIsSettingsLoading(false)
        }
    }

    const saveLeadDistributionSettings = async () => {
        try {
            const payload = {
                statusIds: settings.leadDistributionExcludedStatuses?.map((item) => item.statusId) ?? [],
                countries: settings.leadDistributionExcludedCountries?.map((item) => item.label) ?? [],
                states: settings.leadDistributionExcludedStates?.map((item) => item.label) ?? []
            }
            const response = await admissionService.saveLeadDistributionSettings(payload)
            if (response?.success) {
                toastSuccess("Settings saved successfully")
                await getSettingsList()
            } else {
                handleErrorMessage(response?.message)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const getParams = () => {
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {},
            linkedObjects: true
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    const getLeadDistributionPools = async () => {
        dispatch({isLoading: true})
        const params = getParams()

        try {
            searchUsers("")
            const {data} = await admissionService.getAllLeadDistributions(params)

            const newData = data.map((item) => {
                item.leadDistributions = item.leadDistributions.map((leadDistribution) => {
                    let data = {
                        ...leadDistribution,
                        id: leadDistribution.leadDistributionId,
                        userData: leadDistribution.profile ?? null,
                        percentageHtml: leadDistribution.percentage?.toString() ?? "0",
                        percentage: leadDistribution.percentage?.toString() ?? "0",
                        titleHtml: leadDistribution.title ?? null,
                        title: leadDistribution.title ?? null,
                        programSpecialistData: leadDistribution.programs ?? null,
                        stateData: null
                    }

                    const existingLeadDistributionStates: string[] =
                        leadDistribution.states?.map((stateItem) => stateItem.state) ?? []
                    if (existingLeadDistributionStates.length) {
                        data.stateData = states.filter(
                            (stateItem) => existingLeadDistributionStates.indexOf(stateItem.label) >= 0
                        )
                    }

                    if (data.userData) {
                        data = {
                            ...data,
                            userHtml: data.userData.fullName,
                            programSpecialistHtml:
                                data.programSpecialistData?.map((program) => program.name).join(", ") ?? "",
                            stateHtml: data.states?.map((stateItem) => stateItem.state).join(", ") ?? "",
                            userData: {
                                ...data.userData,
                                id: data.userData.userId,
                                profileId: data.userData.profileId
                            }
                        }
                    }

                    return data
                })

                return item
            })

            const allSelectedCampuses = uniq(
                newData.flatMap((leadDistribution) => leadDistribution.campuses.map((campus) => campus.id))
            )
            setAllSelectedCampuses(allSelectedCampuses)
            dispatch({data: [...newData]})
            setOriginalPools([...newData])
        } catch (e) {
            handleError(e)
        } finally {
            setPoolIdBeingEdited(null)
            setEditingDistributionItem(null)
            setNewDistributionItem(null)
            dispatch({isLoading: false, isHideMenuActions: false})
        }
    }

    const onClickSavePool = async (leadDistributionPools?) => {
        setIsSettingsLoading(true)
        try {
            let data = leadDistributionPools ?? getCurrentData()
            const submitData = data.map((leadDistributionPool) => {
                let data = {
                    campusIds: leadDistributionPool.campuses?.map((campus) => campus.id) ?? [],
                    roleIds: leadDistributionPool.roles?.map((role) => role.roleId) ?? [],
                    leadAssignmentType: leadDistributionPool.leadAssignmentType,
                    selectionType: leadDistributionPool.selectionType,
                    leadDistributions: leadDistributionPool.leadDistributions?.map((leadDistribution) => ({
                        profileId: leadDistribution?.userData?.profileId,
                        percentage: (leadDistribution.percentage ?? "0").toString(),
                        title: (leadDistribution.title ?? "").toString(),
                        programIds: leadDistribution?.programSpecialistData?.map((program) => program.id) ?? [],
                        stateNames: leadDistribution?.stateData?.map((state) => state.label) ?? []
                    }))
                }

                return data
            })

            await admissionService.saveLeadDistributions({
                leadDistributionPools: submitData
            })

            getLeadDistributionPools()
        } catch (e) {
            handleError(e)
        } finally {
            setIsAddingNewPool(false)
            setNewDistributionItem(null)
            setIsSettingsLoading(false)
            dispatch({isHideMenuActions: false})
        }
    }

    const onChangeSettings = (key, value) => {
        setSettings((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    const onClickDeletePool = (leadDistributionPoolId: number) => {
        if (isSettingsLoading) {
            return
        }

        setPoolIdToBeDeleted(leadDistributionPoolId)
        confirmPopupDeletePool.open()
    }

    const onCancelConfirmPopupDeletePool = () => {
        setPoolIdToBeDeleted(null)
        confirmPopupDeletePool.close()
    }

    const onSubmitDeletePool = async () => {
        setIsSettingsLoading(true)

        if (!poolIdToBeDeleted) {
            onCancelConfirmPopupDeletePool()
            getLeadDistributionPools()
            setIsAddingNewPool(false)
            setIsSettingsLoading(false)
            return
        }

        try {
            await admissionService.deleteLeadDistributions({leadDistributionPoolId: poolIdToBeDeleted})
        } catch (error) {
            handleError(error)
        } finally {
            onCancelConfirmPopupDeletePool()
            getLeadDistributionPools()
            setIsSettingsLoading(false)
        }
    }

    const onClickAddLeadDistributionPool = () => {
        let newLeadDistributionPools = getCurrentData()

        newLeadDistributionPools.push({
            isNewPool: true,
            leadAssignmentType: LeadAssignmentType.Percentage,
            selectionType: LeadSelectionType.ByState
        })

        setIsAddingNewPool(true)
        dispatch({data: newLeadDistributionPools})
    }

    const onClickCancelPoolChanges = (leadDistributionPoolId: number) => {
        let newLeadDistributionPools = getCurrentData()

        newLeadDistributionPools = newLeadDistributionPools.map((pool) => {
            if (pool.leadDistributionPoolId === leadDistributionPoolId) {
                pool = originalPools.find(
                    (originalPool) => originalPool.leadDistributionPoolId === leadDistributionPoolId
                )
            }

            return pool
        })

        dispatch({data: newLeadDistributionPools})
        setPoolIdBeingEdited(null)
    }

    const onChangePoolDate = (leadDistributionPoolId, key, value) => {
        if (!isAddingNewPool) {
            setPoolIdBeingEdited(leadDistributionPoolId)
        }

        let newLeadDistributionPools = getCurrentData()

        newLeadDistributionPools = newLeadDistributionPools.map((el) => ({
            ...el,
            [key]: el.leadDistributionPoolId === leadDistributionPoolId ? value : el[key]
        }))

        dispatch({data: newLeadDistributionPools})
    }

    const onChangePoolCampus = (leadDistributionPoolId, value) => {
        if (!isAddingNewPool) {
            setPoolIdBeingEdited(leadDistributionPoolId)
        }

        let newLeadDistributionPools = getCurrentData()

        let campusAlreadyAssigned: boolean = false

        newLeadDistributionPools = newLeadDistributionPools.map((el) => {
            if (el.leadDistributionPoolId === leadDistributionPoolId) {
                if (!value) value = []
                const removedValues = (el?.campuses ?? []).filter((campus) => !value.includes(campus)).map((x) => x.id)
                setAllSelectedCampuses((prev) => {
                    return prev.filter((x) => !removedValues.includes(x))
                })
                el = {
                    ...el,
                    campuses: value
                }
            } else {
                for (let i = 0; i < el.campuses?.length; i++) {
                    for (let j = 0; j < value?.length; j++) {
                        if (el.campuses[i].id === value[j].id) {
                            campusAlreadyAssigned = true
                        }
                    }
                }
            }
            return el
        })

        if (campusAlreadyAssigned) {
            return handleErrorMessage("Campus already assigned to a lead distribution pool")
        }

        dispatch({data: newLeadDistributionPools})
    }

    const renderUsers = (leadDistributionPoolId, leadDistributionId, value?) => {
        let data = getCurrentData()
        let fetchSelectedValue = null
        if (value) {
            fetchSelectedValue = value
        } else {
            data.forEach((leadDistributionPool) => {
                if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                    leadDistributionPool.leadDistributions.forEach((leadDistribution) => {
                        if (leadDistribution.leadDistributionId === leadDistributionId) {
                            fetchSelectedValue = leadDistribution.userData
                        }
                    })
                }

                return leadDistributionPool
            })
        }

        return (
            <KlassappTableDropdownAsync
                loadOptions={(search) => searchUsers(leadDistributionPoolId, search)}
                defaultOptions
                valueKey="id"
                labelKey="name"
                getOptionLabel={(option: any) => getFullName(option)}
                value={fetchSelectedValue?.id}
                onChange={(selectedValues) => {
                    let data = getCurrentData()
                    let userAlreadySelected: boolean = false
                    let newLeadDistributionPools = data.map((leadDistributionPool) => {
                        if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                            let existingIds: number[] = []
                            leadDistributionPool.leadDistributions.forEach((leadDistribution) =>
                                leadDistribution.profile?.id
                                    ? existingIds.push(leadDistribution.profile.id)
                                    : leadDistribution.userData?.profileId
                                    ? existingIds.push(leadDistribution.userData.profileId)
                                    : null
                            )
                            leadDistributionPool.leadDistributions.forEach((leadDistribution) => {
                                if (leadDistribution.leadDistributionId === leadDistributionId) {
                                    leadDistribution.isEditing = true
                                    selectedValues = {
                                        ...selectedValues,
                                        profileId: selectedValues.profiles[0].id
                                    }
                                    leadDistribution.userData = selectedValues
                                    if (existingIds.indexOf(selectedValues.profileId) >= 0) {
                                        userAlreadySelected = true
                                    }
                                }
                            })
                        }

                        return leadDistributionPool
                    })
                    if (userAlreadySelected) {
                        return handleErrorMessage("User already added to the pool")
                    }

                    dispatch({data: newLeadDistributionPools})
                }}
            />
        )
    }

    const renderTextInput = (leadDistributionPoolId, leadDistributionId, key: string, value?) => {
        let data = getCurrentData()
        let fetchSelectedValue = null
        if (value && value !== null) {
            fetchSelectedValue = value
        } else {
            data.forEach((leadDistributionPool) => {
                if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                    leadDistributionPool.leadDistributions.forEach((leadDistribution) => {
                        if (leadDistribution.leadDistributionId === leadDistributionId) {
                            fetchSelectedValue = leadDistribution[key]
                        }
                    })
                }

                return leadDistributionPool
            })
        }

        let props = {}
        if (key === "percentage") {
            props = {type: "number", min: 0}
        }

        return (
            <BaseInput
                value={fetchSelectedValue}
                {...props}
                onChange={(value) => {
                    let data = getCurrentData()
                    let leadDistributionPercentages = {}
                    if (key === "percentage") {
                        data.forEach((leadDistributionPool) => {
                            if (
                                Object.keys(leadDistributionPercentages).indexOf(
                                    leadDistributionPool.leadDistributionPoolId
                                ) < 0
                            ) {
                                leadDistributionPercentages = {
                                    ...leadDistributionPercentages,
                                    [leadDistributionPool.leadDistributionPoolId]: 0
                                }
                            }
                            leadDistributionPool.leadDistributions.forEach((leadDistribution) => {
                                if (leadDistribution.leadDistributionId !== leadDistributionId) {
                                    leadDistributionPercentages[leadDistributionPool.leadDistributionPoolId] +=
                                        leadDistribution.percentage ? parseInt(leadDistribution.percentage) : 0
                                }
                            })
                        })
                    }
                    let percentageExceeded: boolean = false
                    let newLeadDistributionPools = data.map((leadDistributionPool) => {
                        if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                            leadDistributionPool.leadDistributions.forEach((leadDistribution) => {
                                if (leadDistribution.leadDistributionId === leadDistributionId) {
                                    leadDistribution.isEditing = true
                                    leadDistribution[`${key}Html`] = value

                                    if (key === "percentage") {
                                        if (isNaN(parseInt(value)) || parseInt(value) < 0) {
                                            value = 0
                                        }
                                        if (
                                            leadDistributionPercentages[leadDistributionPool.leadDistributionPoolId] +
                                                parseInt(value) <=
                                            100
                                        ) {
                                            leadDistribution[key] = (value * 1).toString()

                                            leadDistribution[key] =
                                                (leadDistribution[key] ?? "").trim() !== ""
                                                    ? leadDistribution[key]
                                                    : "0"

                                            leadDistributionPercentages[leadDistributionPool.leadDistributionPoolId] +=
                                                parseInt(value)
                                        } else {
                                            percentageExceeded = true
                                        }
                                    } else {
                                        leadDistribution[key] = value
                                    }

                                    leadDistribution[`${key}Html`] = renderTextInput(
                                        leadDistributionPool.leadDistributionPoolId,
                                        leadDistribution.leadDistributionId,
                                        key,
                                        value
                                    )
                                }
                            })
                        }

                        return leadDistributionPool
                    })

                    if (key === "percentage" && percentageExceeded) {
                        return handleErrorMessage("Percentage cannot exceed 100% for a pool")
                    }

                    dispatch({data: newLeadDistributionPools})
                }}
            />
        )
    }

    const renderPrograms = (debouncePrograms, leadDistributionPoolId, leadDistributionId, value?) => {
        let data = getCurrentData()
        let fetchSelectedValue = null
        if (value && value !== null) {
            fetchSelectedValue = value
        } else {
            data.forEach((leadDistributionPool) => {
                if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                    leadDistributionPool.leadDistributions.forEach((leadDistribution) => {
                        if (leadDistribution.leadDistributionId === leadDistributionId) {
                            fetchSelectedValue = leadDistribution.programSpecialistData
                        }
                    })
                }

                return leadDistributionPool
            })
        }

        return (
            <KlassappTableDropdownAsyncPaginate
                loadOptions={debouncePrograms}
                value={fetchSelectedValue?.id}
                valueKey="id"
                onChange={(selectedValues) => {
                    let data = getCurrentData()
                    let newLeadDistributionPools = data.map((leadDistributionPool) => {
                        if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                            leadDistributionPool.leadDistributions.forEach((leadDistribution) => {
                                if (leadDistribution.leadDistributionId === leadDistributionId) {
                                    leadDistribution.programSpecialistData = selectedValues
                                    leadDistribution.isEditing = true
                                }
                            })
                        }

                        return leadDistributionPool
                    })

                    dispatch({data: newLeadDistributionPools})
                }}
                isMulti
                placeholder="Program"
            />
        )
    }

    const renderStates = (leadDistributionPoolId, leadDistributionId, value?) => {
        let data = getCurrentData()
        let fetchSelectedValue = null
        if (value && value !== null) {
            fetchSelectedValue = value
        } else {
            data.forEach((leadDistributionPool) => {
                if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                    leadDistributionPool.leadDistributions.forEach((leadDistribution) => {
                        if (leadDistribution.leadDistributionId === leadDistributionId) {
                            fetchSelectedValue = leadDistribution.stateData
                        }
                    })
                }

                return leadDistributionPool
            })
        }

        return (
            <KlassappTableDropdown
                options={groupedStates}
                value={fetchSelectedValue}
                valueKey="value"
                labelKey="label"
                onChange={(selectedValues) => {
                    let data = getCurrentData()
                    let newLeadDistributionPools = data.map((leadDistributionPool) => {
                        if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                            leadDistributionPool.leadDistributions = leadDistributionPool.leadDistributions.map(
                                (leadDistribution) => {
                                    if (leadDistribution.leadDistributionId === leadDistributionId) {
                                        leadDistribution.stateData = selectedValues
                                        leadDistribution.stateHtml = renderStates(
                                            leadDistributionPool.leadDistributionPoolId,
                                            leadDistribution.leadDistributionId,
                                            selectedValues
                                        )
                                        leadDistribution.isEditing = true
                                    }

                                    return leadDistribution
                                }
                            )
                        }

                        return leadDistributionPool
                    })

                    dispatch({data: newLeadDistributionPools})
                }}
                isMulti
                isGrouped
                placeholder={t("common:selectField.placeholder")}
            />
        )
    }

    const onClickAddDistributionItem = (leadDistributionPoolId: number) => {
        const debouncePrograms = debounce(onProgramSearch, delayTime)

        let newLeadDistributionPools = getCurrentData()

        newLeadDistributionPools = newLeadDistributionPools.map((leadDistributionPool) => {
            if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                let leadDistributionId: number = parseInt(
                    `${leadDistributionPool.leadDistributionPoolId}${
                        (leadDistributionPool.leadDistributions ?? []).length + 1
                    }`
                )

                leadDistributionPool.leadDistributions = (leadDistributionPool.leadDistributions ?? []).concat({
                    id: leadDistributionId,
                    leadDistributionId,
                    leadDistributionPoolId: leadDistributionPool.leadDistributionPoolId,
                    userData: null,
                    userHtml: renderUsers(leadDistributionPool.leadDistributionPoolId, leadDistributionId),
                    percentage: null,
                    percentageHtml:
                        leadDistributionPool.leadAssignmentType === LeadAssignmentType.Percentage
                            ? renderTextInput(
                                  leadDistributionPool.leadDistributionPoolId,
                                  leadDistributionId,
                                  "percentage"
                              )
                            : null,
                    title: null,
                    titleHtml: renderTextInput(
                        leadDistributionPool.leadDistributionPoolId,
                        leadDistributionId,
                        "title"
                    ),
                    programSpecialistData: null,
                    programSpecialistHtml: renderPrograms(
                        debouncePrograms,
                        leadDistributionPool.leadDistributionPoolId,
                        leadDistributionId
                    ),
                    stateData: null,
                    stateHtml:
                        leadDistributionPool.selectionType === LeadSelectionType.ByState
                            ? renderStates(leadDistributionPool.leadDistributionPoolId, leadDistributionId)
                            : null
                })
            }

            return leadDistributionPool
        })

        dispatch({data: newLeadDistributionPools, isHideMenuActions: true})
        setNewDistributionItem({leadDistributionPoolId})
    }

    const onClickDeleteDistributionItem = ({leadDistributionPoolId, leadDistributionId, ...rest}) => {
        setDeletedDistributionItem({leadDistributionPoolId, leadDistributionId})
        confirmPopupDeleteDistributionItem.open()
    }

    const onCancelConfirmPopupDistributionItem = () => {
        setDeletedDistributionItem(null)
        confirmPopupDeleteDistributionItem.close()
    }

    const onSubmitDeleteDistributionItem = async () => {
        setIsSettingsLoading(true)

        const {leadDistributionPoolId, leadDistributionId} = deletedDistributionItem

        if (!leadDistributionPoolId || !leadDistributionId) {
            onCancelConfirmPopupDistributionItem()
            getLeadDistributionPools()
            setIsSettingsLoading(false)
            return
        }

        try {
            let data = getCurrentData()
            const newLeadDistributionPools = data.map((leadDistributionPool) => ({
                ...leadDistributionPool,
                leadDistributions:
                    leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId
                        ? leadDistributionPool.leadDistributions?.filter(
                              (leadDistribution) => leadDistribution.leadDistributionId !== leadDistributionId
                          )
                        : leadDistributionPool.leadDistributions
            }))

            await onClickSavePool(newLeadDistributionPools)
        } catch (error) {
            handleError(error)
        } finally {
            onCancelConfirmPopupDistributionItem()
        }
    }

    const onClickEditDistributionItem = (editItem) => {
        const {leadDistributionPoolId, leadDistributionId} = editItem

        let data = getCurrentData()

        let newLeadDistributionPools = data.map((leadDistributionPool) => {
            if (leadDistributionPool.leadDistributionPoolId === leadDistributionPoolId) {
                leadDistributionPool.leadDistributions = leadDistributionPool.leadDistributions.map(
                    (leadDistribution) => {
                        if (leadDistribution.leadDistributionId === leadDistributionId) {
                            return {
                                ...leadDistribution,
                                percentageHtml:
                                    leadDistributionPool.leadAssignmentType === LeadAssignmentType.Percentage
                                        ? renderTextInput(
                                              leadDistributionPool.leadDistributionPoolId,
                                              leadDistribution.leadDistributionId,
                                              "percentage",
                                              leadDistribution.percentage
                                          )
                                        : null,
                                titleHtml: renderTextInput(
                                    leadDistributionPool.leadDistributionPoolId,
                                    leadDistribution.leadDistributionId,
                                    "title",
                                    leadDistribution.title
                                ),
                                isEditing:
                                    leadDistribution.leadDistributionId === editItem.leadDistributionId ? true : false
                            }
                        }

                        return {...leadDistribution}
                    }
                )
            }

            return leadDistributionPool
        })

        setEditingDistributionItem({leadDistributionPoolId, leadDistributionId})
        dispatch({data: newLeadDistributionPools, isHideMenuActions: true})
    }

    const tableOptions = useMemo(
        () => [
            {key: "onClickEdit", func: onClickEditDistributionItem},
            {key: "onClickDelete", func: onClickDeleteDistributionItem}
        ],
        [t]
    )

    useEffect(() => {
        dispatch({isClassComponent: false, isHideMenuActions: false})
        dispatchFunc(tableOptions)
    }, [dispatchFunc, tableOptions])

    const delayTime = 500

    return (
        <div className={styles.wrapper}>
            {isSettingsLoading && <Preloader />}
            <Row gutter={[40, 32]}>
                <Col span={12}>
                    <p className={styles.dropdownHeadline}>{t("admission.leadDistributions.excludeStatus")}</p>
                    <KlassDropdown
                        options={groupedStatuses}
                        value={settings.leadDistributionExcludedStatuses}
                        onChange={(value) => onChangeSettings("leadDistributionExcludedStatuses", value ?? [])}
                        getOptionLabel={getOptionLabel}
                        valueKey="statusId"
                        labelKey="name"
                        isMulti
                    />
                </Col>
                <Col span={12}>
                    <p className={cx(styles.bodyTitle, styles.dropdownHeadline)}>
                        {t("admission.leadDistributions.excludeCountry")}
                    </p>
                    <KlassDropdown
                        options={countries}
                        value={settings.leadDistributionExcludedCountries}
                        placeholder={t("common:selectField.placeholder")}
                        onChange={(value) => onChangeSettings("leadDistributionExcludedCountries", value ?? [])}
                        valueKey="value"
                        labelKey="label"
                        isMulti
                    />
                </Col>
                <Col span={12}>
                    <p className={cx(styles.bodyTitle, styles.dropdownHeadline)}>
                        {t("admission.leadDistributions.excludeState")}
                    </p>
                    <KlassDropdown
                        options={groupedStates}
                        value={settings.leadDistributionExcludedStates}
                        placeholder={t("common:selectField.placeholder")}
                        onChange={(value) => onChangeSettings("leadDistributionExcludedStates", value ?? [])}
                        valueKey="value"
                        labelKey="label"
                        isMulti
                        isGrouped
                    />
                </Col>
            </Row>
            <div className={styles.saveBtnContainer}>
                <BaseButton
                    title="Save Settings"
                    onClick={saveLeadDistributionSettings}
                    className={styles.saveBtn}
                    disabled={JSON.stringify(settings) === JSON.stringify(originalSettings)}
                />
            </div>
            <p className={styles.totalInfo}>
                <Icon icon="ALERT_CIRCLE_FILL" color={"var(--secondary-400-base)"} className={styles.alertIcon} />
                Must be a total of 100%
            </p>
            <Row>
                <Col span={24}>
                    <div className={cx(styles.actionWrap, styles.mb30)}>
                        <BaseButton
                            title={t("admission.leadDistributions.addLeadDistributionPool")}
                            onClick={onClickAddLeadDistributionPool}
                            disabled={isAddingNewPool || JSON.stringify(data) !== JSON.stringify(originalPools)}
                        />
                    </div>
                </Col>
            </Row>
            {[...(data ?? [])]?.reverse()?.map((leadDistributionPool, index) => (
                <div key={leadDistributionPool.leadDistributionPoolId} className={styles.leadDistributionPoolContainer}>
                    <Row gutter={[40, 32]}>
                        <Col span={24}>
                            <div className={styles.leadPoolTitleContainer}>
                                <div className={styles.leadPoolTitle}>
                                    <span className={styles.leadPoolTitleSpan}>
                                        Lead Distribution Pool {(data ?? []).length - index}
                                    </span>
                                </div>
                                <span
                                    className={cx(styles.deletePoolIcon, {
                                        [styles.notAllowed]:
                                            (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                                            (poolIdBeingEdited &&
                                                poolIdBeingEdited !== leadDistributionPool.leadDistributionPoolId) ||
                                            newDistributionItem ||
                                            editingDistributionItem
                                    })}
                                    onClick={() =>
                                        (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                                        (poolIdBeingEdited &&
                                            poolIdBeingEdited !== leadDistributionPool.leadDistributionPoolId) ||
                                        newDistributionItem ||
                                        editingDistributionItem
                                            ? {}
                                            : onClickDeletePool(leadDistributionPool.leadDistributionPoolId)
                                    }>
                                    <Icon icon="DELETE" color="var(--error-400-base)" />
                                </span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <FormLabel label={t("user:user.campuses")} />
                            <KlassDropdown
                                options={filteredCampuses}
                                value={leadDistributionPool.campuses ?? []}
                                onChange={(selectedValues) =>
                                    onChangePoolCampus(leadDistributionPool.leadDistributionPoolId, selectedValues)
                                }
                                valueKey="id"
                                readOnly={
                                    (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                                    (poolIdBeingEdited &&
                                        poolIdBeingEdited !== leadDistributionPool.leadDistributionPoolId) ||
                                    newDistributionItem ||
                                    editingDistributionItem
                                }
                                isMulti
                            />
                        </Col>
                        <Col span={12}>
                            <FormLabel label={t("user:user.role")} />
                            <KlassDropdown
                                options={roleOptions}
                                value={leadDistributionPool.roles ?? []}
                                onChange={(newValue) =>
                                    onChangePoolDate(leadDistributionPool.leadDistributionPoolId, "roles", newValue)
                                }
                                valueKey="roleId"
                                readOnly={
                                    (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                                    (poolIdBeingEdited &&
                                        poolIdBeingEdited !== leadDistributionPool.leadDistributionPoolId) ||
                                    newDistributionItem ||
                                    editingDistributionItem
                                }
                                isMulti
                            />
                        </Col>
                        <Col span={12}>
                            <p>Choose Algorithm for Lead Distribution</p>
                            <Radio.Group
                                onChange={(event) =>
                                    onChangePoolDate(
                                        leadDistributionPool.leadDistributionPoolId,
                                        "leadAssignmentType",
                                        event.target.value
                                    )
                                }
                                value={leadDistributionPool.leadAssignmentType}
                                className={styles.radioBtnWrap}
                                disabled={
                                    (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                                    (poolIdBeingEdited &&
                                        poolIdBeingEdited !== leadDistributionPool.leadDistributionPoolId) ||
                                    newDistributionItem ||
                                    editingDistributionItem
                                }>
                                <Radio value={LeadAssignmentType.Percentage} className={styles.radioBtnItem}>
                                    Percentage
                                </Radio>
                                <Radio value={LeadAssignmentType.RoundRobin} className={styles.radioBtnItem}>
                                    Round Robin
                                </Radio>
                            </Radio.Group>
                        </Col>
                        <Col span={12}>
                            <p>Choose Selection Type for Lead Distribution</p>
                            <Radio.Group
                                onChange={(event) =>
                                    onChangePoolDate(
                                        leadDistributionPool.leadDistributionPoolId,
                                        "selectionType",
                                        event.target.value
                                    )
                                }
                                value={leadDistributionPool.selectionType}
                                className={styles.radioBtnWrap}
                                disabled={
                                    (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                                    (poolIdBeingEdited &&
                                        poolIdBeingEdited !== leadDistributionPool.leadDistributionPoolId) ||
                                    newDistributionItem ||
                                    editingDistributionItem
                                }>
                                <Radio value={LeadSelectionType.ByState} className={styles.radioBtnItem}>
                                    By State
                                </Radio>
                                <Radio value={LeadSelectionType.BySource} className={styles.radioBtnItem}>
                                    By Source
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <div className={styles.buttonWrap}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            onClick={() => onClickCancelPoolChanges(leadDistributionPool.leadDistributionPoolId)}
                            className={styles.cancelBtn}
                            disabled={
                                (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                                JSON.stringify(leadDistributionPool) ===
                                    JSON.stringify(
                                        originalPools?.find(
                                            (pool) =>
                                                pool.leadDistributionPoolId ===
                                                leadDistributionPool.leadDistributionPoolId
                                        )
                                    ) ||
                                newDistributionItem ||
                                editingDistributionItem
                            }
                        />
                        <BaseButton
                            title={t("common:action.save").toUpperCase()}
                            onClick={() => onClickSavePool()}
                            loading={isSettingsLoading}
                            disabled={
                                !leadDistributionPool.campuses?.length ||
                                !leadDistributionPool.roles?.length ||
                                !leadDistributionPool.leadAssignmentType ||
                                (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                                JSON.stringify(leadDistributionPool) ===
                                    JSON.stringify(
                                        originalPools?.find(
                                            (pool) =>
                                                pool.leadDistributionPoolId ===
                                                leadDistributionPool.leadDistributionPoolId
                                        )
                                    ) ||
                                newDistributionItem ||
                                editingDistributionItem
                            }
                        />
                    </div>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={onChangePage}
                        onChangeRowPerPage={onChangeRowPerPage}
                    />
                    <KlassappTable
                        columns={getColumns(leadDistributionPool)}
                        fields={getFields(leadDistributionPool)}
                        allFields={getFields(leadDistributionPool)}
                        menuActions={isHideMenuActions ? [] : menuActions}
                        data={leadDistributionPool?.leadDistributions ?? []}
                        orderField={orderField}
                        onClickRowItem={() => {}}
                        onChangeFields={onChangeFields}
                        onDraggableColumn={onDraggableColumn}
                        onChangeAllFields={onChangeAllFields}
                        isLoading={isLoading}
                        isShowCheckedColumn={false}
                    />
                    {!leadDistributionPool.campuses?.length ||
                    !leadDistributionPool.roles?.length ||
                    (isAddingNewPool && leadDistributionPool.leadDistributionPoolId) ||
                    (poolIdBeingEdited && poolIdBeingEdited !== leadDistributionPool.leadDistributionPoolId) ||
                    newDistributionItem ||
                    editingDistributionItem ? (
                        ""
                    ) : (
                        <div className={styles.buttonWrap}>
                            <AddItemCircleButton
                                onClick={() => onClickAddDistributionItem(leadDistributionPool.leadDistributionPoolId)}
                            />
                        </div>
                    )}
                    {(leadDistributionPool.leadDistributionPoolId === newDistributionItem?.leadDistributionPoolId ||
                        leadDistributionPool.leadDistributionPoolId ===
                            editingDistributionItem?.leadDistributionPoolId) && (
                        <div className={styles.buttonWrap}>
                            <SecondaryButton
                                title={t("common:action.cancel")}
                                onClick={() => getLeadDistributionPools()}
                                className={styles.cancelBtn}
                            />
                            <BaseButton
                                title={t("common:action.done").toUpperCase()}
                                onClick={() => onClickSavePool()}
                                loading={isSettingsLoading}
                            />
                        </div>
                    )}
                </div>
            ))}
            <ConfirmPopup
                isVisible={confirmPopupDeletePool.isVisible}
                title={t("common:confirmationPopup.deleteSingle", {title: "Lead Distribution Pool"})}
                onClose={onCancelConfirmPopupDeletePool}
                onConfirm={onSubmitDeletePool}
                loading={isSettingsLoading}
            />
            <ConfirmPopup
                isVisible={confirmPopupDeleteDistributionItem.isVisible}
                title={t("common:confirmationPopup.deleteSingle", {title: "Lead Distribution Item"})}
                onClose={onCancelConfirmPopupDistributionItem}
                onConfirm={onSubmitDeleteDistributionItem}
                loading={isSettingsLoading}
            />
        </div>
    )
}

export default KlassappTableHOC(LeadDistributions)
