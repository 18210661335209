import {useQuery} from "@tanstack/react-query"
import {studentService} from "services"
import {BaseDepartmentId} from "types/departments"

const queryCacheKey = "department-student"

type DepartmentStudentProps = {
    studentId?: number
    departmentId?: BaseDepartmentId | number
    isEnabled?: boolean
}

export const useDepartmentStudent = ({studentId, departmentId, isEnabled = true}: DepartmentStudentProps) => {
    const query = useQuery(
        [queryCacheKey, studentId, departmentId],
        async () => {
            const {
                data: [student]
            } = await studentService.getDepartmentStudents({
                skipTotal: true,
                filter: {
                    departmentId,
                    profileIds: [studentId]
                }
            })
            return student
        },
        {
            enabled: !!studentId && isEnabled
        }
    )

    return {
        student: query.data,
        isLoading: query.isFetching
    }
}
