import React, {useReducer, useState} from "react"
import {head, isEmpty} from "lodash"
import {BaseDepartmentId} from "types/departments"
import {Scholarships} from "./parts"
import {
    StaffAnalytics,
    StaffAnalyticsTitle,
    StatsChecklist,
    StatsChecklistTitle,
    StatsFilter,
    StatsFunnel,
    StatsHeaderInfo,
    TitleFunnel
} from "sections/shared/stats"
import {StatsForm} from "sections/shared/types"
import {useModel} from "hooks"
import {getFullName} from "helpers"
import {FilterKey} from "types/filter"

export default function FinancialAidStaffTab(props) {
    function reducer(state, action) {
        return {...state, ...action}
    }

    const model = useModel()
    const storageData = model.getStorageFilter(FilterKey.FinAidPage_Analytics)
    const initialState: StatsForm = {
        campus: !isEmpty(storageData?.filter) ? storageData.filter.campus : null,
        program: !isEmpty(storageData?.filter) ? storageData.filter.program : null,
        advisor: !isEmpty(storageData?.filter)
            ? storageData.filter.advisor
            : [
                  {
                      userId: model.user.id,
                      name: getFullName(model.user),
                      profileId: head(model.user.profiles).id,
                      isFixed: true
                  }
              ],
        term: !isEmpty(storageData?.filter) ? storageData.filter.term : null,
        dateRange: null,
        applySearch: false
    }

    const [state, setState] = useReducer(reducer, initialState)
    const [search, setSearch] = useState(!isEmpty(storageData) ? storageData.search : "")

    const onSearchInput = (value: string) => {
        setSearch(value)
    }

    const onClickClearFilter = () => {
        setState(initialState)
    }

    return (
        <div>
            <StatsHeaderInfo />
            <StatsFilter
                form={state}
                setState={setState}
                departmentId={BaseDepartmentId.FinancialAid}
                onClickClearFilter={onClickClearFilter}
                searchValue={search}
                onSearchInput={onSearchInput}
            />
            <StatsFunnel
                departmentId={props.departmentId}
                form={state}
                setState={setState}
                title={TitleFunnel.financialAidFunnel}
            />
            <StaffAnalytics title={StaffAnalyticsTitle.financialAid} departmentId={props.departmentId} />
            <Scholarships />
            <StatsChecklist title={StatsChecklistTitle.financialAidChecklist} departmentId={props.departmentId} />
        </div>
    )
}
