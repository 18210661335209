import React from "react"
import styles from "./preloader.module.css"

type Props = {
    children?: string
}

export const Preloader: React.FunctionComponent<Props> = (props) => {
    return <div className={styles.preloader}>{props.children}</div>
}
