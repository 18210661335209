import React from "react"
import cx from "classnames"
import {DragDropContext} from "react-beautiful-dnd"
import {isEqual, isNumber} from "lodash"
import moment from "moment"
import {handleError, toastSuccess, toastWarning, getPositionMoveInObject} from "helpers"
import {getScheduleEventColor} from "helpers/calendar"
import {academicPlansService, previousCompletedCreditsService, transferCreditsService} from "services"
import {BaseLoading} from "components/Loading"
import {AcademicPlans} from "types/academicPlans"
import {DraggableResult} from "types/common"
import {Auth} from "types/auth"
import {Schedule} from "types/terms"
import {BaseButton} from "components"
import {AcademicPlanCalendar} from "./AcademicPlanCalendar"
import {TermItem} from "./TermItem"
import styles from "../Tab.module.css"
import {TransferAndPreviouslyCompletedTerm} from "./TransferAndPreviouslyCompletedTerm"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

type Props = {
    studentId: number
    isStaff: boolean
    student: Auth.DepartmentStudent
    currentProfileId: number
}

type State = {
    terms: AcademicPlans.Term[]
    transferAndPreviouslyCompletedTerm: any
    availableTerms: AcademicPlans.AvailableTerm[]
    registeringTerm: AcademicPlans.Term
    selectedAvailableTermIds: number[]
    isLoading: boolean
    isCreating: boolean
    events: any[]
    conflictEvents: any[]
    registeringCourses: Record<string, Schedule>
    isRegisteringAllCourses: boolean
    isApprovingAllCourses: boolean
}

export enum TermViewCourseType {
    Transfer = "transfer",
    PreviouslyCompleted = "previously_completed"
}

export class TermViewTab extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            terms: [],
            transferAndPreviouslyCompletedTerm: {
                name: "Transfer and previously completed"
            },
            availableTerms: [],
            selectedAvailableTermIds: [],
            registeringTerm: null,
            isLoading: false,
            isCreating: false,
            events: [],
            conflictEvents: [],
            registeringCourses: {},
            isRegisteringAllCourses: false,
            isApprovingAllCourses: false
        }
    }

    componentDidMount(): void {
        this.getListTerms()
        this.getTransferAndPreviouslyCompletedTerm()
        if (
            [Auth.StudentState.Student, Auth.StudentState.Enrollment, Auth.StudentState.TemporaryOut].includes(
                this.props.student.profileState
            )
        ) {
            this.getListAvailableTerms()
        }
    }

    getListTerms = async (): Promise<void> => {
        const {studentId} = this.props
        try {
            this.setState({isLoading: true})
            const terms = await academicPlansService.getListCourseByTerm({
                studentProfileId: studentId,
                viewType: AcademicPlans.TabKey.Term
            })
            const newTerms = this.initTermData(terms)
            const registeringTerm: AcademicPlans.Term = terms.find((term) => {
                return (
                    (term.isCurrentSelect || term.termId) &&
                    moment(term.registration_start_date).isBefore(moment()) &&
                    moment(term.registration_end_date).isAfter(moment())
                )
            })
            const selectedAvailableTermIds = terms.map((term) => term.termId).filter((termId) => !!termId)
            this.setState({terms: newTerms, registeringTerm, selectedAvailableTermIds})
            const currentTerm = terms.find((term) => {
                return (
                    (term.isCurrentSelect || term.termId) &&
                    moment(term.start_date).isBefore(moment()) &&
                    moment(term.end_date).isAfter(moment())
                )
            })
            if (registeringTerm || currentTerm) {
                await this.getScheduleEvents(registeringTerm || currentTerm)
            }
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isLoading: false})
        }
    }

    getTransferAndPreviouslyCompletedTerm = async () => {
        const {studentId} = this.props
        const params: any = {
            studentProfileId: studentId,
            range: {
                page: 1,
                pageSize: UNLIMITED_PAGE_SIZE
            },
            filter: {
                approved: true
            }
        }
        let [{data: transferCreditsCourses}, {data: previouslyCompletedCreditsCourses}] = await Promise.all([
            transferCreditsService.listTransferCredits(params),
            previousCompletedCreditsService.listPreviousCompletedCredits(params)
        ])

        this.setState((prev) => ({
            ...prev,
            transferAndPreviouslyCompletedTerm: {
                ...prev.transferAndPreviouslyCompletedTerm,
                sections: [
                    {
                        courses: [
                            ...transferCreditsCourses.map((course) => ({
                                ...course,
                                courseType: TermViewCourseType.Transfer
                            })),
                            ...previouslyCompletedCreditsCourses.map((course) => ({
                                ...course,
                                courseType: TermViewCourseType.PreviouslyCompleted
                            }))
                        ]
                    }
                ]
            }
        }))
    }

    getScheduleEvents = async (registeringTerm) => {
        try {
            const data = await academicPlansService.getScheduleEventsByTerm({
                academicPlanTermId: registeringTerm.id
            })
            const events = []
            data.forEach(({schedule, courseCode, courseName}) => {
                const scheduleEvents = schedule?.schedule_events || []
                const instructorIds = (schedule?.instructors || []).map((instructor) => instructor.userId)
                scheduleEvents.forEach((event: any) => {
                    events.push({
                        ...event,
                        id: event.object_id,
                        start: moment(event.start_at_utc).local().format(),
                        end: moment(event.end_at_utc).local().format(),
                        startDateTime: moment(event.start_at_utc).local().format(),
                        endDateTime: moment(event.end_at_utc).local().format(),
                        color: getScheduleEventColor(event.json_data.eventType),
                        scheduleKey: schedule.key,
                        scheduleEventsType: event.schedule_events_type,
                        title: schedule.schedule_suffix,
                        description: event.description,
                        instructorIds,
                        instructors: schedule?.instructors,
                        order: 2,
                        courseCode,
                        courseName,
                        campus: schedule.campus.map((item) => item.name).join(","),
                        isRegistered: true
                    })
                })
            })
            this.setState({events})
        } catch (error) {
            handleError(error)
        }
    }

    initTermData = (terms) => {
        const newTerms = terms.map((term) => {
            term.sections = term.sections.map((section) => {
                section.templateCourses = section.courses
                return section
            })
            return term
        })
        return newTerms
    }

    getListAvailableTerms = async () => {
        const {studentId} = this.props
        try {
            const availableTerms = await academicPlansService.getListAvailableTerms({studentProfileId: studentId})
            this.setState({availableTerms})
        } catch (error) {
            handleError(error)
        }
    }

    onUpdateTermItem = (index, data) => {}

    onDragEnd = async (result: DraggableResult) => {
        const {destination, source} = result || {}
        if (isEqual(destination, source) || !destination) {
            return
        }
        const [destTermId, destSectionId] = destination.droppableId.split("_")
        const [sourceTermId, sourceSectionId] = source.droppableId.split("_")
        if (destTermId !== AcademicPlans.TermDraggable.TermSectionEmpty && destSectionId !== sourceSectionId) {
            toastWarning("Can not move course into another section")
            return
        }
        if (destTermId === AcademicPlans.TermDraggable.TermSectionEmpty || sourceTermId !== destTermId) {
            this.setState({isLoading: true})
            await this.moveCourse(result)
            await this.getListTerms()
        } else {
            this.moveCourseIntoSection(result)
        }
    }

    moveCourseIntoSection = async (result) => {
        const {source, destination} = result
        const [academicPlanTermId, sectionId, academicPlanCourseId] = result.draggableId.split("_")
        let courses = []
        this.state.terms.some((term) => {
            if (term.id === +academicPlanTermId) {
                return term.sections.some((section) => {
                    if (section.id === +sectionId) {
                        courses = section.courses
                        return true
                    }
                    return false
                })
            }
            return false
        })
        const newPosition = getPositionMoveInObject(courses, "position", source.index, destination.index)
        await this.moveCourseAPI({academicPlanCourseId, academicPlanTermId}, newPosition)
        await this.getListTerms()
    }

    moveCourse = async (result) => {
        const [, , academicPlanCourseId] = result.draggableId.split("_")
        if (result.destination.droppableId.includes(AcademicPlans.TermDraggable.TermSectionEmpty)) {
            const [, academicPlanTermId] = result.destination.droppableId.split("_")
            await this.moveCourseAPI({academicPlanCourseId, academicPlanTermId})
        } else {
            const [academicPlanTermId] = result.destination.droppableId.split("_")
            await this.moveCourseAPI({academicPlanCourseId, academicPlanTermId})
        }
    }

    moveCourseAPI = async ({academicPlanCourseId, academicPlanTermId}, newPosition?) => {
        try {
            const payload: any = {
                studentProfileId: this.props.studentId,
                academicPlanCourseId: +academicPlanCourseId,
                academicPlanTermId: +academicPlanTermId
            }
            if (isNumber(newPosition)) {
                payload.position = newPosition
            }
            await academicPlansService.moveCourse(payload)
        } catch (error) {
            handleError(error)
        }
    }

    selectTerm = async (academicPlanTermId, termId) => {
        try {
            await academicPlansService.selectTerm({
                academicPlanTermId,
                termId
            })
        } catch (error) {
            handleError(error)
        }
    }

    onSelectAvailableTerm = async (termId, availableTerm) => {
        const availableTermId = availableTerm ? availableTerm.id : null
        await this.selectTerm(termId, availableTermId)
        await this.getListTerms()
    }

    onAddTermItem = async () => {
        try {
            this.setState({isCreating: true})
            await academicPlansService.addNewTerm({studentProfileId: this.props.studentId})
            const terms = await academicPlansService.getListCourseByTerm({
                studentProfileId: this.props.studentId,
                viewType: AcademicPlans.TabKey.Term
            })
            const newTerms = this.initTermData(terms)
            this.setState({terms: newTerms})
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isCreating: false})
        }
    }

    onRegisterCourseSuccess = async () => {
        await this.getListTerms()
    }

    updateEvents = (data: AcademicPlans.UpdateEvents[]) => {
        let {events, registeringCourses} = this.state
        const newRegisteringCourses = {...registeringCourses}
        data.forEach(({type, changedEvents, schedule}) => {
            switch (type) {
                case AcademicPlans.UpdateEventType.Add: {
                    if (schedule) {
                        const instructorIds = (schedule.instructors || []).map((instructor) => instructor.userId)
                        changedEvents.forEach((event: any) => {
                            events.push({
                                ...event,
                                id: event.object_id,
                                start: moment(event.start_at_utc).local().format(),
                                end: moment(event.end_at_utc).local().format(),
                                startDateTime: moment(event.start_at_utc).local().format(),
                                endDateTime: moment(event.end_at_utc).local().format(),
                                color: getScheduleEventColor(event.json_data.eventType),
                                scheduleKey: schedule.key,
                                scheduleEventsType: event.schedule_events_type,
                                title: schedule.schedule_suffix,
                                description: event.description,
                                instructorIds,
                                instructors: schedule.instructors,
                                order: 2
                            })
                        })
                        newRegisteringCourses[schedule.course_id] = schedule
                    }
                    break
                }
                case AcademicPlans.UpdateEventType.Delete: {
                    const removedEventIds = changedEvents.map((event) => event.object_id)
                    events = events.filter((event) => !removedEventIds.includes(event.id))
                    newRegisteringCourses[schedule.course_id] = null
                    break
                }
                case AcademicPlans.UpdateEventType.Update:
                    break
                default:
                    break
            }
        })
        const conflictEvents = this.getConflictEvents(events)
        this.setState({events, conflictEvents, registeringCourses: newRegisteringCourses})
    }

    getConflictEvents = (events) => {
        const eventsTemp = events.sort((a, b) => moment(a.start).diff(moment(b.start)))
        const overlapDates = []
        for (let index = 0; index < eventsTemp.length - 1; index += 1) {
            const isTimeConflict =
                eventsTemp[index].start < eventsTemp[index + 1].end &&
                eventsTemp[index + 1].start < eventsTemp[index].end
            if (isTimeConflict) {
                overlapDates.push({
                    event1: eventsTemp[index],
                    event2: eventsTemp[index + 1]
                })
            }
        }
        const conflictEvents = []
        overlapDates.forEach(({event1, event2}) => {
            const orderDates = [event1.start, event1.end, event2.start, event2.end].sort((a, b) =>
                moment(a).diff(moment(b))
            )
            conflictEvents.push({
                start: orderDates[1],
                end: orderDates[2],
                className: "conflictEvent",
                title: "CONFLICT",
                isConflict: true,
                order: 3,
                courseIds: [event1, event2].map((event) => event.courseId).filter((courseId) => courseId),
                events: {
                    event1,
                    event2
                }
            })
        })
        return conflictEvents
    }

    changeActiveRegistrationTerm = async (registeringTerm) => {
        this.setState({registeringTerm})
        await this.getScheduleEvents(registeringTerm)
    }

    registerAllCourses = async () => {
        try {
            this.setState({isRegisteringAllCourses: true})
            const {registeringTerm, registeringCourses} = this.state
            const academicCourses: {academicPlanCourseId: number; scheduleId: number}[] = []
            registeringTerm.sections.forEach((section) => {
                section.courses
                    .filter(
                        (course) =>
                            course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Approved &&
                            !!registeringCourses[course.courseId]
                    )
                    .forEach((course) => {
                        academicCourses.push({
                            academicPlanCourseId: course.academicPlanCourseId,
                            scheduleId: registeringCourses[course.courseId].id
                        })
                    })
            })
            await academicPlansService.registerAllCourses({
                studentProfileId: this.props.studentId,
                academicCourses
            })
            toastSuccess("Register all courses successfully")
            this.getListTerms()
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isRegisteringAllCourses: false})
        }
    }

    renderRegisterAllCourses = () => {
        const {registeringTerm, registeringCourses, isRegisteringAllCourses, conflictEvents} = this.state
        const canTermRegister =
            registeringTerm &&
            registeringTerm.termId &&
            moment().startOf("date").isSameOrAfter(moment(registeringTerm.registration_start_date).startOf("date")) &&
            moment().endOf("date").isSameOrBefore(moment(registeringTerm.registration_end_date).endOf("date"))
        if (!registeringTerm || !canTermRegister) {
            return null
        }
        let conflictCourseIds = []
        conflictEvents.forEach((conflictEvent) => {
            conflictCourseIds = [...conflictCourseIds, ...conflictEvent.courseIds]
        })
        let allCourses = []
        registeringTerm.sections.forEach((section) => {
            const sectionCourses = section.courses.filter(
                (course) => course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Approved
            )
            allCourses = [...allCourses, ...sectionCourses]
        })
        const allCoursesHasRegistered = registeringTerm.sections.every((section) =>
            section.courses.every(
                (course) => course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Registered
            )
        )
        const hasCourseSelected =
            allCourses.filter(
                (course) =>
                    course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Approved &&
                    !!registeringCourses[course.courseId]
            ).length > 0
        const hasConflictEvent = registeringTerm.sections.some((section) =>
            section.courses.some((course) => conflictCourseIds.includes(course.courseId))
        )
        const disabledBtn = !hasCourseSelected || hasConflictEvent || allCoursesHasRegistered
        let errorMessage = ""
        if (!allCoursesHasRegistered) {
            if (!hasCourseSelected) {
                errorMessage = "Select schedule for all courses"
            } else if (hasConflictEvent) {
                errorMessage = "Please resolve schedule conflict"
            }
        }
        return (
            <div className={styles.registerAllCourses}>
                <BaseButton
                    title="Register for all courses"
                    disabled={disabledBtn}
                    loading={isRegisteringAllCourses}
                    onClick={this.registerAllCourses}
                />
                {errorMessage && <span className={styles.registerAllCoursesError}>{errorMessage}</span>}
            </div>
        )
    }

    reviewAllCourses = async () => {
        try {
            this.setState({isApprovingAllCourses: true})
            const {registeringTerm} = this.state
            const academicPlanCourseIds: number[] = []
            registeringTerm.sections.forEach((section) => {
                section.courses.forEach((course) => {
                    if (course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.PendingReview)
                        academicPlanCourseIds.push(course.academicPlanCourseId)
                })
            })
            await academicPlansService.reviewAllCourses({
                studentProfileId: this.props.studentId,
                academicPlanCourseIds,
                reviewStatus: AcademicPlans.AcademicPlanCourseReviewStatus.Approved,
                staffProfileId: this.props.currentProfileId
            })
            toastSuccess("Approved all courses successfully")
            this.getListTerms()
        } catch (error) {
            handleError(error)
        } finally {
            this.setState({isApprovingAllCourses: false})
        }
    }

    renderReviewAllCourses = () => {
        const {isStaff} = this.props
        if (!isStaff) {
            return null
        }

        const {isApprovingAllCourses, registeringTerm} = this.state
        if (!registeringTerm) {
            return null
        }

        const academicPlanCourseIds: number[] = []
        registeringTerm.sections.forEach((section) => {
            section.courses.forEach((course) => {
                if (course.reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.PendingReview)
                    academicPlanCourseIds.push(course.academicPlanCourseId)
            })
        })

        if (academicPlanCourseIds.length <= 0) {
            return null
        }

        return (
            <div className={styles.reviewAllCourses}>
                <BaseButton
                    title="Approve all courses"
                    loading={isApprovingAllCourses}
                    onClick={this.reviewAllCourses}
                />
            </div>
        )
    }

    render() {
        const {
            terms,
            isCreating,
            isLoading,
            availableTerms,
            registeringTerm,
            selectedAvailableTermIds,
            events,
            conflictEvents
        } = this.state
        const {studentId, student, isStaff} = this.props

        if (isLoading) {
            return (
                <div className={styles.loadingWrap}>
                    <BaseLoading isShow />
                </div>
            )
        }

        return (
            <div>
                <div className={styles.allCoursesAction}>
                    {this.renderRegisterAllCourses()}
                    {this.renderReviewAllCourses()}
                </div>
                <AcademicPlanCalendar
                    registeringTerm={registeringTerm}
                    events={[...events, ...conflictEvents]}
                    updateEvents={this.updateEvents}
                />
                <BaseLoading isShow={isCreating} />
                <div className={cx("", styles.termBodyWrap)}>
                    <TransferAndPreviouslyCompletedTerm
                        key={-1}
                        studentId={studentId}
                        student={student}
                        isStaff={isStaff}
                        termItem={this.state.transferAndPreviouslyCompletedTerm}
                        index={-1}
                        availableTerms={availableTerms}
                        registeringTerm={registeringTerm}
                        selectedAvailableTermIds={selectedAvailableTermIds}
                        conflictEvents={conflictEvents}
                        onUpdateTermItem={this.onUpdateTermItem}
                        onSelectAvailableTerm={this.onSelectAvailableTerm}
                        reloadTerm={this.getListTerms}
                        updateEvents={this.updateEvents}
                        onRegisterCourseSuccess={this.onRegisterCourseSuccess}
                        changeActiveRegistrationTerm={this.changeActiveRegistrationTerm}
                    />
                    <DragDropContext onDragEnd={this.onDragEnd} className={styles.termItemWrap}>
                        {terms.map((termItem, index) => (
                            <TermItem
                                key={index}
                                studentId={studentId}
                                student={student}
                                isStaff={isStaff}
                                termItem={termItem}
                                index={index}
                                availableTerms={availableTerms}
                                registeringTerm={registeringTerm}
                                selectedAvailableTermIds={selectedAvailableTermIds}
                                conflictEvents={conflictEvents}
                                onUpdateTermItem={this.onUpdateTermItem}
                                onSelectAvailableTerm={this.onSelectAvailableTerm}
                                reloadTerm={this.getListTerms}
                                updateEvents={this.updateEvents}
                                onRegisterCourseSuccess={this.onRegisterCourseSuccess}
                                changeActiveRegistrationTerm={this.changeActiveRegistrationTerm}
                            />
                        ))}
                    </DragDropContext>
                    {isStaff && (
                        <div className={styles.termItem}>
                            <div className={styles.termBody} onClick={this.onAddTermItem}>
                                Add new Term
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
