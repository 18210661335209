/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownProps} from "../Select"
import {formatDateOnly} from "helpers"
import {useModel, usePaymentPeriods} from "hooks"
import {PaymentPeriod} from "types/student-account/payment-period"
import {groupBy} from "lodash"

type Props = Partial<KlassDropdownProps> & {
    programIds?: number[]
}

const PaymentPeriodStartDateSelect: React.FC<Props> = ({programIds, ...props}) => {
    const {t} = useTranslation(["common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    const {paymentPeriods} = usePaymentPeriods({programIds})

    const paymentPeriodOptions = React.useMemo(() => {
        return Object.entries(groupBy(paymentPeriods, "programId")).map(([programId, periods]) => {
            const program = periods[0]?.program
            return {
                label: program ? program.name : "Not defined",
                options: periods.map((period) => ({
                    ...period,
                    name: formatDateOnly(period.startDate, userDateFormat),
                    startValue: formatDateOnly(period.startDate, "YYYY-MM-DD"),
                    endValue: formatDateOnly(period.endDate, "YYYY-MM-DD")
                }))
            }
        })
    }, [paymentPeriods])

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={paymentPeriodOptions}
            labelKey="name"
            valueKey="periodId"
            getOptionLabel={(period: PaymentPeriod) => formatDateOnly(period.startDate, userDateFormat)}
        />
    )
}

export default PaymentPeriodStartDateSelect
