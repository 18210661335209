/* eslint-disable react-hooks/exhaustive-deps */
import cx from "classnames"
import produce from "immer"
import React from "react"
import PerfectScrollbar from "react-perfect-scrollbar"
import {KlassappTableHeader} from "uiKit"
import styles from "./StudentsTable.module.css"
import {ResizeHandler, RowItem, TotalRowItem} from "./parts"
import {Column, ColumnData, DEFAULT_COLUMN_DATA} from "./types"
import CircularSpin from "components/CircularSpin"

type StudentsTableProps<T> = {
    columns: Column<T>[]
    visibleColumns: string[]
    fixedColumns: string[]
    initialColumnData?: Record<string, ColumnData>
    totalRows: string[]
    onRowClick?: (record: T) => void
    renderCell?: (column: Column<T>, record: T) => React.ReactNode
    renderTotalCell?: (field: string) => React.ReactNode
    page: number
    pageSize: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    isLoading: boolean
    total: number
    records: T[]
}

export function StudentsTable<T extends {id: string | number}>({
    columns,
    visibleColumns,
    fixedColumns,
    initialColumnData = {},
    totalRows,
    onRowClick,
    renderCell,
    renderTotalCell,
    page,
    pageSize,
    setPage,
    setPageSize,
    isLoading,
    total,
    records
}: StudentsTableProps<T>) {
    /* region table logic */

    const studentsTableRef = React.useRef<HTMLDivElement>()
    const headerScrollRef = React.useRef<HTMLDivElement>()
    const bodyScrollRef = React.useRef<HTMLDivElement>()
    const totalScrollRef = React.useRef<HTMLDivElement>()
    const virtualScrollRef = React.useRef<HTMLDivElement>()
    const resizeRef = React.useRef<HTMLDivElement>()

    const [resizeClientX, setResizeClientX] = React.useState(0)
    const [resizeWidth, setResizeWidth] = React.useState(0)
    const [resizeCursor, setResizeCursor] = React.useState("ew-resize")
    const [resizingColumn, setResizingColumn] = React.useState("")
    const [virtualResizeWidth, setVirtualResizeWidth] = React.useState(0)
    const [columnData, setColumnData] = React.useState<Record<string, ColumnData>>(initialColumnData)

    React.useEffect(() => {
        setColumnData((prev) =>
            produce(prev, (draft) => {
                for (const column of columns) {
                    if (!draft[column.field]) {
                        draft[column.field] = DEFAULT_COLUMN_DATA
                    }
                }
            })
        )
    }, [columns])

    const handleHeaderScroll = React.useCallback((event) => {
        const {
            target: {scrollLeft}
        } = event
        if (headerScrollRef.current) headerScrollRef.current.scrollLeft = scrollLeft
        if (virtualScrollRef.current) virtualScrollRef.current.scrollLeft = scrollLeft
    }, [])

    const handleBodyScroll = React.useCallback((event) => {
        const {
            target: {scrollLeft}
        } = event
        if (bodyScrollRef.current) bodyScrollRef.current.scrollLeft = scrollLeft
        if (totalScrollRef.current) totalScrollRef.current.scrollLeft = scrollLeft
        if (virtualScrollRef.current) virtualScrollRef.current.scrollLeft = scrollLeft
    }, [])

    const handleVirtualScroll = React.useCallback((event) => {
        const {
            target: {scrollLeft}
        } = event
        if (headerScrollRef.current) headerScrollRef.current.scrollLeft = scrollLeft
        if (bodyScrollRef.current) bodyScrollRef.current.scrollLeft = scrollLeft
        if (totalScrollRef.current) totalScrollRef.current.scrollLeft = scrollLeft
    }, [])

    const onMouseMove = React.useCallback(
        (event) => {
            if (!resizingColumn) return
            const dx = event.clientX - resizeClientX
            const tempColWidth = resizeWidth + dx
            let resizeCursor = ""
            if (tempColWidth < columnData[resizingColumn]?.min) {
                resizeCursor = "e-resize"
            } else if (tempColWidth > columnData[resizingColumn]?.max) {
                resizeCursor = "w-resize"
            } else {
                resizeCursor = "ew-resize"
                setVirtualResizeWidth(-dx)
            }
            setResizeCursor(resizeCursor)
            if (resizeRef.current) {
                resizeRef.current.style.cursor = resizeCursor
            }
            document.body.style.cursor = resizeCursor
        },
        [resizeClientX, resizeWidth, resizingColumn, columnData]
    )

    const onMouseUp = React.useCallback(() => {
        if (resizeRef.current) {
            resizeRef.current.style.removeProperty("cursor")
        }
        document.body.style.removeProperty("cursor")
        setResizingColumn("")
        setVirtualResizeWidth(0)
        setColumnData((prevColumnWidths) => ({
            ...prevColumnWidths,
            [resizingColumn]: {
                ...prevColumnWidths[resizingColumn],
                width: (prevColumnWidths[resizingColumn]?.width ?? 0) - virtualResizeWidth
            }
        }))
    }, [resizingColumn, virtualResizeWidth])

    const onMousedown = React.useCallback(
        (event, resizingColumn) => {
            setResizeClientX(event.clientX)
            setResizeWidth(columnData[resizingColumn]?.width ?? 0)
            setResizingColumn(resizingColumn)
        },
        [columnData]
    )

    React.useEffect(() => {
        document.addEventListener("mousemove", onMouseMove)
        document.addEventListener("mouseup", onMouseUp)
        return () => {
            document.removeEventListener("mousemove", onMouseMove)
            document.removeEventListener("mouseup", onMouseUp)
        }
    }, [onMouseMove, onMouseUp])

    const leftColumns = React.useMemo(() => {
        return columns.filter((column) => fixedColumns.includes(column.field))
    }, [columns, fixedColumns])

    const leftWidth = React.useMemo(() => {
        let width = 0
        leftColumns.forEach((column) => {
            width += visibleColumns.includes(column.field) ? columnData[column.field]?.width ?? 0 : 0
        })
        return width
    }, [visibleColumns, leftColumns, columnData])

    const rightColumns = React.useMemo(() => {
        return columns.filter((column) => !fixedColumns.includes(column.field))
    }, [columns, fixedColumns])

    const horizontalScrollbarWidth = React.useMemo(() => {
        let width = leftWidth
        rightColumns.forEach((column) => {
            width += visibleColumns.includes(column.field) ? columnData[column.field]?.width ?? 0 : 0
        })
        return width
    }, [visibleColumns, rightColumns, columnData, leftWidth])

    const renderHeaderColumn = (column, isLeft = true) =>
        visibleColumns.includes(column.field) && (
            <div
                key={column.field}
                className={isLeft ? styles.headerLeft : styles.headerRight}
                style={{width: columnData[column.field]?.width, backgroundColor: column.backgroundColor}}>
                <p className={isLeft ? styles.headerLeft__title : styles.headerRight__title}>
                    {column.titleHtml ?? column.title}
                </p>

                <ResizeHandler
                    refEl={resizeRef}
                    colName={column.field}
                    onMousedown={onMousedown}
                    onMouseup={onMouseUp}
                    resizeCursor={resizeCursor}
                    resizingColumn={resizingColumn}
                    virtualResizeWidth={virtualResizeWidth}
                />
            </div>
        )

    /* endregion table logic */

    return (
        <div>
            <KlassappTableHeader
                total={total}
                page={page}
                defaultPageSize={pageSize}
                onChangePage={setPage}
                onChangeRowPerPage={setPageSize}
            />

            <div ref={studentsTableRef} className={styles.container}>
                <div className={cx("custom-scroll", styles.horizontalTable)}>
                    <div className={styles.horizontalTableHeaderStructure}>
                        <div className={styles.headerStructureLeft} style={{width: leftWidth}}>
                            {leftColumns.map((column) => renderHeaderColumn(column, true))}
                        </div>
                        <div className={styles.headerStructureRight} style={{left: leftWidth}}>
                            <div
                                className={styles.headerStructureScrollable}
                                ref={headerScrollRef}
                                onScroll={handleBodyScroll}>
                                <div className={styles.headerStructureScrollContent}>
                                    {rightColumns.map((column) => renderHeaderColumn(column, false))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.verticalScroll}>
                        <div className={styles.horizontalScroll} ref={bodyScrollRef} onScroll={handleHeaderScroll}>
                            {isLoading ? (
                                <div className={styles.loading}>
                                    <CircularSpin size="large" />
                                </div>
                            ) : (
                                <>
                                    {records.map((record) => (
                                        <RowItem<T>
                                            key={record.id}
                                            record={record}
                                            leftColumns={leftColumns}
                                            rightColumns={rightColumns}
                                            visibleColumns={visibleColumns}
                                            columnData={columnData}
                                            leftWidth={leftWidth}
                                            render={renderCell}
                                            onRowClick={onRowClick}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </div>

                    <div className={styles.horizontalScrollPlaceholder} />

                    <div
                        className={styles.horizontalScrollTotal}
                        ref={totalScrollRef}
                        style={{minHeight: totalRows.length * 40 + 14}}>
                        {!isLoading &&
                            totalRows.map((totalRow) => (
                                <TotalRowItem
                                    key={totalRow}
                                    title={totalRow}
                                    leftColumns={leftColumns}
                                    rightColumns={rightColumns}
                                    visibleColumns={visibleColumns}
                                    columnData={columnData}
                                    leftWidth={leftWidth}
                                    render={renderTotalCell}
                                />
                            ))}
                    </div>

                    <div className={styles.horizontalScrollWrapper}>
                        <PerfectScrollbar
                            className={styles.horizontalScrollbar__scrollable}
                            // @ts-ignore
                            ref={virtualScrollRef}
                            onScroll={handleVirtualScroll}>
                            <div
                                className={styles.horizontalScrollbar__Content}
                                style={{minWidth: horizontalScrollbarWidth}}
                            />
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        </div>
    )
}
