/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext} from "react"
import {Tabs} from "antd"
import {useTranslation} from "react-i18next"
import {Screen} from "components/ui"
import {useActiveTab, useCurrentProfilePermissions} from "hooks"
import {BaseDepartmentId} from "types/departments"
import {Permissions} from "types/permission"
import {FinancialAidStaffStudentTab, FinancialAidStaffTab, FinancialAidStaffTabNotesTab, ISIR} from "./staffParts"
import {TabHeader} from "components/Tab"
import DisbursementTab from "./staffParts/DisbursementTab/DisbursementTab"
import styles from "./FinancialAid.module.css"
import {ActiveStudentContext, ActiveStudentProvider} from "context/ActiveStudentContext"
import cx from "classnames"
import {AdvisorTitle, FinAidPackageInfo, StudentInfoSidebar} from "uiKit"
import {Modules, useEdularModulesContext} from "@edular/modules"
import {ActivitiesStaffTab} from "sections/FinancialAid/staffParts/Activities"
import {
    FinancialAidStaffPackagingTab,
    FinancialAidStaffVerificationTab
} from "sections/FinancialAid/staffParts/Packaging-Verification"
import {ImportList} from "sections/users/UsersTab/parts"
import {ImportType} from "types/import-users"

const {TabPane} = Tabs

export enum FinancialAidStaffTabs {
    FinancialAid = "financial-aid",
    Activities = "activities",
    Students = "students",
    ISIR = "isir",
    Packaging = "packaging",
    Verification = "verification",
    Notices = "notices",
    Disbursements = "disbursements",
    Refunds = "refunds",
    Import = "import"
}

const FinancialAidStaffView = () => {
    const [activeTabKey, onChangeTab] = useActiveTab(FinancialAidStaffTabs.FinancialAid)
    const {t} = useTranslation(["financialAid"])
    const {isModuleEnable} = useEdularModulesContext()
    const currentProfilePermissions = useCurrentProfilePermissions()
    const departmentId = BaseDepartmentId.FinancialAid

    const {
        student,
        setStudent,
        setStatusChange,
        setStudentStatusChange,
        setFinancialAidStatusChange,
        studentDetailVisible
    } = useContext(ActiveStudentContext)

    React.useEffect(() => {
        setStudent(undefined)
        studentDetailVisible.close()
    }, [activeTabKey])

    return (
        <div className={styles.screenWrap}>
            <Screen
                htmlTitle={t("financialAid.financialAid")}
                header={{
                    title: t("financialAid.financialAid")
                }}
                isReducingPaddingRight={studentDetailVisible.isVisible}>
                <Tabs
                    className="fullwidth klassTab"
                    activeKey={activeTabKey}
                    onChange={onChangeTab}
                    destroyInactiveTabPane>
                    {currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.FinancialAid.FinancialAidStaff.View]
                    }) && (
                        <TabPane
                            tab={<TabHeader icon="DOLLAR_SIGN" title={t("financialAidStaff.financialAid")} />}
                            key={FinancialAidStaffTabs.FinancialAid}>
                            <FinancialAidStaffTab departmentId={departmentId} />
                        </TabPane>
                    )}

                    {isModuleEnable(Modules.ActivitiesAndFollowups) && (
                        <TabPane
                            tab={<TabHeader icon="DOOR_OPEN" title={t("financialAidStaff.activities")} />}
                            key={FinancialAidStaffTabs.Activities}>
                            <ActivitiesStaffTab departmentId={departmentId} />
                        </TabPane>
                    )}

                    {currentProfilePermissions.hasPermissions({
                        staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.View]
                    }) && (
                        <TabPane
                            tab={<TabHeader icon="PERSON_FILL" title={t("financialAidStaff.students")} />}
                            key={FinancialAidStaffTabs.Students}>
                            <FinancialAidStaffStudentTab departmentId={departmentId} />
                        </TabPane>
                    )}

                    <TabPane
                        tab={<TabHeader icon="ISIR" title={t("financialAidStaff.isir")} />}
                        key={FinancialAidStaffTabs.ISIR}>
                        <ISIR />
                    </TabPane>

                    <TabPane
                        tab={<TabHeader icon="ISIR" title={t("financialAidStaff.packaging")} />}
                        key={FinancialAidStaffTabs.Packaging}>
                        <FinancialAidStaffPackagingTab />
                    </TabPane>

                    <TabPane
                        tab={<TabHeader icon="ISIR" title={t("financialAidStaff.verification")} />}
                        key={FinancialAidStaffTabs.Verification}>
                        <FinancialAidStaffVerificationTab />
                    </TabPane>

                    <TabPane
                        tab={<TabHeader icon="SEND" title={t("financialAidStaff.disbursement")} />}
                        key={FinancialAidStaffTabs.Disbursements}>
                        <DisbursementTab />
                    </TabPane>

                    <TabPane
                        tab={<TabHeader icon="UNORDER_LIST" title={t("financialAidStaff.notes")} />}
                        key={FinancialAidStaffTabs.Notices}>
                        <FinancialAidStaffTabNotesTab />
                    </TabPane>

                    {/*
                <TabPane
                    tab={<TabHeader icon="REFUND" title={t("financialAidStaff.refund")} />}
                    key={FinancialAidStaffTabs.Refunds}>
                    <RefundTab />
                </TabPane> */}

                    <TabPane
                        tab={<TabHeader icon="FILE" title={t("financialAidStaff.import")} />}
                        key={FinancialAidStaffTabs.Import}>
                        <ImportList importType={ImportType.AwardSummary} />
                    </TabPane>
                </Tabs>
            </Screen>
            <div className={cx(styles.expandedSidebar, {[styles.hidden]: !studentDetailVisible.isVisible})}>
                {!!student?.profileId && (
                    <StudentInfoSidebar
                        onClose={studentDetailVisible.close}
                        data={{
                            departmentId,
                            student,
                            isDetailButtonVisible: true,
                            detailUrl: "financial-aid",
                            fromPage: "financial-aid",
                            renderDepartmentInfo: (props) => <FinAidPackageInfo student={student} isPanel {...props} />,
                            onChangeStatus: setStatusChange,
                            onChangeStudentStatus: setStudentStatusChange,
                            onChangeFinancialAidStatus: setFinancialAidStatusChange,
                            advisorTitle: AdvisorTitle.finAidAdvisors
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default function FinancialAidStaff() {
    return (
        <ActiveStudentProvider departmentId={BaseDepartmentId.FinancialAid}>
            <FinancialAidStaffView />
        </ActiveStudentProvider>
    )
}
