import React from "react"
import {Icon} from "components/Icon"
import {KlassDropdown} from "components/Select"
import styles from "./GradeTabHeader.module.css"
import {BaseButton} from "components"
import {useModel} from "hooks"

const dot = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
        backgroundColor: color,
        borderRadius: 5,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 20,
        width: 20
    }
})
const colourStyles = {
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        return {...styles, ...dot(data.colorLabel)}
    },
    input: (styles, {data}) => ({...styles, ...dot()}),
    placeholder: (styles) => ({...styles, ...dot()}),
    singleValue: (styles, {data}) => ({...styles, ...dot(data.colorLabel)})
}

export function GradeTabHeader(props) {
    const {dataGradingItems = [], gradingElementId, onChangeElementData, onAddGrade} = props
    const model = useModel()

    return (
        <div className={styles.wrap}>
            <div className={styles.filterWrap}>
                <KlassDropdown
                    placeholder="Type"
                    closeMenuOnSelect
                    value={gradingElementId}
                    onChange={(newValue) => onChangeElementData("gradingElementId", newValue)}
                    options={dataGradingItems}
                    labelKey="gradingItem"
                    valueKey="id"
                    stylesCustom={colourStyles}
                />
                <div className={styles.infoIconWrap}>
                    <Icon icon="WHITE_INFO" className={styles.infoIcon} />
                </div>
            </div>
            <div className={styles.exportBtnWrap}>
                {model.isStaffOrAdmin() ? (
                    <BaseButton onClick={onAddGrade} className={styles.actionAdd} title="Add" variant="primary" />
                ) : null}
            </div>
        </div>
    )
}
