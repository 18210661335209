/* eslint-disable react-hooks/exhaustive-deps */
import {useMemo} from "react"
import styles from "./Card.module.css"
import {useModel} from "hooks"
import {getFullName} from "helpers"
import cx from "classnames"

type Props = {
    icon: JSX.Element
    mainValue: string
    mainUnit?: string
    title: string
    subTitle?: string
    bestValue: string
    rank: number
    comingSoon?: boolean
    comingSoonLabel?: string
}

export function Card(props: Props) {
    const {
        icon,
        mainValue,
        mainUnit,
        title,
        subTitle,
        bestValue,
        rank,
        comingSoon = false,
        comingSoonLabel = "Coming Soon"
    } = props

    const model = useModel()
    const currentUser = model.user

    const listUsers = useMemo(() => {
        const templateUser = {photo: "/image/DefaultAvatar.png", fullName: "Unknown Name", rank}
        let users = []
        if (rank === 0) {
            return []
        }

        if (rank === 1) {
            users = [
                {photo: currentUser.photo?.["64"], fullName: getFullName(currentUser), rank: 1},
                {...templateUser, rank: rank + 1}
            ]
        } else {
            users = [
                {...templateUser, rank: rank - 1},
                {photo: currentUser.photo?.["64"], fullName: getFullName(currentUser), rank}
            ]
        }
        const maxRank = Math.max(...users.map((x) => parseInt(x.rank)))
        users.push({...templateUser, rank: maxRank + 1})
        return users
    }, [rank, currentUser])

    return (
        <div className={styles.container}>
            <div className={cx(styles.card, {[styles.cardCensored]: comingSoon})}>
                <div className={styles.row}>
                    {icon}
                    <div className={styles.mainValueWrap}>
                        <span className={styles.mainValue}>{mainValue}</span>
                        {mainUnit && <span className={styles.mainUnit}>{mainUnit}</span>}
                    </div>
                </div>
                <div className={styles.titleWrap}>
                    <span className={styles.title}>{title}</span>
                    {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
                </div>
                <div className={styles.bestWrap}>
                    <span className={styles.bestTitle}>Best</span>
                    <span className={styles.bestValue}>{bestValue}</span>
                </div>
                <div className={styles.table}>
                    <div className={styles.row}>
                        <span className={styles.tableTitle}>Student</span>
                        <span className={styles.tableTitle}>Rank</span>
                    </div>
                    <div className={styles.list}>
                        {listUsers.map((x, index) => {
                            return (
                                <div className={styles.row} key={index}>
                                    <div className={styles.user}>
                                        <img
                                            src={x.photo}
                                            alt=""
                                            className={cx(styles.userPhoto, {[styles.censoredPhoto]: x.rank !== rank})}
                                        />
                                        <span className={cx(styles.userName, {[styles.censored]: x.rank !== rank})}>
                                            {x.fullName}
                                        </span>
                                    </div>
                                    <div className={cx(styles.userRank, {[styles.userRankHighlight]: x.rank === rank})}>
                                        <span
                                            className={cx(styles.userRankValue, {
                                                [styles.userRankValueHighlight]: x.rank === rank
                                            })}>
                                            {x.rank}
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                        {listUsers.length === 0 && <div className={styles.emptyList}>No record found</div>}
                    </div>
                </div>
            </div>
            {comingSoon && (
                <div className={styles.comingSoon}>
                    <span className={styles.comingSoonLabel}>{comingSoonLabel}</span>
                </div>
            )}
        </div>
    )
}
