/* eslint-disable react-hooks/exhaustive-deps */
import {KlassappTableHOC} from "HOC"
import styles from "./SapAction.module.css"
import {KlassappTableProps} from "types/common"
import {useEffect, useState} from "react"
import {formatDateOnly, handleError} from "helpers"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {Col, Row} from "antd"
import {FormLabel} from "components/Form"
import {KlassDropdown} from "components/Select"
import {OPTIONS_SAP_ACTION_TYPE} from "sections/calendar/parts/common"
import {BaseButton} from "components"
import {AddMeetingPopup} from "./AddMeetingPopup"
import {AddActionPlanPopup} from "./AddActionPlanPopup"
import {academicPlansService} from "services"
import {useModel} from "hooks"

import {AcademicPlans} from "types/academicPlans"
import {useFilterStorage} from "hooks/useFilterStorage"
import {FilterKey} from "types/filter"

type Props = KlassappTableProps & {
    studentId: number
    departmentId: number
}

function ListSapActions(props: Props) {
    const {studentId, departmentId} = props

    const {data, page, total, pageSize, columns, fields, allFields, isLoading, dispatch} = props
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        FilterKey.SapAction,
        {types: []}
    )
    const [showAddMeetingPopup, setShowAddMeetingPopup] = useState(false)
    const [showAddActionPlanPopup, setShowAddActionPlanPopup] = useState(false)
    const model = useModel()

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getData()
    }, [searchValue, page, pageSize])

    const onRefreshData = async () => {
        getData()
    }

    async function getData() {
        try {
            dispatch({isLoading: true})
            const {data, total} = await academicPlansService.listSapActions({
                filter: {
                    studentProfileIds: studentId ? [studentId] : [],
                    types: currentFilter.types?.length ? currentFilter.types.map((type) => type.value) : undefined
                },
                range: {
                    page,
                    pageSize
                }
            })
            const listOfSapActions = data.map((sapAction) => ({
                ...sapAction,
                type: sapAction.type === AcademicPlans.SapActionType.ActionPlan ? "Action Plan" : "Meeting",
                date: formatDateOnly(sapAction.date, model.getUserDateFormat())
            }))
            dispatch({data: listOfSapActions, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function getColumns() {
        const columns = [
            {
                title: "Custom Profile Id",
                field: "customProfileId",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Student Name",
                field: "fullName",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "Type",
                field: "type"
            },
            {
                title: "Date",
                field: "date",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Course",
                field: "courseName",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Term",
                field: "termName",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Schedule",
                field: "scheduleName",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: "Action Plan",
                field: "actionPlan",
                headerStyle: {minWidth: "80px"}
            }
        ]
        return columns
    }

    function getFields() {
        return ["Custom Profile Id", "Student Name", "Type", "Date", "Course", "Term", "Schedule", "Action plan"]
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    const onApplyFilter = () => {
        applyFilter()
        getData()
    }

    return (
        <div>
            <BaseNewFilter
                filter={currentFilter}
                onClick={onApplyFilter}
                onClickClear={clearFilter}
                showSearch={false}
                searchValue={searchValue}
                onSearchInput={changeSearch}
                renderRightFilter={() =>
                    model.isStaffOrAdmin() ? (
                        <div className={styles.actionWrap}>
                            <BaseButton onClick={() => setShowAddMeetingPopup(true)} title="Add Meeting" />
                            <BaseButton onClick={() => setShowAddActionPlanPopup(true)} title="Add Action Plan" />
                        </div>
                    ) : null
                }>
                <Row gutter={[12, 12]}>
                    <Col span={12}>
                        <div>
                            <FormLabel label="SAP Status" />
                            <KlassDropdown
                                value={currentFilter.types}
                                placeholder="Status"
                                isMulti
                                onChange={(option: any) => onChangeFilter("types", option)}
                                valueKey="value"
                                labelKey="label"
                                options={OPTIONS_SAP_ACTION_TYPE}
                                hasEmptyOption
                            />
                        </div>
                    </Col>
                </Row>
            </BaseNewFilter>
            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                    />
                </Col>
            </Row>
            <AddMeetingPopup
                isShow={showAddMeetingPopup}
                onClose={() => {
                    setShowAddMeetingPopup(false)
                }}
                studentProfileId={studentId}
                onRefreshData={onRefreshData}
            />
            <AddActionPlanPopup
                isShow={showAddActionPlanPopup}
                onClose={() => {
                    setShowAddActionPlanPopup(false)
                }}
                studentProfileId={studentId}
                onRefreshData={onRefreshData}
            />
        </div>
    )
}

export const SapAction = KlassappTableHOC(ListSapActions)
