import {get} from "lodash"
import BaseService from "./Base"
import {AcademicPlans} from "types/academicPlans"
import {AttendanceEventStatus, AttendanceEventType} from "types/attendance"
import {ClockHoursTranscriptSection, ClockHoursTranscriptSectionEditPayload} from "types/clock-hours-transcript"

type UpdateAttendancesPayload = {
    attendances: Array<{
        academicStudentStatsId?: number
        academicTermStatsId?: number
        courseId?: number
        studentProfileId: number
        termId: number
        scheduleId?: number
        attendancePercentage: number
    }>
}
class AcademicPlansService extends BaseService {
    async getListCourseBySection(params = {}) {
        const response = await this.post("/v1/academic-plans/list-courses/by-section", params)
        return get(response, "data.data", [])
    }
    async getListTermCourseSchedule(params = {}) {
        const response = await this.post("/v1/academic-plans/term-course-schedule", params)
        return get(response, "data.data", [])
    }

    async getRosterReportData(params = {}) {
        const response = await this.post("/v1/academic-reports/term-roster-report", params)
        return get(response, "data", {data: [], total: 0})
    }

    async getListCourseByTerm(params = {}) {
        const response = await this.post("/v1/academic-plans/list-courses/by-term", params)
        return get(response, "data.data", [])
    }

    async getAttendancePercentage(params: {
        courseId?: number
        scheduleId?: number
        termIds: number[]
        studentProfileIds: number[]
    }): Promise<{percentage: number; totalHoursCompleted: number; totalHours: number}> {
        const response = await this.post("/v3/academic-plans/get-attendance", params)
        return get(response, "data.data", {})
    }

    async updateAttendances(params: UpdateAttendancesPayload): Promise<UpdateAttendancesPayload> {
        const response = await this.post("/v3/academic-plans/update-attendances", params)
        return get(response, "data.data", [])
    }

    async getListAvailableTerms(params = {}) {
        const response = await this.post("/v1/academic-plans/list-available-terms", params)
        return get(response, "data.data", [])
    }

    async selectTerm(params = {}) {
        const response = await this.post("/v1/academic-plans/select-term", params)
        return get(response, "data", {})
    }

    async getSchedulesByCourse(params = {}) {
        const response = await this.post("/v1/academic-plans/get-schedules", params)
        return get(response, "data.data", {})
    }

    async getScheduleEventsByTerm(params = {}) {
        const response = await this.post("/v1/academic-plans/get-schedule-events", params)
        return get(response, "data.data", {})
    }

    async addNewTerm(params = {}) {
        const response = await this.post("/v1/academic-plans/add-new-term", params)
        return get(response, "data", {})
    }

    async removeTerm(params = {}) {
        const response = await this.post("/v1/academic-plans/remove-term", params)
        return get(response, "data", {})
    }

    async moveCourse(params = {}) {
        const response = await this.post("/v1/academic-plans/move-course", params)
        return get(response, "data", {})
    }

    async registerCourse(params = {}) {
        const response = await this.post("/v1/academic-plans/register-course", params)
        return get(response, "data", {})
    }

    async addCourse(params = {}) {
        const response = await this.post("/v1/academic-plans/add-course", params)
        return get(response, "data", {})
    }

    async removeCourse(params = {}) {
        const response = await this.post("/v1/academic-plans/remove-course", params)
        return get(response, "data", {})
    }

    async dropCourse(params = {}) {
        const response = await this.post("/v1/academic-plans/drop-course", params)
        return get(response, "data", {})
    }

    async changeCourseStatus(params = {}) {
        const response = await this.post("/v1/academic-plans/change-course-status", params)
        return get(response, "data", {})
    }

    async dropMultiplesCourses(params = {}) {
        const response = await this.post("/v1/academic-plans/drop-multiples-courses", params)

        return get(response, "data", {})
    }
    async retakeCourses(params = {}) {
        const response = await this.post("/v1/academic-plans/retake-course", params)
        return get(response, "data", {})
    }

    async updateAcademicPlanCourse(params = {}) {
        const response = await this.post("/v1/academic-plans/edit", params)
        return get(response, "data", {})
    }

    async changeSchedule(params: {scheduleId: number; academicPlanCourseId: number; studentProfileId: number}) {
        const response = await this.post("/v1/academic-plans/change-schedule", params)
        return get(response, "data", {})
    }

    async listSapAlerts(params: {
        filter: {
            studentProfileIds: number[]
            termIds: number[]
            sapStatuses: AcademicPlans.SapStatus[]
        }
        range: {page: number; pageSize: number}
    }) {
        const response = await this.post("/v3/academic/sap-alerts/list", params)
        return get(response, "data", {})
    }

    async listSapActions(params: {
        filter: {
            studentProfileIds: number[]
            types?: AcademicPlans.SapActionType[]
        }
        range: {page: number; pageSize: number}
    }) {
        const response = await this.post("/v3/academic/sap-actions/list", params)
        return get(response, "data", {})
    }

    async addSapActions(params: {sapActions: AcademicPlans.SapAction[]}) {
        const response = await this.post("/v3/academic/sap-actions/add", params)
        return get(response, "data", {})
    }

    async addSapMeetings(params: {sapMeetings: AcademicPlans.SapMeeting[]}) {
        const response = await this.post("/v3/academic/sap-meeting/add", params)
        return get(response, "data", {})
    }

    async reviewCourse(params = {}) {
        const response = await this.post("/v1/academic-plans/review-course-change-request", params)
        return get(response, "data", {})
    }

    async getPrerequisiteCourses(params = {}) {
        const response = await this.post("/v1/academic-plans/get-prerequisite-course", params)
        return get(response, "data", {})
    }

    async getListSections(params = {}) {
        const response = await this.post("/v1/academic-plans/list-sections", params)
        return get(response, "data.data.data", [])
    }

    async getAllSectionCourses(params = {}) {
        const response = await this.post("/v1/academic-plans/list-section-courses", params)
        return get(response, "data.data.data", [])
    }

    async setupAcademicPlan(params) {
        const response = await this.post("/v1/academic-plans/setup-academic-plan", params)
        return get(response, "data.data.data", [])
    }

    async addSection(data) {
        const response = await this.post("/v1/academic-plans/add-section", data)
        return get(response, "data.data.data", [])
    }

    async removeSection(data) {
        const response = await this.post("/v1/academic-plans/remove-section", data)
        return get(response, "data.data", [])
    }

    async getStudentStatistics(data) {
        const response = await this.post("/v1/academic-plans/student-stats", data)
        return get(response, "data.data", [])
    }

    async updateLdaTermCourseSchedule(
        data: Array<{
            termId: number
            courseId: number
            scheduleId: number
            lastDayOfAttendance: string
            profileId: number
            isForceUpdate?: boolean
        }>
    ) {
        const response = await this.post("/v1/users/profile/update-last-day-of-attendance-batch", data)
        return get(response, "data.data", [])
    }

    async updateTermSapStatus(data: {
        termId: number
        studentProfileId: number
        sapDate?: string
        sapStatus?: AcademicPlans.SapStatus
    }) {
        const response = await this.post("/v3/academic-plans/update-term-sap-status", data)
        return get(response, "data.data", [])
    }

    async updateCourseSapStatus(data: {
        termId: number
        courseId: number
        scheduleId: number
        studentProfileId: number
        sapDate?: string
        sapStatus?: AcademicPlans.SapStatus
    }) {
        const response = await this.post("/v3/academic-plans/update-course-sap-status", data)
        return get(response, "data.data", [])
    }

    async getListTermsCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/list-terms`, params)
        return get(response, "data", {})
    }

    async addTermsCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/add-term`, params)
        return get(response, "data", {})
    }

    async updateTermsCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/update-term`, params)
        return get(response, "data", {})
    }

    async removeTermsCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/remove-term`, params)
        return get(response, "data", {})
    }

    async addCourseCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/add-course`, params)
        return get(response, "data", {})
    }

    async updateCourseCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/update-course`, params)
        return get(response, "data", {})
    }

    async removeCourseCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/remove-course`, params)
        return get(response, "data", {})
    }

    async addEventCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/add-event`, params)
        return get(response, "data", {})
    }

    async updateEventCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/update-event`, params)
        return get(response, "data", {})
    }

    async removeEventCalendarBased(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/remove-event`, params)
        return get(response, "data", {})
    }

    async getSettings(params) {
        const response = await this.post(`/v1/academic-plans/get-setting`, params)
        return get(response, "data", {})
    }

    async changeSettings(params) {
        const response = await this.post(`/v1/academic-plans/change-setting`, params)
        return get(response, "data", {})
    }

    async registerTerm(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/register-term`, params)
        return get(response, "data", {})
    }

    async unregisterTerm(params) {
        const response = await this.post(`/v1/academic-plans/calendar-based/unregister-term`, params)
        return get(response, "data", {})
    }

    async getEnrollmentStats(params) {
        const response = await this.post(`/v1/academic-plans/enrollment-stats`, params)
        return get(response, "data.data", {})
    }

    async registerAllCourses(params) {
        const response = await this.post(`/v1/academic-plans/register-all-courses`, params)
        return get(response, "data.data", {})
    }

    async getAcademicPlanTerms(params) {
        const response = await this.post(`/v1/academic-plans/get-academic-plan-terms`, params)
        return get(response, "data.data", {})
    }

    async reviewAllCourses(params) {
        const response = await this.post(`/v1/academic-plans/review-all-courses-change-requests`, params)
        return get(response, "data.data", {})
    }

    async deleteMakeupStudentInLesson(params: {
        deletedMakeupStudents: Array<{
            courseId: number
            termId: number
            scheduleId: number
            lessonId: number
            studentProfileId: number
            calendarEventId: number
        }>
    }) {
        const response = await this.post(`/v3/academic-plans/delete-makeup-student`, params)
        return get(response, "data.data", {})
    }

    async addStudent(params: {
        academicMakeupStudents: Array<{
            academicMakeupStudentId?: number
            courseId: number
            termId: number
            scheduleId: number
            lessonId: number
            studentProfileId: number
            calendarEventId: number
            targetLesson?: {
                lessonId: number
                courseId: number
                termId: number
                scheduleId?: number
            }
            reason?: string
            type: AcademicPlans.MakeupStudentType
        }>
    }) {
        const response = await this.post(`/v3/academic-plans/add-student`, params)
        return get(response, "data.data", {})
    }

    async createClassroomMakeupAttendance(params: {
        academicClassroomMakeupStudents: Array<{
            academicMakeupStudentId?: number
            courseId: number
            termId: number
            scheduleId: number
            studentCheckInSignatureId: number
            lessonId: number
            createdAt: string
            studentProfileId: number
            calendarEventId: number
            reason?: string
        }>
    }) {
        const response = await this.post(`/v3/academic-plans/classroom-makeup-attendance/create`, params)
        return get(response, "data.data", {})
    }

    async getClassroomMakeupAttendanceStats(payload: {
        dateRange?: {
            startDate: string
            endDate: string
        }
    }): Promise<{
        numberOfCompletedStudent: number
        numberOfMakeupStudent: number
        numberOfUnconfirmedMakeupAttendance: number
        numberOfNotVerifiedMakeupAttendance: number
        numberOfIncompleteMakeupAttendance: number
    }> {
        const response = await this.post(`/v3/academic-plans/classroom-makeup-attendance/stats`, payload)
        return get(response, "data.data", {})
    }

    async editClassroomMakeupAttendance(params: {
        academicClassroomMakeupStudents: Array<{
            academicMakeupStudentId?: number
            studentCheckInSignatureId?: number
            studentCheckOutSignatureId?: number
            advisorSignatureId?: number
            lessonId: number | null
            instructorSignatureId?: number
            studentProfileId: number
            events?: Array<{
                eventType: AttendanceEventType
                status: AttendanceEventStatus
                createdAt: string
            }>
            reason: string
            credit?: string | null
        }>
    }) {
        const response = await this.post(`/v3/academic-plans/classroom-makeup-attendance/edit`, params)
        return get(response, "data.data", {})
    }

    async getListOfClassroomMakeupAttendance(params: {
        filter: Partial<{
            courseIds: number[]
            termIds: number[]
            scheduleIds: number[]
            search: string
            date: string
            isUnconfirmed: boolean
            isNotVerified: boolean
            lessonIds: number[]
            linkedObjects: boolean
            studentProfileIds: number[]
            academicMakeupStudentIds: number[]
        }>
    }) {
        const response = await this.post(`/v3/instructional/makeup-students`, params)
        return get(response, "data", {})
    }

    listClockHoursTranscriptSections = async (params: {
        filter: Partial<{
            programVersionId: number
        }>
        range: {page: number; pageSize: number}
    }): Promise<{data: ClockHoursTranscriptSection[]; total: number}> => {
        const response = await this.post(`/v1/clock-hours-transcript/sections/list`, params)
        return get(response, "data", {})
    }

    editClockHoursTranscriptSections = async (params: ClockHoursTranscriptSectionEditPayload) => {
        const response = await this.post(`/v1/clock-hours-transcript/sections/edit`, params)
        return get(response, "data", {})
    }
}

export default AcademicPlansService
