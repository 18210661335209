import {useQuery} from "@tanstack/react-query"
import {handleError} from "helpers"
import {userServiceV3} from "services"
import {Auth} from "types/auth"

const queryCacheKey = "autocomplete-user"

type AutocompleteUsersProps = {
    type?: Auth.UserProfileType[]
    search?: string
    withProfiles?: boolean
    belongsToMyCampuses?: boolean
}

export const useAutocompleteUsers = ({
    type,
    search,
    withProfiles = true,
    belongsToMyCampuses = true
}: AutocompleteUsersProps) => {
    const query = useQuery(
        [queryCacheKey, type, search, withProfiles, belongsToMyCampuses],
        async () => {
            const {data} = await userServiceV3.getAllAutocomplete({
                filter: {
                    type,
                    search,
                    ...(belongsToMyCampuses ? {belongsToMyCampuses} : undefined),
                    ...(withProfiles ? {withProfiles} : undefined)
                }
            })
            return data
        },
        {
            onError: (err) => {
                handleError(err)
            },
            initialData: []
        }
    )

    return {
        users: query.data,
        isLoading: query.isFetching
    }
}
