/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer, useState} from "react"
import {useTranslation} from "react-i18next"
import {Radio} from "antd"
import cx from "classnames"
import debounce from "debounce-promise"
import {keyBy, has, get, isEmpty} from "lodash"
import {BaseButton, SecondaryButton} from "components/buttons"
import {FormLabel} from "components/Form"
import {KlassDropAsyncPaginate, KlassDropdown} from "components/Select"
import {ParticipantType, SelectOthersFrom, TaskType, UserType} from "types/tasks"
import {userPersonalContactRolesServiceV3, userPersonalContactsServiceV3, userServiceV3} from "services"
import {getFullName, handleError, toastError, validateEmail} from "helpers"
import {Auth} from "types/auth"
import {Icon} from "components/Icon"
import {PrimaryDescription} from "../Common"
import {SelectManualTable} from "./parts"
import styles from "./OthersSignerSelecting.module.css"
import {getFirstProfile} from "helpers/task"

function reducer(state, action) {
    return {...state, ...action}
}

export function OthersSignerSelecting(props) {
    const {
        signer,
        onCancelSelecting,
        onSaveSelecting,
        onUpdateSignerItem,
        primarySigner,
        userDataOnlySigner,
        onSaveOtherSelecting,
        taskType
    } = props
    const initialState = {
        roles: [],
        isShowTempStudent: false,
        personalContactRole: null,
        isSaving: false,
        isShowAction: true
    }
    const [{roles, personalContactRole, isSaving, isShowAction}, dispatch] = useReducer(reducer, initialState)
    const [isShowTempStudent, setIsShowTempStudent] = useState(false)
    const [tempStudents, setTempStudents] = useState([])
    const {t} = useTranslation(["tasks", "common"])
    const isPrimaryWithScanTask =
        signer.participantType === ParticipantType.PrimarySigner && taskType === TaskType.SCAN_UPLOAD

    useEffect(() => {
        dispatch({personalContactRole: signer.personalContactRole})
        getPersonalContactByRole()
    }, [])

    const getPersonalContactByRole = async () => {
        try {
            const {data} = await userPersonalContactRolesServiceV3.getAll()
            dispatch({roles: data})
        } catch (error) {
            handleError(error)
        }
    }

    const getOthersFromContactRole = async () => {
        try {
            dispatch({isSaving: true})
            let userIds = []
            let users = []
            if (primarySigner.userType === UserType.STUDENT) {
                users = (primarySigner.students || []).map((student) => ({
                    id: student.id,
                    profileId: student.profileId
                }))
                userIds = (primarySigner.students || []).map((student) => student.id).filter((studentId) => studentId)
            } else if (primarySigner.userType === UserType.STAFF) {
                users = (primarySigner.staffs || []).map((staff) => ({
                    id: staff.id,
                    profileId: staff.profileId
                }))
                userIds = (primarySigner.staffs || []).map((staff) => staff.id).filter((staffId) => staffId)
            } else if (userDataOnlySigner?.userType === UserType.STUDENT) {
                users = (userDataOnlySigner.students || []).map((student) => ({
                    id: student.id,
                    profileId: get(getFirstProfile(student), "id")
                }))
                userIds = (userDataOnlySigner.students || [])
                    .map((student) => student.id)
                    .filter((studentId) => studentId)
            } else if (isPrimaryWithScanTask && !isEmpty(tempStudents)) {
                users = (tempStudents || []).map((student) => {
                    const profile = getFirstProfile(student)
                    return {
                        id: student.id,
                        profileId: get(profile, "id")
                    }
                })
                userIds = (tempStudents || []).map((student) => student.id).filter((studentId) => studentId)
            }
            const userPersonalContactRoleIds = personalContactRole?.userPersonalContactRoleId
                ? [personalContactRole.userPersonalContactRoleId]
                : []
            const {data} = await userPersonalContactsServiceV3.getAll({
                filter: {
                    userIds,
                    userPersonalContactRoleIds,
                    isDefault: true
                },
                linkedEntities: true,
                range: {
                    page: 1,
                    pageSize: 1000
                }
            })
            const dataKeyByUserId = keyBy(data, "userId")
            const usersById = keyBy(users, "id")
            const result = userIds.map((userId) => {
                if (dataKeyByUserId[userId]) {
                    const contactProfile = dataKeyByUserId[userId].contactProfile || {}
                    return {
                        ...dataKeyByUserId[userId],
                        primaryUserId: userId,
                        primaryProfileId: get(usersById[userId], "profileId"),
                        firstName: contactProfile.firstName,
                        lastName: contactProfile.lastName,
                        email: contactProfile.email
                    }
                }
                return {
                    email: null,
                    firstName: null,
                    lastName: null,
                    primaryUserId: userId,
                    primaryProfileId: get(usersById[userId], "profileId")
                }
            })
            return result
        } catch (error) {
            handleError(error)
            return []
        } finally {
            dispatch({isSaving: true})
        }
    }

    const onChangeOthersContactRole = (newValue) => {
        dispatch({personalContactRole: newValue})
        setTempStudents([])
    }

    const onChangeSelectedType = (value) => {
        onUpdateSignerItem(signer.id, {...signer, selectedType: value})
    }

    const validateManualOthers = (signer) => {
        if (!signer.others || signer.others.length === 0) {
            toastError(t("common:validation.cantEmpty", {field: "Fields"}))
            return true
        }
        return (signer.others || []).some((other) => {
            if (other.contactProfile) {
                return false
            }
            if (!other.firstName) {
                toastError(t("common:validation.cantEmpty", {field: "First name"}))
                return true
            }
            if (!other.lastName) {
                toastError(t("common:validation.cantEmpty", {field: "Last name"}))
                return true
            }
            if (!other.email) {
                toastError(t("common:validation.cantEmpty", {field: "Email"}))
                return true
            }
            if (other.email && !validateEmail(other.email)) {
                toastError(t("validation.emailInvalidFormat"))
                return true
            }
            if (has(other, "userPersonalContactRole") && !other.userPersonalContactRole) {
                toastError(t("common:validation.hasToBeSelect", {field: "Role"}))
                return true
            }
            return false
        })
    }

    const onClickSave = async () => {
        if (isPrimaryWithScanTask && isEmpty(tempStudents)) {
            setIsShowTempStudent(true)
            setTempStudents(signer.tempStudents || [])
        } else if (
            signer.participantType === ParticipantType.PrimarySigner ||
            signer.selectedType === SelectOthersFrom.ROLE
        ) {
            if (!personalContactRole || !personalContactRole.userPersonalContactRoleId) {
                toastError(t("common:validation.hasToBeSelect", {field: "Relationship"}))
                return
            }
            let others = []
            if (primarySigner) {
                others = await getOthersFromContactRole()
            }
            onSaveSelecting({...signer, others, personalContactRole})
        } else {
            const hasError = validateManualOthers(signer)
            if (!hasError) {
                dispatch({isShowAction: false})
                onSaveOtherSelecting(signer.id)
            }
        }
    }

    const getRelationShipByPrimary = (userType) => {
        if (signer.participantType === ParticipantType.PrimarySigner) {
            return "Relationship to Student"
        }
        switch (userType) {
            case UserType.STUDENT:
                return "Relationship to Student"
            case UserType.STAFF:
                return "Relationship to Staff"
            case UserType.OTHERS:
                return "Relationship to Others"
            default:
                return "Relationship"
        }
    }

    const onChangeTempStudents = (students) => {
        setTempStudents(students)
    }

    const getStudents = async (search = "", loadedOptions) => {
        try {
            const params = {
                filter: {
                    type: [Auth.UserProfileType.Student],
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                },
                linkedObjects: true
            }
            const {data: students, total} = await userServiceV3.getAll(params)
            return {
                options: students,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onSaveTempStudent = async () => {
        if (!tempStudents || !tempStudents.length) {
            toastError("Please select at least 1 student")
            return
        }
        const others = await getOthersFromContactRole()
        onSaveSelecting({...signer, others, personalContactRole, tempStudents})
        setIsShowTempStudent(false)
    }

    const renderBody = () => {
        if (isPrimaryWithScanTask && isShowTempStudent) {
            const debounceUsers = debounce(getStudents, 800)
            return (
                <div>
                    <div className={styles.studentSelectingInfoWrap}>
                        <Icon icon="WARNING_TRIANGLE_FILL" color="#ff8a38" />
                        <div className={styles.studentSelectingInfoBody}>
                            <p className={styles.studentSelectingInfoTitle}>No Student defined</p>
                            <p className={styles.studentSelectingInfoDescription}>
                                Others are linked to students. Please define student signers first to be able to define
                                other signers
                            </p>
                        </div>
                    </div>
                    <div className={styles.studentSelectInfoExtraContent}>
                        <span>
                            If you only want to have others as a signer, you will need to define student related to
                            other person first
                        </span>
                    </div>
                    <div className={styles.studentSelectingInput}>
                        <FormLabel label="Select Student" />
                        <KlassDropAsyncPaginate
                            cacheOptions={false}
                            value={tempStudents}
                            onChange={onChangeTempStudents}
                            getOptionLabel={(option: any) => getFullName(option)}
                            loadOptions={debounceUsers}
                            isMulti
                            placeholder={t("departments:select")}
                        />
                    </div>
                    <div className={styles.studentSelectingAction}>
                        <BaseButton title="Save" onClick={onSaveTempStudent} />
                    </div>
                </div>
            )
        }
        if (signer.participantType === ParticipantType.PrimarySigner || signer.selectedType === SelectOthersFrom.ROLE) {
            return (
                <>
                    <FormLabel label={getRelationShipByPrimary(primarySigner?.userType)} />
                    <KlassDropdown
                        options={roles}
                        valueKey="userPersonalContactRoleId"
                        value={personalContactRole}
                        onChange={onChangeOthersContactRole}
                        placeholder="Relationship"
                    />
                    <div className={styles.actionBtnWrap}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            className={styles.cancelBtn}
                            onClick={() => onCancelSelecting(signer)}
                        />
                        <BaseButton title={t("common:action.save")} onClick={onClickSave} loading={isSaving} />
                    </div>
                </>
            )
        }
        return (
            <>
                <FormLabel label="Select Person" />
                <SelectManualTable
                    signer={signer}
                    primarySigner={primarySigner}
                    onUpdateSignerItem={onUpdateSignerItem}
                    isShowAction={isShowAction}
                    isSaving={isSaving}
                    onCancelSelecting={() => onCancelSelecting(signer)}
                    onClickSave={onClickSave}
                />
            </>
        )
    }

    return (
        <div>
            {signer.participantType !== ParticipantType.PrimarySigner && (
                <div className={styles.chooseTypeWrap}>
                    <Radio.Group
                        onChange={(event) => onChangeSelectedType(event.target.value)}
                        value={signer.selectedType}
                        className={styles.radioBtnWrap}>
                        <Radio
                            value={SelectOthersFrom.MANUAL}
                            className={cx(styles.radioBtn__item, {
                                [styles.radioBtn__active]: signer.selectedType === SelectOthersFrom.MANUAL
                            })}>
                            Enter Manually
                        </Radio>
                        <Radio
                            value={SelectOthersFrom.ROLE}
                            className={cx(styles.radioBtn__item, {
                                [styles.radioBtn__active]: signer.selectedType === SelectOthersFrom.ROLE
                            })}>
                            Select by relationship
                        </Radio>
                    </Radio.Group>
                </div>
            )}
            <div className={styles.primaryDescWrap}>
                <PrimaryDescription isPrimary={signer.participantType === ParticipantType.PrimarySigner} />
            </div>
            {renderBody()}
        </div>
    )
}
