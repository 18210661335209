import React, {useCallback, useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {BasePopup} from "components/popup"
import styles from "./CreateUserPopup.module.css"
import {useAllEnrollmentTypes, useModel, useAdvisors} from "hooks"
import {Auth} from "types/auth"
import {Col, Divider, Row} from "antd"
import {FormLabel} from "components/Form"
import {BaseButton, BaseInput} from "components"
import {useMutation} from "@tanstack/react-query"
import {formatDateOnly, getFieldLabel, handleError, PREFERRED_DAYS_OF_CONTACT_OPTIONS} from "helpers"
import {KlassDropdown} from "components/Select"
import {
    addressService,
    admissionService,
    majorService,
    majorVersionsService,
    profileService,
    studentStaffContactsServiceV3,
    userServiceV3
} from "services"
import {chain, pick, sortBy} from "lodash"
import moment from "moment-timezone"
import {Settings} from "types/settings"
import CampusSelect from "components/CampusSelect"
import {ENROLLMENT_STATUS_OPTIONS, EnrollmentStatus} from "types/students"
import TermSelect from "components/TermSelect"
import ProgramSelect from "components/ProgramSelect"
import {Major} from "types/major"
import {UserOptionLabel} from "components/UserSelect/UserSelect"
import {BaseDepartmentId} from "types/departments"

type ActivityPopupProps = {
    isShow: boolean
    onClose: () => void
    onDone: () => void
}

type UserData = Partial<Auth.UserProfile> &
    Partial<{
        email: string
        phone: string
        country: any
        state: any
        preferredDaysOfContact: any[]
        preferredTimeOfContact: any[]
        campuses: any[]
        admissionsLeadSourceId: number
        enrollmentStatus: EnrollmentStatus
        startingTerm: any
        currentMajor: any
        currentMajorVersionId: number
        advisors?: Auth.AdvisorsListItemDetail[]
    }>

function CreateUserPopup({isShow, onClose, onDone}: ActivityPopupProps) {
    const {t} = useTranslation(["user", "common"])
    const model = useModel()
    const [user, setUser] = useState<UserData>({
        timeZone: moment.tz.guess()
    })
    const [errors, setErrors] = useState<{[key in keyof UserData]: string}>({})
    const {enrollmentTypes} = useAllEnrollmentTypes()
    const {admissionAdvisors} = useAdvisors().data

    const updateUser = useCallback((values: {[key in keyof UserData]: any}) => {
        setUser((prev) => ({...prev, ...values}))
    }, [])

    const validateBeforeSubmit = useCallback(
        (user: UserData) => {
            const errors = {}

            const requiredFields = ["firstName", "lastName", "email", "campuses", "enrollmentType", "enrollmentStatus"]
            for (const field of requiredFields) {
                if (user[field] == null) {
                    errors[field] = t(`user.${field}Required`)
                }
            }

            return errors
        },
        [t]
    )

    const mutation = useMutation(async (user: UserData) => {
        const errors = validateBeforeSubmit(user)
        setErrors(errors)
        if (Object.keys(errors).length) {
            throw new Error(t("common:message.completeForm"))
        }

        const {
            data: [createdUser]
        } = await userServiceV3.create({
            ...pick(user, [
                "firstName",
                "lastName",
                "address",
                "city",
                "postalCode",
                "howDidYouKnowAboutUsId",
                "preferredContactMethod"
            ]),
            emails: [{email: user.email, isPrimary: true}],
            phones: user.phone ? [{phone: user.phone, isPrimary: true}] : [],
            preferredDaysOfContact: (user.preferredDaysOfContact as any[])?.map(({id}) => id),
            preferredTimeOfContact: (user.preferredTimeOfContact as any[])?.map(({id}) => id),
            state: user.state?.label || user.state,
            country: user.country?.label
        })

        const {
            data: [createdProfile]
        } = await profileService.create([
            {
                ...pick(user, [
                    "admissionsLeadSourceId",
                    "enrollmentType",
                    "enrollmentStatus",
                    "startDate",
                    "contractEndDate",
                    "projectedEndDate"
                ]),
                isDefault: true,
                userId: createdUser.id,
                type: Auth.UserProfileType.Student,
                state: Auth.UserProfileState.Prospect,
                customProfileId: await profileService.getNextCustomProfileId(),
                campuses: user.campuses?.map((campus) => pick(campus, ["id", "name"])),
                currentMajor: user.currentMajor?.id,
                currentMajorVersionId: user.currentMajorVersionId,
                startingTermId: user.startingTerm?.id
            }
        ])

        if (user.advisors?.length) {
            await studentStaffContactsServiceV3.create(
                user.advisors.map((advisor) => ({
                    departmentId: BaseDepartmentId.Admissions,
                    profileId: createdProfile.id,
                    contactProfileId: advisor.profileId,
                    isDefault: 0
                }))
            )
        }

        return createdProfile
    })

    useEffect(() => {
        if (mutation.isError) {
            handleError(mutation.error)
        }
    }, [mutation.isError, mutation.error])

    useEffect(() => {
        if (mutation.isSuccess) {
            onDone()
        }
    }, [mutation.isSuccess, onDone])

    const preferredTimeOfContactOptions = [
        {id: "morning", name: t("user.morning")},
        {id: "noon", name: t("user.noon")},
        {id: "evening", name: t("user.evening")}
    ]

    const preferredContactMethodOptions = useMemo(
        () =>
            Object.values(Auth.PreferredContactMethod).map((method) => ({
                id: method,
                name: t(`user.contactMethodLabel.${method}`)
            })),
        [t]
    )

    const [howDidYouKnowAboutUsOptions, setHowDidYouKnowAboutUsOptions] = useState([])
    const [countryOptions, setCountryOptions] = useState([])
    const [stateOptions, setStateOptions] = useState([])
    const [admissionLeadSources, setAdmissionLeadSources] = useState([])
    const [programVersions, setProgramVersions] = React.useState<Major.MajorVersion[]>([])

    useEffect(() => {
        ;(async function getAllHowDidYouKnowAbouUs() {
            try {
                const {data} = await admissionService.getAllHowDidYouKnowAbouUs({
                    range: {page: 1, pageSize: 100},
                    sort: {orderBy: "name", orderDir: "ASC"}
                })
                setHowDidYouKnowAboutUsOptions(data.map((item) => ({...item, id: item.howDidYouKnowAboutUsId})))
            } catch (e) {
                handleError(e)
            }
        })()
        ;(async function getCountries() {
            try {
                const {data} = await addressService.getCountries({})
                const countries = data
                    .filter((country) => !!country.name)
                    .map((country) => ({value: country.countryId, label: country.name}))
                setCountryOptions(countries)
                updateUser({country: countries.find((country) => country.label === "United States")})
            } catch (error) {
                console.error(error)
            }
        })()
        ;(async function getLeadSources() {
            try {
                const {data} = await admissionService.getAllLeadSources()
                setAdmissionLeadSources(data)
            } catch (error) {
                console.error(error)
            }
        })()
    }, [updateUser])

    useEffect(() => {
        async function searchStates(countryId?: number, search?: string) {
            try {
                const response = await addressService.getStates({filter: {search, countryId}})
                const states = chain(response.data)
                    .groupBy("type")
                    .map((value, key) => ({
                        label: key,
                        options: sortBy(value, ["name"]).map((state) => ({value: state.stateId, label: state.name}))
                    }))
                    .value()
                setStateOptions(states)
            } catch (error) {
                setStateOptions([])
                console.error(error)
            }
        }

        if (user.country === "United States") {
            searchStates(undefined, user.country)
        } else if (user.country?.label === "United States") {
            searchStates(user.country?.value)
        } else {
            updateUser({state: ""})
        }
    }, [updateUser, user.country])

    const selectedCampusIds: number[] | undefined = useMemo(() => {
        const ids = (user.campuses ?? []).map((campus) => campus.id)
        if (!ids.length) return undefined
        return ids
    }, [user.campuses])

    useEffect(() => {
        if (!user.currentMajor?.id) return
        ;(async function getMajorVersionOptions() {
            try {
                const params = {
                    filter: {
                        majorIds: [user.currentMajor?.id],
                        campusIds: selectedCampusIds
                    }
                }
                const {data} = await majorVersionsService.getAll(params)
                setProgramVersions(data)
                if (!data.find(({id}) => id === user.currentMajorVersionId)) {
                    updateUser({currentMajorVersionId: null})
                }
            } catch (error) {
                console.error(error)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.currentMajor, selectedCampusIds])

    useEffect(() => {
        if (!user.startingTerm?.id) return
        ;(async function checkTermMajors() {
            try {
                const {data: programsOfTerm} = await majorService.getMajorByTerm([user.startingTerm.id])
                const isCurrentMajorExist =
                    !!user.currentMajor?.id && programsOfTerm.some(({id}) => id === user.currentMajor.id)
                const programByTerm = programsOfTerm.find(({id}) => id === user.currentMajor.id)

                const newUserData: Partial<UserData> = {}

                if (!!programByTerm?.startDate) {
                    newUserData.startDate = formatDateOnly(programByTerm.startDate, "YYYY-MM-DD")
                }
                if (!!programByTerm?.projectedEndDate) {
                    newUserData.contractEndDate = formatDateOnly(programByTerm.projectedEndDate, "YYYY-MM-DD")
                    newUserData.projectedEndDate = formatDateOnly(programByTerm.projectedEndDate, "YYYY-MM-DD")
                }
                if (!isCurrentMajorExist) {
                    newUserData.currentMajor = null
                    newUserData.currentMajorVersionId = null
                }
                updateUser(newUserData)
            } catch (error) {
                console.error(error)
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.startingTerm])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="USER" leftIconColor="#fff" width="70vw">
            <div className={styles.container}>
                <h3 className={styles.title}>{t("user.createNewUser")}</h3>

                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <FormLabel label={t("user.firstName")} isRequired />
                        <BaseInput
                            onChange={(firstName) => updateUser({firstName})}
                            placeholder={t("user.firstName")}
                            value={user.firstName}
                            error={!!errors.firstName}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.lastName")} isRequired />
                        <BaseInput
                            onChange={(lastName) => updateUser({lastName})}
                            placeholder={t("user.lastName")}
                            value={user.lastName}
                            error={!!errors.lastName}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.email")} isRequired />
                        <BaseInput
                            onChange={(email) => updateUser({email})}
                            placeholder={t("user.email")}
                            value={user.email}
                            error={!!errors.email}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.phone")} />
                        <BaseInput
                            onChange={(phone) => updateUser({phone})}
                            placeholder={t("user.phone")}
                            value={user.phone}
                            error={!!errors.phone}
                        />
                    </Col>

                    <Col span={12}>
                        <FormLabel label={t("user.enrollmentType")} isRequired />
                        <KlassDropdown
                            onChange={(option) => updateUser({enrollmentType: option?.id ?? null})}
                            options={enrollmentTypes || []}
                            value={(enrollmentTypes || []).find((option) => option.code === user.enrollmentType)}
                            error={!!errors.enrollmentType}
                            isClearable
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel
                            label={getFieldLabel(
                                model,
                                Settings.GeneralLabel.EnrollmentStatus,
                                t("user.enrollmentStatus")
                            )}
                            isRequired
                        />
                        <KlassDropdown
                            onChange={(option) => updateUser({enrollmentStatus: option?.id ?? null})}
                            options={ENROLLMENT_STATUS_OPTIONS}
                            value={ENROLLMENT_STATUS_OPTIONS.find((option) => option.id === user.enrollmentStatus)}
                            error={!!errors.enrollmentStatus}
                            isClearable
                        />
                    </Col>

                    <Col span={12}>
                        <FormLabel
                            label={getFieldLabel(model, Settings.GeneralLabel.Campuses, t("user.campuses"))}
                            isRequired
                        />
                        <CampusSelect
                            isMulti
                            onChange={(options) => updateUser({campuses: options || []})}
                            value={user.campuses}
                            error={!!errors.campuses}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.leadSource")} />
                        <KlassDropdown
                            onChange={(option) =>
                                updateUser({admissionsLeadSourceId: option?.admissionsLeadSourceId ?? null})
                            }
                            options={admissionLeadSources}
                            labelKey="name"
                            valueKey="admissionsLeadSourceId"
                            value={admissionLeadSources.find(
                                (option) => option.admissionsLeadSourceId === user.admissionsLeadSourceId
                            )}
                            isClearable
                        />
                    </Col>

                    <Col span={12}>
                        <FormLabel label={t("user.currentMajor")} />
                        <ProgramSelect
                            isActive
                            campusId={selectedCampusIds}
                            termIds={user.startingTerm?.id ? [user.startingTerm.id] : undefined}
                            onChange={(currentMajor) => updateUser({currentMajor})}
                            value={user.currentMajor}
                            error={!!errors.currentMajor}
                            isClearable
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel
                            label={getFieldLabel(model, Settings.GeneralLabel.ProgramVersion, t("user.majorVersion"))}
                        />
                        <KlassDropdown
                            isClearable
                            error={!!errors.currentMajorVersionId}
                            options={programVersions}
                            labelKey="version_name"
                            valueKey="id"
                            getOptionLabel={(option: any) =>
                                option.version_code
                                    ? ` ${option.version_name} (${option.version_code})`
                                    : option.version_name
                            }
                            value={programVersions.find(({id}) => id === user.currentMajorVersionId)}
                            onChange={(option) => updateUser({currentMajorVersionId: option?.id})}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.startTerm")} />
                        <TermSelect
                            majorId={user.currentMajor?.id}
                            majorVersionId={user.currentMajorVersionId}
                            campusIds={selectedCampusIds}
                            value={user.startingTerm}
                            onChange={(startingTerm) => updateUser({startingTerm})}
                            error={!!errors.startingTerm}
                            isClearable
                        />
                    </Col>

                    <Col span={12}>
                        <FormLabel label={t("user.admissionsAdvisors")} />
                        <KlassDropdown
                            isMulti
                            options={admissionAdvisors}
                            valueKey="profileId"
                            labelKey="fullName"
                            getOptionLabel={UserOptionLabel}
                            value={user.advisors}
                            placeholder={t("common:selectField.placeholder")}
                            onChange={(advisors) => updateUser({advisors})}
                        />
                    </Col>
                </Row>

                <Divider />

                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <FormLabel label={t("user.country")} />
                        <KlassDropdown
                            value={user.country}
                            placeholder={t("common:selectField.placeholder")}
                            onChange={(option) => updateUser({country: option})}
                            valueKey="value"
                            labelKey="label"
                            options={countryOptions}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.state")} />
                        {user.country?.label === "United States" || user.country === "United States" ? (
                            <KlassDropdown
                                value={user.state}
                                placeholder={t("common:selectField.placeholder")}
                                onChange={(option) => updateUser({state: option})}
                                options={stateOptions}
                                valueKey="value"
                                labelKey="label"
                                isGrouped
                            />
                        ) : (
                            <BaseInput
                                onChange={(state) => updateUser({state})}
                                placeholder={t("user.state")}
                                value={user.state}
                            />
                        )}
                    </Col>
                    <Col span={24}>
                        <FormLabel label={t("user.streetAddress")} />
                        <BaseInput
                            onChange={(address) => updateUser({address})}
                            placeholder={t("user.streetAddress")}
                            value={user.address}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.city")} />
                        <BaseInput
                            onChange={(city) => updateUser({city})}
                            placeholder={t("user.city")}
                            value={user.city}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.postalCode")} />
                        <BaseInput
                            onChange={(postalCode) => updateUser({postalCode})}
                            placeholder={t("user.postalCode")}
                            value={user.postalCode}
                        />
                    </Col>
                </Row>

                <Divider />

                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <FormLabel label={t("user.preferredTimeOfContact")} />
                        <KlassDropdown
                            onChange={(options) => updateUser({preferredTimeOfContact: options || []})}
                            options={preferredTimeOfContactOptions}
                            value={user.preferredTimeOfContact}
                            placeholder={t("common:selectField.placeholder")}
                            isMulti
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.preferredDayOfContact")} />
                        <KlassDropdown
                            onChange={(options) => updateUser({preferredDaysOfContact: options || []})}
                            options={PREFERRED_DAYS_OF_CONTACT_OPTIONS}
                            value={user.preferredDaysOfContact}
                            placeholder={t("common:selectField.placeholder")}
                            isMulti
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.preferredContactMethod")} />
                        <KlassDropdown
                            onChange={(option) => updateUser({preferredContactMethod: option?.id ?? null})}
                            options={preferredContactMethodOptions}
                            value={preferredContactMethodOptions.find(
                                (option) => option.id === user.preferredContactMethod
                            )}
                            placeholder={t("common:selectField.placeholder")}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label={t("user.howDidYouKnow")} />
                        <KlassDropdown
                            onChange={(option) => updateUser({howDidYouKnowAboutUsId: option?.id ?? null})}
                            options={howDidYouKnowAboutUsOptions}
                            value={howDidYouKnowAboutUsOptions.find(
                                (option) => option.id === user.howDidYouKnowAboutUsId
                            )}
                            placeholder={t("common:selectField.placeholder")}
                        />
                    </Col>
                </Row>

                <Divider />

                <Row gutter={24} justify="end">
                    <Col>
                        <BaseButton title={t("common:action.cancel")} onClick={onClose} variant="secondary" />
                    </Col>
                    <Col>
                        <BaseButton
                            title={t("common:action.save")}
                            onClick={() => mutation.mutate(user)}
                            loading={mutation.isLoading}
                        />
                    </Col>
                </Row>
            </div>
        </BasePopup>
    )
}

export default CreateUserPopup
