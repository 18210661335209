import React, {useReducer, useEffect} from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import {Icon} from "components/Icon"
import styles from "./StatsHeaderInfo.module.css"
import {statsService} from "services"
import moment from "moment"
import {handleError} from "helpers"

function reducer(state, action) {
    return {...state, ...action}
}

const INITIAL_STATE = {
    nextAdmissionDays: 0,
    startDate: "",
    enrolledStudents: 0,
    target: 0 //TODO: TBD
}

export function StatsHeaderInfo(props) {
    const [currentState, dispatch] = useReducer(reducer, INITIAL_STATE)
    const {t} = useTranslation(["common"])

    async function getAdmissionInfo() {
        try {
            const [nextTermInfo] = await statsService.getDepartmentStatsAdmissions({
                filter: {
                    registrationStart: moment().format("YYYY-MM-DD").toString()
                },
                sort: {
                    orderBy: "startDate",
                    orderDir: "asc"
                },
                range: {
                    page: 1,
                    pageSize: 1
                }
            })
            if (nextTermInfo) {
                const todayDayOfYear = moment()
                const startDateDayOfYear = moment.utc(nextTermInfo.startDate)
                const nextAdmissionDays = startDateDayOfYear.diff(todayDayOfYear, "days")
                dispatch({
                    nextAdmissionDays: nextAdmissionDays >= 0 ? nextAdmissionDays : 0,
                    startDate: moment.utc(nextTermInfo.startDate).format("MMMM DD, YYYY")
                })
            }
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        getAdmissionInfo()
    }, [])

    return (
        <div className={styles.headerInfoWrap}>
            <div className={styles.headerInfoLeft}>
                <div className={styles.headerInfoLeftItem}>
                    <p className={styles.headerInfoLeft__title}>{t("staffHeader.nextAdmissionIn")}</p>
                    <p className={styles.headerInfoLeft__day}>
                        {currentState.nextAdmissionDays === 0
                            ? t("staffHeader.noUpcomingDates")
                            : t("staffHeader.nDays", {count: currentState.nextAdmissionDays})}
                    </p>
                </div>
                <div className={styles.headerInfoLeftItem}>
                    <p className={styles.headerInfoLeft__title}>{t("staffHeader.starts")}</p>
                    <div className={styles.headerInfoLeft__dateValueWrap}>
                        <p className={styles.headerInfoLeft__dateValue}>{currentState.startDate}</p>
                        <div className={styles.headerInfoLeft__dateIconWrap}>
                            <Icon icon="CALENDAR_FILL_INPUT" className={styles.headerInfoLeft__dateIcon} color="#fff" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.headerInfoRightItem}>
                <p className={styles.headerInfoRightItem__title}>{t("staffHeader.enroll")}</p>
                <div className={styles.headerInfoRightItemValueWrap}>
                    <Icon icon="POINTS_BIG" className={styles.headerInfoRightItem__icon} />
                    <p className={styles.headerInfoRightItem__value}>{currentState.enrolledStudents}</p>
                </div>
            </div>
            <div className={cx(styles.headerInfoRightItem, styles.headerInfoRightItemTarget)}>
                <p className={styles.headerInfoRightItem__title}>{t("staffHeader.target")}</p>
                <p className={styles.headerInfoRightItem__value}>{currentState.target}</p>
            </div>
        </div>
    )
}
