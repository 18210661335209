/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {isEmpty} from "lodash"
import {useLocation} from "react-router-dom"
import cx from "classnames"
import {KlassappTableProps} from "types/common"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {userServiceV3, noticeService} from "services"
import styles from "./FinAidNoticeTable.module.css"
import {getUserPhotoUrl, handleError, handleErrorMessage} from "helpers"
import {FinancialAidStudentTabs} from "../../../FinancialAidStudent/FinancialAidStudent"
import {NoticeTypes} from "../FinAidNoticeDetail"
import {routing} from "helpers/routing"

type PageProps = {
    history: any
    filterObject
    noticeDetails
}

type Props = KlassappTableProps & PageProps

function FinAidNoticeDetailTable(props: Props) {
    const {history} = props
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const noticeId: number = parseInt(query.get("id"))

    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        isShowTableHeaderAction,
        noticeDetails,
        filterObject
    } = props

    const getColumns = () => {
        const format = "MM/DD/YYYY"

        return [
            {
                title: "Student",
                field: "studentHtml",
                style: {minWidth: "300px"}
            },
            {
                title: "ID",
                field: "code",
                style: {minWidth: "120px"}
            },
            {
                title: "Start Date",
                field: "startDate",
                fieldType: "date",
                format,
                style: {minWidth: "200px"}
            },
            {
                title: "Degree",
                field: "degree",
                style: {minWidth: "200px"}
            },
            {
                title: "Program",
                field: "program",
                style: {minWidth: "200px"}
            },
            {
                title: "Status",
                field: "statusHtml",
                style: {minWidth: "150px"}
            }
        ]
    }

    const getFields = () => {
        return ["Student", "ID", "Start Date", "Degree", "Program", "Status"]
    }

    const getPageTitle = () => {
        return "Notice"
    }

    useEffect(() => {
        if (noticeDetails) {
            getData()
        }
    }, [noticeDetails])

    const getParams = () => {
        const {page, pageSize, orderField} = props
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {
                ...filterObject,
                noticeId
            },
            linkedObjects: true
        }
        if (!isEmpty(orderField)) {
            params.orderBy = orderField.field
            params.orderDir = orderField.order
        }
        return params
    }

    const getData = async () => {
        const params = getParams()
        dispatch({isLoading: true})
        try {
            // fetch notice student list
            const {data, total} = await noticeService.getStudentsList(params)
            if (!data) {
                handleErrorMessage("No notice student found")
                return false
            }

            let newData: any = []

            if (noticeDetails.type === NoticeTypes.ISIR) {
                newData = data.map((item) => {
                    let newData: any = {
                        id: item.id,
                        studentName: item.studentName,
                        studentEmail: item.studentEmail,
                        studentHtml: renderStudentHtml(item),
                        code: "",
                        startDate: item.createdAt,
                        statusHtml: renderStatusHtml(noticeDetails.isRead),
                        degree: "",
                        program: "",
                        studentId: noticeDetails.code.substring(1)
                    }

                    return newData
                })
            } else {
                newData = data.map((item) => {
                    let newData: any = {
                        id: item.id,
                        studentName: item.profile.fullName,
                        studentEmail: item.profile.email,
                        studentHtml: renderStudentHtml({...item.profile, studentName: item.profile.fullName}),
                        code: item.profileId,
                        startDate: item.createdAt,
                        statusHtml: renderStatusHtml(noticeDetails.isRead),
                        degree: item.profile.degreeLevel ? item.profile.degreeLevel.name : "",
                        program: item.profile.currentMajorDetails ? item.profile.currentMajorDetails.name : "",
                        studentId: item.profile.id
                    }

                    return newData
                })
            }

            dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const renderStudentHtml = (data) => {
        const studentImage =
            NoticeTypes.ISIR === noticeDetails.type ? data.studentImage : getUserPhotoUrl(data.photo, 32)
        return (
            <div className={styles.fullnameWrap}>
                <img src={studentImage || "/image/DefaultAvatar.png"} alt="" className={styles.avatar} />
                <p className={styles.fullname}>{data.studentName}</p>
            </div>
        )
    }

    const renderStatusHtml = (status: boolean) => {
        const statusString: string = status ? "read" : "unread"
        return (
            <div
                className={cx(
                    styles.status,
                    `${statusString === "read" ? styles.status__attending : styles.status__unread}`
                )}>
                {statusString}
            </div>
        )
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        if (noticeDetails) {
            getData()
        }
    }, [page, pageSize])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const viewDetails = async (inputData) => {
        const {studentEmail, studentName, studentId} = inputData

        const fullName: string[] = studentName.split(" ")

        let profileId: number | null = null

        const {data} = await userServiceV3.getAll({
            filter: {
                emails: [studentEmail]
            },
            range: {
                limit: 1,
                offset: 0
            },
            linkedObjects: true
        })

        if (data.length > 0) {
            if (data[0].profiles !== null) {
                if (data[0].profiles.length === 1) {
                    profileId = data[0].profiles[0].id
                } else {
                    for (let i = 0; i < data[0].profiles.length; i++) {
                        if (data[0].profiles[i].active === 1) {
                            profileId = data[0].profiles[i].id
                        }
                    }
                }
            }
        }

        history.push({
            pathname: routing.finAid.student.isirDetail(profileId),
            search: `?tab=${FinancialAidStudentTabs.ISIR}&isirCode=${noticeDetails.code}&profileId=${profileId}`,
            state: {
                backUrl: `/financial-aid/staff/notice/detail?id=${noticeId}`
            }
        })
    }

    const setDefaultAction = {
        title: "View",
        icon: "ISIR",
        action: viewDetails
    }

    return (
        <div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                actions={tableHeaderActions}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={noticeDetails && noticeDetails.type === NoticeTypes.ISIR ? [setDefaultAction] : []}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn={false}
                onClickRowItem={() => {}}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(FinAidNoticeDetailTable)
