import React, {useRef, useState} from "react"
import {BasePopup} from "components/popup"
import styles from "./OfferApplicationForm.module.css"
import {BaseButton, BaseTextArea} from "components"
import {handleError, toastError} from "helpers"
import {ReactComponent as FileIcon} from "./assets/file.svg"
import {ReactComponent as RemoveIcon} from "./assets/remove.svg"
import {FormLabel} from "components/Form"
import {getPreSignedUrl, uploadUsingPreSignedUrl} from "helpers/chat"

type Props = {
    open: boolean
    onClose: () => void
    onSuccess?: (comment: string, attachment: {attachmentFileName: string; attachmentUrl: string}) => void
}

type LocalFile = {
    url: string
    file?: any
}

export function OfferApplicationForm(props: Props) {
    const {open, onClose, onSuccess} = props
    const [comment, setComment] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const fileRef = useRef<any>()
    const [attachment, setAttachment] = useState<LocalFile>()

    const onSubmit = async () => {
        try {
            if (!attachment || !attachment.file) {
                toastError("Please attach offer file")
            }

            if (comment && comment.trim() === "") {
                toastError("Please drop a message to student")
                return
            }

            setIsSubmitting(true)

            const fileExtension = attachment.file.name.split(".").pop()
            const {url, urlForUploading} = await getPreSignedUrl(fileExtension)
            await uploadUsingPreSignedUrl(urlForUploading, attachment.file)

            if (onSuccess)
                onSuccess(comment, {
                    attachmentFileName: attachment.file.name,
                    attachmentUrl: url
                })
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onOpenChooseFile = () => {
        if (isSubmitting) {
            return
        }
        fileRef.current.click()
    }

    const onClickUpload = (e) => {
        e.target.value = ""
    }

    const onChangeFile = async (e) => {
        if (isSubmitting) {
            return
        }
        const files = e.target.files
        if (files.length > 0) {
            const file = files[0]
            setAttachment({
                url: URL.createObjectURL(file),
                file: file
            })
        }
    }

    const onClickRemoveDocument = async () => {
        setAttachment(undefined)
    }

    const renderAttachment = () => {
        if (attachment)
            return (
                <div className={styles.attachmentsWrap}>
                    <div className={styles.documentWrap}>
                        <FileIcon className={styles.fileIcon} width={24} height={24} />
                        <div className={styles.documentFileName}>{attachment.file.name}</div>
                        <RemoveIcon
                            className={styles.removeIcon}
                            width={24}
                            height={24}
                            onClick={() => onClickRemoveDocument()}
                        />
                    </div>
                </div>
            )
    }

    return (
        <BasePopup
            isShow={open}
            onClose={onClose}
            leftIcon="SEND"
            leftIconColor="#fff"
            width="40vw"
            closable
            destroyOnClose>
            <div className={styles.headingTitle}>Send job offer</div>
            <div>
                <div className={styles.uploadWrap}>
                    <input
                        ref={fileRef}
                        style={{display: "none"}}
                        type="file"
                        multiple
                        onChange={onChangeFile}
                        onClick={onClickUpload}
                        accept="application/pdf"
                    />
                    <BaseButton variant="secondary" title="Attach offer" onClick={onOpenChooseFile} />
                </div>
                {renderAttachment()}
            </div>
            <div className={styles.field}>
                <FormLabel label="Message to student" className={styles.fieldLabel} />
                <BaseTextArea
                    className={styles.noteInput}
                    rows={5}
                    placeholder="type"
                    onChange={setComment}
                    value={comment}
                />
            </div>
            <div className={styles.doneBtn}>
                <BaseButton title="Send Offer" onClick={onSubmit} className={styles.saveBtn} loading={isSubmitting} />
            </div>
        </BasePopup>
    )
}
