import {useQuery} from "@tanstack/react-query"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {handleError} from "helpers"
import {academicGradingSystem} from "services"
import {GradingSystemItem} from "types/gradingSystem"

type UseGradingTemplatesParams = {
    gradingTemplateIds: number[]
}
export const useGradingTemplates = ({gradingTemplateIds}: UseGradingTemplatesParams) => {
    const gradingTemplates = useQuery<GradingSystemItem[]>(
        ["grading-templates", gradingTemplateIds],
        async () => {
            const {data} = await academicGradingSystem.getListGradingSystem({
                filter: {gradingTemplateIds},
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                }
            })
            return data.sort((a, b) => a.id - b.id)
        },
        {
            onError: (err) => {
                handleError(err)
            },
            enabled: !!gradingTemplateIds.length,
            initialData: []
        }
    )
    return {
        data: gradingTemplates.data,
        isLoading: gradingTemplates.isFetching
    }
}
