import {useQuery} from "@tanstack/react-query"
import {studentStaffContactRolesServiceV3} from "services"
import {ContactRole} from "types/user"

const INITIAL_DATA: ContactRole[] = []

export const useStudentStaffContactRoles = () => {
    const query = useQuery(
        ["all-student-staff-contact-roles"],
        async () => {
            const {data: roles} = await studentStaffContactRolesServiceV3.getAll()
            return roles.map((role) => ({...role, id: role.studentStaffContactRoleId})) as ContactRole[]
        },
        {
            refetchOnMount: false
        }
    )
    return {staffContactRoles: query.data ?? INITIAL_DATA}
}
