import React, {useEffect, useState} from "react"
import {BaseLoading} from "components"
import {Alert, Button} from "antd"
import styles from "./App.module.css"

type GlobalErrorBoundaryProps = {
    error: Error
    eventId?: string
}

export function GlobalErrorBoundary({error, eventId}: GlobalErrorBoundaryProps) {
    const [isDetailOpen, setIsDetailOpen] = useState(false)

    const isChunkLoadingFailedError = error?.message && /Loading (CSS )?chunk [\d]+ failed/.test(error.message)

    useEffect(() => {
        if (isChunkLoadingFailedError) {
            window.location.reload()
        }
    }, [isChunkLoadingFailedError])

    if (isChunkLoadingFailedError) {
        return <BaseLoading isShow />
    }

    const hasErrorMessage = !!error?.message

    return (
        <div className={styles.errorBoundary}>
            <Alert
                className={styles.errorBoundaryAlert}
                message="Oops! Something is not right."
                showIcon
                description={
                    <div>
                        <p>
                            If this page does not automatically refresh within 30 seconds, please manually reload it in
                            your browser.
                            <br />
                            If the problem continues to happen please email:{" "}
                            <a
                                href={`mailto:support@klassapp.com?subject=Error%20Message&body=Hello%20klassapp,%20I%20got%20this%20error.%20${error?.message}`}>
                                support@klassapp.com
                            </a>
                        </p>
                        {hasErrorMessage && isDetailOpen && (
                            <p>
                                {error.message} {eventId ? `(Event ID: ${eventId})` : ""}
                            </p>
                        )}
                    </div>
                }
                type="error"
                action={
                    hasErrorMessage ? (
                        <Button size="small" danger onClick={() => setIsDetailOpen((it) => !it)}>
                            {isDetailOpen ? "Hide detail" : "Show detail"}
                        </Button>
                    ) : undefined
                }
            />
        </div>
    )
}
