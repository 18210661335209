import React, {MutableRefObject, useRef} from "react"
import {isMobile} from "react-device-detect"
import cx from "classnames"
import {Icon} from "components/Icon"
import {BaseInput, BaseTextArea} from "components/inputs"
import {BaseDatePicker} from "components/DateTimePicker"
import styles from "./item.module.css"
import moment from "moment"
import {get, isString} from "lodash"
import {RemarkIndicator} from "./RemarkIndicator"

type Props = {
    field: any
    canSign: boolean
    errorFieldIds: string[]
    fieldRefs: MutableRefObject<Record<string, HTMLDivElement>>
    onChangeField: (fieldId: string, data: any) => void
}

export function Text(props: Props) {
    const {field, canSign, errorFieldIds, fieldRefs, onChangeField} = props
    const {top, left, width, height} = field.placeInformation
    const {isYourField} = field
    const textRef = useRef(null)
    let fontSize = Math.min(Math.floor(height / 1.7), 14)
    if (isMobile) {
        const minFontsize = height <= 14 ? 7 : 9
        fontSize = Math.min(Math.floor(height / 1.7), minFontsize)
    }

    const onFillInput = () => {
        if (!canSign || !isYourField) {
            return
        }
        onChangeField(field.id, {isSigned: true, value: field.defaultData})
        setTimeout(() => {
            const inputEl = get(textRef.current, "firstChild.firstChild.firstChild")
            if (inputEl) {
                inputEl.focus()
            }
        }, 0)
    }

    const onChangeValue = (newValue) => {
        onChangeField(field.id, {value: newValue})
    }

    const renderInputByType = () => {
        if (field?.readOnly) {
            return <div className={styles.studentDataValue}>{field.value}</div>
        }
        switch (field.dataType) {
            case "date":
                return (
                    <BaseDatePicker
                        value={field.value ? moment(field.value) : null}
                        className={styles.input}
                        onChange={onChangeValue}
                    />
                )
            case "numeric":
                return (
                    <BaseInput
                        type="number"
                        min={0}
                        value={field.value}
                        className={styles.input}
                        onChange={onChangeValue}
                    />
                )
            case "social_security_number":
                return <BaseInput value={field.value} className={styles.input} onChange={onChangeValue} />
            default:
                const inputHeight = Math.min(height, isMobile ? 20 : 44)
                const maxRows = Math.floor(height / inputHeight)
                if (maxRows >= 2) {
                    return (
                        <BaseTextArea
                            value={field.value}
                            className={cx(styles.input, styles.textarea)}
                            classNameWrap={styles.textAreaWrap}
                            onChange={onChangeValue}
                        />
                    )
                }
                return <BaseInput value={field.value} className={cx(styles.input)} onChange={onChangeValue} />
        }
    }

    const renderField = () => {
        if (!field.isSigned) {
            return (
                <div
                    ref={(ref) => (fieldRefs.current[field.id] = ref)}
                    className={cx(styles.actionItem, {
                        [styles.actionItemRequired]: isYourField && field.required,
                        [styles.hasComment]: !!field.remarkComment,
                        [styles.actionItem_error]: errorFieldIds.includes(field.id)
                    })}
                    style={{width, height}}
                    onClick={onFillInput}>
                    <div className={styles.textWrap}>
                        <p className={styles.label}>{field.label}</p>
                        <Icon icon="TEXT" className={styles.icon} />
                    </div>
                </div>
            )
        }
        return (
            <div
                ref={(ref) => (fieldRefs.current[field.id] = ref)}
                className={cx(styles.actionItem, {
                    [styles.actionItemRequired]: field.isYourField && field.required,
                    [styles.hasComment]: !!field.remarkComment,
                    [styles.actionItem_error]: errorFieldIds.includes(field.id)
                })}
                style={{width, height}}>
                <div className={cx(styles.inputWrap)} style={{fontSize}}>
                    {renderInputByType()}
                </div>
            </div>
        )
    }

    const renderBody = () => {
        if (!canSign || !isYourField) {
            return (
                <div
                    className={cx(styles.actionItem, styles.answerText)}
                    style={{
                        width,
                        height,
                        backgroundColor: `var(--${field.backgroundColor}-100)`,
                        borderColor: `var(--${field.backgroundColor}-400-base)`
                    }}>
                    <div
                        className={cx(styles.inputWrap, {
                            [styles.smallInput]: height <= 30,
                            [styles.superSmallInput]: height <= 14
                        })}>
                        {field.value}
                    </div>
                </div>
            )
        }
        return renderField()
    }

    return (
        <>
            <div className={styles.wrap} style={{left, top}} ref={textRef}>
                {renderBody()}
            </div>
            {field.remarkComment && (
                <div className={styles.wrap} style={{left, top, zIndex: 9}}>
                    <RemarkIndicator field={field} />
                </div>
            )}
        </>
    )
}
