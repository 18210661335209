/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Row, Col} from "antd"
import cx from "classnames"
import {studentService} from "services"
import styles from "./StatsChecklist.module.css"
import {handleError} from "helpers"
import {BaseButton} from "components/buttons"
import {useHistory} from "react-router-dom"
import {BaseDepartmentId} from "types/departments"
import {BaseNewFilter} from "uiKit"
import {FilterKey} from "types/filter"
import {useAdvisors, useFilterStorage} from "hooks"
import CampusSelect from "components/CampusSelect"
import TermSelect from "components/TermSelect"
import ProgramSelect from "components/ProgramSelect"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import {KlassDropdown} from "components/Select"
import {UserOptionLabel} from "components/UserSelect/UserSelect"
import {BaseLoading} from "components"

export enum StatsChecklistTitle {
    financialAidChecklist = "financialAidChecklist",
    admissionChecklist = "admissionChecklist",
    studentServiceChecklist = "studentServiceChecklist"
}

const BackUrlFromModule = {
    [BaseDepartmentId.Academics]: "academics/registrar/staff?tab=workspace",
    [BaseDepartmentId.Admissions]: "admissions/staff?tab=1"
}

type StatsChecklistProps = {
    departmentId: number
    title: StatsChecklistTitle
}

export function StatsChecklist(props: StatsChecklistProps) {
    const {departmentId} = props
    const history = useHistory()
    const [summary, setSummary] = useState({
        completion: 0,
        pendingItems: 0,
        pendingStudents: 0,
        totalStudents: 0
    })

    const {t} = useTranslation(["common"])
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        FilterKey.StatsChecklist,
        {campuses: [], terms: [], programs: [], statuses: [], admAdvisors: [], faAdvisors: [], acadAdvisors: []}
    )
    const {admissionAdvisors, finAidAdvisors, academicAdvisors} = useAdvisors().data
    const dataSeq = React.useRef(0)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        getOverallSummary()
    }, [])

    const getFilter = () => {
        const {campuses, programs, terms, statuses, admAdvisors, faAdvisors, acadAdvisors} = currentFilter
        let filter: any = {}
        if (terms) {
            filter.termsIds = terms.map(({id}) => id)
        }
        if (campuses) {
            filter.campusIds = campuses.map(({id}) => id)
        }
        if (programs) {
            filter.programIds = programs.map(({id}) => id)
        }
        if (statuses) {
            filter.statusIds = statuses.map(({statusId}) => statusId)
        }
        if (admAdvisors && departmentId === BaseDepartmentId.Admissions) {
            filter.advisorProfileIds = (admAdvisors || []).map((item) => item?.profileId).filter((item) => item)
        }
        if (faAdvisors && departmentId === BaseDepartmentId.FinancialAid) {
            filter.advisorProfileIds = (faAdvisors || []).map((item) => item?.profileId).filter((item) => item)
        }
        if (acadAdvisors && ![BaseDepartmentId.Admissions, BaseDepartmentId.FinancialAid].includes(departmentId)) {
            filter.advisorProfileIds = (acadAdvisors || []).map((item) => item?.profileId).filter((item) => item)
        }

        return filter
    }

    const getOverallSummary = async () => {
        try {
            dataSeq.current += 1
            const currentSeq = dataSeq.current
            setLoading(true)
            const filter = getFilter()
            const summary = await studentService.getStudentChecklistListOverallSummary({
                departmentId: props.departmentId,
                ...filter
            })
            if (dataSeq.current !== currentSeq) return
            setSummary(summary)
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false)
        }
    }

    const redirectToChecklistReport = () => {
        history.push(`/scrub-reports?departmentId=${props.departmentId}`, {
            backUrl: BackUrlFromModule[props.departmentId]
        })
    }

    const onApplyFilter = () => {
        applyFilter()
        getOverallSummary()
    }

    const onClearFilter = () => {
        setCurrentFilter({})
        clearFilter()
    }

    const renderAdvisorFilter = () => {
        if (departmentId === BaseDepartmentId.Admissions) {
            return (
                <KlassDropdown
                    isMulti
                    options={admissionAdvisors}
                    valueKey="profileId"
                    labelKey="fullName"
                    getOptionLabel={UserOptionLabel}
                    placeholder={t("statsFilter.admissionAdvisor")}
                    value={currentFilter.admAdvisors}
                    onChange={(newValue) => setCurrentFilter({...currentFilter, admAdvisors: newValue})}
                />
            )
        }
        if (departmentId === BaseDepartmentId.FinancialAid) {
            return (
                <KlassDropdown
                    isMulti
                    options={finAidAdvisors}
                    valueKey="profileId"
                    labelKey="fullName"
                    getOptionLabel={UserOptionLabel}
                    placeholder={t("statsFilter.financialAidAdvisor")}
                    value={currentFilter.faAdvisors}
                    onChange={(newValue) => setCurrentFilter({...currentFilter, faAdvisors: newValue})}
                />
            )
        }

        return (
            <KlassDropdown
                isMulti
                options={academicAdvisors}
                valueKey="profileId"
                labelKey="fullName"
                getOptionLabel={UserOptionLabel}
                placeholder={t("statsFilter.academicAdvisor")}
                value={currentFilter.acadAdvisors}
                onChange={(newValue) => setCurrentFilter({...currentFilter, acadAdvisors: newValue})}
            />
        )
    }

    return (
        <div className={styles.wrap}>
            <div className={styles.headerWrap}>
                <div className={styles.headerLeft}>
                    <div className={styles.headerLeftTitleWrap}>
                        <p className={styles.headerLeft__title}>{t(`statsChecklist.${props.title}`).toUpperCase()}</p>
                    </div>
                </div>
            </div>
            <BaseNewFilter
                filter={currentFilter}
                className={styles.filterContainer}
                showSearch={false}
                searchValue={searchValue}
                onSearchInput={changeSearch}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                renderRightFilter={() => (
                    <div className={styles.actionWrap}>
                        <BaseButton
                            title={t("common:action.report")}
                            variant="secondary"
                            onClick={redirectToChecklistReport}
                        />
                    </div>
                )}>
                <Row gutter={[40, 32]}>
                    <Col span={12}>
                        <CampusSelect
                            isMulti
                            placeholder={t("statsFilter.campus")}
                            value={currentFilter?.campuses}
                            onChange={(newValue) => setCurrentFilter({...currentFilter, campuses: newValue})}
                        />
                    </Col>
                    <Col span={12}>
                        <TermSelect
                            isMulti
                            placeholder={t("statsFilter.startingTerm")}
                            value={currentFilter.terms}
                            onChange={(newValue) => setCurrentFilter({...currentFilter, terms: newValue})}
                        />
                    </Col>
                    <Col span={12}>
                        <ProgramSelect
                            isMulti
                            placeholder={t("statsFilter.program")}
                            value={currentFilter.programs}
                            onChange={(newValue) => setCurrentFilter({...currentFilter, programs: newValue})}
                        />
                    </Col>
                    <Col span={12}>
                        <NewStudentStatusSelectFilter
                            isMulti
                            value={currentFilter.statuses}
                            onChange={(newValue) => setCurrentFilter({...currentFilter, statuses: newValue})}
                        />
                    </Col>
                    <Col span={12}>{renderAdvisorFilter()}</Col>
                </Row>
            </BaseNewFilter>
            <div className={styles.dataContainer}>
                <Row gutter={[24, 24]}>
                    <Col span={8}>
                        <div className={styles.cardItem}>
                            <p className={styles.cardItem__number}>{summary.totalStudents}</p>
                            <p className={styles.cardItem__title}>{t("statsChecklist.students").toUpperCase()}</p>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={styles.cardItem}>
                            <p className={styles.cardItem__number}>{Math.ceil(summary.completion * 100)}%</p>
                            <p className={styles.cardItem__title}>{t("statsChecklist.completion").toUpperCase()}</p>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className={cx(styles.cardItem, styles.cardItem__pending)}>
                            <div className={styles.pendingWrap}>
                                <div className={styles.pendingCard}>
                                    <p className={styles.pendingCard__number}>{summary.pendingStudents}</p>
                                    <p className={styles.pendingCard__title}>
                                        {t("statsChecklist.students").toUpperCase()}
                                    </p>
                                </div>
                                <div className={styles.pendingCard}>
                                    <p className={styles.pendingCard__number}>{summary.pendingItems}</p>
                                    <p className={styles.pendingCard__title}>
                                        {t("statsChecklist.items").toUpperCase()}
                                    </p>
                                </div>
                            </div>
                            <p className={styles.pending__title}>{t("statsChecklist.overdue").toUpperCase()}</p>
                        </div>
                    </Col>
                </Row>
                <BaseLoading isShow={isLoading} />
            </div>
        </div>
    )
}
