import {useQuery} from "@tanstack/react-query"
import {addressService} from "services"

const INITIAL_DATA = []

export const useAllCountries = () => {
    const countriesQuery = useQuery(
        ["all-countries"],
        async () => {
            const {data} = await addressService.getCountries()
            return data.map((v: any) => ({
                id: v.countryId,
                name: v.name,
                code: v.code
            }))
        },
        {}
    )

    return {
        countries: countriesQuery.data ?? INITIAL_DATA,
        isLoading: countriesQuery.isFetching,
        fetched: countriesQuery.isFetched
    }
}
