import React from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdownAsyncProps} from "../Select"
import {BaseDepartmentId} from "types/departments"
import orderBy from "lodash/orderBy"
import {KlassDropdown} from "../inputs"
import {pick} from "lodash"
import {useAllStudentDepartmentStatuses} from "hooks/student-statuses/useAllStudentDepartmentStatuses"

type Props = Partial<KlassDropdownAsyncProps> & {
    departmentId?: number | number[]
    shortly?: boolean
}

const StudentStatusSelect: React.FC<Props> = ({departmentId, shortly, ...props}) => {
    const {t} = useTranslation(["workflow", "common"])
    const {statuses} = useAllStudentDepartmentStatuses()

    const statusGroups = React.useMemo(
        () =>
            (Object.values(BaseDepartmentId).filter((v) => typeof v === "number") as number[])
                .filter((id) => {
                    if (departmentId) {
                        return (Array.isArray(departmentId) ? departmentId : [departmentId]).includes(id)
                    }
                    return true
                })
                .map((departmentId) => {
                    let options = orderBy(
                        statuses.filter((status) => status.stage?.departmentId === departmentId),
                        ["stage.state", "priority"],
                        ["asc", "asc"]
                    ).map((status) => ({
                        ...pick(status, ["statusId", "name", "stageId"]),
                        stage: status.stage && pick(status.stage, ["stageId", "departmentId", "name"]),
                        departmentId: status.stage?.departmentId
                    }))
                    if (shortly) {
                        options = options.map(({statusId, name, stage}) => ({
                            statusId,
                            name,
                            departmentId: stage?.departmentId
                        })) as any[]
                    }
                    return {
                        label: t(`workflow.statusDepartment.${departmentId}`),
                        options
                    }
                })
                .filter((group) => group.options.length > 0),
        [t, statuses, shortly, departmentId]
    )

    return (
        <KlassDropdown
            {...props}
            options={statusGroups}
            valueKey="statusId"
            labelKey="name"
            getOptionLabel={(option) => (
                <span>
                    {!!(option.departmentId || option.stage?.departmentId) && (
                        <span className="mr-4">
                            [{t(`common:moduleAbbrev.${option.departmentId || option.stage?.departmentId}`)}]
                        </span>
                    )}
                    <span>{option.name}</span>
                </span>
            )}
        />
    )
}

export default StudentStatusSelect
