/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {finAidService} from "services"
import {useModel} from "hooks"
import {Col, Row} from "antd"
import {routing} from "helpers/routing"
import {formatCurrency, formatDateRange, getFullName, handleError} from "helpers"
import {KlassappTableProps} from "types/common"
import {Auth} from "types/auth"
import {FundSource} from "types/fin-aid/fund-source"
import {User} from "types/user"
import {BaseButton, Icon} from "components"
import styles from "./AwardSummaries.module.css"
import cx from "classnames"
import {KlassDropdown} from "components/Select"
import TermSelect from "components/TermSelect"
import FundSourceSelect from "components/FundSourceSelect"
import {AwardSummary, AwardSummaryStatus} from "types/fin-aid/fin-aid"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import {ExportButton, ExportFileType} from "components/ui"
import {useMutation} from "@tanstack/react-query"
import {get} from "lodash"
import {exportCsv, exportExcel} from "helpers/export-table"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {PaymentPeriod} from "types/student-account/payment-period"

interface Props extends KlassappTableProps {
    studentId?: number
    student?: Auth.DepartmentStudent
    onAwardSummarySelect?: React.Dispatch<React.SetStateAction<AwardSummary>>
    isReport?: boolean
}

const currentYear = moment().year()
const yearOptions = [-1, 0, 1, 2, 3, 4, 5, 6].map((index) => ({
    id: currentYear + index,
    name: `${currentYear + index} - ${currentYear + index + 1}`
}))

const INITIAL_FILTER = {
    awardYear: undefined,
    term: undefined,
    fundSource: undefined,
    status: undefined
}

function AwardSummaries({
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    tableHeaderActions,
    isLoading,
    menuActions,
    isHideMenuActions,
    isShowTableHeaderAction,
    onClickShowConfirmModal,
    getCurrentData,
    getCurrentPage,
    getCurrentPageSize,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    studentId,
    onAwardSummarySelect,
    isReport = false
}: Props) {
    const history = useHistory()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const {t} = useTranslation(["financialAid", "common"])

    const getFilter = () => ({
        studentProfileId: studentId,
        search,
        awardYear: filter.awardYear?.id,
        termId: filter.term?.id,
        fundSourceId: filter.fundSource?.fundSourceId,
        status: filter.status?.id
    })

    const getData = async () => {
        dispatch({isLoading: true})
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const {total, data: awardSummaries} = await finAidService.getAwardSummaries({
            filter: getFilter(),
            range: {
                page,
                pageSize
            },
            linkedEntities: true
        })
        dispatch({total, data: awardSummaries.map((item) => ({...item, id: item.awardSummaryId})), isLoading: false})
    }

    const onClickRowItem = ({studentProfileId, awardSummaryId}) => {
        history.push(routing.finAid.student.awardSummaryDetail(studentProfileId, awardSummaryId))
    }

    const onClickEdit = ({studentProfileId, awardSummaryId}) => {
        history.push(routing.finAid.student.awardSummaryDetail(studentProfileId, awardSummaryId))
    }

    const onClickDelete = React.useCallback(
        async (deletedItem: AwardSummary) => {
            // if (deletedItem.isLock) {
            //     toastError(t("common:message.cantDeleteLockedItem"))
            //     return
            // }
            try {
                dispatch({isLoading: true})
                await finAidService.deleteAwardSummaries([deletedItem.awardSummaryId])
                await getData()
            } catch (e) {
                handleError(e)
            } finally {
                dispatch({isLoading: false})
            }
        },
        [getData]
    )

    const onClickDeleteMulti = React.useCallback(async () => {
        const checkedItems = getCurrentData().filter((item) => item.isChecked)
        // if (checkedItems.find((item) => item.isLock)) {
        //     toastError(t("common:message.cantDeleteLockedItem"))
        //     return
        // }
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await finAidService.deleteAwardSummaries(checkedItems.map((item) => item.awardSummaryId))
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }, [getData])

    const getPageTitle = () => {
        return t("finAidAward.awardSummary.tableTitle")
    }

    const getFields = useCallback(() => {
        return [
            isReport ? null : "disb",
            t("finAidAward.awardSummary.column.awardYear"),
            isReport ? null : t("finAidAward.awardSummary.column.student"),
            t("finAidAward.awardSummary.column.fundSource"),
            t("finAidAward.awardSummary.column.status"),
            t("finAidAward.awardSummary.column.grossAmount"),
            t("finAidAward.awardSummary.column.netAmount"),
            t("finAidAward.awardSummary.column.awardPeriod")
        ].filter(Boolean)
    }, [isReport])

    const getColumns = useCallback(() => {
        return [
            isReport
                ? null
                : {
                      title: "disb",
                      titleHtml: <span />,
                      field: "awardSummaryId",
                      render: (value, record) => (
                          <div
                              onClick={(e) => {
                                  onAwardSummarySelect?.(record)
                                  e.stopPropagation()
                              }}
                              className={styles.iconDetail}>
                              <Icon icon="UNORDER_LIST" />
                          </div>
                      )
                  },
            {
                title: t("finAidAward.awardSummary.column.awardYear"),
                field: "awardYear",
                render: (value) => `${value} - ${value + 1}`
            },
            !isReport
                ? null
                : {
                      title: t("finAidAward.awardSummary.column.student"),
                      field: "studentProfile",
                      render: (value) => getFullName(value)
                  },
            {
                title: t("finAidAward.awardSummary.column.startingTerm"),
                field: "finAidOfferId",
                render: (_, record: AwardSummary) => record.finAidPackage?.termTuitions?.[0]?.termName ?? ""
            },
            {
                title: t("finAidAward.awardSummary.column.fundSource"),
                field: "fundSource",
                render: (value: FundSource) => value?.title ?? ""
            },
            {
                title: t("finAidAward.awardSummary.column.status"),
                field: "status",
                render: (value) => (
                    <div className={cx(styles.status, value)}>{t(`finAidAward.awardSummary.status.${value}`)}</div>
                ),
                renderText: (value) => t(`finAidAward.awardSummary.status.${value}`)
            },
            {
                title: t("finAidAward.awardSummary.column.comment"),
                field: "comments"
            },
            {
                title: t("finAidAward.awardSummary.column.grossAmount"),
                field: "grossAmount",
                render: (value) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("finAidAward.awardSummary.column.netAmount"),
                field: "netAmount",
                render: (value) => formatCurrency(value),
                renderText: (value: number) => (value ?? 0).toFixed(2)
            },
            {
                title: t("finAidAward.awardSummary.column.codStatus"),
                field: "codStatusCode"
            },
            {
                title: t("finAidAward.awardSummary.column.codInformedBorrower"),
                field: "codInformedBorrowerId"
            },
            {
                title: t("finAidAward.awardSummary.column.awardPeriod"),
                field: "period",
                render: (period?: PaymentPeriod) => {
                    if (!period) return ""
                    return formatDateRange(period.startDate, period.endDate, userDateFormat)
                }
            },
            {
                title: t("finAidAward.awardSummary.column.isirCpsTrans"),
                field: "isirCPSTrans"
            },
            {
                title: t("finAidAward.awardSummary.column.revision"),
                field: "revision"
            },
            {
                title: t("finAidAward.awardSummary.column.acceptanceDate"),
                field: "acceptanceDate",
                render: (value) => (value ? moment(value).format(userDateFormat) : undefined)
            },
            {
                title: t("finAidAward.awardSummary.column.lastEditor"),
                field: "updatedBy",
                render: (value: User) => getFullName(value)
            },
            {
                title: t("finAidAward.awardSummary.column.lastEdited"),
                field: "updatedAt",
                render: (value) => (value ? moment(value).format(userDateFormat) : undefined)
            }
        ].filter(Boolean)
    }, [isReport])

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            const actions = []
            const hasLockedItem = (checkedData || []).some((item: AwardSummary) => false)
            if (checkedData?.length === 1) {
                actions.push({
                    title: t("common:action.edit"),
                    icon: "EDIT",
                    action: () => onClickEdit(checkedData[0])
                })
            }
            if (!hasLockedItem) {
                actions.push({
                    title: t("common:action.delete"),
                    icon: "DELETE",
                    action: () => onClickShowConfirmModal("DELETE")
                })
            }
            return actions
        },
        [t, onClickShowConfirmModal, onClickEdit]
    )

    const getMenuActions = React.useCallback(
        () => [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete,
                canShow: (item: AwardSummary) => true
            }
        ],
        [t]
    )

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    const statusOptions = React.useMemo(
        () =>
            Object.values(AwardSummaryStatus).map((status) => ({
                id: status,
                name: t(`finAidAward.awardSummary.status.${status}`)
            })),
        [t]
    )

    // region filter

    const [search, setSearch] = useState("")
    const [filter, setFilter] = useState(INITIAL_FILTER)

    const onChangeFilter = (key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }

    const onClearFilter = () => {
        setFilter(INITIAL_FILTER)
    }

    const onApplyFilter = () => {
        getData()
    }

    const onSearchInput = (search = "") => {
        setSearch(search)
    }

    useEffect(() => {
        getData()
    }, [search])

    // endregion filter

    const onClickAdd = useCallback(() => {
        history.push(routing.finAid.student.awardSummaryDetail(studentId, "new"))
    }, [])

    const exportMutation = useMutation(
        async (type: ExportFileType) => {
            const {data: awardSummaries} = await finAidService.getAwardSummaries({
                filter: getFilter(),
                range: {
                    pageSize: UNLIMITED_PAGE_SIZE,
                    page: 1
                },
                linkedEntities: true
            })

            const payload = {
                filename: `FA Award Summary Report ${moment().format("YYYY-MM-DD")}`,
                columns: columns.map((col) => col.title),
                rows: awardSummaries.map((item) =>
                    columns.map((col) => {
                        if (col.fieldType === "date") {
                            const formatDateUtc = model.getUserDateFormat()
                            return get(item, col.field) ? moment.utc(get(item, col.field)).format(formatDateUtc) : ""
                        }
                        const value = col.renderText
                            ? col.renderText(get(item, col.field), item)
                            : col.render
                            ? col.render(get(item, col.field), item)?.toString?.()
                            : get(item, col.field)?.toString?.()
                        return (type === "csv" ? value?.replaceAll(/[^a-zA-Z0-9. -]/g, "") : value) || ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        },
        {
            onError: (error) => handleError(error)
        }
    )

    return (
        <div>
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <Row gutter={16} justify="end" align="middle">
                        {!isReport && (
                            <PermissionsRequired
                                permissions={{
                                    staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.Add]
                                }}>
                                <Col>
                                    <BaseButton
                                        title="CREATE"
                                        icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                                        onClick={onClickAdd}
                                    />
                                </Col>
                            </PermissionsRequired>
                        )}
                        {isReport && (
                            <Col>
                                <ExportButton
                                    isLoading={exportMutation.isLoading}
                                    onSelect={exportMutation.mutate}
                                    label="Download"
                                    availableFileTypes={["csv", "excel"]}
                                />
                            </Col>
                        )}
                    </Row>
                )}>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <KlassDropdown
                            placeholder={t("finAidAward.awardSummary.column.awardYear")}
                            options={yearOptions}
                            value={filter.awardYear}
                            onChange={(option) => onChangeFilter("awardYear", option)}
                        />
                    </Col>
                    <Col span={12}>
                        <TermSelect
                            placeholder={t("finAidAward.awardSummary.column.term")}
                            value={filter.term}
                            onChange={(option) => onChangeFilter("term", option)}
                        />
                    </Col>
                    <Col span={12}>
                        <FundSourceSelect
                            placeholder={t("finAidAward.awardSummary.column.fundSource")}
                            value={filter.fundSource}
                            onChange={(option) => onChangeFilter("fundSource", option)}
                        />
                    </Col>
                    <Col span={12}>
                        <KlassDropdown
                            placeholder={t("finAidAward.awardSummary.column.status")}
                            options={statusOptions}
                            value={filter.status}
                            onChange={(option) => onChangeFilter("status", option)}
                        />
                    </Col>
                </Row>
            </BaseNewFilter>

            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions || isReport ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                isShowCheckedColumn={!isReport}
                isShowColumnPicker
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
            />
        </div>
    )
}

export default KlassappTableHOC(AwardSummaries)
