/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import cx from "classnames"
import {Icon} from "components/Icon"
import styles from "./RejectedTaskUsers.module.css"
import {handleError} from "helpers"
import {taskService} from "services"
import moment from "moment"
import {useModel} from "hooks"

type RejectedTaskUser = {
    id: number
    subtaskCode: string
    subtaskId: number
    rejectedAt: string
    reason: string
    rejectedBy: number
    rejectedByUser: string
}

export function RejectedTaskUsers(props) {
    const model = useModel()
    const [isCollapseBody, setIsCollapseBody] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [rejectedTaskUsers, setRejectedTaskUsers] = useState<RejectedTaskUser[]>([])
    const {taskData} = props

    useEffect(() => {
        getRejectedTaskUsers()
    }, [])

    const getRejectedTaskUsers = async () => {
        try {
            setIsLoading(true)
            const {data} = await taskService.getRejectedTaskUsers({taskId: taskData.id})
            setRejectedTaskUsers(data)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const onToggleShowBody = () => {
        setIsCollapseBody(!isCollapseBody)
    }

    if (isLoading || !rejectedTaskUsers.length) {
        return null
    }

    const userDateTimeFormat = model.getUserDateTimeFormat()

    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                <div className={styles.header__icon}>
                    <Icon icon="CROSS_CIRCLE_FILL" color="#fff" />
                </div>
                <p className={styles.title}>{rejectedTaskUsers.length} tasks have been rejected by participants</p>
                <div className={styles.collapse} onClick={onToggleShowBody}>
                    <Icon icon={!isCollapseBody ? "ARROW_DOWN" : "ARROW_RIGHT"} color="#fff" />
                </div>
            </div>
            <div
                className={cx(styles.body, {
                    [styles.body__show]: !isCollapseBody
                })}>
                {rejectedTaskUsers.map((tu) => (
                    <div className={styles.item} key={tu.subtaskId}>
                        <p className={styles.item__title}>
                            Task <span className={styles.item__titleHighlight}>{tu.subtaskCode}</span> has been rejected
                            by
                            <span> {tu.rejectedByUser}</span>{" "}
                            {tu.rejectedAt && <span> at {moment(tu.rejectedAt).format(userDateTimeFormat)}</span>}
                        </p>
                        <p className={styles.description}>{tu.reason}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
