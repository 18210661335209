import React, {useEffect, useState} from "react"
import {Row, Col} from "antd"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {KlassDropdown} from "components/Select"
import {AcademicPlans} from "types/academicPlans"
import {academicPlansService, courseScheduleService} from "services"
import {handleError, toastError, toastSuccess} from "helpers"
import {FormLabel} from "components/Form"
import styles from "./RegisterCoursePopup.module.css"
import {isEmpty} from "lodash"

type Props = {
    isShow: boolean
    studentProfileId: number
    sectionCourse: AcademicPlans.SectionCourse
    onClose: () => void
    reloadView: () => void
}

export function RegisterCoursePopup(props: Props) {
    const {isShow, studentProfileId, sectionCourse, onClose, reloadView} = props
    const statuses = [{id: AcademicPlans.AcademicPlanCourseStatus.Registered, name: "Registered"}]
    const [isSaving, setIsSaving] = useState(false)
    const [info, setInfo] = useState({
        term: null,
        schedule: null,
        status: {id: AcademicPlans.AcademicPlanCourseStatus.Registered, name: "Registered"}
    })
    const [planTerms, setPlanTerms] = useState([])
    const [schedules, setSchedules] = useState([])

    useEffect(() => {
        getAcademicPlanTerms()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAcademicPlanTerms = async () => {
        try {
            const data = await academicPlansService.getAcademicPlanTerms({studentProfileId})
            setPlanTerms(data)
        } catch (error) {
            handleError(error)
        }
    }

    const validateData = () => {
        if (isEmpty(info.term) || isEmpty(info.schedule)) {
            toastError("Please select required field")
            return false
        }
        return true
    }

    const registerCourse = async () => {
        const isValid = validateData()
        if (!isValid) {
            return
        }
        try {
            setIsSaving(true)
            await academicPlansService.registerCourse({
                academicPlanCourseId: sectionCourse.academicPlanCourseId,
                academicPlanTermId: info.term.id,
                scheduleId: info.schedule.id,
                studentProfileId
            })
            toastSuccess("Register course successfully")
            onClose()
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSaving(false)
        }
    }

    const updateField = (field, value) => {
        const newInfo = {...info}
        newInfo[field] = value
        if (field === "term") {
            newInfo.schedule = null
        }
        setInfo(newInfo)
    }

    const onChangeInfo = (field, value) => {
        if (field === "term") {
            getSchedules(value?.termId)
        }
        updateField(field, value)
    }

    const getSchedules = async (termId: number) => {
        if (!termId) {
            setSchedules([])
            return
        }
        try {
            const {data: schedules} = await courseScheduleService.courseScheduleGet({
                filter: {
                    term_id: termId,
                    course_id: sectionCourse.courseId
                }
            })
            setSchedules(schedules)
        } catch (error) {
            handleError(error)
            setSchedules([])
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="EDIT_LINE" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.title}>
                    Register Course - {sectionCourse.courseName}({sectionCourse.courseCode})
                </p>
                <Row gutter={[24, 12]}>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Status" />
                            <KlassDropdown
                                options={statuses}
                                value={info.status}
                                onChange={(newValue) => onChangeInfo("status", newValue)}
                                readOnly
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Term" isRequired />
                            <KlassDropdown
                                options={planTerms}
                                onChange={(newValue) => onChangeInfo("term", newValue)}
                                value={info.term}
                                placeholder="Term"
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Schedule" isRequired />
                            <KlassDropdown
                                options={schedules}
                                valueKey="id"
                                labelKey="schedule_suffix"
                                value={info.schedule}
                                onChange={(newValue) => onChangeInfo("schedule", newValue)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className={styles.action}>
                    <BaseButton title="Cancel" variant="secondary" loading={isSaving} onClick={onClose} />
                    <BaseButton title="Save" loading={isSaving} onClick={registerCourse} />
                </div>
            </div>
        </BasePopup>
    )
}
