/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {isEmpty} from "lodash"

import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {PermissionsRequired} from "components/PermissionsRequired"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {useModel} from "hooks"
import {handleError, truncateWithEllipses, checkPermission, handleErrorMessage} from "helpers"
import {settingEmailTemplatesService} from "services"
import {Permissions} from "types/permission"
import styles from "./SMSTemplates.module.css"
import {SettingTemplatesTabs} from "../../Templates"

function SMSTemplates(props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        isLoadedTableFuncs,
        getCurrentData,
        getCurrentPage,
        getCurrentPageSize
    } = props
    const {t} = useTranslation(["templates", "settings", "common"])
    const history = useHistory()
    const model = useModel()

    const getFields = () => {
        return [t("templates:code"), t("templates:name"), t("templates:description"), t("templates:lastEdited")]
    }

    const getPageTitle = () => {
        return t("templates:titleTemplate", {field: "Text Message"})
    }

    const getColumns = () => {
        return [
            {
                title: t("templates:code"),
                field: "code",
                sortable: true
            },
            {
                title: t("templates:name"),
                field: "title",
                sortable: true
            },
            {
                title: t("templates:description"),
                field: "description",
                sortable: true
            },
            {
                title: t("templates:lastEdited"),
                field: "updatedAt",
                fieldType: "date",
                format: "MM/DD/YYYY",
                style: {minWidth: 150},
                sortable: true
            }
        ]
    }

    const getParams = () => {
        const page = getCurrentPage()
        const pageSize = getCurrentPageSize()
        const params: any = {
            range: {page, pageSize},
            filter: {
                templateType: SettingTemplatesTabs.SMS
            },
            linkedObjects: true
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const params = getParams()
            const {data, total} = await settingEmailTemplatesService.list(params)
            const newData = data.map((el) => ({
                ...el,
                id: el.emailTemplateId,
                description: truncateWithEllipses(el.description ?? "", 35, 35)
            }))
            dispatch({data: newData, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {
        history.push(`/settings/sms-templates/detail?id=${row.id}`)
    }

    const onClickDeleteMulti = async () => {
        if (!checkPermission({staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.Delete]}, model)) {
            return handleErrorMessage(t("templates:dontHavePermissionToDelete"))
        }

        const data = getCurrentData()
        const checkedIds = data.filter((item) => item.isChecked).map((item) => item.id)
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingEmailTemplatesService.delete(checkedIds)
            await getData()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickDuplicateMulti = async () => {
        if (!checkPermission({staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.Add]}, model)) {
            return handleErrorMessage(t("templates:dontHavePermissionToCreate"))
        }

        const data = getCurrentData()
        const payload = data
            .filter((item) => item.isChecked)
            .map(({title, description, template, templateType}) => ({
                title: `${title} (Copy)`,
                description,
                template,
                templateType
            }))
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await settingEmailTemplatesService.create(payload)
            await getData()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickEdit = (editedItem) => {
        onClickRowItem(editedItem)
    }

    const onClickDelete = async (deletedItem) => {
        if (!checkPermission({staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.Delete]}, model)) {
            return handleErrorMessage(t("templates:dontHavePermissionToDelete"))
        }

        const {dispatch} = props
        try {
            dispatch({isLoading: true})
            await getData()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "onClickDuplicateMulti", func: onClickDuplicateMulti},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, orderField?.field, orderField?.order])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const onClickAdd = () => {
        history.push("/settings/sms-templates/detail")
    }

    return (
        <>
            <PermissionsRequired permissions={{staff: [Permissions.Staff.Settings.SystemSettings.EmailTemplates.Add]}}>
                <div className={styles.actionWrap}>
                    <BaseButton
                        title={t("common:action.add")}
                        icon={<Icon className={styles.iconPlus} icon="PLUS" color="#FFF" />}
                        onClick={onClickAdd}
                    />
                </div>
            </PermissionsRequired>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </>
    )
}

export default KlassappTableHOC(SMSTemplates)
