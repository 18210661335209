import React, {useCallback, useEffect, useMemo, useState} from "react"
import {Icon} from "components/Icon"
import {PaymentPlanForm} from "./PaymentPlanForm"
import PaymentPlanItemTable from "./PaymentPlanItemTable"
import styles from "./PaymentPlanDetail.module.css"
import {PaymentPlan, PaymentPlanFrequency, PaymentPlanStatus, PaymentPlanType} from "types/student-account/payment-plan"
import moment from "moment"
import {useModel, useVisible} from "hooks"
import {formatCurrency, handleError, toastError, toastSuccess, toDateOnly} from "helpers"
import {omit, orderBy, sum} from "lodash"
import {studentAccountService} from "services"
import {useTranslation} from "react-i18next"
import {ConfirmPopup} from "uiKit"
import {useMutation} from "@tanstack/react-query"
import cx from "classnames"

type Props = {
    profileId: number
    oldPaymentPlan?: Partial<PaymentPlan>
    onSave?: (paymentPlan: PaymentPlan, isNew?: boolean) => void
    onCancel?: (paymentPlanId?: number) => void
    onUpdate?: (paymentPlanId?: number) => void
}

export const PaymentPlanDetail = ({profileId, oldPaymentPlan, onSave, onCancel, onUpdate}: Props) => {
    const {t} = useTranslation(["studentAccount", "common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()

    const [isExpanded, setExpanded] = useState(!oldPaymentPlan?.paymentPlanId)
    const [isEditing, setEditing] = useState(!oldPaymentPlan?.paymentPlanId)
    const [isSaving, setSaving] = useState(false)
    const [paymentPlan, setPaymentPlan] = useState<Partial<PaymentPlan>>(
        oldPaymentPlan || {
            title: "Payment Plan",
            academicYear: new Date().getFullYear(),
            type: PaymentPlanType.MinimumAmount,
            minPaymentAmount: 0,
            numberOfPayments: 0,
            firstPaymentDue: new Date().toISOString(),
            frequency: PaymentPlanFrequency.Weekly,
            reminderIds: [],
            reminders: []
        }
    )
    const deleteConfirmPopup = useVisible(false)

    const toggleDetailView = useCallback(() => setExpanded((prev) => !prev), [])

    const onClickEdit = useCallback(() => {
        setPaymentPlan(oldPaymentPlan || {})
        setEditing(true)
    }, [oldPaymentPlan])

    const onClickDelete = useCallback(() => deleteConfirmPopup.open(), [deleteConfirmPopup])

    const confirmDelete = useMutation(
        async () => {
            await studentAccountService.deletePaymentPlans([paymentPlan.paymentPlanId])
            onUpdate(paymentPlan.paymentPlanId)
        },
        {
            mutationKey: [paymentPlan.paymentPlanId],
            onSuccess: () => toastSuccess(t("common:message.deleteSuccess")),
            onError: (error) => handleError(error)
        }
    )

    const updatePlanStatusMutation = useMutation(
        async (status: PaymentPlanStatus) => {
            await studentAccountService.updatePaymentPlan({
                paymentPlanId: paymentPlan.paymentPlanId,
                status
            })
            onUpdate(paymentPlan.paymentPlanId)
        },
        {
            mutationKey: [paymentPlan.paymentPlanId],
            onMutate: () => setSaving(true),
            onSettled: () => setSaving(false),
            onSuccess: () => toastSuccess(t("common:message.updateSuccess")),
            onError: (error) => handleError(error)
        }
    )

    const onClickCancel = useCallback(() => {
        setEditing(false)
        onCancel?.(oldPaymentPlan?.paymentPlanId)
    }, [onCancel, oldPaymentPlan?.paymentPlanId])

    useEffect(() => {
        if (isEditing && !oldPaymentPlan?.paymentPlanId) {
            ;(async function getNextCode() {
                const nextCode = await studentAccountService.getPaymentPlanNextCode()
                setPaymentPlan((prev) => ({...prev, code: nextCode}))
            })()
        }
    }, [isEditing, oldPaymentPlan])

    const validateFields = useCallback(
        (paymentPlan: Partial<PaymentPlan>): boolean => {
            const mandatoryFields = [
                "code",
                "title",
                "academicYear",
                "accountId",
                "type",
                "originalAmount",
                "currentBalance",
                "numberOfPayments",
                "firstPaymentDue",
                "frequency"
            ]

            for (const field of mandatoryFields) {
                if (paymentPlan[field] == null) {
                    toastError(t("common:validation.fieldRequired"))
                    return false
                }
            }

            return true
        },
        [t]
    )

    const onClickSave = useCallback(async () => {
        try {
            setSaving(true)
            if (!validateFields(paymentPlan)) {
                return
            }

            const submitData = omit(paymentPlan, [
                "studentProfile",
                "term",
                "account",
                "reminders",
                "charges",
                "payments",
                "paymentItems",
                "items",
                "updatedBy",
                "updatedByProfileId",
                "createdAt",
                "updatedAt",
                "deletedAt"
            ])
            if (paymentPlan.paymentPlanId) {
                const updatedPaymentPlan = await studentAccountService.updatePaymentPlan(
                    omit(submitData, ["studentProfileId"])
                )
                onSave(updatedPaymentPlan)
            } else {
                const createdPaymentPlan = await studentAccountService.createPaymentPlan({
                    ...submitData,
                    studentProfileId: profileId
                })
                onSave(createdPaymentPlan, true)
            }
            setEditing(false)
        } catch (error) {
            handleError(error)
        } finally {
            setSaving(false)
        }
    }, [profileId, paymentPlan, validateFields, onSave])

    const remainingItems = useMemo(
        () =>
            orderBy(oldPaymentPlan?.items?.filter((item) => item.paidAmount < item.amount) ?? [], ["dueDate"], ["asc"]),
        [oldPaymentPlan?.items]
    )

    const remainingPayments = useMemo(() => remainingItems.length, [remainingItems])

    const balance = useMemo(() => sum(remainingItems.map((item) => item.amount - item.paidAmount)), [remainingItems])

    const status = useMemo(() => {
        if (oldPaymentPlan?.status === PaymentPlanStatus.Canceled) {
            return "Canceled"
        }
        const oldestDuePayment = remainingItems[0]
        if (oldestDuePayment) {
            const dueDate = toDateOnly(oldestDuePayment.dueDate)
            const numDays = Math.abs(moment().diff(dueDate, "day"))
            const isPastDue = moment(dueDate).isBefore(Date.now(), "day")
            const isDue = moment().add(20, "day").isAfter(dueDate)
            if (!isPastDue && !isDue) return "New"
            return (
                <span
                    title={moment(dueDate).format(userDateFormat)}
                    className={cx({[styles.pastDue]: isPastDue, [styles.due]: isDue})}>
                    {isPastDue ? "Past Due" : "Due"}
                    <br />
                    <span style={{fontSize: 14, whiteSpace: "nowrap"}}>
                        {t(!numDays ? "paymentPlan.today" : "paymentPlan.numDays", {count: numDays})}
                    </span>
                </span>
            )
        }
        return "New"
    }, [t, userDateFormat, oldPaymentPlan?.status, remainingItems])

    return (
        <div className={styles.itemWrap}>
            {!!oldPaymentPlan?.paymentPlanId && (
                <div className={styles.itemHeaderWrap}>
                    <p className={styles.item__code}>{oldPaymentPlan.code}</p>
                    <div>
                        <p className={styles.item__title}>{oldPaymentPlan.title}</p>
                        <p className={styles.item__time}>
                            Updated{" "}
                            <span className={styles.item__timeHighlight}>
                                {moment(oldPaymentPlan.updatedAt).format(userDateFormat)}
                            </span>
                        </p>
                    </div>
                    <div className={styles.rightWrap}>
                        <div className={styles.balanceWrap}>
                            <p className={styles.balance__title}>Status</p>
                            <p className={styles.balance__cost}>{status}</p>
                        </div>
                        {paymentPlan.status === PaymentPlanStatus.Active && (
                            <div className={styles.balanceWrap}>
                                <p className={styles.balance__title}>Remain</p>
                                <p className={styles.balance__cost}>
                                    {remainingPayments}{" "}
                                    <span style={{fontSize: 14, whiteSpace: "nowrap"}}>payments</span>
                                </p>
                            </div>
                        )}
                        <div className={styles.balanceWrap}>
                            <p className={styles.balance__title}>Original</p>
                            <p className={styles.balance__cost}>{formatCurrency(oldPaymentPlan.originalAmount)}</p>
                        </div>
                        <div className={styles.balanceWrap}>
                            <p className={styles.balance__title}>Balance</p>
                            <p className={styles.balance__cost}>{formatCurrency(balance)}</p>
                        </div>
                        <div className={styles.iconWrap} onClick={toggleDetailView}>
                            <Icon icon="ARROW_DOWN" color="#212121" className={styles.icon} />
                        </div>
                    </div>
                </div>
            )}

            {isExpanded && (
                <>
                    <PaymentPlanForm
                        {...{
                            profileId,
                            paymentPlan,
                            setPaymentPlan,
                            isEditing,
                            isSaving,
                            updatePlanStatus: updatePlanStatusMutation.mutate,
                            onClickEdit,
                            onClickDelete,
                            onClickCancel,
                            onClickSave
                        }}
                    />
                    {!!oldPaymentPlan?.paymentPlanId && <PaymentPlanItemTable paymentPlan={oldPaymentPlan} />}
                </>
            )}

            <ConfirmPopup
                isVisible={deleteConfirmPopup.isVisible}
                title={t("common:action.confirm")}
                onClose={deleteConfirmPopup.close}
                onConfirm={confirmDelete.mutate}
                loading={confirmDelete.isLoading}
                description="Are you sure to delete this payment plan? This action cannot be undone"
                icon="DELETE"
            />
        </div>
    )
}
