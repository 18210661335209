/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useReducer, useState} from "react"
import {useAllVerificationStatuses, useVisible} from "hooks"
import moment from "moment"
import {useHistory} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {isEmpty, omit, uniqBy} from "lodash"
import {Button, Checkbox, Col, Empty, Row} from "antd"
import cx from "classnames"
import {KlassappTable, KlassappTableDropdown, KlassappTableHeader, SignaturePopup} from "uiKit"
import {AddItemCircleButton, BaseButton, SecondaryButton} from "components/buttons"
import {TaskStatus} from "types/tasks"
import {DEPENDENCY_STATUS_OPTIONS} from "types/students"
import {KlassappTableHOC} from "HOC"
import {getFullName, handleError, handleErrorMessage} from "helpers"
import {Icon} from "components/Icon"
import {BasePopup} from "components/popup"
import styles from "./Verification.module.css"
import {finAidService, generalSettingsService, isirService, profileCampusService, userServiceV3} from "services"
import {TaskList} from "./parts"
import {KlassDropdown} from "components"
import {FormLabel} from "components/Form"
import {FAStatusBy, FinAidAwardYear, FinAidStudentTerm} from "types/fin-aid/fin-aid"
import {useQuery} from "@tanstack/react-query"
import {VerificationStatus} from "types/fin-aid/verification-status"
import {GeneralSettingField} from "types/settings/general-setting"
import TermSelect from "components/TermSelect"
import {TermDetails} from "types/terms"

const currentYear = moment().year()
const yearOptions = [1, 0, -1, -2, -3].map((index) => ({
    id: currentYear + index,
    name: `${currentYear + index} - ${currentYear + index + 1}`
}))

function reducer(state, action) {
    return {...state, ...action}
}

enum ReviewItemType {
    APPROVAL = "approval"
}

function Verification(props) {
    const initialState = {
        reviewItem: null,
        reviewType: null,
        isRejecting: false,
        isApproving: false,
        filter: {
            status: [
                TaskStatus.TODO,
                TaskStatus.DRAFT,
                TaskStatus.STARTED,
                TaskStatus.REJECTED,
                TaskStatus.OVERDUE,
                TaskStatus.AWAITING_APPROVAL
            ],
            search: ""
        }
    }
    const [{reviewItem, isRejecting, isApproving, filter}, dispatchState] = useReducer(reducer, initialState)
    const {
        dispatch,
        dispatchFunc,
        page,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        tableHeaderActions,
        getCurrentData,
        isLoading,
        isShowTableHeaderAction,
        studentId
    } = props
    const history = useHistory()
    const {t} = useTranslation(["financialAid", "common"])

    const signaturePopup = useVisible(false)
    const [studentEmail, setStudentEmail] = useState<string[]>([])
    const [awardYearCode, setAwardYearCode] = useState<number | null>(null)
    const [reportStatus, setReportStatus] = useState<string>("") // "unlocked" | "locked"
    const [isShowTaskPopup, setIsShowTaskPopup] = useState<boolean>(false)
    const [selectedCodeForAction, setSelectedCodeForAction] = useState(null)
    const [newItemDataIndex, setNewItemDataIndex] = useState<any>(null)
    const [newItemData, setNewItemData] = useState<any>(null)
    const [newItemChangeValue, setNewItemChangeValue] = useState<any>(null)
    const [changeValue, setChangeValue] = useState<any>("")
    const [changeValueKey, setChangeValueKey] = useState<any>("")
    const [cCodeMaster, setCCodeMaster] = useState<any>(null)
    const [schoolCodeData, setSchoolCodeData] = useState<any>(null)

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])

        if (typeof studentId !== "number" || isNaN(studentId)) {
            handleErrorMessage("Student doesn't exist in system")
        } else {
            getSystemData({profileId: [studentId]})
        }
    }, [])

    useEffect(() => {
        if (awardYearCode) {
            getListCCodeMaster()
        }
    }, [awardYearCode])

    useEffect(() => {
        // if (!schoolCodeData) {
        //     getProfileCampusData()
        // } else {
        if (studentEmail?.length && reportStatus.trim() !== "") {
            getListISIR({emails: studentEmail})
        }
        // }
    }, [schoolCodeData, reportStatus, studentEmail])

    useEffect(() => {
        if (newItemData) {
            const newData = props.data.map((item) => {
                if (item.id === newItemData.id) {
                    item = newItemData
                    item.cCode = renderSelectInput(
                        "cCodeData",
                        t("verification.placeholder.cCode"),
                        cCodeMaster,
                        newItemData.cCodeData
                    )
                    setNewItemData(item)
                }
                return item
            })

            dispatch({data: newData})
        }
    }, [newItemData])

    useEffect(() => {
        let key = changeValueKey
        let value = changeValue

        if (changeValueKey !== "") {
            let {data} = props

            newItemData[key] = value
            const newData = data[newItemDataIndex].dataCCode.map((item) => {
                if (item.id === newItemData.id) {
                    item.cCode = renderSelectInput("cCodeData", t("verification.placeholder.cCode"), cCodeMaster)
                    item.commentText = newItemChangeValue.commentText
                    item.task = renderTaskHtml(newItemData, true)
                    item.isRequiredHtml = renderIsRequiredHtml(newItemData, true)
                    item.rowClassName = newItemData.isRequired ? undefined : styles.rowHighlighted
                    item.approval = renderApprovalHtml(newItemData, true)
                    item.upload = renderDocsHtml(newItemData, true)
                }
                return item
            })
            setNewItemData(newItemData)
            data[newItemDataIndex].dataCCode = newData
            props.dispatch({data})
        }
    }, [changeValueKey, changeValue])

    function getFields() {
        return [
            t("verification.cCode"),
            t("verification.commentText"),
            t("verification.task"),
            t("verification.isRequired"),
            t("verification.approval"),
            t("verification.upload")
        ]
    }

    function getPageTitle() {
        return t("aptitudeTest.title")
    }

    function getColumns() {
        return [
            {
                title: t("verification.cCode"),
                field: "cCode",
                style: {minWidth: "150px"}
            },
            {
                title: t("verification.commentText"),
                field: "commentText",
                style: {minWidth: "300px"}
            },
            {
                title: t("verification.task"),
                field: "task",
                style: {minWidth: "120px"}
            },
            {
                title: t("verification.isRequired"),
                field: "isRequiredHtml",
                headerStyle: {textAlign: "center"},
                style: {minWidth: "100px"}
            },
            {
                title: t("verification.approval"),
                field: "approval",
                headerStyle: {textAlign: "center", borderRight: "1px solid var(--black-200)"},
                style: {
                    minWidth: "300px",
                    borderLeft: "1px solid var(--black-200)",
                    borderRight: "1px solid var(--black-200)"
                }
            },
            {
                title: t("verification.upload"),
                field: "upload",
                style: {minWidth: "150px"}
            }
        ]
    }

    const getProfileCampusData = async () => {
        try {
            const {data} = await profileCampusService.getAll({
                filter: {
                    profileId: [props.model.profileId]
                },
                linkedObjects: true
            })

            if (data.length > 0) {
                let schoolCodes: string[] = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].schoolCodes) {
                        for (let j = 0; j < data[i].schoolCodes.length; j++) {
                            schoolCodes.push(data[i].schoolCodes[j].code)
                        }
                    }
                }

                setSchoolCodeData(schoolCodes)
            }
        } catch (e) {
            handleError(e)
        }
    }

    const getParams = () => {
        const {page, pageSize, orderField} = props
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {
                studentEmail
            },
            linkedEntities: true
        }
        if (!isEmpty(orderField)) {
            params.orderBy = orderField.field
            params.orderDir = orderField.order
        }
        return params
    }

    const getSystemData = async (filter) => {
        try {
            // fetch user data
            const {data} = await userServiceV3.getAll({
                filter: filter,
                range: {
                    limit: 1,
                    offset: 0
                },
                linkedObjects: true
            })

            if (data.length > 0) {
                let emails: string[] = []
                for (let i = 0; i < data[0].emails.length; i++) {
                    emails.push(data[0].emails[i].email)
                }
                setStudentEmail(emails)

                if (data[0].isISIRConfirmed) {
                    setReportStatus("locked")
                } else {
                    setReportStatus("unlocked")
                }
            }
        } catch (e) {
            handleError(e)
        }
    }

    const getListISIR = async ({emails}: Partial<{emails: string[]}>) => {
        // if (!schoolCodeData) {
        //     await getProfileCampusData()
        //     return false
        // }
        const params = getParams()
        if (emails?.length) {
            params.filter = {
                ...params.filter,
                studentEmail: emails
            }
        }
        if (schoolCodeData) {
            params.filter = {
                ...params.filter,
                schoolCode: schoolCodeData
            }
        }
        dispatch({isLoading: true})
        try {
            const {data: isirs} = await isirService.getList(params)
            const newISIR = isirs.map((isir) => {
                let isirJSON = JSON.parse(isir.isirJson)

                const isirDependencyStatus = DEPENDENCY_STATUS_OPTIONS.filter(
                    (el) => el.id === isirJSON.dependencyStatusData
                )

                setAwardYearCode(Number(isirJSON.awardYearCode))

                let newData: any = {
                    type: t("financialAid:financialAidStaff.isir"),
                    id: isir.id,
                    isirCode: isir.isirCode,
                    awardYear: isirJSON.awardYear || "-",
                    schoolCode: isirJSON.studentHighSchoolCode,
                    verficiation: "",
                    email: isir.studentEmail,
                    status: "",
                    isDefault: isir.isDefault,
                    isirData: isir,
                    isirName: "",
                    dateReceived: isir.createdAt || "-",
                    ombNumber: "-",
                    primaryEFC: isirJSON.primaryEFC || "-",
                    dependencyStatus: isirJSON.dependencyStatus || "-",
                    dependencyStatusData: isirDependencyStatus[0] ? isirDependencyStatus[0].name : "-",
                    dataCCode: isir.dataCCode
                }

                for (let i = 0; i < isir.dataCCode.length; i++) {
                    isir.dataCCode[i] = {
                        ...isir.dataCCode[i],
                        id: isir.dataCCode[i].id,
                        cCode: isir.dataCCode[i].cCode,
                        commentText: isir.dataCCode[i].commentText,
                        task: renderTaskHtml(isir.dataCCode[i]),
                        isRequiredHtml: renderIsRequiredHtml(isir.dataCCode[i]),
                        rowClassName: isir.dataCCode[i].isRequired ? undefined : styles.rowHighlighted,
                        approval: renderApprovalHtml(isir.dataCCode[i]),
                        upload: renderDocsHtml(isir.dataCCode[i])
                    }
                }

                return newData
            })
            dispatch({data: newISIR, total: newISIR.length})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const getListCCodeMaster = async () => {
        dispatch({isLoading: true})
        try {
            const {data} = await isirService.getListCCode({
                filter: {
                    awardYearCode
                }
            })
            setCCodeMaster(data)
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function selectCodeForTask(item) {
        setSelectedCodeForAction(item)
        setIsShowTaskPopup(true)
    }

    function renderTaskHtml(item, isDisabled?: boolean) {
        if (!item.taskId) {
            return (
                <div
                    className={cx(styles.review, {
                        [styles.disabledHtml]: isDisabled || reportStatus === "locked" || !item.isRequired
                    })}
                    onClick={
                        isDisabled || reportStatus === "locked" || !item.isRequired
                            ? () => {}
                            : () => selectCodeForTask(item)
                    }>
                    TASK ID
                </div>
            )
        } else {
            return <div className={styles.review}>{item.taskId}</div>
        }
    }

    const onChangeIsRequired = async (toggleCCodeItem, checked) => {
        try {
            await isirService.updateCcodeItemRequired(toggleCCodeItem.id, checked)
            const newData = getCurrentData().map((isir) => {
                if (isir.isirCode !== toggleCCodeItem.isirCode) return isir
                return {
                    ...isir,
                    dataCCode: isir.dataCCode.map((ccodeItem) => {
                        if (ccodeItem.id !== toggleCCodeItem.id) return ccodeItem
                        const isRequired = checked
                        const newItemData = {...ccodeItem, isRequired}
                        return {
                            ...newItemData,
                            task: renderTaskHtml(newItemData),
                            approval: renderApprovalHtml(newItemData),
                            upload: renderDocsHtml(newItemData),
                            isRequiredHtml: renderIsRequiredHtml(newItemData),
                            rowClassName: isRequired ? undefined : styles.rowHighlighted
                        }
                    })
                }
            })
            dispatch({data: newData})
        } catch (error) {
            handleError(error)
        }
    }

    function renderIsRequiredHtml(item, isDisabled?: boolean) {
        return (
            <div className={styles.isRequiredCheckbox}>
                <Checkbox
                    checked={item.isRequired}
                    disabled={!!isDisabled}
                    onChange={(event) => onChangeIsRequired(item, event.target.checked)}
                />
            </div>
        )
    }

    const onCloseTaskPopup = () => {
        setIsShowTaskPopup(false)
    }

    const onTaskSubmit = async (data) => {
        await isirService.updateStudentCCodeDocument(selectedCodeForAction.id, {taskId: data.id})
        onCloseTaskPopup()
        await getListISIR({})
    }

    function renderApprovalHtml(item, isDisabled?: boolean) {
        if (isDisabled || reportStatus === "locked" || !item.isRequired) {
            return (
                <div
                    className={cx(styles.review, {
                        [styles.disabledHtml]: isDisabled || reportStatus === "locked" || !item.isRequired
                    })}>
                    REVIEW
                </div>
            )
        }
        switch (item.approvalStatus) {
            case "pending":
                return (
                    <div className={styles.review} onClick={() => onClickReview(ReviewItemType.APPROVAL, item)}>
                        REVIEW
                    </div>
                )
            case "approved":
                return renderStatusApproved(item, "approvalSignature")
            case "rejected":
                return renderStatusRejected(item, "approvalSignature")
            default:
                return <></>
        }
    }

    function renderStatusApproved(item, key) {
        return (
            <div className={styles.statusWrap}>
                <div className={cx(styles.signatureWrap, styles.signature__approval)}>
                    <Icon icon="CHECKMARK_CIRCLE" className={styles.signature__statusIcon} color="#18A957" />
                    <img src={item[key].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>{moment(item[key].updatedAt).format("DD/MM/YYYY HH:mm ")}</p>
                    <p className={styles.dateInfo__text}>{getFullName(item[key])}</p>
                </div>
            </div>
        )
    }

    function renderStatusRejected(item, key) {
        return (
            <div className={styles.statusWrap}>
                <div className={cx(styles.signatureWrap, styles.signature__reject)}>
                    <Icon icon="CROSS_CIRCLE" className={styles.signature__statusIcon} color="#DF1642" />
                    <img src={item[key].imageUrl} alt="" className={styles.signature__img} />
                </div>
                <div className={styles.dateInfo}>
                    <p className={styles.dateInfo__text}>{moment(item[key].updatedAt).format("DD/MM/YYYY HH:mm ")}</p>
                    <p className={styles.dateInfo__text}>{getFullName(item[key])}</p>
                </div>
            </div>
        )
    }

    function onClickReview(type, item) {
        signaturePopup.open()
        dispatchState({reviewItem: item, reviewType: type})
    }

    function getKeyFromType() {
        return "approvalStatus"
    }

    async function onClickReject() {
        if (isRejecting || isApproving) {
            return
        }
        const data = {
            id: reviewItem.id
        }
        const key = getKeyFromType()
        data[key] = "rejected"
        try {
            dispatchState({isRejecting: true})
            await isirService.updateStudentApprovalStatus(data)
            dispatchState({reviewItem: null})
            signaturePopup.close()
            await getListISIR({})
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isRejecting: false})
        }
    }

    async function onClickApprove() {
        if (isRejecting || isApproving) {
            return
        }
        const data = {
            id: reviewItem.id
        }
        const key = getKeyFromType()
        data[key] = "approved"
        try {
            dispatchState({isApproving: true})
            await isirService.updateStudentApprovalStatus(data)
            dispatchState({reviewItem: null})
            signaturePopup.close()
            await getListISIR({})
        } catch (error) {
            handleError(error)
        } finally {
            dispatchState({isApproving: false})
        }
    }

    function renderDocsHtml(item, isDisabled?: boolean) {
        if (item.document) {
            return (
                <div className={styles.fileName} onClick={() => onOpenDocument(item.document)}>
                    {item.document.name}
                </div>
            )
        }
        return (
            <div
                className={cx(styles.upload, {
                    [styles.disabledHtml]: isDisabled || reportStatus === "locked" || !item.isRequired
                })}
                onClick={
                    isDisabled || reportStatus === "locked" || !item.isRequired ? () => {} : () => onClickUpload(item)
                }>
                UPLOAD
            </div>
        )
    }

    function onOpenDocument(document) {
        window.open(document.documentUrl)
    }

    function onClickUpload(item) {
        const {pathname, search} = history.location
        const backUrl = `${pathname}${search}`
        const submitDataAfterSuccessCCode = {id: item.id}
        history.push({
            pathname: "/documents/detail",
            search: `?userProfileId=${+studentId}&tab=6&backUrl=${backUrl}`,
            state: {
                submitDataAfterSuccessCCode,
                backUrl
            }
        })
    }

    const renderSelectInput = (valueKey, placeholder, optionsList, value?) => {
        let fetchSelectedValue = ""
        if (value && value !== null) {
            fetchSelectedValue = value
        } else if (newItemData && newItemData !== null) {
            fetchSelectedValue = newItemData[valueKey]
        }

        return (
            <KlassappTableDropdown
                placeholder={placeholder}
                onChange={(value) => {
                    setNewItemChangeValue(value)
                    setChangeValueKey(valueKey)
                    setChangeValue(value.cCode)
                }}
                options={optionsList}
                value={optionsList.filter((el) => {
                    return el.cCode === fetchSelectedValue
                })}
                valueKey="cCode"
                labelKey="cCode"
            />
        )
    }

    const onClickAddItem = (index) => {
        const newItem = {
            id: "new-item",
            cCode: renderSelectInput("cCodeData", t("verification.placeholder.cCode"), cCodeMaster),
            commentText: "",
            task: "",
            approval: "",
            upload: ""
        }

        setNewItemData(newItem)
        setNewItemDataIndex(index)

        let {data} = props
        data[index].dataCCode.push(newItem)

        dispatch({data})
    }

    const onClickCancel = () => {
        let {data} = props
        data[newItemDataIndex].dataCCode.pop()

        setNewItemData(null)
        setNewItemDataIndex(null)
        props.dispatch({data})
    }

    const onClickSave = async () => {
        const {cCodeData} = newItemData

        // map ccode with student
        const data = {
            isirCode: props.data[newItemDataIndex].isirCode,
            cCodeIds: [cCodeData]
        }
        await isirService.createStudentCCodeDocument(data)

        setNewItemData(null)
        setNewItemDataIndex(null)
        getListISIR({})
    }

    const redirectISIR = async (isirCode: string) => {
        if (process.env.REACT_APP_ISIR_WEB_BASE_URL as string) {
            const url = new URL(`/`, process.env.REACT_APP_ISIR_WEB_BASE_URL as string)
            url.searchParams.append("isirCode", isirCode)
            url.searchParams.append("xProfileId", props.model.profileId.toString())
            url.searchParams.append("token", props.model.authToken)
            url.searchParams.append("pdf", "")

            window.open(url.href, "_blank")
        } else {
            handleErrorMessage("Cannot open ISIR PDF. Please try again later.")
        }
    }

    const [selectedAwardYear, setSelectedAwardYear] = useState<number | undefined>()
    const [selectedTerm, setSelectedTerm] = useState<TermDetails | undefined>()
    const [selectedVerificationStatus, setSelectedVerificationStatus] = useState<VerificationStatus | undefined>()
    const [faAwardYears, setFaAwardYears] = React.useState<FinAidAwardYear[]>([])
    const [faStudentTerms, setFaStudentTerms] = React.useState<FinAidStudentTerm[]>([])

    const {verificationStatuses} = useAllVerificationStatuses()

    const faStatusByQuery = useQuery(
        ["fa-status-by"],
        async () => {
            const [setting] = await generalSettingsService.getSettings([GeneralSettingField.FaStatusBy])
            return setting?.value ?? FAStatusBy.AwardYear
        },
        {
            initialData: FAStatusBy.AwardYear
        }
    )

    const handleVerificationStatusChange = useCallback(
        ({
            awardYear,
            termId,
            verificationStatusId
        }: {
            awardYear?: number
            termId?: number
            verificationStatusId: number
        }) => {
            if (awardYear) {
                const newFaAwardYears = uniqBy(
                    [
                        {
                            profileId: studentId,
                            awardYear,
                            verificationStatusId
                        },
                        ...faAwardYears
                    ],
                    "awardYear"
                )
                setFaAwardYears(newFaAwardYears)
                finAidService.updateStudentAwardYears(studentId, newFaAwardYears).catch(handleError)
            }
            if (termId) {
                const newFaStudentTerms = uniqBy(
                    [
                        {
                            profileId: studentId,
                            termId,
                            verificationStatusId
                        },
                        ...faStudentTerms
                    ],
                    "termId"
                )
                setFaStudentTerms(newFaStudentTerms)
                finAidService
                    .updateStudentTerms(
                        studentId,
                        newFaStudentTerms.map((item) => omit(item, ["term"]))
                    )
                    .catch(handleError)
            }
        },
        [studentId, faAwardYears, faStudentTerms]
    )

    useEffect(() => {
        if (faStatusByQuery.data === FAStatusBy.AwardYear) {
            ;(async function loadAwardYears() {
                try {
                    const awardYears = await finAidService.getStudentAwardYears(studentId)
                    setFaAwardYears(awardYears)
                } catch {}
            })()
        } else {
            ;(async function loadStudentTerms() {
                try {
                    const studentTerms = await finAidService.getStudentTerms(studentId)
                    setFaStudentTerms(studentTerms)
                } catch {}
            })()
        }
    }, [studentId, faStatusByQuery.data])

    useEffect(() => {
        setSelectedVerificationStatus(
            verificationStatuses.find(
                (status) =>
                    status.statusId ===
                    faAwardYears.find(({awardYear}) => awardYear === selectedAwardYear)?.verificationStatusId
            ) ?? null
        )
    }, [verificationStatuses, faAwardYears, selectedAwardYear])

    useEffect(() => {
        setSelectedVerificationStatus(
            verificationStatuses.find(
                (status) =>
                    status.statusId ===
                    faStudentTerms.find(({termId}) => termId === selectedTerm?.id)?.verificationStatusId
            ) ?? null
        )
    }, [verificationStatuses, faStudentTerms, selectedTerm])

    return (
        <div>
            <Row gutter={24}>
                {faStatusByQuery.data === FAStatusBy.AwardYear ? (
                    <Col span={8}>
                        <FormLabel label="Award Year" />
                        <KlassDropdown
                            isClearable
                            options={yearOptions}
                            value={yearOptions.find((option) => option.id === selectedAwardYear)}
                            onChange={(option) => setSelectedAwardYear(option?.id)}
                        />
                    </Col>
                ) : (
                    <Col span={8}>
                        <FormLabel label="Term" />
                        <TermSelect isClearable value={selectedTerm} onChange={setSelectedTerm} />
                    </Col>
                )}
                <Col span={8}>
                    <FormLabel label="Verification Status" />
                    <KlassDropdown
                        readOnly={!selectedAwardYear && !selectedTerm}
                        isClearable={false}
                        valueKey="statusId"
                        labelKey="name"
                        options={verificationStatuses}
                        value={selectedVerificationStatus}
                        onChange={(status: VerificationStatus) =>
                            handleVerificationStatusChange({
                                awardYear:
                                    faStatusByQuery.data === FAStatusBy.AwardYear ? selectedAwardYear : undefined,
                                termId: faStatusByQuery.data === FAStatusBy.Term ? selectedTerm?.id : undefined,
                                verificationStatusId: status?.statusId
                            })
                        }
                    />
                </Col>
            </Row>

            {!data.length && <Empty className="mt-32" description="There is no ISIR documents yet." />}

            {data.map((item, index) => (
                <div key={index} className={styles.verificationCard}>
                    <div className={styles.header}>
                        <div className={styles.isirName}>
                            <span>ISIR [{item.isirCode}]</span>
                        </div>
                        <Button
                            onClick={() => redirectISIR(item.isirCode)}
                            shape="circle"
                            className={styles.fileButton}
                            icon={<Icon icon="FILE_TEXT" />}
                        />
                    </div>
                    <Row gutter={16} className={styles.cardsWrapper}>
                        <Col span={4}>
                            <div className={styles.cards}>
                                <p>{t("verification.date")}</p>
                                <span className={styles.cardDetail}>
                                    {item.dateReceived ? moment(item.dateReceived).format("MM/DD/YYYY") : ""}
                                </span>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className={styles.cards}>
                                <p>{t("verification.awardYear")}</p>
                                <span className={styles.cardDetail}>{item.awardYear}</span>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className={styles.cards}>
                                <p>{t("verification.efcNumber")}</p>
                                <span className={styles.cardDetail}>{item.primaryEFC}</span>
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className={styles.cards}>
                                <p>{t("verification.dependency")}</p>
                                <span className={styles.cardDetail}>{item.dependencyStatusData}</span>
                            </div>
                        </Col>
                    </Row>

                    <KlassappTableHeader
                        isShowAction={isShowTableHeaderAction}
                        actions={tableHeaderActions}
                        page={page}
                        total={item.dataCCode.length}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onChangeAllFields={props.onChangeAllFields}
                        onDraggableColumn={props.onDraggableColumn}
                    />
                    <KlassappTable
                        columns={columns}
                        fields={fields}
                        data={item.dataCCode}
                        isLoading={isLoading}
                        menuActions={[]}
                        allFields={allFields}
                        onClickRowItem={() => {}}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                        onDraggableColumn={props.onDraggableColumn}
                        onChangeAllFields={props.onChangeAllFields}
                    />
                    {reportStatus !== "locked" ? (
                        !newItemData ? (
                            <AddItemCircleButton onClick={() => onClickAddItem(index)} />
                        ) : newItemDataIndex === index ? (
                            <div className={styles.buttonWrap}>
                                <SecondaryButton
                                    title={props.t("common:action.cancel")}
                                    onClick={onClickCancel}
                                    className={styles.cancelBtn}
                                />
                                <BaseButton
                                    title={props.t("common:action.save").toUpperCase()}
                                    onClick={onClickSave}
                                    className={styles.saveBtn}
                                />
                            </div>
                        ) : (
                            ""
                        )
                    ) : (
                        ""
                    )}
                    <SignaturePopup
                        isShow={signaturePopup.isVisible}
                        onClose={() => signaturePopup.close()}
                        title="Please Review Item">
                        <div className={styles.divider}></div>
                        <div className={styles.action}>
                            <Button
                                className={cx(styles.actionBtn, styles.rejectBtn)}
                                onClick={onClickReject}
                                loading={isRejecting}>
                                <span>{t("common:action.reject")}</span>
                                <Icon icon="CROSS_CIRCLE" color="#df1642" className={styles.action__icon} />
                            </Button>
                            <Button
                                className={cx(styles.actionBtn, styles.approveBtn)}
                                onClick={onClickApprove}
                                loading={isApproving}>
                                <span>{t("common:action.approve")}</span>
                                <Icon icon="CHECKMARK_CIRCLE_1" color="#18a957" className={styles.action__icon} />
                            </Button>
                        </div>
                    </SignaturePopup>
                    <BasePopup isShow={isShowTaskPopup} onClose={onCloseTaskPopup}>
                        <div className={styles.wrap}>
                            <p className={styles.title}>Select Task</p>
                            <p className={styles.taskPopupSubtitle}>LINK TASK TO THE ISIR ITEM</p>
                            <p className={styles.taskPopupCCode}>
                                <span className={styles.taskPopupCode}>{selectedCodeForAction?.cCode}</span>{" "}
                                {selectedCodeForAction?.commentText}
                            </p>
                            <TaskList
                                filter={filter}
                                isStaff
                                onClickCancel={onCloseTaskPopup}
                                onTaskSubmit={onTaskSubmit}
                                {...props}
                            />
                        </div>
                    </BasePopup>
                </div>
            ))}
        </div>
    )
}

export default KlassappTableHOC(Verification)
