import React, {useEffect, useState} from "react"
import {Row, Col} from "antd"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import styles from "./AcademicAdvisorPopup.module.css"
import {studentStaffContactsServiceV3} from "services"
import {getFullName, getUserPhotoUrl, handleError} from "helpers"
import {MAP_DEPARTMENT_ADVISOR_ROLE} from "sections/users/Detail/parts/Directory/parts"
import {BaseDepartmentId} from "types/departments"
import {BaseLoading} from "components"
import {PopupMessageSenderV2} from "sections/NewCommunication/shared"

type AcademicAdvisorPopupProps = {
    studentProfileId: number
    isShow: boolean
    onClose: () => void
}
export function AcademicAdvisorPopup(props: AcademicAdvisorPopupProps) {
    const {studentProfileId} = props
    const [advisor, setAdvisor] = useState<{
        contactProfile: {
            phone: string
            email: string
            firstName: string
            lastName: string
            address: string
        }
        avatar?: string
    }>()
    const {isShow, onClose} = props
    const [isLoading, setIsLoading] = useState(false)

    const getAdvisor = async () => {
        setIsLoading(true)
        try {
            const {data: studentStaffContacts} = await studentStaffContactsServiceV3.getByProfile([studentProfileId])
            const advisorInfo = studentStaffContacts.find(
                (item) => item.studentStaffContactRoleId === MAP_DEPARTMENT_ADVISOR_ROLE[BaseDepartmentId.Academics]
            )
            if (advisorInfo?.contactProfile?.photo?.length) {
                advisorInfo.contactProfile.photo = JSON.parse(advisorInfo.contactProfile.photo)
            }
            const avatarUrl = advisorInfo?.contactProfile?.photo
                ? getUserPhotoUrl(advisorInfo.contactProfile?.photo, 512)
                : "/image/DefaultAvatar.png"
            setAdvisor({
                ...advisorInfo,
                avatar: avatarUrl
            })
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isShow && studentProfileId) {
            getAdvisor()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, studentProfileId])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="CONTACT_1" leftIconColor="#fff" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.headerTitle}>{advisor || isLoading ? "Academic Advisor" : "Empty"}</p>
                {advisor ? (
                    <>
                        <div className={styles.body}>
                            <Row gutter={[42, 0]}>
                                <Col span={6}>
                                    <div className={styles.avatarWrap}>
                                        <img src={advisor.avatar} className={styles.avatar} alt="" />
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <div>
                                        <p className={styles.advisorName}>
                                            {getFullName(advisor?.contactProfile ?? "N/A")}
                                        </p>
                                        <div className={styles.infoWrap}>
                                            <div className={styles.studentPhoneEmail}>
                                                <div className={styles.studentPhoneWrap}>
                                                    <div className={styles.studentPhoneIconWrap}>
                                                        <Icon icon="PHONE" className={styles.studentPhoneIcon} />
                                                    </div>
                                                    <div className={styles.studentPhoneInfo}>
                                                        <p className={styles.studentPhone__value}>
                                                            {advisor?.contactProfile?.phone ?? "N/A"}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={styles.studentEmailWrap}>
                                                    <div className={styles.studentPhoneIconWrap}>
                                                        <Icon icon="EMAIL" className={styles.studentPhoneIcon} />
                                                    </div>
                                                    <div className={styles.studentPhoneInfo}>
                                                        <p className={styles.studentPhone__value}>
                                                            {advisor?.contactProfile?.email ?? "N/A"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.studentAddress}>
                                                <div className={styles.studentPhoneIconWrap}>
                                                    <Icon icon="LOCATION_FILL" className={styles.studentPhoneIcon} />
                                                </div>
                                                <div className={styles.studentPhoneInfo}>
                                                    <p className={styles.studentPhone__value}>
                                                        {advisor?.contactProfile?.address ?? "N/A"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.messageBtnWrap}>
                            {advisor ? (
                                <PopupMessageSenderV2
                                    renderButton={({onClick}) => {
                                        return <BaseButton onClick={onClick} title="Message" variant="secondary" />
                                    }}
                                    user={advisor.contactProfile}
                                    userIdField="contactUserId"
                                />
                            ) : null}
                        </div>
                    </>
                ) : null}
            </div>
            <BaseLoading isShow={isLoading} />
        </BasePopup>
    )
}
