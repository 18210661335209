/* eslint-disable react-hooks/exhaustive-deps */
import cx from "classnames"
import {Icon} from "components/Icon"
import React, {useEffect, useState} from "react"
import styles from "./MediaViewer.module.css"
import {Tooltip} from "antd"
import {useTranslation} from "react-i18next"
import {Communication} from "types/communication"
import ReactPlayer from "react-player/lazy"

type Props = {
    isShow: boolean
    onClose: () => void
    file: Communication.AttachedFile
}

export function MediaViewer(props: Props) {
    const {isShow, onClose, file} = props
    const {t} = useTranslation(["chat", "common"])
    const [isPlaying, setPlaying] = useState(false)

    const handleClose = () => {
        setPlaying(false)
        onClose()
    }

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                handleClose()
            }
        }
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [onClose])

    const onClickFile = (e) => {
        e.stopPropagation()
    }

    const openInBrowser = () => {
        const url = file.url
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.target = "_blank"
        a.download = file.filename
        a.click()
    }

    return (
        <div className={cx(styles.root, {[styles.rootIsOpen]: isShow})} onClick={handleClose}>
            <div className={styles.actionsContainer}>
                <Tooltip placement="bottomRight" title={t("chat.openInBrowser")}>
                    <button onClick={openInBrowser} className={styles.iconContainer}>
                        <Icon icon="ICON_EXPEND" color="var(--whitebase)" />
                    </button>
                </Tooltip>
                <Tooltip placement="bottomRight" title={t("common:action.close")}>
                    <button onClick={onClose} className={styles.iconContainer}>
                        <Icon icon="CLOSE" color="var(--whitebase)" />
                    </button>
                </Tooltip>
            </div>
            {file && (
                <div className={styles.mediaContainer} onClick={onClickFile}>
                    {file.type === Communication.AttachedFileType.Image && (
                        <img src={file.url} alt={file.filename} className={styles.image} />
                    )}
                    {file.type === Communication.AttachedFileType.Video && (
                        <ReactPlayer
                            url={file.url}
                            controls
                            className={styles.video}
                            playing={isPlaying}
                            onPlay={() => setPlaying(true)}
                            onPause={() => setPlaying(false)}
                        />
                    )}
                </div>
            )}
        </div>
    )
}
