import React, {useReducer, useState} from "react"
import {useTranslation} from "react-i18next"
import {Row, Col} from "antd"
import {cloneDeep, groupBy, isEmpty, omit, pick} from "lodash"
import moment from "moment"
import debounce from "debounce-promise"
import {BaseFilter} from "uiKit"
import {KlassDropAsyncPaginate} from "components/Select"
import {BaseRangePicker} from "components/DateTimePicker"
import {FormLabel} from "components/Form"
import {BaseButton, SecondaryButton} from "components/buttons"
import {userServiceV3, campusesService, majorService, termsService, groupsService} from "services"
import {getFullName, handleError, toastError} from "helpers"
import {Auth} from "types/auth"
import {ParticipantType} from "types/tasks"
import {PrimaryDescription} from "../Common"
import styles from "./StudentSignerSelecting.module.css"
import {getFirstProfile} from "helpers/task"
import DegreeLevelSelect from "components/DegreeLevelSelect"
import {NewStudentStatusSelectFilter} from "components/NewStudentStatusSelect"
import {BaseInput, KlassDropdown} from "components"
import {ENROLLMENT_STATUS_OPTIONS} from "types/students"
import {useAllEnrollmentTypes, useVisible} from "hooks"
import {useCreateNewStudent} from "./useCreateNewStudent"

function reducer(state, action) {
    return {...state, ...action}
}

const allowedProfileFields = [
    "id",
    "profileId",
    "userId",
    "firstName",
    "middleName",
    "lastName",
    "fullName",
    "name",
    "photo",
    "emails",
    "phones",
    "campus",
    "campuses",
    "currentMajor",
    "degreeLevel",
    "currentTerm",
    "title",
    "type",
    "state",
    "applicantId",
    "customProfileId",
    "customUserId",
    "active"
]

export function StudentSignerSelecting(props) {
    const {signer, onCancelSelecting, onSaveSelecting, onUpdateSignerItem} = props
    const initialState = {
        filter: {
            startDateRange: null,
            degreeLevel: null,
            startingTerms: [],
            groups: [],
            studentStatusIds: [],
            departments: [],
            campuses: [],
            majors: []
        },
        renderKey: 1
    }
    const [{filter, renderKey}, dispatch] = useReducer(reducer, initialState)
    const {t} = useTranslation(["tasks", "common", "user"])
    const [totalStudent, setTotalStudent] = useState(0)
    const [isSelectingAll, setIsSelectingAll] = useState(false)
    const [isLoadingTotal, setIsLoadingTotal] = useState(false)
    const [currentFilter, setCurrentFilter] = useState({...initialState.filter})
    const formAddNewStudent = useVisible()
    const newStudent = useCreateNewStudent()
    const {enrollmentTypes} = useAllEnrollmentTypes()

    const getParams = () => {
        const studentStatusIds = (currentFilter.studentStatusIds || []).map((status) => status.statusId)
        const campusIds = (currentFilter.campuses || []).map((campus) => campus.id)
        const majorIds = (currentFilter.majors || []).map((major) => major.id)
        const params: any = {
            filter: {
                type: [Auth.UserProfileType.Student],
                active: true,
                isAccessDisabled: false
            },
            linkedObjects: true
        }
        if (studentStatusIds.length) {
            params.filter.studentStatusIds = studentStatusIds
        }
        if (campusIds.length) {
            params.filter.campus = campusIds
        }
        if (majorIds.length) {
            params.filter.major = majorIds
        }
        if (currentFilter.startDateRange) {
            const [startDate, endDate] = currentFilter.startDateRange
            params.filter.startDateRange = [
                moment(startDate).format("YYYY-MM-DD"),
                moment(endDate).format("YYYY-MM-DD")
            ]
        }
        if (currentFilter.degreeLevel) {
            params.filter.degreeLevel = [currentFilter.degreeLevel.degreeLevelId]
        }
        if (!isEmpty(currentFilter.startingTerms)) {
            params.filter.startingTermIds = currentFilter.startingTerms.map((item) => item.id)
        }
        if (!isEmpty(currentFilter.groups)) {
            params.filter.groupIds = currentFilter.groups.map((item) => item.groupId)
        }
        return params
    }

    const getStudents = async (search = "", loadedOptions) => {
        try {
            setIsLoadingTotal(true)
            const params = getParams()
            params.filter.search = search
            params.range = {
                limit: 20,
                offset: loadedOptions.length
            }
            const {data: students, total} = await userServiceV3.getAll(params)
            setTotalStudent(total)
            return {
                options: students
                    .map((item) => {
                        return {
                            ...pick(item, allowedProfileFields),
                            profileId: item.profiles?.[0]?.id || "",
                            profiles: item.profiles?.map((profile) => pick(profile, allowedProfileFields)),
                            activeProfile: item.activeProfile && pick(item.activeProfile, allowedProfileFields)
                        }
                    })
                    .filter((item) => item.profileId),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        } finally {
            setIsLoadingTotal(false)
        }
    }

    const getCampuses = async (search = "", loadedOptions) => {
        try {
            const {data: campuses, total} = await campusesService.getAll({
                fields: ["id", "name"],
                filter: {
                    search
                },
                range: {
                    limit: 20,
                    offset: loadedOptions.length
                }
            })
            return {
                options: campuses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return []
        }
    }

    const getMajors = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: majors, total} = await majorService.getAll({
                range: {
                    page,
                    pageSize
                },
                filter: {
                    search,
                    isActive: true,
                    campusIds: newStudent.data.campus ? [newStudent.data.campus.id] : undefined
                },
                sort: {
                    orderBy: "id",
                    orderDir: "DESC"
                }
            })
            return {
                options: majors,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getGroups = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: groups, total} = await groupsService.getAll({
                range: {
                    page,
                    pageSize
                },
                sort: {
                    orderBy: "createdAt",
                    orderDir: "DESC"
                },
                filter: {
                    search
                }
            })
            return {
                options: groups,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const searchTerms = async (search = "", loadedOptions) => {
        try {
            const {data, total} = await termsService.getAllTerms({
                limit: 20,
                offset: loadedOptions.length,
                text: search
            })
            const terms = data.map(({id, name, start_date}) => ({
                id,
                name: `${name} (${moment.utc(start_date).format("MM/DD/YYYY")})`
            }))
            return {
                options: terms,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const getStudentLabel = (option) => {
        const groupedProfile = groupBy(option.profiles, "customProfileId") || {}
        const filteredProfile = Object.values(groupedProfile).map((profile) => {
            if (profile.length >= 2) {
                return profile.filter(({state}) => state === Auth.StudentStateType.Student)
            }
            return profile
        })
        const filteredOptions = filteredProfile.flatMap((profile) => profile)
        const customProfileIds =
            filteredOptions
                ?.filter((profile) => profile.active)
                ?.map((profile) => profile.customProfileId)
                ?.filter(Boolean) || []
        return (
            <div className={styles.studentOption}>
                <div>{getFullName(option)}</div>
                {customProfileIds.length > 0 && (
                    <div className={styles.studentOptionEnrollmentsContainer}>
                        {customProfileIds.map((customProfileId, index) => {
                            return (
                                <span
                                    key={`${customProfileId}-${index}`}
                                    className={styles.studentOptionEnrollmentItem}>
                                    {`[${customProfileId}]`}{" "}
                                    {getCampusesByCustomProfileId(option.profiles, customProfileId)}
                                </span>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }

    const getCampusesByCustomProfileId = (profiles: any[], customProfileId: string): string | null => {
        return profiles.find((profile) => profile.customProfileId === customProfileId)?.campus || ""
    }

    const onClickApplyFilter = () => {
        const newRenderKey = renderKey + 1
        setCurrentFilter(cloneDeep(filter))
        dispatch({renderKey: newRenderKey})
    }

    const onClickSave = () => {
        if (!signer.students) {
            toastError(t("common:validation.hasToBeSelect", {field: "Student"}))
            return
        }
        if (
            signer.participantType === ParticipantType.PrimarySigner ||
            signer.participantType === ParticipantType.UserDataOnly
        ) {
            signer.students = signer.students.map((student) => {
                const activeProfile = getFirstProfile(student, Auth.UserProfileType.Student)
                return {
                    ...student,
                    profileId: activeProfile?.id,
                    activeProfile,
                    activeTerm: activeProfile.currentTerm
                }
            })
            onSaveSelecting(signer)
        } else {
            let students = Array.isArray(signer.students) ? signer.students : [signer.students]
            students = students.map((student) => {
                const activeProfile = getFirstProfile(student, Auth.UserProfileType.Student)
                return {
                    ...student,
                    profileId: activeProfile?.id,
                    activeProfile,
                    activeTerm: activeProfile?.currentTerm
                }
            })
            onSaveSelecting({...signer, students})
        }
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...filter, [key]: value}
        dispatch({filter: newFilter})
    }

    const onChangeUsers = (users) => {
        onUpdateSignerItem(signer.id, {...signer, students: users || []})
    }

    const handleCreateNewStudent = async () => {
        const messageError = await newStudent.validate()
        if (messageError) {
            toastError(messageError)

            return
        }
        const createdStudent = await newStudent.create()
        const activeProfile = getFirstProfile(createdStudent, Auth.UserProfileType.Student)
        if (!activeProfile) {
            throw new Error(`New student profile doesn't exist`)
        }
        const newStudentSigner = {
            ...createdStudent,
            ...pick(createdStudent, allowedProfileFields),
            profileId: activeProfile.id,
            profiles: createdStudent.profiles.map((profile) => pick(profile, allowedProfileFields)),
            activeProfile: pick(activeProfile, allowedProfileFields),
            activeTerm: activeProfile.currentTerm
        }
        onSaveSelecting({...signer, students: [newStudentSigner]})
    }

    const onClickSelectAll = async () => {
        try {
            setIsSelectingAll(true)
            const params = getParams()
            params.range = {
                limit: totalStudent,
                offset: 0
            }
            const {data: students} = await userServiceV3.getAll(params)
            onUpdateSignerItem(signer.id, {...signer, students: students})
        } catch (error) {
            handleError(error)
        } finally {
            setIsSelectingAll(false)
        }
    }

    const getStudentsDebounced = debounce(getStudents, 800)
    const getCampusesDebounced = debounce(getCampuses, 800)
    const getMajorsDebounced = debounce(getMajors, 800)
    const getGroupsDebounced = debounce(getGroups, 800)
    const searchTermsDebounced = debounce(searchTerms, 300)

    const params = getParams()

    const isShowSelectAll = !isEmpty(omit(params.filter, ["type", "isAccessDisabled", "active"]))

    const renderContent = () => {
        if (formAddNewStudent.isVisible) {
            return (
                <div className={styles.newStudentWrap}>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <div>
                                <FormLabel label={t("user:user.firstName")} isRequired />
                                <BaseInput
                                    value={newStudent.data.firstName}
                                    onChange={(newValue) => newStudent.set({firstName: newValue})}
                                    placeholder={t("user:user.firstName")}
                                    error={newStudent.errors.firstName}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label={t("user:user.lastName")} isRequired />
                                <BaseInput
                                    value={newStudent.data.lastName}
                                    onChange={(newValue) => newStudent.set({lastName: newValue})}
                                    placeholder={t("user:user.lastName")}
                                    error={newStudent.errors.lastName}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label={t("user:user.email")} isRequired />
                                <BaseInput
                                    value={newStudent.data.email}
                                    onChange={(newValue) => newStudent.set({email: newValue})}
                                    placeholder={t("user:user.email")}
                                    error={newStudent.errors.email}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label={t("user:user.phone")} />
                                <BaseInput
                                    value={newStudent.data.phone}
                                    onChange={(newValue) => newStudent.set({phone: newValue})}
                                    placeholder={t("user:user.phone")}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label={t("user:user.enrollmentType")} isRequired />
                                <KlassDropdown
                                    onChange={(newValue) => newStudent.set({enrollmentType: newValue})}
                                    options={enrollmentTypes || []}
                                    value={newStudent.data.enrollmentType}
                                    error={newStudent.errors.enrollmentType}
                                    isClearable
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label={t("user:user.enrollmentStatus")} isRequired />
                                <KlassDropdown
                                    onChange={(newValue) => newStudent.set({...newStudent, enrollmentStatus: newValue})}
                                    options={ENROLLMENT_STATUS_OPTIONS}
                                    value={newStudent.data.enrollmentStatus}
                                    error={newStudent.errors.enrollmentStatus}
                                    isClearable
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label={t("user:user.campus")} isRequired />
                                <KlassDropAsyncPaginate
                                    value={newStudent.data.campus}
                                    onChange={(newValue) => newStudent.set({campus: newValue, currentMajor: null})}
                                    loadOptions={getCampusesDebounced}
                                    error={newStudent.errors.campus}
                                    placeholder={t("common:selectField.placeholder")}
                                    isClearable
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label={t("user:user.currentMajor")} isRequired />
                                <KlassDropAsyncPaginate
                                    /**
                                     * NOTE
                                     * When "program" gets changed — clear the "campus" dropdown.
                                     * Campuses are connected to programs — the newly selected program
                                     * might not be available for the previously selected campus
                                     * so users have to select "campus" again from the list of available campuses
                                     */
                                    key={JSON.stringify(newStudent.data.campus)}
                                    value={newStudent.data.currentMajor}
                                    onChange={(newValue) => newStudent.set({currentMajor: newValue})}
                                    loadOptions={getMajorsDebounced}
                                    getOptionLabel={(option) => `${option.name} (${option.code})`}
                                    error={newStudent.errors.currentMajor}
                                    placeholder={t("common:selectField.placeholder")}
                                    isClearable
                                />
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <div className={styles.actionBtnWrap}>
                            <SecondaryButton
                                title={t("common:action.cancel")}
                                className={styles.cancelBtn}
                                onClick={formAddNewStudent.close}
                            />
                            <BaseButton
                                title={t("common:action.create")}
                                loading={newStudent.isCreating}
                                onClick={handleCreateNewStudent}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <>
                {signer.participantType === ParticipantType.PrimarySigner ? (
                    <BaseFilter className={styles.filterWrap} onClick={onClickApplyFilter} filter={filter}>
                        <Row gutter={[24, 24]}>
                            <Col span={12}>
                                <div>
                                    <FormLabel label="Campus" />
                                    <KlassDropAsyncPaginate
                                        value={filter.campuses}
                                        onChange={(newValue) => onChangeFilter("campuses", newValue)}
                                        loadOptions={getCampusesDebounced}
                                        isMulti
                                        placeholder={t("common:selectField.placeholder")}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <FormLabel label="Major" />
                                    <KlassDropAsyncPaginate
                                        value={filter.majors}
                                        onChange={(newValue) => onChangeFilter("majors", newValue)}
                                        loadOptions={getMajorsDebounced}
                                        getOptionLabel={(option) => `${option.name} (${option.code})`}
                                        isMulti
                                        placeholder={t("common:selectField.placeholder")}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <FormLabel label="Groups" />
                                    <KlassDropAsyncPaginate
                                        value={filter.groups}
                                        onChange={(newValue) => onChangeFilter("groups", newValue)}
                                        loadOptions={getGroupsDebounced}
                                        valueKey="groupId"
                                        isMulti
                                        placeholder={t("common:selectField.placeholder")}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <FormLabel label="Status" />
                                    <NewStudentStatusSelectFilter
                                        isMulti
                                        onChange={(newValue) => onChangeFilter("studentStatusIds", newValue)}
                                        value={filter.studentStatusIds}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <FormLabel label="Start Date" />
                                    <BaseRangePicker
                                        placeholder={["Student Start Date From", "Student Start Date To"]}
                                        value={filter.startDateRange}
                                        onChange={(newValue) => onChangeFilter("startDateRange", newValue)}
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <FormLabel label="Starting Term" />
                                    <KlassDropAsyncPaginate
                                        loadOptions={searchTermsDebounced}
                                        onChange={(selectedValues) => onChangeFilter("startingTerms", selectedValues)}
                                        placeholder="Starting Term"
                                        value={filter.startingTerms}
                                        isMulti
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <FormLabel label="Degree Level" />
                                    <DegreeLevelSelect
                                        value={filter.degreeLevel}
                                        onChange={(option) => onChangeFilter("degreeLevel", option)}
                                        isClearable
                                    />
                                </div>
                            </Col>
                        </Row>
                    </BaseFilter>
                ) : (
                    <div className={styles.filterWrap}></div>
                )}
                <div className={styles.selectStudentWrap}>
                    <FormLabel label={`Select Student ${isLoadingTotal ? "" : `(${totalStudent})`}`} />
                    {isShowSelectAll && (
                        <BaseButton
                            variant="secondary"
                            title="Select All"
                            loading={isSelectingAll || isLoadingTotal}
                            className={styles.selectAll}
                            disabled={totalStudent === 0}
                            onClick={onClickSelectAll}
                        />
                    )}
                    <BaseButton
                        variant="secondary"
                        title="Add new student"
                        disabled={isSelectingAll}
                        className={styles.selectAll}
                        onClick={formAddNewStudent.open}
                    />
                </div>
                <div key={renderKey}>
                    <KlassDropAsyncPaginate
                        cacheOptions={false}
                        value={signer.students}
                        onChange={onChangeUsers}
                        formatOptionLabel={(option: any) => getStudentLabel(option)}
                        loadOptions={getStudentsDebounced}
                        isMulti={
                            signer.participantType === ParticipantType.PrimarySigner ||
                            signer.participantType === ParticipantType.UserDataOnly
                        }
                        placeholder={t("departments:select")}
                    />
                </div>
                <div className={styles.actionBtnWrap}>
                    <SecondaryButton
                        title={t("common:action.cancel")}
                        className={styles.cancelBtn}
                        onClick={() => onCancelSelecting(signer)}
                    />
                    <BaseButton title={t("common:action.save")} onClick={onClickSave} />
                </div>
            </>
        )
    }

    return (
        <div className={styles.wrap}>
            <PrimaryDescription isPrimary={signer.participantType === ParticipantType.PrimarySigner} />
            {renderContent()}
        </div>
    )
}
