/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState, useEffect} from "react"
import {Checkbox} from "antd"
import {isEmpty} from "lodash"
import cx from "classnames"
import {Icon} from "components/Icon"
import {BaseInput} from "components/inputs"
import {KlassDropdown} from "components/Select"
import {FormLabel} from "components/Form"
import {dataTypes, defaultDataTypes, getStudentDataValues} from "sections/Tasks/data"
import {DefaultDataType} from "types/tasks"
import {getContentPositionStyle} from "./utils"
import styles from "./FieldActionItem.module.css"
import {useModel} from "hooks"

export function TextForm(props) {
    const {field, definedUsers, scaleInfo, totalPage, participants, canEditable, onClickDelete, onChangeField} = props
    const [style, setStyle] = useState({})
    const bodyRef = useRef(null)
    const [isShowScroll, setIsShowScroll] = useState(true)
    const model = useModel()
    const studentDataValues = getStudentDataValues(model)
    const syncToEdularFields = [
        "firstName",
        "lastName",
        "middleName",
        "nickNamePreferedName",
        "birthDate",
        "ssn",
        "address",
        "city",
        "state",
        "postalCode",
        "country"
    ]

    useEffect(() => {
        if (!bodyRef || !bodyRef.current) {
            return
        }
        const style = getContentPositionStyle(bodyRef, field, scaleInfo, totalPage)
        setStyle(style)
    }, [bodyRef])

    const onChangeData = (key, value) => {
        if (!canEditable) {
            return
        }
        field[key] = value
        if (key === "defaultData") {
            if (isEmpty(value) || !syncToEdularFields.includes(value.id)) {
                field.syncToEdular = undefined
            } else {
                field.syncToEdular = !!field.syncToEdular
            }
        }
        onChangeField(field)
    }

    return (
        <div
            ref={bodyRef}
            className={cx(styles.bodyContent, {
                [styles.bodyContentHiddenScroll]: !isShowScroll
            })}
            style={style}>
            <div className={styles.bodyHeader}>
                <p className={styles.bodyHeaderTitle}>Text</p>
                {canEditable && (
                    <div className={styles.deleteBtn} onClick={() => onClickDelete(field)}>
                        <Icon icon="DELETE" className={styles.deleteIcon} />
                    </div>
                )}
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Label" />
                <BaseInput
                    placeholder="Label"
                    value={field.label}
                    onChange={(newValue) => onChangeData("label", newValue)}
                />
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Signers" />
                <KlassDropdown
                    options={definedUsers}
                    labelKey="label"
                    value={field.signatureTypes}
                    onChange={(newValue) => onChangeData("signatureTypes", newValue)}
                    isMulti
                    onMenuOpen={() => setIsShowScroll(false)}
                    onMenuClose={() => setIsShowScroll(true)}
                />
            </div>
            <div className={styles.formItem}>
                <Checkbox
                    checked={field.required}
                    onChange={({target: {checked}}) => onChangeData("required", checked)}>
                    <span>Required</span>
                </Checkbox>
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Data type" />
                <KlassDropdown
                    options={dataTypes}
                    value={field.dataType}
                    onChange={(newValue) => onChangeData("dataType", newValue)}
                    onMenuOpen={() => setIsShowScroll(false)}
                    onMenuClose={() => setIsShowScroll(true)}
                />
            </div>
            <div className={styles.formItem}>
                <FormLabel label="Default data type" />
                <KlassDropdown
                    options={defaultDataTypes}
                    value={field.defaultDataType}
                    onChange={(newValue) => onChangeData("defaultDataType", newValue)}
                    onMenuOpen={() => setIsShowScroll(false)}
                    onMenuClose={() => setIsShowScroll(true)}
                />
            </div>
            {field.defaultDataType && field.defaultDataType.id === DefaultDataType.MANUAL_TEXT && (
                <div className={styles.formItem}>
                    <FormLabel label="Default data" />
                    <BaseInput
                        placeholder="Default data"
                        value={field.defaultData}
                        onChange={(newValue) => onChangeData("defaultData", newValue)}
                    />
                </div>
            )}
            {field.defaultDataType && field.defaultDataType.id === DefaultDataType.STUDENT_DATA && (
                <>
                    <div className={styles.formItem}>
                        <FormLabel label="Select participant" />
                        <KlassDropdown
                            options={participants}
                            value={field.defaultParticipant}
                            onChange={(newValue) => onChangeData("defaultParticipant", newValue)}
                            labelKey="label"
                            onMenuOpen={() => setIsShowScroll(false)}
                            onMenuClose={() => setIsShowScroll(true)}
                        />
                    </div>
                    <div className={styles.formItem}>
                        <FormLabel label="Participant data" />
                        <KlassDropdown
                            options={studentDataValues}
                            value={field.defaultData}
                            onChange={(newValue) => onChangeData("defaultData", newValue)}
                            onMenuOpen={() => setIsShowScroll(false)}
                            onMenuClose={() => setIsShowScroll(true)}
                        />
                    </div>
                    {field.defaultData && syncToEdularFields.includes(field.defaultData?.id) && (
                        <div className={styles.formItem}>
                            <Checkbox
                                checked={field.syncToEdular}
                                onChange={({target: {checked}}) => onChangeData("syncToEdular", checked)}>
                                <span>Sync To Edular</span>
                            </Checkbox>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
