import React, {useEffect} from "react"
import moment from "moment"
import {Icon} from "components/Icon"
import styles from "./Courses.module.css"
import {roundTwoDigits} from "helpers"
import {useModel} from "hooks"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"

type Props = {
    courses: any[]
} & KlassappTableProps

const Courses = (props: Props) => {
    const {
        courses,
        page,
        total,
        pageSize,
        onChangePage,
        onChangeRowPerPage,
        orderField,
        columns,
        data,
        isLoading,
        allFields,
        fields,
        onChangeFields,
        onDraggableColumn,
        onChangeAllFields
    } = props
    const model = useModel()

    function getColumns() {
        return [
            {
                title: "ID",
                field: "courseCode"
            },
            {
                title: "COURSE",
                field: "courseName"
            },
            {
                title: "SCHEDULE",
                field: "scheduleName"
            },
            {
                title: "LDA",
                field: "lda"
            },
            {
                title: "ATTENDANCE",
                field: "attendancePercentage"
            },
            {
                title: "GPA",
                field: "gpa",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: "ACTION",
                field: "action"
            }
        ]
    }

    function getFields() {
        return ["ID", "COURSE", "SCHEDULE", "LDA", "ATTENDANCE", "GPA", "ACTION"]
    }

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const data = courses.map((course) => ({
            ...course,
            gpa: typeof course.gpa === "number" && course.isVerified ? course.gpa : "",
            lda: course.lda ? moment(course.lda).format(model.getUserDateFormat()) : "",
            attendancePercentage: `${roundTwoDigits(course.attendancePercentage ?? 0)}%`
        }))
        props.dispatch({
            data,
            total: data.length
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courses])

    return (
        <div className={styles.wrap}>
            <div className={styles.header}>
                <Icon icon="COURSE" color="#62B1FF" />
                <p className={styles.headerTitle}>Courses</p>
            </div>
            <div className={styles.body}>
                <KlassappTableHeader
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    displayMode="panel"
                    onChangePage={onChangePage}
                    onChangeRowPerPage={onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={onChangeFields}
                    onDraggableColumn={onDraggableColumn}
                    onChangeAllFields={onChangeAllFields}
                />
                <KlassappTable
                    columns={columns}
                    menuActions={[]}
                    data={data}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumnHeader={false}
                    isShowCheckedColumn={false}
                    onClickRowItem={() => {}}
                    onChangeFields={onChangeFields}
                    onDraggableColumn={onDraggableColumn}
                    onChangeAllFields={onChangeAllFields}
                />
            </div>
        </div>
    )
}

export default KlassappTableHOC(Courses)
