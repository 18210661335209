/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect, useMemo} from "react"
import {useTranslation} from "react-i18next"
import {useEdularModulesContext, Modules} from "@edular/modules"
import {ApplicationProgress, Checklist} from "sections/shared/student"
import {handleError} from "helpers"
import {studentService} from "services"
import {useActiveTab, useCurrentProfilePermissions} from "hooks"
import {Tabs} from "antd"
import ActivitiesTable from "sections/shared/student/ActivitiesTable/ActivitiesTable"
import {Model} from "Model"
import WorkflowTriggersTable from "sections/Workflow/WorkflowTriggersTable"
import {Permissions} from "@edular/permissions"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

function reducer(state, action) {
    return {...state, ...action}
}

export enum AdmissionStudentAdmissionSubtab {
    Activities = "activities",
    WorkflowTriggers = "workflow-triggers",
    Checklist = "checklist"
}

type Props = {
    studentId: number
    departmentId: number
    model: Model
}

export function AdmissionStudentTab(props: Props) {
    const {studentId, departmentId} = props
    const {t} = useTranslation(["user", "common"])
    const initialState = {
        activeTabKey: "1",
        stageId: 0,
        currentStageId: 0,
        student: {},
        isUpdatingAcceptanceAdmission: false,
        admissionAcceptance: {
            acceptanceDate: null
        }
    }
    const [{stageId, currentStageId}, dispatch] = useReducer(reducer, initialState)
    const {isModuleEnable} = useEdularModulesContext()
    const canShowActivity = isModuleEnable(Modules.ActivitiesAndFollowups)
    const currentProfilePermissions = useCurrentProfilePermissions()
    const canShowWorkflowTriggers = useMemo(
        () =>
            currentProfilePermissions.hasPermissions({
                staff: [Permissions.Staff.Settings.General.Workflow.Add]
            }),
        [currentProfilePermissions]
    )
    const [activeTabKey, onChangeTab] = useActiveTab(
        canShowActivity ? AdmissionStudentAdmissionSubtab.Activities : AdmissionStudentAdmissionSubtab.Checklist,
        "subtab"
    )

    async function loadStudent(studentProfileId: number) {
        try {
            const {
                data: [student]
            } = await studentService.getDepartmentStudents({filter: {profileIds: [studentProfileId], departmentId}})
            dispatch({
                student,
                admissionAcceptance: {
                    acceptanceDate: student?.acceptanceDate
                }
            })
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        loadStudent(studentId)
    }, [])

    return (
        <div>
            <ApplicationProgress
                departmentId={props.departmentId}
                studentId={props.studentId}
                stageId={stageId}
                currentStageId={currentStageId}
                dispatch={dispatch}
            />
            <Tabs className="fullwidth klassSubTab" activeKey={activeTabKey} onChange={onChangeTab}>
                {canShowActivity && (
                    <TabPane
                        tab={<TabHeader title={t("user.activities")} />}
                        key={AdmissionStudentAdmissionSubtab.Activities}>
                        <ActivitiesTable studentId={studentId} departmentId={departmentId} />
                    </TabPane>
                )}
                {canShowWorkflowTriggers && (
                    <TabPane
                        tab={<TabHeader title={t("user.workflowTriggers")} />}
                        key={AdmissionStudentAdmissionSubtab.WorkflowTriggers}>
                        <WorkflowTriggersTable profileId={studentId} departmentId={departmentId} />
                    </TabPane>
                )}
                <TabPane
                    tab={<TabHeader title={t("user.checklist")} />}
                    key={AdmissionStudentAdmissionSubtab.Checklist}>
                    <Checklist studentId={studentId} departmentId={departmentId} />
                </TabPane>
            </Tabs>
        </div>
    )
}
