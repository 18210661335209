import React, {useState} from "react"
import {Popover, Tooltip, Button} from "antd"
import moment from "moment"
import cx from "classnames"
import {get} from "lodash"
import {Icon} from "components/Icon"
import {getColorContrast} from "helpers"
import {getRecurringInfo} from "helpers/calendar"
import styles from "./EventContent.module.css"

export function EventContent(props) {
    const [isShowEventPreview, setIsShowEventPreview] = useState(false)
    const {eventInfo, clickInfo, handleShowEventPreview, eventOptions} = props
    const eventData = eventInfo?.event
    const backgroundColor = get(eventData, "backgroundColor", "#1e90ff")
    const {
        startDateTime,
        endDateTime,
        description,
        isConflict,
        courseName,
        courseCode,
        submodule,
        instructors,
        rrule,
        campus
    } = eventData?.extendedProps || {}

    const closeEventPreview = () => {
        setIsShowEventPreview(false)
        setTimeout(() => {
            handleShowEventPreview(false)
            // To trigger handleDateClick event happen before close
        }, 300)
        clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
        clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
    }

    const onEventPreviewChange = (visible) => {
        if (!visible) {
            clickInfo?.el?.parentElement?.classList?.remove(styles.highlightEventPreviewWrap)
            clickInfo?.el?.classList?.remove(styles.highlightEventPreview)
        } else {
            clickInfo?.el?.parentElement?.classList?.add(styles.highlightEventPreviewWrap)
            clickInfo?.el?.classList?.add(styles.highlightEventPreview)
        }
        setIsShowEventPreview(visible)
        setTimeout(() => {
            handleShowEventPreview(visible)
            // To trigger handleDateClick event happen before close
        }, 300)
    }

    const onClickEventOption = (event, action) => {
        closeEventPreview()
        if (action) {
            action(event)
        }
    }

    const renderHeader = (options) => {
        return (
            <div className={styles.eventPreviewHeader}>
                {options.map((eventOption, index) => (
                    <Tooltip
                        key={index}
                        title={eventOption.title}
                        placement="bottom"
                        color="rgba(97,97,97,0.902)"
                        overlayInnerStyle={{minHeight: 27}}
                        overlayClassName={styles.tooltipCard}>
                        <Button
                            className={styles.eventPreviewHeaderBtn}
                            icon={
                                <Icon
                                    icon={eventOption.icon}
                                    color="#5f6368"
                                    className={cx(styles.eventPreviewHeaderIcon, eventOption.iconClassName)}
                                />
                            }
                            onClick={() => onClickEventOption(eventInfo.event, eventOption.action)}
                        />
                    </Tooltip>
                ))}
                <Tooltip
                    title="Close"
                    placement="bottom"
                    color="rgba(97,97,97,0.902)"
                    overlayInnerStyle={{minHeight: 27}}
                    overlayClassName={styles.tooltipCard}>
                    <Button
                        className={styles.eventPreviewHeaderBtn}
                        icon={<Icon icon="CLOSE" color="#5f6368" className={styles.eventPreviewHeaderIcon} />}
                        onClick={closeEventPreview}
                    />
                </Tooltip>
            </div>
        )
    }

    const renderTitle = () => {
        return (
            <div className={styles.eventPreviewContentRow}>
                <div className={styles.eventPreviewContentLeft}>
                    <div className={styles.eventPreviewType} style={{background: backgroundColor}}></div>
                </div>
                <div className={styles.eventPreviewInfo}>
                    <p className={styles.eventPreviewTitle}>{eventData.title}</p>
                    <p className={styles.submoduleNamePreview}>
                        {courseName} ({courseCode})
                    </p>
                    <p className={styles.submoduleNamePreview}>{campus}</p>
                    {submodule?.title && <p className={styles.submoduleNamePreview}>{submodule.title}</p>}
                    <p className={styles.submoduleNamePreview}>
                        {(instructors || []).map((instructor) => instructor.fullName).join(", ")}
                    </p>
                    <div className={styles.eventPreviewDate}>
                        <span>{moment(startDateTime).format("dddd, MMMM DD")}</span>
                        <span className={styles.eventPreviewDate__dot}>⋅</span>
                        <span>
                            {moment(startDateTime).format("HH:mm")} - {moment(endDateTime).format("HH:mm")}
                        </span>
                    </div>
                    {getRecurringInfo(rrule)}
                </div>
            </div>
        )
    }

    const renderDescription = () => {
        if (!description) {
            return null
        }
        return (
            <div className={styles.eventPreviewContentRow}>
                <div className={styles.eventPreviewContentLeft}>
                    <Icon className={styles.infoIcon} icon="NOTES" />
                </div>
                <div className={styles.eventPreviewInfo}>
                    <div
                        className={styles.eventPreviewTitle}
                        dangerouslySetInnerHTML={{
                            __html: description
                        }}
                    />
                </div>
            </div>
        )
    }

    const renderEventPreviewContent = (eventInfo) => {
        if (!eventInfo) return null
        const options = (eventOptions || []).filter(
            (eventOption) => !eventOption.canShow || eventOption.canShow(eventInfo._def)
        )
        return (
            <div className={styles.eventPreviewWrap}>
                {renderHeader(options)}
                <div className={styles.eventPreviewContent}>
                    {renderTitle()}
                    {renderDescription()}
                </div>
            </div>
        )
    }

    if (isConflict) {
        return (
            <div className={styles.warningIconWrap}>
                <Icon icon="WARNING_TRIANGLE_FILL" className={styles.warningIcon} />
            </div>
        )
    }

    return (
        <Popover
            visible={isShowEventPreview}
            content={() => renderEventPreviewContent(eventInfo.event)}
            trigger="click"
            onVisibleChange={onEventPreviewChange}
            placement="left">
            <div className={styles.eventContent}>
                <p className={styles.eventTitle} style={{color: getColorContrast(backgroundColor).color}}>
                    {eventInfo.event.title || ""}
                </p>
                <p className={styles.eventTime} style={{color: getColorContrast(backgroundColor).color}}>
                    {courseName}
                </p>
                <p className={styles.eventTime} style={{color: getColorContrast(backgroundColor).color}}>
                    {campus}
                </p>
                {submodule?.title && (
                    <p className={styles.eventTime} style={{color: getColorContrast(backgroundColor).color}}>
                        {submodule.title}
                    </p>
                )}
            </div>
        </Popover>
    )
}
