import React, {Component} from "react"
import styles from "./CollapseButton.module.css"
import {ReactComponent as IconMenu} from "./img/menu.svg"
import {ReactComponent as IconArrow} from "./img/arrow.svg"

type Props = {
    onClick: () => void
    type: "arrow" | "list"
}

export class CollapseButton extends Component<Props, {}> {
    public render() {
        return (
            <button type="button" className={styles.button} onClick={this.props.onClick}>
                <Icon type={this.props.type} />
            </button>
        )
    }
}

function Icon({type}: {type: "arrow" | "list"}) {
    if (type === "arrow") {
        return <IconArrow className={styles.iconArrow} width="20" height="10" />
    }

    return <IconMenu className={styles.iconList} width="20" height="10" />
}
