import {get} from "lodash"
import BaseService from "./Base"
import {
    AttendanceRoundingItem,
    EditAttendanceRoundingPayload,
    GeneralSetting,
    ListAttendanceRoundingPayload
} from "types/settings/general-setting"

class GeneralSettingsService extends BaseService {
    async getSettings(fields?: string[]): Promise<GeneralSetting[]> {
        const response = await this.post(`/v1/general-settings/list`, {fields})
        return get(response, "data.data", [])
    }

    async updateSettings(data: Partial<GeneralSetting>[]) {
        await this.post(`/v1/general-settings/edit`, data)
    }

    async listAttendanceRounding(payload: ListAttendanceRoundingPayload): Promise<{
        data: Array<AttendanceRoundingItem>
        total: number
        enabled: boolean
    }> {
        const response = await this.post(`/v1/general-settings/attendance-rounding/list`, payload)
        return get(response, "data.data", {})
    }

    async editAttendanceRounding(payload: EditAttendanceRoundingPayload): Promise<Array<AttendanceRoundingItem>> {
        const response = await this.post(`/v1/general-settings/attendance-rounding/edit`, payload)
        return get(response, "data.data", [])
    }
}

export default GeneralSettingsService
