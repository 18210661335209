import React from "react"
import cx from "classnames"
import {StatusCards} from "../Common"
import {Filter, Revenue, LeaveAbsence, Withdrawn, ExecutiveSummary, SelfPay} from "./parts"
import styles from "./WorkspaceTab.module.css"

export function WorkspaceTab(props) {
    return (
        <div>
            <Filter />
            <StatusCards />
            <Revenue />
            <div className={styles.bodyWrap}>
                <div className={cx(styles.item, styles.item__left)}>
                    <LeaveAbsence />
                </div>
                <div className={cx(styles.item, styles.item__right)}>
                    <Withdrawn />
                </div>
            </div>
            <div className={styles.bodyWrap}>
                <div className={cx(styles.item, styles.item__left)}>
                    <ExecutiveSummary />
                </div>
                <div className={cx(styles.item, styles.item__right)}>
                    <SelfPay />
                </div>
            </div>
        </div>
    )
}
