/* eslint-disable react-hooks/exhaustive-deps */
import {Col, Tooltip} from "antd"
import {checkDocumentDepartmentPermission, openLinkInNewTab} from "helpers"
import {useModel, useVisible} from "hooks"
import {useMemo, useState} from "react"
import {BaseDepartmentId} from "types/departments"
import {PermissionType} from "types/permission"
import styles from "./DocumentBlockView.module.css"
import {BaseLoading, Icon} from "components"
import {ActionRowItem} from "uiKit/table/parts"
import moment from "moment"
import {useDocumentActions} from "hooks/"
import {DocumentAction, UserDocumentStatus} from "types/user-documents"
import {ConfirmPopup} from "uiKit"

type BlockItemProps = {
    item: any
    departmentId?: BaseDepartmentId
    itemRef?: any
    goToDetails: (item: any) => void
    onPreviewDocument: (item: any) => void
    onRefetchData: () => void
}

export function BlockItem(props: BlockItemProps) {
    const model = useModel()
    const {item, departmentId, itemRef, goToDetails, onPreviewDocument, onRefetchData} = props
    const {status, statusUpdatedBy, statusUpdatedAt, linkedActivities, linkedChecklists, linkedTasks, departmentName} =
        item
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const {deleteDocuments, updateStatus, print, isSubmitting} = useDocumentActions()
    const showConfirmationPopup = useVisible(false)
    const [actionDocument, setActionDocument] = useState<DocumentAction>()

    const onConfirmAction = (action: DocumentAction) => {
        setActionDocument(action)
        showConfirmationPopup.open()
    }

    const onHandleAction = async () => {
        if (!actionDocument) return
        showConfirmationPopup.close()
        switch (actionDocument) {
            case DocumentAction.Delete:
                return deleteDocuments([item.userDocumentId], {
                    onSuccess: onRefetchData
                })
            case DocumentAction.Approve:
            case DocumentAction.Reject:
                return updateStatus(
                    [
                        {
                            userDocumentId: item.userDocumentId,
                            status:
                                actionDocument === DocumentAction.Approve
                                    ? UserDocumentStatus.Approved
                                    : UserDocumentStatus.Rejected
                        }
                    ],
                    {
                        onSuccess: onRefetchData
                    }
                )
        }
    }

    const onClickPrint = async (data) => {
        print([data.userDocumentId], {
            onSuccess: async (dataUrls) => {
                if (dataUrls?.length) {
                    dataUrls.forEach(openLinkInNewTab)
                }
            }
        })
    }

    const menuActions = useMemo(() => {
        const actions: any[] = []
        const canDeleteByPermission = checkDocumentDepartmentPermission(PermissionType.Delete, model, departmentId)
        if (canDeleteByPermission && item.status !== UserDocumentStatus.Approved) {
            actions.push({
                title: "Delete",
                icon: "DELETE",
                action: () => onConfirmAction(DocumentAction.Delete)
            })
        }

        const canApprove = model.isStaffOrAdmin() && item.ownerUserId !== model.user.id
        if (canApprove) {
            if (item.status !== UserDocumentStatus.Approved) {
                actions.push({
                    title: "Approve",
                    icon: "CHECKMARK_CIRCLE",
                    action: () => onConfirmAction(DocumentAction.Approve)
                })
            }
            if (item.status !== UserDocumentStatus.Rejected) {
                actions.push({
                    title: "Reject",
                    icon: "CROSS_CIRCLE_FILL",
                    action: () => onConfirmAction(DocumentAction.Reject)
                })
            }
        }
        const canPrint = model.isStaffOrAdmin() || item.ownerUserId === model.user.id
        if (canPrint) {
            actions.push({
                title: "Print",
                icon: "DOCUMENT",
                action: onClickPrint
            })
        }
        return actions
    }, [item])

    const confirmationTitle = useMemo(() => {
        if (!actionDocument) return ""
        return `Are you sure you want to ${actionDocument.toLowerCase()} this document (${item.code}) ?`
    }, [actionDocument])

    return (
        <Col span={6} ref={itemRef}>
            <div className={styles.item}>
                <div className={styles.detail}>
                    <div className={styles.headerWrap}>
                        <span className={styles.code} onClick={() => goToDetails(item)}>
                            {item.code}
                        </span>
                        <div className={styles.links}>
                            {linkedTasks && (
                                <Tooltip title="Linked to Tasks">
                                    <div className={styles.linkIconWrap}>
                                        <Icon icon="TASK_FILL" className={styles.linkIcon} />
                                    </div>
                                </Tooltip>
                            )}
                            {linkedChecklists && (
                                <Tooltip title="Linked to Checklists">
                                    <div className={styles.linkIconWrap}>
                                        <Icon icon="CHECKLIST" className={styles.linkIcon} />
                                    </div>
                                </Tooltip>
                            )}
                            {linkedActivities && (
                                <Tooltip title="Linked to Activities">
                                    <div className={styles.linkIconWrap}>
                                        <Icon icon="CHECKMARK_CIRCLE" className={styles.linkIcon} />
                                    </div>
                                </Tooltip>
                            )}
                            <div className={styles.department}>{departmentName}</div>
                            <ActionRowItem data={item} menuActions={menuActions} />
                        </div>
                    </div>
                    <span className={styles.name}>{item.name}</span>
                    <div className={styles.statusWrap}>
                        {status === UserDocumentStatus.Approved ? (
                            <Icon icon="CHECKMARK_CIRCLE" color="#18A957" />
                        ) : status === UserDocumentStatus.Rejected ? (
                            <Icon icon="CROSS_CIRCLE_FILL" color="#DF1642" />
                        ) : (
                            <Icon icon="CHECKMARK_CIRCLE_1" color="#E6E6E6" />
                        )}
                        {!!status && status !== "pending" && (
                            <div className={styles.statusInfo}>
                                <span className={styles.statusInfo__text}>{statusUpdatedBy}</span>
                                <span className={styles.statusInfo__text}>
                                    {moment(statusUpdatedAt).format(userDateTimeFormat)}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.preview}>
                    <div className={styles.previewFile}>
                        {item.previewUrl && (
                            <img src={item.previewUrl} alt="" loading="lazy" className={styles.imgPreview} />
                        )}
                    </div>
                    <div className={styles.previewIcon} onClick={() => onPreviewDocument(item)}>
                        <Icon icon="EYE" color="var(--primary-400-base)" />
                    </div>
                </div>
            </div>
            <BaseLoading isShow={isSubmitting} />
            <ConfirmPopup
                isVisible={showConfirmationPopup.isVisible}
                title={confirmationTitle}
                onClose={showConfirmationPopup.close}
                onConfirm={onHandleAction}
            />
        </Col>
    )
}
