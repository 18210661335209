/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback} from "react"
import styles from "./FileSelect.module.css"
import {useDropzone} from "react-dropzone"
import {ReactComponent as UploadIcon} from "./upload.svg"
import {useModel} from "hooks"

type Props = {
    onSelectFiles: (_attachedFiles: any) => void
}

export function FileSelect(props: Props) {
    const {onSelectFiles} = props
    const model = useModel()
    const allowedDocumentFormats = model.getAllowedDocumentFormats() || "application/pdf"

    const onDrop = useCallback((acceptedFiles) => {
        onSelectFiles(acceptedFiles)
    }, [])

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: allowedDocumentFormats
    })

    return (
        <div className={styles.step1} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={styles.iconWrap}>
                <UploadIcon />
            </div>
            <div className={styles.step1Desc}>
                <span className={styles.uploadLabel}>Click to upload</span>
                <span className={styles.uploadDescLabel}>Or drag and drop files here</span>
            </div>
        </div>
    )
}
