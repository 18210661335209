import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownProps} from "../Select"
import {formatCodeName} from "helpers"
import {useAllCampuses} from "hooks"

type Props = Partial<KlassDropdownProps> & {
    displayOnlyCampusIds?: number[]
    displayOnlyActiveCampuses?: boolean
}

const CampusSelect: React.FC<Props> = ({displayOnlyCampusIds, displayOnlyActiveCampuses = false, ...props}) => {
    const {t} = useTranslation(["common"])
    const {campuses} = useAllCampuses()

    const options = useMemo(() => {
        const newCampuses = campuses.filter((campus) => {
            const filteredByIds = !displayOnlyCampusIds?.length || displayOnlyCampusIds.includes(campus.id)
            const filteredByIsActive = !displayOnlyActiveCampuses || campus.isActive

            return filteredByIds && filteredByIsActive
        })

        return newCampuses
    }, [campuses, displayOnlyActiveCampuses, displayOnlyCampusIds])

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={options}
            valueKey="id"
            labelKey="name"
            getOptionLabel={formatCodeName}
        />
    )
}

export default CampusSelect
