import {Col, Row} from "antd"
import styles from "./ConversionRateSettings.module.css"
import {FormLabel} from "components/Form"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "@edular/permissions"
import {BaseButton, BaseLoading, SecondaryButton} from "components"
import {useTranslation} from "react-i18next"
import {useCallback, useEffect, useState} from "react"
import {admissionService} from "services"
import debounce from "debounce-promise"
import {KlassDropAsyncPaginate} from "components/Select"
import {AdmissionsConversionRateStatus} from "types/admissions"
import {StatusStateShortName, handleError} from "helpers"
import {isEqual} from "lodash"

export const ConversionRateSettings = () => {
    const {t} = useTranslation(["admission", "user"])
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [initConvRateStatus, setInitConvRateStatus] = useState<AdmissionsConversionRateStatus>()
    const [convRateStatus, setConvRateStatus] = useState<AdmissionsConversionRateStatus>()

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        try {
            setIsLoading(true)
            const data = await admissionService.getConverionRateStatus()
            const dataFormated = {
                firstRateStatus: data.firstRateStatus
                    ? {
                          ...data.firstRateStatus,
                          statusName: `[${StatusStateShortName[data.firstRateStatus.stateName]}] ${
                              data.firstRateStatus.statusName
                          }`
                      }
                    : null,
                secondRateStatus: data.secondRateStatus
                    ? {
                          ...data.secondRateStatus,
                          statusName: `[${StatusStateShortName[data.secondRateStatus.stateName]}] ${
                              data.secondRateStatus.statusName
                          }`
                      }
                    : null,
                thirdRateStatus: data.thirdRateStatus
                    ? {
                          ...data.thirdRateStatus,
                          statusName: `[${StatusStateShortName[data.thirdRateStatus.stateName]}] ${
                              data.thirdRateStatus.statusName
                          }`
                      }
                    : null,
                fourthRateStatus: data.fourthRateStatus
                    ? {
                          ...data.fourthRateStatus,
                          statusName: `[${StatusStateShortName[data.fourthRateStatus.stateName]}] ${
                              data.fourthRateStatus.statusName
                          }`
                      }
                    : null,
                fifthRateStatus: data.fifthRateStatus
                    ? {
                          ...data.fifthRateStatus,
                          statusName: `[${StatusStateShortName[data.fifthRateStatus.stateName]}] ${
                              data.fifthRateStatus.statusName
                          }`
                      }
                    : null
            }
            setInitConvRateStatus(dataFormated)
            setConvRateStatus(dataFormated)
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }

    const loadStatusOptions = async (input, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const params = {
                range: {
                    page,
                    pageSize
                },
                sort: {
                    orderBy: "priority",
                    orderDir: "asc"
                }
            }
            const {data, total} = await admissionService.getAllFunnelStatuses(params)
            return {
                options: data.map((option) => ({
                    ...option,
                    statusName: `[${StatusStateShortName[option.stateName]}] ${option.statusName}`
                })),
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onClickCancel = useCallback(() => {
        setConvRateStatus({
            firstRateStatus: null,
            secondRateStatus: null,
            thirdRateStatus: null,
            fourthRateStatus: null,
            fifthRateStatus: null,
            ...initConvRateStatus
        })
    }, [initConvRateStatus])

    const onClickSave = async () => {
        try {
            setIsSubmitting(true)
            const payload = {
                firstRateStatusId: convRateStatus?.firstRateStatus?.id,
                secondRateStatusId: convRateStatus?.secondRateStatus?.id,
                thirdRateStatusId: convRateStatus?.thirdRateStatus?.id,
                fourthRateStatusId: convRateStatus?.fourthRateStatus?.id,
                fifthRateStatusId: convRateStatus?.fifthRateStatus?.id
            }
            await admissionService.updateConverionRateStatus(payload)
            getData()
        } catch (err) {
            handleError(err)
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleChange = (key, value) => {
        setConvRateStatus((prev) => ({
            ...prev,
            [key]: value
        }))
    }

    const debounceStatuses = debounce(loadStatusOptions, 500)

    if (isLoading) {
        return <BaseLoading isShow={isLoading} />
    }
    const isChanged = !isEqual(convRateStatus, initConvRateStatus)

    return (
        <div className={styles.container}>
            <Row gutter={[12, 12]}>
                <Col span={8}>
                    <FormLabel label="Conversion Rate 1" />
                    <KlassDropAsyncPaginate
                        value={convRateStatus?.firstRateStatus}
                        onChange={(newValue) => handleChange("firstRateStatus", newValue)}
                        valueKey="id"
                        labelKey="statusName"
                        loadOptions={debounceStatuses}
                        placeholder="Conversion Rate 1"
                        isClearable
                    />
                </Col>
                <Col span={8}>
                    <FormLabel label="Conversion Rate 2" />
                    <KlassDropAsyncPaginate
                        value={convRateStatus?.secondRateStatus}
                        onChange={(newValue) => handleChange("secondRateStatus", newValue)}
                        valueKey="id"
                        labelKey="statusName"
                        loadOptions={debounceStatuses}
                        placeholder="Conversion Rate 2"
                        isClearable
                    />
                </Col>
                <Col span={8}>
                    <FormLabel label="Conversion Rate 3" />
                    <KlassDropAsyncPaginate
                        value={convRateStatus?.thirdRateStatus}
                        onChange={(newValue) => handleChange("thirdRateStatus", newValue)}
                        valueKey="id"
                        labelKey="statusName"
                        loadOptions={debounceStatuses}
                        placeholder="Conversion Rate 3"
                        isClearable
                    />
                </Col>
                <Col span={8}>
                    <FormLabel label="Conversion Rate 4" />
                    <KlassDropAsyncPaginate
                        value={convRateStatus?.fourthRateStatus}
                        onChange={(newValue) => handleChange("fourthRateStatus", newValue)}
                        valueKey="id"
                        labelKey="statusName"
                        loadOptions={debounceStatuses}
                        placeholder="Conversion Rate 4"
                        isClearable
                    />
                </Col>
                <Col span={8}>
                    <FormLabel label="Conversion Rate 5" />
                    <KlassDropAsyncPaginate
                        value={convRateStatus?.fifthRateStatus}
                        onChange={(newValue) => handleChange("fifthRateStatus", newValue)}
                        valueKey="id"
                        labelKey="statusName"
                        loadOptions={debounceStatuses}
                        placeholder="Conversion Rate 5"
                        isClearable
                    />
                </Col>
            </Row>
            <PermissionsRequired permissions={{staff: [Permissions.Staff.Settings.Modules.Admissions.Edit]}}>
                {isChanged && (
                    <div className={styles.buttonWrap}>
                        <SecondaryButton
                            title={t("common:action.cancel")}
                            onClick={onClickCancel}
                            className={styles.cancelBtn}
                        />
                        <BaseButton
                            title={t("common:action.save").toUpperCase()}
                            onClick={onClickSave}
                            loading={isSubmitting}
                        />
                    </div>
                )}
            </PermissionsRequired>
        </div>
    )
}
