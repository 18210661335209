/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from "react"
import {Col, Row} from "antd"
import moment from "moment"
import {get, isEmpty, orderBy, pick} from "lodash"
import {FormLabel} from "components/Form"
import {KlassDropdown} from "components/Select"
import {BaseButton} from "components/buttons"
import {BaseDatePicker} from "components/DateTimePicker"
import {DEPENDENCY_STATUS_OPTIONS, ENROLLMENT_STATUS_OPTIONS, STATES_OPTIONS} from "types/students"
import styles from "./form.module.css"
import {Auth} from "types/auth"
import {checkPermission, getDegreeLevelName, getFieldLabel, getShortName, handleError} from "helpers"
import {finAidService, majorService, settingAcademicTrackService, studentService, userService} from "services"
import {BaseInput} from "components/inputs"
import {DisplayMode, SelectOption} from "types/common"
import cx from "classnames"
import {ActiveStudentContext} from "context/ActiveStudentContext"
import {StudentDepartmentStatus} from "types/workflow"
import {DegreeLevel} from "types/degreeLevel"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"
import TermSelect from "components/TermSelect"
import produce from "immer"
import {BaseDepartmentId} from "types/departments"
import {Permissions} from "types/permission"
import {useAllEnrollmentTypes, useModel} from "hooks"
import {useTranslation} from "react-i18next"
import {FinAidOffer, HousingPlan} from "types/fin-aid/fin-aid"
import {Settings} from "types/settings"

type Props = {
    onClickCancel: () => void
    onSaveSuccess: () => void
    displayMode?: DisplayMode
}

export function FinancialAidForm(props: Props) {
    const {t} = useTranslation(["common", "chat", "financialAid", "user"])
    const model = useModel()
    const {displayMode = "normal", onClickCancel, onSaveSuccess} = props
    const isFullDisplay = displayMode === "normal"
    const {student, setStudent, studentAdvisors, advisorsPopup, payingMethods, departmentStatuses} =
        useContext(ActiveStudentContext)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [majorVersionOptions, setMajorVersionOptions] = useState([])

    const [stateValue, setStateValue] = useState<SelectOption | undefined>()
    const [enrollmentTypeValue, setEnrollmentTypeValue] = useState<SelectOption | undefined>()
    const [enrollmentStatusValue, setEnrollmentStatusValue] = useState<SelectOption | undefined>()
    const [dependencyStatusValue, setDependencyStatusValue] = useState<SelectOption | undefined>()
    const [finAidStatus, setFinAidStatus] = useState<StudentDepartmentStatus | undefined>()
    const [finAidOffer, setFinAidOffer] = React.useState<FinAidOffer | undefined>()
    const [academicTracks, setAcademicTracks] = useState<SelectOption[]>([])
    const [academicTrackValue, setAcademicTrackValue] = useState<SelectOption | undefined>()
    const [housingPlanValue, setHousingPlanValue] = useState<SelectOption | undefined>()
    const {enrollmentTypes} = useAllEnrollmentTypes()

    const housingPlanOptions = React.useMemo(
        () =>
            Object.values(HousingPlan).map((value) => ({
                id: value,
                name: t(`financialAid:housingPlan.${value}`)
            })),
        [t]
    )

    useEffect(() => {
        if (!student?.profileId) return

        setStateValue(STATES_OPTIONS.find((item) => item.id === student.profileState))
        setDependencyStatusValue(DEPENDENCY_STATUS_OPTIONS.find((item) => item.id === student.dependencyStatus))
        setEnrollmentStatusValue(ENROLLMENT_STATUS_OPTIONS.find((item) => item.id === student.enrollmentStatus))
        setHousingPlanValue(housingPlanOptions.find((item) => item.id === student.housingPlan))
        ;(async function getFinAidStatus() {
            try {
                const {data: statuses} = await studentService.getStudentStatuses(student.profileId)
                const finAidStatus = statuses.find(
                    (status) => status.departmentId === BaseDepartmentId.FinancialAid
                )?.status
                setFinAidStatus(finAidStatus)
            } catch (error) {
                console.error(error)
            }
        })()
        ;(async function loadLatestFinAidOffer() {
            setFinAidOffer(
                await finAidService.getFinAidLatestOffer({studentProfileId: student.profileId, linkedPackage: true})
            )
        })()
    }, [student?.profileId])

    useEffect(() => {
        if (!isEmpty(enrollmentTypes)) {
            const data = enrollmentTypes.find((item) => item.code === student.enrollmentType)
            setEnrollmentTypeValue({id: data.code, name: data.name})
        }
    }, [enrollmentTypes, student?.profileId])

    useEffect(() => {
        ;(async function getMajorVersionOptions() {
            if (!student?.currentMajor?.id) {
                setMajorVersionOptions([])
                return
            }
            try {
                const majorVersions = await majorService.getMajorVersions({
                    orderBy: "version_name",
                    orderDir: "ASC",
                    program_id: student.currentMajor.id,
                    isActive: true
                })
                setMajorVersionOptions(
                    majorVersions.map((item) => ({
                        ...item,
                        versionName: item.version_name,
                        versionCode: item.version_code
                    }))
                )
            } catch (error) {
                handleError(error)
                setMajorVersionOptions([])
            }
        })()
    }, [student?.currentMajor])

    useEffect(() => {
        if (!student?.startingTerm) return
        ;(async function updateStartingTermDates() {
            const {data: programsOfTerm} = await majorService.getMajorByTerm([student.startingTerm.id])
            setStudent((prev) =>
                produce(prev, (draft) => {
                    const isCurrentMajorExist =
                        !!draft.currentMajor?.id && programsOfTerm.some((major) => major.id === draft.currentMajor.id)
                    const programByTerm = programsOfTerm.find((major) => major.id === draft.currentMajor?.id)
                    if (!!programByTerm?.startDate) {
                        draft.startDate = moment.utc(programByTerm.startDate).toISOString()
                    }
                    if (!!programByTerm?.projectedEndDate) {
                        draft.contractEndDate = moment.utc(programByTerm.projectedEndDate).toISOString()
                        draft.projectedEndDate = moment.utc(programByTerm.projectedEndDate).toISOString()
                    }
                    if (!isCurrentMajorExist) {
                        draft.currentMajor = null
                        draft.currentMajorVersionId = null
                    }
                })
            )
        })()
    }, [student?.startingTerm])

    useEffect(() => {
        ;(async function getAcademicTrackOptions() {
            try {
                const {data} = await settingAcademicTrackService.getAllAcademicTracks({
                    sort: {orderBy: "name", orderDir: "asc"}
                })
                setAcademicTracks(data.map((track) => ({id: track.trackId, name: track.name})))
            } catch (error) {
                handleError(error)
            }
        })()
    }, [])

    useEffect(() => {
        setAcademicTrackValue(academicTracks.find((track) => track.id === student?.academicTrackId))
    }, [academicTracks, student?.academicTrackId])

    const onChangeData = (key, value) => {
        setStudent((prev) =>
            produce(prev, (draft) => {
                if (key === "currentMajor") {
                    draft.startingTermId = null
                    draft.startingTerm = null
                    draft.currentMajorVersionId = null
                    draft.degreeLevel = get(value, "degreeLevel.name", "")
                }
                draft[key] = value
            })
        )
    }

    const onClickSave = async () => {
        const dataSubmit: any = pick(student, [
            "inquiryMessage",
            "startDate",
            "contractEndDate",
            "firstDateOfClass",
            "midpointDate",
            "projectedEndDate",
            "actualEndDate",
            "numberOfDependents"
        ])

        if (finAidStatus) {
            dataSubmit.studentDepartmentStatuses = [
                {
                    profileId: student.profileId,
                    statusId: finAidStatus.statusId,
                    departmentId: BaseDepartmentId.FinancialAid
                }
            ]
        }

        if (dataSubmit?.numberOfDependents) {
            dataSubmit.numberOfDependents = Number(dataSubmit.numberOfDependents)
        }
        dataSubmit.type = Auth.UserProfileType.Student
        dataSubmit.enrollmentType = enrollmentTypeValue?.id
        dataSubmit.enrollmentStatus = enrollmentStatusValue?.id
        dataSubmit.dependencyStatus = dependencyStatusValue?.id
        dataSubmit.academicTrackId = academicTrackValue?.id
        dataSubmit.housingPlan = housingPlanValue?.id
        dataSubmit.campuses = (student.campuses || []).map((campus) => ({id: campus.id, name: campus.name}))
        dataSubmit.payingMethodIds = (student.payingMethods || []).map((payingMethod) => payingMethod.payingMethodId)
        dataSubmit.currentMajor = student.currentMajor ? get(student.currentMajor, "id") : null
        dataSubmit.currentMajorVersionId = student.currentMajorVersionId
            ? get(student.currentMajorVersionId, "id")
            : null
        dataSubmit.startingTermId = student.startingTerm ? get(student.startingTerm, "id") : null
        try {
            setIsSubmitting(true)
            await userService.updateProfileUserById(student.profileId, dataSubmit)
            onSaveSuccess()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const checkHasPermissionToUpdateAdvisor = () => {
        return checkPermission({staff: [Permissions.Staff.FinancialAid.Students.UpdateAdvisor.Edit]}, model)
    }
    const hasPermissionToUpdateAdvisor = checkHasPermissionToUpdateAdvisor()

    const renderListAdvisors = () => {
        const studentAdvisorsOrderByDefault = orderBy(studentAdvisors, ["isDefault"], ["desc"])
        return (
            <div>
                {(studentAdvisorsOrderByDefault || []).map((advisor, index) => (
                    <span key={index} className={styles.advisorWrap}>
                        <span className={styles.advisorName}>{getShortName(advisor)}</span>
                        {index !== studentAdvisorsOrderByDefault.length - 1 && ", "}
                    </span>
                ))}
            </div>
        )
    }

    if (!student) {
        return null
    }

    return (
        <div className={cx({[styles.wrap]: isFullDisplay})}>
            {isFullDisplay && <p className={styles.title}>Student financial Aid Details</p>}
            <div className={styles.body}>
                <Row gutter={[24, 24]}>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Dependency Status" />
                            <KlassDropdown
                                options={DEPENDENCY_STATUS_OPTIONS}
                                value={dependencyStatusValue}
                                onChange={setDependencyStatusValue}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Number of Dependents" />
                            <BaseInput
                                disabledArrow
                                className={styles.inputGrade}
                                type="number"
                                value={student.numberOfDependents}
                                onChange={(newValue) => onChangeData("numberOfDependents", newValue)}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Method of Paying for College" />
                            <KlassDropdown
                                isMulti
                                options={payingMethods}
                                valueKey="payingMethodId"
                                labelKey="name"
                                value={student.payingMethods}
                                onChange={(newValue) => onChangeData("payingMethods", newValue)}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Housing Plan" />
                            <KlassDropdown
                                options={housingPlanOptions}
                                value={housingPlanValue}
                                onChange={setHousingPlanValue}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Current Award year" />
                            <BaseInput
                                value={
                                    finAidOffer?.awardYear
                                        ? `${finAidOffer.awardYear} - ${finAidOffer.awardYear + 1}`
                                        : "-"
                                }
                                readOnly
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Financial Aid Package status" />
                            <BaseInput
                                value={
                                    finAidOffer?.status
                                        ? String(t(`financialAid:finAidOffer.status.${finAidOffer?.status}`))
                                        : "-"
                                }
                                readOnly
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Current ISIR" />
                            <BaseInput value={finAidOffer?.finAidPackage?.isir || "-"} readOnly />
                        </div>
                    </Col>
                </Row>

                <p className={styles.subTitle}>General</p>

                <Row gutter={[24, 24]}>
                    {!model.clientSetting.isDepartmentalStatusesHidden && (
                        <Col span={isFullDisplay ? 8 : 24}>
                            <div>
                                <FormLabel label="Financial Aid Status" />
                                <KlassDropdown
                                    options={departmentStatuses}
                                    value={finAidStatus}
                                    valueKey="statusId"
                                    onChange={setFinAidStatus}
                                />
                            </div>
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label={t("studentInfo.advisor", {departmentName: "Admissions"})} />
                            {hasPermissionToUpdateAdvisor ? (
                                <div className={cx(styles.contentLink)} onClick={advisorsPopup.open}>
                                    {studentAdvisors && studentAdvisors.length
                                        ? renderListAdvisors()
                                        : t("studentInfo.selectAdvisor")}
                                </div>
                            ) : (
                                renderListAdvisors()
                            )}
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Enrollment Type" />
                            <KlassDropdown
                                options={enrollmentTypes || []}
                                value={enrollmentTypeValue}
                                onChange={setEnrollmentTypeValue}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.EnrollmentStatus,
                                    "Enrollment status"
                                )}
                            />
                            <KlassDropdown
                                options={ENROLLMENT_STATUS_OPTIONS}
                                value={enrollmentStatusValue}
                                onChange={setEnrollmentStatusValue}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(model, Settings.GeneralLabel.Campuses, t("user:user.campuses"))}
                            />
                            <CampusSelect
                                isMulti
                                value={student.campuses}
                                onChange={(newValue) => onChangeData("campuses", newValue)}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label={t("user:user.currentMajor")} />
                            <ProgramSelect
                                isClearable
                                isActive
                                value={student.currentMajor}
                                onChange={(newValue) => onChangeData("currentMajor", newValue || null)}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.ProgramVersion,
                                    t("user:user.majorVersion")
                                )}
                            />
                            <KlassDropdown
                                onChange={(newValue) => onChangeData("currentMajorVersionId", newValue)}
                                getOptionLabel={({versionCode, versionName}) =>
                                    versionCode ? ` ${versionName} (${versionCode})` : versionName
                                }
                                getOptionValue={({versionCode, versionName}) =>
                                    versionCode ? ` ${versionName} (${versionCode})` : versionName
                                }
                                options={majorVersionOptions}
                                value={student.currentMajorVersionId}
                                isClearable
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label={t("user:user.startTerm")} />
                            <TermSelect
                                isClearable
                                value={student.startingTerm}
                                onChange={(newValue) => onChangeData("startingTerm", newValue)}
                            />
                        </div>
                    </Col>

                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(model, Settings.GeneralLabel.StartDate, t("user:user.startDate"))}
                            />
                            <BaseDatePicker value={student.startDate ? moment.utc(student.startDate) : null} readOnly />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.ContractEndDate,
                                    t("user:user.contractEndDate")
                                )}
                            />
                            <BaseDatePicker
                                value={student.contractEndDate ? moment.utc(student.contractEndDate) : null}
                                readOnly
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.FirstDateOfClass,
                                    t("user:user.firstDateOfClass")
                                )}
                            />
                            <BaseDatePicker
                                value={student.firstDateOfClass ? moment.utc(student.firstDateOfClass) : null}
                                onChange={(date) => onChangeData("firstDateOfClass", date.toISOString())}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.MidpointDate,
                                    t("user:user.midpointDate")
                                )}
                            />
                            <BaseDatePicker
                                value={student.midpointDate ? moment.utc(student.midpointDate) : null}
                                onChange={(date) => onChangeData("midpointDate", date.toISOString())}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={getFieldLabel(
                                    model,
                                    Settings.GeneralLabel.ProjectedEndDate,
                                    t("user:user.projectedEndDate")
                                )}
                            />
                            <BaseDatePicker
                                value={student.projectedEndDate ? moment.utc(student.projectedEndDate) : null}
                                onChange={(date) => onChangeData("projectedEndDate", date.toISOString())}
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel
                                label={
                                    getFieldLabel(
                                        model,
                                        Settings.GeneralLabel.ActualEndDate,
                                        t("user:user.actualEndDate")
                                    ) + " (System generated)"
                                }
                            />
                            <BaseDatePicker
                                value={student.actualEndDate ? moment.utc(student.actualEndDate) : null}
                                readOnly
                            />
                        </div>
                    </Col>

                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Degree Level" />
                            <BaseInput
                                value={
                                    student.degreeLevel
                                        ? typeof student.degreeLevel === "string"
                                            ? student.degreeLevel
                                            : getDegreeLevelName(student.degreeLevel as DegreeLevel.DegreeLevel)
                                        : null
                                }
                                readOnly
                            />
                        </div>
                    </Col>
                    <Col span={isFullDisplay ? 8 : 24}>
                        <div>
                            <FormLabel label="Academic Track" />
                            <KlassDropdown
                                options={academicTracks}
                                value={academicTrackValue}
                                onChange={setAcademicTrackValue}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.action}>
                <BaseButton title="Cancel" variant="secondary" onClick={onClickCancel} />
                <BaseButton title="Save" loading={isSubmitting} onClick={onClickSave} />
            </div>
        </div>
    )
}
