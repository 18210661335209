import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {studentAccountService} from "services"
import {PaymentPeriod} from "types/student-account/payment-period"
import {Order} from "types/common"

const queryCacheKey = "program-payment-periods"
const INITIAL_DATA: PaymentPeriod[] = []

export const usePaymentPeriods = ({programIds}: {programIds?: number[]} = {}) => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey, programIds],
        async () => {
            if (programIds && !programIds.length) return []
            const periods = await studentAccountService.getPublicPaymentPeriods({
                sort: {orderBy: "startDate", orderDir: Order.Desc},
                filter: {programIds}
            })
            return periods.map((item) => ({...item, id: item.periodId}))
        },
        {
            refetchOnMount: false
        }
    )

    return {
        paymentPeriods: query.data ?? INITIAL_DATA,
        isLoading: query.isFetching,
        invalidatePaymentPeriodsCache: useCallback(
            (programIds?: number[]) => {
                queryClient.invalidateQueries({queryKey: [queryCacheKey, programIds]})
            },
            [queryClient]
        )
    }
}
