/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from "react"
import {useTranslation} from "react-i18next"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {formatCodeName} from "helpers"
import {useAllPrograms, useProgramsByTerm} from "hooks"
import {intersection} from "lodash"

type Props = Partial<KlassDropdownAsyncProps> & {
    campusId?: number | number[]
    degreeLevelId?: number | number[]
    isActive?: boolean
    termIds?: number[]
}

const ProgramSelect: React.FC<Props> = ({campusId, termIds, degreeLevelId, isActive, ...props}) => {
    const {t} = useTranslation(["common"])
    const {programs} = useAllPrograms()
    const {programs: programsByTerm} = useProgramsByTerm({termIds})

    const options = useMemo(() => {
        if (termIds?.length) {
            return programsByTerm
        }
        return programs.filter((program) => {
            if (isActive != null) {
                if (program.isActive !== isActive) return false
            }
            if (campusId) {
                if (!intersection(program.campusIds ?? [], Array.isArray(campusId) ? campusId : [campusId]).length)
                    return false
            }
            if (degreeLevelId) {
                if (!(Array.isArray(degreeLevelId) ? degreeLevelId : [degreeLevelId]).includes(program.degreeLevelId))
                    return false
            }
            return true
        })
    }, [programs, programsByTerm, termIds, campusId, degreeLevelId, isActive])

    return (
        <KlassDropdown
            {...props}
            key={JSON.stringify({campusId, degreeLevelId, isActive, termIds})}
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={options}
            valueKey="id"
            labelKey="name"
            getOptionLabel={formatCodeName}
        />
    )
}

export default ProgramSelect
