import React from "react"
import {withTranslation} from "react-i18next"
import {isEqual} from "lodash"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {BaseButton, SecondaryButton} from "components/buttons"
import {handleError, handleErrorMessage} from "helpers"
import {TaskType} from "types/tasks"
import {KlassappTableHOC} from "HOC"
import {taskService} from "services"
import {Status} from "sections/Tasks/Common"
import styles from "./TaskList.module.css"

type PageProps = {
    t: Function
    filter: any
    isStaff: boolean
    history?: any
    onClickCancel: any
    onTaskSubmit: any
}

type Props = KlassappTableProps & PageProps

class TaskList extends React.Component<Props, {}> {
    public selectedTask = null

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns}
        ])
        this.getData()
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.filter, this.props.filter)) {
            this.props.dispatch({page: 1}, () => {
                this.getData()
            })
        }
    }

    getPageTitle = () => {
        return this.props.t("tasks.task")
    }

    getFields = () => {
        const {t} = this.props
        return [
            t("tasks.code"),
            t("tasks.name"),
            t("tasks.type"),
            t("tasks.responses"),
            t("tasks.dueDate"),
            t("tasks.status")
        ]
    }

    getData = async () => {
        const {dispatch} = this.props
        dispatch({isLoading: true})
        try {
            const params = this.getParams()
            const {data, total} = await taskService.getAll(params)
            const newData = data.map((task: any) => {
                task.statusHtml = this.renderStatusHtml(task.status, task.allSubtaskRejected)
                task.typeHtml = this.renderTypeHtml(task.type, task.parentId)
                task.responses = task.responsesLabel
                return task
            })
            this.props.dispatch({data: newData, total: total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderTypeHtml = (type, parentId) => {
        const {t} = this.props
        if (parentId) {
            return t("tasks.taskFromLibrary")
        }
        switch (type) {
            case TaskType.DIGITAL_DOCUMENT:
                return t("tasks.digitalDocument")
            case TaskType.SCAN_UPLOAD:
                return t("tasks.scanUpload")
            case TaskType.FROM_LIBRARY:
                return t("tasks.library")
            case TaskType.FORM:
                return t("tasks.form")
            case TaskType.CUSTOM:
                return t("tasks.custom")
            default:
                return ""
        }
    }

    renderStatusHtml = (status, allSubtaskRejected) => {
        return <Status status={status} doneWithRejected={allSubtaskRejected} />
    }

    getParams = () => {
        const {page, pageSize, filter, orderField} = this.props
        const {status, ...restFilter} = filter
        if (status.length) {
            restFilter.status = status
        }
        const params = {
            filter: restFilter,
            range: {
                page,
                pageSize
            },
            sort: {
                orderBy: orderField.field ?? "updatedAt",
                orderDir: orderField.order ?? "DESC"
            }
        }
        return params
    }

    getColumns = () => {
        const {t} = this.props
        return [
            {
                title: t("tasks.code"),
                field: "code",
                style: {width: "150px"},
                sortable: true
            },
            {
                title: t("tasks.name"),
                field: "name",
                style: {minWidth: "150px"},
                orderField: "name",
                sortable: true
            },
            {
                title: t("tasks.type"),
                field: "typeHtml",
                style: {minWidth: "150px"},
                orderField: "type",
                sortable: true
            },
            {
                title: t("tasks.responses"),
                field: "responses",
                style: {minWidth: "150px"},
                orderField: "responses"
            },
            {
                title: t("tasks.dueDate"),
                field: "dueDate",
                style: {minWidth: "150px"},
                orderField: "dueDate",
                fieldType: "date",
                format: "MM/DD/YYYY",
                sortable: true
            },
            {
                title: t("tasks.status"),
                field: "statusHtml",
                style: {minWidth: "150px"},
                orderField: "status",
                sortable: true
            }
        ]
    }

    onTaskSubmit = async () => {
        this.props.onTaskSubmit(this.selectedTask)
        this.resetData()
    }

    onUpdateRowData = (data) => {
        if (data.isChecked) {
            this.selectedTask = data
        } else {
            this.selectedTask = null
        }
    }

    onCancel = async () => {
        this.props.onClickCancel()
        this.resetData()
    }

    resetData = () => {
        let {data} = this.props
        data = data.map((item) => (item.isChecked = false))

        this.props.onUpdateRowData(data)
        this.selectedTask = null
    }

    render() {
        const {page, total, pageSize, columns, data, allFields, fields, tableHeaderActions, isLoading, t, orderField} =
            this.props

        return (
            <div>
                <KlassappTableHeader
                    isShowAction={false}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    isShowCheckedColumn
                    orderField={orderField}
                    onClickRowItem={() => {}}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={(data) => {
                        if (!this.selectedTask || this.selectedTask.id === data.id) {
                            data.isChecked = !!data.isChecked
                            this.props.onUpdateRowData(data)
                            this.onUpdateRowData(data)
                        } else {
                            handleErrorMessage("A task is already selected to be linked")
                        }
                    }}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                <div className={styles.createBtnWrap}>
                    <SecondaryButton
                        title={t("common:action.cancel")}
                        className={styles.cancelBtn}
                        onClick={this.onCancel}
                    />
                    <BaseButton
                        className={styles.submitBtn}
                        title="SELECT TASK"
                        isActive={!!this.selectedTask}
                        onClick={this.onTaskSubmit}
                        loading={isLoading}
                    />
                </div>
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["tasks", "common"])(TaskList))
