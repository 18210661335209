import React, {useState} from "react"
import {Row, Col, Dropdown} from "antd"
import cx from "classnames"
import debounce from "debounce-promise"
import moment from "moment"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {KlassDropdown, KlassDropAsyncPaginate} from "components/Select"
import {AcademicPlans} from "types/academicPlans"
import {academicPlansService, academicCourseGradesService, termsService, courseScheduleService} from "services"
import {handleError, isPositiveFloat, toastError, toastSuccess} from "helpers"
import {FormLabel} from "components/Form"
import {BaseInput} from "components/inputs"
import styles from "./ChangeCourseStatusPopup.module.css"
import {isEmpty} from "lodash"
import {Auth} from "types/auth"
import {useModel} from "hooks"
import {BaseDatePicker} from "components/DateTimePicker"
import {AcademicInstructional} from "types/academicInstructional"

type Props = {
    isShow: boolean
    studentProfileId: number
    sectionCourse: AcademicPlans.SectionCourse
    student: Auth.DepartmentStudent
    onClose: () => void
    reloadView: () => void
}

export function ChangeCourseStatusPopup(props: Props) {
    const {isShow, studentProfileId, sectionCourse, student, onClose, reloadView} = props
    const statuses = [
        {id: AcademicPlans.AcademicPlanCourseStatus.Registered, name: "Registered"},
        {id: AcademicPlans.AcademicPlanCourseStatus.Completed, name: "Completed"},
        {id: AcademicPlans.AcademicPlanCourseStatus.Failed, name: "Failed"},
        {id: AcademicPlans.AcademicPlanCourseStatus.Dropped, name: "Dropped"}
    ]
    const [isSaving, setIsSaving] = useState(false)
    const [info, setInfo] = useState({
        totalGrade: null,
        maxTotalGrade: null,
        term: sectionCourse?.termId ? {id: sectionCourse.termId, name: sectionCourse.termName} : null,
        schedule: sectionCourse?.registeredScheduleId
            ? {id: sectionCourse.registeredScheduleId, schedule_suffix: sectionCourse.scheduleName}
            : null,
        weightedAvgValue: null,
        gpaValue: null,
        alphaValue: null,
        totalValue: null,
        maxTotalValue: null,
        status: statuses.find((item) => item.id === sectionCourse.status)
    })
    const [schedules, setSchedules] = useState([])
    const [dropDate, setDropDate] = useState(null)
    const model = useModel()

    const validateData = () => {
        if (isEmpty(info.term)) {
            toastError("Please select required field")
            return false
        }
        return true
    }

    const updateStatus = async () => {
        const isValid = validateData()
        if (!isValid) {
            return
        }
        try {
            setIsSaving(true)
            await academicPlansService.changeCourseStatus({
                studentProfileId,
                status: info.status.id,
                termId: info.term.id,
                academicPlanCourseId: sectionCourse.academicPlanCourseId,
                scheduleId: info.schedule?.id,
                dropDate: dropDate || undefined
            })
            await academicCourseGradesService.addManualGrade({
                listGrades: [
                    {
                        courseId: sectionCourse.courseId,
                        weightedAvg: info.weightedAvgValue ? parseFloat(info.weightedAvgValue) : 0,
                        isPassed: info.status.id === AcademicPlans.AcademicPlanCourseStatus.Completed,
                        passFail:
                            info.status.id === AcademicPlans.AcademicPlanCourseStatus.Completed
                                ? AcademicInstructional.PassFailStatus.Pass
                                : AcademicInstructional.PassFailStatus.Fail,
                        gpa: info.gpaValue ? parseFloat(info.gpaValue) : 0,
                        alphabetical: info.alphaValue || undefined,
                        totalGrade: info.totalValue ? parseFloat(info.totalValue) : 0,
                        maxTotalGrade: info.maxTotalValue ? parseFloat(info.maxTotalValue) : 0,
                        isVerified: true,
                        studentProfileId: studentProfileId,
                        termId: info.term?.id,
                        scheduleId: info.schedule?.id
                    }
                ]
            })
            toastSuccess("Update successfully")
            onClose()
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsSaving(false)
        }
    }

    const updateField = (field, value) => {
        const newInfo = {...info}
        newInfo[field] = value
        setInfo(newInfo)
    }

    const onChangeInfo = (field, value) => {
        switch (field) {
            case "totalValue":
            case "weightedAvgValue":
            case "gpa":
                if (isPositiveFloat(value)) {
                    updateField(field, value)
                } else {
                    toastError(`Invalid value`)
                }
                break
            case "term":
                getSchedules(value?.id)
                updateField(field, value)
                break
            default:
                updateField(field, value)
                break
        }
    }

    const getSchedules = async (termId: number) => {
        if (!termId) {
            setSchedules([])
            return
        }
        try {
            const {data: schedules} = await courseScheduleService.courseScheduleGet({
                filter: {
                    term_id: termId,
                    course_id: sectionCourse.courseId
                }
            })
            setSchedules(schedules)
        } catch (error) {
            handleError(error)
            setSchedules([])
        }
    }

    const onTermSearchChange = async (search = "", loadedOptions) => {
        try {
            const {data, total} = await termsService.getAllTerms({
                limit: 20,
                offset: loadedOptions.length,
                text: search
            })
            const terms = data.map(({id, name, start_date}) => ({
                id,
                name: `${name} (${moment.utc(start_date).format(model.dateFormat)})`
            }))
            return {
                options: terms,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            handleError(error)
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onTermSearchChangeDebounce = debounce(onTermSearchChange, 300)

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="EDIT_LINE" width="60vw">
            <div className={styles.wrap}>
                <p className={styles.title}>
                    Change Course Status - {sectionCourse.courseName}({sectionCourse.courseCode})
                </p>
                <Row gutter={[24, 12]}>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Status" />
                            <KlassDropdown
                                options={statuses}
                                value={info.status}
                                onChange={(newValue) => onChangeInfo("status", newValue)}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        {info.status.id === AcademicPlans.AcademicPlanCourseStatus.Dropped && (
                            <div>
                                <FormLabel label="Drop Date" />
                                <BaseDatePicker value={dropDate} onChange={(newValue) => setDropDate(newValue)} />
                            </div>
                        )}
                    </Col>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Term" isRequired />
                            <KlassDropAsyncPaginate
                                loadOptions={onTermSearchChangeDebounce}
                                onChange={(newValue) => onChangeInfo("term", newValue)}
                                value={info.term}
                                placeholder="Term"
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <div>
                            <FormLabel label="Schedule" />
                            <KlassDropdown
                                options={schedules}
                                valueKey="id"
                                labelKey="schedule_suffix"
                                value={info.schedule}
                                onChange={(newValue) => onChangeInfo("schedule", newValue)}
                            />
                        </div>
                    </Col>
                </Row>
                <div className={styles.courseGradeWrap}>
                    <FormLabel label="Course Grade" />
                    <Row gutter={[24, 12]}>
                        <Col span={12}>
                            <div>
                                <FormLabel label="Total" />
                                <div className={styles.inputWrap}>
                                    <BaseInput
                                        disabledArrow
                                        className={styles.inputGrade}
                                        type="number"
                                        value={info.totalValue}
                                        onChange={(newValue) => onChangeInfo("totalValue", newValue)}
                                    />
                                    {/* TODO <span className={styles.extraInput}>/ 10</span> */}
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label="Weight AVG" />
                                <div className={styles.inputWrap}>
                                    <BaseInput
                                        disabledArrow
                                        className={styles.inputGrade}
                                        type="number"
                                        value={info.weightedAvgValue}
                                        onChange={(newValue) => onChangeInfo("weightedAvgValue", newValue)}
                                    />
                                    <span className={styles.extraInput}>/ 100</span>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label="Alpha" />
                                <BaseInput
                                    className={styles.inputGrade}
                                    value={info.alphaValue}
                                    onChange={(newValue) => onChangeInfo("alphaValue", newValue)}
                                />
                            </div>
                        </Col>
                        <Col span={12}>
                            <div>
                                <FormLabel label="Point" />
                                <BaseInput
                                    disabledArrow
                                    className={styles.inputGrade}
                                    type="number"
                                    value={info.gpaValue}
                                    onChange={(newValue) => onChangeInfo("gpaValue", newValue)}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className={styles.action}>
                    <BaseButton title="Cancel" variant="secondary" loading={isSaving} onClick={onClose} />
                    <BaseButton title="Save" loading={isSaving} onClick={updateStatus} />
                </div>
            </div>
        </BasePopup>
    )
}
