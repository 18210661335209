/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react"
import {ViewGridType} from "types/settings/documentType"
import {AppliedFilter, FilterKey, FilterList} from "types/filter"
import {useModel, useVisible} from "hooks"
import moment from "moment"
import {DocumentListView} from "./parts/DocumentListView"
import {DocumentBlockView} from "./parts/DocumentBlockView"
import {DocumentStats} from "./parts/DocumentStats"
import {DocumentFilter} from "./parts/DocumentFilter"
import {DocumentUpload} from "./parts/DocumentUpload"
import {ExportFileType} from "components/ui"

type Props = {
    userId?: number
    departmentId?: number
    backUrl?: string
    filterKey?: FilterKey
}

const INITIAL_FILTER: AppliedFilter = {
    search: "",
    filters: {}
}

export function Documents(props: Props) {
    const [viewType, setViewType] = useState<ViewGridType>("list")
    const [appliedFilter, setAppliedFilter] = useState<AppliedFilter>()
    const {backUrl = "/document-hub", departmentId, userId, filterKey = FilterKey.StudentDocument} = props
    const [reloadFilterKey, setReloadFilterKey] = useState<any>()
    const model = useModel()
    const uploadPopup = useVisible(false)
    const [reloadTableKey, setReloadTableKey] = useState<any>()
    const [isExporting, setExporting] = useState(false)
    const [exportFileType, setExportFileType] = useState<ExportFileType>()

    useEffect(() => {
        const storageData = model.getStorageByKey(filterKey)
        if (storageData) {
            setViewType(storageData.viewType ?? "list")
        }
    }, [])

    const onApplyFilter = (payload: AppliedFilter) => {
        setAppliedFilter(payload)
    }

    const filterParams = useMemo(() => {
        const {
            departmentSubunit,
            date,
            status,
            linkedTasks,
            linkedChecklists,
            linkedActivities,
            students,
            startingTerm,
            program,
            campus,
            startDateRange,
            studentStatusIds
        } = appliedFilter?.filters ?? {}

        let filter: FilterList = {linkedTasks, linkedChecklists, linkedActivities}

        if (students) {
            filter.studentUserIds = {
                operator: students.operator,
                value: students.value.map(({id}) => id)
            }
        }
        if (studentStatusIds) {
            filter.studentStatusIds = {
                operator: studentStatusIds.operator,
                value: studentStatusIds.value.map(({statusId}) => statusId)
            }
        }
        if (departmentSubunit) {
            filter.departmentSubunitIds = {
                operator: departmentSubunit.operator,
                value: departmentSubunit.value.map(({subunitId}) => subunitId)
            }
        }
        if (program) {
            filter.programIds = {
                operator: program.operator,
                value: program.value.map(({id}) => id)
            }
        }
        if (campus) {
            filter.campusIds = {
                operator: campus.operator,
                value: campus.value.map(({id}) => id)
            }
        }
        if (startingTerm) {
            filter.startingTermIds = {
                operator: startingTerm.operator,
                value: startingTerm.value.map(({id}) => id)
            }
        }
        if (status) {
            filter.status = {
                operator: status.operator,
                value: status.value.map(({id}) => id)
            }
        }
        if (date) {
            filter.date = {
                operator: date.operator,
                value: Array.isArray(date.value)
                    ? date.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(date.value).format("YYYY-MM-DD")
            }
        }
        if (startDateRange) {
            filter.startDateRange = {
                operator: startDateRange.operator,
                value: Array.isArray(startDateRange.value)
                    ? startDateRange.value.map((v) => moment(v).format("YYYY-MM-DD"))
                    : moment(startDateRange.value).format("YYYY-MM-DD")
            }
        }

        return {
            filters: filter,
            search: appliedFilter?.search,
            userId,
            onlyVisibleForUser: model.isStudent()
        }
    }, [appliedFilter, userId])

    const onExported = () => {
        setExporting(false)
        setExportFileType(undefined)
    }

    const renderContent = () => {
        switch (viewType) {
            case "list":
                return (
                    <DocumentListView
                        key={reloadTableKey}
                        filterParams={filterParams}
                        backUrl={backUrl}
                        departmentId={departmentId}
                        exportFileType={exportFileType}
                        onExported={onExported}
                        onExporting={() => setExporting(true)}
                    />
                )
            case "blocks":
            default:
                return (
                    <DocumentBlockView
                        key={reloadTableKey}
                        filterParams={filterParams}
                        backUrl={backUrl}
                        departmentId={departmentId}
                    />
                )
        }
    }

    const onChangeViewType = (_viewType: ViewGridType) => {
        model.updateStorageFilter(filterKey, {
            viewType: _viewType
        })
        setViewType(_viewType)
    }

    return (
        <div>
            <DocumentStats
                userId={userId}
                onReloadFilter={() => setReloadFilterKey(new Date())}
                filterKey={filterKey}
            />
            <DocumentFilter
                key={reloadFilterKey}
                userId={userId}
                viewTypes={["list", "blocks"]}
                onChangeViewType={onChangeViewType}
                viewType={viewType}
                filterKey={filterKey}
                initialFilter={INITIAL_FILTER}
                onApplyFilter={onApplyFilter}
                onClearFilter={() => onApplyFilter({filters: {}, search: ""})}
                onClickAdd={uploadPopup.open}
                showExport={viewType === "list"}
                isExporting={isExporting}
                handleExport={setExportFileType}
            />
            {renderContent()}
            {uploadPopup.isVisible && (
                <DocumentUpload
                    isShow={uploadPopup.isVisible}
                    onCancel={uploadPopup.close}
                    userId={userId}
                    onDocumentsCreated={() => {
                        uploadPopup.close()
                        setReloadTableKey(new Date())
                    }}
                />
            )}
        </div>
    )
}
