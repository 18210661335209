import React, {useMemo} from "react"
import {Col, Modal, Row} from "antd"
import styles from "./PaymentProcessingModal.module.css"
import {ReactComponent as ProcessingImg} from "assets/images/payment-processing.svg"
import {ReactComponent as FailedImg} from "assets/images/payment-failed.svg"
import {ReactComponent as ConfirmedImg} from "assets/images/payment-confirmed.svg"
import {BaseButton, SecondaryButton} from "components"
import {useHistory} from "react-router-dom"
import {Auth} from "types/auth"
import {useModel} from "hooks"

export type PaymentStatus = "processing" | "failed" | "confirmed"

type PaymentProcessingModalProps = {
    visible: boolean
    status: PaymentStatus
    error?: string
    onClose?: () => void
}

const PaymentProcessingModal: React.FC<PaymentProcessingModalProps> = ({visible, status, error, onClose}) => {
    const history = useHistory()
    const model = useModel()

    const {icon, title, desc} = useMemo(() => {
        switch (status) {
            case "processing":
                return {
                    icon: <ProcessingImg />,
                    title: "Your payment is processing",
                    desc: "Please do not close browser window"
                }
            case "failed":
                return {icon: <FailedImg />, title: "Your payment failed", desc: error}
            default:
                return {
                    icon: <ConfirmedImg />,
                    title: "Your payment was confirmed",
                    desc: error || "Please find your receipt in your Documents"
                }
        }
    }, [status, error])

    return (
        <Modal
            centered
            maskClosable={false}
            closable={false}
            keyboard={false}
            destroyOnClose
            footer={null}
            className={styles.modal}
            visible={visible}>
            <Row justify="center" className={styles.container}>
                <Col span={24} className="text-center">
                    {icon}
                </Col>
                <Col span={24} className={styles.title}>
                    {title}
                </Col>
                <Col span={24} className={styles.desc}>
                    {desc}
                </Col>
                {status === "failed" && (
                    <Col span={24} className="text-center">
                        <SecondaryButton className={styles.btn} title="Close" onClick={onClose} />
                    </Col>
                )}
                {status === "confirmed" && (
                    <Col span={24} className="text-center">
                        <SecondaryButton className={styles.btn} title="Close" onClick={onClose} />
                        {!error && (
                            <BaseButton
                                className={styles.btn}
                                title="Find Receipt"
                                onClick={() => {
                                    onClose?.()
                                    history.push(`/users/${model.user.id}?tab=${Auth.UserDetailTab.Documents}`)
                                }}
                            />
                        )}
                    </Col>
                )}
            </Row>
        </Modal>
    )
}

export default PaymentProcessingModal
