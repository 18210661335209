import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {departmentSubunitService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {DepartmentSubunit} from "types/departments"

const queryCacheKey = "all-department-subunits"
const INITIAL_DATA: DepartmentSubunit[] = []

export const useAllDepartmentSubunits = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data: subunits} = await departmentSubunitService.getSubunits({
                filter: {},
                range: {pageSize: UNLIMITED_PAGE_SIZE, page: 1},
                sort: {orderBy: "name", orderDir: "ASC"},
                linkedEntities: true
            })
            return subunits
        },
        {
            refetchOnMount: false
        }
    )

    return {
        subunits: query.data ?? INITIAL_DATA,
        invalidateDepartmentSubunitsCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
