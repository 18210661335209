import React from "react"
import cx from "classnames"
import styles from "./FormLabel.module.css"

type Props = {
    label: React.ReactNode
    className?: string
    isRequired?: boolean
    subtitle?: React.ReactNode
}

export function FormLabel(props: Props) {
    const {label, className, isRequired, subtitle} = props

    return (
        <div className={cx(styles.label, className)}>
            {label}
            {isRequired && <span className={styles.star}>*</span>}
            {subtitle}
        </div>
    )
}
