import React from "react"
import moment from "moment-timezone"
import cx from "classnames"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableProps} from "types/common"
import {Icon} from "components/Icon"
import {AttendanceHeader, AttendanceDetailPopup} from "./parts"
import styles from "./AttendanceTab.module.css"
import {attendanceService} from "services"
import {cloneDeep} from "lodash"
import {KlassappTableHOC} from "HOC"
import {TFunction, withTranslation} from "react-i18next"
import {Course} from "types/courses"
import {BaseButton, BasePopup, SecondaryButton} from "components"
import {AttendanceStatus} from "types/attendance"
import {convertTZ, handleError, roundTwoDigits} from "helpers"
import {Model} from "Model"
import {LessonAttendancePopup} from "sections/Attendance/parts/LessonAttendancePopup"
import {AcademicInstructional} from "types/academicInstructional"

type State = {
    isShowPopup: boolean
    selectedAttendance: any
    isShowConfirmVerify: boolean
    showLessonAttendancePopup: boolean
    loading: boolean
    attendanceStats?: {
        actionNeeded: number
        attendedHours: number
        missedHours: number
        scheduledHours: number
    }
}

type AttendanceTabProps = KlassappTableProps & {
    studentProfileIds: number[]
    model: Model
    courseIds: number[]
    scheduleId?: number
    courseInfo?: Course.InstructionalCourse
    termIds: number[]
    getAttendanceStats?: () => void
    history?: any
    t?: TFunction
}
class AttendanceTab extends React.Component<AttendanceTabProps, State> {
    constructor(props) {
        super(props)
        this.state = {
            isShowPopup: false,
            attendanceStats: undefined,
            isShowConfirmVerify: false,
            showLessonAttendancePopup: false,
            loading: false,
            selectedAttendance: undefined
        }
    }

    getFields = () => {
        return ["Date", "Time", "CheckIn", "", "CheckOut", "Approved", "Notes", "Type"]
    }

    getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = this.props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    getPageTitle = () => {
        return this.props.t("user.users")
    }

    componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getListAttendances},
            {key: "getFields", func: this.getFields},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getColumns", func: this.getTableColumn},
            {key: "onClickRowItem", func: this.onClickRowItem}
        ])
        this.getListAttendances()
    }

    componentDidUpdate(prevProps: Readonly<AttendanceTabProps>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.courseInfo !== this.props.courseInfo) {
            const {termIds = [], courseIds = [], studentProfileIds = [], scheduleId} = this.props
            this.getAttendanceStats({
                termIds,
                courseIds,
                studentProfileIds,
                scheduleId
            })
        }
    }

    getTableColumn = () => {
        return [
            {
                title: "Date",
                titleHtml: this.renderDateHeader(),
                field: "dateHtml",
                style: {minWidth: "200px"}
            },
            {
                title: "Time",
                titleHtml: this.renderTimeHeader(),
                field: "timeHtml",
                style: {minWidth: "150px"}
            },
            {
                title: "CheckIn",
                field: "checkinHtml",
                style: {minWidth: "300px"}
            },
            {
                title: "CheckOut",
                field: "checkoutHtml",
                style: {minWidth: "120px"}
            },
            {
                title: "Approved",
                field: "statusHtml",
                headerStyle: {textAlign: "center"},
                style: {minWidth: "150px", textAlign: "center"}
            },
            {
                title: "Notes",
                field: "notes",
                style: {minWidth: "300px"}
            },
            {
                title: "Type",
                field: "type"
            }
        ]
    }

    renderDateHeader = () => {
        return (
            <div className={styles.tableHeaderTitleWrap} style={{paddingLeft: 8}}>
                <Icon className={styles.dateIcon} icon="CALENDAR" />
                <p className={styles.tableHeaderTitle}>Date</p>
            </div>
        )
    }

    renderTimeHeader = () => {
        return (
            <div className={styles.tableHeaderTitleWrap}>
                <Icon className={styles.timeIcon} icon="CLOCK" />
                <p className={styles.tableHeaderTitle}>Time</p>
            </div>
        )
    }

    getParams = () => {
        const {studentProfileIds, courseIds, termIds, scheduleId} = this.props
        const params = {
            checkInvite: false,
            studentProfileIds,
            courses: courseIds,
            scheduleId,
            termIds
        }
        return params
    }

    getAttendanceStats = async ({
        termIds = [],
        courseIds = [],
        studentProfileIds = [],
        scheduleId
    }: {
        termIds: number[]
        courseIds: number[]
        scheduleId: number
        studentProfileIds: number[]
    }) => {
        try {
            const params = {
                courseIds: courseIds,
                lessonIds: [],
                studentProfileIds,
                scheduleId,
                termIds: termIds
            }
            const stats = await attendanceService.getClassroomAttendanceStats(params)
            this.setState({
                ...this.state,
                attendanceStats: stats
            })
        } catch (error) {
            handleError(error)
        }
    }
    getListAttendances = async () => {
        const {dispatch, termIds, courseIds, studentProfileIds, scheduleId} = this.props
        dispatch({isLoading: true})
        const params = this.getParams()
        if (this.props.getAttendanceStats) {
            this.props.getAttendanceStats()
        }
        try {
            this.getAttendanceStats({
                termIds,
                courseIds,
                studentProfileIds,
                scheduleId
            })
            const lessons = await attendanceService.getHistoryStudentsAttendanceByCourse(params)
            const total = lessons.length ?? 0
            const data = lessons.map((item: any, index: number) => {
                const lesson = item.lesson
                item.attendance.attendanceId = item.attendance.id
                item.dateHtml = this.renderDate(lesson, item.attendance?.isTransfer)
                item.type = this.renderType(item)
                item.timeHtml = this.renderTime(lesson)
                item.checkinHtml = this.renderCheckInHtml(item.attendance, index)
                item.checkoutHtml = this.renderCheckOutHtml(item.attendance)
                item.notes = this.renderNote(item.attendance.message ?? "")
                item.attendance.attendanceMessage = item.attendance.message
                item.statusHtml = this.renderStatus(item.attendance.status, item.attendance.isVerified === "yes")
                if (
                    item.attendance.status === AttendanceStatus.Irregular ||
                    item.attendance.isVerified === "yes" ||
                    !item.attendance.attendanceId ||
                    !this.props.model.isStaffOrAdmin()
                ) {
                    item.isDisableCheckBox = true
                }
                return item
            })
            dispatch({data: data, total})
        } catch (err) {
            console.log(err)
        } finally {
            dispatch({isLoading: false})
        }
    }

    renderDate = (data, isTransfer: boolean) => {
        const timezone = this.props.courseInfo?.timezone ?? moment.tz.guess()
        return (
            <div>
                {data.startsAt
                    ? moment(convertTZ(new Date(data.startsAt), timezone)).format(this.props.model.getUserDateFormat())
                    : ""}
                {isTransfer ? <span className={styles.isTransfer}>T</span> : null}
            </div>
        )
    }

    renderNote = (data) => {
        return <div>{data}</div>
    }

    renderTime = (data) => {
        const timezone = this.props.courseInfo?.timezone ?? moment.tz.guess()
        return (
            <div>
                {moment(convertTZ(new Date(data.startsAt), timezone)).format(this.props.model.getUserTimeFormat())} -{" "}
                {moment(convertTZ(new Date(data.endsAt), timezone)).format(this.props.model.getUserTimeFormat())}
            </div>
        )
    }

    renderType = (data) => {
        const isMakeup = !!data.isMakeup
        if (isMakeup) {
            return (
                <div className={styles.iconType}>
                    <Icon icon="PLUS_PERSON" color="#666" />
                </div>
            )
        }
        return <div></div>
    }

    renderBreakIcon = (eventType: string, status: string) => {
        switch (eventType) {
            case AcademicInstructional.AttendanceEventType.BreakStart:
                return <Icon icon="ATTENDANCE_OUT" color={"#DF1642"} className={styles.attendanceOutIcon} />
            case AcademicInstructional.AttendanceEventType.BreakEnd:
                return <Icon icon="ATTENDANCE_IN" color={"#18A957"} className={styles.breakItem__icon} />
            default:
                return (
                    <Icon
                        icon="ATTENDANCE_IN"
                        color={status === "success" ? "#18A957" : "#E6E6E6"}
                        className={styles.breakItem__icon}
                    />
                )
        }
    }

    renderCheckOutHtml = (item) => {
        const timezone = this.props.courseInfo?.timezone ?? moment.tz.guess()
        const checkoutEvent = (item?.events ?? []).find(
            (event) => event.eventType === AcademicInstructional.AttendanceEventType.Checkout
        )
        return (
            <div className={styles.checkInWrap}>
                <div className={styles.checkInIconWrap}>
                    <Icon
                        icon="CHECKOUT_FILL"
                        className={styles.checkInIcon}
                        color={
                            checkoutEvent?.status === AcademicInstructional.CheckInStatus.Success
                                ? "#1E90FF"
                                : "#E6E6E6"
                        }
                    />
                </div>
                {checkoutEvent && checkoutEvent.status === AcademicInstructional.CheckInStatus.Success ? (
                    <span className={styles.checkInTime}>
                        {moment(convertTZ(new Date(checkoutEvent?.createdAt), timezone)).format(
                            this.props.model.getUserTimeFormat()
                        )}
                    </span>
                ) : null}
            </div>
        )
    }

    renderCheckInHtml = (item: AcademicInstructional.LessonStudent, index: number) => {
        const timezone = this.props.courseInfo?.timezone ?? moment.tz.guess()
        const checkInEvent = (item?.events ?? []).find(
            (event) =>
                event.eventType === AcademicInstructional.AttendanceEventType.Checkin && event.status === "success"
        )
        const checkOutEvent = (item?.events ?? []).find(
            (event) =>
                event.eventType === AcademicInstructional.AttendanceEventType.Checkout && event.status === "success"
        )
        const breakEvents = (item?.events ?? []).filter(
            (event) =>
                event.eventType !== AcademicInstructional.AttendanceEventType.Checkin &&
                event.eventType !== AcademicInstructional.AttendanceEventType.Checkout
        )

        return (
            <div className={styles.checkInContainer}>
                <div className={styles.checkInWrap}>
                    <div className={styles.checkInIconWrap}>
                        <Icon
                            icon="CHECKIN_FILL"
                            className={styles.checkInIcon}
                            color={
                                checkInEvent?.status === AcademicInstructional.CheckInStatus.Success
                                    ? "#1E90FF"
                                    : "#E6E6E6"
                            }
                        />
                    </div>
                    <span className={styles.checkInTime}>
                        {checkInEvent?.status === AcademicInstructional.CheckInStatus.Success
                            ? moment(convertTZ(new Date(checkInEvent.createdAt), timezone)).format(
                                  this.props.model.getUserTimeFormat()
                              )
                            : ""}
                    </span>
                </div>
                <div className={styles.breakWrap}>
                    {breakEvents.map((event, index) => {
                        const isRenderCheckInLine = index === 0 && checkInEvent
                        const isRenderCheckOutLine =
                            event.eventType !== AcademicInstructional.AttendanceEventType.BreakStart &&
                            breakEvents.length &&
                            index === breakEvents.length - 1 &&
                            checkOutEvent
                        return (
                            <div key={event.id} className={styles.attendanceContainer}>
                                {isRenderCheckInLine ||
                                (index > 0 &&
                                    (breakEvents[index - 1].eventType ===
                                        AcademicInstructional.AttendanceEventType.BreakEnd ||
                                        breakEvents[index - 1].eventType ===
                                            AcademicInstructional.AttendanceEventType.AttendanceCheck)) ? (
                                    <div
                                        className={styles.progressLine}
                                        style={{
                                            right: "50%",
                                            left: isRenderCheckInLine ? -80 : "-50%"
                                        }}
                                    />
                                ) : null}
                                {isRenderCheckOutLine ? (
                                    <div
                                        className={styles.progressLine}
                                        style={{
                                            right: -70,
                                            left: "50%"
                                        }}
                                    />
                                ) : null}
                                <div className={styles.breakItem}>
                                    <div className={styles.checkInIconWrap}>
                                        {this.renderBreakIcon(event.eventType, event.status)}
                                    </div>
                                    <span className={styles.checkInTime}>
                                        {moment(convertTZ(new Date(event.createdAt), timezone)).format(
                                            this.props.model.getUserTimeFormat()
                                        )}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {checkInEvent && checkOutEvent && breakEvents.length === 0 ? (
                    <div
                        className={styles.progressLine}
                        style={{
                            right: -70,
                            left: 30
                        }}
                    />
                ) : null}
            </div>
        )
    }

    renderStatus = (status, isVerified = false) => {
        switch (true) {
            case isVerified === true && status === "approved":
                return (
                    <div className={styles.attCheckVerified}>
                        <Icon icon="CHECKED" />
                    </div>
                )
            case status === "approved":
                return (
                    <div className={styles.attCheck}>
                        <Icon icon="CHECKED" />
                    </div>
                )
            case status === "irregular":
                return (
                    <div className={styles.actionWarning}>
                        <Icon icon="WARNING_TRIANGLE_FILL" />
                    </div>
                )
            case status === "rejected":
                return (
                    <div className={styles.attClose}>
                        <Icon icon="CROSS_CIRCLE" />
                    </div>
                )
            default:
                return <></>
        }
    }

    onClickRowItem = (data) => {
        const {attendance, lesson} = data
        const item = {
            ...attendance,
            ...lesson,
            lessonId: lesson.id,
            lessonStatus: lesson.status,
            attendanceStatus: attendance.status
        }
        if (this.props.model.isStaffOrAdmin()) {
            this.setState({
                isShowPopup: true,
                selectedAttendance: {...item, attendedMinutes: `${roundTwoDigits((item.attendedHours ?? 0) * 60)}`}
            })
        } else {
            this.setState({
                ...this.state,
                showLessonAttendancePopup: true,
                selectedAttendance: {...item, attendedMinutes: `${roundTwoDigits((item.attendedHours ?? 0) * 60)}`}
            })
        }
    }

    onClosePopup = () => {
        this.setState({isShowPopup: false})
        this.setState({
            selectedAttendance: undefined
        })
    }
    onPressVerify = async () => {
        this.setState({
            ...this.state,
            loading: true
        })
        try {
            const selectedAttendances = this.props.data.filter((item) => item.isChecked)
            const params = selectedAttendances.map((item) => ({
                attendanceId: item.attendance.attendanceId,
                status: item.attendance.status,
                isVerified: true,
                profileId: this.props.studentProfileIds[0],
                lessonId: item.lesson.id
            }))
            await attendanceService.updateAttendance(params)
            await this.getListAttendances()
            this.onCancelConfirm()
        } catch (err) {
            handleError(err)
        } finally {
            this.setState({
                ...this.state,
                loading: false
            })
        }
    }
    onCancelConfirm = () =>
        this.setState({
            ...this.state,
            isShowConfirmVerify: false
        })
    onShowConfirm = () =>
        this.setState({
            ...this.state,
            isShowConfirmVerify: true
        })

    tableHeaderActions = () => {
        return [
            {
                title: (
                    <div className={styles.actionWrap}>
                        <BaseButton
                            title="Verify"
                            variant="secondary"
                            className={styles.verifybtn}
                            onClick={this.onShowConfirm}
                            loading={false}
                        />
                    </div>
                ) as any,
                icon: null,
                action: () => {}
            }
        ]
    }

    onUpdateRowData = (updatedData) => {
        const data = this.props.getCurrentData()
        const newData = cloneDeep(data).map((item) => {
            if (item.lesson.id === updatedData.lesson.id) {
                return {
                    ...item,
                    isChecked: updatedData.isChecked
                }
            }
            return item
        })
        this.props.dispatch({data: newData})
    }
    render() {
        const {isShowPopup} = this.state
        const {
            page,
            total,
            pageSize,
            columns,
            data,
            allFields,
            fields,
            t,
            isLoading,
            orderField,
            studentProfileIds,
            courseIds,
            termIds,
            courseInfo
        } = this.props

        return (
            <div>
                <AttendanceHeader attendanceStats={this.state.attendanceStats} />
                <KlassappTableHeader
                    page={page}
                    total={total}
                    isShowAction={!!data.find((item) => item.isChecked)}
                    actions={this.tableHeaderActions()}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    data={data}
                    menuActions={[]}
                    isLoading={isLoading}
                    fields={fields}
                    allFields={allFields}
                    orderField={orderField}
                    isShowCheckedColumn
                    onUpdateRowData={this.onUpdateRowData}
                    isShowCheckedColumnHeader={false}
                    onClickRowItem={this.onClickRowItem}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onClickSortColumn={this.props.onClickSortColumn}
                />
                <AttendanceDetailPopup
                    actualStartedAt={this.state.selectedAttendance?.actualStartedAt}
                    actualEndedAt={this.state.selectedAttendance?.actualEndedAt}
                    studentProfileIds={studentProfileIds}
                    courseIds={courseIds}
                    getListAttendances={this.getListAttendances}
                    termIds={termIds}
                    loading={this.state.loading}
                    courseInfo={courseInfo}
                    selectedAttendance={this.state.selectedAttendance}
                    isShow={isShowPopup}
                    onClose={this.onClosePopup}
                />
                <BasePopup
                    isShow={this.state.isShowConfirmVerify}
                    onClose={this.onCancelConfirm}
                    width="70vw"
                    leftIcon="WARNING_SOLID"
                    leftIconColor="#fff">
                    <div className={styles.wrap}>
                        <div className={styles.popupTitle}>{t("common:confirmationPopup.confirmVerifyAttendance")}</div>
                        <span className={styles.smallTitle}>
                            {t("common:confirmationPopup.smallTextVerifyAttendance")}
                        </span>
                        <div className={styles.footer}>
                            <div className={cx(styles.actionDetailWrapPopup)}>
                                <div className={styles.button}>
                                    <SecondaryButton
                                        onClick={this.onCancelConfirm}
                                        title={t("common:action.cancelModal")}
                                    />
                                </div>
                                <div className={cx(styles.button, styles.buttonPopupConfirm)}>
                                    <BaseButton
                                        onClick={this.onPressVerify}
                                        title={t("common:action.confirm")}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </BasePopup>
                {!this.props.model.isStaffOrAdmin() ? (
                    <LessonAttendancePopup
                        studentProfileIds={[this.props.model.profileId]}
                        isShow={this.state.showLessonAttendancePopup}
                        lessonId={this.state.selectedAttendance?.lessonId}
                        courseId={this.state.selectedAttendance?.courseId}
                        status={this.state.selectedAttendance?.lessonStatus}
                        termId={this.state.selectedAttendance?.termId}
                        onClose={() => this.setState({...this.state, showLessonAttendancePopup: false})}
                    />
                ) : null}
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["user"])(AttendanceTab))
