import React, {Component} from "react"
import styles from "./Button.module.css"
import cx from "classnames"

type Props = {
    className?: string
    label: string
    disabled?: boolean
    type?: "secondary" | "calendar" | "transparent"
    onClick: (e?) => void
    showDropDown?: boolean
}

export class Button extends Component<Props, {}> {
    private onClick(event) {
        const {onClick} = this.props
        event.preventDefault()
        event.stopPropagation()
        onClick(event)
    }

    public render() {
        const {label, disabled, type, showDropDown, className} = this.props
        return (
            <button
                className={cx(
                    styles.button,
                    {
                        [styles["button--secondary"]]: type === "secondary",
                        [styles.calendarButton]: type === "calendar",
                        [styles["button--transparent"]]: type === "transparent"
                    },
                    className
                )}
                onClick={this.onClick.bind(this)}
                disabled={disabled}>
                {label}
                <span className={cx(!showDropDown && styles.hideDropdownArrow, styles.dropdownSymbol)}>^</span>
            </button>
        )
    }
}
