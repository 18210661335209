import React from "react"
import styles from "./ProgressReportPdf.module.css"
import CourseClock from "./CourseClock"
import moment from "moment-timezone"
import {StatusTerm} from "../CurrentProgress/CurrentProgress"
import {get} from "lodash"
import {formatDateOnly, formatDecimal, getFieldLabel} from "helpers"
import {Settings} from "types/settings"
import {useModel} from "hooks"
import {GeneralSettingField} from "types/settings/general-setting"

type TermCourseClockProps = {
    listTableDegreeAudit: {
        cgpa: number
        startDate: string
        endDate: string
        name: string
        termId: number
        totalCredit: number
        totalGpa: number
        type: "clock" | "credit"
    }[]
    transcriptType: GeneralSettingField
    profileId: number
    listOfSubmodules: string[]
    setTotalHoursSubModules?: (value: number) => void
    setListOfSubmodules: (value: string[]) => void
}

const TermCourseClock = (props: TermCourseClockProps) => {
    const {
        listTableDegreeAudit = [],
        profileId,
        transcriptType,
        listOfSubmodules,
        setListOfSubmodules,
        setTotalHoursSubModules
    } = props
    const model = useModel()

    const checkStatus = (startDate: string, endDate: string) => {
        const momentStart = moment(startDate)
        const momentEnd = moment(endDate)
        const today = moment()
        if (today.isSameOrAfter(momentStart) && today.isSameOrBefore(momentEnd)) {
            return StatusTerm.InProgress
        } else if (today.isAfter(momentEnd)) {
            return StatusTerm.Ended
        } else {
            return StatusTerm.NotStarted
        }
    }
    if (transcriptType === GeneralSettingField.ProgressReportSubmodulesBreakDown) {
        return (
            <div className={styles.tableContainer}>
                <div className={styles.tableTerm}>
                    <CourseClock
                        transcriptType={transcriptType}
                        termId={undefined}
                        setTotalHoursSubModules={setTotalHoursSubModules}
                        profileId={profileId}
                        setListOfSubmodules={setListOfSubmodules}
                        listOfSubmodules={listOfSubmodules}
                    />
                </div>
            </div>
        )
    }
    return (
        <div className={styles.tableContainer}>
            {listTableDegreeAudit.map((table, index) => {
                const status = checkStatus(get(table, ["startDate"], ""), get(table, ["endDate"], ""))
                const startDate = formatDateOnly(table.startDate, model.getUserDateFormat())
                const endDate = formatDateOnly(table.endDate, model.getUserDateFormat())
                const gpa = typeof table?.totalGpa === "number" ? formatDecimal(table?.totalGpa) : table?.totalGpa
                const cgpa = typeof table?.cgpa === "number" ? formatDecimal(table.cgpa) : table?.cgpa
                return (
                    <div key={index.toString()} className={styles.tableTerm}>
                        <div className={styles.tableTermTitle}>
                            <div className={styles.termName}>
                                <span className={styles.tableTitle}>{table.name}</span>
                                <span className={styles.tableTitle}>
                                    {startDate} - {endDate}
                                </span>
                            </div>
                            <div className={styles.tableTermTitle}>
                                <div className={styles.gpaBlock}>
                                    <span className={styles.cgpaText}>
                                        {getFieldLabel(model, Settings.AcademicLabel.Gpa, "GPA")}
                                    </span>
                                    <span className={styles.cgpaValue}>{gpa}</span>
                                </div>
                                <div className={styles.cgpaBlock}>
                                    <span className={styles.cgpaText}>
                                        {getFieldLabel(model, Settings.AcademicLabel.Cgpa, "CGPA")}
                                    </span>
                                    <span className={styles.cgpaValue}>{cgpa}</span>
                                </div>
                            </div>
                        </div>
                        <CourseClock
                            transcriptType={transcriptType}
                            status={status}
                            gpa={gpa}
                            termId={table.termId}
                            profileId={profileId}
                            setListOfSubmodules={setListOfSubmodules}
                            listOfSubmodules={listOfSubmodules}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default TermCourseClock
