import {BaseButton, BaseInput, BaseLoading, BasePopup} from "components"
import {useCallback, useEffect, useState} from "react"
import styles from "./PopupEditCourseGrade.module.css"
import {FormLabel} from "components/Form"
import {handleError} from "helpers"
import {useTranslation} from "react-i18next"
import {academicCourseGradesService} from "services"
import moment from "moment"

type Props = {
    isShow: boolean
    onClose: () => void
    onRefresh: () => void
    courseGradesStudent?: {
        date: string
        academicCourseGradeStudentId?: number
        studentProfileId: number
        maxPointValue: number
        grade: number
        title: string
        academicCourseGradeId: number
        gradingTemplateTitle: string
        termId: number
        scheduleId?: number
        courseId: number
        notes?: string
    }
}

export function PopupEditCourseGrade(props: Props) {
    const {isShow, onClose, courseGradesStudent, onRefresh} = props
    const {
        academicCourseGradeStudentId,
        studentProfileId,
        grade: initialGrade,
        maxPointValue: initialMaxPointValue,
        academicCourseGradeId,
        title,
        gradingTemplateTitle,
        termId,
        scheduleId,
        date,
        courseId
    } = courseGradesStudent || {}
    const [isLoading, setIsLoading] = useState(false)
    const [grade, setGrade] = useState<number>()
    const [maxPointValue, setMaxPointValue] = useState<number>()
    const {t} = useTranslation(["common"])

    useEffect(() => {
        setGrade(initialGrade)
        setMaxPointValue(initialMaxPointValue)
    }, [initialGrade, initialMaxPointValue])

    const onSubmit = useCallback(async () => {
        try {
            setIsLoading(true)
            await academicCourseGradesService.updateCourseGradeStudents({
                courseGradeStudent: {
                    studentProfileId: studentProfileId,
                    grade: parseFloat(grade as any),
                    date,
                    courseId,
                    maxPointValue: parseFloat(maxPointValue as any),
                    termId,
                    scheduleId,
                    academicCourseGradeId,
                    academicCourseGradeStudentId
                }
            })
            setIsLoading(false)
            onRefresh()
            onClose()
        } catch (err) {
            handleError(err)
            setIsLoading(false)
        }
    }, [
        studentProfileId,
        grade,
        date,
        courseId,
        maxPointValue,
        termId,
        scheduleId,
        academicCourseGradeId,
        academicCourseGradeStudentId,
        onRefresh,
        onClose
    ])

    const onChangeGradeValue = useCallback((text) => {
        setGrade(text)
    }, [])
    const onChangeMaxPointValue = useCallback((text) => {
        setMaxPointValue(text)
    }, [])

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_LINE_ALT" width="50vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Edit Grade ({moment(date).format("MM/DD")})</p>
                <FormLabel label="Grading Template" />
                <BaseInput disabled placeholder={"Title"} disabledArrow value={gradingTemplateTitle} />
                <FormLabel label="Title" />
                <BaseInput disabled placeholder={"Title"} disabledArrow value={title} />
                <FormLabel label="Grade" className={styles.formLabel} />
                <BaseInput onChange={onChangeGradeValue} placeholder={"Grade"} disabledArrow value={grade} />
                <FormLabel label="Max Point" className={styles.formLabel} />
                <BaseInput
                    onChange={onChangeMaxPointValue}
                    disabled
                    placeholder={"Max Point"}
                    disabledArrow
                    value={maxPointValue}
                />
                <div className={styles.submitContainer}>
                    <BaseButton
                        title={isLoading ? t("common:action.saving") : t("common:action.save")}
                        onClick={onSubmit}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </div>
                <BaseLoading isShow={isLoading} />
            </div>
        </BasePopup>
    )
}
