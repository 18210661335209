import React from "react"
import {useHistory} from "react-router-dom"
import {BasePopup} from "components/popup"
import {BaseButton, SecondaryButton} from "components/buttons"
import styles from "./TaskSubmissionDisclosureConfirm.module.css"

export function TaskSubmissionDisclosureConfirm(props) {
    const {isShow, onClose, taskId, subTaskId, backUrl} = props
    const history = useHistory()

    const onClickAgree = () => {
        let searchUrl = `id=${taskId}`
        if (subTaskId) {
            searchUrl += `&subTaskId=${subTaskId}`
        }
        history.push({pathname: `/tasks/submissions`, search: searchUrl, state: {backUrl}})
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="FILE_TEXT" width="70vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Please Confirm</p>
                <p className={styles.description}>Consumer Disclosure &amp; Electronic Delivery Consent </p>
                <div className={styles.content}>
                    <p>
                        The document sender from your college has sent you a document to sign through the Klass App's
                        electronic signature system. In compliance with the U.S. Electronic Signatures in Global and
                        National Commerce Act, known as the E-Sign Act,
                    </p>
                    <p>
                        Klass App is providing you with the following information regarding your decision to receive
                        information in electronic form. You can record your consent to the E-SIGN disclosure by placing
                        a checkmark in the box below and click “Continue”.
                    </p>
                    <p>
                        By checking the boxes you consent to the electronic delivery of the disclosures, agreements,
                        change notices, terms and conditions and any other documents. You also agree that we do not need
                        to provide you with additional paper (non- electronic) copies of the disclosures, agreements,
                        change notices, terms and conditions and any other documents, unless specifically requested.{" "}
                    </p>
                    <p>Once you consent to the disclosures, you may request paper copies, which may result in a fee.</p>
                </div>
                <div className={styles.actionBtnWrap}>
                    <SecondaryButton title="Cancel" className={styles.cancelBtn} onClick={onClose} />
                    <BaseButton title="Agree" onClick={onClickAgree} className={styles.agreeBtn} />
                </div>
            </div>
        </BasePopup>
    )
}
