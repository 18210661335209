/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {Checkbox, Popover} from "antd"
import {Icon} from "components/Icon"
import {BasePopup} from "components/popup"
import {BaseInput} from "components/inputs"
import {BaseButton} from "components/buttons"
import styles from "./AttendanceDetailPopup.module.css"
import {attendanceService, profileService} from "services"
import {Course} from "types/courses"
import {clone, cloneDeep, get} from "lodash"
import {convertTZ, getUserPhotoUrl, handleError, toastWarning} from "helpers"
import moment from "moment-timezone"
import {BaseLoading} from "components"
import {useModel} from "hooks"
import {AttendanceEvent, AttendanceEventStatus, AttendanceEventType, AttendanceStatus} from "types/attendance"
import cx from "classnames"
import {BaseTimePicker} from "components/DateTimePicker"
import {v4 as uuid} from "uuid"
import {Major} from "types/major"

const EVENT_OPTIONS: Array<{name: string; value: AttendanceEventType}> = [
    {
        name: "Checkin",
        value: AttendanceEventType.Checkin
    },
    {
        name: "Checkout",
        value: AttendanceEventType.Checkout
    },
    {
        name: "In",
        value: AttendanceEventType.BreakEnd
    },
    {
        name: "Out",
        value: AttendanceEventType.BreakStart
    }
]

type Props = {
    isShow: boolean
    studentProfileIds?: number[]
    courseIds?: number[]
    termIds?: number[]
    actualStartedAt: string
    actualEndedAt: string
    courseInfo?: Course.InstructionalCourse | Course.CourseTableItem
    loading?: boolean
    selectedAttendance?: any
    getListAttendances?: () => void
    onClose: () => void
}

export function AttendanceDetailPopup(props: Props) {
    const {
        isShow,
        onClose,
        studentProfileIds = [],
        actualStartedAt,
        actualEndedAt,
        courseIds = [],
        loading,
        getListAttendances,
        termIds = [],
        selectedAttendance,
        courseInfo
    } = props
    const deleteEventIds = useRef<number[]>([])
    const {t} = useTranslation(["studentServices"])
    const [listEvent, setListEvent] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAttended, setIsAttended] = useState(null)
    const [showPopover, setShowPopover] = useState({})
    const [isApproved, setIsApproved] = useState(false)
    const [message, setMessage] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isExcused, setIsExcused] = useState(false)
    const [studentInfo, setStudentInfo] = useState<any>()
    const [dateData, setDateData] = useState({
        checkInTime: "",
        checkOutTime: "",
        checkInDate: ""
    })
    const model = useModel()
    const timeFormat = model.getUserTimeFormat()
    const dateFormat = model.getUserDateFormat()

    const avatarUrl = studentInfo?.photo ? getUserPhotoUrl(studentInfo?.photo, 512) : "/image/DefaultAvatar.png"

    const getStudentInfo = useCallback(async () => {
        const {data: student} = await profileService.getOne({
            id: studentProfileIds[0],
            type: "student"
        })
        setStudentInfo(student)
    }, [studentProfileIds])

    const getAttendanceDetail = useCallback(async () => {
        setIsLoading(true)
        try {
            const {attendances = []} = await attendanceService.getDetailHistoryStudentsAttendanceByCourse({
                termIds,
                courseIds,
                studentProfileIds,
                attendanceIds: [selectedAttendance.attendanceId]
            })
            if (attendances?.length) {
                const attendance = get(attendances, [0])
                setDateData({
                    ...dateData,
                    checkInTime: attendance?.checkInTime
                        ? moment(convertTZ(new Date(attendance.checkInTime), courseInfo?.timezone)).format(timeFormat)
                        : "",
                    checkOutTime: attendance?.checkOutTime
                        ? moment(convertTZ(new Date(attendance.checkOutTime), courseInfo?.timezone)).format(timeFormat)
                        : "",
                    checkInDate: attendance?.checkInTime
                        ? moment(convertTZ(new Date(attendance.checkInTime), courseInfo?.timezone)).format(dateFormat)
                        : ""
                })
                let events = attendance?.events || []
                setListEvent(events)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setIsLoading(false)
        }
    }, [studentProfileIds, termIds, courseIds, selectedAttendance])

    useEffect(() => {
        studentProfileIds.length && getStudentInfo()
        if (isShow && selectedAttendance && studentProfileIds?.length && selectedAttendance?.attendanceId) {
            getAttendanceDetail()
            const isVerified = selectedAttendance.isVerified === "yes"
            const isApproved =
                selectedAttendance.attendanceStatus === "approved"
                    ? true
                    : selectedAttendance.attendanceStatus === "rejected"
                    ? false
                    : null
            const isExcused = selectedAttendance.isExcused
            const attendanceMessage = selectedAttendance.attendanceMessage ?? ""
            setMessage(attendanceMessage)
            setIsApproved(isVerified)
            setIsAttended(courseInfo.type === "credit" ? isApproved : true)
            setIsExcused(isExcused)
        }
    }, [isShow, studentProfileIds, courseIds, termIds, selectedAttendance])

    useEffect(() => {
        if (!isShow) {
            setStudentInfo(undefined)
            setMessage("")
            setListEvent([])
            deleteEventIds.current = []
        }
    }, [isShow])

    const renderStudentInfo = () => {
        return (
            <div className={styles.studentWrapper}>
                <div className={styles.imgWrapper}>
                    <img src={avatarUrl} className={styles.avatar} alt="" />
                </div>
                <div>
                    <p className={styles.studentName}>{studentInfo?.fullName}</p>
                    <div className={styles.flexContainer}>
                        <div className={styles.columnContainer}>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>{t("popup.userId")}: </span>
                                <span className={styles.idValue}>{studentInfo?.userId}</span>
                            </div>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>{t("popup.enrollmentId")}: </span>
                                <span className={styles.idValue}>{studentInfo?.customProfileId}</span>
                            </div>
                        </div>

                        <div className={styles.columnContainer}>
                            <div className={styles.labelContainer}>
                                <span className={styles.idLabel}>{t("popup.course")}: </span>
                                <span className={styles.courseValue}>{courseInfo?.name || courseInfo?.courseName}</span>
                            </div>
                            {selectedAttendance ? (
                                <div className={styles.labelContainer}>
                                    <span className={styles.idLabel}>ATTENDED MINUTES:</span>
                                    <span className={styles.courseValue}>
                                        {selectedAttendance?.attendedMinutes ?? 0}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderTitle = (eventType: string) => {
        if (eventType === "check-out") {
            return t("popup.checkout")
        } else if (eventType === "check-in") {
            return t("popup.checkin")
        } else if (eventType === "break-start") {
            return "Out"
        } else if (eventType === "break-end") {
            return "In"
        } else if (eventType === "lessonStart") {
            return "Lesson Start"
        } else if (eventType === "lessonEnd") {
            return "Lesson End"
        } else if (eventType === "attendance-check") {
            return "In"
        } else {
            return "Select"
        }
    }

    const renderIconAttendance = (eventType: string, status: string) => {
        if (eventType === "check-out") {
            return (
                <Icon
                    icon="CHECKOUT_FILL"
                    color={status === "success" ? "#1E90FF" : "#E6E6E6"}
                    className={styles.checkOutIcon}
                />
            )
        } else if (eventType === "check-in") {
            return (
                <Icon
                    icon="CHECKIN_FILL"
                    color={status === "success" ? "#1E90FF" : "#E6E6E6"}
                    className={styles.rightIcon}
                />
            )
        } else if (eventType === "break-start") {
            return <Icon icon="ATTENDANCE_OUT" color={"#DF1642"} className={styles.rightIcon} />
        } else if (eventType === "break-end") {
            return <Icon icon="ATTENDANCE_IN" color={"#18A957"} className={styles.rightIcon} />
        } else if (eventType === "attendance-check") {
            return (
                <Icon
                    icon="ATTENDANCE_IN"
                    color={status === "success" ? "#18A957" : "#E6E6E6"}
                    className={styles.rightIcon}
                />
            )
        } else {
            return <div className={styles.rightIcon} />
        }
    }

    const onPressDeleteEvent = (event: AttendanceEvent) => {
        typeof event.id === "number" && deleteEventIds.current.push(event.id)
        const events = listEvent.filter((item) => item.id !== event.id)
        setListEvent([...events])
    }

    const onAddEvent = (e) => {
        let newListEvent = clone(listEvent)
        newListEvent.push({
            status: "success",
            id: uuid()
        })
        setListEvent(newListEvent)
    }

    const renderAddEvent = () => {
        return (
            <div className={styles.buttonAddEvent}>
                <div onClick={onAddEvent} className={styles.addEventContainer}>
                    <span>Add event</span>
                </div>
            </div>
        )
    }

    const renderPopoverSelectEvent = (event: AttendanceEvent) => {
        const checkIn = listEvent.find(
            (item) => item.eventType === AttendanceEventType.Checkin && item.status === "success"
        )
        const checkOut = listEvent.find(
            (item) => item.eventType === AttendanceEventType.Checkout && item.status === "success"
        )
        let options = cloneDeep(EVENT_OPTIONS)
        if (event?.eventType) {
            options = options.filter((item) => item.value !== event.eventType)
        }
        if (checkIn) {
            options = options.filter((item) => item.value !== AttendanceEventType.Checkin)
        }
        if (checkOut) {
            options = options.filter((item) => item.value !== AttendanceEventType.Checkout)
        }
        return (
            <div className={styles.selectContainer}>
                {options.map((option) => (
                    <div
                        onClick={() => {
                            event.eventType = option.value
                            setListEvent([...listEvent])
                            showPopover[event.id] = false
                            setShowPopover({...showPopover})
                        }}
                        className={cx(styles.popupContainer, styles.hoverBackgroundGrey)}>
                        {renderIconAttendance(option.value, "success")}
                        <span style={{width: 120}} className={styles.infoLabel}>
                            {renderTitle(option.value)}
                        </span>
                    </div>
                ))}
            </div>
        )
    }

    const renderCheckInItem = (data: any) => {
        const event = listEvent.find((item) => item.id === data.id)
        const isRenderDelete = event?.status === "success"
        return (
            <div key={event?.id ?? uuid()}>
                <div className={styles.infoWrapper}>
                    {event?.status === "success" ? (
                        <>
                            <BaseTimePicker
                                style={{width: 130, marginRight: 24}}
                                value={
                                    data?.createdAt
                                        ? moment(convertTZ(new Date(data?.createdAt), courseInfo?.timezone))
                                        : ""
                                }
                                format={timeFormat}
                                allowClear={false}
                                onChange={(date) => {
                                    event.createdAt = moment(date).tz(courseInfo?.timezone, true)
                                    setListEvent([...listEvent])
                                }}
                                placeholder="Time"
                            />
                        </>
                    ) : (
                        <div style={{backgroundColor: "transparent"}} className={styles.hourLabel} />
                    )}
                    {event?.status === "success" ? (
                        <Popover
                            placement="right"
                            color="white"
                            visible={showPopover[event.id]}
                            arrowContent={null}
                            onVisibleChange={(visible) => {
                                showPopover[event.id] = visible
                                setShowPopover({...showPopover})
                            }}
                            content={() => renderPopoverSelectEvent(event)}
                            trigger="click">
                            <div className={styles.popupContainer}>
                                {renderIconAttendance(data?.eventType, data.status)}
                                <span
                                    style={{width: 80, color: data?.eventType ? "black" : "rgba(0,0,0,0.3)"}}
                                    className={styles.infoLabel}>
                                    {renderTitle(data?.eventType)}
                                </span>
                            </div>
                        </Popover>
                    ) : (
                        <span style={{width: 80}} className={styles.infoLabel}>
                            {renderTitle(data?.eventType)}
                        </span>
                    )}
                    {event?.status === "success" ? (
                        <BaseInput
                            value={event?.message ?? ""}
                            onChange={(text) => {
                                event.message = text
                                setListEvent([...listEvent])
                            }}
                            placeholder={t("popup.notes")}
                            className={styles.inputWidth}
                        />
                    ) : (
                        <div className={styles.inputWidth} />
                    )}
                    {isRenderDelete ? (
                        <div onClick={() => onPressDeleteEvent(event)}>
                            <Icon icon="DELETE" className={styles.deleteIcon} />
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }

    const renderLessonStartEndEvent = (time: string, type: "lessonEnd" | "lessonStart") => {
        return (
            <div className={styles.infoWrapper}>
                <span className={styles.hourLabel}>
                    {moment(convertTZ(new Date(time), courseInfo?.timezone)).format(timeFormat)}
                </span>
                <span className={styles.infoLabel}>{renderTitle(type)}</span>
                <div style={{display: "flex", flex: 1}} />
            </div>
        )
    }

    const renderAttendAction = () => {
        return (
            <div className={styles.attendedWrapper}>
                <div className={styles.attendedBody}>
                    <p className={styles.attendedTitle}>{t("popup.attended")}</p>
                    <div className={styles.attendedBodyItem} onClick={() => setIsAttended(true)}>
                        <span className={styles.optionLabel}>{t("popup.yes")}</span>
                        <div
                            className={cx(styles.unchecked, {
                                [styles.checked]: isAttended === true
                            })}>
                            <Icon icon="TICK" className={styles.attendanceTickIcon} color="#e5e5e5" />
                        </div>
                    </div>
                    <div className={styles.attendedBodyItem} onClick={() => setIsAttended(false)}>
                        <span className={styles.optionLabel}>{t("popup.no")}</span>
                        <div
                            className={cx(styles.unchecked, {
                                [styles.checked]: isAttended === false
                            })}>
                            <Icon icon="TICK" className={styles.attendanceTickIcon} color="#e5e5e5" />
                        </div>
                    </div>
                </div>
                <hr className={styles.attendanceDivider} />
                <div className={`${styles.flexContainer} ${styles.alignItems}`}>
                    {isAttended === true ? null : (
                        <div className={styles.checkWrapper}>
                            <Checkbox checked={isExcused} onChange={() => setIsExcused(!isExcused)}>
                                <span className={styles.checkLabel}>{t("popup.excused")}</span>
                            </Checkbox>
                        </div>
                    )}
                    <BaseInput value={message} onChange={setMessage} placeholder={t("popup.notes")} />
                </div>
            </div>
        )
    }

    const onSave = useCallback(async () => {
        let attended = isAttended
        if (courseInfo && courseInfo?.type === Major.Unit.Clock) {
            attended = true
        }
        if ((attended === null || attended === undefined) && isApproved) {
            return toastWarning("You need to select Yes or No to verify this attendance")
        }
        setIsSubmitting(true)
        try {
            const params: {
                status: AttendanceStatus
                message: string
                isVerified: boolean
                isExcusedAbsence?: boolean
                attendanceId?: number
                deleteEventIds?: number[]
                events?: {id: any; message: any; eventType: any; createdAt: string}[]
                addEvents?: {
                    status: AttendanceEventStatus
                    message: string
                    attendanceId?: number
                    eventType: AttendanceEventType
                    createdAt: string
                }[]
                profileId: number
                lessonId?: number
            } = {
                profileId: studentProfileIds[0],
                status:
                    attended === true
                        ? "approved"
                        : attended === false
                        ? "rejected"
                        : selectedAttendance.atendanceStatus,
                message: message,
                isVerified: !!isApproved
            }
            if (selectedAttendance.lessonId) {
                params.lessonId = selectedAttendance.lessonId
            }
            if (selectedAttendance.attendanceId) {
                params.attendanceId = selectedAttendance.attendanceId
            }
            if (params.status === "approved") {
                params.isExcusedAbsence = false
            } else {
                params.isExcusedAbsence = !!isExcused
            }
            const listEventAdd = listEvent
                .filter((item) => typeof item.id !== "number")
                .map((item) => {
                    const createdAt = moment(item.createdAt)
                    const startDate = moment(convertTZ(new Date(actualStartedAt), courseInfo?.timezone))
                    createdAt.set({
                        month: startDate.month(),
                        date: startDate.date(),
                        year: startDate.year()
                    })
                    const params: {
                        status: AttendanceEventStatus
                        message: string
                        attendanceId?: number
                        eventType: AttendanceEventType
                        createdAt: string
                    } = {
                        message: item.message ?? "",
                        eventType: item.eventType,
                        status: AttendanceEventStatus.Success,
                        createdAt: createdAt.toISOString()
                    }
                    if (selectedAttendance.attendanceId) {
                        params.attendanceId = selectedAttendance.attendanceId
                    }
                    return params
                })

            const events = listEvent
                .filter((item) => typeof item.id === "number")
                .map((item) => {
                    const createdAt = moment(item.createdAt)
                    const startDate = moment(convertTZ(new Date(actualStartedAt), courseInfo?.timezone))
                    createdAt.set({
                        month: startDate.month(),
                        date: startDate.date(),
                        year: startDate.year()
                    })
                    return {
                        id: item.id,
                        message: item.message ?? "",
                        eventType: item.eventType,
                        createdAt: createdAt.toISOString()
                    }
                })
            if (events?.length) {
                params.events = events
            }
            if (deleteEventIds.current?.length) {
                params.deleteEventIds = deleteEventIds.current
            }
            if (listEventAdd.length) {
                params.addEvents = listEventAdd
            }
            await attendanceService.updateAttendance(params)
            getListAttendances && getListAttendances()
            setStudentInfo(undefined)
            setMessage("")
            deleteEventIds.current = []
            setListEvent([])
            onClose()
        } catch (err) {
            handleError(err)
        } finally {
            setIsSubmitting(false)
        }
    }, [
        getListAttendances,
        isApproved,
        selectedAttendance,
        isAttended,
        actualStartedAt,
        isExcused,
        message,
        courseInfo,
        listEvent,
        studentProfileIds
    ])

    const {checkInTime, checkOutTime, checkInDate} = dateData
    const checkInEvent = listEvent.find(
        (event) => event.eventType === AttendanceEventType.Checkin && event.status === "success"
    )
    const checkOutEvent = listEvent.find(
        (event) => event.eventType === AttendanceEventType.Checkout && event.status === "success"
    )
    const otherEvents = listEvent.filter(
        (event) => event.eventType !== AttendanceEventType.Checkout && event.eventType !== AttendanceEventType.Checkin
    )
    const isActivityAttendance = selectedAttendance?.classroomAttendanceType === Course.AttendanceTrackingType.Activity
    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PERSON_FILL" width="70vw">
            <div className={styles.popupWrapper}>
                <p className={styles.title}>{t("attendance.title")}</p>
                {!isActivityAttendance && selectedAttendance?.attendanceId && checkInDate ? (
                    <div>
                        <div className={styles.timeWrapper}>
                            <span>{`${checkInDate}${checkInTime ? ", " + checkInTime : ""}${
                                checkOutTime !== "" ? "-" : ""
                            }${checkOutTime}`}</span>
                        </div>
                    </div>
                ) : null}
                {isActivityAttendance ? (
                    <div>
                        <div className={styles.timeWrapper}>
                            <span>{`${moment(
                                convertTZ(new Date(selectedAttendance.attendanceDate), courseInfo?.timezone)
                            ).format(dateFormat)}, ${moment(
                                convertTZ(new Date(selectedAttendance.attendanceDate), courseInfo?.timezone)
                            ).format(timeFormat)}`}</span>
                        </div>
                    </div>
                ) : null}
                <hr className={styles.divisor} />
                {renderStudentInfo()}
                {actualStartedAt ? (
                    <>
                        {actualStartedAt && renderLessonStartEndEvent(actualStartedAt, "lessonStart")}
                        {renderCheckInItem({
                            status: "failure",
                            eventType: AttendanceEventType.Checkin,
                            ...checkInEvent
                        })}
                        {otherEvents.map(renderCheckInItem)}
                        {renderCheckInItem({
                            status: "failure",
                            eventType: AttendanceEventType.Checkout,
                            ...checkOutEvent
                        })}
                        {actualEndedAt && renderLessonStartEndEvent(actualEndedAt, "lessonEnd")}
                        {renderAddEvent()}
                        {courseInfo.type === "credit" && actualEndedAt ? renderAttendAction() : null}
                        {actualEndedAt ? (
                            <div className={styles.approveAttendWrap} onClick={() => setIsApproved(!isApproved)}>
                                <span className={styles.checkLabel}>{t("popup.approveAttendance")}</span>
                                <div
                                    className={cx(styles.unchecked, {
                                        [styles.checked]: isApproved
                                    })}>
                                    <Icon icon="TICK" className={styles.attendanceTickIcon} color="#e5e5e5" />
                                </div>
                            </div>
                        ) : null}
                        <div className={styles.saveContainer}>
                            <BaseButton loading={isSubmitting} onClick={onSave} title={t("popup.save")} />
                        </div>
                    </>
                ) : !isActivityAttendance ? (
                    <div className={styles.emptyView}>
                        <span className={styles.textEmpty}>Attendance is empty</span>
                    </div>
                ) : (
                    <>
                        <div className={`${styles.flexContainer} ${styles.alignItems}`}>
                            <BaseInput value={message} onChange={setMessage} placeholder={t("popup.notes")} />
                        </div>
                        <div
                            style={{marginTop: 20}}
                            className={styles.approveAttendWrap}
                            onClick={() => setIsApproved(!isApproved)}>
                            <span className={styles.checkLabel}>{t("popup.approveAttendance")}</span>
                            <div
                                className={cx(styles.unchecked, {
                                    [styles.checked]: isApproved
                                })}>
                                <Icon icon="TICK" className={styles.attendanceTickIcon} color="#e5e5e5" />
                            </div>
                        </div>
                        <div className={styles.saveContainer}>
                            <BaseButton loading={isSubmitting} onClick={onSave} title={t("popup.save")} />
                        </div>
                    </>
                )}
                <BaseLoading isShow={isLoading || loading} />
            </div>
        </BasePopup>
    )
}
