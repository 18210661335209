import {AppliedFilter, FilterKey, FilterList} from "types/filter"
import {AcademicAffairsFilter} from "../../../AcademicAffairsFilter"
import {useState} from "react"
import {StudentListTable} from "sections/shared/students"
import {BaseDepartmentId} from "types/departments"
import {ExportFileType} from "components/ui"

export function AcademicAffairsStudentList() {
    const [filters, setFilters] = useState<FilterList>({})
    const [isExporting, setIsExporting] = useState<boolean>(false)
    const [exportFileType, setExportFileType] = useState<ExportFileType | null>()

    const onApplyFilter = (payload: AppliedFilter) => {
        const {filters} = payload
        setFilters(filters)
    }

    const handleExportFile = async (type: ExportFileType) => {
        setExportFileType(type)
    }

    const handleUpdateState = (payload: any) => {
        const {isExporting, exportFileType} = payload
        setIsExporting(isExporting)
        setExportFileType(exportFileType)
    }

    return (
        <div>
            <AcademicAffairsFilter
                onApplyFilter={onApplyFilter}
                filterKey={FilterKey.AcademicsPage_Analytics_Students}
                isStudentList
                showExport
                isExporting={isExporting}
                handleExport={handleExportFile}
            />

            <StudentListTable
                departmentId={BaseDepartmentId.Academics}
                studentForm={filters}
                filterMemoryKey={FilterKey.AcademicsPage_Analytics_Students}
                useNewFilter
                exportFileType={exportFileType}
                updateState={handleUpdateState}
            />
        </div>
    )
}
