import {withAsyncPaginate} from "react-select-async-paginate"
import Creatable from "react-select/creatable"
import cx from "classnames"
import {dropdownStyle} from "./dropdownStyle"
import {KlassDropdownAsyncProps} from "./KlassDropdownAsync"

const AsyncPaginateCreatable = withAsyncPaginate(Creatable)

export function KlassDropAsyncPaginateCreatable(props: KlassDropdownAsyncProps) {
    const {
        stylesCustom,
        valueKey,
        labelKey,
        isMulti,
        readOnly = false,
        isClearable,
        className,
        refData,
        ...rest
    } = props
    let valueKeySelect = valueKey || "id"
    let labelKeySelect = labelKey || "name"
    let styles = dropdownStyle
    if (stylesCustom) styles = {...styles, ...stylesCustom}
    const customProps = {...rest, styles}

    return (
        <AsyncPaginateCreatable
            cacheOptions
            defaultOptions
            getOptionValue={(option: any) => option?.[valueKeySelect] || option?.value}
            getOptionLabel={(option: any) => option[labelKeySelect] || option.label}
            closeMenuOnSelect
            isMulti={isMulti}
            menuIsOpen={readOnly ? false : undefined}
            isSearchable={!readOnly}
            isClearable={isClearable && !readOnly}
            classNamePrefix="klassapp-dropdown"
            className={cx("klassapp-dropdown", className, {readOnly})}
            ref={refData}
            loadOptions={rest.loadOptions}
            {...customProps}
        />
    )
}
