/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useState, useEffect} from "react"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import moment from "moment"
import cx from "classnames"
import {useModel, useVisible} from "hooks"
import {BasePopup} from "components/popup"
import {AcademicPlans} from "types/academicPlans"
import {Icon} from "components/Icon"
import {EventContent} from "../Popup"
import styles from "./AcademicPlanCalendar.module.css"
import {Auth} from "types/auth"

type Props = {
    registeringTerm: AcademicPlans.Term
    events: any[]
    updateEvents: (data: AcademicPlans.UpdateEvents[]) => void
}

export function AcademicPlanCalendar(props: Props) {
    const calendarRef = useRef(null)
    const [monthLabel, setMonthLabel] = useState("")
    const [clickInfo, setClickInfo] = useState<any>({})
    const [eventOptions] = useState([])
    const [conflictEvents, setConflictEvents] = useState<any>([])
    const confirmPopupConflict = useVisible()
    const model = useModel()
    const locale = model.getLocale() === "en" ? "en-au" : "es"
    const {registeringTerm, events} = props

    useEffect(() => {
        if (calendarRef.current) {
            updateMonthLabel(calendarRef.current.getApi().getDate())
        }
    }, [])

    const onDateClick = (date) => {
        //
    }

    const handleShowEventPreview = (visible) => {
        // setIsShowEventPreview(visible)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <EventContent
                updateViewTo={""}
                eventInfo={eventInfo}
                clickInfo={clickInfo}
                eventOptions={eventOptions}
                isShowGoing={false}
                handleShowEventPreview={handleShowEventPreview}
            />
        )
    }

    const handleEventClick = (clickInfo) => {
        setClickInfo(clickInfo)
        const {event} = clickInfo
        if (event.extendedProps.isConflict) {
            const conflictEvents = Object.values(event.extendedProps.events).map((item: any) => ({
                ...item,
                isActive: false
            }))
            setConflictEvents(conflictEvents)
            confirmPopupConflict.open()
        }
    }

    const selectEventConflict = (index, isDisabled) => {
        if (isDisabled) return
        let copyConflictEvents = [...conflictEvents]
        copyConflictEvents = copyConflictEvents.map((event, conflictIndex) => {
            event.isActive = index === conflictIndex
            return event
        })
        setConflictEvents(copyConflictEvents)
        // setSelectedEventConflict(conflictEvents[index])
    }

    const changeMonth = (action: "next" | "previous") => {
        if (calendarRef.current) {
            const date = calendarRef.current.getApi().getDate()
            if (date) {
                if (action === "previous") {
                    const prevMonth = moment(date).subtract(1, "month").toDate()
                    setMonthLabel(moment(prevMonth).format("MMMM YYYY"))
                    calendarRef.current.getApi().gotoDate(prevMonth)
                } else {
                    const nextMonth = moment(date).add(1, "month").toDate()
                    setMonthLabel(moment(nextMonth).format("MMMM YYYY"))
                    calendarRef.current.getApi().gotoDate(nextMonth)
                }
            }
        }
    }

    const updateMonthLabel = (date) => {
        if (moment(date).month() !== moment(date).add(6, "days").month()) {
            setMonthLabel(moment(date).locale(locale).add(6, "days").format("MMMM YYYY"))
        } else {
            setMonthLabel(moment(date).locale(locale).format("MMMM YYYY"))
        }
    }

    const changeWeek = (action: "next" | "previous") => {
        let method = "next"
        if (action === "previous") {
            method = "prev"
        }
        if (calendarRef.current) {
            calendarRef.current.getApi()[method]()
            updateMonthLabel(calendarRef.current.getApi().getDate())
        }
    }

    return (
        <div>
            {registeringTerm && (
                <div className={styles.termInfo}>
                    <span className={styles.termTitle}>{registeringTerm.title}</span>
                </div>
            )}
            <div className={styles.header}>
                <div className={styles.arrowsContainer}>
                    <button className={styles.arrowButton} onClick={() => changeMonth("previous")} title="Prev Month">
                        <Icon icon="ARROW_LEFT" className={styles.headerIcon} color="var(--black-base)" />
                    </button>
                    <span className={styles.dateInfo}>{monthLabel}</span>
                    <button className={styles.arrowButton} onClick={() => changeMonth("next")} title="Next Month">
                        <Icon icon="ARROW_RIGHT" className={styles.headerIcon} color="var(--black-base)" />
                    </button>
                </div>
                <div className={styles.arrowsContainer}>
                    <button className={styles.arrowButton} onClick={() => changeWeek("previous")} title="Prev Week">
                        <Icon icon="ARROW_LEFT" className={styles.headerIcon} color="var(--black-base)" />
                    </button>
                    <button className={styles.arrowButton} onClick={() => changeWeek("next")} title="Next Week">
                        <Icon icon="ARROW_RIGHT" className={styles.headerIcon} color="var(--black-base)" />
                    </button>
                </div>
            </div>
            <div className={styles.fullCalendarContainer}>
                <FullCalendar
                    key={events.length}
                    events={events}
                    initialView="timeGridWeek"
                    eventOverlap={false}
                    editable={false}
                    selectable={false}
                    selectMirror
                    allDaySlot={false}
                    dayMaxEvents={2}
                    dayMaxEventRows={2}
                    slotDuration="01:00:00"
                    scrollTime="08:00:00"
                    firstDay={0}
                    eventOrder="order"
                    ref={calendarRef}
                    plugins={[timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: "",
                        center: "",
                        right: ""
                    }}
                    views={{
                        timeGridWeek: {
                            titleFormat: {year: "numeric", month: "long"}
                        }
                    }}
                    eventTimeFormat={{
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false
                    }}
                    slotLabelFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: model.user?.timeFormat === Auth.TimeFormat.Time_12
                    }}
                    initialDate={registeringTerm ? new Date(registeringTerm.start_date) : null}
                    // validRange={{
                    //     start: moment.utc(term.start_date).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                    //     end: moment.utc(term.end_date).endOf("day").format("YYYY-MM-DD HH:mm:ss")
                    // }}
                    scrollTimeReset={false}
                    dateClick={onDateClick}
                    eventContent={renderEventContent}
                    eventClick={handleEventClick}
                />
            </div>

            <BasePopup
                isShow={confirmPopupConflict.isVisible}
                onClose={confirmPopupConflict.close}
                leftIcon="WARNING_SOLID"
                leftIconColor="#fff"
                width="600px">
                <div className={styles.conflictWrap}>
                    <p className={styles.conflictTitle}>Conflict Schedules</p>
                    <div className={cx(styles.scheduleItems, "custom-scroll")}>
                        {conflictEvents.map((event, index) => (
                            <div
                                className={cx(styles.scheduleItem, {
                                    [styles.scheduleItemActive]: event.isActive,
                                    [styles.scheduleItemDisabled]: event.isRegistered
                                })}
                                onClick={() => selectEventConflict(index, event.isRegistered)}
                                key={`event-conflict-${index}`}>
                                <span>
                                    {event.title}
                                    <br />
                                    {moment(event.start).format("HH:mm")} - {moment(event.end).format("HH:mm")}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </BasePopup>
        </div>
    )
}
