/* eslint-disable react-hooks/exhaustive-deps */
import {checkPermission, formatDecimal, formatDecimalWithoutRound, getFullName, handleError} from "helpers"
import jsPDF from "jspdf"
import React, {
    Dispatch,
    forwardRef,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react"
import {
    fontBold,
    fontRegular
} from "sections/FinancialAid/FinancialAidStudent/parts/CollegeFinancingPlan/CFPStepDetail/font"
import {reportsService, studentService} from "services"
import styles from "./ProgressReportPdf.module.css"
import {Branding} from "types/branding"
import {useModel} from "hooks"
import StudentInfoCredit from "./StudentInfoCredit"
import {Auth} from "types/auth"
import {get} from "lodash"
import {BaseDepartmentId} from "types/departments"
import moment from "moment"
import TermCourseClock from "./TermCourseClock"
import {BaseButton, BaseLoading} from "components"
import PracticalTable from "./PracticalTable"
import StudentInfoClock from "./StudentInfoClock"
import TermCourseCredit from "./TermCourseCredit"
import {Course} from "types/courses"
import {GeneralSettingField} from "types/settings/general-setting"
import {Permissions} from "@edular/permissions"
import OfficialTranscriptSignature from "./OfficialTranscriptSignature"
import FooterPdf from "./Footer"
import {useQuery} from "@tanstack/react-query"
import {ClockHoursTranscriptSections} from "./ClockHoursTranscripts"

type ProgressReportPdfProps = {
    data: {
        profileId: number
        departmentId: BaseDepartmentId
        type: Course.CourseType
        transcriptType: GeneralSettingField
    }
    setLoadingData?: Dispatch<SetStateAction<{[key: number]: boolean}>>
}

const ProgressReportPdf = forwardRef((props: ProgressReportPdfProps, ref) => {
    const {data, setLoadingData} = props
    const {profileId, departmentId: departmentIdValue, type, transcriptType} = data
    const [listOfSubmodules, setListOfSubmodules] = useState<string[]>([])
    const model = useModel()
    const pdfRef = useRef<HTMLDivElement>()
    const footerTranscriptRef = useRef<HTMLDivElement>()
    const [downloading, setDownloading] = useState(false)
    const [studentInfo, setStudentInfo] = useState<Auth.DepartmentStudent | undefined>()
    const [logoBase64, setLogoBase64] = useState("")
    const studentProfileId = Number(profileId)
    const departmentId = Number(departmentIdValue)

    const {data: progressReportData, isFetching} = useQuery(
        ["progress-report-data", studentProfileId, departmentId],
        () =>
            reportsService.getProgressReport({
                studentProfileId,
                departmentId
            }),
        {
            onSuccess: ({departmentStudentDetail}) => {
                if (setLoadingData) {
                    setLoadingData((prev) => ({
                        ...prev,
                        [profileId]: false
                    }))
                }
                if (model.clientSetting.isNewStudentStatusesVisible) {
                    departmentStudentDetail.studentStatus = departmentStudentDetail.status
                    setStudentInfo({...departmentStudentDetail})
                } else {
                    getStatusProfile(departmentStudentDetail)
                }
            },
            onError: (error) => {
                handleError(error)
                if (setLoadingData) {
                    setLoadingData((prev) => ({
                        ...prev,
                        [profileId]: false
                    }))
                }
            },
            initialData: {
                transferCredits: [],
                previouslyCompletedCredits: [],
                listOfCurrentProgressTable: [],
                practicalCourses: [],
                overallStudentStats: {}
            }
        }
    )
    const {
        clientDetail,
        academicProgressData,
        listOfCurrentProgressTable,
        transferCredits,
        previouslyCompletedCredits,
        practicalCourses,
        overallStudentStats,
        clockHourTranscriptSections
    } = progressReportData

    const logo = clientDetail?.college_logo || model.clientSetting?.branding?.[Branding.BrandingType.OfficialLogoSquare]

    function convertImgToBase64(url, callback) {
        var canvas: any = document.createElement("CANVAS")
        var ctx = canvas.getContext("2d")
        var img = new Image()
        img.crossOrigin = "Anonymous"
        img.onload = function () {
            canvas.height = img.height
            canvas.width = img.width
            ctx.drawImage(img, 0, 0)
            var dataURL = canvas.toDataURL("image/png")
            callback.call(this, dataURL)
            canvas = null
        }
        img.src = url
    }

    useEffect(() => {
        if (logo) {
            convertImgToBase64(logo + `?t=${Date.now()}`, function (base64Img) {
                setLogoBase64(base64Img)
            })
        }
    }, [logo])

    const getStatusProfile = async (student) => {
        try {
            const {data: statuses} = await studentService.getStudentStatuses(+profileId)

            statuses.forEach((status) => {
                if (status.departmentId === BaseDepartmentId.StudentAccount) {
                    student.studentAccountStatus = status.status
                } else if (status.departmentId === BaseDepartmentId.Academics) {
                    student.studentStatus = status.status
                }
            })
            setStudentInfo({...student})
        } catch (err) {
            handleError(err)
        }
    }

    const renderFooter = useMemo(() => {
        return (
            <div
                ref={footerTranscriptRef}
                style={
                    downloading
                        ? {
                              paddingLeft: 33,
                              paddingRight: 33
                          }
                        : {}
                }>
                <FooterPdf />
            </div>
        )
    }, [downloading])

    const download = React.useCallback(async () => {
        setDownloading(true)
        if (setLoadingData) {
            setLoadingData((prev) => ({
                ...prev,
                [profileId]: true
            }))
        }
        try {
            const element = pdfRef.current
            const pdfWidth = 210
            const elementWidth = element.clientWidth
            const widthScaleFactor = pdfWidth / elementWidth

            const pdf = new jsPDF("p", "mm", "a4")

            pdf.addFileToVFS("Poppins-regular.ttf", fontRegular)
            pdf.addFont("Poppins-regular.ttf", "Poppins", "normal", "normal")
            pdf.addFileToVFS("Poppins-bold.ttf", fontBold)
            pdf.addFont("Poppins-bold.ttf", "Poppins", "normal", "bold")

            pdf.setFont("Poppins")

            await pdf.html(element, {
                image: {
                    type: "jpeg",
                    quality: 100
                },
                html2canvas: {
                    scale: widthScaleFactor,
                    allowTaint: true,
                    letterRendering: true,
                    svgRendering: true
                },
                autoPaging: "text",
                margin: [10, 0, 25, 0],
                x: 0,
                y: 0,
                width: pdfWidth,
                windowWidth: elementWidth
            })

            const pageCount = pdf.getNumberOfPages()
            for (let i = 1; i <= pageCount; i++) {
                pdf.setPage(i)
                const pageSize = pdf.internal.pageSize
                const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()

                const footerTranscript = footerTranscriptRef.current
                await pdf.html(footerTranscript, {
                    x: 0,
                    y: pageHeight * i - 15,
                    image: {
                        type: "jpeg",
                        quality: 100
                    },
                    html2canvas: {
                        scale: widthScaleFactor,
                        allowTaint: true,
                        letterRendering: true,
                        svgRendering: true
                    },
                    width: pdfWidth,
                    windowWidth: elementWidth
                })
            }

            pdf.save(`Degree Audit - ${getFullName(studentInfo)}.pdf`)
        } catch (error) {
            handleError(error)
        } finally {
            setDownloading(false)
            if (setLoadingData) {
                setLoadingData((prev) => ({
                    ...prev,
                    [profileId]: false
                }))
            }
        }
    }, [studentInfo, setLoadingData])

    useImperativeHandle(
        ref,
        () => {
            return {
                download: download
            }
        },
        [download]
    )

    const renderSignature = useMemo(() => {
        return <OfficialTranscriptSignature />
    }, [])

    const hasPermissionToCreateProgressReport = useMemo(() => {
        return checkPermission({staff: [Permissions.Staff.Academics.Registrar.Students.ProgressReport.Add]}, model)
    }, [model])

    const totalTransferCreditGpa = transferCredits.reduce((total, item) => {
        if (typeof item.grades == "number" && item?.grades >= 0) {
            total += item.grades * (item.appliedCredits ?? 0)
        }
        return total
    }, 0)

    const totalTransferCredits = transferCredits.reduce((total, item) => {
        if (typeof item.appliedCredits === "number" && item.appliedCredits) {
            total += item.appliedCredits
        }
        return total
    }, 0)

    const totalTransferCreditGpaValue = formatDecimal(
        totalTransferCreditGpa >= 0 ? totalTransferCreditGpa / transferCredits.length : 0
    )
    const totalTransferCreditsValue = formatDecimalWithoutRound(totalTransferCredits ?? 0)

    const totalCompletedGpa = previouslyCompletedCredits.reduce((total, item) => {
        if (typeof item.grades == "number" && item?.grades >= 0) {
            total += item.grades * (item.creditsApplied ?? 0)
        }
        return total
    }, 0)

    const totalCompletedCredits = previouslyCompletedCredits.reduce((total, item) => {
        if (typeof item.creditsApplied === "number" && item.creditsApplied) {
            total += item.creditsApplied
        }
        return total
    }, 0)

    const totalCompletedGpaValue = formatDecimal(
        totalCompletedGpa >= 0 ? totalCompletedGpa / previouslyCompletedCredits.length : 0
    )
    const totalCompletedCreditsValue = formatDecimalWithoutRound(totalCompletedCredits ?? 0)
    const cgpa = formatDecimal(overallStudentStats?.cgpa ?? 0)

    return (
        <div className={styles.parent}>
            <div className={styles.container}>
                <div
                    ref={pdfRef}
                    className={styles.pdfPage}
                    style={
                        !downloading
                            ? {
                                  paddingTop: 40
                              }
                            : {paddingTop: 0}
                    }>
                    <div className={styles.rowTitle}>
                        <div>
                            <img className={styles.logo} alt="" src={logoBase64} />
                        </div>
                        <div className={styles.titleView}>
                            <span className={styles.title}>Progress Report</span>
                            <span className={styles.title}>(Unofficial Transcript)</span>
                        </div>
                    </div>

                    <div className={styles.studentNameForm}>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <span className={styles.studentCode}>{get(studentInfo, "customProfileId", "")}</span>
                            <span className={styles.studentName}>{getFullName(studentInfo) || "-"}</span>
                        </div>
                        <span className={styles.issuedDate}>Issued {moment().format(model.getUserDateFormat())}</span>
                    </div>

                    {type === Course.CourseType.Credit ? (
                        <StudentInfoCredit cgpa={cgpa} progressData={academicProgressData} studentInfo={studentInfo} />
                    ) : (
                        <StudentInfoClock
                            cgpa={cgpa}
                            studentStats={overallStudentStats}
                            progressData={academicProgressData}
                            studentInfo={studentInfo}
                        />
                    )}

                    {transferCredits?.length ? (
                        <div className={styles.tableContainer}>
                            <span className={styles.tableTitle}>Transferred credits</span>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}>Transfer</span>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Term Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>University</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}>Equivalent</span>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 115}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Units earned</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Quality Points</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Grade</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 114}}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Grade Points</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transferCredits.map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td className={styles.tableColumn}>{item.idTransfer}</td>
                                                <td className={styles.tableColumn}>{item.courseNameTransfer}</td>
                                                <td className={styles.tableColumn}>{item.termNameTransfer}</td>
                                                <td className={styles.tableColumn}>{item.universityName}</td>
                                                <td className={styles.tableColumn}>{item.codeEquivalent}</td>
                                                <td className={styles.tableColumn}>{item.courseName}</td>
                                                <td className={styles.tableColumn}>{item.appliedCredits}</td>
                                                <td className={styles.tableColumn}>{item.qualityPoints}</td>
                                                <td className={styles.tableColumn}>{item.alphabetical}</td>
                                                <td className={styles.tableColumn}>{item.grades}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className={styles.termAvg}>TOTAL</td>
                                        <td className={styles.termAvg}></td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalTransferCreditsValue}</td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalTransferCreditGpaValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : null}

                    {previouslyCompletedCredits?.length ? (
                        <div className={styles.tableContainer}>
                            <span className={styles.tableTitle}>Previously completed credits</span>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span>Id</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span>Course Name</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText}>
                                            <div className={styles.headerTransfer}>
                                                <span className={styles.blockSeparatorHeader}></span>
                                                <span>Term Name</span>
                                            </div>
                                        </th>

                                        <th className={styles.tableHeaderText} style={{width: 115}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Units earned</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Quality Points</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 60}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Grade</span>
                                            </div>
                                        </th>
                                        <th className={styles.tableHeaderText} style={{width: 114}}>
                                            <div className={styles.headerTransfer}>
                                                <span>Grade Points</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {previouslyCompletedCredits.map((item, index) => {
                                        return (
                                            <tr key={index.toString()}>
                                                <td className={styles.tableColumn}>{item.codeApplied}</td>
                                                <td className={styles.tableColumn}>{item.courseNameApplied}</td>
                                                <td className={styles.tableColumn}>{item.termName}</td>
                                                <td className={styles.tableColumn}>{item.creditsApplied}</td>
                                                <td className={styles.tableColumn}>{item.qualityPoints}</td>
                                                <td className={styles.tableColumn}>{item.alphabetical}</td>
                                                <td className={styles.tableColumn}>{item.grades}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td className={styles.termAvg}>TOTAL</td>
                                        <td className={styles.termAvg}></td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalCompletedCreditsValue}</td>
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg} />
                                        <td className={styles.termAvg}>{totalCompletedGpaValue}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : null}

                    <ClockHoursTranscriptSections data={clockHourTranscriptSections} />

                    {type === Course.CourseType.Credit ? (
                        <TermCourseCredit listTableDegreeAudit={listOfCurrentProgressTable} profileId={profileId} />
                    ) : (
                        <TermCourseClock
                            setListOfSubmodules={setListOfSubmodules}
                            listOfSubmodules={listOfSubmodules}
                            transcriptType={transcriptType}
                            listTableDegreeAudit={listOfCurrentProgressTable}
                            profileId={profileId}
                        />
                    )}
                    {practicalCourses?.length ? <PracticalTable data={practicalCourses} /> : null}
                    {renderSignature}
                    {!downloading ? renderFooter : null}
                </div>
            </div>
            {downloading ? renderFooter : null}
            {hasPermissionToCreateProgressReport ? (
                <BaseButton
                    className={styles.buttonDownload}
                    loading={downloading}
                    onClick={download}
                    title={"Download"}
                />
            ) : null}
            <BaseLoading isShow={isFetching} />
        </div>
    )
})

export default ProgressReportPdf
