import {useQuery} from "@tanstack/react-query"
import {profileService} from "services"

const INITIAL_DATA = []

export const useAllRoles = () => {
    const query = useQuery(
        ["all-roles"],
        async () => {
            return await profileService.getRoles({
                sort: {orderBy: "name", orderDir: "asc"}
            })
        },
        {}
    )
    return query.data ?? INITIAL_DATA
}
