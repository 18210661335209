import {useQuery} from "@tanstack/react-query"
import {settingStatusService} from "services"
import {StudentDepartmentStatus} from "types/workflow"

const INITIAL_DATA: StudentDepartmentStatus[] = []

export const useAllStudentDepartmentStatuses = () => {
    const query = useQuery(
        ["all-student-department-statuses"],
        async () => {
            const {data: statuses} = await settingStatusService.getAllStatuses({
                sort: {orderBy: "priority", orderDir: "asc"},
                filter: {},
                linkedEntities: true
            })
            return statuses.map((status) => ({
                ...status,
                id: status.statusId,
                departmentId: status.stage?.departmentId
            })) as StudentDepartmentStatus[]
        },
        {
            refetchOnMount: false
        }
    )

    return {statuses: query.data ?? INITIAL_DATA, isLoading: query.isFetching}
}
