import React from "react"
import cx from "classnames"
import styles from "./Tab.module.css"
import {ReactComponent as IconPrograms} from "./icons/fill.svg"
import {ReactComponent as IconVersion} from "./icons/version.svg"
import {ReactComponent as IconTask} from "./icons/task.svg"
import {ReactComponent as IconUser} from "./icons/user.svg"
import {ReactComponent as IconUsers} from "./icons/users.svg"
import {ReactComponent as IconSettings} from "./icons/settings.svg"
import {ReactComponent as IconMonitor} from "./icons/monitor.svg"
import {ReactComponent as IconFilter} from "./icons/filter.svg"
import {ReactComponent as IconCourse} from "./icons/course.svg"
import {ReactComponent as IconGrade} from "./icons/course.svg"
import {ReactComponent as IconDollar} from "./icons/Icon_Dollar_Sign.svg"
import {ReactComponent as IconMajors} from "./icons/Icon_Program_Fill.svg"
import {ReactComponent as IconTerm} from "./icons/term_view.svg"
import {ReactComponent as IconList} from "./icons/list_view.svg"
import {ReactComponent as IconEvent} from "./icons/event.svg"
import {ReactComponent as IconGPA} from "./icons/gpa.svg"
import {ReactComponent as IconPie} from "./icons/pie.svg"
import {ReactComponent as IconClock} from "./icons/clock.svg"
import {ReactComponent as IconBulbLine} from "./icons/iconBulbLine.svg"
import {ReactComponent as IconAvailable} from "./icons/available.svg"
import {ReactComponent as IconCalendar} from "./icons/calendar.svg"
import {ReactComponent as IconCalendarLine} from "./icons/Icon_Calendar_Line-Alt.svg"
import {ReactComponent as IconRates} from "./icons/rates.svg"

type Item = {
    value: string
    label: string
}

type Props = {
    activeKey: any
    onClick: (key: any) => void
    items: string[] | Item[]
}

export const Tab = (props: Props) => {
    const {activeKey, onClick, items} = props
    return (
        <nav className={styles.root}>
            {/* @ts-ignore */}
            {items.map((item: string | Item) => (
                <Element
                    item={item}
                    activeTabKey={activeKey}
                    key={typeof item === "string" ? item : item.value}
                    onClick={onClick}
                />
            ))}
        </nav>
    )
}

type ElementProps = {
    item: string | Item
    activeTabKey: any
    onClick: (value: string) => void
}
function Element(props: ElementProps) {
    const {item, activeTabKey, onClick} = props
    const isActive = typeof item === "string" ? item === activeTabKey : item.value === activeTabKey
    const value = typeof item === "string" ? item : item.value
    const label = typeof item === "string" ? item : item.label

    return (
        <button
            className={cx(styles.button, isActive && styles.active)}
            onClick={() => {
                onClick(value)
            }}
            onMouseDown={(e) => {
                e.preventDefault()
                const element: HTMLAnchorElement = e.currentTarget as unknown as HTMLAnchorElement
                removeFocus(element)
            }}
            onKeyUp={(e) => {
                onKeyDown(e)
            }}>
            <div className={styles.buttonInner}>
                <Icon id={label} />
                <span className={styles.text}>{label}</span>
            </div>
        </button>
    )
}

function Icon({id}: {id: string}) {
    const props = {width: "24", height: "24", className: styles.icon}
    switch (id) {
        case "Program":
            return <IconPrograms {...props} />
        case "Version":
            return <IconVersion {...props} />
        case "tasks":
            return <IconTask {...props} />
        case "personal":
            return <IconUser {...props} />
        case "advisors":
            return <IconUsers {...props} />
        case "Messaging":
            return <IconUsers {...props} />
        case "Virtual Rooms":
            return <IconMonitor {...props} />
        case "Settings":
            return <IconSettings {...props} />
        case "User list":
            return <IconUsers {...props} />
        case "Filter":
            return <IconFilter {...props} />
        case "Courses":
            return <IconCourse {...props} />
        case "Schedule":
            return <IconCalendar {...props} />
        case "Grade":
            return <IconGrade {...props} />
        case "Majors":
            return <IconMajors {...props} />
        case "Сost Of Attendance":
        case "Costo de Asistencia":
            return <IconDollar {...props} />
        case "Rates":
        case "Tarifas":
            return <IconRates {...props} />
        case "List View":
            return <IconList {...props} />
        case "Term View":
            return <IconTerm {...props} />
        case "Custom":
            return <IconUsers {...props} />
        case "Work Schedule":
            return <IconCalendar {...props} />
        case "Event Types":
            return <IconEvent {...props} />
        case "GPA":
            return <IconGPA {...props} />
        case "Completion Rate":
        case "Tasa de finalización":
            return <IconPie {...props} />
        case "Maximum Timeframe":
        case "Marco de tiempo máximo":
            return <IconClock {...props} />
        case "Resources":
            return <IconBulbLine {...props} />
        case "Availability":
            return <IconAvailable {...props} />
        case "Calendar":
            return <IconCalendar {...props} />
        case "Term details":
            return <IconCalendarLine {...props} />
        case "Attendance":
            return <IconUsers {...props} />

        default:
            return null
    }
}

function onKeyDown(event) {
    const element: HTMLAnchorElement = event.currentTarget
    if (event.keyCode === 13 || event.keyCode === 32) {
        removeFocus(element)
    }
}

function removeFocus(element: HTMLElement) {
    if (element) {
        element.focus()
        element.blur()
    }
}
