/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React, {KeyboardEventHandler, useState} from "react"
import {useTranslation} from "react-i18next"
import {userService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import {UserAutocomplete} from "types/user"
import {getFullName} from "helpers"
import styles from "./UserSelect.module.css"
import {Auth} from "types/auth"
import {orderBy, uniq} from "lodash"
import {STAFF_STATE_OPTIONS} from "types/staffs"

type Props = Partial<KlassDropdownAsyncProps> & {
    type?: Auth.UserProfileType | Auth.UserProfileType[]
    isActive?: boolean | boolean[]
    showGroup?: boolean
    supportCustomProfileIdSearch?: boolean
}

const UserSelect: React.FC<Props> = ({
    type,
    isActive,
    showGroup = false,
    supportCustomProfileIdSearch = false,
    ...props
}) => {
    const {t} = useTranslation(["common"])
    const [inputValue, setInputValue] = useState<string>("")

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            const {data} = await userService.searchUsers({
                filters: {
                    type,
                    isActive,
                    search
                },
                range: {
                    page: 1,
                    pageSize: 20
                }
            })
            if (showGroup) {
                const states = uniq(data.map((item) => item.state))
                return states
                    .map((state) => {
                        const options = orderBy(
                            data.filter((x) => x.state === state),
                            ["state", "firstName", "lastName"],
                            ["asc", "asc", "asc"]
                        )

                        return {
                            label: STAFF_STATE_OPTIONS.find((y) => y.id === state)?.name ?? "WITHOUT STATE",
                            options
                        }
                    })
                    .filter((group) => group.options.length > 0)
            }
            return data
        }, 500),
        [type, isActive]
    )

    const handleInputChange = (_inputValue: string) => {
        setInputValue(_inputValue)
    }

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = async (event) => {
        if (!inputValue || !supportCustomProfileIdSearch) return

        switch (event.key) {
            case "Enter":
            case "Tab":
                const values = inputValue.trim().split(",")
                if (values.length > 1) {
                    const {data} = await userService.searchUsers({
                        filters: {
                            type,
                            isActive,
                            customProfileIds: values
                        },
                        range: {
                            page: 1,
                            pageSize: values.length
                        }
                    })
                    props.onChange((props.value || []).concat(data))
                    setInputValue("")
                    event.preventDefault()
                }
        }
    }

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions
            valueKey="profileId"
            labelKey="name"
            getOptionLabel={UserOptionLabel}
            onKeyDown={handleKeyDown}
            onInputChange={handleInputChange}
            inputValue={inputValue}
        />
    )
}

export default UserSelect

export const UserOptionLabel = (option: UserAutocomplete) => {
    return (
        <span>
            <span className={styles.userId}>{option.customProfileId}</span>
            {getFullName(option) || option.name}
            {option.type === Auth.UserProfileType.Student && !!option.state && (
                <span className={styles.userType}> ({option.state})</span>
            )}
        </span>
    )
}
