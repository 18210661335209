import {Spin} from "antd"
import {Icon} from "components/Icon"
import {useModel} from "hooks"
import React, {useEffect} from "react"
import {GridContextProvider, GridDropZone, GridItem, swap} from "react-grid-dnd" // uses "react-gesture-responder" under the hood
import {mediaLibraryService} from "services"
import styles from "../MediaLibrary.module.css"
import {ReactComponent as IconStarClosed} from "../icons/star-closed.svg"
import {ReactComponent as IconStarOpen} from "../icons/star-open.svg"
import {ActionRowItem} from "./ActionRowItem"

type Props = {
    data: any
    removeFavorite: any
    addFavorite: any
    togglePinnedItem: any
    newMenuActions: any
    isLoading: boolean
    hasPermissionsToTogglePin: boolean
    setSelectedItem: any
    handleMouseOver: any
    handleMouseOut: any
    onRefreshData: () => void
}
const ROW_HEIGHT = 350
const ROW_NUMBER = 4

export function ViewBlocksMediaLibrary(props: Props) {
    const {
        data,
        removeFavorite,
        addFavorite,
        togglePinnedItem,
        newMenuActions,
        isLoading,
        hasPermissionsToTogglePin,
        setSelectedItem,
        handleMouseOver,
        handleMouseOut,
        onRefreshData
    } = props
    const [items, setItems] = React.useState([])
    const model = useModel()

    async function onChange(sourceId, sourceIndex, targetIndex, targetId) {
        const nextState = swap(items, sourceIndex, targetIndex)
        setItems(nextState)

        await mediaLibraryService.updateMediaLibrarySequence({
            sourceIndex,
            targetIndex,
            items: nextState.map(({itemId, sequenceNumber}) => ({itemId, sequenceNumber}))
        })
        onRefreshData()
    }

    useEffect(() => {
        setItems(data)
    }, [data])
    return (
        <>
            {isLoading ? (
                <div className={styles.rowLoading}>
                    <Spin />
                </div>
            ) : items.length ? (
                <GridContextProvider onChange={onChange}>
                    <GridDropZone
                        id="items"
                        boxesPerRow={ROW_NUMBER}
                        rowHeight={ROW_HEIGHT}
                        style={{
                            height: `${Math.ceil(items.length / ROW_NUMBER) * ROW_HEIGHT}px`
                        }}
                        disableDrag={model.isStudent()}>
                        {items.map((item) => (
                            <GridItem
                                key={item.itemId}
                                style={{
                                    padding: "10px"
                                }}>
                                <div className={styles.card}>
                                    <div>
                                        <div className={styles.detailRow}>
                                            <span className={styles.title}>{item.title}</span>
                                            <div className={styles.actionIconsContainer}>
                                                {item.isFavorite ? (
                                                    <IconStarClosed
                                                        width="22"
                                                        height="22"
                                                        className={styles.icon}
                                                        onClick={() => removeFavorite(item)}
                                                    />
                                                ) : (
                                                    <IconStarOpen
                                                        width="22"
                                                        height="22"
                                                        className={styles.icon}
                                                        onClick={() => addFavorite(item)}
                                                    />
                                                )}
                                                {hasPermissionsToTogglePin && (
                                                    <span onClick={() => togglePinnedItem(item)}>
                                                        <Icon
                                                            className={styles.icon}
                                                            icon={item.isPinned ? "BOARD_PIN_FILLED" : "BOARD_PIN"}
                                                            color={item.isPinned ? "#1e90ff" : "#E6E6E6"}
                                                        />
                                                    </span>
                                                )}
                                                <ActionRowItem data={item} menuActions={newMenuActions} />
                                            </div>
                                        </div>
                                        <p className={styles.description}>{item.description}</p>
                                    </div>
                                    <div className={styles.mediaDiv} onClick={() => setSelectedItem(item)}>
                                        <span>
                                            {item.type === "document" ? (
                                                <div
                                                    className={styles.listingMediaPreviewContainer}
                                                    onMouseOver={() => handleMouseOver(item)}
                                                    onMouseOut={() => handleMouseOut()}>
                                                    {item.thumbnailCard}
                                                </div>
                                            ) : (
                                                item.thumbnailCard
                                            )}
                                        </span>
                                    </div>
                                    {item.createdByProfile?.fullName && (
                                        <span className={styles.uploadedBy}>
                                            {`UPLOADED BY ${item.createdByProfile?.fullName}`}
                                        </span>
                                    )}
                                    <span className={styles.uploadedBy}>
                                        {item.createdByProfile?.fullName ? `` : `UPLOADED `} ON {item.createdDateFormat}
                                    </span>
                                </div>
                            </GridItem>
                        ))}
                    </GridDropZone>
                </GridContextProvider>
            ) : (
                <div className={styles.emptyData}>
                    <span>No results found</span>
                </div>
            )}
        </>
    )
}
