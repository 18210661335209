import {get} from "lodash"
import BaseService from "./Base"
import {Major} from "types/major"

class MajorService extends BaseService {
    async getAll(params = {}) {
        const response = await this.post("/v2/programs/list", params)
        return get(response, "data", [])
    }

    async exportAsCsv(data) {
        const response = await this.post(`/v2/programs/export-as-csv`, data, {
            responseType: "blob"
        })
        return get(response, "data", null)
    }

    async getMajor(id: number, data = {}, params?: object): Promise<Major.Major | undefined> {
        const response = await this.post(
            `/v2/programs/list`,
            {
                ...data,
                linkedEntities: true,
                filter: {programIds: [id]}
            },
            params
        )
        return get(response, "data.data[0]", {})
    }

    async getMajorByTerm(termIds?: number[]) {
        const response = await this.post(`/v2/programs/list/by-term`, {
            filter: {termIds}
        })
        return get(response, "data", [])
    }

    async getMajorByAssignedToTerm(params) {
        const response = await this.post(`/v2/programs/list/assigned-to-term`, params)
        return get(response, "data.data", [])
    }

    async createMajor(params = {}) {
        const response = await this.post("/v2/programs/create", params)
        return get(response, "data.data", {})
    }

    async updateMajor(id, data, params = {}) {
        data.programId = id
        const response = await this.post(`/v2/programs/edit`, data, params)
        return get(response, "data", {})
    }

    async deleteMajor(programIds) {
        const response = await this.post(`/v2/programs/delete`, {programIds})
        return get(response, "data", {})
    }

    async getMajorVersions(params = {}) {
        const response = await this.get("/v1/program-versions", params)
        return get(response, "data.data", [])
    }

    async createProgramVersion(params = {}) {
        const response = await this.post("/v1/program-versions", params)
        return get(response, "data", {})
    }

    async getMajorVersionDetail(id, params = {}) {
        const response = await this.get(`/v1/program-versions/${id}`, params)
        return get(response, "data.data", {})
    }

    async updateProgramVersion(id, params = {}) {
        const response = await this.put(`/v1/program-versions/${id}`, params)
        return get(response, "data", {})
    }

    async deleteProgramVersion(id) {
        const response = await this.delete(`/v1/program-versions/${id}`, {})
        return get(response, "data", {})
    }

    async addSectionProgramVersion(params = {}) {
        const response = await this.post("/v1/program-versions/sections", params)
        return get(response, "data.data", {})
    }

    async createProgramTerm(params = {}) {
        const response = await this.post("/v1/program-terms", params)
        return get(response, "data.data", {})
    }

    async updateProgramTerm(id, params = {}) {
        const response = await this.put(`/v1/program-terms/${id}`, params)
        return get(response, "data.data", {})
    }

    async updateAcademicYear(params = {}) {
        const response = await this.post("/v1/program-terms/update-academic-year", params)
        return get(response, "data.data", {})
    }

    async deleteAcademicYear(params = {}) {
        const response = await this.post("/v1/program-terms/delete-academic-year", params)
        return get(response, "data.data", {})
    }

    async duplicateAcademicYear(params = {}) {
        const response = await this.post("/v1/program-terms/duplicate-academic-year", params)
        return get(response, "data.data", {})
    }

    async deleteProgramTerm(id) {
        const response = await this.delete(`/v1/program-terms/${id}`, {})
        return get(response, "data", {})
    }

    async getAllSections(data) {
        const response = await this.post("/v1/program-versions/list-sections", data)
        return get(response, "data.data", {})
    }

    async getAllSectionCourses(data) {
        const response = await this.post("/v1/program-versions/list-section-courses", data)
        return get(response, "data.data", {})
    }

    async editSectionProgramVersion(id, data = {}) {
        const response = await this.put(`/v1/program-versions/sections/${id}`, data)
        return get(response, "data.data", {})
    }

    async deleteSectionProgramVersion(data = {}) {
        const response = await this.post(`/v1/program-versions/delete-sections`, data)
        return get(response, "data", {})
    }

    async addCoursesInTermView(data = {}) {
        const response = await this.post("/v1/program-versions/add-courses", data)
        return get(response, "data", {})
    }

    async moveCourse(data = {}) {
        const response = await this.post("v1/program-versions/move-course", data)
        return get(response, "data", {})
    }

    async removeCourses(data) {
        const response = await this.post("/v1/program-versions/remove-courses", data)
        return get(response, "data", {})
    }

    async updateAlternateCourse(data) {
        const response = await this.post("/v1/program-versions/update-alternate-course", data)
        return get(response, "data", {})
    }

    async duplicate(majors: Object[]) {
        const response = await this.post("/v2/programs/create", majors)
        return get(response, "data.data", {})
    }

    async getListCipCodes(params) {
        const response = await this.post(`/v1/cip-codes/list`, params)
        return get(response, "data", {})
    }

    async getListTermsCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/list-terms`, params)
        return get(response, "data", {})
    }

    async addTermsCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/add-term`, params)
        return get(response, "data", {})
    }

    async updateTermsCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/update-term`, params)
        return get(response, "data", {})
    }

    async removeTermsCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/remove-term`, params)
        return get(response, "data", {})
    }

    async addCourseCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/add-course`, params)
        return get(response, "data", {})
    }

    async updateCourseCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/update-course`, params)
        return get(response, "data", {})
    }

    async removeCourseCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/remove-course`, params)
        return get(response, "data", {})
    }

    async addEventCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/add-event`, params)
        return get(response, "data", {})
    }

    async updateEventCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/update-event`, params)
        return get(response, "data", {})
    }

    async removeEventCalendarBased(params) {
        const response = await this.post(`/v1/program-versions/calendar-based/remove-event`, params)
        return get(response, "data", {})
    }

    async getProgramSpecialization(params) {
        const response = await this.post(`/v1/program-specializations/autocomplete`, params)
        return get(response, "data", {})
    }
}

export default MajorService
