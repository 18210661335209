/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import {Checkbox, Col, Divider, Row} from "antd"
import {head, pick} from "lodash"
import {useTranslation} from "react-i18next"
import {BaseFileInput, BaseInput} from "components/inputs"
import {BaseButton, SecondaryButton} from "components/buttons"
import {Icon} from "components/Icon"
import DepartmentSubunitSelect from "components/DepartmentSubunitSelect"
import {DepartmentSubunit} from "types/departments"
import {PermissionType} from "types/permission"
import {getEnableSubunitsByDepartmentPermission, handleError, toastError} from "helpers"
import {documentService, linksService} from "services"
import styles from "./DocumentForm.module.css"
import {DeepPartial} from "types/common"
import {useModel} from "hooks"
import {UserDocument} from "types/user-documents"
import LinksSection from "../shared/student/LinksSection"
import {Task} from "types/tasks"
import {StudentActivity} from "types/activity"

type Props = {
    profileId?: number
    departmentSubunitId?: number
    onCancel: () => void
    onSave: (document?: UserDocument) => void
}

const DocumentForm: React.FC<Props> = ({profileId, departmentSubunitId, onCancel, onSave}) => {
    const {t} = useTranslation(["documents, common"])
    const model = useModel()
    const [isSubmitting, setSubmitting] = React.useState(false)
    const [document, setDocument] = React.useState<
        DeepPartial<UserDocument> & {
            linkedTasks: Task[]
            linkedActivities: StudentActivity[]
            linkedChecklists: any[]
        }
    >({
        code: "",
        name: "",
        description: "",
        isVisibleForUser: false,
        linkedTasks: [],
        linkedChecklists: [],
        linkedActivities: []
    })
    const [files, setFiles] = React.useState<File[]>([])

    const allowedDocumentFormats = React.useMemo(() => model.getAllowedDocumentFormats() || "application/pdf", [model])
    const isDocumentForMe = React.useMemo(
        () => model.user.profiles.some((profile) => profile.id === profileId),
        [model, profileId]
    )

    const accessibleSubunitIds = React.useMemo(
        () => getEnableSubunitsByDepartmentPermission(PermissionType.Add, model, "Documents"),
        [model]
    )

    React.useEffect(() => {
        if (model.departments && departmentSubunitId) {
            const department = model.departments.find((department) => department.departmentId === departmentSubunitId)
            const departmentSubunit: DepartmentSubunit = head(department?.subunits || [])
            setDocument((prev) => ({
                ...prev,
                departmentSubunit,
                departmentSubunitId: departmentSubunit?.subunitId
            }))
        }
    }, [model.departments, departmentSubunitId])

    const updateDocument = React.useCallback((values: Partial<typeof document>) => {
        setDocument((prev) => ({...prev, ...values}))
    }, [])

    const validateData = React.useCallback(
        (document: DeepPartial<UserDocument>) => {
            if (!document.name) {
                toastError(t("common:validation.cantEmpty", {field: "The name"}))
                return false
            }
            if (!document.code) {
                toastError(t("common:validation.cantEmpty", {field: "The code"}))
                return false
            }
            if (!document.departmentSubunit) {
                toastError(t("common:validation.hasToBeSelect", {field: "The department"}))
                return false
            }
            return true
        },
        [t]
    )

    const saveLinks = React.useCallback(
        async (documentId: number) => {
            return await linksService.saveDocumentLink(documentId, {
                studentActivityIds: document.linkedActivities?.map((item) => item.studentActivityId),
                studentChecklistItemIds: document.linkedChecklists?.map((item) => item.studentChecklistItemId),
                taskIds: document.linkedTasks?.map((item) => item.taskId)
            })
        },
        [document.linkedActivities, document.linkedTasks, document.linkedChecklists]
    )

    const handleSubmit = React.useCallback(async () => {
        if (!validateData(document)) {
            return
        }

        setSubmitting(true)
        try {
            const formData = new FormData()
            for (const file of files) {
                formData.append(`document`, file)
            }
            const documentUrl = await documentService.uploadDocument(formData)
            const createdDocument = await documentService.createDocument({
                ...pick(document, ["name", "code", "description", "status", "departmentSubunitId", "isVisibleForUser"]),
                ownerUserProfileId: profileId,
                isVisibleForUser: isDocumentForMe,
                documentUrl
            })
            await saveLinks(createdDocument.userDocumentId)
            onSave(createdDocument)
        } catch (e) {
            handleError(e)
        } finally {
            setSubmitting(false)
        }
    }, [profileId, document, validateData, isDocumentForMe, files, onSave])

    return (
        <>
            <h1>
                <b>Add Document</b>
            </h1>

            <Row>
                <Col span={16}>
                    <div className={`${styles.itemBodyWrap} ${styles.itemBodyMargin}`}>
                        <p className={styles.bodyTitle}>{t("documents:documentDetail.department")}</p>
                        <DepartmentSubunitSelect
                            isMulti={false}
                            isClearable={false}
                            value={document.departmentSubunit}
                            subunitIds={accessibleSubunitIds}
                            onChange={(departmentSubunit?: DepartmentSubunit) => {
                                updateDocument({
                                    departmentSubunit,
                                    departmentSubunitId: departmentSubunit?.subunitId
                                })
                            }}
                        />
                    </div>
                </Col>

                <Col span={8}>
                    <div className={`${styles.itemBodyWrap}`}>
                        <p className={styles.bodyTitle}>{t("documents:documentDetail.code")}</p>
                        <BaseInput
                            onChange={(code) => updateDocument({code})}
                            placeholder="Code"
                            value={document.code}
                        />
                    </div>
                </Col>

                <Col span={24}>
                    <div className={`${styles.itemBodyWrap}`}>
                        <p className={styles.bodyTitle}>{t("documents:documentDetail.name")}</p>
                        <BaseInput
                            onChange={(name) => updateDocument({name})}
                            placeholder="Name"
                            value={document.name}
                        />
                    </div>
                </Col>

                <Col span={24}>
                    <div className={`${styles.itemBodyWrap}`}>
                        <p className={styles.bodyTitle}>{t("documents:documentDetail.description")}</p>
                        <BaseInput
                            onChange={(description) => updateDocument({description})}
                            placeholder="Description"
                            value={document.description}
                        />
                    </div>
                </Col>

                {!isDocumentForMe && (
                    <Col span={24}>
                        <div className={`${styles.itemBodyWrap}`}>
                            <Checkbox
                                checked={!!document.isVisibleForUser}
                                onChange={({target: {checked}}) => updateDocument({isVisibleForUser: checked})}
                                className={styles.checkboxMargin}
                            />
                            <span className={styles.bodyTitle}> {t("documents:documentDetail.visibleUser")}</span>
                        </div>
                    </Col>
                )}
            </Row>

            <div>
                <Divider className={styles.divider} />
                <p className={styles.bodyTitle}>{t("documents:documentDetail.uploadDocument")}</p>
                <div className={styles.uploadIconWrap}>
                    <BaseFileInput
                        // @ts-ignore
                        onChange={setFiles}
                        className={styles.fileNameWrap}
                        accept={allowedDocumentFormats}
                        multiple>
                        <span className={styles.uploadIcon}>
                            <Icon icon="UPLOAD" color="#FF349B" />
                        </span>
                    </BaseFileInput>
                </div>
            </div>

            <Divider className={styles.divider} />

            <LinksSection
                profileIds={[profileId]}
                tasks={document.linkedTasks}
                onTasksChange={(linkedTasks: Task[]) => {
                    updateDocument({linkedTasks})
                }}
                checklists={document.linkedChecklists}
                onChecklistsChange={(linkedChecklists: any[]) => {
                    updateDocument({linkedChecklists})
                }}
                activities={document.linkedActivities}
                onActivitiesChange={(linkedActivities: StudentActivity[]) => {
                    updateDocument({linkedActivities})
                }}
            />

            <div className={styles.buttonWrap}>
                <SecondaryButton title={t("common:action.cancel")} onClick={onCancel} className={styles.cancelBtn} />

                <BaseButton title={t("common:action.save")} onClick={handleSubmit} loading={isSubmitting} />
            </div>
        </>
    )
}

export default DocumentForm
