import React, {useState, useEffect, ReactNode} from "react"
import {useTranslation} from "react-i18next"
import {head, isEmpty, get} from "lodash"
import cx from "classnames"
import {BasePopup} from "components/popup"
import {Icon, IconType} from "components/Icon"
import {BaseLoading} from "components/Loading"
import {userService} from "services"
import {SignSignatureEditForm, TextSignatureEditForm} from "./parts"
import {
    checkIfProfileHasPermissionsForSignaturesAndInitialsOfAnotherProfileOfType,
    checkIfProfileHasPermissionsForTheirSignatureAndInitials,
    handleError,
    toastSuccess
} from "helpers"
import styles from "./SignaturePopup.module.css"
import {useModel} from "hooks"
import {PermissionType} from "types/permission"
import {BaseButton} from "components/buttons"
import {Auth} from "types/auth"

const SIGNATURE_TYPE = {
    SIGNATURE: "SIGNATURE",
    TEXT: "TEXT"
}

type Props = {
    isShow: boolean
    onClose: () => void
    title?: string
    onSetNewSignature?: (newSignature) => void
    autoClose?: boolean
    targetUser?: {
        profileId: number
        id: number
        type: Auth.UserProfileType
    }
    customTitle?: React.ReactNode
    currentSignatureLoaded?: any
    isAnsweringATask?: boolean
    useOneTimeSignature?: boolean
    isShowSign?: boolean
    isShowText?: boolean
    children?: ReactNode
    actions?: {
        signLabel?: string
        onCancel?: () => void
        onSign?: (signature) => void
    }
}

export function SignaturePopup(props: Props) {
    const {
        isShow,
        onClose,
        customTitle,
        title,
        onSetNewSignature,
        autoClose = true,
        targetUser,
        currentSignatureLoaded,
        isAnsweringATask,
        isShowSign = true,
        useOneTimeSignature,
        isShowText = true,
        actions
    } = props
    const [activeType, setActiveType] = useState(isShowSign ? SIGNATURE_TYPE.SIGNATURE : SIGNATURE_TYPE.TEXT)
    const [isShowEditForm, setIsShowEditForm] = useState(false)
    const [currentSignature, setCurrentSignature] = useState(null)
    const [isLoadingSignature, setIsLoadingSignature] = useState(null)
    const {t} = useTranslation(["user", "common"])
    const model = useModel()

    useEffect(() => {
        if (isShow) {
            if (!isAnsweringATask && !useOneTimeSignature) {
                getCurrentSignature()
            } else if (useOneTimeSignature) {
                setIsShowEditForm(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, targetUser, useOneTimeSignature])

    useEffect(() => {
        if (isAnsweringATask) {
            if (currentSignatureLoaded) {
                setCurrentSignature(currentSignatureLoaded)
            } else {
                setIsShowEditForm(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSignatureLoaded])

    const checkCanEditSignature = () => {
        if (targetUser) {
            return targetUser.id === model.user.id
                ? checkIfProfileHasPermissionsForTheirSignatureAndInitials(model, PermissionType.Edit)
                : checkIfProfileHasPermissionsForSignaturesAndInitialsOfAnotherProfileOfType(
                      model,
                      targetUser.type,
                      PermissionType.Edit
                  )
        }
        return checkIfProfileHasPermissionsForTheirSignatureAndInitials(model, PermissionType.Edit)
    }

    const checkCanDeleteSignature = () => {
        if (targetUser) {
            return targetUser.id === model.user.id
                ? checkIfProfileHasPermissionsForTheirSignatureAndInitials(model, PermissionType.Delete)
                : checkIfProfileHasPermissionsForSignaturesAndInitialsOfAnotherProfileOfType(
                      model,
                      targetUser.type,
                      PermissionType.Delete
                  )
        }
        return checkIfProfileHasPermissionsForTheirSignatureAndInitials(model, PermissionType.Delete)
    }

    const getCurrentSignature = async () => {
        try {
            setIsLoadingSignature(true)
            const userId = targetUser?.id || model.user.id
            const {data} = await userService.getSignaturesList({
                userId,
                onlyActive: true,
                range: {page: 1, pageSize: 1}
            })
            if (isEmpty(data) || !get(head(data), "imageUrl")) {
                setIsShowEditForm(true)
            } else {
                setCurrentSignature(head(data))
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoadingSignature(false)
        }
    }

    const onClickSignatureType = (type) => {
        setActiveType(type)
    }

    const renderSignatureItemIcon = (type) => {
        let icon: IconType = "SIGN_HAND_DRAWN"
        switch (type) {
            case SIGNATURE_TYPE.SIGNATURE:
                icon = "SIGN_HAND_DRAWN"
                break
            case SIGNATURE_TYPE.TEXT:
                icon = "TEXT"
                break
        }
        return (
            <div
                className={cx(styles.iconItemWrap, {
                    [styles.iconItemWrap__active]: activeType === type
                })}
                onClick={() => onClickSignatureType(type)}>
                <Icon icon={icon} color={activeType === type ? "#FFF" : "#1E90FF"} className={styles.icon} />
            </div>
        )
    }

    const onClickEditSignature = () => {
        setIsShowEditForm(true)
    }

    const onSaveSuccessful = async (newSignature) => {
        setIsShowEditForm(false)
        toastSuccess(t("personal.signature.saveSignature"))
        setCurrentSignature(newSignature)
        if (autoClose) {
            onClose()
        }
        if (onSetNewSignature) {
            onSetNewSignature(newSignature)
        }
    }

    const onCancelEditForm = () => {
        setIsShowEditForm(false)
    }

    const onDeleteSuccessful = () => {
        setIsShowEditForm(false)
        setCurrentSignature(null)
        toastSuccess(t("personal.signature.deleteSignature"))
    }

    const renderAction = () => {
        if (isShowEditForm) {
            const userId = targetUser?.id || model.user.id
            const profileId = targetUser?.profileId || model.user.profileId
            if (activeType === SIGNATURE_TYPE.SIGNATURE) {
                const canDeleteSignatureInitials = checkCanDeleteSignature()
                return (
                    <SignSignatureEditForm
                        userId={userId}
                        profileId={profileId}
                        onSaveSuccessful={onSaveSuccessful}
                        onCancel={onCancelEditForm}
                        useOneTimeSignature={useOneTimeSignature}
                        onDelete={onDeleteSuccessful}
                        canDeleteSignatureInitials={canDeleteSignatureInitials}
                    />
                )
            }
            return (
                <TextSignatureEditForm
                    userId={userId}
                    profileId={profileId}
                    useOneTimeSignature={useOneTimeSignature}
                    onSaveSuccessful={onSaveSuccessful}
                    onCancel={onCancelEditForm}
                />
            )
        }
        const canEditSignatureInitials =
            checkCanEditSignature() && (isShowSign || isShowText || model.clientSetting?.isTypedSignaturesEnabled)
        return (
            <>
                <div className={styles.signatureImgWrap}>
                    <img
                        src={currentSignature ? currentSignature.imageUrl : ""}
                        className={styles.signatureImg}
                        alt=""
                    />
                    <BaseLoading isShow={isLoadingSignature} />
                </div>
                {canEditSignatureInitials && (
                    <div className={styles.editBtnWrap} onClick={onClickEditSignature}>
                        <p className={styles.editBtn}>{t("personal.signature.editSignature")}</p>
                    </div>
                )}
            </>
        )
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PEOPLE" leftIconColor="#fff" width="70vw">
            <div className={styles.wrap}>
                {customTitle ? customTitle : <p className={styles.title}>{title}</p>}
                <div className={styles.iconWrap}>
                    {isShowSign && renderSignatureItemIcon(SIGNATURE_TYPE.SIGNATURE)}
                    {model.clientSetting?.isTypedSignaturesEnabled &&
                        isShowText &&
                        renderSignatureItemIcon(SIGNATURE_TYPE.TEXT)}
                </div>
                <p className={styles.desc}>{t("personal.signature.typeYourSignatureBelow")}</p>
                {renderAction()}
                {!isShowEditForm && !isLoadingSignature && actions && (
                    <div className={styles.actions}>
                        <BaseButton title={t("common:action.cancel")} variant="secondary" onClick={actions.onCancel} />
                        <BaseButton
                            title={actions.signLabel || "Sign"}
                            onClick={() => actions.onSign(currentSignature)}
                        />
                    </div>
                )}
                {props.children}
            </div>
        </BasePopup>
    )
}
