import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {campusesService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {Campus} from "types/campus"

const QUERY_CACHE_KEY = "all-campuses"

type CampusShortDetail = Pick<Campus.Campus, "id" | "name" | "code" | "isActive">

const INITIAL_DATA: CampusShortDetail[] = []

export const useAllCampuses = () => {
    const queryClient = useQueryClient()

    const query = useQuery<CampusShortDetail[]>(
        [QUERY_CACHE_KEY],
        async () => {
            const {data: campuses} = await campusesService.getAll({
                fields: ["id", "name", "code", "isActive"],
                sort: {orderBy: "name", orderDir: "asc"},
                filter: {},
                range: {limit: UNLIMITED_PAGE_SIZE, offset: 0}
            })
            return campuses
        },
        {
            refetchOnMount: false
        }
    )

    return {
        campuses: query.data ?? INITIAL_DATA,
        invalidateCampusesCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [QUERY_CACHE_KEY]})
        }, [queryClient])
    }
}
