import React, {Component} from "react"
import cx from "classnames"
import styles from "./Iframe.module.css"

type Props = {
    id?
    onLoad?
    className?
    iframeRef?
    title
    iframe_url
}

export class Iframe extends Component<Props, {}> {
    private iframe: HTMLIFrameElement
    public render() {
        const {title, iframe_url, id, onLoad, className, iframeRef} = this.props
        return (
            <div>
                <iframe
                    id={id}
                    title={title}
                    src={iframe_url}
                    frameBorder={0}
                    width="100%"
                    height="100%"
                    className={cx({
                        [className]: className,
                        [styles.iframe]: !className
                    })}
                    allowFullScreen
                    ref={iframeRef}
                    onLoad={onLoad}
                />
            </div>
        )
    }
}
