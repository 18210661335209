/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useMemo, useReducer, useState} from "react"
import {
    Status,
    StudentFilterV2,
    StudentForm,
    StudentListTable,
    StudentPriorityList,
    ViewType
} from "sections/shared/students"
import {checkPermission} from "helpers"
import {BaseDepartmentId} from "types/departments"
import {Permissions} from "types/permission"
import {ActiveStudentContext} from "context/ActiveStudentContext"
import {Col, Row} from "antd"
import styles from "./Students.module.css"
import {useTranslation} from "react-i18next"
import {studentServicesService} from "services"
import {isEmpty} from "lodash"
import {AppliedFilter, FieldOperator, FilterKey} from "types/filter"
import {useModel, useSearchParam} from "hooks"
import {ExportFileType} from "components/ui"

const INITIAL_FILTER: AppliedFilter = {
    search: "",
    filters: {
        includeArchive: {operator: FieldOperator.Equal, value: false}
    }
}

type State = {
    activeStudent: any
    staffs: any
    statsInfo: any
    studentForm: StudentForm
    studentFormSearch: string
    studentStats: {
        sev?: number
        enrolled?: number
        registrationProgress?: number
        registrationComplete?: number
    }
}

function reducer(state: State, action) {
    return {...state, ...action}
}

type Props = {
    departmentId: number
    statsKey?: number
    filterKey?: FilterKey
    showRiskLevelFilters?: boolean
}

export function Students({departmentId, statsKey, filterKey: initialFilterKey, showRiskLevelFilters = false}: Props) {
    const {t} = useTranslation(["studentServices", "common"])
    const model = useModel()

    const filterKey = useMemo(() => {
        if (initialFilterKey) {
            return initialFilterKey
        }
        return departmentId === BaseDepartmentId.Academics
            ? FilterKey.AcademicsPage_Students
            : FilterKey.StudentServicesPage_Students
    }, [initialFilterKey, departmentId])

    const filterPriorityKey =
        departmentId === BaseDepartmentId.Academics
            ? FilterKey.AcademicsPage_Students_Priority
            : FilterKey.StudentServicesPage_Students_Priority

    const storageFilter = model.getStorageFilter(filterKey)
    const {value: studentId} = useSearchParam("studentId", {parse: (value) => +value})

    const canShowAllStudents = React.useMemo(() => {
        switch (departmentId) {
            case BaseDepartmentId.Academics:
                return checkPermission({staff: [Permissions.Staff.Academics.Director.View]}, model)
            case BaseDepartmentId.StudentServices:
                return checkPermission(
                    {
                        staff: [Permissions.Staff.StudentServicesAndAcademicAffairs.Director.View]
                    },
                    model
                )
            default:
                return false
        }
    }, [departmentId, model.myPermissions])

    const handleExportFile = async (type: ExportFileType) => {
        dispatch({exportFileType: type})
    }

    const canHaveAccessOthers = React.useMemo(() => {
        switch (departmentId) {
            case BaseDepartmentId.Academics:
                return checkPermission(
                    {staff: [Permissions.Staff.Academics.Instructional.Students.PermissionsToAccessOthers.View]},
                    model
                )
            case BaseDepartmentId.StudentServices:
                return checkPermission(
                    {
                        staff: [
                            Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students
                                .PermissionsToAccessOthers.View
                        ]
                    },
                    model
                )
            default:
                return false
        }
    }, [departmentId, model.myPermissions])

    const initialState: State = {
        activeStudent: {},
        staffs: [],
        statsInfo: [],
        studentForm: !isEmpty(storageFilter?.filters) ? storageFilter.filters : {},
        studentFormSearch: !isEmpty(storageFilter) ? storageFilter.search : "",
        studentStats: {}
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const [viewType, setViewType] = useState<ViewType>("standard") // model.getUserTableView()
    const {
        studentDetailVisible,
        setStudent,
        setDepartmentId,
        statusChange,
        studentStatusChange,
        enrollmentProfileIdChange,
        reloadStudent,
        reloadStudentAdvisors
    } = useContext(ActiveStudentContext)

    useEffect(() => {
        ;(async function getStudentStats() {
            const {enrolledStudents, sevStudents} = await studentServicesService.getStudentsStats()
            dispatch({
                studentStats: {
                    ...state.studentStats,
                    sev: sevStudents || 0,
                    enrolled: enrolledStudents || 0
                }
            })
        })()
    }, [statsKey])

    useEffect(() => {
        if (state.activeStudent && state.activeStudent.profileId) {
            setStudent(state.activeStudent)
            setDepartmentId(departmentId)
            studentDetailVisible.open()
            reloadStudent(state.activeStudent.profileId)
            reloadStudentAdvisors(state.activeStudent.profileId)
        }
    }, [state.activeStudent])

    const renderContent = () => {
        switch (viewType) {
            case "standard":
            case "kanban":
                return (
                    <StudentListTable
                        statusChange={statusChange}
                        studentStatusChange={studentStatusChange}
                        departmentId={departmentId}
                        studentId={studentId}
                        studentForm={state.studentForm}
                        studentFormSearch={state.studentFormSearch}
                        updateState={dispatch}
                        canShowAllStudents={canShowAllStudents}
                        canHaveAccessOthers={canHaveAccessOthers}
                        enrollmentProfileIdChange={enrollmentProfileIdChange}
                        filterMemoryKey={filterKey}
                        reloadStudentAdvisors={reloadStudentAdvisors}
                        useNewFilter
                        exportFileType={state.exportFileType}
                    />
                )
            case "priority":
            default:
                return (
                    <StudentPriorityList
                        statusChange={statusChange}
                        studentStatusChange={studentStatusChange}
                        departmentId={departmentId}
                        studentId={studentId}
                        studentForm={state.studentForm}
                        studentFormSearch={state.studentFormSearch}
                        updateState={dispatch}
                        canShowAllStudents={canShowAllStudents}
                        canHaveAccessOthers={canHaveAccessOthers}
                        filterMemoryKey={filterPriorityKey}
                        enrollmentProfileIdChange={enrollmentProfileIdChange}
                        useNewFilter
                    />
                )
        }
    }

    const onApplyFilter = (payload: AppliedFilter) => {
        const {filters, search} = payload
        dispatch({studentForm: filters, studentFormSearch: search})
    }

    return (
        <div>
            <Row gutter={[16, 24]} className={styles.marginBottom}>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <p className={styles.cardTitle}>{t("studentEnrollmentVerification")}</p>
                        </div>
                        <p className={styles.cardValue}>{state.studentStats?.sev || 0}</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <p className={styles.cardTitle}>{t("fullyEnrolled")}</p>
                        </div>
                        <p className={styles.cardValue}>{state.studentStats?.enrolled || 0}</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <p className={styles.cardTitle}>{t("courseRegistration")}</p>
                        </div>
                        <p className={styles.cardValue}>{state.studentStats?.registrationProgress || 0}</p>
                    </div>
                </Col>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <p className={styles.cardTitle}>{t("courseRegistrationCompleted")}</p>
                        </div>
                        <p className={styles.cardValue}>{state.studentStats?.registrationComplete || 0}</p>
                    </div>
                </Col>
            </Row>
            <StudentFilterV2
                status={Status.academicStatus}
                canShowAllStudents={canShowAllStudents}
                canHaveAccessOthers={canHaveAccessOthers}
                hideLeadRecordAction
                // viewTypes={["standard", "priority"]}
                onChangeViewType={setViewType}
                viewType={viewType}
                filterKey={filterKey}
                departmentId={departmentId}
                hideLeadSource
                showExport={viewType !== "priority"}
                isExporting={state.isExporting}
                handleExport={handleExportFile}
                initialFilter={INITIAL_FILTER}
                onApplyFilter={onApplyFilter}
                onClearFilter={() => onApplyFilter({filters: {}, search: ""})}
                showStudentServiceFilters
                showRiskLevelFilters={showRiskLevelFilters}
            />
            {renderContent()}
        </div>
    )
}
