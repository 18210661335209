/* eslint-disable react-hooks/exhaustive-deps */
import {handleError, toastError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {useModel, useVisible} from "hooks"
import {useCallback, useEffect, useState} from "react"
import {savedFilterService} from "services"
import {KlassappTableProps} from "types/common"
import {FilterKey, SavedFilter} from "types/filter"
import {ConfirmPopup, KlassappTable, KlassappTableHeader} from "uiKit"
import styles from "./SavedFiltersList.module.css"
import {BaseButton, BaseInput, BaseLoading, SecondaryButton} from "components"
import {Checkbox} from "antd"

type Props = KlassappTableProps & {
    filterKey: FilterKey
    onClose: () => void
}

function SavedFiltersTable(props: Props) {
    const model = useModel()
    const {
        data,
        page,
        total,
        pageSize,
        columns,
        fields,
        allFields,
        isLoading,
        orderField,
        isLoadedTableFuncs,
        dispatch,
        dispatchFunc,
        getCurrentData,
        isShowTableHeaderAction,
        tableHeaderActions,
        isHideMenuActions,
        menuActions
    } = props
    const {filterKey, onClose} = props
    const showConfirmationPopup = useVisible(false)
    const [selectedSavedFilterIds, setSelectedSavedFilterIds] = useState<number[]>([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [editItem, setEditItem] = useState<SavedFilter>()

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickEdit", func: onClickEdit},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickDelete", func: onClickDelete},
            {key: "getMenuActions", func: getMenuActions}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, orderField?.field, orderField?.order])

    useEffect(() => {
        if (editItem) {
            const data = getCurrentData()
            const newData = data.map((x) => {
                if (x.filterId === editItem.filterId) {
                    return {
                        ...x,
                        nameHtml: renderNameInput(x),
                        publicHtml: renderPublicHtml(x)
                    }
                }
                return x
            })
            dispatch({data: newData})
        }
    }, [editItem])

    const getData = useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const payload = {
                filter: {
                    filterKey
                },
                range: {
                    pageSize: pageSize,
                    page: page
                },
                sort: orderField?.field
                    ? {
                          orderBy: orderField.field,
                          orderDir: orderField.order
                      }
                    : undefined
            }
            const {data, total} = await savedFilterService.list(payload)
            const formattedData = data.map((x) => {
                return {...x, id: x.filterId, nameHtml: renderNameInput(x), publicHtml: renderPublicHtml(x)}
            })
            dispatch({data: formattedData, total})
        } catch (err) {
            handleError(err)
        } finally {
            dispatch({isLoading: false})
        }
    }, [filterKey, page, pageSize, orderField])

    const getFields = () => {
        return ["Name", "Public", "Created Date"]
    }

    const getColumns = () => {
        const cols: any = [
            {
                title: "Name",
                field: "nameHtml",
                style: {
                    minWidth: "100px",
                    maxWidth: "150px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                sortable: true
            },
            {
                title: "Public",
                field: "publicHtml",
                sortable: true
            },
            {
                title: "Created Date",
                field: "createdAt",
                fieldType: "date",
                format: model.getUserDateFormat(),
                style: {minWidth: "100px", maxWidth: "110px"},
                sortable: true
            }
        ]

        return cols
    }

    const canShowDeleteIcon = (data) => {
        return data.ownerUserId === model.user.id
    }

    const getMenuActions = () => {
        return [
            {
                title: "Edit",
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: "Delete",
                icon: "DELETE",
                action: onClickDelete,
                canShow: canShowDeleteIcon
            }
        ]
    }

    const getTableHeaderActions = () => {
        const actions: any[] = []
        const data = getCurrentData()

        const canDelete = data.filter((item) => item.isChecked).every((item) => item.ownerUserId === model.user.id)
        if (canDelete) {
            actions.push({
                title: "Delete",
                icon: "DELETE",
                action: onConfirmDelete
            })
        }

        return actions
    }

    const onConfirmDelete = () => {
        const data = getCurrentData()
        const checkedItems = data.filter((item) => item.isChecked)

        setSelectedSavedFilterIds(checkedItems.map((item) => item.filterId))
        showConfirmationPopup.open()
    }

    const onHandleDelete = async () => {
        showConfirmationPopup.close()
        try {
            setIsSubmitting(true)
            await savedFilterService.remove({ids: selectedSavedFilterIds})
            getData()
            dispatch({isShowTableHeaderAction: false, isHideMenuActions: false})
        } catch (err) {
            handleError(err)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onClickEdit = (item) => {
        setEditItem(item)
    }

    const onChangeItemData = (field: string, value: any) => {
        setEditItem((prev) => ({...prev, [field]: value}))
    }

    const onHandleSave = async () => {
        if (!editItem) return
        if (editItem.name.trim() === "") {
            toastError("Name can not be empty")
            return
        }
        try {
            setIsSubmitting(true)
            await savedFilterService.edit({
                filterId: editItem.filterId,
                name: editItem.name,
                isPublic: editItem.isPublic
            })
            getData()
            setEditItem(null)
            dispatch({isShowTableHeaderAction: false, isHideMenuActions: false})
        } catch (err) {
            handleError(err)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onClickDelete = async (deletedItem) => {
        setSelectedSavedFilterIds([deletedItem.userDocumentId])
        showConfirmationPopup.open()
    }

    const renderNameInput = useCallback(
        (item) => {
            if (editItem && item.filterId === editItem.filterId) {
                return (
                    <BaseInput
                        value={editItem.name}
                        placeholder="Name"
                        onChange={(value) => onChangeItemData("name", value)}
                    />
                )
            }
            return item.name
        },
        [editItem]
    )

    const renderPublicHtml = useCallback(
        (item) => {
            if (editItem && item.filterId === editItem.filterId) {
                return (
                    <Checkbox
                        checked={editItem.isPublic}
                        onChange={(event) => onChangeItemData("isPublic", event.target.checked)}
                    />
                )
            }
            return <Checkbox checked={item.isPublic} disabled />
        },
        [editItem]
    )

    return (
        <div className={styles.container}>
            <div className={styles.formTitle}>Saved filters</div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                menuActions={isHideMenuActions ? [] : menuActions}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onClickSortColumn={props.onClickSortColumn}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            <div className={styles.buttonWrap}>
                <SecondaryButton title="Cancel" onClick={onClose} />
                <BaseButton title="Save" onClick={onHandleSave} loading={isSubmitting} disabled={!editItem} />
            </div>
            <BaseLoading isShow={isSubmitting} />
            <ConfirmPopup
                isVisible={showConfirmationPopup.isVisible}
                title={`Are you sure you want to delete ${selectedSavedFilterIds.length} saved filter(s)?`}
                onClose={showConfirmationPopup.close}
                onConfirm={onHandleDelete}
            />
        </div>
    )
}

export const SavedFiltersList = KlassappTableHOC(SavedFiltersTable)
