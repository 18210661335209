import React from "react"
import {get} from "lodash"
import moment from "moment"
import cx from "classnames"
import {useModel} from "hooks"
import ActionRowItem from "./ActionRowItem"
import styles from "../FixedKlassappTable.module.css"
import {Checkbox} from "antd"

export function FixedRowItem(props) {
    const model = useModel()
    const currentUserDateFormat = model && model.getUserDateFormat && model.getUserDateFormat()
    const currentUserDateTimeFormat = model && model.getUserDateTimeFormat && model.getUserDateTimeFormat()
    const {
        rowData,
        rowIndex,
        leftWidth,
        fields,
        fixedColumns,
        rightHeaderColumns,
        isShowCheckedColumn,
        columnsKeyByField,
        menuActions,
        tableWidth
    } = props

    const renderDataByType = (column, data) => {
        const {fieldType, field, format} = column
        const value = get(data, field)
        switch (fieldType) {
            case "dateUtc":
                const formatDateUtc = currentUserDateFormat || format
                return value ? moment.utc(value).format(formatDateUtc) : ""
            case "date":
                const formatDate = currentUserDateFormat || format
                return value ? moment(value).format(formatDate) : ""
            case "dateTime":
                return value ? moment(value).format(currentUserDateTimeFormat || format) : ""
            case "withSubtext":
                return (
                    <>
                        <p>{value || ""}</p>
                        <p className={cx(styles.subText)}>{data.subText || ""}</p>
                    </>
                )
            default:
                return value || ""
        }
    }

    const onToggleCheck = (checked) => {
        const newData = {...rowData}
        newData.isChecked = checked
        if (props.onToggleCheckBox) {
            props.onToggleCheckBox(newData)
        } else {
            props.onUpdateRowData(newData, rowData)
        }
    }

    const filteredMenuActions = (menuActions || []).filter(
        (menuAction) => !menuAction.canShow || menuAction.canShow(rowData)
    )

    return (
        <div className={cx(styles.rowGroup, styles.rowHeaderGroup)}>
            <div className={styles.rowItem}>
                <div className={styles.stickyCell} style={{width: leftWidth}}>
                    {isShowCheckedColumn && (
                        <div
                            className={cx(styles.statusGroupWrap, styles.cell, {
                                [styles.evenCell]: rowIndex % 2 === 0
                            })}
                            style={{width: 30}}>
                            {rowData.isDisableCheckBox ? null : (
                                <Checkbox
                                    checked={!!rowData.isChecked}
                                    onChange={(event) => onToggleCheck(event.target.checked)}
                                />
                            )}
                        </div>
                    )}
                    {fixedColumns.map((column, index) => {
                        if (fields.includes(column.title)) {
                            const width = get(columnsKeyByField, [column.field, "width"], 100)
                            const maxWidth = get(columnsKeyByField, [column.field, "maxWidth"], undefined)
                            return (
                                <div
                                    key={index}
                                    className={cx(styles.statusGroupWrap, styles.cell, {
                                        [styles.evenCell]: rowIndex % 2 === 0
                                    })}
                                    style={{width: width * tableWidth, maxWidth: maxWidth}}>
                                    {renderDataByType(column, rowData)}
                                </div>
                            )
                        }
                        return null
                    })}
                </div>
                <div style={{width: leftWidth, minWidth: leftWidth}}></div>
                {rightHeaderColumns.map((column, index) => {
                    if (fields.includes(column.title)) {
                        const width = get(columnsKeyByField, [column.field, "width"], 100)
                        const maxWidth = get(columnsKeyByField, [column.field, "maxWidth"], undefined)
                        return (
                            <div key={index}>
                                <div
                                    className={cx(styles.cell, styles.cell48, {
                                        [styles.evenCell]: rowIndex % 2 === 0
                                    })}
                                    style={{width: width * tableWidth, maxWidth: maxWidth}}>
                                    <div className={styles.itemWrap}>
                                        <div className={styles.item}>{renderDataByType(column, rowData)}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    return null
                })}
                <div
                    style={{
                        flexGrow: 1
                    }}>
                    <div
                        className={cx(styles.cell, {[styles.evenCell]: rowIndex % 2 === 0})}
                        style={{minWidth: 40}}></div>
                </div>
                <div className={cx(styles.stickyCell, styles.menuActionWrap)} style={{width: 40}}>
                    <div
                        className={cx(styles.statusGroupWrap, styles.cell, {
                            [styles.evenCell]: rowIndex % 2 === 0
                        })}
                        style={{width: 40}}>
                        {menuActions && menuActions.length ? (
                            <ActionRowItem data={rowData} menuActions={filteredMenuActions} />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    )
}
