import {useCallback} from "react"
import {useQuery, useQueryClient} from "@tanstack/react-query"
import {settingActivityService} from "services"
import {UNLIMITED_PAGE_SIZE} from "data/constants"

const queryCacheKey = "all-activities"
const INITIAL_DATA = []

export const useAllActivities = () => {
    const queryClient = useQueryClient()

    const query = useQuery(
        [queryCacheKey],
        async () => {
            const {data: activites} = await settingActivityService.getAllActivities({
                filter: {},
                sort: {
                    orderBy: "name",
                    orderDir: "ASC"
                },
                range: {
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE
                }
            })
            return activites.map((activity) => ({...activity, id: activity.activityId}))
        },
        {
            refetchOnMount: false
        }
    )

    return {
        activities: query.data ?? INITIAL_DATA,
        invalidateActivitiesCache: useCallback(() => {
            queryClient.invalidateQueries({queryKey: [queryCacheKey]})
        }, [queryClient])
    }
}
