/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import cx from "classnames"
import {get} from "lodash"
import {Icon} from "components/Icon"
import {BarChart} from "components/Chart"
import {statsService} from "services"
import styles from "./StaffAnalytics.module.css"
import {handleError} from "helpers"
import {BaseButton} from "components/buttons"

const widthOfYAxes = 45

export enum StaffAnalyticsTitle {
    financialAid = "financialAid",
    admission = "admission"
}

type StaffAnalyticsProps = {
    title: StaffAnalyticsTitle
    departmentId: number
}

function StartDateItem(props) {
    const {item, widthOfChart, startTime} = props
    const compareTime = moment(startTime)
    const dateDiff = moment(item).diff(compareTime, "days")

    return (
        <div className={styles.startDateItem} style={{left: `${(dateDiff / 365) * (widthOfChart - widthOfYAxes)}px`}}>
            <Icon className={styles.startDateItem__icon} icon="ADMISSION_START_DATE" />
            <p className={styles.startDateItem__value}>{moment(item).format("MM/DD")}</p>
        </div>
    )
}

export function StaffAnalytics(props: StaffAnalyticsProps) {
    const [widthOfChart, setWidthOfChart] = useState(0)
    const [chartData, setChartData] = useState({labels: [], datasets: []})
    const startDateData = ["2021-02-13T14:26:08.000Z", "2021-04-15T14:26:08.000Z"]
    const {t} = useTranslation(["common"])

    useEffect(() => {
        getEnrolledStudentsInfo()
        const widthOfChartEl = document.getElementById("bar-chart")
        if (widthOfChartEl) {
            const width = Number.parseInt(widthOfChartEl.style.width, 10)
            if (width) {
                setWidthOfChart(width)
            }
        }
    }, [])

    const getEnrolledStudentsInfo = async () => {
        try {
            const data = await statsService.getDepartmentStatsEnrolledStudents({
                departmentId: props.departmentId
            })
            const chartData = {labels: [], datasets: []}
            chartData.labels = data.map((item) => item.yearMonth)
            chartData.datasets.push({
                label: "Enroll",
                data: data.map((item) => item.enrolled),
                backgroundColor: "#18a957"
            })
            setChartData(chartData)
        } catch (error) {
            handleError(error)
        }
    }

    const enroll = get(chartData, "datasets[0].data", []).reduce((acc, cur: any) => {
        return acc + Number.parseInt(cur, 10)
    }, 0)

    return (
        <div className={styles.wrap}>
            <div className={styles.headerWrap}>
                <div className={styles.headerLeft}>
                    <div className={styles.headerLeftTitleWrap}>
                        <p className={styles.headerLeft__title}>{t(`statsAnalytics.${props.title}`).toUpperCase()}</p>
                        <BaseButton title={t("action.fullAnalytics")} variant="secondary" onClick={() => {}} />
                    </div>
                    <div className={styles.headerLeftInfoWrap}>
                        <div className={styles.headerLeftInfo}>
                            <div className={styles.headerLeftInfo__color}></div>
                            <p className={styles.headerLeftInfo__title}>{t("statsAnalytics.enroll").toUpperCase()}</p>
                        </div>
                        <div className={styles.headerLeftInfo}>
                            <div className={cx(styles.headerLeftInfo__color, styles.headerLeftInfo__colorTarget)}></div>
                            <p className={styles.headerLeftInfo__title}>{t("statsAnalytics.target").toUpperCase()}</p>
                        </div>
                        <div className={styles.headerLeftInfo}>
                            <Icon icon="ADMISSION_START_DATE" />
                            <p className={styles.headerLeftInfo__title}>
                                {t("statsAnalytics.startDate").toUpperCase()}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.headerRightWrap}>
                    <div className={styles.headerRightItem}>
                        <p className={styles.headerRightItem__title}>{t("statsAnalytics.ENROLL")}</p>
                        <p className={styles.headerRightItem__value}>{enroll}</p>
                    </div>
                    <div className={cx(styles.headerRightItem, styles.headerRightItemTarget)}>
                        <p className={cx(styles.headerRightItem__title, styles.headerRightItem__titleTarget)}>
                            {t("statsAnalytics.TARGET")}
                        </p>
                        <p className={cx(styles.headerRightItem__value, styles.headerRightItem__valueTarget)}>0</p>
                    </div>
                </div>
            </div>
            <BarChart data={chartData} id="bar-chart" />
            <div className={styles.startDateWrap}>
                <div style={{width: `${widthOfYAxes}px`, minWidth: `${widthOfYAxes}px`}}></div>
                <div className={styles.startDateBodyWrap}>
                    {startDateData.map((item, index) => (
                        <StartDateItem
                            key={index}
                            item={item}
                            widthOfChart={widthOfChart}
                            startTime={chartData.labels[0]}
                        />
                    ))}
                </div>
            </div>
            <p className={styles.yearDesc} style={{paddingLeft: `${widthOfYAxes}px`}}>
                2021
            </p>
        </div>
    )
}
