/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./SearchChat.module.css"
import cx from "classnames"
import {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {ReactComponent as CloseIcon} from "./assets/close.svg"
import {Communication} from "types/communication"
import {useInfiniteQuery} from "@tanstack/react-query"
import {chatServiceV2} from "services"
import {handleErrorChat} from "helpers"
import {Spin} from "antd"
import {UserAvatar, ControlledSearch} from "sections/NewCommunication/shared"
import {useDebouncedValue} from "hooks"

type Props = {
    roomId?: number | string
    visible: boolean
    onHideSearch: () => void
    onClickResult: (_message: Communication.Message) => void
}

const ITEMS_PER_PAGE: number = 50

export default function SearchChat(props: Props) {
    const {visible, onHideSearch, onClickResult, roomId} = props
    const [searchText, setSearchText] = useState<string>("")
    const observer = useRef<IntersectionObserver>()
    const debouncedSearchText = useDebouncedValue(searchText)

    useEffect(() => {
        setSearchText("")
    }, [roomId])

    const fetchMessages = async ({pageParam = 1}) => {
        const {data, total} = await chatServiceV2.searchMessages({
            filter: {
                roomId,
                search: debouncedSearchText
            },
            range: {pageSize: ITEMS_PER_PAGE, page: pageParam}
        })
        return {results: data, nextPage: pageParam + 1, totalPages: Math.ceil(total / ITEMS_PER_PAGE)}
    }

    const {
        data: searchMessages,
        hasNextPage,
        fetchNextPage,
        isFetching,
        isLoading
    } = useInfiniteQuery(["room-search", roomId, debouncedSearchText], fetchMessages, {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.nextPage <= lastPage.totalPages) return lastPage.nextPage
            return undefined
        },
        onError: (err) => {
            handleErrorChat(err)
        },
        enabled: !!roomId && !!debouncedSearchText && visible
    })

    const lastElementRef = useCallback(
        (node: HTMLDivElement) => {
            if (isLoading) return

            if (observer.current) observer.current.disconnect()

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage && !isFetching) {
                    fetchNextPage()
                }
            })

            if (node) observer.current.observe(node)
        },
        [fetchNextPage, hasNextPage, isFetching, isLoading]
    )

    const data = useMemo(() => {
        return searchMessages?.pages.reduce((acc, page) => {
            return [...acc, ...page.results]
        }, [])
    }, [searchMessages])

    const isEmpty = useMemo(() => {
        if ((data || []).length > 0 || isLoading) {
            return false
        }
        return true
    }, [data, isLoading])

    if (!visible) return null

    const renderMessages = () => {
        if (debouncedSearchText === "") return null

        return (
            <div className={styles.sectionWrap}>
                {isEmpty ? (
                    <>
                        <div className={styles.notFound}>
                            <span className={styles.notFoundMessage}>No message found</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.infoHeading}>
                            <div className={styles.info}>
                                <span className={styles.title}>Found</span>
                            </div>
                        </div>
                        <div className={styles.messages}>
                            {(data || []).map((x) => {
                                return (
                                    <div
                                        ref={lastElementRef}
                                        className={styles.messageContainer}
                                        key={x.messageId}
                                        onClick={() => onClickResult(x)}>
                                        <div className={styles.infoContainer}>
                                            <UserAvatar user={x.author} className={styles.avatar} />
                                            <span className={styles.fullName}>{x.author.fullName}</span>
                                        </div>
                                        {x.jsonData?.email?.editorType === Communication.EditorType.Html ||
                                        x.jsonData?.source?.editorType === Communication.EditorType.Html ? (
                                            <div className={styles.emailAttachment}>Email attachment</div>
                                        ) : (
                                            <div
                                                className={styles.content}
                                                dangerouslySetInnerHTML={{__html: x.content}}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                            {isFetching && <Spin className={styles.loadingData} />}
                        </div>
                    </>
                )}
            </div>
        )
    }

    const renderContent = () => {
        return (
            <div className={styles.main}>
                <div className={styles.searchWrap}>
                    <ControlledSearch value={searchText} onChangeValue={setSearchText} />
                </div>
                {renderMessages()}
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <div className={cx(styles.section, styles.heading)}>
                <span className={styles.titleHeading}>Search message</span>
                <div className={styles.iconWrap} onClick={onHideSearch}>
                    <CloseIcon className={styles.icon} />
                </div>
            </div>
            <div className={styles.divider} />
            {renderContent()}
        </div>
    )
}
