/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import {formatCodeName} from "helpers"
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {tagService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"

type Props = Partial<KlassDropdownAsyncProps> & {
    creatable: boolean
}

const generateUniqueId = () => Date.now()

const TagSelect: React.FC<Props> = ({creatable, value, ...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            const {data} = await tagService.getAllTags({
                filter: {
                    search
                },
                range: {
                    pageSize: 20,
                    page: 1
                }
            })
            const found = value.find((x) => x.name.toLowerCase().trim() === search.toLowerCase().trim())
            if (data.length > 0 || !creatable || found) return data

            data.push({
                tagId: generateUniqueId(),
                name: search.trim(),
                isNew: true
            })
            return data
        }, 500),
        [value]
    )

    return (
        <KlassDropdownAsync
            {...props}
            value={value}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions
            valueKey="tagId"
            labelKey="name"
            getOptionLabel={formatCodeName}
        />
    )
}

export default TagSelect
