import {BasePopup} from "uiKit"
import {FilterKey} from "types/filter"
import {SavedFiltersList} from "./SavedFiltersList"

type Props = {
    isShow: boolean
    onClose: () => void
    filterKey: FilterKey
}

export function SavedFiltersPopup(props: Props) {
    const {isShow, onClose, filterKey} = props

    return (
        <BasePopup isShow={isShow} onCancel={onClose} width="60vw" leftIcon="FILTER" destroyOnClose>
            <SavedFiltersList filterKey={filterKey} onClose={onClose} />
        </BasePopup>
    )
}
