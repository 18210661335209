import React from "react"
import {DatePicker} from "antd"
import {Icon} from "components/Icon"
import cx from "classnames"
import styles from "./BaseDatePicker.module.css"
import {useModel} from "hooks"

const {RangePicker} = DatePicker

type RangePickerComponentsProps = {[key in string]: any}

export type BaseRangePickerProps = RangePickerComponentsProps & {
    value?: any
    onChange?: any
    className?: string
    format?: string
    error?: boolean
    disabled?: boolean
    readOnly?: boolean
    suffixIcon?: JSX.Element
    disabledDate?: (currentDate: any) => boolean
}

export function BaseRangePicker(props: BaseRangePickerProps) {
    const model = useModel()
    const {onChange, className, error, format, ...rest} = props
    const currentUserDateFormat = model && model.getUserDateFormat && model.getUserDateFormat()

    return (
        <RangePicker
            onChange={onChange}
            className={cx(styles.datePicker, className, {[styles.error]: error})}
            suffixIcon={
                <div className={styles.calendarIconWrap}>
                    <Icon icon="CALENDAR_INPUT" color="#fff" />
                </div>
            }
            format={format || currentUserDateFormat || "MM/DD/YYYY"}
            {...rest}
        />
    )
}
