import React, {useEffect} from "react"
import classNames from "classnames"
import {Icon} from "components/Icon"
import styles from "./MediaViewer.module.css"
import {Tooltip} from "antd"
import {useTranslation} from "react-i18next"

type Props = {
    isShow: boolean
    onClose: () => void
    url: string
}

export default function MediaViewer(props: Props) {
    const {isShow, onClose, url} = props
    const {t} = useTranslation(["common"])
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                onClose()
            }
        }
        document.addEventListener("keydown", handleKeyDown)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
        }
    }, [onClose])

    const onClickFile = (e) => {
        e.stopPropagation()
    }

    return (
        <div className={classNames(styles.root, {[styles.rootIsOpen]: isShow})} onClick={onClose}>
            <div className={styles.actionsContainer}>
                <Tooltip placement="bottomRight" title={t("common:action.close")}>
                    <button title="Close" onClick={onClose} className={styles.iconContainer}>
                        <Icon icon="CLOSE" color="var(--whitebase)" />
                    </button>
                </Tooltip>
            </div>
            {url && (
                <div className={styles.mediaContainer} onClick={onClickFile}>
                    <img src={url} alt={url} className={styles.image} />
                </div>
            )}
        </div>
    )
}
