import React from "react"
import {Upload, Button} from "antd"
import {Icon} from "components/Icon"
import cx from "classnames"
import styles from "./BaseInput.module.css"

export type BaseFileInputProps = {
    children?: any
    onChange: (file: File | File[]) => void
    className?: string
    accept?: string
    multiple?: boolean
}

type State = {
    fileName: any
    files: File[]
}

export default class BaseFileInput extends React.Component<BaseFileInputProps, State> {
    constructor(props) {
        super(props)
        this.state = {
            fileName: null,
            files: []
        }
    }

    onChangeFile = (file) => {
        if (this.props.multiple) {
            const {files} = this.state
            files.push(file)
            this.setState({files})
            this.props.onChange(files)
        } else if (file) {
            this.setState({fileName: file.name})
            this.props.onChange(file)
        }
        return false
    }

    onRemoveFile = (removedIndex) => {
        if (this.props.multiple) {
            const {files} = this.state
            const newFiles = files.filter((_, index) => index !== removedIndex)
            this.setState({files: newFiles})
            this.props.onChange(newFiles)
        } else {
            this.setState({fileName: null})
            this.props.onChange(null)
        }
    }

    renderMultipleFileUpload = () => {
        const {files} = this.state
        const {children, className, accept = "*", multiple = false} = this.props
        return (
            <>
                {files.map((file, index) => (
                    <div key={index} className={styles.fileInputWrap}>
                        <div className={cx(styles.fileNameWrap, className)}>
                            <span className={styles.fileName}>{file.name}</span>
                        </div>
                        <Button className={styles.removeIconBtn} onClick={() => this.onRemoveFile(index)}>
                            <Icon className={styles.removeIcon} icon="DELETE" color="#FFF" />
                        </Button>
                    </div>
                ))}
                <div className={styles.uploadWrap}>
                    <Upload beforeUpload={this.onChangeFile} accept={accept} multiple={multiple}>
                        {children}
                    </Upload>
                </div>
            </>
        )
    }

    render() {
        const {fileName} = this.state
        const {children, className, accept = "*", multiple = false} = this.props

        if (multiple) {
            return this.renderMultipleFileUpload()
        }

        return (
            <>
                {fileName && (
                    <div className={styles.fileInputWrap}>
                        <div className={cx(styles.fileNameWrap, className)}>
                            <span className={styles.fileName}>{fileName}</span>
                        </div>
                        <Button className={styles.removeIconBtn} onClick={this.onRemoveFile}>
                            <Icon className={styles.removeIcon} icon="DELETE" color="#FFF" />
                        </Button>
                    </div>
                )}
                <div className={cx(styles.uploadWrap, {[styles.hidden]: !!fileName})}>
                    <Upload beforeUpload={this.onChangeFile} accept={accept}>
                        {children}
                    </Upload>
                </div>
            </>
        )
    }
}
