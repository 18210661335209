/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useReducer, useState} from "react"
import {Col, Popover, Row} from "antd"
import {get, isEmpty} from "lodash"
import {useTranslation} from "react-i18next"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {BaseRangePicker} from "components/DateTimePicker"
import {KlassappTableHOC} from "HOC"
import {Icon} from "components/Icon"
import {attendanceService, reportsService} from "services"
import {handleError, toastInfo} from "helpers"
import styles from "./../common.module.css"
import stylesLessonList from "./LessonList.module.css"
import {useFilterStorage, useModel} from "hooks"
import {routing} from "helpers/routing"
import {useHistory} from "react-router-dom"
import {LessonAttendanceStatus, LessonStatus, RegistrarLesson} from "types/attendance"
import moment from "moment-timezone"
import {LessonAttendancePopup} from "../LessonAttendancePopup"
import {AcademicPlans} from "types/academicPlans"
import {FilterKey} from "types/filter"
import {useDebounce} from "react-use"
import {ExportButton, ExportFileType} from "components/ui"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {exportCsv, exportExcel} from "helpers/export-table"
import UserSelect from "components/UserSelect"
import {Auth} from "types/auth"
import {UserAutocomplete} from "types/user"
import CampusSelect from "components/CampusSelect"
import ProgramSelect from "components/ProgramSelect"

function reducer(state, action) {
    return {...state, ...action}
}

const initFilter = {
    search: "",
    campuses: [],
    programs: [],
    instructors: [],
    date: null
}

function LessonListTab(props) {
    const {t} = useTranslation(["studentServices", "common"])
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        FilterKey.RegistrarLessonList,
        initFilter
    )
    const [isExporting, setIsExporting] = useState(false)
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const history = useHistory()
    const [selectedLesson, setSelectedLesson] = useState<{
        lessonId: number
        termId: number
        courseId: number
        status: LessonStatus
    }>()
    const [showLessonAttendancePopup, setShowLessonAttendancePopup] = useState(false)
    const {data, page, total, pageSize, columns, fields, allFields, isLoading, dispatch, studentProfileId} = props
    const initialState = {
        count: {
            lessonsCount: 0,
            attendedStudentsCount: 0,
            missedStudentsCount: 0,
            actionNeededStudentsCount: 0
        }
    }
    const [searchDebounce, setSearchDebounce] = useState(searchValue)
    useDebounce(() => setSearchDebounce(searchValue), 500, [searchValue])
    const [{count}, dispatchState] = useReducer(reducer, initialState)

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getLessons},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        getLessonsStats()
    }, [searchDebounce])

    useEffect(() => {
        getLessons()
    }, [page, pageSize, searchDebounce])

    const renderMakeupInfo = (makeupInfo: {
        type: AcademicPlans.MakeupStudentType
        reason: string
        targetMakeupLesson?: {
            lessonName: string
            startsAt: string
            endAts: string
            termCode: string
            courseCode: string
            scheduleSuffix: string
        }
    }) => (
        <div onClick={(e) => e.preventDefault()}>
            <p className={stylesLessonList.titleMakeupPopup}>This lesson is a Makeup lesson for:</p>
            <div className={stylesLessonList.detailMakeupPopup}>
                {makeupInfo.type === AcademicPlans.MakeupStudentType.MakeupAttendance ? (
                    <>
                        <span className={stylesLessonList.contentMakeupPopup}>
                            {makeupInfo.targetMakeupLesson?.lessonName} ({makeupInfo.targetMakeupLesson?.termCode}-
                            {makeupInfo.targetMakeupLesson?.scheduleSuffix})
                        </span>
                        <span className={stylesLessonList.contentMakeupPopup}>
                            {moment(makeupInfo.targetMakeupLesson?.startsAt).format(model.getUserDateFormat())},{" "}
                            {moment(makeupInfo.targetMakeupLesson?.startsAt).format(model.getUserTimeFormat())} -{" "}
                            {moment(makeupInfo.targetMakeupLesson?.endAts).format(model.getUserTimeFormat())}
                        </span>
                    </>
                ) : (
                    <div className={stylesLessonList.rowContentMakeup}>
                        <span className={stylesLessonList.contentMakeupPopup}>Reason:</span>
                        <span className={stylesLessonList.contentMakeupPopup}>{makeupInfo.reason ?? ""}</span>
                    </div>
                )}
            </div>
        </div>
    )

    const renderName = (item) => {
        return (
            <div className={stylesLessonList.name}>
                <div className={stylesLessonList.nameStudent}>
                    <span>{item.name}</span>
                </div>
                {!!item.isMakeup && (
                    <Popover placement="right" showArrow={false} content={renderMakeupInfo(item.makeupInfo)}>
                        <div onClick={(e) => e.stopPropagation()} className={stylesLessonList.icon}>
                            <Icon icon="PLUS_PERSON" color="#666" />
                        </div>
                    </Popover>
                )}
            </div>
        )
    }

    const getFilterParams = () => {
        const params: any = {
            search: searchDebounce,
            onlyPastLesson: true,
            campusIds: !isEmpty(currentFilter.campuses) ? currentFilter.campuses.map((campus) => campus.id) : undefined,
            programIds: !isEmpty(currentFilter.programs)
                ? currentFilter.programs.map((program) => program.id)
                : undefined,
            instructorProfileIds: !isEmpty(currentFilter.instructors)
                ? currentFilter.instructors.map((instructor) => instructor.profileId)
                : undefined,
            startDate: get(currentFilter, ["date", 0])
                ? moment(currentFilter.date[0]).startOf("day").utc().format()
                : undefined,
            endDate: get(currentFilter, ["date", 1])
                ? moment(currentFilter.date[1]).startOf("day").utc().format()
                : undefined
        }
        const profileId = model.profileId
        if (studentProfileId) {
            params.studentProfileIds = [+studentProfileId]
        } else if (!model.isStaffOrAdmin()) {
            params.studentProfileIds = [+profileId]
        }
        return params
    }

    async function getLessonsStats() {
        try {
            const params = getFilterParams()
            const lessonsStats = await attendanceService.getRegistrarLessonStats(params)
            dispatchState({count: lessonsStats})
        } catch (error) {
            handleError(error)
        }
    }

    async function getLessons() {
        try {
            dispatch({isLoading: true})
            const params = getFilterParams()
            const {data, total} = await attendanceService.getRegistrarLessonList({
                page,
                pageSize,
                ...params
            })
            const lessons = data.map((lesson) => {
                lesson.id = lesson.lessonId
                lesson.attendanceStatus = renderAttendanceStatus(lesson)
                lesson.lessonStatus = renderLessonStatus(lesson)
                lesson.isMakeup = !!lesson.makeupInfo
                lesson.lessonNameHtml = renderName(lesson)
                lesson.instructorsHtml = lesson.instructors ?? ""
                lesson.attendedStudentsCount = get(lesson, ["attendedStudentsCount"], 0).toString()
                lesson.missedStudentsCount = get(lesson, ["missedStudentsCount"], 0).toString()
                return lesson
            })
            dispatch({data: lessons, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function renderLessonStatus(lesson) {
        if (lesson.status === LessonStatus.Active || lesson.status === LessonStatus.Paused) {
            return "STARTED"
        }
        if (lesson.status === LessonStatus.Ended) {
            return "ENDED"
        }
        return "NOT STARTED"
    }

    function renderAttendanceStatus(lesson) {
        if (lesson.attendanceStatus === LessonAttendanceStatus.Verified) {
            return <Icon icon="CHECKED" />
        }

        if (lesson.attendanceStatus === LessonAttendanceStatus.NeedAction) {
            return <Icon icon="ALERT" />
        }
        return (
            <div className={stylesLessonList.notAttended}>
                <Icon className={stylesLessonList.notAttendedIcon} icon="CROSS_CIRCLE" />
            </div>
        )
    }

    function getColumns() {
        return [
            {
                title: t("attendance.table.date"),
                field: "date",
                fieldType: "date",
                format: dateFormat,
                headerStyle: {minWidth: "100px"}
            },
            {
                title: t("attendance.table.id"),
                field: "id",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.code"),
                field: "code",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: t("attendance.table.name"),
                field: "lessonNameHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.type"),
                field: "type",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.instructor"),
                field: "instructorsHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.student"),
                field: "allStudentsCount",
                headerStyle: {minWidth: "90px"}
            },
            {
                title: t("attendance.table.attended"),
                field: "attendedStudentsCount",
                headerStyle: {minWidth: "100px"}
            },
            {
                title: t("attendance.table.missed"),
                field: "missedStudentsCount",
                headerStyle: {minWidth: "80px"}
            },
            {
                title: t("attendance.table.attendanceStatus"),
                field: "attendanceStatus",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.lessonStatus"),
                field: "lessonStatus",
                headerStyle: {minWidth: "150px"}
            }
        ]
    }

    function getFields() {
        return [
            t("attendance.table.date"),
            t("attendance.table.id"),
            t("attendance.table.code"),
            t("attendance.table.name"),
            t("attendance.table.type"),
            t("attendance.table.instructor"),
            t("attendance.table.student"),
            t("attendance.table.attended"),
            t("attendance.table.missed"),
            t("attendance.table.attendanceStatus"),
            t("attendance.table.lessonStatus")
        ]
    }

    function onClickRow(data) {
        if (model.isStaffOrAdmin()) {
            history.push(routing.registrar.lessonDetail(data.courseId, data.termId, data.id))
        } else {
            setSelectedLesson({
                lessonId: data.lessonId,
                termId: data.termId,
                courseId: data.courseId,
                status: data.status
            })
            setShowLessonAttendancePopup(true)
        }
    }

    const onClickClearFilter = () => {
        clearFilter()
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    const onSearchInput = (search) => {
        changeSearch(search)
    }

    const onApplyFilter = () => {
        applyFilter()
        getLessonsStats()
        getLessons()
    }

    function renderAttendanceStatusReport(lesson: RegistrarLesson) {
        if (lesson.attendanceStatus === LessonAttendanceStatus.Verified) {
            return "Verified"
        }

        if (lesson.attendanceStatus === LessonAttendanceStatus.NeedAction) {
            return "Need Action"
        }
        return "No Attendances"
    }

    const formatData = (data: RegistrarLesson[]) => {
        const lessons = data.map((lesson) => {
            return {
                ...lesson,
                id: lesson.lessonId,
                attendanceStatus: renderAttendanceStatusReport(lesson),
                lessonStatus: renderLessonStatus(lesson),
                isMakeup: !!lesson.makeupInfo,
                lessonNameHtml: lesson.name,
                instructorsHtml: lesson.instructors ?? "",
                attendedStudentsCount: get(lesson, ["attendedStudentsCount"], 0).toString(),
                missedStudentsCount: get(lesson, ["missedStudentsCount"], 0).toString()
            }
        })
        return lessons
    }

    const onClickExportBtn = async (type: ExportFileType) => {
        if (total > 1000) {
            try {
                setIsExporting(true)
                const params = getFilterParams()
                const reportColumns = getColumns()
                    .filter((col) => fields.includes(col.title))
                    .map((column) => ({
                        field: column.field,
                        title: column.title
                    }))
                await reportsService.generateReport({
                    reportKey: "registrarLessons",
                    payload: {filter: params, fields: reportColumns},
                    format: type
                })
                toastInfo(
                    "The report contains more than 1000 records; it will take some time to generate it. We will send you a notification with the download link when the report is ready"
                )
            } catch (error) {
                handleError(error)
            } finally {
                setIsExporting(false)
            }
        } else {
            try {
                setIsExporting(true)
                const params = getFilterParams()
                const {data} = await attendanceService.getRegistrarLessonList({
                    page: 1,
                    pageSize: UNLIMITED_PAGE_SIZE,
                    ...params
                })
                const lessons = formatData(data)
                const filename = `Registrar-Lessons-Report-${moment().format("MM_DD_YYYY")}`
                const reportColumns = getColumns().filter((col) => fields.includes(col.title))
                const payload = {
                    filename,
                    columns: reportColumns.map((col) => col.title.toUpperCase()),
                    rows: lessons.map((item) =>
                        reportColumns.map((col) => {
                            return item[col.field]?.toString()?.replace(/\r\n|\n|\r/g, " ") ?? ""
                        })
                    )
                }
                if (type === "csv") {
                    exportCsv(payload)
                } else if (type === "excel") {
                    exportExcel(payload)
                }
            } catch (error) {
                handleError(error)
            } finally {
                setIsExporting(false)
            }
        }
    }

    return (
        <div>
            <BaseNewFilter
                filter={currentFilter}
                onClick={onApplyFilter}
                searchValue={searchValue}
                onClickClear={onClickClearFilter}
                renderRightFilter={() => (
                    <div className={stylesLessonList.actionWrap}>
                        <ExportButton
                            onSelect={onClickExportBtn}
                            isLoading={isExporting}
                            availableFileTypes={["csv", "excel"]}
                        />
                    </div>
                )}
                onSearchInput={onSearchInput}>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <UserSelect
                            isMulti
                            type={Auth.UserProfileType.Staff}
                            value={currentFilter.instructors}
                            placeholder={t("filter.instructor")}
                            onChange={(options?: UserAutocomplete[]) => {
                                onChangeFilter("instructors", options ?? [])
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <BaseRangePicker
                            placeholder={["Lesson Start Date", "Lesson End Date"]}
                            value={
                                currentFilter.date
                                    ? [moment(currentFilter.date[0]), moment(currentFilter.date[1])]
                                    : null
                            }
                            onChange={(newValue) => {
                                onChangeFilter("date", newValue)
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <CampusSelect
                            value={currentFilter.campuses}
                            placeholder={t("filter.campus")}
                            onChange={(option: any) => onChangeFilter("campuses", option ?? [])}
                            isMulti
                        />
                    </Col>
                    <Col span={12}>
                        <ProgramSelect
                            value={currentFilter.programs}
                            placeholder={t("filter.program")}
                            onChange={(option: any) => onChangeFilter("programs", option ?? [])}
                            isMulti
                        />
                    </Col>
                </Row>
            </BaseNewFilter>
            <Row gutter={16} className={styles.cardsMaring}>
                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.lessonColor}`}>
                                <Icon icon="TIMER" />
                            </div>
                            <p className={styles.cardTitle}>{t("attendance.lessonsList.lessons")}</p>
                        </div>
                        <p className={styles.cardValue}>{count.lessonsCount}</p>
                    </div>
                </Col>

                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.attendedColor}`}>
                                <Icon icon="PERSON_DONE" color="var(--success-400-base)" />
                            </div>
                            <p className={styles.cardTitle}>{t("attendance.lessonsList.attended")}</p>
                        </div>
                        <p className={styles.cardValue}>{count.attendedStudentsCount}</p>
                    </div>
                </Col>

                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.missedColor}`}>
                                <Icon icon="PERSON_REMOVE" color="var(--error-300)" />
                            </div>
                            <p className={styles.cardTitle}>{t("attendance.lessonsList.missed")}</p>
                        </div>
                        <p className={styles.cardValue}>{count.missedStudentsCount}</p>
                    </div>
                </Col>

                <Col span={6}>
                    <div className={styles.cardWrap}>
                        <div className={styles.titleWrap}>
                            <div className={`${styles.iconWrapper} ${styles.actionColor}`}>
                                <Icon icon="WARNING_TRIANGLE_FILL" color="var(--warning-400-base)" />
                            </div>
                            <p className={styles.cardTitle}>{t("attendance.lessonsList.actionNeeded")}</p>
                        </div>
                        <p className={styles.cardValue}>{count.actionNeededStudentsCount}</p>
                    </div>
                </Col>
            </Row>
            <Row className={styles.marginTop}>
                <Col span={24}>
                    <KlassappTableHeader
                        page={page}
                        total={total}
                        defaultPageSize={pageSize}
                        onChangePage={props.onChangePage}
                        onChangeRowPerPage={props.onChangeRowPerPage}
                        fields={fields}
                        allFields={allFields}
                        onChangeFields={props.onChangeFields}
                        onChangeAllFields={props.onChangeAllFields}
                        onDraggableColumn={props.onDraggableColumn}
                    />
                    <KlassappTable
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        fields={fields}
                        allFields={allFields}
                        onClickRowItem={onClickRow}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                        onDraggableColumn={props.onDraggableColumn}
                        onChangeAllFields={props.onChangeAllFields}
                    />
                </Col>
            </Row>
            {!model.isStaffOrAdmin() ? (
                <LessonAttendancePopup
                    studentProfileIds={[model.profileId]}
                    isShow={showLessonAttendancePopup}
                    lessonId={selectedLesson?.lessonId}
                    courseId={selectedLesson?.courseId}
                    status={selectedLesson?.status}
                    termId={selectedLesson?.termId}
                    onClose={() => setShowLessonAttendancePopup(false)}
                />
            ) : null}
        </div>
    )
}

export const LessonList = KlassappTableHOC(LessonListTab)
