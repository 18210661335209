import React, {useEffect, useMemo, useRef, useState} from "react"
import {BasePopup} from "components/popup"
import styles from "./LedgerCardPopup.module.css"
import {useAllAvailableStudentStatuses, useModel} from "hooks"
import {BaseButton} from "components"
import {Branding} from "types/branding"
import {convertImgToBase64, formatCurrency, getFullName, handleError} from "helpers"
import {StudentTransaction, StudentTransactionType} from "types/student-account/student-ledger"
import moment from "moment"
import {Auth} from "types/auth"
import cx from "classnames"
import jsPDF from "jspdf"
import {
    fontBold,
    fontRegular
} from "sections/FinancialAid/FinancialAidStudent/parts/CollegeFinancingPlan/CFPStepDetail/font"
import {PAYMENT_METHOD_OPTIONS} from "types/student-account/student-payment"

type Props = {
    isShow: boolean
    onClose: () => void
    student?: Auth.DepartmentStudent
    transactions?: StudentTransaction[]
}

export function LedgerCardPopup({isShow, onClose, student, transactions}: Props) {
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const pdfRef = useRef<HTMLDivElement>()
    const [isDownloading, setDownloading] = useState(false)
    const [logoBase64, setLogoBase64] = useState("")

    const visibleTransactions = useMemo(() => transactions?.filter((item) => item.id >= 0), [transactions])
    const {totalCharge, totalPayment} = useMemo(() => {
        let totalCharge = 0
        let totalPayment = 0
        visibleTransactions.forEach((transaction) => {
            switch (transaction.type) {
                case StudentTransactionType.Charge:
                    totalCharge += transaction.amount
                    break
                case StudentTransactionType.Payment:
                    totalPayment += transaction.amount
                    break
            }
        })
        return {totalCharge, totalPayment}
    }, [visibleTransactions])

    const handleDownload = React.useCallback(async () => {
        setDownloading(true)
        try {
            const element = pdfRef.current
            const pdf = new jsPDF("portrait", "mm", [element.clientWidth, (element.clientWidth * 297) / 210])

            pdf.addFileToVFS("Poppins-regular.ttf", fontRegular)
            pdf.addFont("Poppins-regular.ttf", "Poppins", "normal", "normal")
            pdf.addFileToVFS("Poppins-bold.ttf", fontBold)
            pdf.addFont("Poppins-bold.ttf", "Poppins", "normal", "bold")

            pdf.setFont("Poppins")

            await pdf.html(element, {
                image: {
                    type: "jpeg",
                    quality: 100
                },
                html2canvas: {
                    scale: 1,
                    allowTaint: true,
                    letterRendering: true,
                    svgRendering: true
                }
            })

            pdf.save(`Student Ledger Statement - ${getFullName(student)} - ${moment().format("YYYY-MM-DD")}.pdf`)
        } catch (error) {
            handleError(error)
        } finally {
            setDownloading(false)
        }
    }, [student])

    useEffect(() => {
        const logo = model.clientSetting?.branding?.[Branding.BrandingType.OfficialLogoSquare]
        if (logo) {
            convertImgToBase64(`${logo}?t=${Date.now()}`).then(setLogoBase64)
        }
    }, [model.clientSetting?.branding])

    const {statuses} = useAllAvailableStudentStatuses()

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="DOLLAR_SIGN" leftIconColor="#fff" width="70vw">
            <div className={styles.container}>
                <BaseButton
                    className={styles.downloadButton}
                    loading={isDownloading}
                    onClick={handleDownload}
                    title={"Download"}
                />

                <div ref={pdfRef} className={styles.pdfPage}>
                    <div className={styles.titleSection}>
                        <img className={styles.logo} alt="" src={logoBase64} />
                        <div>
                            <h1 className={styles.pageTitle}>Student Ledger / Statement</h1>
                        </div>
                        <div>{moment().format(userDateTimeFormat)}</div>
                    </div>

                    <div className={styles.studentSection}>
                        <div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Student Name</div>
                                <div className={styles.studentValue}>
                                    <b>{getFullName(student)}</b>
                                </div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Student Status</div>
                                <div className={styles.studentValue}>
                                    <b>{statuses.find((status) => status.statusId === student?.statusId)?.name}</b>
                                </div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Enrollment ID</div>
                                <div className={styles.studentValue}>{student?.customProfileId}</div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Address</div>
                                <div className={styles.studentValue}>
                                    {student?.address}
                                    <br />
                                    {student?.city}, {student?.state} {student?.postalCode}
                                </div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Start Date</div>
                                <div className={styles.studentValue}>
                                    {student?.startDate ? moment(student.startDate).format(userDateFormat) : ""}
                                </div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>LDA</div>
                                <div className={styles.studentValue}>
                                    {student?.lastDayOfAttendance
                                        ? moment(student.lastDayOfAttendance).format(userDateFormat)
                                        : ""}
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Balance</div>
                                <div className={styles.studentValue}>{formatCurrency(totalCharge - totalPayment)}</div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Campus</div>
                                <div className={styles.studentValue}>{student?.campuses?.[0]}</div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Current Term</div>
                                <div className={styles.studentValue}>{student?.termName}</div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Program Version</div>
                                <div className={styles.studentValue}>{student?.programVersionName}</div>
                            </div>
                            <div className={styles.studentRow}>
                                <div className={styles.studentLabel}>Graduation Date</div>
                                <div className={styles.studentValue}>
                                    {student?.graduationDate
                                        ? moment(student.graduationDate).format(userDateFormat)
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.tableHeaderText}>Date</th>
                                    <th className={styles.tableHeaderText}>Receipt/Ref</th>
                                    <th className={styles.tableHeaderText}>Payment Method</th>
                                    <th className={styles.tableHeaderText}>Type</th>
                                    <th className={styles.tableHeaderText}>Description</th>
                                    <th className={cx(styles.tableHeaderText, styles.money)}>Charge</th>
                                    <th className={cx(styles.tableHeaderText, styles.money)}>Payment</th>
                                    <th className={cx(styles.tableHeaderText, styles.money)}>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {visibleTransactions.map((transaction) => {
                                    const amount = formatCurrency(transaction.amount)
                                    const balance = transaction.balance ? formatCurrency(transaction.balance) : ""
                                    let description = ""
                                    let transactionType = ""
                                    let receiptRef = ""
                                    switch (transaction.type) {
                                        case StudentTransactionType.Charge:
                                            description = transaction.charge?.description || ""
                                            transactionType =
                                                transaction.charge?.account?.name ||
                                                transaction.charge?.customAccountName
                                            receiptRef = transaction.charge?.notes
                                            break
                                        case StudentTransactionType.Payment:
                                            description = transaction.payment?.extraDescription || ""
                                            transactionType = transaction.payment?.account?.name
                                            receiptRef = transaction.payment?.receiptId
                                            break
                                    }
                                    return (
                                        <tr key={transaction.tranxId}>
                                            <td className={styles.tableColumn}>
                                                {moment(transaction.tranxDate).format(userDateFormat)}
                                            </td>
                                            <td
                                                className={cx(styles.tableColumn, styles.receiptText)}
                                                title={receiptRef}>
                                                {receiptRef}
                                            </td>
                                            <td className={styles.tableColumn}>
                                                {PAYMENT_METHOD_OPTIONS.find(
                                                    (option) => option.id === transaction.payment?.methodOfPayment
                                                )?.name || transaction.payment?.methodOfPayment}
                                            </td>
                                            <td className={styles.tableColumn}>{transactionType}</td>
                                            <td className={styles.tableColumn}>{description}</td>

                                            <td className={cx(styles.tableColumn, styles.money)}>
                                                {transaction.type === StudentTransactionType.Charge && amount}
                                            </td>
                                            <td className={cx(styles.tableColumn, styles.money)}>
                                                {transaction.type === StudentTransactionType.Payment && amount}
                                            </td>
                                            <td className={cx(styles.tableColumn, styles.money)}>{balance}</td>
                                        </tr>
                                    )
                                })}
                                <tr className={styles.totalRow}>
                                    <td className={styles.tableColumn} />
                                    <td className={styles.tableColumn} />
                                    <td className={styles.tableColumn} />
                                    <td className={styles.tableColumn} />
                                    <td className={styles.tableColumn}>
                                        <b>Total</b>
                                    </td>
                                    <td className={cx(styles.tableColumn, styles.money)}>
                                        <b>{formatCurrency(totalCharge)}</b>
                                    </td>
                                    <td className={cx(styles.tableColumn, styles.money)}>
                                        <b>{formatCurrency(totalPayment)}</b>
                                    </td>
                                    <td className={cx(styles.tableColumn, styles.money)} />
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </BasePopup>
    )
}
