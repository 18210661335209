/* eslint-disable react-hooks/exhaustive-deps */
import {Permissions} from "@edular/permissions"
import {Row} from "antd"
import cx from "classnames"
import {PDFViewer} from "components/FileViewer"
import {BasePopup} from "components/popup"
import {Screen} from "components/ui"
import {
    copyTextToClipboard,
    getPreviewUnavailableImage,
    handleError,
    onErrorImageLoad,
    toastError,
    toastSuccess
} from "helpers"
import {KlassappTableHOC} from "HOC"
import {useCurrentProfilePermissions, useModel, useVisible} from "hooks"
import {isEmpty, keys, map} from "lodash"
import moment from "moment"
import {useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import ReactPlayer from "react-player/lazy"
import {useHistory} from "react-router-dom"
import {mediaLibraryService} from "services"
import {FilterKey} from "types/filter"
import {ConfirmPopupChildren} from "uiKit"
import styles from "./MediaLibrary.module.css"
import {Filter, ViewBlocksMediaLibrary, ViewListMediaLibrary} from "./parts"
import {MEDIAL_LIBRARY_ITEM_TYPE_OPTIONS} from "./parts/Filter/Filter"
import TagSlider from "./parts/TagSlider"
import {Tag} from "types/tags"

export enum ViewType {
    Blocks = "blocks",
    List = "list"
}

const defaultFilter = {
    itemType: null,
    updatedAt: null,
    campuses: [],
    departmentSubunits: [],
    degreeLevels: [],
    programs: [],
    userTypes: [],
    userStates: [],
    tags: []
}

function MediaLibrary(props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        pageSize,
        data,
        isLoading,
        isLoadedTableFuncs,
        getCurrentPage,
        getCurrentPageSize,
        menuActions
    } = props
    const model = useModel()
    const currentProfilePermissions = useCurrentProfilePermissions()
    const storageData = model.getStorageFilter(FilterKey.MediaLibrary)

    const [filterObject, setFilterObject] = useState<any>(
        !isEmpty(storageData?.filter) ? storageData.filter : defaultFilter
    )
    const [search, setSearch] = useState(!isEmpty(storageData) ? storageData.search : "")
    const [deletedItem, setDeletedItem] = useState<any>(null)
    const [isPageFavorite, setPageFavorite] = useState<boolean>(false)
    const [total, setTotal] = useState(0)
    const [stats, setStats] = useState<any>({videos: 0, images: 0, links: 0, documents: 0})
    const [viewType, setViewType] = useState<ViewType>(ViewType.Blocks)
    const [selectedItem, setSelectedItem] = useState(null)
    const [htmlMediaFile, setHtmlMediaFile] = useState(null)

    const {t} = useTranslation(["common", "settings"])
    const history = useHistory()
    const confirmPopup = useVisible()

    const hasPermissionsToEdit = currentProfilePermissions.hasPermissions({
        staff: [Permissions.Staff.Resources.MediaLibrary.General.Edit],
        student: [Permissions.Student.Resources.MediaLibrary.Edit],
        others: [Permissions.Others.Resources.MediaLibrary.Edit]
    })

    const hasPermissionsToDelete = currentProfilePermissions.hasPermissions({
        staff: [Permissions.Staff.Resources.MediaLibrary.General.Delete],
        student: [Permissions.Student.Resources.MediaLibrary.Delete],
        others: [Permissions.Others.Resources.MediaLibrary.Delete]
    })

    const hasPermissionsToTogglePin = currentProfilePermissions.hasPermissions({
        staff: [Permissions.Staff.Resources.MediaLibrary.PinnedItems.Edit]
    })

    const iframeOver = useRef(false)
    const selectedItemIframe = useRef(null)

    useEffect(() => {
        const onWindowBlur = () => {
            if (iframeOver.current) {
                setSelectedItem(selectedItemIframe.current)
            }
        }

        window.focus()
        window.addEventListener("blur", onWindowBlur)

        return () => {
            window.removeEventListener("blur", onWindowBlur)
        }
    }, [])

    const getFields = () => {
        return [
            t("settings:mediaLibrary.listView.title"),
            t("settings:mediaLibrary.listView.description"),
            t("settings:mediaLibrary.listView.type"),
            t("settings:mediaLibrary.listView.uploaded")
        ]
    }

    const getFilterMemoryKey = () => {
        return FilterKey.MediaLibrary
    }

    const getColumns = () => {
        return [
            {
                title: t("settings:mediaLibrary.listView.title"),
                columnIndex: 1,
                field: "title",
                render: (_, record) => renderTitle(record)
            },
            {
                title: t("settings:mediaLibrary.listView.description"),
                columnIndex: 2,
                field: "descriptionShort"
            },
            {
                title: t("settings:mediaLibrary.listView.type"),
                columnIndex: 3,
                field: "typeCaps"
            },
            {
                title: t("settings:mediaLibrary.listView.uploaded"),
                field: "createdAt",
                fieldType: "date",
                columnIndex: 4,
                format: "MM/DD/YYYY hh:ii A",
                style: {minWidth: 150}
            }
        ]
    }

    const renderTitle = (record) => {
        return (
            <div onClick={() => setSelectedItem(record)} className={styles.listTitle}>
                {record.title}
            </div>
        )
    }

    useEffect(() => {
        if (selectedItem) {
            let html: any = null
            if (selectedItem.type === "image") {
                html = (
                    <div className={styles.mediaPopupContainer}>
                        <h1>{selectedItem.title}</h1>
                        <p>{selectedItem.description}</p>
                        <img
                            src={selectedItem.details.url ?? getPreviewUnavailableImage(model.clientSetting.slug)}
                            alt=""
                        />
                    </div>
                )
            } else if (selectedItem.type === "video") {
                html = (
                    <div className={styles.mediaPopupContainer}>
                        <h1>{selectedItem.title}</h1>
                        <p>{selectedItem.description}</p>
                        <ReactPlayer url={selectedItem.details.videoUrl} controls />
                    </div>
                )
            } else if (selectedItem.type === "document") {
                html = (
                    <div className={styles.mediaPopupContainer}>
                        <h1>{selectedItem.title}</h1>
                        <p>{selectedItem.description}</p>
                        <PDFViewer
                            fileSrc={selectedItem.details.url}
                            options={{toolbar: false, zoom: "fitToWidth", view: "fitToWidth"}}
                        />
                    </div>
                )
            } else if (selectedItem.type === "link") {
                window.open(selectedItem.details.url, "_blank")
                setSelectedItem(null)
            }

            if (html) {
                setHtmlMediaFile(html)
            }
        }
    }, [selectedItem])

    const onClosePopup = () => {
        setHtmlMediaFile(null)
        setSelectedItem(null)
        handleMouseOut()
    }

    const handleMouseOver = (item) => {
        iframeOver.current = true
        selectedItemIframe.current = item
    }

    const handleMouseOut = () => {
        window.focus()
        iframeOver.current = false
        selectedItemIframe.current = null
    }

    const copyLink = async ({type, details}) => {
        try {
            const link: string = type === "video" ? details.videoUrl : details.url
            if (link) {
                await copyTextToClipboard(link)
                return toastSuccess("Link copied")
            }
            toastError("Cannot copy link as link is invalid")
        } catch (err) {
            handleError(err)
        }
    }

    const getFilter = () => {
        const filter = {
            search: search,
            userTypes: map(filterObject.userTypes, (item) => item.id),
            itemTypes: filterObject.itemType ? [filterObject.itemType.id] : null,
            updatedAt: filterObject.updatedAt ? moment(filterObject.updatedAt).format("YYYY-MM-DD") : null,
            campusIds: map(filterObject.campuses, (item) => item.id),
            departmentSubunitIds: map(filterObject.departmentSubunits, (item) => item.subunitId),
            degreeLevelIds: map(filterObject.degreeLevels, (item) => item.degreeLevelId),
            programIds: map(filterObject.programs, (item) => item.id),
            userStates: map(filterObject.userStates, (item) => item.id),
            tagIds: map(filterObject.tags, (item) => item.tagId)
        }
        let result: any = {}
        keys(filter).forEach((field) => {
            if (!isEmpty(filter[field])) {
                result[field] = filter[field]
            }
        })
        const segments = new URL(window.location.href).pathname.split("/")
        const last = segments.pop() || segments.pop() // Handle potential trailing slash
        if (last === "favorite") {
            result.onlyFavorite = true
            setPageFavorite(true)
        }
        return result
    }

    const getParams = () => {
        const filter = getFilter()
        const storageData = model.getStorageFilter(FilterKey.MediaLibrary)
        const page = storageData?.page || getCurrentPage()
        const pageSize = storageData?.pageSize || getCurrentPageSize()
        const params = {
            filter,
            range: viewType === "list" ? {page, pageSize} : {page: 1, pageSize: 100},
            sort: [
                {orderBy: "sequenceNumber", orderDir: "asc"},
                {orderBy: "createdAt", orderDir: "desc"}
            ],
            linkedEntities: true
        }
        return params
    }

    const getData = async (customFilters?) => {
        dispatch({isLoading: true, data: []})
        try {
            setStats({videos: 0, images: 0, links: 0, documents: 0})
            const params = getParams()
            params.filter = {
                ...params.filter,
                ...customFilters
            }
            const {data, total, totalDocuments, totalImages, totalLinks, totalVideos} =
                await mediaLibraryService.getAll(params)
            const newData = data.map((item) => {
                item.id = item.itemId
                item.descriptionShort = item.description.substring(0, 50)
                item.typeCaps = item.type.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase())
                item.createdDateFormat = moment(item.createdAt).format("MM/DD/YYYY hh:mm A")
                if (item.type === "image") {
                    let imgSrc = !!item.details?.thumbnailUrl ? item.details?.thumbnailUrl : item.details?.url
                    item.thumbnailCard = (
                        <img
                            className={styles.mediaImage}
                            src={imgSrc}
                            alt=""
                            onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                        />
                    )
                } else {
                    item.thumbnailCard = (
                        <img
                            className={styles.mediaImage}
                            src={
                                !!item.details?.thumbnailUrl
                                    ? item.details?.thumbnailUrl
                                    : getPreviewUnavailableImage(model.clientSetting.slug)
                            }
                            alt=""
                            onError={(currentTarget) => onErrorImageLoad(model.clientSetting.slug, currentTarget)}
                        />
                    )
                }

                return item
            })

            setTotal(total)
            setStats({videos: totalVideos, images: totalImages, links: totalLinks, documents: totalDocuments})
            dispatch({data: newData, total})
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickEdit = (editedItem) => {
        history.push(`/media-library/detail?id=${editedItem.id}`)
    }

    const onClickDeleteConfirm = async (deletedItem) => {
        setDeletedItem(deletedItem)
        confirmPopup.open()
    }

    const onClickDelete = async () => {
        const {dispatch} = props
        try {
            dispatch({isLoading: true})
            const params = {itemIds: [deletedItem.itemId]}
            await mediaLibraryService.delete(params)
            setDeletedItem(null)
            confirmPopup.close()
            await getData()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFilterMemoryKey", func: getFilterMemoryKey},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDeleteConfirm},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, page, pageSize, search])

    useEffect(() => {
        getData()
    }, [viewType])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const updateFilter = (field: string, value: any) => {
        const newFilter = {...filterObject}
        newFilter[field] = value
        setFilterObject(newFilter)
    }

    const onChangeSearch = (searchValue) => {
        dispatch({page: 1})
        model.updateStorageFilter(FilterKey.MediaLibrary, {search: searchValue, page: 1})
        setSearch(searchValue)
    }

    const applyFilter = (tags?: Tag[]) => {
        dispatch({page: 1})
        let filter = {...filterObject}
        if (tags) {
            filter = {
                ...filter,
                tags
            }
        }
        model.updateStorageFilter(FilterKey.MediaLibrary, {filter, page: 1})
        getData(tags ? {tagIds: tags.map(({tagId}) => tagId)} : {})
    }

    const onClearFilter = () => {
        setFilterObject({...defaultFilter})
    }

    const togglePinnedItem = async (rowItem) => {
        const newData = data.map((item) => {
            if (item.id === rowItem.id) {
                item.isPinned = !item.isPinned
            }
            return item
        })
        dispatch({data: newData})
        try {
            const params = {itemId: rowItem.id}
            await mediaLibraryService.togglePinnedItem(params)
            getData()
        } catch (error) {
            handleError(error)
        }
    }

    const addFavorite = async (rowItem) => {
        const newData = data.map((item) => {
            if (item.id === rowItem.id) {
                item.isFavorite = true
            }
            return item
        })
        dispatch({data: newData})
        try {
            const params = {itemIds: [rowItem.id]}
            await mediaLibraryService.addFavorite(params)
        } catch (error) {
            handleError(error)
        }
    }

    const removeFavorite = async (rowItem) => {
        const newData = data.map((item) => {
            if (item.id === rowItem.id) {
                item.isFavorite = false
            }
            return item
        })
        dispatch({data: newData})
        try {
            const params = {itemIds: [rowItem.id]}
            await mediaLibraryService.removeFavorite(params)
        } catch (error) {
            handleError(error)
        }
    }

    const newMenuActions = [
        ...menuActions.filter(
            (action) =>
                model.isAdmin() ||
                (hasPermissionsToEdit && action.title === "Edit") ||
                (hasPermissionsToDelete && action.title === "Delete")
        ),
        {
            title: t("common:action.copyLink"),
            icon: "LINK",
            action: copyLink
        }
    ]

    const onClickFilterByCard = (itemType) => {
        updateFilter("itemType", itemType)
        dispatch({page: 1})
        model.updateStorageFilter(FilterKey.MediaLibrary, {
            filter: {
                ...filterObject,
                itemType: itemType
            },
            page: 1
        })
        const customFilters = {
            itemTypes: itemType ? [itemType.id] : []
        }
        getData(customFilters)
    }

    const handleTagsSelect = (val: Tag[]) => {
        updateFilter("tags", val)
        applyFilter(val)
    }

    return (
        <Screen
            htmlTitle={isPageFavorite ? t("settings:mediaLibrary.titleFavorite") : t("settings:mediaLibrary.title")}
            header={{
                title: isPageFavorite ? t("settings:mediaLibrary.titleFavorite") : t("settings:mediaLibrary.title")
            }}>
            <ConfirmPopupChildren
                isVisible={confirmPopup.isVisible}
                onClose={confirmPopup.close}
                onConfirm={onClickDelete}
                title={t("settings:mediaLibrary.confirmation")}
                isSubmitting={isLoading}>
                <div className={styles.confirmBody}>{t("settings:mediaLibrary.deleteConfirmMessage")}</div>
            </ConfirmPopupChildren>
            <Filter
                filterObject={filterObject}
                filterFormSearch={search}
                updateFilter={updateFilter}
                onClickFilter={applyFilter}
                onClearFilter={onClearFilter}
                onChangeSearch={onChangeSearch}
                viewType={viewType}
                setViewType={setViewType}
                isPageFavorite={isPageFavorite}
            />
            <Row>
                <div className={styles.headerCardContainer}>
                    <div
                        className={cx(styles.headerCard, styles.headerCardLeft, {
                            [styles.selectedHeaderStudentCard]: !filterObject?.itemType?.id
                        })}
                        onClick={() => onClickFilterByCard(null)}>
                        {t("settings:mediaLibrary.all")} (
                        <span
                            className={cx(styles.headerCardStatValue, {
                                [styles.selectedHeaderCardStatValue]: !filterObject?.itemType?.id
                            })}>
                            {total}
                        </span>
                        )
                    </div>
                    <div
                        className={cx(styles.headerCard, styles.headerCardRight, {
                            [styles.selectedHeaderStudentCard]: filterObject?.itemType?.id === "document"
                        })}
                        onClick={() =>
                            onClickFilterByCard(MEDIAL_LIBRARY_ITEM_TYPE_OPTIONS.find((el) => el.id === "document"))
                        }>
                        {t("settings:mediaLibrary.documents")} (
                        <span
                            className={cx(styles.headerCardStatValue, {
                                [styles.selectedHeaderCardStatValue]: filterObject?.itemType?.id === "document"
                            })}>
                            {stats.documents}
                        </span>
                        )
                    </div>
                    <div
                        className={cx(styles.headerCard, {
                            [styles.selectedHeaderStudentCard]: filterObject?.itemType?.id === "image"
                        })}
                        onClick={() =>
                            onClickFilterByCard(MEDIAL_LIBRARY_ITEM_TYPE_OPTIONS.find((el) => el.id === "image"))
                        }>
                        {t("settings:mediaLibrary.images")} (
                        <span
                            className={cx(styles.headerCardStatValue, {
                                [styles.selectedHeaderCardStatValue]: filterObject?.itemType?.id === "image"
                            })}>
                            {stats.images}
                        </span>
                        )
                    </div>
                    <div
                        className={cx(styles.headerCard, {
                            [styles.selectedHeaderStudentCard]: filterObject?.itemType?.id === "link"
                        })}
                        onClick={() =>
                            onClickFilterByCard(MEDIAL_LIBRARY_ITEM_TYPE_OPTIONS.find((el) => el.id === "link"))
                        }>
                        {t("settings:mediaLibrary.links")} (
                        <span
                            className={cx(styles.headerCardStatValue, {
                                [styles.selectedHeaderCardStatValue]: filterObject?.itemType?.id === "link"
                            })}>
                            {stats.links}
                        </span>
                        )
                    </div>
                    <div
                        className={cx(styles.headerCard, {
                            [styles.selectedHeaderStudentCard]: filterObject?.itemType?.id === "video"
                        })}
                        onClick={() =>
                            onClickFilterByCard(MEDIAL_LIBRARY_ITEM_TYPE_OPTIONS.find((el) => el.id === "video"))
                        }>
                        {t("settings:mediaLibrary.videos")} (
                        <span
                            className={cx(styles.headerCardStatValue, {
                                [styles.selectedHeaderCardStatValue]: filterObject?.itemType?.id === "video"
                            })}>
                            {stats.videos}
                        </span>
                        )
                    </div>
                </div>
            </Row>
            <TagSlider selectedTags={filterObject.tags} onSelectedTagsChange={handleTagsSelect} />
            {viewType === "blocks" && (
                <ViewBlocksMediaLibrary
                    removeFavorite={removeFavorite}
                    addFavorite={addFavorite}
                    togglePinnedItem={togglePinnedItem}
                    newMenuActions={newMenuActions}
                    hasPermissionsToTogglePin={hasPermissionsToTogglePin}
                    setSelectedItem={setSelectedItem}
                    handleMouseOver={handleMouseOver}
                    handleMouseOut={handleMouseOut}
                    onRefreshData={getData}
                    {...props}
                />
            )}
            {viewType === "list" && (
                <ViewListMediaLibrary newMenuActions={newMenuActions} setSelectedItem={setSelectedItem} {...props} />
            )}
            {htmlMediaFile && (
                <BasePopup
                    isShow={htmlMediaFile ? true : false}
                    onClose={onClosePopup}
                    isShowLeftSide={false}
                    children={htmlMediaFile}
                />
            )}
        </Screen>
    )
}

export default KlassappTableHOC(MediaLibrary)
