import React, {useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import {useEdularModulesContext, Modules} from "@edular/modules"
import ActivitiesTable from "../../../shared/student/ActivitiesTable/ActivitiesTable"
import styles from "./ActivitiesStaffTab.module.css"
import {BaseButton} from "components/buttons"
import {useCurrentProfilePermissions} from "hooks"
import {Permissions} from "types/permission"
import {Tabs} from "antd"
import {TabHeader} from "components/Tab"
import WorkflowTriggersTable from "sections/Workflow/WorkflowTriggersTable/WorkflowTriggersTable"

const {TabPane} = Tabs

enum Tab {
    Activities = "activities",
    WorkflowTriggers = "workflow-triggers"
}

export function ActivitiesStaffTab(props) {
    const {t} = useTranslation(["academics", "common"])
    const {isModuleEnable} = useEdularModulesContext()
    const [activeTabKey, onChangeTab] = useState<string>(Tab.Activities)
    const currentProfilePermissions = useCurrentProfilePermissions()
    const canShowWorkflowTriggers = useMemo(
        () =>
            currentProfilePermissions.hasPermissions({
                staff: [Permissions.Staff.Settings.General.Workflow.Add]
            }),
        [currentProfilePermissions]
    )

    return (
        <div>
            <div className={styles.headerLeftTitleWrap}>
                <p className={styles.title}>{t("registrar.academicsActivities").toUpperCase()}</p>
                <BaseButton title={t("common:action.openReport")} variant="secondary" onClick={() => {}} disabled />
            </div>
            {isModuleEnable(Modules.ActivitiesAndFollowups) && canShowWorkflowTriggers ? (
                <Tabs className="fullwidth klassSubTab" activeKey={activeTabKey} onChange={onChangeTab}>
                    <TabPane tab={<TabHeader title={t("common:activityPopup.activities")} />} key={Tab.Activities}>
                        {activeTabKey === Tab.Activities && <ActivitiesTable departmentId={props.departmentId} />}
                    </TabPane>
                    <TabPane
                        tab={<TabHeader title={t("common:activityPopup.workflowTriggers")} />}
                        key={Tab.WorkflowTriggers}>
                        {activeTabKey === Tab.WorkflowTriggers && (
                            <WorkflowTriggersTable departmentId={props.departmentId} />
                        )}
                    </TabPane>
                </Tabs>
            ) : (
                <ActivitiesTable departmentId={props.departmentId} />
            )}
        </div>
    )
}
