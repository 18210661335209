import {Auth} from "types/auth"
import {Timestamps} from "../common"
import {
    AutomatedWorkflow,
    WorkflowAction,
    AutomatedWorkflowActionType,
    WorkflowTarget,
    WorkflowTrigger,
    WorkflowTriggerElement
} from "./automated-workflow"
import {ProcessWorkflow} from "./process-workflow"
import {Edge, Node} from "reactflow"
import {INITIAL_FLOWCHART_NODES} from "components/FlowChart/FlowChart.constants"

export enum WorkflowType {
    Process = "process",
    Automated = "automated"
}

export type Workflow = ProcessWorkflow &
    AutomatedWorkflow &
    Timestamps & {
        readonly id: number
        readonly code: string
        readonly type: WorkflowType
        name: string
        description?: string | null
        isActive: boolean | 0 | 1
        template?: string | null
        departmentSubunitIds: number[]
        readonly lastEditorId: number
        readonly lastEditor: Auth.UserProfile
        readonly lastEditorProfileId: number
        readonly lastEditorProfile: Auth.UserProfile
    }

export type EditableWorkflow = Omit<Partial<Workflow>, "targets" | "triggers" | "actions"> & {
    targets: EditableWorkflowTarget[]
    triggers: EditableWorkflowTrigger[]
    actions: EditableWorkflowAction[]
    internalFlow?: {nodes: Node[]; edges: Edge[]}
}

export type EditableWorkflowTarget = Partial<WorkflowTarget>

export type EditableWorkflowTrigger = Partial<WorkflowTrigger>

export type EditableWorkflowAction = Partial<WorkflowAction>

export const NEW_WORKFLOW_TARGET_STUDENT: EditableWorkflowTarget = {
    type: Auth.UserProfileType.Student,
    startDate: null,
    campusIds: [],
    programIds: [],
    statusIds: [],
    studentStatusIds: [],
    groupIds: [],
    roleIds: [],
    usersFromTask: true,
    usersFromCalendarEvent: true
}

export const NEW_WORKFLOW_TARGET_STAFF: EditableWorkflowTarget = {
    type: Auth.UserProfileType.Staff,
    startDate: "",
    campusIds: [],
    departmentSubunitIds: [],
    advisorIds: [],
    advisorProfileIds: []
}

export const NEW_WORKFLOW_TRIGGER: EditableWorkflowTrigger = {
    element: WorkflowTriggerElement.StudentStatus,
    operator: "eq",
    value: ""
}

export const NEW_WORKFLOW_ACTION: EditableWorkflowAction = {
    type: AutomatedWorkflowActionType.Message,
    value: ""
}

const NEW_WORKFLOW: EditableWorkflow = {
    name: "",
    description: "",
    departmentSubunitIds: [],
    targets: [],
    triggers: [],
    actions: []
}

export const createNewEditableWorkflow = (type: WorkflowType): EditableWorkflow => {
    return {
        ...NEW_WORKFLOW,
        type,
        targets: type === WorkflowType.Automated ? [NEW_WORKFLOW_TARGET_STUDENT] : [],
        triggers: type === WorkflowType.Automated ? [NEW_WORKFLOW_TRIGGER] : [],
        internalFlow: type === WorkflowType.Process ? {nodes: INITIAL_FLOWCHART_NODES, edges: []} : undefined
    }
}

export const createEditableWorkflow = (workflow: Workflow): EditableWorkflow => {
    return {
        ...workflow,
        departmentSubunitIds: workflow.departmentSubunitIds,
        internalFlow: workflow.flow
            ? JSON.parse(workflow.flow)
            : workflow.type === WorkflowType.Process
            ? {nodes: INITIAL_FLOWCHART_NODES, edges: []}
            : undefined
    }
}

export const getFormattedWorkflowName = (workflow: Partial<Workflow>) =>
    workflow.code ? `${workflow.name} (${workflow.code})` : workflow.name
