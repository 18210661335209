import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {Col, Row} from "antd"
import {studentActivitiesService} from "services"
import styles from "./ActivityStats.module.css"
import cx from "classnames"

type Props = {
    departmentId?: number
    studentId?: number
    isVisible?: boolean
    advancedFilter: "activities" | "workflowActivities" | undefined
    setAdvancedFilter: (type: "activities" | "workflowActivities" | undefined) => void
}

function ActivityStats({departmentId, studentId, isVisible, advancedFilter, setAdvancedFilter}: Props) {
    const {t} = useTranslation(["activities", "common"])
    const [stats, setStats] = useState<Record<string, any>>({})

    useEffect(() => {
        ;(async function getActivityStats() {
            const {data} = await studentActivitiesService.getActivityStats({
                profileId: studentId,
                departmentIds: departmentId ? [departmentId] : undefined
            })
            setStats(data)
        })()
    }, [studentId, departmentId, isVisible])

    return (
        <div className={styles.statsContainer}>
            <Row gutter={20} align="stretch">
                <Col span={7}>
                    <div
                        className={cx(styles.statsContent, {[styles.canClickable]: stats.openActivities > 0})}
                        onClick={() => {
                            if (stats.openActivities > 0) {
                                setAdvancedFilter(advancedFilter === "activities" ? undefined : "activities")
                            }
                        }}>
                        <div className={styles.statsValue}>{stats.openActivities ?? 0}</div>
                        <div className={styles.statsLabel}>{t("activities.stats.openActivities")}</div>
                        {advancedFilter === "activities" && <div className={styles.isActivated}>(Active)</div>}
                    </div>
                </Col>
                <Col span={7}>
                    <div
                        className={cx(styles.statsContent, {
                            [styles.canClickable]: stats.openWorkflowActivities > 0
                        })}
                        onClick={() => {
                            if (stats.openWorkflowActivities > 0) {
                                setAdvancedFilter(
                                    advancedFilter === "workflowActivities" ? undefined : "workflowActivities"
                                )
                            }
                        }}>
                        <div className={styles.statsValue}>{stats.openWorkflowActivities ?? 0}</div>
                        <div className={styles.statsLabel}>{t("activities.stats.openWorkflow")}</div>
                        {advancedFilter === "workflowActivities" && <div className={styles.isActivated}>(Active)</div>}
                    </div>
                </Col>
                <Col span={10}>
                    <div className={cx(styles.statsContent, styles.statsGroupContainer)}>
                        <Row gutter={8} className={styles.statsGroupContent}>
                            <Col span={12}>
                                <div className={cx(styles.statsContent, styles.statsSmall)}>
                                    <div className={styles.statsValue}>{stats.overdueActivities ?? 0}</div>
                                    <div className={styles.statsLabel}>{t("activities.stats.activities")}</div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={cx(styles.statsContent, styles.statsSmall)}>
                                    <div className={styles.statsValue}>{stats.overdueWorkflowItems ?? 0}</div>
                                    <div className={styles.statsLabel}>{t("activities.stats.workflow")}</div>
                                </div>
                            </Col>
                        </Row>
                        <div className={cx(styles.statsLabel, styles.statsGroupContainerLabelGroup)}>
                            {t("activities.stats.overdueActivities")}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ActivityStats
