import React from "react"
import cx from "classnames"
import styles from "./StudentProgress.module.css"
import {formatDecimal} from "helpers"

type Props = {
    title: string
    totalTitle: string
    totalHours: any[]
    programVersionTotalClockHours: number
}

export function StudentProgressTermAndCourse(props: Props) {
    const {title, totalTitle, totalHours} = props
    const [scheduleHour, completedHour, attemptedHour, remainingHour, distEdHours, total] = totalHours
    const totalHourItem = total?.hour ?? 0
    const completedHourPercent = Math.min(Math.ceil((completedHour.hour / totalHourItem) * 100), 100)
    const attemptedHourPercent = Math.min(Math.ceil((attemptedHour.hour / totalHourItem) * 100), 100)
    const remainingHourPercent = Math.ceil(100 - completedHourPercent)
    const scheduleHourPercent = Math.min(Math.ceil((scheduleHour.hour / totalHourItem) * 100), 100)
    const distEdPercent = Math.min(Math.ceil((distEdHours.hour / totalHourItem) * 100), 100)

    const getMinPercent = (percent: number) => {
        return percent > 0 ? percent : 0
    }

    const getMaxPercent = (percent: number) => {
        return percent <= 100 ? percent : 100
    }

    return (
        <>
            <div className={styles.totalProgressWrap}>
                {totalHourItem > 0 && (
                    <>
                        <p className={styles.totalProgressTitle}>{title}</p>
                        <div className={styles.totalProgressBarWrap}>
                            <div className={styles.attemptedHourWrap} style={{left: `${attemptedHourPercent - 1}%`}}>
                                <div className={styles.attemptedHourPointer}></div>
                                <div
                                    className={styles.attemptedHourLine}
                                    style={{background: attemptedHour.color}}></div>
                            </div>
                            <div className={styles.attemptedHourWrap} style={{left: `${scheduleHourPercent - 1}%`}}>
                                <div className={styles.attemptedHourPointer}></div>
                                <div
                                    className={styles.attemptedHourLine}
                                    style={{background: scheduleHour.color}}></div>
                            </div>

                            {distEdPercent > 0 && (
                                <div
                                    className={styles.secondItem}
                                    style={{
                                        width: `${distEdPercent}%`,
                                        left: 0,
                                        zIndex: 2,
                                        background: distEdHours.color
                                    }}>
                                    <p className={styles.progressBarNumber}>{distEdHours.hour}</p>
                                </div>
                            )}

                            {completedHourPercent > 0 && (
                                <div
                                    className={styles.firstItem}
                                    style={{
                                        width: `${getMaxPercent(completedHourPercent)}%`,
                                        left: 0,
                                        zIndex: 1,
                                        background: completedHour.color
                                    }}>
                                    <p className={styles.progressBarNumber} style={{position: "absolute", right: 6}}>
                                        {completedHour.hour}
                                    </p>
                                </div>
                            )}
                            {remainingHourPercent > 0 && (
                                <div
                                    className={styles.thirdItem}
                                    style={{
                                        width: `${getMaxPercent(remainingHourPercent + 1)}%`,
                                        left: `${getMinPercent(completedHourPercent)}%`,
                                        background: remainingHour.color
                                    }}>
                                    <p className={styles.progressBarNumber}>{remainingHour.hour}</p>
                                </div>
                            )}
                            {/* {scheduleHourPercent > 0 && (
                                <div
                                    className={styles.thirdItem}
                                    style={{
                                        width: `${getMaxPercent(scheduleHourPercent + 1)}%`,
                                        left: `${getMinPercent(completedHourPercent)}%`,
                                        background: scheduleHour.color
                                    }}>
                                    <p className={styles.progressBarNumber}>{scheduleHour.hour}</p>
                                </div>
                            )} */}
                        </div>
                    </>
                )}
            </div>

            <div className={styles.hourItemWrap}>
                <div className={cx(styles.hourItem, styles.hourItemLeft)}>
                    <div className={styles.hourTitleWrap}>
                        <p className={styles.hourStatus} style={{background: completedHour.color}}></p>
                        <p className={styles.hourTitle}>{completedHour.title}:</p>
                    </div>
                    <p className={styles.hourDescription}>{formatDecimal(completedHour.hour)}</p>
                </div>
                <div className={styles.hourItem}>
                    <div className={styles.hourTitleWrap}>
                        <p className={styles.hourStatus} style={{background: scheduleHour.color}}></p>
                        <p className={styles.hourTitle}>{scheduleHour.title}:</p>
                    </div>
                    <p className={styles.hourDescription}>{formatDecimal(scheduleHour.hour)}</p>
                </div>
            </div>
            <div className={styles.hourItemWrap}>
                <div className={cx(styles.hourItem, styles.hourItemLeft)}>
                    <div className={styles.hourTitleWrap}>
                        <p className={styles.hourStatus} style={{background: distEdHours.color}}></p>
                        <p className={styles.hourTitle}>{distEdHours.title}:</p>
                    </div>
                    <p className={styles.hourDescription}>{formatDecimal(distEdHours.hour)}</p>
                </div>
                <div className={styles.hourItem}>
                    <div className={styles.hourTitleWrap}>
                        <p className={styles.hourStatus} style={{background: attemptedHour.color}}></p>
                        <p className={styles.hourTitle}>{attemptedHour.title}:</p>
                    </div>
                    <p className={styles.hourDescription}>{formatDecimal(attemptedHour.hour)}</p>
                </div>
            </div>
            <div className={styles.hourItemWrap}>
                <div className={cx(styles.hourItem, styles.hourItemLeft)}>
                    <div className={styles.hourTitleWrap}>
                        <p className={styles.hourTitle}>{totalTitle}:</p>
                    </div>
                    <p className={styles.hourDescription}>{formatDecimal(totalHourItem)}</p>
                </div>
                <div className={cx(styles.hourItem)}>
                    <div className={styles.hourTitleWrap}>
                        <p className={styles.hourStatus} style={{background: remainingHour.color}}></p>
                        <p className={styles.hourTitle}>{remainingHour.title}:</p>
                    </div>
                    <p className={styles.hourDescription}>{formatDecimal(remainingHour.hour)}</p>
                </div>
            </div>
        </>
    )
}
