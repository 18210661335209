/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useCallback} from "react"
import {BaseLoading} from "components"
import {useEdularModulesContext} from "@edular/modules"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {useTranslation} from "react-i18next"
import debounce from "debounce-promise"
import {Elements} from "@stripe/react-stripe-js"
import {Stripe} from "@stripe/stripe-js"
import {loadStripe} from "@stripe/stripe-js/pure"
import {Screen} from "components/ui"
import {BaseInput} from "components/inputs"
import {checkPermission, getFullName} from "helpers"
import {Icon} from "components/Icon"
import {smsService, paymentsService, userServiceV3} from "services"
import {CreditBox} from "components/Payments"
import {MODULES} from "data/constants"
import {Payment} from "./parts"
import {EditPayment} from "./parts/EditPayment"
import CampusSelect from "components/CampusSelect"
import DepartmentForChatSelect from "components/DepartmentForChatSelect"
import {KlassDropdown, KlassDropAsyncPaginate} from "components/Select"
import {BaseButton} from "components/buttons"
import {Permissions} from "types/permission"
import styles from "./SMS.module.css"
import {useModel} from "hooks"
import {Checkbox} from "antd"

type ColumnErrors = {
    type?: boolean
    areaCode?: boolean
}

const SMS = (props) => {
    const {
        filter,
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        onChangePage,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        isLoadedTableFuncs,
        onChangeFields,
        updateTableHeaderActions
    } = props
    const {t} = useTranslation(["settings"])
    const {getModule} = useEdularModulesContext()
    const [customer, setCustomer] = useState<any>()
    const [showPaymentForm, setSowPaymentForm] = useState<boolean>(false)
    const [loaded, setLoaded] = useState(false)
    const [balance, setBalance] = useState<{currentBalance; availableBalance}>()
    const [maxTotalNumbers, setMaxTotalNumbers] = useState<number>(0)
    const [countNumbers, setCountNumbers] = useState<number>(0)
    const [paymentIntent, setPaymentIntent] = useState<{secret; intentId}>()
    const [numbersToAdd, setNumbersToAdd] = useState<number>(0)
    const [phoneNumberForCancel, setPhoneNumberForCancel] = useState<any[]>([])
    const [errors, setErrors] = useState<{[key: number]: ColumnErrors}>({})
    const model = useModel()
    const canEdit = checkPermission({staff: [Permissions.Staff.Settings.General.Sms.Edit]}, model)

    const [stripePromise, setStripePromise] = useState<Promise<Stripe> | undefined>()
    useEffect(() => {
        setStripePromise(
            process.env.REACT_APP_STRIPE_SHAREABLE_KEY ? loadStripe(process.env.REACT_APP_STRIPE_SHAREABLE_KEY) : null
        )
    }, [process.env.REACT_APP_STRIPE_SHAREABLE_KEY])

    const getPageTitle = () => {
        return t("smsSettings.smsSettings")
    }

    const fieldNames = ["area", "areaCode", "phoneNumber", "isGlobal", "user", "campus", "department"]

    const emptyColumn = {
        type: undefined,
        isGlobal: undefined,
        areaCode: undefined,
        phoneNumber: undefined,
        campus: undefined,
        department: undefined,
        index: 0
    }

    const getFields = () => {
        return fieldNames.map((field) => t(`smsSettings.${field}`))
    }

    const loadOptions = async (search, loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize)

            const {data, total} = await userServiceV3.getAll({
                filter: {
                    type: ["staff"],
                    search
                },
                range: {
                    limit: pageSize,
                    offset: page
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const debounceUsers = debounce(loadOptions, 800)

    const areaOptions = [
        {
            id: "toll_free",
            name: "Toll Free"
        },
        {
            id: "local",
            name: "Local Area"
        }
    ]

    const handleAreaChanges = (index, option) => {
        const newData = [...data]
        newData[index].type = option.id
        dispatch({data: newData})
    }

    const handleAreaCodeChanges = (index, value) => {
        const newData = [...data]
        newData[index].areaCode = +value
        dispatch({data: newData})
    }

    const handleIsGlobal = (smsPhoneNumbersId: number, isChecked: boolean) => {
        const newData = [...data]

        for (let i = 0; i < newData.length; i++) {
            const item = newData[i]
            if (item.smsPhoneNumbersId === smsPhoneNumbersId) {
                item.isGlobal = isChecked
                if (isChecked) {
                    item.campuses = []
                    item.departments = []
                    item.user = null
                }
            }
        }
        dispatch({data: newData})
    }

    const handleUserChanges = (smsPhoneNumbersId, option) => {
        const newData = [...data]

        for (let i = 0; i < newData.length; i++) {
            const item = newData[i]
            if (item.smsPhoneNumbersId === smsPhoneNumbersId) {
                item.user = option ? option : null
                if (option) {
                    item.campuses = []
                    item.departments = []
                }
            }
        }
        dispatch({data: newData})
    }

    const handleCampusChanges = (smsPhoneNumbersId, option) => {
        const newData = [...data]

        for (let i = 0; i < newData.length; i++) {
            const item = newData[i]
            if (item.smsPhoneNumbersId === smsPhoneNumbersId) {
                item.campuses = option ? [option] : []
            }
        }
        dispatch({data: newData})
    }

    const handleDepartmentChanges = (smsPhoneNumbersId, option) => {
        const newData = [...data]

        for (let i = 0; i < newData.length; i++) {
            const item = newData[i]
            if (item.smsPhoneNumbersId === smsPhoneNumbersId) {
                item.departments = option ? [option] : []
            }
        }
        dispatch({data: newData})
    }

    const emptyRow = () => {
        return <div className={styles.disabled} />
    }

    const getColumns = () => {
        return [
            {
                title: t(`smsSettings.area`),
                field: "type",
                style: {maxWidth: "200px", padding: "0px"},
                sortable: true,
                render: (value, row) => {
                    if (row.smsPhoneNumbersId) {
                        return value
                    }
                    return (
                        <KlassDropdown
                            error={errors[row.index]?.type}
                            options={areaOptions}
                            className={styles.dropdown}
                            onChange={(option) => handleAreaChanges(row.index, option)}
                        />
                    )
                }
            },
            {
                title: t(`smsSettings.areaCode`),
                field: "areaCode",
                style: {maxWidth: "100px", width: "100px", padding: "0px"},
                sortable: true,
                render: (value, row) => {
                    if (row.type !== "local") {
                        return emptyRow()
                    } else if (row.smsPhoneNumbersId) {
                        return value
                    }
                    return (
                        <BaseInput
                            type="number"
                            max={3}
                            onChange={(value) => handleAreaCodeChanges(row.index, value)}
                            disabled={!canEdit}
                        />
                    )
                }
            },
            {
                title: t(`smsSettings.phoneNumber`),
                field: "phone",
                sortable: true,
                style: {padding: "0px"},
                render: (value, row) => {
                    return row.smsPhoneNumbersId ? value : emptyRow()
                }
            },
            {
                title: t(`smsSettings.isGlobal`),
                field: "isGlobal",
                sortable: true,
                style: {padding: "0px"},
                render: (value, row) => {
                    return row.smsPhoneNumbersId ? (
                        <div className={styles.global}>
                            <Checkbox
                                checked={Boolean(value)}
                                onChange={(event) =>
                                    handleIsGlobal(row.smsPhoneNumbersId, event.target.checked)
                                }></Checkbox>
                        </div>
                    ) : (
                        emptyRow()
                    )
                }
            },
            {
                title: t(`smsSettings.user`),
                field: "user",
                style: {maxWidth: "300px", width: "300px", padding: "0px"},
                sortable: true,
                render: (value, row) => {
                    if (!row.smsPhoneNumbersId || row.isGlobal) {
                        return emptyRow()
                    }

                    return (
                        <KlassDropAsyncPaginate
                            defaultValue={value}
                            className={styles.dropdown}
                            valueKey="id"
                            onChange={(options) => handleUserChanges(row.smsPhoneNumbersId, options)}
                            getOptionLabel={(option: any) => getFullName(option)}
                            loadOptions={debounceUsers}
                            isClearable={canEdit}
                            placeholder={t("departments:select")}
                            readOnly={!canEdit}
                        />
                    )
                }
            },
            {
                title: t(`smsSettings.campus`),
                field: "campuses",
                style: {maxWidth: "300px", width: "300px", padding: "0px"},
                sortable: true,
                render: (value, row) => {
                    if ((!row.smsPhoneNumbersId || row.user) && !row.isGlobal) {
                        return emptyRow()
                    }
                    return (
                        <CampusSelect
                            className={styles.dropdown}
                            value={value}
                            isClearable={canEdit}
                            onChange={(options) => handleCampusChanges(row.smsPhoneNumbersId, options)}
                            readOnly={!canEdit}
                        />
                    )
                }
            },
            {
                title: t(`smsSettings.department`),
                field: "departments",
                style: {maxWidth: "300px", width: "300px", padding: "0px"},
                sortable: true,
                render: (value, row) => {
                    if (!row.smsPhoneNumbersId || row.user || row.isGlobal) {
                        return emptyRow()
                    }
                    return (
                        <DepartmentForChatSelect
                            className={styles.dropdown}
                            defaultValue={value}
                            value={value}
                            isClearable={canEdit}
                            onChange={(options) => handleDepartmentChanges(row.smsPhoneNumbersId, options)}
                            readOnly={!canEdit}
                        />
                    )
                }
            }
        ]
    }

    const getParams = useCallback(() => {
        const params = {
            range: {
                limit: pageSize,
                offset: (page - 1) * pageSize
            }
        }
        return params
    }, [page, pageSize])

    const getCustomer = async () => {
        try {
            const customer = await paymentsService.getCustomer()
            setCustomer(customer)

            if (customer && customer.stripePaymentMethodId !== null) {
                setLoaded(true)
            }
        } catch (error) {
            console.log("@@@ getPaymentIntent error", error)
        }
    }

    const getBalance = async () => {
        try {
            const {currentBalanceInCents} = await paymentsService.getBalance()

            const currentBalance = currentBalanceInCents / 100
            setBalance({
                currentBalance,
                availableBalance: currentBalance
            })
        } catch (error) {
            console.log("@@@ getPaymentIntent error", error)
        }
    }

    const getPaymentIntent = async () => {
        try {
            const intent = await paymentsService.createIntent({
                amountInCents: 100, // 1 usd
                paymentMethodTypes: ["card"],
                description: "Initial subscription payment"
            })
            setPaymentIntent(intent)
            setLoaded(true)
        } catch (error) {
            console.log("@@@ getPaymentIntent error", error)
        }
    }

    const getSmsNumbers = useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const {data, total} = await smsService.getNumbers(getParams())
            setCountNumbers(total)

            setPhoneNumberForCancel(JSON.parse(JSON.stringify(data)))
            dispatch({
                data,
                total
            })
        } catch (error) {
            console.log("@@@ getSmsNumbers error", error)
        } finally {
            dispatch({isLoading: false})
        }
    }, [dispatch, getParams])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getColumns", func: getColumns},
            {key: "getListData", func: getSmsNumbers},
            {key: "getFields", func: getFields}
        ])
        getBalance()
        getCustomer()
    }, [])

    useEffect(() => {
        if (showPaymentForm && (customer === null || (customer && customer.stripePaymentMethodId === null))) {
            getPaymentIntent()
        }
    }, [customer, showPaymentForm])

    useEffect(() => {
        const module = getModule(MODULES.SMS)
        const setting = module?.settings?.find((setting) => setting.code === "total_numbers")
        setMaxTotalNumbers(setting ? +setting.value : 0)
    }, [getModule])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getSmsNumbers()
        }
    }, [isLoadedTableFuncs, page, pageSize, filter, orderField?.field, orderField?.order, getSmsNumbers])

    useEffect(() => {
        updateTableHeaderActions()
    }, [data, updateTableHeaderActions])

    const addNumbers = () => {
        const newData = [...data]

        for (let i = 0; i < numbersToAdd; i++) {
            const newEmptyColumn = {
                ...emptyColumn,
                index: data.length + i
            }
            newData.push(newEmptyColumn)
        }

        dispatch({data: newData, total: newData.length})
        setCountNumbers(+numbersToAdd + +countNumbers)
        setNumbersToAdd(0)
    }

    const handleAddNumbersCange = (value) => {
        const max = maxTotalNumbers - countNumbers
        if (value < 0) {
            setNumbersToAdd(0)
        } else if (value > max) {
            setNumbersToAdd(max)
        } else {
            setNumbersToAdd(value)
        }
    }

    const validateNumbers = () => {
        const newErrors = {}
        for (let i = 0; i < data.length; i++) {
            const item = data[i]
            const errorsInField: ColumnErrors = {}
            if (!item.id) {
                if (!item.type) {
                    errorsInField.type = true
                }
                if (item.type === "local" && !item[`areaCode`]) {
                    errorsInField[`areaCode`] = true
                }
                if (Object.keys(errorsInField).length > 0) {
                    newErrors[item.index] = errorsInField
                }
            }
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const generatePhoneNumbers = async (newNumbers) => {
        const payload = newNumbers.map(({index, ...rest}) => {
            return {...rest}
        })
        await smsService.assignNumbers(payload)
    }

    const updatePhoneNumbers = async (numbersToUpdate) => {
        const payload = numbersToUpdate.map(({smsPhoneNumbersId, departments, campuses, user, isGlobal}) => {
            const departmentIds = departments ? departments.map(({departmentId}) => departmentId) : []
            const campusIds = campuses ? campuses.map(({id}) => id) : []
            return {smsPhoneNumbersId, departmentIds, campusIds, isGlobal: Boolean(isGlobal), userId: user?.id}
        })
        await smsService.editNumbers(payload)
    }

    const cancelPhoneNumbers = async () => {
        console.log({phoneNumberForCancel})
        dispatch({data: []}, () => {
            dispatch({data: JSON.parse(JSON.stringify(phoneNumberForCancel))})
        })
    }

    const savePhoneNumbers = async () => {
        const valid = validateNumbers()
        if (!valid) {
            return
        }
        const newNumbers = data.filter((data) => data.index !== undefined)
        const numbersToUpdate = data.filter((data) => data.smsPhoneNumbersId !== undefined)
        if (newNumbers.length) {
            await generatePhoneNumbers(newNumbers)
        }
        if (numbersToUpdate.length) {
            await updatePhoneNumbers(numbersToUpdate)
        }
        await getSmsNumbers()
    }

    return (
        <Screen
            htmlTitle={t("smsSettings.smsSettings")}
            className={styles.app}
            header={{
                title: t("smsSettings.smsSettings")
            }}>
            <div style={{maxWidth: "406px"}}>
                <div>
                    {t("smsSettings.numberOfLines")} {countNumbers} / {maxTotalNumbers}
                </div>
                <div style={{display: "flex", marginTop: "10px", alignItems: "center"}}>
                    <BaseInput
                        value={numbersToAdd || ""}
                        placeholder={t("smsSettings.numberOfLines")}
                        onChange={handleAddNumbersCange}
                        type="number"
                        max={maxTotalNumbers - countNumbers}
                        min={0}
                        style={{width: "236px", height: "44px"}}
                        disabled={!canEdit}
                    />
                    {canEdit && (
                        <BaseButton
                            style={{minWidth: "128px", marginLeft: "42px"}}
                            className={styles.button}
                            title={t("smsSettings.addNumbersButton").toUpperCase()}
                            onClick={addNumbers}
                            loading={false}
                        />
                    )}
                </div>
            </div>
            <div className={styles.line} />
            <div className={styles.subtitle}>{t(`smsSettings.smsPhoneNumbers`)}</div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
            />
            <KlassappTable
                columns={getColumns()}
                menuActions={[]}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn={false}
                onClickRowItem={() => {}}
                onChangeFields={onChangeFields}
                className={styles.table}
            />
            <div className={styles.actions}>
                <div className={styles.notice}>
                    <Icon icon="WHITE_INFO" color="#FF349B"></Icon> {t(`smsSettings.notice`)}
                </div>
                {canEdit && (
                    <div className={styles.actionBtns}>
                        <BaseButton
                            className={styles.button}
                            variant="secondary"
                            title={t(`smsSettings.cancel`)}
                            onClick={cancelPhoneNumbers}
                            loading={false}
                        />
                        <BaseButton
                            className={styles.button}
                            title={t(`smsSettings.save`)}
                            onClick={savePhoneNumbers}
                            loading={false}
                        />
                    </div>
                )}
            </div>
            <div className={styles.line} />
            <div className={styles.subtitle}>{t(`smsSettings.paymentAndBudget`)}</div>
            <div style={{display: "flex"}}>
                <CreditBox title="AVAILABLE CREDIT" amount={balance?.currentBalance || "0.00"} />
                <BaseButton
                    style={{minWidth: "128px", marginLeft: "42px", alignSelf: "center"}}
                    className={styles.button}
                    title={!showPaymentForm ? t(`smsSettings.openPaymentForm`) : t(`smsSettings.closePaymentForm`)}
                    loading={false}
                    onClick={() => setSowPaymentForm(!showPaymentForm)}
                />
            </div>
            {showPaymentForm && (
                <>
                    <div className={styles.subtitle}>{t(`smsSettings.paymentOptions`)}</div>
                    <div style={{display: "flex"}}>
                        {!loaded && <BaseLoading isShow={isLoading} />}

                        {loaded && paymentIntent && paymentIntent.secret ? (
                            <Elements
                                stripe={stripePromise}
                                options={{
                                    clientSecret: paymentIntent.secret,
                                    appearance: {
                                        theme: "none",
                                        rules: {
                                            ".Label": {
                                                marginBottom: "8px",
                                                marginTop: "12px",
                                                color: "#666666"
                                            },
                                            ".Input": {
                                                boxShadow: "0 2px 12px 0 rgba(48, 49, 51, 0.1)",
                                                border: "solid 0 #000"
                                            }
                                        }
                                    }
                                }}>
                                <Payment intentId={paymentIntent.intentId} />
                            </Elements>
                        ) : (
                            loaded && (
                                <Elements stripe={stripePromise}>
                                    <EditPayment customer={customer} onSubmit={getCustomer} />
                                </Elements>
                            )
                        )}
                    </div>
                </>
            )}
        </Screen>
    )
}

export default KlassappTableHOC(SMS)
