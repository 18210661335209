/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useHistory} from "react-router-dom"
import cx from "classnames"
import {Menu, Dropdown} from "antd"
import {Icon} from "components/Icon"
import {Auth} from "types/auth"
import {checkPermission} from "helpers"
import {useModel} from "hooks"
import {Permissions} from "types/permission"
import styles from "./ModuleNavigation.module.css"
import {Modules, useEdularModulesContext} from "@edular/modules"

type Props = {
    activeModule: Auth.ModuleNavigation
    userId: number
    profileId: number
}

export function ModuleNavigation(props: Props) {
    const {activeModule, userId, profileId} = props
    const history = useHistory()
    const model = useModel()
    const [modules, setModules] = useState([{id: Auth.ModuleNavigation.Users, name: "Users"}])
    const {isModuleEnable} = useEdularModulesContext()

    useEffect(() => {
        getModules()
    }, [])

    const getModules = () => {
        const canShowAdmission =
            isModuleEnable(Modules.Admissions) &&
            checkPermission({staff: [Permissions.Staff.Admissions.Students.AdmissionsStudentDetails.View]}, model)
        const canShowAcademics =
            isModuleEnable(Modules.Academic) &&
            checkPermission(
                {staff: [Permissions.Staff.Academics.Instructional.Students.AcademicsStudentDetails.View]},
                model
            )
        const canShowFinAid =
            isModuleEnable(Modules.FinancialAid) &&
            checkPermission({staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.View]}, model)
        const canShowAcademicAffairs =
            isModuleEnable(Modules.AcademicAffair) &&
            checkPermission(
                {staff: [Permissions.Staff.AcademicAffairs.StudentServices.Students.StudentDetail.View]},
                model
            )
        const canShowStudentServices =
            isModuleEnable(Modules.StudentServices) &&
            checkPermission(
                {
                    staff: [
                        Permissions.Staff.StudentServicesAndAcademicAffairs.StudentServices.Students.StudentDetail.View
                    ]
                },
                model
            )
        const canShowCareerService =
            isModuleEnable(Modules.CareerServices) &&
            checkPermission({staff: [Permissions.Staff.CareerServices.View]}, model)
        const canShowStudentAccount =
            isModuleEnable(Modules.StudentAccount) &&
            checkPermission({staff: [Permissions.Staff.StudentAccount.Students.StudentLedger.View]}, model)
        const newModules = [
            {id: Auth.ModuleNavigation.Users, name: "Users"},
            canShowAdmission && {id: Auth.ModuleNavigation.Admissions, name: "Admissions"},
            canShowAcademics && {id: Auth.ModuleNavigation.Academics, name: "Academics"},
            canShowFinAid && {id: Auth.ModuleNavigation.FinancialAid, name: "Financial Aid"},
            canShowAcademicAffairs && {id: Auth.ModuleNavigation.AcademicAffairs, name: "Academic Affairs"},
            canShowStudentServices && {id: Auth.ModuleNavigation.StudentServices, name: "Student Services"},
            canShowCareerService && {id: Auth.ModuleNavigation.CareerServices, name: "Career Services"},
            canShowStudentAccount && {id: Auth.ModuleNavigation.StudentAccount, name: "Student Account"}
        ].filter((item) => item)
        setModules(newModules)
    }

    const onClick = ({key}) => {
        if (key === activeModule || (key === Auth.ModuleNavigation.Users && !userId) || !profileId) {
            return
        }
        switch (key) {
            case Auth.ModuleNavigation.Users: {
                history.push(`/users/${userId}`)
                break
            }
            case Auth.ModuleNavigation.Admissions: {
                history.push(`/admissions/student/${profileId}`)
                break
            }
            case Auth.ModuleNavigation.Academics: {
                history.push(`/academics/registrar/student/${profileId}`)
                break
            }
            case Auth.ModuleNavigation.FinancialAid: {
                history.push(`/financial-aid/student/${profileId}`)
                break
            }
            case Auth.ModuleNavigation.AcademicAffairs: {
                history.push(`/academic-affairs/student/${profileId}`)
                break
            }
            case Auth.ModuleNavigation.StudentServices: {
                history.push(`/student-services/student/${profileId}`)
                break
            }
            case Auth.ModuleNavigation.CareerServices: {
                history.push(`/career-services/student/${profileId}`)
                break
            }
            case Auth.ModuleNavigation.StudentAccount: {
                history.push(`/student-account/student/${profileId}`)
                break
            }
            default:
                break
        }
    }

    const renderMenu = () => {
        return (
            <Menu onClick={onClick}>
                {modules.map((moduleItem) => (
                    <Menu.Item
                        key={moduleItem.id}
                        className={cx(styles.menuItem, {[styles.menuItem__active]: moduleItem.id === activeModule})}>
                        <span className={styles.menuItem__text}>{moduleItem.name}</span>
                    </Menu.Item>
                ))}
            </Menu>
        )
    }

    const renderActiveModule = () => {
        switch (activeModule) {
            case Auth.ModuleNavigation.Users:
                return "Users"
            case Auth.ModuleNavigation.Admissions:
                return "Admissions"
            case Auth.ModuleNavigation.Academics:
                return "Academics"
            case Auth.ModuleNavigation.FinancialAid:
                return "Financial Aid"
            case Auth.ModuleNavigation.AcademicAffairs:
                return "Academic Affairs"
            case Auth.ModuleNavigation.StudentServices:
                return "Student Services"
            case Auth.ModuleNavigation.CareerServices:
                return "Career Services"
            case Auth.ModuleNavigation.StudentAccount:
                return "Student Account"
            default:
                return ""
        }
    }

    const isStaff = model.isStaffOrAdmin()
    if (!isStaff) {
        return null
    }

    return (
        <div className={styles.wrap}>
            <Dropdown overlay={renderMenu} trigger={["click"]}>
                <div className={styles.moduleInfo}>
                    <span className={styles.moduleInfo__title}>Module</span>
                    <div className={styles.moduleBody}>
                        <span className={styles.moduleBody__value}>{renderActiveModule()}</span>
                        <div className={styles.iconWrap}>
                            <Icon icon="ARROW_DOWN" className={styles.arrowDownIcon} color="#212121" />
                        </div>
                    </div>
                </div>
            </Dropdown>
        </div>
    )
}
