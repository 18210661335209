/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect} from "react"
import {chatServiceV2, smsChatService} from "services"
import {useModel} from "./useModel"
import ChatWsV2 from "sections/NewCommunication/chatWsV2"
import {v4 as uuid} from "uuid"
import useNotification from "./useNotification"
import {Communication} from "types/communication"
import {useQuery} from "@tanstack/react-query"
import {handleError} from "helpers"

export default function useChatV2() {
    const model = useModel()
    const {newMessageNotificationV2} = useNotification()

    const getUUId = () => {
        return uuid()
    }

    useQuery(["notification-summary"], () => chatServiceV2.summaryNotifications(), {
        onSuccess: (resp) => {
            const {data} = resp
            let unread = 0
            data.forEach((item) => {
                unread += item.unread || 0
            })
            model.updateChatOptions(Communication.ChatOptions.UnreadNotification, unread)
        },
        onError: (err) => {
            handleError(err)
        }
    })

    useQuery(["chat-total-unread"], () => chatServiceV2.getTotalUnread(), {
        onSuccess: (resp) => {
            const {data} = resp
            model.updateChatOptions(Communication.ChatOptions.UnreadCount, data.total)
        },
        onError: (err) => {
            handleError(err)
        }
    })

    useQuery(["chat-settings"], () => chatServiceV2.getSettings(), {
        onSuccess: (resp) => {
            const {data} = resp
            model.updateChatOptions(Communication.ChatOptions.Settings, data)
        },
        onError: (err) => {
            // handleError(err)
        },
        enabled: model.isStaffOrAdmin()
    })

    useQuery(["direct-sms-unread"], () => smsChatService.getUnreadCount(), {
        onSuccess: (resp) => {
            const {unreadCount} = resp
            model.updateChatOptions(Communication.ChatOptions.UnreadSms, unreadCount)
        },
        onError: (err) => {
            handleError(err)
        },
        enabled: model.isStaffOrAdmin()
    })

    useEffect(() => {
        const unsubscribe = ChatWsV2.initialize({
            token: model.authToken,
            deviceId: getUUId()
        })

        // just request permission when user is logged in
        if ("Notification" in window) {
            Notification.requestPermission()
        }

        return () => {
            unsubscribe()
        }
    }, [])

    const handlerTotalUnread = useCallback(
        (data) => {
            model.updateChatOptions(Communication.ChatOptions.UnreadCount, data.total)
        },
        [model]
    )

    useEffect(() => {
        const unsubscribeTotalUnread = ChatWsV2.registerOnTotalUnread((data) => {
            handlerTotalUnread(data)
        })

        return () => {
            unsubscribeTotalUnread()
        }
    }, [handlerTotalUnread])

    const handlerNewMessage = useCallback(
        (message) => {
            if (message.author?.userId !== model.user?.id) {
                model.updateChatOptions(Communication.ChatOptions.UnreadCount, model.chatOptions.unreadCount + 1)
                newMessageNotificationV2(message)
            }
        },
        [model]
    )

    useEffect(() => {
        const unsubscribeNewMessages = ChatWsV2.registerOnSidebarNewMessages((message) => {
            handlerNewMessage(message)
        })

        return () => {
            unsubscribeNewMessages()
        }
    }, [handlerNewMessage])

    const handlerNewNotification = useCallback(
        (data) => {
            model.updateChatOptions(
                Communication.ChatOptions.UnreadNotification,
                model.chatOptions.unreadNotification + 1
            )
        },
        [model]
    )

    useEffect(() => {
        const unsubscribeNewNotification = ChatWsV2.registerOnSidebarNewNotification((data) => {
            handlerNewNotification(data)
        })

        return () => {
            unsubscribeNewNotification()
        }
    }, [handlerNewNotification])
}
