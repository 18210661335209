import {get} from "lodash"
import {
    DailyAttendanceByPhaseItem,
    DailyAttendanceByProgramVersionItem,
    DailyAttendanceViewType,
    LastDayOfAttendancePayload,
    UpdateOrCreateAttendancePayload
} from "types/attendance"
import BaseService from "./Base"
import {getBlobFromBase64String} from "helpers"

type GetLessonsAttendanceSummaryPayload = {
    courses?: number[]
    termIds?: number[]
    onlyLastLesson?: boolean
    scheduleId?: number
    onlyActionNeeded?: boolean
    studentProfileIds?: number[]
}

class AttendanceService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_ATTENDANCE_API_BASE_URL)
    }

    uploadScreenShots = async (base64: string) => {
        const formData = new FormData()
        const file = await getBlobFromBase64String(base64)
        formData.append("image", file, "image.jpeg")
        const response = await this.post(`/users/upload-captured-image`, formData)
        return get(response, "data", {})
    }

    getAttendanceSummary = async (payload: {
        courseId?: number
        studentProfileIds?: number[]
        termIds?: number[]
        scheduleId?: number
    }) => {
        const res = await this.post("/attendance/summary-percentage-by-course", payload)

        return res.data
    }
    getProgressAttendance = async (payload: {
        courseId?: number
        studentProfileIds?: number[]
        termIds?: number[]
        scheduleId?: number
    }) => {
        const res = await this.post("/attendance/summary-progress-by-course", payload)

        return res.data
    }

    getCourseStatistics = async (payload: {
        courseId?: number
        studentProfileIds?: number[]
        termIds?: number[]
        scheduleId?: number
    }) => {
        const res = await this.post("/attendance/course-statistics", payload)

        return res.data
    }

    getLessonsAttendance = async (payload?: GetLessonsAttendanceSummaryPayload) => {
        const res = await this.post("/attendance/lessons-attendance", payload)

        return res.data
    }

    getCourseStudentsStats = async (
        courseId: number,
        termIds: number[],
        studentProfileIds?: number[],
        lessonIds?: number[],
        scheduleId?: number
    ) => {
        const res = await this.post("/attendance/students-stats", {
            courseId,
            termIds,
            studentProfileIds,
            lessonIds,
            scheduleId
        })
        return res.data
    }

    getStudentsAttendanceByCourse = async (courseId?: number) => {
        const res = await this.post("/attendance/by-course", {courseId})

        return res.data
    }

    getAttendanceReport = async (payload: {
        courseIds?: number[]
        termIds?: number[]
        studentProfileIds?: number[]
        range?: {
            page: number
            pageSize: number
        }
    }) => {
        const res = await this.post("/attendance/attendance-report", payload)
        return get(res, "data.data", {})
    }

    getHistoryStudentsAttendanceByCourse = async ({
        studentProfileIds,
        courses,
        scheduleId,
        termIds,
        range,
        checkInvite
    }: {
        range?: {
            page: number
            pageSize: number
        }
        scheduleId?: number
        studentProfileIds: number[]
        courses: number[]
        termIds: number[]
        checkInvite: boolean
    }) => {
        const res = await this.post("/attendance/lessons-attendance", {
            checkInvite,
            scheduleId,
            studentProfileIds,
            courses,
            termIds,
            range
        })
        return get(res, "data.data", {})
    }

    getDetailHistoryStudentsAttendanceByCourse = async ({
        studentProfileIds,
        courseIds,
        termIds,
        attendanceIds
    }: {
        attendanceIds: number[]
        studentProfileIds: number[]
        courseIds: number[]
        termIds: number[]
    }) => {
        const res = await this.post("/attendance/history/get", {studentProfileIds, courseIds, termIds, attendanceIds})
        return get(res, "data.data", {})
    }

    getLessonActivity = async ({
        lessonId,
        courseId,
        termIds = [],
        calendarEventId,
        scheduleId
    }: {
        lessonId: number
        courseId: number
        scheduleId?: number
        termIds?: number[]
        calendarEventId?: number
    }) => {
        const res = await this.post("/attendance/lesson-activity", {
            lessonId,
            courseId,
            scheduleId,
            calendarEventId,
            termIds,
            range: {
                page: 1,
                pageSize: 500
            }
        })

        return res.data
    }

    updateOrCreateAttendance = async (payload: UpdateOrCreateAttendancePayload[]) =>
        this.post("/attendance/update-or-create", payload).then((res) => res.data)

    getLastDayOfAttendance = async (payload: LastDayOfAttendancePayload) => {
        const res = await this.post("/attendance/last-days-of-attendance-for-students", payload)
        return res.data?.data ?? []
    }

    getAllLessons = async (filters = {}, page, pageSize) => {
        const response = await this.post("/attendance-reports/get-lessons", {...filters, page, pageSize})
        return {total: response.data?.total || 0, data: response.data?.data || []}
    }

    getLesson = async (lessonId: number) => {
        const response = await this.post("/attendance-reports/get-lesson-report", {lessonId})
        return response.data?.data
    }

    getLessonInstructors = async () => {
        const response = await this.post("/attendance-reports/get-filters-options", {})
        return response.data?.data?.instructors || []
    }

    getAttendanceChanges = async (filter = {}, range, sort) => {
        const response = await this.post(
            `${process.env.REACT_APP_EDULAR_CORE_API_BASE_URL}/v3/reports/attendance-changes/list`,
            {
                filter,
                range,
                sort
            }
        )
        return {total: response.data?.data?.total || 0, data: response.data?.data?.data || []}
    }

    getRegistrarLessonStats = async (data) => {
        const response = await this.post("/registrar/lessons/stats", data)
        return get(response, "data.data")
    }

    getRegistrarLessonList = async (params) => {
        const response = await this.post("/registrar/lessons/list", params)
        return get(response, "data")
    }

    getClassroomAttendanceStats = async (data) => {
        const response = await this.post("/registrar/attendance/classroom/stats", data)
        return get(response, "data.data")
    }

    getDailyAttendanceStats = async (data) => {
        const response = await this.post("/registrar/attendance/daily/stats", data)
        return get(response, "data.data")
    }

    getClassroomAttendanceList = async (params) => {
        const response = await this.post("/registrar/attendance/classroom/list", params)
        return get(response, "data")
    }

    getDailyAttendanceList = async (params) => {
        const response = await this.post("/attendance/daily-attendance/list", params)
        return get(response, "data")
    }

    getDailyAttendanceListByDay = async (params) => {
        const response = await this.post("/attendance/daily-attendance/by-day", params)
        return get(response, "data")
    }

    getMissedAttendanceStats = async (data) => {
        const response = await this.post("/registrar/attendance/missed/stats", data)
        return get(response, "data.data")
    }

    getMissedDailyAttendanceStats = async (data) => {
        const response = await this.post("/registrar/daily-attendance/missed/stats", data)
        return get(response, "data.data")
    }

    getMissedAttendanceList = async (params) => {
        const response = await this.post("/registrar/attendance/missed/list", params)
        return get(response, "data")
    }
    getMissedDailyAttendanceList = async (params) => {
        const response = await this.post("/registrar/daily-attendance/missed/list", params)
        return get(response, "data")
    }

    getLessonDetail = async (params) => {
        const response = await this.post("/lessons/by-id", params)
        return get(response, "data")
    }

    deleteLessonByIds = async (params: {lessonIds: number[]}) => {
        const response = await this.post("/lessons/delete", params)
        return get(response, "data")
    }

    getCurrentLesson = async () => {
        const response = await this.post("/lessons/active/my", {})
        return get(response, "data.data", [])
    }

    checkAttendanceManually = async (params) => {
        const response = await this.post("/attendance/create-event-manually", params)
        return get(response, "data")
    }

    updateAttendance = async (params) => {
        const response = await this.post("/attendance/update-or-create", params)
        return get(response, "data")
    }

    verifyActivities = async (params) => {
        const response = await this.post("/attendance/activity/verify", params)
        return get(response, "data")
    }

    updateLesson = async (params) => {
        const response = await this.post("/lessons/update", params)
        return get(response, "data")
    }

    updateDailyAttendance = async (params) => {
        const response = await this.post("/attendance/daily-attendance/update", params)
        return get(response, "data")
    }

    createDailyAttendance = async (params) => {
        const response = await this.post("/attendance/daily-attendance/create", params)
        return get(response, "data")
    }

    updateLessonStatus = async (params) => {
        const response = await this.post("/lessons/update-status", params)
        return get(response, "data")
    }

    endLesson = async (params) => {
        const response = await this.post("/attendance/checkout-lesson-students", params)
        return get(response, "data")
    }

    checkOnlineAttendanceStatus = async (params) => {
        const response = await this.post("/attendance/check-online", params)
        return get(response, "data")
    }

    getPersonalBeacon = async () => {
        const response = await this.get("/beacons/personal", {})
        return get(response, "data.data", {})
    }

    getAllBeacons = async () => {
        const response = await this.post("/beacons/list", {})
        return get(response, "data.data", {})
    }

    getMyCourse = async (body) => {
        const response = await this.post("/instructional/courses/list", body)
        return get(response, "data.data", {})
    }

    getDailyAttendanceStatsNew = async (payload: {
        startDate?: string
        endDate?: string
        viewType: DailyAttendanceViewType
    }): Promise<DailyAttendanceByPhaseItem[] | DailyAttendanceByProgramVersionItem[]> => {
        const response = await this.post("/attendance/daily-attendance/stats", payload)
        return get(response, "data.data")
    }

    getLeaderboard = async (params = {}) => {
        const res = await this.post("/users/attendance-stats", params)
        return get(res, "data.data", {})
    }

    createLesson = async (params) => {
        const response = await this.post("/lessons/add", params)
        return get(response, "data")
    }

    editLesson = async (params) => {
        const response = await this.post("/lessons/edit", params)
        return get(response, "data")
    }

    getLessonByCalendarEventId = async (params) => {
        const response = await this.post("/lessons/by-calendar-event-id", params)
        return get(response, "data")
    }

    getAttendanceList = async (params) => {
        const response = await this.post("/attendance/list", params)
        return get(response, "data")
    }

    updateExternshipDailyAttendance = async (params) => {
        const response = await this.post("/attendance/update-attendance-events", params)
        return get(response, "data")
    }
}

export default AttendanceService
