/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {Col, Row} from "antd"
import {useTranslation} from "react-i18next"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {Icon} from "components/Icon"
import {attendanceService} from "services"
import {convertTZ, formatDate, formatDateOnly, handleError, roundTwoDigits} from "helpers"
import styles from "./AttendanceOverviewExternshipTab.module.css"
import {useModel, useVisible} from "hooks"
import {AttendanceEventType, AttendanceStatus} from "types/attendance"
import moment from "moment"

import {useDebounce} from "react-use"
import {useFilterStorage} from "hooks/useFilterStorage"
import {FilterKey} from "types/filter"
import {YesNo} from "types/common"
import {exportCsv, exportExcel} from "helpers/export-table"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {ExportButton, ExportFileType} from "components/ui"
import {BaseRangePicker} from "components/DateTimePicker"
import {DailyAttendancePopup} from "sections/Attendance/parts/DailyAttendancePopup"

export enum FilterEventType {
    Active = "active",
    InSchool = "inSchool",
    Break = "break",
    Checkout = "checkOut",
    Missed = "missed"
}

const DEFAULT_FILTER = {
    dateRange: []
}

function AttendanceOverviewExternship(props) {
    const {termId, courseId, studentProfileId} = props
    const {t} = useTranslation(["studentServices"])
    const {searchValue, currentFilter, setCurrentFilter, changeSearch, clearFilter, applyFilter} = useFilterStorage(
        FilterKey.ExternshipAttendance,
        DEFAULT_FILTER
    )
    const [exporting, setExporting] = useState(false)
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const {data, page, total, pageSize, columns, fields, allFields, isLoading, dispatch} = props

    const [searchDebounce, setSearchDebounce] = useState(searchValue)
    const [selectedAttendance, setSelectedAttendance] = useState<{
        profileId: number
    }>()
    const attendancePopup = useVisible(false)

    useDebounce(() => setSearchDebounce(searchValue), 500, [searchValue])

    useEffect(() => {
        props.dispatch({isClassComponent: false})
        props.dispatchFunc([
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    const getFilterParams = () => {
        let params: any = {
            termIds: [termId],
            courseIds: [courseId],
            linkedEntities: true
        }

        if (currentFilter?.dateRange?.length) {
            const startDate = moment(currentFilter.dateRange[0])
            startDate.startOf("day")
            const endDate = moment(currentFilter.dateRange[1])
            endDate.endOf("day")
            params.fromDate = startDate.format("YYYY-MM-DD")
            params.toDate = endDate.format("YYYY-MM-DD")
        }

        // Will uncomment this when API supports search params
        // if (searchDebounce?.length) {
        //     params.search = searchDebounce
        // }

        if (studentProfileId) {
            params.studentProfileIds = [parseInt(studentProfileId)]
        }
        return params
    }

    const renderIconAttendance = (eventType: string) => {
        if (eventType === "check-out") {
            return <Icon icon="CHECKOUT_FILL" color={"#1E90FF"} className={styles.checkOutIcon} />
        } else if (eventType === "check-in") {
            return <Icon icon="CHECKIN_FILL" color={"#1E90FF"} className={styles.rightIcon} />
        } else if (eventType === "break-start") {
            return <Icon icon="ATTENDANCE_OUT" color={"#DF1642"} className={styles.rightIcon} />
        } else if (eventType === "break-end") {
            return <Icon icon="ATTENDANCE_IN" color={"#18A957"} className={styles.rightIcon} />
        } else {
            return null
        }
    }

    async function getData() {
        try {
            dispatch({isLoading: true})
            const params = getFilterParams()
            const {data, total} = await attendanceService.getAttendanceList({
                range: {
                    page,
                    pageSize
                },
                ...params
            })
            const results = (data || []).map((item) => {
                const events = item.events ?? []
                const latestEventType = events.length ? events[events.length - 1].eventType : undefined
                const checkIn = events.find((event) => event.eventType === AttendanceEventType.Checkin)
                const checkOut = events.find((event) => event.eventType === AttendanceEventType.Checkout)
                return {
                    ...item,
                    checkInHtml: checkIn
                        ? moment(convertTZ(new Date(checkIn.createdAt), item.student?.timezone)).format(
                              model.getUserTimeFormat()
                          )
                        : "",
                    checkOutHtml: checkOut
                        ? moment(convertTZ(new Date(checkOut.createdAt), item.student?.timezone)).format(
                              model.getUserTimeFormat()
                          )
                        : "",
                    statusHtml: renderIconAttendance(latestEventType),
                    id: item.attendanceId,
                    missedHours: `${roundTwoDigits((item.missedHours ?? 0) * 60)}`,
                    attendedHours: `${roundTwoDigits((item.attendedHours ?? 0) * 60)}`,
                    dateHtml: formatDateOnly(item.date, dateFormat),
                    attendHtml: renderAttendHtml(item.status, item.isVerified)
                }
            })
            props.dispatch({data: results, total})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    function renderAttendHtml(status, isVerified) {
        if (isVerified === YesNo.Yes && status === AttendanceStatus.Rejected) {
            return (
                <div className={styles.attClose}>
                    <Icon icon="CROSS_CIRCLE" />
                </div>
            )
        }
        if (isVerified === YesNo.Yes) {
            return (
                <div className={styles.attCheckVerified}>
                    <Icon icon="CHECKED" />
                </div>
            )
        }
        switch (status) {
            case AttendanceStatus.Approved:
                return (
                    <div className={styles.attCheck}>
                        <Icon icon="CHECKED" />
                    </div>
                )
            case AttendanceStatus.Irregular:
                return (
                    <div className={styles.actionWarning}>
                        <Icon icon="WARNING_TRIANGLE_FILL" />
                    </div>
                )
            case AttendanceStatus.Rejected:
                return (
                    <div className={styles.attClose}>
                        <Icon icon="CROSS_CIRCLE" />
                    </div>
                )
            case AttendanceStatus.InClass:
            default:
                return null
        }
    }

    function getColumns() {
        return [
            {
                title: t("attendance.table.date"),
                field: "dateHtml",
                fieldType: "date",
                format: dateFormat,
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.student"),
                field: "studentName",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Check In",
                field: "checkInHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Check Out",
                field: "checkOutHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "Status",
                field: "statusHtml",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.attended"),
                field: "attendedHours",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: t("attendance.table.missed"),
                field: "missedHours",
                headerStyle: {minWidth: "150px"}
            },
            {
                title: "attn",
                field: "attendHtml",
                headerStyle: {minWidth: "150px"}
            }
        ]
    }

    function getFields() {
        return [
            t("attendance.table.date"),
            t("attendance.table.student"),
            "Check In",
            "Check Out",
            "Status",
            t("attendance.table.attended"),
            t("attendance.table.missed"),
            "attn"
        ]
    }

    const onChangeFilter = (key, value) => {
        const newFilter = {...currentFilter}
        newFilter[key] = value
        setCurrentFilter(newFilter)
    }

    useEffect(() => {
        onApplyFilter()
    }, [currentFilter, page, pageSize, searchDebounce])

    const onApplyFilter = () => {
        applyFilter()
        getData()
    }

    const onClickRow = async (data) => {
        attendancePopup.open()
        try {
            setSelectedAttendance(data)
        } catch (err) {
            handleError(err)
        }
    }

    const onClosePopup = useCallback(() => {
        attendancePopup.close()
        setSelectedAttendance(undefined)
    }, [])

    const handleExportFile = async (type: ExportFileType) => {
        setExporting(true)
        try {
            const params = getFilterParams()
            const {data} = await attendanceService.getAttendanceList({
                range: {
                    page,
                    pageSize: UNLIMITED_PAGE_SIZE
                },
                ...params
            })
            const results = (data || []).map((item) => {
                const events = item.events ?? []
                const latestEventType = events.length ? events[events.length - 1].eventType : undefined
                const checkIn = events.find((event) => event.eventType === AttendanceEventType.Checkin)
                const checkOut = events.find((event) => event.eventType === AttendanceEventType.Checkout)
                return {
                    ...item,
                    checkInHtml: checkIn
                        ? moment(convertTZ(new Date(checkIn.createdAt), item.student?.timezone)).format(
                              model.getUserTimeFormat()
                          )
                        : "",
                    checkOutHtml: checkOut
                        ? moment(convertTZ(new Date(checkOut.createdAt), item.student?.timezone)).format(
                              model.getUserTimeFormat()
                          )
                        : "",
                    statusHtml: latestEventType,
                    id: item.attendanceId,
                    missedHours: `${roundTwoDigits((item.missedHours ?? 0) * 60)}`,
                    attendedHours: `${roundTwoDigits((item.attendedHours ?? 0) * 60)}`,
                    dateHtml: formatDate(item.date, dateFormat),
                    attendHtml:
                        item.status === AttendanceStatus.Approved
                            ? `${YesNo.Yes} (Verified: ${item.isVerified ? YesNo.Yes : YesNo.No})`
                            : `${YesNo.No} (Verified: ${item.isVerified ? YesNo.Yes : YesNo.No})`
                }
            })
            const filename = `Attendance_${moment().format("MM_DD_YYYY")}`
            const payload = {
                filename,
                columns: columns.map((col) => col.title.toUpperCase()),
                rows: results.map((item) =>
                    columns.map((col) => {
                        return item[col.field]?.toString() ?? ""
                    })
                )
            }
            if (type === "csv") {
                exportCsv(payload)
            } else if (type === "excel") {
                exportExcel(payload)
            }
        } catch (err) {
            handleError(err)
        } finally {
            setExporting(false)
        }
    }

    return (
        <div>
            <BaseNewFilter
                searchValue={searchValue}
                onSearchInput={changeSearch}
                onClick={onApplyFilter}
                onClickClear={clearFilter}
                filter={currentFilter}
                renderRightFilter={() => (
                    <div className={styles.rightFilter}>
                        <ExportButton
                            label="EXPORT"
                            isLoading={exporting}
                            onSelect={handleExportFile}
                            availableFileTypes={["csv", "excel"]}
                        />
                    </div>
                )}>
                <Row gutter={[40, 32]}>
                    <Col span={12}>
                        <BaseRangePicker
                            placeholder={["Date From", "Date To"]}
                            value={currentFilter.dateRange}
                            onChange={(newValue) => {
                                onChangeFilter("dateRange", newValue)
                            }}
                        />
                    </Col>
                </Row>
            </BaseNewFilter>
            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
                onClickRowItem={onClickRow}
            />
            <DailyAttendancePopup
                studentProfileIds={selectedAttendance?.profileId ? [selectedAttendance.profileId] : []}
                getListAttendances={getData}
                selectedAttendance={selectedAttendance}
                isShow={attendancePopup.isVisible}
                onClose={onClosePopup}
                isExternshipAttendance
            />
        </div>
    )
}

export const AttendanceOverviewExternshipTab = KlassappTableHOC(AttendanceOverviewExternship)
