import React from "react"
import cx from "classnames"
import {Icon} from "components/Icon"
import {RemarkIndicator} from "./RemarkIndicator"
import styles from "./item.module.css"

type Props = {
    field: any
    onChangeRemarkField: (fieldId: string, comment: string) => void
}

export function Checkbox(props: Props) {
    const {field, onChangeRemarkField} = props
    const {width, height} = field.placeInformation
    const style: any = {width, height}

    const renderBody = () => {
        return field.value ? (
            <Icon icon="CHECKBOX_CHECKED" className={styles.iconChecked} color="var(--primary-400-base)" />
        ) : null
    }

    return (
        <RemarkIndicator field={field} onChangeRemarkField={onChangeRemarkField}>
            <div className={cx(styles.actionItem, styles.flexCenter)} style={style}>
                {renderBody()}
            </div>
        </RemarkIndicator>
    )
}
