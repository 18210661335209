import React from "react"
import {Icon} from "components/Icon"
import styles from "./StudentInfo.module.css"
import {AcademicInstructional} from "types/academicInstructional"
import {getFullName} from "helpers"
import cx from "classnames"

type Props = {
    student: AcademicInstructional.LessonStudent
    checkedStudentIds: number[]
    disabled?: boolean
    onToggleCheck: (studentId: number) => void
}

export function StudentInfo(props: Props) {
    const {student, checkedStudentIds, onToggleCheck, disabled} = props
    const isChecked = checkedStudentIds.includes(student.student.userId)

    return (
        <div className={styles.studentItem}>
            {student.student.photo && (
                <img src={student.student.photo[64]} className={styles.studentItem__img} alt="" />
            )}
            <span className={styles.studentItem__info}>
                {student.student.userId} {getFullName(student.student)}
            </span>
            <div
                className={styles.studentItem__iconWrap}
                onClick={disabled ? undefined : () => onToggleCheck(student.student.userId)}>
                {disabled ? null : (
                    <div
                        className={cx(styles.unchecked, {
                            [styles.checked]: isChecked
                        })}>
                        <Icon icon="TICK" className={styles.attendanceTickIcon} color="#e5e5e5" />
                    </div>
                )}
            </div>
        </div>
    )
}
