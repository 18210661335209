/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useState} from "react"
import styles from "./Search.module.css"
import cx from "classnames"
import {ReactComponent as Icon} from "./search.svg"
import {CloseButton} from "./parts/CloseButton"
import {debounce} from "lodash"

type Props = {
    placeholder?: string
    disabled?: boolean
    onChangeValue: (value: string) => void
    className?: string
}

export const Search = (props: Props) => {
    const {placeholder, disabled, onChangeValue, className} = props
    const [value, setValue] = useState("")

    const debounceHandler = useCallback(
        debounce((value: string) => {
            onChangeValue(value)
        }, 500),
        []
    )
    const onChange = useCallback(
        (val) => {
            setValue(val)
            debounceHandler(val)
        },
        [debounceHandler]
    )

    return (
        <div className={cx(styles.root, className)}>
            <input
                className={cx(styles.input, {[styles.inputClear]: value.length > 0})}
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
                disabled={disabled}
            />
            <div className={styles.iconWrapper}>
                <Icon className={styles.icon} width={18} height={18} />
            </div>
            {value.length > 0 && (
                <div className={styles.clearButton}>
                    <CloseButton onClick={() => onChange("")} />
                </div>
            )}
        </div>
    )
}

export const ControlledSearch = (props: Props & {value: string}) => {
    const {placeholder, disabled, value, onChangeValue} = props

    return (
        <div className={styles.root}>
            <input
                className={cx(styles.input, {[styles.inputClear]: value.length > 0})}
                type="text"
                value={value}
                onChange={(e) => onChangeValue(e.target.value)}
                placeholder={placeholder}
                disabled={disabled}
            />
            <div className={styles.iconWrapper}>
                <Icon className={styles.icon} width={18} height={18} />
            </div>
            {value.length > 0 && (
                <div className={styles.clearButton}>
                    <CloseButton onClick={() => onChangeValue("")} />
                </div>
            )}
        </div>
    )
}
