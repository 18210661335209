import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {Col, Modal, Row, Spin, Tabs} from "antd"
import styles from "./FinAidPlan.module.css"
import {finAidService, generalSettingsService} from "services"
import moment from "moment"
import {Auth} from "types/auth"
import {BaseDepartmentId} from "types/departments"
import {FAAwardWorksheetTemplate, FinAidAwardYear} from "types/fin-aid/fin-aid"
import {BaseButton, Icon} from "components"
import {KlassDropdown} from "components/Select"
import CollegeFinancingPlans from "../CollegeFinancingPlan/CollegeFinancingPlans"
import FinAidWorksheets from "../FinAidWorksheet/FinAidWorksheets"
import FinAidOffers from "../FinAidOffer/FinAidOffers"
import FinAidOfferDocuments from "../FinAidOffer/FinAidOfferDocuments"
import {useActiveTab, useDepartmentStudent, useModel} from "hooks"
import {TabHeader} from "components/Tab"
import {DEPENDENCY_STATUS_OPTIONS} from "types/students"
import {orderBy} from "lodash"
import {useQuery} from "@tanstack/react-query"
import {GeneralSettingField} from "types/settings/general-setting"
import {getFieldLabel} from "helpers"
import {Settings} from "types/settings"

const {TabPane} = Tabs

export enum FinAidPlanTabs {
    CollegeFinancingPlan = "cfps",
    AwardWorksheet = "worksheets",
    AwardWorksheetClock = "aw-clock",
    FinAidOffer = "offers",
    FinAidPackge = "packages"
}

const currentYear = moment().year()
const yearOptions = [-1, 0, 1, 2, 3, 4, 5, 6].map((index) => ({
    value: currentYear + index,
    label: `${currentYear + index} - ${currentYear + index + 1}`
}))

interface FinAidPlanProps {
    studentId: number
    departmentId: number
}

function FinAidPlan({studentId, departmentId}: FinAidPlanProps) {
    const {t} = useTranslation(["financialAid", "common"])
    const [isLoading, setLoading] = React.useState(true)
    const [awardYears, setAwardYears] = React.useState<FinAidAwardYear[]>([])
    const [awardYearsInfo, setAwardYearsInfo] = React.useState<{isir?: string; efc?: number}>({})
    const [newAwardYearVisible, setNewAwardYearVisible] = React.useState(false)
    const [selectedAwardYear, setSelectedAwardYear] = React.useState<typeof yearOptions[0] | undefined>()
    const [activeSubTab, setActiveSubTab] = useActiveTab(FinAidPlanTabs.CollegeFinancingPlan, "finaidtab")
    const {student} = useDepartmentStudent({studentId, departmentId: BaseDepartmentId.FinancialAid})

    const faAWTemplateQuery = useQuery<FAAwardWorksheetTemplate>(
        ["fa-aw-template"],
        async () => {
            const [setting] = await generalSettingsService.getSettings([GeneralSettingField.FaAwardWorksheetTemplate])
            return (setting?.value as FAAwardWorksheetTemplate) ?? FAAwardWorksheetTemplate.Standard
        },
        {
            initialData: FAAwardWorksheetTemplate.Standard
        }
    )

    useEffect(() => {
        ;(async function loadAwardYearsLatestWorksheet() {
            try {
                const awardYears = await finAidService.getStudentAwardYears(studentId)
                setAwardYears(awardYears)
                const awardYearsInfo = await finAidService.getAwardYearsInfo(studentId)
                setAwardYearsInfo(awardYearsInfo)
            } catch {
            } finally {
                setLoading(false)
            }
        })()
    }, [studentId])

    const handleNewAwardYear = React.useCallback(async () => {
        setNewAwardYearVisible(true)
    }, [])

    const newAwardYearConfirm = React.useCallback(async () => {
        setAwardYears((prev) => {
            if (prev.find((awardYear) => awardYear.awardYear === selectedAwardYear.value)) return prev
            return orderBy(
                [...prev, {profileId: studentId, awardYear: selectedAwardYear.value}],
                ["awardYear"],
                ["desc"]
            )
        })
        setNewAwardYearVisible(false)
    }, [studentId, selectedAwardYear])

    const newAwardYearCancel = React.useCallback(() => setNewAwardYearVisible(false), [])

    useEffect(() => {
        if (awardYears.length) {
            finAidService.updateStudentAwardYears(studentId, awardYears)
        }
    }, [studentId, awardYears])

    return (
        <div className={styles.finPlanContainer}>
            <Row gutter={24} wrap={false}>
                <Col flex={1}>
                    <div className={styles.infosContainer}>
                        <div className={styles.infoContent}>
                            <div className={styles.infoLabel}>{t("finAidPlan.awardYear.isir")}</div>
                            <div className={styles.infoValue}>{awardYearsInfo?.isir || "-"}</div>
                        </div>

                        <div className={styles.infoContent}>
                            <div className={styles.infoLabel}>{t("finAidPlan.awardYear.efc")}</div>
                            <div className={styles.infoValue}>{awardYearsInfo?.efc || "-"}</div>
                        </div>

                        <div className={styles.infoContent}>
                            <div className={styles.infoLabel}>{t("finAidPlan.awardYear.dependencyStatus")}</div>
                            <div className={styles.infoValue}>
                                {DEPENDENCY_STATUS_OPTIONS.find((option) => option.id === student?.dependencyStatus)
                                    ?.name || "-"}
                            </div>
                        </div>

                        <div className={styles.infoContent}>
                            <div className={styles.infoLabel}>{t("finAidPlan.awardYear.methodOfPaying")}</div>
                            <div className={styles.infoValue}>
                                {student?.payingMethods?.map((method) => method.name).join(", ") || "-"}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <BaseButton
                        title="NEW AWARD YEAR"
                        icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                        onClick={handleNewAwardYear}
                        disabled={isLoading}
                    />
                </Col>
            </Row>

            <div className={styles.awardYearsContainer}>
                {isLoading ? (
                    <Spin />
                ) : (
                    awardYears.map((awardYear) => (
                        <AwardYearContent
                            key={awardYear.awardYear}
                            {...{
                                studentId,
                                departmentId,
                                student,
                                awardYear,
                                activeSubTab,
                                setActiveSubTab,
                                faAWTemplate: faAWTemplateQuery.data
                            }}
                        />
                    ))
                )}
            </div>

            <Modal
                keyboard={false}
                closable={false}
                centered
                visible={newAwardYearVisible}
                onCancel={newAwardYearCancel}
                closeIcon={<Icon icon="CLOSE" color="var(--black-base)" className={styles.closeIcon} />}
                maskClosable={false}
                footer={null}>
                <h2 className={styles.modalTitle}>Select Award Year</h2>
                <KlassDropdown
                    onChange={setSelectedAwardYear}
                    options={yearOptions}
                    value={selectedAwardYear}
                    valueKey="value"
                    labelKey="label"
                    placeholder="Select"
                />
                <div className={styles.actionBtn}>
                    <BaseButton title={t("common:action.cancel")} variant="secondary" onClick={newAwardYearCancel} />
                    <BaseButton title={t("common:action.save")} onClick={newAwardYearConfirm} />
                </div>
            </Modal>
        </div>
    )
}

export default FinAidPlan

interface AwardYearContentProps extends FinAidPlanProps {
    awardYear: FinAidAwardYear
    student?: Auth.DepartmentStudent
    activeSubTab: string
    setActiveSubTab: React.Dispatch<React.SetStateAction<string>>
    faAWTemplate: FAAwardWorksheetTemplate
}

function AwardYearContent({
    studentId,
    departmentId,
    awardYear,
    student,
    activeSubTab,
    setActiveSubTab,
    faAWTemplate
}: AwardYearContentProps) {
    const {t} = useTranslation(["financialAid"])
    const model = useModel()

    return (
        <div className={styles.awardYearContent}>
            <h3 className={styles.title}>
                {t("finAidPlan.awardYear.title")} {awardYear.awardYear} - {awardYear.awardYear + 1}
            </h3>

            <div className={styles.wrapper}>
                <Tabs className="fullwidth" activeKey={activeSubTab} onChange={setActiveSubTab}>
                    <TabPane
                        tab={
                            <TabHeader
                                title={getFieldLabel(
                                    model,
                                    Settings.FinAidLabel.CollegeFinancingPlan,
                                    t(`finAidPlan.tab.${FinAidPlanTabs.CollegeFinancingPlan}`)
                                )}
                            />
                        }
                        key={FinAidPlanTabs.CollegeFinancingPlan}>
                        <CollegeFinancingPlans
                            studentId={studentId}
                            departmentId={departmentId}
                            awardYear={awardYear.awardYear}
                            student={student}
                        />
                    </TabPane>
                    {faAWTemplate === FAAwardWorksheetTemplate.Clock ? (
                        <TabPane
                            tab={
                                <TabHeader
                                    title={getFieldLabel(
                                        model,
                                        Settings.FinAidLabel.AWClock,
                                        t(`finAidPlan.tab.${FinAidPlanTabs.AwardWorksheetClock}`)
                                    )}
                                />
                            }
                            key={FinAidPlanTabs.AwardWorksheetClock}>
                            <FinAidWorksheets
                                studentId={studentId}
                                departmentId={departmentId}
                                awardYear={awardYear.awardYear}
                                student={student}
                                isNewDesign
                            />
                        </TabPane>
                    ) : (
                        <TabPane
                            tab={
                                <TabHeader
                                    title={getFieldLabel(
                                        model,
                                        Settings.FinAidLabel.AwardWorksheet,
                                        t(`finAidPlan.tab.${FinAidPlanTabs.AwardWorksheet}`)
                                    )}
                                />
                            }
                            key={FinAidPlanTabs.AwardWorksheet}>
                            <FinAidWorksheets
                                studentId={studentId}
                                departmentId={departmentId}
                                awardYear={awardYear.awardYear}
                                student={student}
                            />
                        </TabPane>
                    )}
                    <TabPane
                        tab={
                            <TabHeader
                                title={getFieldLabel(
                                    model,
                                    Settings.FinAidLabel.FinAidOffer,
                                    t(`finAidPlan.tab.${FinAidPlanTabs.FinAidOffer}`)
                                )}
                            />
                        }
                        key={FinAidPlanTabs.FinAidOffer}>
                        <FinAidOfferDocuments
                            studentId={studentId}
                            departmentId={departmentId}
                            awardYear={awardYear.awardYear}
                            student={student}
                        />
                    </TabPane>
                    <TabPane
                        tab={
                            <TabHeader
                                title={getFieldLabel(
                                    model,
                                    Settings.FinAidLabel.FinAidPackages,
                                    t(`finAidPlan.tab.${FinAidPlanTabs.FinAidPackge}`)
                                )}
                            />
                        }
                        key={FinAidPlanTabs.FinAidPackge}>
                        <FinAidOffers
                            studentId={studentId}
                            departmentId={departmentId}
                            awardYear={awardYear.awardYear}
                            student={student}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}
