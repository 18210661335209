import React, {Component} from "react"
import styles from "./Label.module.css"

type Props = {
    text: string
    required?: boolean
    children: JSX.Element
    error?: string
    className?: string
}

export class Label extends Component<Props, {}> {
    public render() {
        const {children, text, error, required, className} = this.props
        return (
            <label className={`${styles.label} ${className}`}>
                {text && (
                    <p className={styles.title}>
                        {text}
                        {required && <span className={styles.required}>*</span>}
                    </p>
                )}
                {children}
                {error && (
                    <div className={styles.errorWrapper}>
                        <p className={styles.errorText}>{error}</p>
                    </div>
                )}
            </label>
        )
    }
}
