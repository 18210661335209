import React, {useMemo, useRef, useState} from "react"
import {Button, Checkbox} from "antd"
import cx from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"
import {Icon} from "components/Icon"
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd"
import {checkIsFirefox} from "helpers"
import {TableRowItem, EmptyRow, LoadingRow, ColumnPicker} from "./parts"
import styles from "./KlassappTable.module.css"
import {useClickAway} from "react-use"
import {KlassappTableContext} from "./KlassappTableContext"
import {KlassappTableProps} from "types/common"

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

function BodyTable(props) {
    const {tableData, isLoading, columns, isDraggable, ...rest} = props

    if (isLoading) {
        return (
            <tbody className={styles.bodyTable}>
                <LoadingRow colSpan={columns.length + 2} />
            </tbody>
        )
    }
    if (!tableData.length) {
        return (
            <tbody className={styles.bodyTable}>
                <EmptyRow colSpan={columns.length + 2} />
            </tbody>
        )
    }

    if (isDraggable) {
        return (
            <Droppable droppableId="table">
                {(droppableProvided) => (
                    <tbody
                        ref={droppableProvided.innerRef}
                        className={styles.bodyTable}
                        {...droppableProvided.droppableProps}>
                        {tableData.map((item, index: number) => (
                            <Draggable draggableId={`${item.id}`} index={index} key={item.id}>
                                {(provided, snapshot) => (
                                    <TableRowItem
                                        provided={provided}
                                        snapshot={snapshot}
                                        key={item.id}
                                        data={item}
                                        columns={columns}
                                        isDraggable={isDraggable}
                                        {...rest}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </tbody>
                )}
            </Droppable>
        )
    }
    return (
        <tbody className={styles.bodyTable}>
            {tableData.map((item) => (
                <TableRowItem key={item.id} data={item} columns={columns} isDraggable={isDraggable} {...rest} />
            ))}
        </tbody>
    )
}

export type KlassappTableEditableProps = KlassappTableProps & {
    onChangeCellInput: Function
}

export function KlassappTableEditable(props: KlassappTableEditableProps) {
    const {
        className,
        parentColumns = [],
        columns,
        menuActions,
        data,
        isLoading,
        fields,
        allFields,
        isShowCheckedColumn,
        isShowColumnPicker,
        isShowHeader = true,
        isDraggable,
        keyRender = "id",
        orderField,
        onClickRowItem,
        onDoubleClickRowItem,
        onChangeFields,
        onUpdateTableData,
        onUpdateRowData,
        onToggleCheckHeader,
        onClickSortColumn,
        onDraggableEnd,
        icon,
        expandable,
        onChangeCellInput
    } = props

    const colsPickerRef = useRef(null)
    const [isShowScrollTable, setIsShowScrollTable] = useState(true)

    useClickAway(colsPickerRef, () => {
        setIsShowChooseCols(false)
    })

    const onToggleCheck = (checked) => {
        if (onToggleCheckHeader) {
            onToggleCheckHeader(checked)
        } else {
            const newTableData = data.map((item) => {
                item.isChecked = checked
                return item
            })
            onUpdateTableData(newTableData)
        }
    }
    const [isShowChooseCols, setIsShowChooseCols] = useState(false)
    const isChecked = useMemo(() => {
        return data.length && data.every((row) => !!row?.isChecked)
    }, [data])

    const renderOrderIcon = (field) => {
        return orderField.field === field ? (
            orderField.order === "asc" ? (
                <Icon icon="SORT_DOWN_SOLID" className={styles.sortIcon} />
            ) : (
                <Icon icon="SORT_UP_SOLID" className={styles.sortIcon} />
            )
        ) : (
            <Icon icon="SORT_SOLID" className={styles.sortIcon} />
        )
    }

    const onDragEnd = (result) => {
        const {destination, source} = result || {}
        if (!destination || destination.index === source.index) {
            return
        }
        if (destination.index === source.index) {
            return
        }
        if (onDraggableEnd) {
            onDraggableEnd(result)
        } else {
            const newTableData = reorder(data, source.index, destination.index)
            onUpdateTableData(newTableData)
        }
    }

    const onShowChooseCols = () => {
        setIsShowChooseCols(true)
    }

    const renderTableContent = () => {
        return (
            <table className={styles.table}>
                {isShowHeader && (
                    <thead className={styles.tableHead}>
                        {parentColumns.length ? (
                            <tr>
                                {isShowCheckedColumn && (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                )}
                                {!!expandable?.expandedRowRender && (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                )}
                                {parentColumns.map((col, index) => (
                                    <th
                                        key={index}
                                        className={`${styles.textLeft} ${styles.tableCellHeader}`}
                                        colSpan={col.span}>
                                        {col.title}
                                    </th>
                                ))}
                                {isShowColumnPicker ? (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                ) : menuActions && menuActions.length ? (
                                    <th className={`${styles.textLeft} ${styles.tableCellHeader}`}></th>
                                ) : null}
                            </tr>
                        ) : null}
                        <tr className={styles.tableRowHeader}>
                            {isShowCheckedColumn && (
                                <th
                                    className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.expandIconCell}`}>
                                    <Checkbox
                                        checked={isChecked}
                                        onChange={(event) => onToggleCheck(event.target.checked)}
                                    />
                                </th>
                            )}
                            {!!expandable?.expandedRowRender && (
                                <th
                                    className={`${styles.tableCell} ${styles.tableCellHeader} ${styles.expandIconCell}`}></th>
                            )}
                            {columns.map((column, index) => {
                                const canSortable = column.sortable && onClickSortColumn
                                const orderField = column.orderField || column.field

                                return (
                                    fields.includes(column.title) && (
                                        <th
                                            key={index}
                                            className={cx(
                                                styles.tableCell,
                                                styles.tableCellHeader,
                                                column.classNameHeader,
                                                canSortable ? styles.cursor : ""
                                            )}
                                            style={column.headerStyle}
                                            onClick={canSortable ? () => onClickSortColumn(orderField) : () => {}}>
                                            {column.titleHtml || column.title}
                                            {canSortable && renderOrderIcon(orderField)}
                                        </th>
                                    )
                                )
                            })}
                            {isShowColumnPicker ? (
                                <th className={`${styles.tableCell} ${styles.tableCellHeader}`}>
                                    <div className={styles.flexEnd}>
                                        <Button
                                            className={styles.plusIconBtn}
                                            icon={<Icon icon={icon || "COLUMNS"} className={styles.iconPlus} />}
                                            onClick={onShowChooseCols}
                                        />
                                    </div>
                                </th>
                            ) : menuActions && menuActions.length ? (
                                <th className={`${styles.tableCell} ${styles.tableCellHeader}`}></th>
                            ) : null}
                        </tr>
                    </thead>
                )}
                <BodyTable
                    columns={columns}
                    tableData={data}
                    menuActions={menuActions}
                    fields={fields}
                    isLoading={isLoading}
                    isShowCheckedColumn={isShowCheckedColumn}
                    isShowColumnPicker={isShowColumnPicker}
                    onClickRowItem={onClickRowItem}
                    onDoubleClickRowItem={onDoubleClickRowItem}
                    onUpdateRowData={onUpdateRowData}
                    isDraggable={isDraggable}
                    expandable={expandable}
                    keyRender={keyRender}
                    onChangeCellInput={onChangeCellInput}
                />
            </table>
        )
    }

    const renderTable = () => {
        const isFirefox = checkIsFirefox()
        return (
            <KlassappTableContext.Provider value={{isShowScrollTable, setIsShowScrollTable}}>
                <div className={cx(styles.root, {"hidden-scroll": !isShowScrollTable})}>
                    {isFirefox ? (
                        <div
                            className={cx(
                                "custom-scroll",
                                isShowScrollTable ? styles.tableScroll : styles.tableScrollHidden,
                                className
                            )}>
                            {renderTableContent()}
                        </div>
                    ) : (
                        <PerfectScrollbar
                            className={cx(
                                "custom-scroll",
                                isShowScrollTable ? styles.tableScroll : styles.tableScrollHidden,
                                className
                            )}>
                            {renderTableContent()}
                        </PerfectScrollbar>
                    )}
                    {isShowChooseCols && (
                        <div ref={colsPickerRef}>
                            <ColumnPicker fields={fields} allFields={allFields} onChangeFields={onChangeFields} />
                        </div>
                    )}
                </div>
            </KlassappTableContext.Provider>
        )
    }

    if (isDraggable) {
        return (
            <DragDropContext onDragEnd={onDragEnd} style={{overflow: "auto"}}>
                {renderTable()}
            </DragDropContext>
        )
    }
    return renderTable()
}
