import React, {Component} from "react"
import styles from "./RadioButton.module.css"
import cx from "classnames"

type Props = {
    className?: string
    classNameText?: string
    text?: string
    checked?: boolean
    onClick: (e?) => void
    disabled?: boolean
    name?: string
    value?: any
    type?: string
}

export class RadioButton extends Component<Props, {}> {
    public render() {
        const {className, classNameText, text, checked, onClick, disabled, ...props} = this.props
        return (
            <label className={cx(styles.root, className)}>
                <input
                    className={styles.input}
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={onClick}
                    {...props}
                />
                <div className={styles.ellipse} />
                {text && <p className={cx(styles.text, classNameText)}>{text}</p>}
            </label>
        )
    }
}
