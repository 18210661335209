/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {savedFilterService} from "services"
import {KlassDropAsyncPaginate, KlassDropdownAsyncProps} from "components/Select"
import styles from "./SavedFiltersSelect.module.css"
import {FilterKey} from "types/filter"

type Props = Partial<KlassDropdownAsyncProps> & {
    filterKey: FilterKey
}

export const SavedFiltersSelect = (props: Props) => {
    const {filterKey} = props

    const search = React.useCallback(
        debounce(async (search: string = "", loadedOptions) => {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await savedFilterService.list({
                filter: {
                    search,
                    filterKey
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        }, 500),
        []
    )

    return (
        <KlassDropAsyncPaginate
            {...props}
            placeholder={props.placeholder || "Select saved filter"}
            loadOptions={search}
            defaultOptions
            valueKey="filterId"
            labelKey="name"
            className={styles.container}
            getOptionLabel={SavedFilterOptionLabel}
        />
    )
}

const SavedFilterOptionLabel = (option) => {
    return (
        <span>
            {option.name}
            {option.isPublic ? <span className={styles.publicType}> (PUBLIC)</span> : null}
        </span>
    )
}
