import styles from "./ClockHoursTranscript.module.css"
import {useState} from "react"
import {Major} from "types/major"
import {Icon} from "components"
import ProgramTable from "./ProgramTable"
import SectionView from "./SectionView"

type ClockHoursTranscriptProps = {}

const ClockHoursTranscript = (props: ClockHoursTranscriptProps) => {
    const [selectedProgram, setSelectedProgram] = useState<Major.MajorVersion>()

    return (
        <div className={styles.wrapper}>
            {!selectedProgram ? (
                <>
                    <ProgramTable setSelectedProgram={setSelectedProgram} />
                </>
            ) : (
                <div className={styles.sectionView}>
                    <div className={styles.header}>
                        <div className={styles.backBtnWrap} onClick={() => setSelectedProgram(undefined)}>
                            <Icon icon="BACK" color="#FF349B" className={styles.backIcon} />
                        </div>
                        <div className={styles.programName}>{selectedProgram.version_name}</div>
                    </div>
                    <SectionView selectedProgram={selectedProgram} />
                </div>
            )}
        </div>
    )
}

export default ClockHoursTranscript
