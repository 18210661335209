/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Model} from "Model"
import {QRScan} from "./QRScan"
import styles from "./QRScan.module.css"
import {HTMLTitle} from "components/misc"
import moment from "moment"

type Props = {
    model: Model
}

export const QRScanPage = (props: Props) => {
    const {model} = props
    const [now, setNow] = useState(moment())

    useEffect(() => {
        model.menu.infoBar = false
        model.menu.sidebar = false

        const interval = window.setInterval(() => {
            setNow(moment())
        }, 1000)

        return () => {
            window.clearInterval(interval)
        }
    }, [])

    return (
        <>
            <HTMLTitle title="QR Checkin" />
            <div className={styles.pageContainer}>
                <div className={styles.timeLabel}>
                    {now.format("dddd")}
                    <br />
                    {now.format("LL")}
                    <br />
                    {now.format("LT")}
                </div>
                <QRScan showAsStandalone />
            </div>
        </>
    )
}
