/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from "react"
import {useTranslation} from "react-i18next"
import moment from "moment"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {BaseService, studentActivitiesService} from "services"
import {Icon} from "components/Icon"
import {KlassappTableHOC} from "HOC"
import {getFullName, handleError, toastError, toastSuccess} from "helpers"
import styles from "./ActivitiesTable.module.css"
import {useActiveTab, useCurrentProfilePermissions, useModel, useVisible} from "hooks"
import cx from "classnames"
import ProcessWorkflowModal from "sections/Settings/Workflows/parts/ProcessWorkflowModal"
import {BaseButton} from "components"
import {Col, Row, Spin, Tabs, Tooltip} from "antd"
import {BaseRangePicker} from "components/DateTimePicker"
import {KlassDropdown} from "components/Select"
import {ActivityPopup} from "../ActivityPopup"
import WorkflowActivitiesTable from "./WorkflowActivitiesTable"
import {
    EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED,
    EVENT_STUDENT_ACTIVITY_CREATED,
    StudentActivity
} from "types/activity"
import CampusSelect from "components/CampusSelect"
import {Campus} from "types/campus"
import ProgramSelect from "components/ProgramSelect"
import {Major} from "types/major"
import {DisplayMode, KlassappTableProps} from "types/common"
import ActivityStats from "sections/shared/student/ActivitiesTable/ActivityStats"
import {Link, useHistory} from "react-router-dom"
import {TriggeredWorkflowStatus} from "types/triggered-workflow"
import {FilterKey} from "types/filter"
import {isEmpty, pick} from "lodash"
import {emitEvent} from "helpers/eventHelper"
import {useMutation} from "@tanstack/react-query"
import CircularSpin from "components/CircularSpin"
import UserSelect from "components/UserSelect"
import {
    BaseDepartmentId,
    BASE_DEPARTMENT_NAME,
    DEPARTMENTS_WITH_ACTIVITIES,
    MAP_DEPARTMENT_ID_2_KEY
} from "types/departments"
import {ADMISSION_STUDENT_TABS} from "sections/Admission/AdmissionStudent/AdmissionStudent"
import {AdmissionStudentAdmissionSubtab} from "sections/Admission/AdmissionStudent/parts"
import {FinancialAidStudentTabs} from "sections/FinancialAid/FinancialAidStudent/FinancialAidStudent"
import {FinAidStudentFinAidSubtab} from "sections/FinancialAid/FinancialAidStudent/parts/FinancialAidStudentTab/FinancialAidStudentTab"
import {RegistrarStudentTab} from "sections/academics/student/AcademicsStudent"
import {StudentServicesStudentTab} from "sections/StudentServices/StudentServicesStudent/StudentServicesStudent"
import {Auth} from "types/auth"
import {UserAutocomplete} from "types/user"
import {useAllActivities, useAllDepartments} from "hooks"
import {TabHeader} from "components/Tab"

const {TabPane} = Tabs

type Props = KlassappTableProps & {
    studentId?: number
    departmentId?: number
    includesHoldActivities?: boolean
    addNewOpenPage?: boolean
    displayMode?: DisplayMode
}

type ActivitiesTab = {
    departmentId: number
    name: string
    tabKey: string
}

const INITIAL_FILTER = {
    activityDates: null,
    departments: [],
    activities: [],
    campuses: [],
    programs: [],
    staffs: [],
    assignedProfile: null,
    completedBy: [],
    completedDates: null
}

const modules: ActivitiesTab[] = DEPARTMENTS_WITH_ACTIVITIES.map((departmentId) => ({
    departmentId,
    name: BASE_DEPARTMENT_NAME[departmentId],
    tabKey: MAP_DEPARTMENT_ID_2_KEY[departmentId]
}))

function ActivitiesTable(props: Props) {
    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        data,
        allFields,
        fields,
        orderField,
        tableHeaderActions,
        isLoading,
        isLoadedTableFuncs,
        getCurrentPage,
        getCurrentData,
        getCurrentPageSize,
        departmentId,
        displayMode = "normal"
    } = props

    const initiallySelectedTabKey = modules.find((m) => m.departmentId === departmentId)?.tabKey

    const [activeTabKey, setActiveTabKey] = useActiveTab(initiallySelectedTabKey, "activitiesDepartment")

    const selectedDepartmentId = useMemo(
        () => (activeTabKey ? modules.find((m) => m.tabKey === activeTabKey)?.departmentId : null),
        [activeTabKey]
    )

    const {t} = useTranslation(["activities", "common"])
    const history = useHistory()
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const userTimeFormat = model.getUserTimeFormat()
    const dataSeq = React.useRef(0)
    const currentProfilePermissions = useCurrentProfilePermissions()
    const canAddActivity =
        currentProfilePermissions.departmentActivities.hasPermissionsToAdd(selectedDepartmentId) || model.isAdmin()
    const canEditActivity =
        currentProfilePermissions.departmentActivities.hasPermissionsToEdit(selectedDepartmentId) || model.isAdmin()

    const storageData = model.getStorageFilter(FilterKey.ActivityTable)

    const activityPopup = useVisible(false)
    const [formEdit, setFormEdit] = useState(null)
    const [search, setSearch] = useState(!isEmpty(storageData) ? storageData.search : "")
    const [filter, setFilter] = useState(!isEmpty(storageData?.filter) ? storageData.filter : INITIAL_FILTER)
    const [appliedFilter, setAppliedFilter] = useState(filter)
    const [workflowActivity, setWorkflowActivity] = React.useState<StudentActivity | undefined>()
    const showWorkflowDetail = async (activity: StudentActivity) => {
        setWorkflowActivity(activity)
    }
    const isFullDisplay = displayMode === "normal"
    const [advancedFilter, setAdvancedFilter] = useState<"activities" | "workflowActivities" | undefined>()
    const [isExpanded, setExpanded] = useState(false)
    const {departments} = useAllDepartments()
    const {activities: allActivities} = useAllActivities()

    const activities = useMemo(() => {
        let departmentIdToUserActivitiesFrom: number | null = null

        if (selectedDepartmentId) {
            const moduleDepartment = departments.find((dept) => dept.departmentId === departmentId)

            departmentIdToUserActivitiesFrom = moduleDepartment?.useActivitiesFromDepartmentId
        }
        return allActivities
            .filter((activity) => {
                if (!activity.isActive) return false

                if (departmentIdToUserActivitiesFrom && activity.departmentId !== departmentIdToUserActivitiesFrom)
                    return false

                return true
            })
            .map((activity) => pick(activity, ["activityId", "name"]))
    }, [departments, allActivities, selectedDepartmentId])

    const getFields = React.useCallback(() => {
        return [
            "icon",
            t("activities.dateTime"),
            t("activities.aging"),
            t("activities.activity"),
            t("activities.student"),
            t("activities.assignedTo"),
            t("activities.completed")
        ]
    }, [])

    const getFilterMemoryKey = () => {
        return FilterKey.ActivityTable
    }

    const checkIfCanDeleteActivity = (departmentId: number | null = null): boolean => {
        return (
            currentProfilePermissions.departmentActivities.hasPermissionsToDelete(
                selectedDepartmentId || departmentId
            ) || model.isAdmin()
        )
    }

    const canDeleteActivity = useMemo(() => checkIfCanDeleteActivity(), [])

    const getData = React.useCallback(async () => {
        dataSeq.current += 1
        const currentSeq = dataSeq.current
        try {
            dispatch({isLoading: true})
            const storageData = model.getStorageFilter(FilterKey.ActivityTable)
            const page = storageData?.page || getCurrentPage()
            const pageSize = storageData?.pageSize || getCurrentPageSize()
            const params: any = {
                range: {
                    limit: pageSize,
                    offset: (page - 1) * pageSize
                },
                filter: {
                    search,
                    profileId: props.studentId,
                    departmentId: selectedDepartmentId
                        ? selectedDepartmentId
                        : appliedFilter.departments.length
                        ? appliedFilter.departments.map((item) => item.departmentId)
                        : undefined,
                    activityDates: appliedFilter.activityDates ?? undefined,
                    activityTypes: appliedFilter.activities.length
                        ? appliedFilter.activities.map((item) => item.activityId)
                        : undefined,
                    assignedProfileId: appliedFilter.assignedProfile
                        ? appliedFilter.assignedProfile.profileId
                        : undefined,
                    completedBy: appliedFilter.completedBy?.length
                        ? appliedFilter.completedBy.map((item) => item.profileId)
                        : undefined,
                    completedDates: appliedFilter.completedDates ?? undefined,
                    campuses: appliedFilter.campuses.length ? appliedFilter.campuses.map((item) => item.id) : undefined,
                    programs: appliedFilter.programs.length ? appliedFilter.programs.map((item) => item.id) : undefined,
                    staffs: appliedFilter.staffs.length ? appliedFilter.staffs.map((item) => item.profileId) : undefined
                },
                linkedObjects: true,
                includesHoldActivities: isEmpty(advancedFilter) ? props.includesHoldActivities : false,
                workflowActivities: advancedFilter === "workflowActivities",
                advancedFilter
            }
            if (!isEmpty(orderField)) {
                params.sort = {
                    orderBy: orderField.field,
                    orderDir: orderField.order
                }
            }
            const {data: activities, total} = await studentActivitiesService.getActivities(params)

            if (dataSeq.current !== currentSeq) return

            dispatch({
                data: activities.map((activity) => ({
                    ...activity,
                    id: activity.studentActivityId,
                    warning: !!activity.isHoldActivity,
                    expandedRowClass: styles.expandedRow
                })),
                total
            })

            if (total < (page - 1) * pageSize) {
                props.onChangePage(1)
            }
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }, [
        search,
        appliedFilter,
        orderField?.field,
        orderField?.order,
        selectedDepartmentId,
        props.studentId,
        props.includesHoldActivities,
        advancedFilter
    ])

    const onChangeFilter = React.useCallback((key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }, [])

    const onClearFilter = React.useCallback(() => {
        setFilter(INITIAL_FILTER)
        setAppliedFilter(INITIAL_FILTER)
    }, [])

    const onApplyFilter = React.useCallback(() => {
        setAppliedFilter(filter)
        model.updateStorageFilter(FilterKey.ActivityTable, {filter})
    }, [filter])

    const onSearchInput = React.useCallback((search = "") => {
        model.updateStorageFilter(FilterKey.ActivityTable, {search})
        setSearch(search)
    }, [])

    const renderIconHtml = React.useCallback((studentActivity) => {
        const {completed, triggeredWorkflowId, triggeredWorkflow, activity} = studentActivity
        const workflowCompleted = triggeredWorkflow?.status === TriggeredWorkflowStatus.Done
        if (!triggeredWorkflowId && !(activity?.isHoldActivity && !completed)) {
            return null
        }
        return (
            <div
                className={cx(styles.iconTableWrap, {
                    [styles.red]: activity?.isHoldActivity && !completed && !triggeredWorkflowId,
                    [styles.green]:
                        triggeredWorkflowId && (!activity?.isHoldActivity || completed) && workflowCompleted,
                    [styles.white]:
                        triggeredWorkflowId && (!activity?.isHoldActivity || completed) && !workflowCompleted
                })}
                onClick={
                    triggeredWorkflowId
                        ? (e) => {
                              e.stopPropagation()
                              showWorkflowDetail(studentActivity)
                          }
                        : undefined
                }>
                <Icon
                    icon={
                        triggeredWorkflowId
                            ? "WORKFLOW"
                            : activity?.isHoldActivity && !completed
                            ? "ALERT_CIRCLE"
                            : "EDIT_LINE"
                    }
                    color={
                        activity?.isHoldActivity && !completed && !triggeredWorkflowId
                            ? "#DF1642"
                            : triggeredWorkflowId && workflowCompleted
                            ? "#fff"
                            : "#1E90FF"
                    }
                />
            </div>
        )
    }, [])

    const toggleActivity = useMutation(
        async ({
            profileId,
            studentActivityId,
            isCompleted
        }: {
            profileId: number
            studentActivityId: number
            isCompleted: boolean
        }) => {
            const completedDate = new Date()
            const completedBy = BaseService.profileId
            const params = isCompleted
                ? {
                      studentActivityId,
                      completed: true,
                      completedDate,
                      completedBy
                  }
                : {
                      studentActivityId,
                      completed: false
                  }
            await studentActivitiesService.editActivity(params)
            emitEvent(EVENT_STUDENT_ACTIVITY_COMPLETION_TOGGLED, {
                profileId,
                studentActivityId,
                isCompleted,
                completedBy,
                completedDate
            })
        },
        {
            onSuccess: () => getData(),
            onError: (error) => handleError(error)
        }
    )

    const renderCompletedHtml = React.useCallback(
        ({
            studentActivityId,
            profileId,
            completed,
            completedByProfile,
            completedDate,
            triggeredWorkflow,
            initialActivityId
        }) => {
            const workflowCompleted = triggeredWorkflow?.status === TriggeredWorkflowStatus.Done
            if (completed) {
                return (
                    <div className={styles.completedDateWrap}>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                                if (toggleActivity.isLoading) return
                                toggleActivity.mutate({profileId, studentActivityId, isCompleted: false})
                            }}>
                            {toggleActivity.isLoading ? (
                                <CircularSpin size="small" />
                            ) : (
                                <Icon
                                    icon="CHECKMARK_CIRCLE"
                                    color={workflowCompleted && !initialActivityId ? "#18A957" : "#1e90ff"}
                                />
                            )}
                        </div>
                        <div className={styles.completedDateInfo}>
                            <p className={styles.completedDateInfo__item}>{getFullName(completedByProfile)}</p>
                            <p className={styles.completedDateInfo__item}>
                                {completedDate ? moment(completedDate).format(userDateFormat) : ""}
                            </p>
                        </div>
                    </div>
                )
            }
            return (
                <div className={styles.completedDateWrap}>
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            if (toggleActivity.isLoading) return
                            toggleActivity.mutate({profileId, studentActivityId, isCompleted: true})
                        }}>
                        {toggleActivity.isLoading ? (
                            <CircularSpin size="small" />
                        ) : (
                            <Icon icon="CHECK_CIRCLE" color="#939393" />
                        )}
                    </div>
                </div>
            )
        },
        []
    )

    const getColumns = React.useCallback(() => {
        return [
            {
                title: "icon",
                titleHtml: () => null,
                columnIndex: 1,
                field: "iconHtml",
                style: {width: 24, minWidth: 24, textAlign: "right", padding: 0},
                render: (_, record) => renderIconHtml(record)
            },
            {
                title: t("activities.dateTime"),
                columnIndex: 2,
                field: "activityDate",
                sortable: true,
                orderField: "activityDate",
                render: (value) => moment(value).format(userDateTimeFormat)
            },
            {
                title: t("activities.aging"),
                columnIndex: 3,
                field: "activityDate",
                sortable: true,
                orderField: "activityDate",
                render: (value) => (
                    <span title={moment(value).format(userDateTimeFormat)}>{moment(value).fromNow(true)}</span>
                )
            },
            {
                title: t("activities.activity"),
                columnIndex: 4,
                field: "activity.name",
                sortable: true,
                orderField: "activityName",
                style: {lineHeight: "1.2"}
            },
            {
                title: t("activities.student"),
                columnIndex: 5,
                field: "student",
                sortable: true,
                orderField: "studentName",
                render: (value, record: StudentActivity) => {
                    const departmentId = record.departmentSubunit?.departmentId
                    let url = "#"
                    switch (departmentId) {
                        case BaseDepartmentId.Admissions:
                            url = `/admissions/student/${record.profileId}?tab=${ADMISSION_STUDENT_TABS.ADMISSIONS}&subtab=${AdmissionStudentAdmissionSubtab.Activities}`
                            break
                        case BaseDepartmentId.FinancialAid:
                            url = `/financial-aid/student/${record.profileId}?tab=${FinancialAidStudentTabs.FinancialAid}&subtab=${FinAidStudentFinAidSubtab.Activities}`
                            break
                        case BaseDepartmentId.Academics:
                            url = `/academics/registrar/student/${record.profileId}?tab=${RegistrarStudentTab.Workspace}`
                            break
                        case BaseDepartmentId.AcademicAffair:
                            url = `/academic-affairs/student/${record.profileId}?tab=${StudentServicesStudentTab.Home}&departmentId=${BaseDepartmentId.AcademicAffair}`
                            break
                        case BaseDepartmentId.StudentServices:
                            url = `/student-services/student/${record.profileId}?tab=${StudentServicesStudentTab.Home}&departmentId=${BaseDepartmentId.StudentServices}`
                            break
                    }
                    return <Link to={url}>{getFullName(value)}</Link>
                }
            },
            {
                title: t("activities.assignedTo"),
                columnIndex: 6,
                field: "assignedProfiles",
                style: {lineHeight: "1.2"},
                render: (value) =>
                    (value ?? []).map((profile) => (
                        <div key={profile.profileId}>
                            {getFullName(profile)} {profile.title ? `(${profile.title})` : ""}
                        </div>
                    ))
            },
            {
                title: t("activities.description"),
                columnIndex: 7,
                field: "description",
                sortable: true,
                orderField: "description"
            },
            {
                title: t("activities.lastEdited"),
                columnIndex: 8,
                field: "updatedAt",
                sortable: true,
                orderField: "updatedAt",
                render: (value) => moment(value).format(userDateTimeFormat)
            },
            {
                title: t("activities.completed"),
                columnIndex: 9,
                field: "completedDate",
                sortable: true,
                orderField: "completedDate",
                render: (_, record) => renderCompletedHtml(record)
            }
        ]
    }, [renderIconHtml, renderCompletedHtml, props.studentId, props.departmentId])

    const onClickDelete = React.useCallback(
        async (data) => {
            try {
                await studentActivitiesService.deleteActivity({studentActivityId: [data.studentActivityId]})
                await getData()
                toastSuccess(t("common:activityPopup.deleteSuccess"))
            } catch (error: any) {
                toastError(error.message)
            }
        },
        [getData]
    )

    const onClickClose = React.useCallback(() => {
        setFormEdit(null)
        activityPopup.close()
    }, [])

    const onClickDeleteMulti = React.useCallback(async () => {
        const data = getCurrentData()
        const studentActivityIds = data.filter((activity) => activity.isChecked).map((activity) => activity.id)
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false})
            await studentActivitiesService.deleteActivity({studentActivityId: studentActivityIds})
            await getData()
        } catch (error: any) {
            toastError(error.message)
        } finally {
            dispatch({isLoading: false})
        }
    }, [getData])

    const onClickEdit = React.useCallback(
        (data) => {
            const departmentIdByActivity = data.departmentSubunit.departmentId
            const canEditActivityByDepartment =
                currentProfilePermissions.departmentActivities.hasPermissionsToAdd(departmentIdByActivity) ||
                model.isAdmin()
            if (!canEditActivityByDepartment) return
            setFormEdit({...formEdit, ...data})
            activityPopup.open()
        },
        [canEditActivity, formEdit]
    )

    const handleRowClick = React.useCallback(
        (record: StudentActivity) => {
            onClickEdit(record)
        },
        [onClickEdit]
    )

    const handleCanDeleteRow = (data) => {
        props.onUpdateRowData(data, data)
    }

    const getTableHeaderActions = React.useCallback(
        (isShowDuplicateBtn = true, checkedData = []) => {
            const actions = [
                !isFullDisplay
                    ? null
                    : {
                          title: t(isExpanded ? "common:action.collapseAll" : "common:action.expandAll"),
                          icon: "EXPAND",
                          action: () => setExpanded((prev) => !prev)
                      }
            ]
            if (checkedData?.length) {
                if (checkIfCanDeleteActivity(checkedData[0]?.departmentSubunit?.departmentId)) {
                    actions.push({
                        title: t("common:action.delete"),
                        icon: "DELETE",
                        action: () => onClickDeleteMulti()
                    })
                }
            }
            return actions.filter(Boolean)
        },
        [onClickDeleteMulti, isFullDisplay, isExpanded]
    )

    useEffect(() => {
        dispatch({isClassComponent: false, isHideMenuActions: true})
        dispatchFunc([
            {key: "getPageTitle", func: () => t("activities.title")},
            {key: "getFilterMemoryKey", func: getFilterMemoryKey},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: handleRowClick},
            {key: "getTableHeaderActions", func: getTableHeaderActions}
        ])
    }, [getColumns, getTableHeaderActions, handleRowClick, onClickDelete, onClickEdit])

    useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [
        isLoadedTableFuncs,
        page,
        pageSize,
        orderField?.field,
        orderField?.order,
        selectedDepartmentId,
        props.studentId,
        search,
        appliedFilter,
        advancedFilter
    ])

    const getStudentDueText = (studentActivity: StudentActivity) => {
        return studentActivity.studentDue.unit === "custom"
            ? `at ${moment(studentActivity.studentDue.time).format(userDateTimeFormat)}`
            : `in ${studentActivity.studentDue.value} ${studentActivity.studentDue.unit} from ${moment(
                  studentActivity.updatedAt
              ).format(userDateTimeFormat)}` +
                  (studentActivity.studentDueDate
                      ? ` (at ${moment(studentActivity.studentDueDate).format(userDateTimeFormat)})`
                      : "")
    }

    const renderItemsInRow = () => {
        if (isLoading) {
            return (
                <div className={styles.rowLoading}>
                    <Spin />
                </div>
            )
        }

        return (
            <>
                {data.map((item: StudentActivity) => {
                    return (
                        <div key={item.studentActivityId} className={styles.rowWrap}>
                            <div className={styles.rowTitleWrap}>
                                <div>{renderIconHtml(item)}</div>
                                <div>{item.activity?.name || ""}</div>
                                <div className="flex-1" />
                                {canDeleteActivity && (
                                    <div
                                        className={cx(styles.activityIcon, "cursor-pointer")}
                                        onClick={() => onClickDelete(item)}>
                                        <Icon icon="DELETE" />
                                    </div>
                                )}

                                <div
                                    className={cx(styles.activityIcon, "cursor-pointer")}
                                    onClick={() => onClickEdit(item)}>
                                    <Icon icon="EDIT_LINE" />
                                </div>
                            </div>
                            <div className={styles.rowDescription}>{item.description}</div>
                            <div className={styles.rowFieldWrap}>
                                <div className={styles.rowFieldTitle}>Date & time</div>
                                <div className={styles.rowFieldValue}>
                                    {moment(item.activityDate).format(userDateTimeFormat)}
                                </div>
                            </div>
                            <div className={styles.rowFieldWrap}>
                                <div className={styles.rowFieldTitle}>Assigned To</div>
                                <div className={styles.rowFieldValue}>
                                    {(item.assignedProfiles ?? []).map((profile) => (
                                        <div key={profile.profileId}>
                                            {getFullName(profile)} {profile.title ? `(${profile.title})` : ""}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.rowFieldWrap}>
                                <div className={styles.rowFieldTitle}>Completed</div>
                                <div
                                    className={cx(styles.rowFieldValue, styles.rowStatusValue, {
                                        [styles.rowCompletedValue]: item.completed
                                    })}>
                                    {item.completed ? (
                                        <>
                                            <div
                                                className={styles.rowStatusIcon}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (toggleActivity.isLoading) return
                                                    toggleActivity.mutate({
                                                        profileId: item.profileId,
                                                        studentActivityId: item.studentActivityId,
                                                        isCompleted: false
                                                    })
                                                }}>
                                                {toggleActivity.isLoading ? (
                                                    <CircularSpin size="small" />
                                                ) : (
                                                    <Icon
                                                        icon="CHECKMARK_CIRCLE"
                                                        color="#18A957"
                                                        className={styles.rowActivityIcon}
                                                    />
                                                )}
                                            </div>
                                            <div className={styles.rowStatusValue}>
                                                {item.completedDate
                                                    ? moment(item.completedDate).format(userDateTimeFormat)
                                                    : "-"}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className={styles.rowStatusIcon}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (toggleActivity.isLoading) return
                                                    toggleActivity.mutate({
                                                        profileId: item.profileId,
                                                        studentActivityId: item.studentActivityId,
                                                        isCompleted: true
                                                    })
                                                }}>
                                                {toggleActivity.isLoading ? (
                                                    <CircularSpin size="small" />
                                                ) : (
                                                    <Icon
                                                        icon="CHECK_CIRCLE"
                                                        color="#939393"
                                                        className={styles.rowActivityIcon}
                                                    />
                                                )}
                                            </div>
                                            <div className={styles.rowStatusValue}>Not completed</div>
                                        </>
                                    )}
                                </div>
                            </div>
                            {!!item.studentDue && (
                                <div className={styles.rowFieldWrap}>
                                    <div className={styles.rowFieldTitle}>Set due alert</div>
                                    <Tooltip placement="left" title={getStudentDueText(item)}>
                                        <div className={styles.rowFieldValue}>
                                            {item.studentDue.unit === "custom"
                                                ? `at ${moment(item.studentDue.time).format(userDateTimeFormat)}`
                                                : `in ${item.studentDue.value} ${item.studentDue.unit} ${
                                                      item.studentDue.time
                                                          ? `at ${moment(item.studentDue.time).format(userTimeFormat)}`
                                                          : ""
                                                  }`}
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )
                })}
            </>
        )
    }

    const handleActivityUpserted = React.useCallback(
        (activity: StudentActivity) => {
            getData()
        },
        [props.studentId, getData]
    )

    const renderFilter = () => {
        return (
            <BaseNewFilter
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={onSearchInput}
                searchValue={search}
                showRightFilter={isFullDisplay}
                renderRightFilter={() =>
                    model.isStaffOrAdmin() &&
                    canAddActivity && (
                        <div className={styles.createTaskWrap}>
                            <BaseButton
                                title={t("common:applicationProgress.add")}
                                icon={<Icon className={styles.plusIcon} icon="PLUS" color="#FFF" />}
                                onClick={() => {
                                    if (props.addNewOpenPage) history.push("/activities/new")
                                    else activityPopup.open()
                                }}
                            />
                        </div>
                    )
                }>
                <Row gutter={[24, 24]}>
                    <Col span={isFullDisplay ? 12 : 24}>
                        <BaseRangePicker
                            placeholder={["Activity Date From", "To"]}
                            value={
                                filter.activityDates
                                    ? [moment(filter.activityDates[0]), moment(filter.activityDates[1])]
                                    : null
                            }
                            onChange={(value) => onChangeFilter("activityDates", value)}
                        />
                    </Col>
                    {!props.departmentId && (
                        <Col span={isFullDisplay ? 12 : 24}>
                            <KlassDropdown
                                isMulti
                                options={model.departments}
                                labelKey="name"
                                valueKey="departmentId"
                                value={filter.departments}
                                onChange={(options) => onChangeFilter("departments", options ?? [])}
                                placeholder={t("activities.department")}
                            />
                        </Col>
                    )}
                    <Col span={isFullDisplay ? 12 : 24}>
                        <KlassDropdown
                            isMulti
                            options={activities}
                            labelKey="name"
                            valueKey="activityId"
                            value={filter.activities}
                            onChange={(options) => onChangeFilter("activities", options ?? [])}
                            placeholder={t("activities.activityType")}
                        />
                    </Col>
                    {!props.departmentId && (
                        <>
                            <Col span={isFullDisplay ? 12 : 24}>
                                <CampusSelect
                                    isMulti
                                    placeholder={t("activities.campus")}
                                    value={filter.campuses}
                                    onChange={(options: Campus.Campus[]) => onChangeFilter("campuses", options ?? [])}
                                />
                            </Col>
                            <Col span={isFullDisplay ? 12 : 24}>
                                <ProgramSelect
                                    isMulti
                                    isActive
                                    placeholder={t("activities.program")}
                                    value={filter.programs}
                                    onChange={(options: Major.Major[]) => onChangeFilter("programs", options ?? [])}
                                    getOptionLabel={(option) => `${option.name} (${option.code})`}
                                />
                            </Col>
                        </>
                    )}
                    <Col span={isFullDisplay ? 12 : 24}>
                        <UserSelect
                            type={Auth.UserProfileType.Staff}
                            isClearable
                            value={filter.assignedProfile}
                            onChange={(option) => onChangeFilter("assignedProfile", option)}
                            placeholder={t("activities.assignedProfile")}
                        />
                    </Col>
                    <Col span={isFullDisplay ? 12 : 24}>
                        <UserSelect
                            type={Auth.UserProfileType.Staff}
                            isMulti
                            isClearable
                            value={filter.completedBy}
                            onChange={(options?: UserAutocomplete[]) => onChangeFilter("completedBy", options ?? [])}
                            placeholder={t("activities.completedBy")}
                        />
                    </Col>
                    <Col span={isFullDisplay ? 12 : 24}>
                        <BaseRangePicker
                            placeholder={["Completion Date From", "To"]}
                            value={
                                filter.completedDates
                                    ? [moment(filter.completedDates[0]), moment(filter.completedDates[1])]
                                    : null
                            }
                            onChange={(value) => onChangeFilter("completedDates", value)}
                        />
                    </Col>
                </Row>
            </BaseNewFilter>
        )
    }

    const renderContent = (departmentId?: BaseDepartmentId) => {
        return (
            <div>
                {renderFilter()}
                {isFullDisplay && (
                    <ActivityStats
                        isVisible={activityPopup.isVisible}
                        departmentId={departmentId}
                        studentId={props.studentId}
                        advancedFilter={advancedFilter}
                        setAdvancedFilter={setAdvancedFilter}
                    />
                )}

                <KlassappTableHeader
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    isShowAction
                    actions={tableHeaderActions}
                    onChangePage={props.onChangePage}
                    onChangeRowPerPage={props.onChangeRowPerPage}
                    displayMode={displayMode}
                    fields={fields}
                    unselectableFields={["icon"]}
                    allFields={allFields}
                    onChangeFields={props.onChangeFields}
                    onChangeAllFields={props.onChangeAllFields}
                    onDraggableColumn={props.onDraggableColumn}
                />
                {isFullDisplay ? (
                    <KlassappTable
                        columns={columns}
                        data={data}
                        isLoading={isLoading}
                        menuActions={[]}
                        fields={fields}
                        allFields={allFields}
                        unselectableFields={["icon"]}
                        orderField={orderField}
                        isShowCheckedColumn
                        onClickRowItem={props.onClickRowItem}
                        onToggleCheckBox={handleCanDeleteRow}
                        onChangeFields={props.onChangeFields}
                        onUpdateRowData={props.onUpdateRowData}
                        onUpdateTableData={props.onUpdateTableData}
                        onDraggableColumn={props.onDraggableColumn}
                        onChangeAllFields={props.onChangeAllFields}
                        onClickSortColumn={props.onClickSortColumn}
                        expandable={{
                            isExpanded,
                            rowExpandable: () => true,
                            expandedRowRender: (record: StudentActivity, expanded: boolean) =>
                                !expanded ? null : !!record.workflowActivities?.length ? (
                                    <WorkflowActivitiesTable
                                        activity={record}
                                        studentId={props.studentId}
                                        departmentId={selectedDepartmentId}
                                        getData={getData}
                                        parentFields={fields}
                                        parentAllFields={allFields}
                                    />
                                ) : (
                                    <div className="px-8 py-8">
                                        <div className="mb-4">
                                            <b>Department</b>: {record.departmentSubunit?.name}
                                        </div>
                                        <div className="mb-4">
                                            <b>Notify</b>:{" "}
                                            {record.notifyStaffRole
                                                ? record.notifyStaffRoleIds.join(", ")
                                                : record.notifyStaffProfiles.map(getFullName).join(", ")}
                                        </div>
                                        {record.completed && (
                                            <div className="mb-4">
                                                <b>Completed</b>: {getFullName(record.completedByProfile)} (
                                                {moment(record.completedDate).format(userDateTimeFormat)})
                                            </div>
                                        )}
                                        {record.studentDue && (
                                            <div className="mb-4">
                                                <b>Set due alert</b>: {getStudentDueText(record)}
                                            </div>
                                        )}
                                        {!!record.triggeredWorkflow && (
                                            <div className="mb-4">
                                                <b>Initiate worklow</b>: {record.triggeredWorkflow?.workflow?.name}
                                            </div>
                                        )}
                                        <div className="mb-4">
                                            <b>Last edited</b>: {moment(record.updatedAt).format(userDateTimeFormat)}
                                        </div>
                                        <div className="mb-4 pre-wrap">
                                            <b>Description</b>:<br />
                                            {record.description}
                                        </div>
                                    </div>
                                )
                        }}
                        tableClassName={styles.activitiesTable}
                        classNameActionMenu={styles.tableActionMenu}
                    />
                ) : (
                    renderItemsInRow()
                )}
                {!!activityPopup.isVisible && (
                    <ActivityPopup
                        isShow={activityPopup.isVisible}
                        onClose={onClickClose}
                        studentId={props.studentId}
                        departmentId={selectedDepartmentId}
                        setReload={handleActivityUpserted}
                        formEditData={formEdit}
                    />
                )}

                <ProcessWorkflowModal
                    visible={!!workflowActivity}
                    onClose={() => setWorkflowActivity(undefined)}
                    activity={workflowActivity}
                />
            </div>
        )
    }

    const renderBody = (module: ActivitiesTab) => {
        const isActive = module.departmentId === selectedDepartmentId

        return (
            <TabPane tab={<TabHeader title={module.name} />} key={module.tabKey}>
                {isActive && renderContent(module.departmentId)}
            </TabPane>
        )
    }

    if (departmentId) {
        return (
            <Tabs className="fullwidth klassSubTab" activeKey={activeTabKey} onChange={setActiveTabKey}>
                {modules.map((module) => renderBody(module))}
            </Tabs>
        )
    }

    return renderContent()
}

const WrappedActivitiesTable = KlassappTableHOC(ActivitiesTable)

export default WrappedActivitiesTable
