import {get} from "lodash"
import BaseService from "./Base"

class SmsMsService extends BaseService {
    constructor() {
        super(process.env.REACT_APP_SMS_API_BASE_URL)
    }
    async getLogs(data, params = {}) {
        const response = await this.post(`/v1/sms/logs`, data, params)
        return get(response, "data", [])
    }
}

export default SmsMsService
