import {get} from "lodash"
import BaseService from "./Base"
import {Auth} from "types/auth"

export default class StudentServiceV3 extends BaseService {
    public async getAll(params = {}): Promise<{
        total: number
        data: Auth.DepartmentStudent[]
        profilesCountByStatus?: Record<number, number>
        stats: {
            upcoming: number
            inProgress: number
            completed: number
        }
    }> {
        const response = await this.post("/v3/students/externship-course/list", params)
        return get(response, "data", null)
    }
}
