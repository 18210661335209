/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {Tabs} from "antd"
import {get} from "lodash"
import {useTranslation} from "react-i18next"
import {useHistory, useLocation, useParams} from "react-router-dom"
import * as queryString from "query-string"
import {Screen} from "components/ui"
import {useActiveTab, useCurrentProfilePermissions, useModel} from "hooks"
import {isirService, studentService} from "services"
import {getFullName, handleError} from "helpers"
import {Tasks} from "sections/shared/student"
import {Documents} from "sections/Documents"
import {Permissions} from "types/permission"
import {FinancialAidStudentTab, FinAidPlan, ISIRReport, Verification, FinanicalAidStudentTabNotesTab} from "./parts"
import {BaseDepartmentId} from "types/departments"
import {FinancialAidStaffTabs} from "../FinancialAidStaff"
import {Auth} from "types/auth"
import {routing} from "helpers/routing"
import {TabHeader} from "components/Tab"
import {AdvisorTitle, FinAidPackageInfo, ModuleStudentHeader} from "uiKit"
import RefundTab from "../staffParts/Refund/RefundTab"
import FinAidAward from "./parts/FinAidAward"
import {BaseLoading} from "components"

const {TabPane} = Tabs

export enum FinancialAidStudentTabs {
    FinancialAid = "financial-aid",
    FinAidPlan = "fin-aid-plan",
    FinAidAward = "fin-aid-award",
    Refund = "refund",
    Documents = "documents",
    ISIR = "isir",
    Verification = "verification",
    Tasks = "tasks",
    Notices = "notices"
}

export default function FinancialAidStudent(props) {
    const model = useModel()
    const history = useHistory<{backUrl?: string}>()
    const location = useLocation()
    const [backUrl, setBackUrl] = useState<string>()
    const departmentId = BaseDepartmentId.FinancialAid
    const [activeTabKey, onChangeTab] = useActiveTab(FinancialAidStudentTabs.FinancialAid)
    const {t} = useTranslation(["financialAid"])
    const currentProfilePermissions = useCurrentProfilePermissions()
    const [student, setStudent] = useState<Auth.DepartmentStudent | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams<{profileId: string}>()
    const profileId = +params.profileId

    const queryParams: {isirCode?: string} = queryString.parse(history.location.search)
    useEffect(() => {
        if (location.state?.["backUrlFromPage"]) {
            setBackUrl(location.state?.["backUrlFromPage"])
        }
    }, [location])
    const getStudentDetail = async () => {
        try {
            setIsLoading(true)

            let student: any = null

            if (profileId) {
                const params: any = {
                    filter: {
                        profileIds: [profileId],
                        departmentId
                    }
                }
                if ((queryParams?.isirCode ?? "").trim() !== "") {
                    params.filter = {
                        ...params.filter,
                        includeArchive: true
                    }
                }
                const {data} = await studentService.getDepartmentStudents(params)

                if (data[0]) {
                    student = data[0]
                }
            } else if ((queryParams?.isirCode ?? "").trim() !== "") {
                const isirCode: string = queryParams.isirCode.trim()

                const {data: isirs} = await isirService.getList({
                    filter: {
                        isirCode
                    }
                })

                const newISIRs = isirs.map((isir) => {
                    let isirJSON = JSON.parse(isir.isirJson)

                    return {
                        id: isir.id,
                        isirCode: isir.isirCode,
                        isDefault: isir.isDefault,
                        schoolCode: isirJSON.studentHighSchoolCode,
                        firstName: isirJSON.studentFirstName,
                        lastName: isirJSON.studentLastName,
                        ssn: isirJSON.studentSSN,
                        email: isirJSON.studentEmailAddress
                    }
                })

                student = newISIRs.find((isir) => isir.isDefault) ?? newISIRs[0]
            }

            setStudent(student)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getStudentDetail()
    }, [])

    const hideTabsInProduction = process.env.REACT_APP_NODE_ENV === "production"
    const previousTab: string | null = !model.isStudent()
        ? backUrl ?? routing.finAid.staff.index(FinancialAidStaffTabs.Students)
        : null

    if (!student) {
        return (
            <Screen
                htmlTitle="Financial Aid Student"
                header={{
                    title: getFullName(student) || "-",
                    code: get(student, "customUserId", ""),
                    backUrl: previousTab
                }}>
                {isLoading ? <BaseLoading isShow /> : <p>Information not found</p>}
            </Screen>
        )
    }

    return (
        <Screen
            htmlTitle="Financial Aid Student"
            header={{
                title: getFullName(student) || "-",
                code: get(student, "customProfileId", ""),
                backUrl: previousTab
            }}
            rightPanel={{
                type: "student",
                data: {
                    student,
                    departmentId,
                    advisorTitle: AdvisorTitle.finAidAdvisors,
                    renderDepartmentInfo: (props) => <FinAidPackageInfo student={student} isPanel {...props} />
                }
            }}>
            <ModuleStudentHeader
                activeModule={Auth.ModuleNavigation.FinancialAid}
                userId={student?.userId}
                customUserId={student?.customUserId}
                profileId={profileId}
                isBackButtonActive={!!previousTab}
            />
            <Tabs className="fullwidth klassTab" activeKey={activeTabKey} onChange={onChangeTab} destroyInactiveTabPane>
                {currentProfilePermissions.hasPermissions({
                    staff: [Permissions.Staff.FinancialAid.Students.FinancialAidStudentDetail.View],
                    student: [Permissions.Student.FinancialAid.View],
                    others: [Permissions.Others.FinancialAid.View]
                }) && (
                    <TabPane
                        tab={<TabHeader title={t("financialAidStudent.financialAid")} />}
                        key={FinancialAidStudentTabs.FinancialAid}>
                        <FinancialAidStudentTab studentId={profileId} departmentId={departmentId} />
                    </TabPane>
                )}

                <TabPane tab={<TabHeader title={t("financialAidStaff.isir")} />} key={FinancialAidStudentTabs.ISIR}>
                    <ISIRReport studentId={profileId} departmentId={departmentId} {...props} />
                </TabPane>

                <TabPane
                    tab={<TabHeader title={t("financialAidStudent.verification")} />}
                    key={FinancialAidStudentTabs.Verification}>
                    <Verification studentId={profileId} {...props} />
                </TabPane>

                <TabPane
                    tab={<TabHeader title={t("financialAidStudent.finAidPlan")} />}
                    key={FinancialAidStudentTabs.FinAidPlan}>
                    <FinAidPlan studentId={profileId} departmentId={departmentId} />
                </TabPane>

                <TabPane
                    tab={<TabHeader title={t("financialAidStudent.finAidAward")} />}
                    key={FinancialAidStudentTabs.FinAidAward}>
                    <FinAidAward studentId={profileId} departmentId={departmentId} />
                </TabPane>

                <TabPane
                    tab={<TabHeader title={t("financialAidStudent.notices")} />}
                    key={FinancialAidStudentTabs.Notices}>
                    <FinanicalAidStudentTabNotesTab studentId={profileId} {...props} />
                </TabPane>

                {!hideTabsInProduction && (
                    <TabPane
                        tab={<TabHeader title={t("financialAidStudent.refund")} />}
                        key={FinancialAidStudentTabs.Refund}>
                        <RefundTab />
                    </TabPane>
                )}

                <TabPane tab={<TabHeader title={t("common:tasks.title")} />} key={FinancialAidStudentTabs.Tasks}>
                    <Tasks
                        studentId={profileId}
                        departmentId={BaseDepartmentId.FinancialAid}
                        history={history}
                        model={model}
                        fromPage={{backUrl: `/financial-aid/student/${profileId}?tab=tasks`}}
                    />
                </TabPane>

                <TabPane
                    tab={<TabHeader title={t("financialAidStudent.documents")} />}
                    key={FinancialAidStudentTabs.Documents}>
                    <Documents
                        userId={student?.userId}
                        backUrl={`/financial-aid/student/${profileId}?tab=${FinancialAidStudentTabs.Documents}`}
                        departmentId={departmentId}
                        {...props}
                    />
                </TabPane>
            </Tabs>
        </Screen>
    )
}
