import {v4 as uuid} from "uuid"
import {Auth} from "types/auth"
import {ActivityStatus, Timestamps} from "./common"
import {RateSheetRateDetails, RateType} from "./rate-sheets"
import {Major} from "./major"
import {Campus} from "./campus"

// COA Templates

export enum CoaType {
    Clock = "clock",
    Credit = "credit"
}

export type CoaTemplateBaseDetails<RateT = number, LastUpdatedByT = number> = {
    coaTemplateId: number
    rateSheetId: number
    programIds?: number[]
    campusIds?: number[]
    code: string
    name: string
    type: CoaType
    status: ActivityStatus
    numberOfTerms: number
    monthsInAcademicYear: number
    termsNumberOfCreditsOrHours: number[]
    lastUpdatedBy: LastUpdatedByT
    rates: RateT[]

    // populated
    programs?: Major.Major[]
    campuses?: Campus.Campus[]
}

export type CoaTemplateListItem = CoaTemplateBaseDetails<number, Auth.UserShortDetails> & Timestamps

export type CoaTemplateDetails = CoaTemplateBaseDetails<CoaTemplateRateDetails, number> & Timestamps

export type CoaTemplateRateBaseDetails = {
    coaTemplateRateId: number
    coaTemplateId: number
    rateId: number
    type: RateType
    termRates: number[]

    // populated
    rate?: RateSheetRateDetails
}

export type CoaTemplateRateDetails = CoaTemplateRateBaseDetails & Timestamps

export type EditableCoaRate = {
    editableCoaRateId: number | string
    rateId: number | null
    rate?: RateSheetRateDetails // populated
    type: RateType
    termRates: number[]
    action?: "create" | "edit" | "delete"
}

export type EditableCoaTemplate = Omit<CoaTemplateBaseDetails<EditableCoaRate>, "coaTemplateId" | "lastUpdatedBy"> & {
    rateSheetId: number | null
    programIds?: number[]
}

export type EditCoaTemplateRatePayload =
    | ({action: "create"} & Omit<CoaTemplateRateBaseDetails, "coaTemplateRateId" | "coaTemplateId">)
    | ({action: "edit"} & Omit<CoaTemplateRateBaseDetails, "coaTemplateId">)
    | {action: "delete"; coaTemplateRateId: number}

export type EditCoaTemplatePayload = Omit<CoaTemplateBaseDetails<EditCoaTemplateRatePayload>, "lastUpdatedBy">

export type CreateCoaTemplateRatePayload = Omit<
    CoaTemplateRateBaseDetails,
    "coaTemplateRateId" | "coaTemplateId" | "coaTemplateId"
>
export type CreateCoaTemplatePayload = Omit<
    CoaTemplateBaseDetails<CreateCoaTemplateRatePayload>,
    "coaTemplateId" | "lastUpdatedBy"
>

export const BASE_EDITABLE_COA_TEMPLATE: EditableCoaTemplate = {
    status: ActivityStatus.Active,
    rateSheetId: null,
    code: "",
    name: "",
    type: CoaType.Credit,
    numberOfTerms: 2,
    monthsInAcademicYear: 9,
    termsNumberOfCreditsOrHours: [0, 0],
    rates: [
        {
            editableCoaRateId: uuid(),
            rateId: null,
            type: RateType.Program,
            termRates: [],
            action: "create"
        }
    ]
}

export enum TermProgramCoaStatus {
    Configured = "configured",
    Approved = "approved"
}

export type TermProgramCoaBaseDetails<RateT = number> = {
    termProgramId: number
    rateSheetId: number
    coaTemplateId: number
    type: CoaType
    numberOfTerms: number
    status?: TermProgramCoaStatus
    termsNumberOfCreditsOrHours: number[]
    rates: RateT[]
}

export type TermProgramCoaDetails = TermProgramCoaBaseDetails<TermProgramCoaRateDetails> & Timestamps

export type TermProgramCoaRateBaseDetails = {
    rateId: number
    termProgramId: number
    name: string
    type: RateType
    termRates: number[]
    isInstitutionalCharge: false
}

export type TermProgramCoaRateDetails = TermProgramCoaRateBaseDetails & Timestamps

export type EditableTermProgramCoa = Omit<
    TermProgramCoaBaseDetails<EditableCoaRate>,
    "termProgramId" | "coaTemplateId"
> & {
    rateSheetId: number | null
    coaTemplateId: number | null
}

export type TermProgramCoaRatePayloadForCreate = {
    action: "create"
    name: string
    type: RateType
    termRates: number[]
    isInstitutionalCharge: false
}

export type TermProgramCoaRatePayloadForEdit = {
    action: "edit"
    rateId: number
    name: string
    type: RateType
    termRates: number[]
    isInstitutionalCharge: false
}

export type TermProgramCoaRatePayloadForDelete = {
    action: "delete"
    rateId: number
}

export type TermProgramCoaRatePayloadForUpdate =
    | TermProgramCoaRatePayloadForCreate
    | TermProgramCoaRatePayloadForEdit
    | TermProgramCoaRatePayloadForDelete

export type TermProgramCoaPayloadForUpdate = {
    termProgramId: number
    coaTemplateId: number
    rateSheetId: number
    type: CoaType
    numberOfTerms: number
    termsNumberOfCreditsOrHours: number[]
    rates: TermProgramCoaRatePayloadForUpdate[]
}

export const BASE_EDITABLE_TERM_PROGRAM_COA: EditableTermProgramCoa = {
    coaTemplateId: null,
    rateSheetId: null,
    type: CoaType.Credit,
    numberOfTerms: 2,
    termsNumberOfCreditsOrHours: [0, 0],
    rates: [
        {
            editableCoaRateId: uuid(),
            rateId: null,
            type: RateType.Program,
            termRates: [],
            action: "create"
        }
    ]
}

export type ListTermProgramCoaPayload = Partial<{
    filter: Partial<{
        termProgramIds: number[]
        termIds: number[]
        majorIds: number[]
    }>
    range: {
        page: number
        pageSize: number
    }
    sort: {
        orderBy: string
        orderDir: "asc" | "desc" | "ASC" | "DESC"
    }
    linkedEntities?: boolean
}>
