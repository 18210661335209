/* eslint-disable react-hooks/exhaustive-deps */
import groupBy from "lodash/groupBy"
import React, {useEffect, useMemo} from "react"
import {useTranslation} from "react-i18next"
import {DepartmentSubunit, getSubunitName} from "types/departments"
import {KlassDropdown, KlassDropdownAsyncProps} from "../Select"
import {useAllDepartmentSubunits} from "hooks"
import {pick} from "lodash"

type Props = Partial<KlassDropdownAsyncProps> & {
    departmentIds?: number[]
    subunitIds?: number[]
    setFirstAsPreSelected?: boolean
    shortly?: boolean
}

const DepartmentSubunitSelect: React.FC<Props> = ({
    departmentIds,
    subunitIds,
    setFirstAsPreSelected = false,
    shortly,
    ...props
}) => {
    const {t} = useTranslation(["common"])
    const {subunits} = useAllDepartmentSubunits()
    const optionGroups = useMemo(() => {
        const filtered = subunits.filter((subunit) => {
            if (!subunit.departmentId) return false
            if (departmentIds?.length) {
                if (!departmentIds.includes(subunit.departmentId)) return false
            }
            if (subunitIds?.length) {
                if (!subunitIds.includes(subunit.subunitId)) return false
            }
            return true
        })

        return Object.entries(groupBy(filtered, "departmentId")).map(([departmentId, subunits]) => ({
            label: subunits[0]?.department?.name,
            options: shortly ? subunits.map((subunit) => pick(subunit, ["subunitId", "name", "code"])) : subunits
        }))
    }, [subunits, departmentIds, subunitIds, shortly])

    useEffect(() => {
        if (setFirstAsPreSelected && !props.value) {
            const option = optionGroups?.[0]?.options?.[0]
            props.onChange?.(option)
        }
    }, [optionGroups, setFirstAsPreSelected])

    return (
        <KlassDropdown
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            options={optionGroups}
            valueKey="subunitId"
            labelKey="name"
            getOptionLabel={(option: DepartmentSubunit) => getSubunitName(option)}
        />
    )
}

export default DepartmentSubunitSelect
