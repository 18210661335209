/* eslint-disable react-hooks/exhaustive-deps */
import React, {createContext, useCallback, useEffect, useState} from "react"
import {useVisible, UseVisibleArgs} from "hooks"
import styles from "sections/StudentAccount/Payment/PaymentModal/PaymentModal.module.css"
import {PaymentView} from "sections/StudentAccount/Payment/PaymentModal/PaymentView"
import {BasePopup} from "components"
import PaymentProcessingModal from "sections/StudentAccount/Payment/PaymentProcessingModal"
import {PaymentStatus} from "sections/StudentAccount/Payment/PaymentProcessingModal/PaymentProcessingModal"
import qs from "query-string"
import {coreSocket, studentPaymentService} from "services"
import {CurrencyExchangeRates, StudentPayment} from "types/student-account/student-payment"
import {SocketEvent} from "helpers/socket"
import {useHistory, useLocation} from "react-router-dom"
import {omit} from "lodash"

type ContextProps = {
    paymentPopup: UseVisibleArgs
    studentId: number
    setStudentId: React.Dispatch<React.SetStateAction<number>>
    setPaymentStatus: React.Dispatch<React.SetStateAction<PaymentStatus | undefined>>
    setPaymentError: React.Dispatch<React.SetStateAction<string | undefined>>
    currencyExchangeRates: CurrencyExchangeRates
}

export const StudentPaymentContext = createContext<ContextProps>({} as ContextProps)

type PaymentResponse = {
    paymentId: string
    // Stripe https://stripe.com/docs/payments/quickstart#show-status-message
    payment_intent: string // e.g. pi_3NC431G5PX1iJhko1OugAL82
    payment_intent_client_secret: string // e.g. pi_3NC431G5PX1iJhko1OugAL82_secret_T0e6qzcB5ToHMprqjt5UAQ5NI
    redirect_status: "succeeded"
}

export const StudentPaymentProvider: React.FC<React.PropsWithChildren<{isLoggedIn: boolean}>> = ({
    children,
    isLoggedIn
}) => {
    const paymentPopup = useVisible(false)
    const [studentId, setStudentId] = React.useState<number | undefined>()
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | undefined>()
    const [paymentError, setPaymentError] = useState<string | undefined>()
    const [currencyExchangeRates, setCurrencyExchangeRates] = React.useState<CurrencyExchangeRates>({})

    const location = useLocation()
    const history = useHistory()
    const paymentResponse = React.useMemo(() => qs.parse(location.search) as PaymentResponse, [location.key])

    useEffect(() => {
        ;(async function checkPaymentIntentStatus() {
            if (!paymentResponse.paymentId) return
            setPaymentStatus(undefined)
            const paymentId = +paymentResponse.paymentId
            const {
                data: [studentPayment]
            } = await studentPaymentService.getPayments({filter: {paymentId}})
            if (!studentPayment) return

            function updateViewForStatus(status: StudentPayment["status"]) {
                switch (status) {
                    case "pending":
                        setPaymentStatus("confirmed")
                        setPaymentError("The receipt is still being processed...")
                        break
                    case "canceled":
                        setPaymentStatus("failed")
                        setPaymentError("Your payment was failed. Please try again.")
                        break
                    case "success":
                        setPaymentStatus("confirmed")
                        setPaymentError(undefined)
                        break
                    default:
                        setPaymentStatus(undefined)
                }
            }

            function handleStudentPaymentUpdated({paymentId: updatedPaymentId, status}: StudentPayment) {
                if (updatedPaymentId === paymentId) {
                    updateViewForStatus(status)
                }
            }

            if (studentPayment.status === "pending") {
                coreSocket.on(SocketEvent.StudentPaymentUpdated, handleStudentPaymentUpdated)
            }
            updateViewForStatus(studentPayment.status)

            return () => {
                coreSocket.off(SocketEvent.StudentPaymentUpdated, handleStudentPaymentUpdated)
            }
        })()
    }, [paymentResponse.paymentId])

    const handleClosePaymentProcessingModal = useCallback(() => {
        setPaymentStatus(undefined)
        setPaymentError(undefined)
        const search = qs.parse(location.search)
        if (search.paymentId) {
            history.replace({
                pathname: location.pathname,
                hash: location.hash,
                search: qs.stringify(
                    omit(search, ["paymentId", "redirect_status", "payment_intent", "payment_intent_client_secret"])
                )
            })
        }
    }, [location.key])

    useEffect(() => {
        if (isLoggedIn) {
            loadCurrencyExchangeRates()
        }
    }, [isLoggedIn])

    const loadCurrencyExchangeRates = async () => {
        try {
            const rates = await studentPaymentService.getCurrencyExchangeRates()
            setCurrencyExchangeRates(rates)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <StudentPaymentContext.Provider
            value={{
                paymentPopup,
                studentId,
                setStudentId,
                setPaymentStatus,
                setPaymentError,
                currencyExchangeRates
            }}>
            {children}

            <BasePopup
                isShow={paymentPopup.isVisible}
                onClose={paymentPopup.close}
                leftIcon="DOLLAR_SIGN"
                leftIconColor="#fff"
                dialogContentClassName={styles.popupContent}
                destroyOnClose
                keyboard={false}
                maskClosable={false}
                width="auto">
                {paymentPopup.isVisible && !!studentId && <PaymentView studentId={studentId} />}
            </BasePopup>

            <PaymentProcessingModal
                visible={!!paymentStatus}
                status={paymentStatus}
                error={paymentError}
                onClose={handleClosePaymentProcessingModal}
            />
        </StudentPaymentContext.Provider>
    )
}
