import {useQuery} from "@tanstack/react-query"
import {userPersonalContactRolesServiceV3} from "services"
import {ContactRole} from "types/user"

const INITIAL_DATA: ContactRole[] = []

export const useUserPersonalContactRoles = () => {
    const query = useQuery(
        ["all-user-personal-contact-roles"],
        async () => {
            const {data: roles} = await userPersonalContactRolesServiceV3.getAll()
            return roles.map((role) => ({...role, id: role.userPersonalContactRoleId})) as ContactRole[]
        },
        {
            refetchOnMount: false
        }
    )
    return {userPersonalContactRoles: query.data ?? INITIAL_DATA}
}
