import React from "react"
import {v4 as uuid} from "uuid"
import {cloneDeep, isEmpty, isEqual, pick} from "lodash"
import debounce from "debounce-promise"
import {withTranslation} from "react-i18next"
import {BaseInput} from "components/inputs"
import {BaseButton, AddItemCircleButton} from "components/buttons"
import {KlassappTable, KlassappTableHeader, KlassappTableDropdown, KlassappTableDropdownAsyncPaginate} from "uiKit"
import {KlassappTableProps} from "types/common"
import {academicIntegrationMapping, courseService} from "services"
import {handleError, checkPermission, toastError} from "helpers"
import {KlassappTableHOC} from "HOC"
import {Model} from "Model"
import {PermissionUserType} from "types/permission"
import styles from "./IntegrationMapping.module.css"
import {EditIntegrationMapping} from "services/AcademicIntegrationMapping"

type ExternalSystemType = {
    id?: string
    name?: string
}

const ExternalSystemOptions: ExternalSystemType[] = [
    {
        id: "fame",
        name: "Fame"
    },
    {
        id: "genesis",
        name: "Genesis"
    }
]

type PageProps = {
    t: Function
    model: Model
}

type Props = KlassappTableProps & PageProps

type State = {
    newItemData: any
    oldItemData: any
    errors: any
    isSubmitting: boolean
    isEditing: boolean
    stages: any[]
    permissions: PermissionUserType
    newDataIntegrationMapping: any[]
    oldDataIntegrationMapping: any[]
}

class IntegrationMapping extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            newItemData: null,
            oldItemData: null,
            errors: {},
            isSubmitting: false,
            isEditing: false,
            stages: [],
            permissions: {staff: []},
            newDataIntegrationMapping: [],
            oldDataIntegrationMapping: []
        }
        this.onSearchChange = debounce(this.onSearchChange.bind(this), 300)
    }

    async componentDidMount() {
        this.props.dispatchFunc([
            {key: "getPageTitle", func: this.getPageTitle},
            {key: "getListData", func: this.getData},
            {key: "getFields", func: this.getFields},
            {key: "getColumns", func: this.getColumns},
            {key: "getTableHeaderActions", func: this.getTableHeaderActions},
            {key: "getMenuActions", func: this.getMenuActions},
            {key: "onClickDeleteMulti", func: this.onClickDeleteMulti},
            // {key: "onClickDuplicateMulti", func: this.onClickDuplicateMulti},
            {key: "onClickEdit", func: this.onClickEdit},
            {key: "onClickDelete", func: this.onClickDelete}
        ])
        this.props.dispatch({isLoading: true})
        this.getData()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (!isEqual(prevState.newDataIntegrationMapping, this.state.newDataIntegrationMapping)) {
            this.props.dispatch({
                data: cloneDeep(this.state.newDataIntegrationMapping)
            })
        }
    }

    getPageTitle = () => {
        return this.props.t("admission:admission.statuses.status")
    }

    getFields = () => {
        return ["Course Id", "Course Name", "Mapping Code", "External System"]
    }

    getTableHeaderActions = (isShowDuplicateBtn = true, checkedData = []) => {
        const canWrite = checkPermission(this.state.permissions, this.props.model)
        if (!canWrite) {
            return []
        }
        const hasLockedItem = (checkedData || []).some((item) => item.isLock)
        const {t, onClickShowConfirmModal} = this.props
        const actions = []
        if (!hasLockedItem) {
            actions.push({
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            })
        }
        // if (isShowDuplicateBtn) {
        //     actions.push({
        //         title: t("common:action.duplicate"),
        //         icon: "DUPLICATE",
        //         action: () => this.onClickDuplicateMulti()
        //     })
        // }
        return actions
    }

    canShowDeleteIcon = (data) => {
        return checkPermission(this.state.permissions, this.props.model) && !data.isLock
    }

    canShowEditIcon = () => {
        return checkPermission(this.state.permissions, this.props.model)
    }

    getMenuActions = () => {
        const {t} = this.props
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: this.onClickEdit,
                canShow: this.canShowEditIcon
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: this.onClickDelete,
                canShow: this.canShowDeleteIcon
            }
        ]
    }

    getData = async () => {
        this.props.dispatch({isLoading: true})
        const params = this.getParams()
        try {
            const response = await academicIntegrationMapping.listIntegrationMapping(params)
            const integrationMappings = (response?.integrationMappings ?? []).map((item) => ({
                ...item,
                mappingCodeValue: item.mappingCode,
                externalSystemValue: item.externalSystem,
                externalSystem: cloneDeep(ExternalSystemOptions).find((ex) => ex.id === item.externalSystem)?.name,
                courseIdValue: item.courseId,
                courseId: item.courseCode,
                courseNameValue: item.courseName,
                id: item.integrationMappingId
            }))
            this.setState({
                ...this.state,
                isEditing: false,
                newDataIntegrationMapping: [...integrationMappings],
                oldDataIntegrationMapping: [...integrationMappings]
            })
            this.props.dispatch({data: integrationMappings, total: response.total})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    getParams = () => {
        const {page, pageSize, orderField} = this.props
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {}
        }
        if (!isEmpty(orderField)) {
            params.sort = {
                orderBy: orderField.field,
                orderDir: orderField.order
            }
        }
        return params
    }

    getColumns = () => {
        return [
            {
                title: "Course Id",
                field: "courseId"
            },
            {
                title: "Course Name",
                field: "courseName"
            },
            {
                title: "Mapping Code",
                field: "mappingCode"
            },
            {
                title: "External System",
                field: "externalSystem"
            }
        ]
    }

    onClickDeleteMulti = async () => {
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            const checkedItems = [...this.props.data].filter((item) => item.isChecked && item.integrationMappingId)
            const checkedItemIds = checkedItems.map((item) => item.integrationMappingId)
            if (checkedItemIds.length) {
                await academicIntegrationMapping.deleteIntegrationMappings(checkedItemIds)
            }
            const newData = [...this.props.data].filter((item) => !item.isChecked)
            this.setState({
                ...this.state,
                newDataIntegrationMapping: newData,
                oldDataIntegrationMapping: newData
            })
            this.props.dispatch({data: newData, total: newData.length})
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onClickEdit = (editItem) => {
        const newListData = this.state.newDataIntegrationMapping.map((item) => {
            if (item.id === editItem.id) {
                return {
                    ...item,
                    id: item.integrationMappingId,
                    courseId: this.renderCourseCodeDropDown(item.integrationMappingId, {
                        code: item.courseCode,
                        courseId: item.courseIdValue
                    }),
                    courseName: this.renderCourseDropDown(item.integrationMappingId, {
                        name: item.courseName,
                        courseId: item.courseIdValue
                    }),
                    mappingCode: this.renderInput(
                        "mappingCode",
                        "Mapping Code",
                        item.integrationMappingId,
                        item.mappingCodeValue,
                        true
                    ),
                    externalSystem: this.renderExternalSystem(
                        item.integrationMappingId,
                        cloneDeep(ExternalSystemOptions).find((op) => op.id === item.externalSystemValue)
                    )
                }
            }
            return item
        })
        this.setState({...this.state, isEditing: true, newDataIntegrationMapping: [...newListData]})
    }

    onClickDelete = async (deletedItem) => {
        try {
            this.props.dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            let newData = [...this.state.newDataIntegrationMapping]
            if (typeof deletedItem.id === "string") {
                newData = newData.filter((item) => item.id !== deletedItem.id)
                this.setState({
                    ...this.state,
                    newDataIntegrationMapping: newData,
                    oldDataIntegrationMapping: newData
                })
            } else {
                await academicIntegrationMapping.deleteIntegrationMappings([deletedItem.integrationMappingId])
                this.getData()
            }
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
        }
    }

    onSearchChange = async (input, callback) => {
        callback(this.state.stages)
    }

    onSearchCourse = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await courseService.getAutocompleteCourse({
                filter: {
                    search: search
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    onSearchExternalSystem = async () => {
        try {
            return {
                options: ExternalSystemOptions,
                hasMore: false
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    onCodeSearchCourse = async (search = "", loadedOptions) => {
        try {
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data, total} = await courseService.getAutocompleteCourse({
                filter: {
                    codeSearch: search
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: data,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    onChangeCourse = (id, course) => {
        const changeDataForm = this.state.newDataIntegrationMapping.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                courseIdValue: course.courseId,
                courseId: this.renderCourseCodeDropDown(id, course),
                courseName: this.renderCourseDropDown(id, course)
            }
            return newValue
        })
        this.setState({
            ...this.state,
            newDataIntegrationMapping: [...changeDataForm]
        })
    }

    onChangeExternalSystem = (id, externalSystem: ExternalSystemType) => {
        const changeDataForm = this.state.newDataIntegrationMapping.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                externalSystemValue: externalSystem.id,
                externalSystem: this.renderExternalSystem(id, externalSystem)
            }
            return newValue
        })
        this.setState({
            ...this.state,
            newDataIntegrationMapping: [...changeDataForm]
        })
    }

    renderExternalSystem = (id, externalSystem?: ExternalSystemType) => {
        return (
            <KlassappTableDropdown
                options={ExternalSystemOptions}
                value={externalSystem}
                onChange={(value) => this.onChangeExternalSystem(id, value)}
                placeholder="Select External System"
                valueKey="id"
                labelKey="name"
            />
        )
    }

    renderCourseDropDown = (id, course?: any) => {
        return (
            <KlassappTableDropdownAsyncPaginate
                value={course}
                key={id}
                isDisabled={false}
                valueKey="courseId"
                loadOptions={this.onSearchCourse}
                onChange={(value) => this.onChangeCourse(id, value)}
                labelKey="name"
                placeholder="Select Course Name"
            />
        )
    }

    renderCourseCodeDropDown = (id, course?: any) => {
        return (
            <KlassappTableDropdownAsyncPaginate
                value={course}
                key={id}
                isDisabled={false}
                valueKey="courseId"
                loadOptions={this.onSearchCourse}
                onChange={(value) => this.onChangeCourse(id, value)}
                labelKey="code"
                placeholder="Select Course Id"
            />
        )
    }

    onClickAddItem = () => {
        const id = uuid()
        const newItem = {
            id: id,
            courseId: this.renderCourseCodeDropDown(id),
            courseName: this.renderCourseDropDown(id),
            mappingCode: this.renderInput("mappingCode", "Mapping Code", id, "", true),
            externalSystem: this.renderExternalSystem(id)
        }
        const {data} = this.props
        const newData: any[] = [...data]
        newData.push(newItem)
        this.setState({newDataIntegrationMapping: newData})
        this.props.dispatch({data: newData})
    }

    renderInput = (field: string, nameField: string, id: string, value: string, isEditing: boolean) => {
        if (!isEditing) {
            return (
                <div className={styles.titleWrap}>
                    <span className={styles.title}>{value}</span>
                </div>
            )
        }
        return (
            <BaseInput
                type="text"
                placeholder={nameField}
                value={value}
                onChange={(text) => this.onChangeInput(id, field, nameField, text)}
            />
        )
    }

    onChangeInput = (id, field, nameField, value) => {
        const changeDataForm = this.state.newDataIntegrationMapping.map((item) => {
            if (item.id !== id) {
                return item
            }
            const newValue = {
                ...item,
                [`${field}Value`]: value,
                [field]: this.renderInput(field, nameField, item.id, value, true)
            }
            return newValue
        })
        this.setState({
            ...this.state,
            newDataIntegrationMapping: [...changeDataForm]
        })
    }

    validateDataBeforeSubmit = () => {
        for (let i = 0; i < this.state.newDataIntegrationMapping.length; i++) {
            const intemap = this.state.newDataIntegrationMapping[i]
            if (!intemap.courseIdValue || intemap.courseIdValue === "") {
                toastError(this.props.t("common:validation.cantEmpty", {field: "The course info"}))
                return false
            } else if (!intemap.mappingCodeValue || intemap.mappingCodeValue === "") {
                toastError(this.props.t("common:validation.cantEmpty", {field: "The Mapping Code"}))
                return false
            } else if (!intemap.externalSystemValue || intemap.externalSystemValue === "") {
                toastError(this.props.t("common:validation.cantEmpty", {field: "The External System"}))
                return false
            }
        }
        return true
    }

    onClickSave = async () => {
        if (!this.validateDataBeforeSubmit()) {
            return
        }
        this.props.dispatch({isLoading: true})
        const params: EditIntegrationMapping[] = this.state.newDataIntegrationMapping.map((item) => ({
            integrationMappingId: item.integrationMappingId,
            mappingCode: item.mappingCodeValue,
            externalSystem: item.externalSystemValue,
            courseId: item.courseIdValue
        }))
        try {
            await academicIntegrationMapping.editIntegrationMappings(params)
        } catch (e) {
            handleError(e)
        } finally {
            this.props.dispatch({isLoading: false})
            this.getData()
        }
    }

    onClickCancel = () => {
        this.setState({
            ...this.state,
            isEditing: false,
            newDataIntegrationMapping: cloneDeep(this.state.oldDataIntegrationMapping)
        })
        this.props.dispatch({data: cloneDeep(this.state.oldDataIntegrationMapping)})
    }

    compareIsChanged = () => {
        const {oldDataIntegrationMapping, newDataIntegrationMapping} = this.state
        if (oldDataIntegrationMapping.length !== newDataIntegrationMapping.length) {
            return true
        }
        const dataCheckIntegrationMap = ["courseIdValue", "mappingCodeValue", "externalSystemValue"]
        let isEqualIntegrationMap = true

        for (let i = 0; i < newDataIntegrationMapping.length; i++) {
            const oldIntegrationMap = pick(oldDataIntegrationMapping[i], dataCheckIntegrationMap)
            const newIntegrationMap = pick(newDataIntegrationMapping[i], dataCheckIntegrationMap)
            if (!isEqual(oldIntegrationMap, newIntegrationMap)) {
                isEqualIntegrationMap = false
                break
            }
        }
        return !isEqualIntegrationMap
    }

    render() {
        const isChanged = this.compareIsChanged()
        const {isSubmitting} = this.state
        const {
            t,
            isLoading,
            page,
            total,
            pageSize,
            columns,
            data,
            menuActions,
            tableHeaderActions,
            allFields,
            fields,
            isHideMenuActions,
            isShowTableHeaderAction
        } = this.props

        return (
            <div className={styles.wrapper}>
                <KlassappTableHeader
                    isShowAction={isShowTableHeaderAction}
                    actions={tableHeaderActions}
                    page={page}
                    total={total}
                    defaultPageSize={pageSize}
                    onChangePage={this.props.onChangePage}
                    onChangeRowPerPage={this.props.onChangeRowPerPage}
                    fields={fields}
                    allFields={allFields}
                    onChangeFields={this.props.onChangeFields}
                    onChangeAllFields={this.props.onChangeAllFields}
                    onDraggableColumn={this.props.onDraggableColumn}
                />
                <KlassappTable
                    columns={columns}
                    fields={fields}
                    data={data}
                    menuActions={isHideMenuActions ? [] : menuActions}
                    isLoading={isLoading}
                    allFields={allFields}
                    isShowCheckedColumn
                    onClickRowItem={() => {}}
                    onChangeFields={this.props.onChangeFields}
                    onUpdateRowData={this.props.onUpdateRowData}
                    onUpdateTableData={this.props.onUpdateTableData}
                    onDraggableColumn={this.props.onDraggableColumn}
                    onChangeAllFields={this.props.onChangeAllFields}
                />
                {!isLoading && <AddItemCircleButton onClick={this.onClickAddItem} />}

                <div className={styles.actionWrap}>
                    {(isChanged || this.state.isEditing) && (
                        <BaseButton
                            title={t("common:action.cancel")}
                            variant="secondary"
                            className={styles.cancelBtn}
                            onClick={this.onClickCancel}
                        />
                    )}
                    <div>
                        <BaseButton
                            title={isSubmitting ? t("common:action.saving") : t("common:action.save")}
                            onClick={this.onClickSave}
                            isActive={isChanged}
                            disabled={!!Object.keys(this.state.errors).length}
                            loading={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default KlassappTableHOC(withTranslation(["financialAid", "admission", "common"])(IntegrationMapping))
