import React from "react"
import cx from "classnames"
import {useTranslation} from "react-i18next"
import {KlassDropdown} from "components/Select"
import {BaseDatePicker} from "components/DateTimePicker"
import {OPTIONS_WEEK_DAYS, OPTIONS_FREQUENCY_TYPE} from "../constants"
import styles from "./RecurringComponents.module.css"
import {RecurringEnd} from "types/calendar"
import NumberFormat from "react-number-format"

const getFrequencyTypes = (ignoreOptions = []) => {
    return OPTIONS_FREQUENCY_TYPE.filter((opt) => !ignoreOptions.includes(opt.label))
}

export function EndTitle() {
    const {t} = useTranslation(["calendar"])
    return <div className={styles.everyLabel}>{t("calendar.ends")}</div>
}

export function EndBody(props) {
    const {t} = useTranslation(["calendar"])
    const {endDate, setEndDate, executions, setExecutions, end, setEnd, errors, ignoreFrequencyOptions = []} = props

    const renderContent = () => {
        switch (end.value) {
            case RecurringEnd.OnDate:
                return (
                    <BaseDatePicker
                        value={endDate}
                        onChange={(value) => setEndDate(value)}
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        error={!!errors["endDate"]}
                    />
                )
            case RecurringEnd.AfterExecution:
                return (
                    <>
                        <NumberFormat
                            placeholder=""
                            onValueChange={({floatValue}) => setExecutions(floatValue)}
                            value={executions}
                            className={styles.inputNumber}
                            min={0}
                            allowNegative={false}
                        />
                        <div className={styles.executions}>{t("calendar.executions")}</div>
                    </>
                )

            default:
                return null
        }
    }

    return (
        <div className={styles.endBody}>
            <div className={styles.endInput}>
                <KlassDropdown
                    options={getFrequencyTypes(ignoreFrequencyOptions)}
                    value={end}
                    onChange={setEnd}
                    labelKey="label"
                    valueKey="value"
                    placeholder=""
                    error={!!errors["end"]}
                />
            </div>
            <div className={styles.everyInput}>{renderContent()}</div>
        </div>
    )
}

export const Every = ({title, value, onChange, error}) => {
    const {t} = useTranslation(["calendar"])

    return (
        <div
            className={cx(styles.every, {
                [styles.error]: !!error
            })}>
            <div className={styles.everyLabel}>{t("calendar.every")}</div>
            <NumberFormat
                value={value}
                onValueChange={({floatValue}) => onChange(floatValue)}
                className={styles.inputNumber}
                placeholder=""
                min={0}
                allowNegative={false}
            />
            <div className={styles.everyTitle}>{title}</div>
        </div>
    )
}

export const Days = ({value, onChange, error}) => {
    const {t} = useTranslation(["calendar"])

    const onSelectDay = (day) => {
        const days = {...value}
        if (days[day.value]) {
            days[day.value] = undefined
        } else {
            days[day.value] = day
        }
        onChange(days)
    }

    return (
        <div
            className={cx(styles.itemWrap, {
                [styles.error]: !!error
            })}>
            {OPTIONS_WEEK_DAYS.map((day) => (
                <div
                    key={day.value}
                    className={cx(styles.dayBox, {
                        [styles.dayBoxSelected]: !!value[day.value]
                    })}
                    onClick={() => onSelectDay(day)}>
                    {t(`calendar.weekdays.${day.label}`)}
                </div>
            ))}
        </div>
    )
}
