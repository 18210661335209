import React, {useEffect} from "react"
import {TFunction, withTranslation} from "react-i18next"
import {KlassappTableHeader, KlassappTable} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {KlassappTableProps} from "types/common"
import {handleError} from "helpers"
import {Course} from "types/courses"
import {AcademicPlans} from "types/academicPlans"
import {Checkbox, Tooltip} from "antd"
import styles from "./CoursesTable.module.css"
import {Icon} from "components"

type PageProps = {
    t: TFunction
    selectedCourses: AcademicPlans.TermCourse[] | Course.Course[]
}

type Props = KlassappTableProps & PageProps

const SelectedCoursesTable = (props: Props) => {
    const {
        t,
        page,
        total,
        pageSize,
        onDraggableColumn,
        columns,
        isShowTableHeaderAction,
        data,
        getCurrentData,
        onUpdateRowData,
        onChangeFields,
        onChangePage,
        onChangeRowPerPage,
        onChangeAllFields,
        onClickSortColumn,
        allFields,
        onUpdateTableData,
        fields,
        orderField,
        isLoading,
        dispatch,
        dispatchFunc,
        selectedCourses,
        tableHeaderActions
    } = props

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions}
        ])
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getFields = () => {
        return [t("course.code"), t("course.name"), t("course.instructor"), "Class Assistance"]
    }

    const getPageTitle = () => {
        return "Courses"
    }

    const getColumns = () => {
        return [
            {
                title: t("course.code"),
                field: "code",
                style: {minWidth: "150px"}
            },
            {
                title: t("course.name"),
                field: "name",
                style: {minWidth: "250px"}
            },
            {
                title: t("course.instructor"),
                field: "instructor",
                style: {minWidth: "200px"}
            },
            {
                title: "Class Assistance",
                field: "isCA",
                style: {minWidth: "20px"},
                titleHtml: (
                    <div className={styles.classAssistance}>
                        <span>Class Assistance</span>
                        <Tooltip title={"Clock hour will not be calculated"} key={"Class Assistance"}>
                            <div className={styles.classAssistanceInfo}>
                                <Icon icon={"INFO"} />
                            </div>
                        </Tooltip>
                    </div>
                )
            }
        ]
    }

    const getTableHeaderActions = () => {
        return []
    }

    const renderIsCA = (isCA: boolean) => {
        return (
            <Checkbox
                disabled
                checked={isCA}
                onChange={(event) => {
                    const data = getCurrentData()
                    dispatch({
                        data: data.map((item) => ({
                            ...item,
                            isCA: renderIsCA(event.target.checked),
                            role: event.target.checked ? Course.AcademicPlanCourseRole.ClassAssistance : undefined
                        }))
                    })
                }}
            />
        )
    }

    const getData = async () => {
        try {
            dispatch({isLoading: true})
            const data = (selectedCourses || []).map((course, index) => {
                course.id = course.id || `${course.courseId}_${index}`
                course.code = course.code || course.courseCode
                course.name = course.name || course.courseName
                course.isCA = renderIsCA(course.role === Course.AcademicPlanCourseRole.ClassAssistance)
                return course
            })
            dispatch({data, total: data.length})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    return (
        <>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                onChangeFields={onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
                onClickSortColumn={onClickSortColumn}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
            />
        </>
    )
}

export default KlassappTableHOC(withTranslation(["course"])(SelectedCoursesTable))
