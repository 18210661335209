import {Screen} from "components/ui"
import {Course} from "types/courses"
import {BaseDepartmentId} from "types/departments"
import {GeneralSettingField} from "types/settings/general-setting"
import styles from "./ProgressReportPdf.module.css"
import ProgressReportPdf from "./ProgressReportPdf"

type ProgressReportScreenProps = {
    match: {
        params: {
            profileId: number
            departmentId: BaseDepartmentId
            type: Course.CourseType
            transcriptType: GeneralSettingField
        }
    }
}

const ProgressReportScreen = (props: ProgressReportScreenProps) => {
    const {match} = props
    const data = match?.params
    return (
        <Screen
            className={styles.screen}
            header={{
                isShowNotification: false,
                className: styles.header,
                backUrl: `/academics/registrar/student/${data.profileId}?tab=degree-audit`,
                variant: "column"
            }}>
            <ProgressReportPdf data={data} />
        </Screen>
    )
}

export default ProgressReportScreen
