import React from "react"
import cx from "classnames"
import styles from "./item.module.css"

export function DateField(props) {
    const {field} = props
    const {top, left, width, height} = field.placeInformation

    return (
        <div className={styles.wrap} style={{left, top}}>
            <div className={cx(styles.actionItem, styles.flexCenter)} style={{width, height}}>
                <span className={styles.date}>{field.date}</span>
            </div>
        </div>
    )
}
