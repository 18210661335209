import axios from "axios"
import {get} from "lodash"
import {StudentActivityLog} from "types/student-activity-log"
import {BaseService} from "services/index"

class ActivityLogsService extends BaseService {
    constructor(baseURL = process.env.REACT_APP_ACTIVITY_LOG_API_BASE_URL) {
        super()
        this.axios = axios.create({
            timeout: 30 * 1000,
            baseURL
        })
        // this.addInterceptors() // TODO enable this after V3-6415
    }

    async getActivityLogs({
        filter,
        to,
        page,
        pageSize,
        linkedEntities
    }: {
        filter?: any
        to?: string
        page: number
        pageSize: number
        linkedEntities?: boolean
    }): Promise<{
        total: number
        items: StudentActivityLog[]
    }> {
        const filters: MongoQueryFilter[] = []
        if (filter?.userId) {
            filters.push({
                field: "userId",
                operator: MongoOperator.$in,
                value: Array.isArray(filter.userId) ? filter.userId : [filter.userId]
            })
        }
        if (filter?.profileId) {
            filters.push({
                field: "profileId",
                operator: MongoOperator.$in,
                value: Array.isArray(filter.profileId) ? filter.profileId : [filter.profileId]
            })
        }
        if (filter?.targetId) {
            filters.push({
                field: "targetId",
                operator: MongoOperator.$in,
                value: Array.isArray(filter.targetId) ? filter.targetId : [filter.targetId]
            })
        }
        if (filter?.type) {
            filters.push({
                field: "type",
                operator: MongoOperator.$in,
                value: Array.isArray(filter.type) ? filter.type : [filter.type]
            })
        }
        if (to) {
            filters.push({
                field: "createdAt",
                operator: MongoOperator.$gte,
                value: to
            })
        }
        const response = await this.get("/v1/activity-logs", {
            filter: parseMongoQueryFilter(filters),
            pageNumber: !to ? page : undefined,
            pageSize: !to ? pageSize : undefined,
            sort: "-time",
            linkedEntities
        })
        return get(response, "data", {})
    }

    async createActivityLogs(items: Partial<StudentActivityLog>[]): Promise<StudentActivityLog[]> {
        const response = await this.post("/v1/activity-logs/bulk", {items})
        return get(response, "data.items", [])
    }
}

export default ActivityLogsService

export enum MongoOperator {
    $eq = "$eq",
    $ne = "$ne",
    $lt = "$lt",
    $lte = "$lte",
    $gt = "$gt",
    $gte = "$gte",
    $in = "$in",
    $nin = "$nin",
    $regex = "$regex",
    $or = "$or"
}

export type MongoQueryFilter = {
    field: string
    value: any
    operator: MongoOperator
    regexOption?: string
}

export const parseMongoQueryFilter = (filter: MongoQueryFilter[]): string => {
    return filter
        .map((obj, idx) => {
            const queryStringSegment = getQueryString(obj)

            return idx !== filter.length - 1 ? queryStringSegment + "&" : queryStringSegment
        })
        .join("")
}

const getQueryString = (filter: {field: string; value: any; operator: MongoOperator; regexOption?: string}): string => {
    const {field, value, operator, regexOption = "i"} = filter
    switch (operator) {
        case "$eq":
            return `${field}=${value}`
        case "$ne":
            return `${field}!=${value}`
        case "$lt":
            return `${field}<${value}`
        case "$lte":
            return `${field}<=${value}`
        case "$gt":
            return `${field}>${value}`
        case "$gte":
            return `${field}>=${value}`
        case "$in":
            return `${field}=${value.map((val: any) => String(val)).join(",")}`
        case "$nin":
            return `${field}!=${value.map((val: any) => String(val)).join(",")}`
        case "$regex":
            return `${field}=/${value}/${regexOption}`
        case "$or":
            const fields = field.split(",")
            let searchStr = ""
            for (const searchField of fields) {
                searchStr += `{"${searchField}":{"$regex":"${value}","$options":"i"}},`
            }
            searchStr = searchStr.slice(0, searchStr.length - 1)
            return `filter={"$or":[${searchStr}]}`
        default:
            return `${field}=${value}`
    }
}
