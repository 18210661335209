import React, {useState} from "react"
import {BasePopup} from "components/popup"
import styles from "./RejectApplicationForm.module.css"
import {BaseButton, BaseTextArea} from "components"
import {handleError, toastError} from "helpers"

type Props = {
    open: boolean
    onClose: () => void
    onSuccess?: (comment: string) => void
}

export function RejectApplicationForm(props: Props) {
    const {open, onClose, onSuccess} = props
    const [comment, setComment] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSubmit = async () => {
        try {
            if (comment && comment.trim() === "") {
                toastError("Should give reason to reject")
                return
            }

            setIsSubmitting(true)
            if (onSuccess) onSuccess(comment)
        } catch (e) {
            handleError(e)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <BasePopup
            isShow={open}
            onClose={onClose}
            leftIcon="CLOSE"
            leftIconColor="#fff"
            width="40vw"
            closable
            destroyOnClose>
            <div className={styles.headingTitle}>Reject Application</div>
            <div className={styles.descTitle}>Inform student why this applicaion was rejected</div>
            <BaseTextArea
                className={styles.noteInput}
                rows={5}
                placeholder="type"
                onChange={setComment}
                value={comment}
            />
            <div className={styles.doneBtn}>
                <BaseButton title="Reject" onClick={onSubmit} className={styles.saveBtn} loading={isSubmitting} />
            </div>
        </BasePopup>
    )
}
