/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {academicGradingSystem} from "services"
import {handleError} from "helpers"
import styles from "./GradingSystem.module.css"
import {BaseNewFilter, KlassappTable, KlassappTableHeader} from "uiKit"
import {useTranslation} from "react-i18next"
import {useHistory} from "react-router-dom"
import {KlassappTableHOC} from "HOC"
import {BaseButton} from "components"
import {debounce} from "lodash"

function GradingSystem(props) {
    const [filter, setFilter] = useState({
        campus: null,
        instructor: null
    })

    const {
        dispatch,
        dispatchFunc,
        page,
        total,
        pageSize,
        columns,
        getCurrentData,
        isHideMenuActions,
        menuActions,
        data,
        allFields,
        fields,
        tableHeaderActions,
        orderField,
        isLoading,
        isShowTableHeaderAction,
        departmentId
    } = props

    const textSearch = useRef<string>()

    const searchGradingSystem = async (text: string) => {
        textSearch.current = text
        dispatch({isLoading: true})
        try {
            const params = getParams()
            const {data, total} = await academicGradingSystem.getListGradingSystem(params)
            dispatch({data: data, total: total})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const debouncedChangeHandler = useMemo(() => debounce(searchGradingSystem, 600), [])

    // Stop the invocation of the debounced function after unmounting
    useEffect(() => {
        return () => {
            debouncedChangeHandler.cancel()
        }
    }, [debouncedChangeHandler])

    const {t} = useTranslation(["course"])
    const history = useHistory()

    const getFields = () => {
        return ["ID", t("academics:settings.description")]
    }

    const getPageTitle = () => {
        return "Course"
    }

    const getTableHeaderActions = () => {
        const {t, onClickShowConfirmModal} = props
        return [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: () => onClickShowConfirmModal("DELETE")
            }
        ]
    }

    const getColumns = () => {
        return [
            {
                title: "ID",
                field: "code",
                style: {minWidth: "70px"},
                headerStyle: {minWidth: "70px"}
            },
            {
                title: t("academics:settings.description"),
                field: "title",
                style: {minWidth: "150px"},
                headerStyle: {minWidth: "150px"}
            }
        ]
    }

    const getParams = () => {
        const params: any = {
            range: {
                page,
                pageSize
            },
            filter: {
                text: textSearch.current
            }
        }
        return params
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const params = getParams()
            const {data, total} = await academicGradingSystem.getListGradingSystem(params)
            dispatch({data: data, total: total})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const onClickRowItem = (row) => {
        history.push(`/settings/add-grading?id=${row.id}`)
    }

    const onClickDeleteMulti = useCallback(async () => {
        const checkedItems = getCurrentData().filter((item) => item.isChecked)
        const payload = checkedItems.map((item) => item.id)
        try {
            dispatch({isLoading: true, isShowTableHeaderAction: false, isHideMenuActions: false})
            await academicGradingSystem.deleteGradingSystem(payload)
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }, [data])

    const onClickEdit = (editItem) => {
        history.push(`/settings/add-grading?id=${editItem.id}`)
    }

    const onClickDelete = useCallback(async (deletedItem) => {
        try {
            dispatch({isLoading: true})
            await academicGradingSystem.deleteGradingSystem([deletedItem.id])
            await getData()
        } catch (e) {
            handleError(e)
        } finally {
            dispatch({isLoading: false})
        }
    }, [])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "getTableHeaderActions", func: getTableHeaderActions},
            {key: "onClickDeleteMulti", func: onClickDeleteMulti},
            {key: "getMenuActions", func: getMenuActions},
            {key: "onClickEdit", func: onClickEdit},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [data])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    const getMenuActions = () => {
        return [
            {
                title: t("common:action.edit"),
                icon: "EDIT",
                action: onClickEdit
            },
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDelete
            }
        ]
    }

    const onClickAdd = useCallback(() => {
        history.push(`/settings/add-grading`)
    }, [])

    const onClickClearFilter = useCallback(() => {}, [])

    const onApplyFilter = useCallback(() => {}, [])

    const onSearchInput = useCallback((input: string) => {
        debouncedChangeHandler(input)
    }, [])

    return (
        <div className={styles.wrapper}>
            <BaseNewFilter
                className={styles.filterWrap}
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClickClearFilter}
                onSearchInput={onSearchInput}
            />

            <div className={styles.action}>
                <BaseButton title="Add" variant="primary" onClick={onClickAdd} />
            </div>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={isHideMenuActions ? [] : menuActions}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
        </div>
    )
}

export default KlassappTableHOC(GradingSystem)
