/* eslint-disable react-hooks/exhaustive-deps */
import React, {useReducer, useEffect} from "react"
import {BasePopup} from "components/popup"
import {BaseButton} from "components/buttons"
import {Course} from "types/courses"
import {handleError} from "helpers"
import styles from "./AddCoursesPopup.module.css"
import SelectedCoursesTable from "./SelectedCoursesTable"
import CoursesTable from "./CoursesTable"
import {cloneDeep} from "lodash"
import {v4 as uuid} from "uuid"
import {SectionCourse} from "./SectionView"

type Props = {
    selectedCourses: SectionCourse[]
    isShow: boolean
    sectionId: string | number
    onClose: () => void
    saveCoursesSuccess: (courses: Array<Course.Course & {sectionCourseId: number; sectionId: number}>) => void
}
const INITIAL_STATE = {
    isSaving: false,
    selectedCourses: [],
    forceKey: 1
}

export function AddCoursesPopup(props: Props) {
    function reducer(state, action) {
        return {...state, ...action}
    }
    const [{selectedCourses, forceKey, isSaving}, dispatch] = useReducer(reducer, INITIAL_STATE)
    const {isShow, onClose, saveCoursesSuccess, sectionId} = props
    const hasChanged = selectedCourses.filter((course) => !course.isSelected).length > 0

    useEffect(() => {
        const selectedCourses = props.selectedCourses.map((course: any) => {
            course.isSelected = true
            return course
        })
        dispatch({selectedCourses: selectedCourses, forceKey: forceKey + 1})
    }, [])

    const onAddCourses = (
        addedCourses: Array<
            Course.CourseTableItem & {
                sectionCourseId?: number
                sectionId: number
                total: number
            }
        >
    ) => {
        const newCourses = addedCourses.map((course) => ({
            courseId: course.courseId,
            courseCode: course.code,
            courseName: course.name,
            sectionCourseId: uuid(),
            credits: course.total,
            sectionId: sectionId
        }))
        dispatch({selectedCourses: [...selectedCourses, ...newCourses], forceKey: forceKey + 1})
    }

    const saveCourses = async () => {
        try {
            dispatch({isSaving: true})
            saveCoursesSuccess(cloneDeep(selectedCourses))
            onClose()
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isSaving: false})
        }
    }

    return (
        <BasePopup isShow={isShow} onClose={onClose} leftIcon="PLUS_CIRCLE" width="80vw">
            <div className={styles.wrap}>
                <p className={styles.title}>Add Course</p>
                <div className={styles.tableWrap}>
                    <SelectedCoursesTable key={`selected_${forceKey}`} selectedCourses={selectedCourses} />
                    <CoursesTable key={forceKey} onAddCourses={onAddCourses} />
                </div>
                <div className={styles.action}>
                    <BaseButton title="Save" onClick={saveCourses} loading={isSaving} isActive={hasChanged} />
                </div>
            </div>
        </BasePopup>
    )
}
