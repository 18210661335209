/* eslint-disable react-hooks/exhaustive-deps */
import React, {MutableRefObject} from "react"
import cx from "classnames"
import {Icon} from "components/Icon"
import {InitialType} from "types/tasks"
import styles from "./item.module.css"
import {RemarkIndicator} from "./RemarkIndicator"

type Props = {
    field: any
    canSign: boolean
    currentInitials: any
    errorFieldIds: string[]
    fieldRefs: MutableRefObject<Record<string, HTMLDivElement>>
    onChangeField: (fieldId: string, data: any) => void
    onChangeSingleInitialsField: (field: any, data: any) => void
    openInitialPopup: (field: any) => void
}

export function Initials(props: Props) {
    const {
        field,
        canSign,
        currentInitials,
        errorFieldIds,
        fieldRefs,
        onChangeField,
        onChangeSingleInitialsField,
        openInitialPopup
    } = props
    const {top, left, width, height} = field.placeInformation
    const {isYourField} = field

    const onSignInitials = () => {
        if (!canSign || !isYourField) {
            return
        }
        if (!currentInitials || !currentInitials.imageUrl) {
            openInitialPopup(field)
            return
        }
        const value = currentInitials.imageUrl
        if (field.initialType === InitialType.MULTIPLE) {
            onChangeField(field.id, {isSigned: true, value: !field.value ? value : null})
        } else {
            onChangeSingleInitialsField(
                {fieldId: field.id, parentId: field.parentId},
                {isSigned: true, value: !field.value ? value : null}
            )
        }
    }

    const renderField = () => {
        if (!field.isSigned || !field.value) {
            return (
                <div
                    ref={(ref) => (fieldRefs.current[field.id] = ref)}
                    className={cx(styles.actionItem, {
                        [styles.actionItemRequired]: isYourField && field.required,
                        [styles.hasCommentBorder]: !!field.remarkComment,
                        [styles.actionItem_error]: errorFieldIds.includes(field.id)
                    })}
                    style={{width, height}}
                    onClick={onSignInitials}>
                    <div className={styles.textWrap}>
                        <p className={styles.label}>{field.label}</p>
                        <Icon icon="INITIALS" className={styles.icon} />
                    </div>
                </div>
            )
        }
        if (currentInitials?.imageUrl) {
            return (
                <div
                    className={cx(styles.actionItem, styles.flexCenter, {
                        [styles.actionItemRequired]: isYourField && field.required,
                        [styles.hasCommentBorder]: !!field.remarkComment
                    })}
                    style={{width, height}}
                    onClick={onSignInitials}>
                    {field.value && (
                        <Icon icon="CHECKMARK_CIRCLE" className={styles.checkedIcon} color="var(--success-400-base)" />
                    )}
                    {field.value && <img className={styles.signedItem} src={currentInitials.imageUrl} alt="" />}
                </div>
            )
        }
        return null
    }

    const renderBody = () => {
        if (!canSign || !isYourField) {
            return (
                <div
                    className={cx(styles.actionItem, styles.flexCenter)}
                    style={{
                        width,
                        height,
                        backgroundColor: "transparent",
                        borderColor: "transparent"
                    }}>
                    {field.value && <img className={styles.signedItem} src={field.value} alt="" />}
                </div>
            )
        }
        return renderField()
    }

    return (
        <>
            <div className={styles.wrap} style={{left, top}}>
                {renderBody()}
            </div>
            {field.remarkComment && (
                <div className={styles.wrap} style={{left, top, zIndex: 9}}>
                    <RemarkIndicator field={field} />
                </div>
            )}
        </>
    )
}
