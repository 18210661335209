import React, {useEffect, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import DayPicker from "react-day-picker"
import cx from "classnames"
import {SecondaryButton} from "components/buttons"
import {Icon} from "components/Icon"
import moment from "moment"
import styles from "./CalendarPicker.module.css"
import "./CustomPanelDatePicker.css"
import {DisplayMode} from "types/common"

type CalendarPickerProps = {
    selectedDay?: Date
    onDayClick?: Function
    displayMode?: DisplayMode
}

export const CalendarPicker = (props: CalendarPickerProps) => {
    const {selectedDay, onDayClick, displayMode = "normal"} = props
    const {t} = useTranslation(["calendar"])
    const weekdaysShort = [
        t("calendar.weekdays.Sun"),
        t("calendar.weekdays.Mon"),
        t("calendar.weekdays.Tue"),
        t("calendar.weekdays.Wed"),
        t("calendar.weekdays.Thu"),
        t("calendar.weekdays.Fri"),
        t("calendar.weekdays.Sat")
    ]
    const pickerRef = useRef(null)
    const [today, setToday] = useState(selectedDay ? selectedDay : new Date())
    const [dateViewed, setDateViewed] = useState(selectedDay ? selectedDay : new Date())
    const isPanelDisplay = displayMode === "panel"

    useEffect(() => {
        setToday(selectedDay)
        setDateViewed(selectedDay)
    }, [selectedDay])

    const onClick = (date) => {
        if (onDayClick) {
            onDayClick(date)
        }
    }

    const onClickPrevMonth = () => {
        pickerRef.current.showPreviousMonth()
        const prevMonth = moment(dateViewed).subtract(1, "month").toDate()
        setDateViewed(prevMonth)
    }

    const onClickNextMonth = () => {
        pickerRef.current.showNextMonth()
        const nextMonth = moment(dateViewed).add(1, "month").toDate()
        setDateViewed(nextMonth)
    }

    const modifiers = {
        todaySelected: today
    }

    const modifiersStyles = {
        todaySelected: {
            color: "#185abc",
            backgroundColor: "#d2e3fc",
            maxWidth: "30px",
            maxHeight: "12px",
            minWidth: "30px",
            minHeight: "12px",
            borderRadios: "50%",
            width: "40px",
            height: "15px",
            border: "none",
            zIndex: 4
        }
    }

    return (
        <div className={styles.root}>
            <div className={cx(styles.indicatorWrap, {[styles.indicatorPanelWrap]: isPanelDisplay})}>
                {isPanelDisplay && (
                    <SecondaryButton
                        title=""
                        icon={<Icon icon="ARROW_LEFT" className={styles.indicatorIcon} color="var(--black-base)" />}
                        className={cx(styles.indicatorIconBtn, {
                            [styles.indicatorIconBtnPanel]: isPanelDisplay
                        })}
                        onClick={onClickPrevMonth}
                    />
                )}
                <div className={cx(styles.indicatorDate, {[styles.indicatorPanelDate]: isPanelDisplay})}>
                    {moment(dateViewed).format("MMMM YYYY")}
                </div>
                {!isPanelDisplay && (
                    <SecondaryButton
                        title=""
                        icon={<Icon icon="ARROW_LEFT" className={styles.indicatorIcon} color="var(--black-base)" />}
                        className={styles.indicatorIconBtn}
                        onClick={onClickPrevMonth}
                    />
                )}
                <SecondaryButton
                    title=""
                    icon={
                        <Icon
                            icon="ARROW_LEFT"
                            className={cx(styles.indicatorIcon, styles.rotate)}
                            color="var(--black-base)"
                        />
                    }
                    className={cx(styles.indicatorIconBtn, {
                        [styles.indicatorIconBtnPanel]: isPanelDisplay
                    })}
                    onClick={onClickNextMonth}
                />
            </div>
            <div className={isPanelDisplay ? "PanelDatePicker" : ""}>
                <DayPicker
                    className="w-full"
                    weekdaysShort={weekdaysShort}
                    showOutsideDays
                    month={dateViewed}
                    ref={pickerRef}
                    selectedDays={today}
                    modifiers={modifiers}
                    modifiersStyles={modifiersStyles}
                    onDayClick={onClick}
                    firstDayOfWeek={0}
                    captionElement={() => null}
                />
            </div>
        </div>
    )
}
