/* eslint-disable react-hooks/exhaustive-deps */
import debounce from "debounce-promise"
import React from "react"
import {useTranslation} from "react-i18next"
import {documentService} from "services"
import {KlassDropdownAsync, KlassDropdownAsyncProps} from "../Select"
import {UserDocument} from "types/user-documents"

type Props = Partial<KlassDropdownAsyncProps> & {
    profileId: number
    selectedDocumentIds?: number[]
}

const DocumentSelect: React.FC<Props> = ({profileId, selectedDocumentIds = [], ...props}) => {
    const {t} = useTranslation(["common"])

    const search = React.useCallback(
        debounce(async (search: string = "") => {
            const {data} = await documentService.autocompleteDocuments({
                search,
                ownerUserProfileId: profileId
            })
            const dataFiltered = data.filter((item) => !selectedDocumentIds.includes(item.userDocumentId))
            return dataFiltered
        }, 500),
        [profileId]
    )

    return (
        <KlassDropdownAsync
            {...props}
            placeholder={props.placeholder || t("selectField.placeholder")}
            loadOptions={search}
            defaultOptions
            valueKey="userDocumentId"
            labelKey="name"
            getOptionLabel={(option: UserDocument) => option.name}
        />
    )
}

export default DocumentSelect
